<template>
  <div class="no-drag vis-chart-filter" :class="margin">
    <el-select
      :value="filterValueParse"
      :multiple="multipleSelectionByChartType"
      :size="selectBoxSize"
      :placeholder="dropdownPlaceholder[multipleSelectionByChartType]"
      :disabled="disabledTextField || isDropdownListDisabled"
      :tabindex="tabIndex"
      :filter-method="filterMethod"
      clearable
      filterable
      collapse-tags
      @change="(value) => addFiltersToPanels({ value })"
    >
      <el-option
        v-for="item in searchedOptions"
        :key="item.value"
        :label="filterItem(item.label)"
        :value="checkFilterItemValue(item.value)"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { DefaultValue } from "../../../commons/dashboardProperties";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
import { getFilterList } from "../../../util/chart-filter/filterListHelper";
import {
  checkSelectBoxSize,
  getOperator,
  getDropdownPlaceholder,
} from "../../../util/dashboard-filter/dashboardChartFilter";
import { getSelectedFieldDatasetId } from "../../../util/dataset-fields/datasetFields";
import { formatMeasure } from "../../panel/measures/js/formatMeasures";
import { checkIfFieldTypeIsDateAndFormatDateFilterValue } from "../../../util/chart-filter/filterValueSetters";
import { checkFilterDefaultValueNone } from "../../../util/chart-filter/filterChartCommon";

export default {
  mixins: [panelDataPropertiesStyle],
  props: {
    option: {
      type: Object,
      default() {
        return {};
      },
    },
    panelData: {
      type: Object,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isFilterPaneActive: {
      type: Boolean,
      default: false,
    },
    scheduleFilterValue: {
      type: Object,
      default: null,
    },
    margin: {
      type: String,
      default: "vis-mr--1",
    },
    applyToTabsKey: {
      type: String,
      default: "dropdownFilterApplyToTabs",
    },
    filterOnMount: {
      type: Boolean,
      default: false
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    inversedList: {
      type: Array,
      default: () => [],
    },
    isSelectAllActive: {
      type: Boolean,
      default: false
    },
    isInverseSelect: {
      type: Boolean,
      default: false
    },
    isChartTypeFilterDropdown: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    defaultValueByChartType: {
      handler(oldValue, newValue) {
        if (oldValue !== newValue) {
          this.addFiltersToPanels({ value: null });
        }
      },
    },
    filterList: {
      handler() {
        this.searchedOptions = this.filterList;
      },
      deep:true,
      immediate:true
    },
  },
  data() {
    return {
      searchedOptions: []
    };
  },
  mounted() {
    //ScheduleFilterValue default dolduruluyor, eğer var ise filtersa eklensin
    if (this.scheduleFilterValue?.[this.metricField]?.value)
      this.addFiltersToPanels({ value: this.scheduleFilterValue?.[this.metricField]?.value });

      this.addFiltersToPanelWithFilterDefaultValue();
  },
  computed: {
    tabIndex() {
      return this.CHART_COMPUTED_VALUES.DROPDOWN_FILTER_TAB_INDEX;
    },
    dropdownPlaceholder() {
      return getDropdownPlaceholder();
    },
    filterList() {
      const selectAllList = this.selectedItems.map((item) => ({ value: item}));
      return (this.isSelectAllActive || this.isInverseSelect) ? getFilterList(selectAllList) : getFilterList(this.option.arr);
    },
    isDropdownListDisabled() {
      return (
        this.isDisabled && this.defaultValueByChartType == DefaultValue.NONE
      );
    },
    disabledTextField() {
      return !this.panelDataDetailsMetrics?.length;
    },

    selectBoxSize() {
      return checkSelectBoxSize(this.isFilterPaneActive);
    },

    filterValueParse() {
      return this.getSelectedList();
    },

    metricField() {
      //Filter chart'lar sadece bir tane field alcağı içi listelerdeki  0'ıncıyı alıyoruz.
      return this.panelDataDetailsCustomFilters?.length
        ? this.panelDataDetailsCustomFilters?.[0]?.field
        : this.panelDataDetailsMetrics?.[0]?.field;
    },
    metricAlias() {
      //Filter chart'lar sadece bir tane field alcağı içi listelerdeki  0'ıncıyı alıyoruz.
      return this.panelDataDetailsCustomFilters?.length
        ? this.panelDataDetailsCustomFilters?.[0]?.alias
        : this.panelDataDetailsMetrics?.[0]?.alias;
    },
    // The FilterDropdown component is used in places where we cannot use the SelectListFilter component. This check was implemented for this reason.
    multipleSelectionByChartType() {
      return this.isChartTypeFilterDropdown ? this.dropdownFilterMultipleSelection : this.selectListFilterMultipleSelection;
    },
    // The FilterDropdown component is used in places where we cannot use the SelectListFilter component. This check was implemented for this reason.
    defaultValueByChartType() {
      return this.isChartTypeFilterDropdown ? this?.dropdownFilterDefaultValue : this.selectListFilterDefaultValue;
    }
  },

  methods: {
    getSelectedList() {
      //this.scheduleFilterValue => schedule popup'ında filters tabında kullanılır.
      if (this.scheduleFilterValue) {
        return this.scheduleFilterValue?.[this.metricField]?.value;
      } else if (this.isSelectAllActive) {
        this.addFiltersToPanels({ value: this.selectedItems });
        return this.selectedItems;
      } else if (this.isInverseSelect) {
        this.addFiltersToPanels({ value: this.inversedList });
        return this.inversedList;
      }else {
        return this.filterValue?.[this.metricField]?.value
      }
    },
    addFiltersToPanels({ value, isFilterAddActiveWithDefaultValue = false }) {
      const params = {
        value: this.getValue(value),
        operator: getOperator(
          this.multipleSelectionByChartType,
          this.panelDataDetailsCustomFilters?.length
        ),
        panelI: this.panelData.i,
        isActiveDefaultValue:
          this?.defaultValueByChartType == DefaultValue.SELECTION,
        datasetId: getSelectedFieldDatasetId(this.panelData),
        hasCustomFilter: this.panelDataDetailsCustomFilters?.length,
        applyToTabs: this[this.applyToTabsKey],
        notRunTheViewChart: isFilterAddActiveWithDefaultValue
      };

      this.$emit("addFiltersToPanels", params);
      this.$emit("resetAllControllers");
    },

    filterMethod(value) {
      const replaceSpecialCharacters = (value) => {
        return value.replaceAll("İ", "I").toLowerCase().replaceAll("ı", "i");
      };

      const filterValue = replaceSpecialCharacters(value);
      const filterMap = this.filterList
        .filter((f) => replaceSpecialCharacters(f.value.toString()).includes(filterValue));

      this.searchedOptions = filterMap;
    },

    getValue(value) {
      if (this.multipleSelectionByChartType) {
        return value?.length
          ? { [this.metricField]: { value, alias: this.metricAlias } }
          : {};
      } else {
        return value
          ? { [this.metricField]: { value, alias: this.metricAlias } }
          : {};
      }
    },
    filterItem(value) {
      return formatMeasure(value, this.panelDataDetailsMetrics?.[0]?.format);
    },
    checkFilterItemValue(filterValue) {
      return checkIfFieldTypeIsDateAndFormatDateFilterValue(
        this.panelDataDetailsMetrics?.[0],
        filterValue
      );
    },
    addFiltersToPanelWithFilterDefaultValue() {
      if (
        !checkFilterDefaultValueNone(this?.defaultValueByChartType) && 
        this.filterValueParse &&
        this.filterOnMount
      ) {
        this.addFiltersToPanels({ value: this.filterValueParse, isFilterAddActiveWithDefaultValue: true });
      }
    }
  },
};
</script>
<style scoped>
::v-deep .el-select__input {
  font-size: 0.75rem;
}
::v-deep
  .el-select-dropdown.is-multiple
  .el-select-dropdown__item.selected.hover {
  background-color: var(--primary);
}
</style>
