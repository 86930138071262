<template>
  <el-dialog width="90%" :visible="true">
    <div class="vis-conditional-card">
    <div class="vis-conditional-title vis-flex--spacebeetwen-Ycenter">
      <span>
        {{ $t("table.conditionalFormatting") }} - {{ selectedFieldAlias }}</span
      >
      <div class="vis-conditional-title-rightbox">
        <i
          @click="$emit('closePopup')"
          aria-hidden="true"
          :class="customIcon.Close"
          class="vis-cursor-pointer"
        ></i>
      </div>
    </div>
    <!-- vis-conditional-title-end -->
    <div class="vis-conditional-content">
      <div class="vis-flex--Ycenter vis-mb--1">
        <span class="vis-mr--1">{{ $t("filterComponents.type") }}</span>
        <el-select
          class="vis-el-select-not-with"
          size="mini"
          v-model="selectedCondition.formatType"
        >
          <el-option
            v-for="item in conditionFormatTypes"
            :key="item.value"
            :label="$t(item.label)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div
        v-for="(condition, conditionIndex) in selectedCondition.conditions"
        :key="condition.id"
        class="vis-conditional-condition"
      >
        <div class="vis-text--right">
          <i
            @click="duplicateCondition(condition)"
            :class="customIcon.Copy"
            class="vis-cursor-pointer"
          ></i>
          <i
            @click="deleteCondition(conditionIndex)"
            :class="customIcon.Trash"
            class="vis-ml--1 vis-cursor-pointer"
          ></i>
        </div>

        <div class="vis-row">
          <div class="vis-col vis-col-4">
            <span class="vis-font-bold"
              >{{ $t("filterComponents.rule") }} -
              {{ conditionIndex + 1 }}</span
            >
          </div>
          <div class="vis-col">
            <span class="vis-font-bold">{{ $t("generalPages.format") }}</span>
          </div>
        </div>
        <div class="vis-row">
          <div class="vis-col vis-col-4 vis-condition-vertical-line">
            <!-- CONDITION RULE START -->
            <div v-for="(rule, ruleIndex) in condition.rules" :key="ruleIndex">
              <div class="vis-row">
                <div class="vis-col">
                  <label class="vis-form-label">{{
                    $t("conditionalFormatting.dataItem")
                  }}</label>
                  <ConditionalFormattingSelectBox
                    :options="fieldOptions"
                    :value="rule.dataItem"
                    :placeholder="$t('conditionalFormatting.selectDataItem')"
                    @handleChange="(value) => changeDataItem(value, rule)"
                  />
                </div>
                <div class="vis-col vis-col-3">
                  <label class="vis-form-label">{{
                    $t("conditionalFormatting.operator")
                  }}</label>
                  <ConditionalFormattingSelectBox
                    :options="operatorOptions(rule.dataItem)"
                    :value="rule.operator"
                    :placeholder="$t('conditionalFormatting.selectOperator')"
                    @handleChange="(value) => changeOperator(value, rule)"
                  />
                </div>
                <!-- FIRST VALUE START -->
                <div class="vis-col">
                  <label class="vis-form-label">{{
                    $t("conditionalFormatting.value")
                  }}</label>
                  <ConditionalFormattingSelectBox
                    v-if="isSelectBoxActive(rule.dataItem)"
                    :options="mappedSearchOptions"
                    :multiple="isMultipleOperatorActive(rule.operator)"
                    :value="rule.value1"
                    :allowCreate="true"
                    :filterable="true"
                    :placeholder="$t('conditionalFormatting.selectValue')"
                    @handleFocus="getSearchByFieldAndUsageType(rule)"
                    @handleChange="(value) => addValueByOperator(value, rule)"
                  />
                  <el-date-picker
                    v-else-if="checkDateFilterActive(rule.dataItem)"
                    :value="rule.value1"
                    type="date"
                    size="mini"
                    :placeholder="$t('filterComponents.start_date')"
                    @input="(value) => addValueByOperator(value, rule)"
                  >
                  </el-date-picker>
                  <el-input
                    v-else
                    size="mini"
                    :value="rule.value1"
                    @input="
                      (value) => {
                        rule.value1 = valueControl(value);
                      }
                    "
                  />
                </div>
                <!-- FIRST VALUE END -->
                <!-- SECOND VALUE START -->
                <div class="vis-col" v-if="isSecondInputActive(rule)">
                  <label class="vis-form-label">{{
                    $t("conditionalFormatting.value")
                  }}</label>
                  <!-- Date Picker -->
                  <el-date-picker
                    v-if="checkDateFilterActive(rule.dataItem)"
                    :value="rule.value2"
                    type="date"
                    size="mini"
                    :placeholder="$t('filterComponents.end_date')"
                    @input="(value) => (rule.value2 = value)"
                  >
                  </el-date-picker>
                  <!-- Input -->
                  <el-input v-else size="mini" v-model="rule.value2" />
                </div>
                <!-- SECOND VALUE END -->
              </div>
            </div>
            <!-- CONDITION RULE END -->
          </div>
          <!-- Format START -->
          <div class="vis-col">
            <div
              v-if="
                selectedCondition.formatType ===
                conditionFormatType.FORMAT_VALUE
              "
              class="vis-row vis-flex--spacebeetwen"
            >
              <div class="vis-col vis-col-1_5">
                <label class="vis-form-label">{{
                  $t("conditionalFormatting.fontType")
                }}</label>
                <el-select
                  size="mini"
                  v-model="condition.format.fontFamily"
                  :placeholder="$t('conditionalFormatting.selectFontFamily')"
                >
                  <el-option
                    v-for="fontFamily in fontFamilies"
                    :key="fontFamily.value"
                    :label="fontFamily.label"
                    :value="fontFamily.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="vis-col vis-col-1">
                <label class="vis-form-label">{{
                  $t("conditionalFormatting.fontSize")
                }}</label>
                <el-select
                  size="mini"
                  v-model="condition.format.fontSize"
                  :placeholder="$t('conditionalFormatting.selectFontSize')"
                >
                  <el-option
                    v-for="fontSize in fontSizes"
                    :key="fontSize.value"
                    :label="fontSize.label"
                    :value="fontSize.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="vis-col vis-col-1_5">
                <label class="vis-form-label">{{
                  $t("conditionalFormatting.fontWeight")
                }}</label>
                <el-select
                  size="mini"
                  v-model="condition.format.fontWeight"
                  :placeholder="$t('conditionalFormatting.selectFontWeight')"
                >
                  <el-option
                    v-for="fontWeight in fontWeights"
                    :key="fontWeight.value"
                    :label="$t(fontWeight.label)"
                    :value="fontWeight.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="vis-col vis-col-1  vis-text--center">
                <label class="vis-form-label">{{
                  $t("conditionalFormatting.fontColor")
                }}</label>
                <el-color-picker
                  size="mini"
                  v-model="condition.format.fontColor"
                ></el-color-picker>
              </div>
              <div class="vis-col vis-col-1  vis-text--center">
                <label class="vis-form-label">{{
                  $t("conditionalFormatting.chipColor")
                }}</label>
                <el-color-picker
                  size="mini"
                  v-model="condition.format.chipColor"
                ></el-color-picker>
              </div>
              <div class="vis-col vis-col-1_5 vis-text--center">
                <label class="vis-form-label">{{
                  $t("conditionalFormatting.backgroundColor")
                }}</label>
                <el-color-picker
                  size="mini"
                  v-model="condition.format.backgroundColor"
                ></el-color-picker>
              </div>
              <div class="vis-col vis-col-2 vis-text--center" v-if="isShowConditionalRowFormatting">
                <label class="vis-form-label">{{
                  $t("conditionalFormatting.Row Background Color")
                }}</label>
                <el-color-picker
                  size="mini"
                  v-model="condition.format.rowBackgroundColor"
                ></el-color-picker>
              </div>
                <!--TEXT SHOW START-->
                <div class="vis-col vis-col-2">
                    <label class="vis-form-label">{{
                      $t("conditionalFormatting.Show")
                    }}</label>
                    <el-select
                      size="mini"
                      v-model="condition.format.valueShowOption"
                      :placeholder="$t('conditionalFormatting.Icon Size')"
                    >
                      <el-option
                        v-for="showOption in valueShowOptions"
                        :key="showOption.value"
                        :label="$t(showOption.label)"
                        :value="showOption.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div
                    v-if="
                    condition.format.valueShowOption === valueFormatShowType.TEXT_ONLY || 
                    condition.format.valueShowOption === valueFormatShowType.VALUE_AND_TEXT
                    "
                    class="vis-col vis-col-3"
                  >
                    <label class="vis-form-label">{{
                      $t("conditionalFormatting.Custom Text")
                    }}</label>
                    <el-input v-model="condition.format.customText"></el-input>
                  </div>
            </div>
            <ConditionalFormattingIconFormat
              v-else
              :condition="condition"
              @setIconFormat="setIconFormat"
            />
          </div>
          <!-- Format END -->
        </div>
      </div>
    </div>
    <!--FOOTER START-->
    <div class="vis-conditional-footer vis-flex--spacebeetwen">
      <el-button @click="addCondition()" type="text">{{
        $t("table.addCondition")
      }}</el-button>
      <div>
        <el-button @click="$emit('closePopup')" type="text">{{
          $t("generalPages.cancel")
        }}</el-button>
        <el-button
          @click="saveConditionalFormatting"
          type="primary"
          size="small"
          >{{ $t("generalPages.apply") }}</el-button
        >
      </div>
    </div>
    <!--FOOTER END-->
  </div>
</el-dialog>

  <!-- vis-conditional-card-end -->
</template>
<script>
import { mapActions } from "vuex";
import { ACTION as ACTION_TEST_QUERY } from "../../../store/modules/Visualize/TestQuery/types";
import cloneDeep from "clone-deep";
import { CustomIcon } from "../../../assets/js/custom-icons";
import { fontsArr, fontWeightsArr } from "../../../commons/dashboardProperties";
import { DatamodelContextDefaults } from "../../../commons/dataModelTypes";
import {
  attributeOptions,
  defaultConditionalFormattingData,
  defaultConditionData,
  fontSizes,
  measureOptions,
  conditionalOperatorValues,
  conditionFormatType,
  conditionFormatTypes,
  defaultIconFormatData,
  dateOptions,
  valueShowOptions,
  valueFormatShowType,
} from "./conditionalFormattingHelper";
import { v4 as uuidv4 } from "uuid";
import { chartTypes } from "../../../commons/dashboardAndPanel";
import { getPanelDataPropertiesStyle } from "../../../util/panelDataPropertiesStyle";
import { datatablePropertiesEnum } from "../../../commons/dashboardProperties";
import ConditionalFormattingIconFormat from "./ConditionalFormattingIconFormat.vue";
import ConditionalFormattingSelectBox from "./ConditionalFormattingSelectBox.vue";

export default {
  components: {
    ConditionalFormattingIconFormat,
    ConditionalFormattingSelectBox,
  },
  props: {
    selectedPanel: {
      type: Object,
    },
    selectedColumn: {
      type: Object,
    },
    isShowConditionalRowFormatting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formatType: conditionFormatType.FORMAT_VALUE,
      customIcon: CustomIcon,
      selectedCondition: {},
      fontFamilies: fontsArr,
      fontSizes: fontSizes,
      fontWeights: fontWeightsArr,
      selectedFieldWithSearch: {},
      searchOptions: {},
      condition: {},
      conditionFormatTypes: conditionFormatTypes,
      conditionFormatType: conditionFormatType,
      chartTypes: chartTypes,
      valueShowOptions: valueShowOptions,
      valueFormatShowType: valueFormatShowType,
    };
  },
  mounted() {
    this.getPreviouslySavedConditionFormatting();
  },
  computed: {
    selectedPanelColumns() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      if (this.selectedPanel.type === chartTypes.PIVOT_TABLE) {
        const selectedColumnFieldId = this.selectedColumn?.fieldId;
        return selectedPanelCloned?.details?.columns.filter((x) => {
          return (
            (x.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE &&
            x.fieldId === selectedColumnFieldId) || x.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE
          );
        });
      } else {
        return selectedPanelCloned?.details?.columns;
      }
    },
    selectedFieldId() {
      return this.selectedColumn.fieldId;
    },
    selectedSlotFieldId() {
      return this.selectedColumn?.slotFieldId;
    },
    selectedFieldAlias() {
      return this.selectedColumn.alias;
    },
    fieldOptions() {
      return this.selectedPanelColumns.map((x) => {
        let value =
          (x.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE &&
          getPanelDataPropertiesStyle(
            this.selectedPanel,
            datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
          )) || this.selectedPanel.type === chartTypes.PIVOT_TABLE
            ? x.field + x.type + x?.slotFieldId
            : x.field + x?.slotFieldId;

        return {
          field: x.field,
          fieldId: x.fieldId,
          value,
          label: x.alias,
          usageType: x.fieldUsageType,
          datasetId: x.datasetId,
          fieldType: x.fieldType,
          slotFieldId: x?.slotFieldId,
        };
      });
    },
    selectedConditions() {
      return this.selectedCondition?.conditions;
    },
    searchParams() {
      const { field, alias, fieldId, datasetId, fieldType } =
        this.selectedFieldWithSearch;
      return {
        filter: [],
        metric: [
          {
            field,
            type: DatamodelContextDefaults?.DISTINCT,
            alias,
            fieldId,
            datasetId,
            fieldType,
          },
        ],
        aggregation: [],
        params: [],
        dataset: { id: this.selectedFieldWithSearch.datasetId },
        vismetadata: {
          vistype: chartTypes?.OTHER,
        },
      };
    },
    mappedSearchOptions() {
      return this.selectedFieldWithSearch?.fieldId
        ? this.searchOptions?.[this.selectedFieldWithSearch?.fieldId]?.map(
            (s) => {
              return {
                value: s.value,
                label: s.value,
              };
            }
          )
        : [];
    },
  },
  methods: {
    ...mapActions({
      fetchSearchWithoutMutate: ACTION_TEST_QUERY.FETCH_SEARCH_WITHOUT_MUTATE,
    }),
    getPreviouslySavedConditionFormatting() {
      let hasCondition =
        this.selectedPanel?.properties?.conditionalFormattings?.find((x) =>
          this.selectedColumn?.slotFieldId
            ? x?.slotFieldId === this.selectedColumn?.slotFieldId
            : x.fieldId === this.selectedColumn.fieldId
        );

      if (hasCondition) {
        this.condition = hasCondition;
        this.condition.conditions.forEach((element) => {
          if (!element?.iconFormat) {
            element.iconFormat = defaultIconFormatData();
          }
        });
      }

      if (
        (this.selectedSlotFieldId !== undefined &&
          this.selectedSlotFieldId === this.condition?.slotFieldId) ||
        this.selectedFieldId === this.condition?.fieldId
      ) {
        this.selectedCondition = cloneDeep(this.condition);
      } else
        this.selectedCondition = defaultConditionalFormattingData(
          this.selectedFieldId,
          this.selectedSlotFieldId
        );
    },
    async getSearch() {
      const response = await this.fetchSearchWithoutMutate({
        loadingComponent: "GetSearch",
        bodyParam: this.searchParams,
      });

      this.$set(
        this.searchOptions,
        this.selectedFieldWithSearch.fieldId,
        response?.aggregations
      );
    },
    addRule(conditionId) {
      //BU KISIM İÇİN ÇALIŞMA YAPILACAK.
      const selectedRule = this.selectedCondition?.conditions.find(
        (x) => x.id === conditionId
      );
      selectedRule.rules.push({
        dataItem: "",
        operator: "",
        value1: null,
        value2: null,
      });
    },
    addCondition() {
      this.selectedCondition?.conditions.push(defaultConditionData());
    },
    valueControl(value){
      return value.replace(/[^0-9.]/g, '')
    },
    deleteCondition(conditionIndex) {
      this.selectedCondition?.conditions.splice(conditionIndex, 1);
    },
    duplicateCondition(condition) {
      const clonedCondition = cloneDeep(condition);
      clonedCondition.id = uuidv4();
      this.selectedCondition?.conditions.push(clonedCondition);
    },
    isSecondInputActive(rule) {
      return (
        rule.operator === conditionalOperatorValues.BETWEEN ||
        rule.operator === conditionalOperatorValues.NOT_BETWEEN
      );
    },
    getSelectedField(dataItem) {
      return this.fieldOptions?.find((x) => x.value === dataItem);
    },
    operatorOptions(dataItem) {
      const selectedField = this.getSelectedField(dataItem);

      if (!selectedField) return [];

      return selectedField?.usageType ===
        DatamodelContextDefaults.USAGE_TYPES.MEASURE
        ? measureOptions()
        : this.checkFieldUsageTypeAttribute(selectedField?.usageType)
        ? attributeOptions()
        : dateOptions();
    },
    checkDateFilterActive(dataItem) {
      const selectedField = this.getSelectedField(dataItem);

      if (!selectedField) return false;

      return this.checkFieldUsageTypeDate(selectedField?.usageType);
    },
    isSelectBoxActive(dataItem) {
      const selectedField = this.getSelectedField(dataItem);

      if (!selectedField) return false;

      return this.checkFieldUsageTypeAttribute(selectedField?.usageType);
    },
    changeDataItem(value, rule) {
      rule.dataItem = value;
      rule.value1 = "";
      rule.value2 = "";
      this.getSearchByFieldAndUsageType(rule);
    },
    changeOperator(value, rule) {
      rule.operator = value;
      if (this.isMultipleOperatorActive(rule.operator)) {
        rule.value1 = "";
        rule.value2 = "";
      }
    },
    addUsageTypeInRule(rule) {
      const selectedField = this.getSelectedField(rule?.dataItem);

      if (!rule?.usageType) rule.usageType = "";

      rule.usageType = selectedField?.usageType;
    },
    addValueByOperator(value, rule) {
      this.addUsageTypeInRule(rule);

      if (this.isMultipleOperatorActive(rule.operator)) {
        rule.value1 = Array(rule.value1);
        rule.value1 = value;
      } else {
        rule.value1 = value;
      }
    },
    getSearchByFieldAndUsageType(rule) {
      this.selectedFieldWithSearch = this.getSelectedField(rule?.dataItem);

      if (this.searchOptions[this.selectedFieldWithSearch.fieldId]) return;

      if (
        this.checkFieldUsageTypeAttribute(
          this.selectedFieldWithSearch.usageType
        )
      ) {
        this.getSearch();
      }
    },
    isMultipleOperatorActive(operator) {
      return (
        operator === conditionalOperatorValues.IN ||
        operator === conditionalOperatorValues.NOT_IN
      );
    },
    saveConditionalFormatting() {
      const deleteConditionalFormattingByFieldId = (
        selectedPanelCloned,
        fieldId,
        slotFieldId
      ) => {
        selectedPanelCloned.properties.conditionalFormattings =
          selectedPanelCloned?.properties?.conditionalFormattings?.filter((x) =>
            slotFieldId ? x?.slotFieldId != slotFieldId : x.fieldId != fieldId
          );
      };
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      let selectedCondition =
        selectedPanelCloned?.properties?.conditionalFormattings?.find((x) =>
          this.selectedCondition?.slotFieldId
            ? x.slotFieldId === this.selectedCondition.slotFieldId
            : x.fieldId === this.selectedCondition.fieldId
        );

      if (!selectedCondition) {
        if (!selectedPanelCloned?.properties?.conditionalFormattings?.length) {
          selectedPanelCloned.properties.conditionalFormattings = [];
        }
        selectedPanelCloned.properties.conditionalFormattings.push(
          this.selectedCondition
        );
      } else {
        deleteConditionalFormattingByFieldId(
          selectedPanelCloned,
          this.selectedCondition.fieldId,
          this.selectedCondition?.slotFieldId
        );
        selectedPanelCloned.properties.conditionalFormattings.push(
          this.selectedCondition
        );
      }

      this.$emit("updateSelectedPanel", { value: selectedPanelCloned });
      this.$emit("closePopup");
    },
    checkFieldUsageTypeAttribute(usageType) {
      return usageType === DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE;
    },
    checkFieldUsageTypeDate(usageType) {
      return usageType === DatamodelContextDefaults.USAGE_TYPES.DATE;
    },
    setIconFormat(param) {
      this.selectedCondition.conditions.forEach((condition) => {
        if (condition.id === param.id) condition.iconFormat = param.iconFormat;
      });
    },
  },
};
</script>
<style scoped>
.vis-conditional-card {
  width: 100%;
  border: 1px solid var(--layout-border-color);
  box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.019);
  background-color: #ffffff;
}
.vis-conditional-title {
  position: relative;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  font-weight: 500;
  border-bottom: 2px solid var(--primary-lighteen-1);
}
.vis-conditional-content {
  position: relative;
  width: 100%;
  padding: 20px 20px 0 20px;
  max-height: 530px;
  overflow: auto;
}
.vis-conditional-condition {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  background-color: #f6f8f9;
  padding: 10px;
  border-radius: 7px;
}
::v-deep .vis-conditional-condition .el-select__tags {
  flex-wrap: inherit !important;
}
.vis-condition-row {
  position: relative;
  width: 100%;
  margin: 1rem 0;
  display: flex;
}
.vis-col {
  padding: 0 5px;
}
.vis-condition-vertical-line::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 100%;
  background-color: #e7e7e7;
}

.vis-conditional-footer {
  position: relative;
  width: 100%;
  padding: 20px;
}

::v-deep .el-dialog__header {
  display: none !important;
}

::v-deep .el-dialog__body {
  padding: 0 !important;
}
::v-deep .vis-el-select-not-with {
  width: auto !important;
}

</style>
