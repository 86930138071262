<template>
  <div>
    <div class="vis-temporary-filters-popup">
      <div
        class="vis-temporary-filters-popup-header vis-flex--spacebeetwen-Ycenter"
      >
        <h3>{{ $t("generalPages.defineFilters") }}</h3>
        <i
          @click="$emit('close')"
          class="vis-cursor-pointer"
          :class="customIcon.Close"
          aria-hidden="true"
        />
      </div>

      <div class="vis-temporary-filters-popup-body">
        <vue-good-table
          :columns="columns"
          :rows="mappedItems"
          max-height="340px"
          :fixed-header="true"
        >
          <template slot="table-column" slot-scope="props">
            <span>
              {{ $t(`${props.column.label}`) }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === columnFields.value">
              <el-date-picker
                v-if="isDateType(props.formattedRow[columnFields.filterName])"
                :value="
                  getValueOfRow(props.formattedRow[columnFields.filterName])
                "
                @input="
                  (val) =>
                    setValueOfRow(
                      props.formattedRow[columnFields.filterName],
                      val
                    )
                "
                format="dd/MM/yyyy"
                value-format="dd-MM-yyyy"
              ></el-date-picker>
              <el-input
                v-else
                :value="
                  getValueOfRow(props.formattedRow[columnFields.filterName])
                "
                @input="
                  (val) =>
                    setValueOfRow(
                      props.formattedRow[columnFields.filterName],
                      val
                    )
                "
              />
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <div
          class="vis-row-box vis-text--right vis-temporary-filters-popup-run"
        >
          <el-button @click="reset" size="small">{{
            $t("generalPages.reset")
          }}</el-button>
          <el-button @click="fetchSql" type="primary" size="small">{{
            $t("generalPages.run")
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  TEMP_STORAGE_KEYS,
  GETTER as GETTER_TEMP_STORAGE,
  MUTATION as MUTATION_TEMP_STORAGE,
} from "../../store/modules/temp-storage/types";
import { VueGoodTable } from "vue-good-table";
import { Notification } from "element-ui";
import { CustomIcon } from "../../assets/js/custom-icons";
import _ from "lodash";
import { filterSources } from "./sql/types";

export default {
  components: { VueGoodTable },
  props: {
    filterParams: {
      type: Array,
    },
    items: {
      type: Array,
      default: () => [],
    },
    sql: {
      type: String,
      default: "",
    },
    parseToSql: {
      type: Boolean,
      default: false,
    },
    updateLocalStorage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columnFields: {
        filterName: "filterName",
        value: "value",
      },
      clonedTempRunSqlFilters: null,
      clonedSql: "",
      customIcon: CustomIcon,
      datasetId: null,
      datamodelId: null,
    };
  },
  mounted() {
    this.datasetId = this.filterParams?.find(
      (f) => f.filterSource === filterSources.DATASET
    )?.objectId;
    this.datamodelId = this.filterParams?.find(
      (f) => f.filterSource === filterSources.DATAMODEL
    )?.objectId;

    this.init();
  },
  computed: {
    ...mapGetters({
      tempStorageBykey: GETTER_TEMP_STORAGE.GET_TEMP_STORAGE_BY_KEY,
    }),
    mappedItems() {
      return Array.from(new Set(this.items)).map((i) => ({
        filterName: i,
        value: "",
      }));
    },
    columns() {
      return [
        {
          label: "generalPages.filterName",
          field: this.columnFields.filterName,
        },
        {
          label: "generalPages.value",
          field: this.columnFields.value,
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      resetTempStorageByKey: MUTATION_TEMP_STORAGE.RESET_TEMP_STORAGE_BY_KEY,
      setTempStorageByKey: MUTATION_TEMP_STORAGE.SET_TEMP_STORAGE_BY_KEY,
    }),
    init() {
      const uiValues = {};

      const setUIValues = (objectId) => {
        const localStorageValues = JSON.parse(
          localStorage.getItem(`customFilterParams.objectId-${objectId}`)
        );

        if (localStorageValues) {
          localStorageValues.forEach((element) => {
            if (element)
              uiValues[`:${element.name}`] = element.localStorageValue;
          });
        }
      };

      setUIValues(this.datasetId);
      setUIValues(this.datamodelId);

      this.clonedSql = this.sql;
      this.clonedTempRunSqlFilters = uiValues;

      if (!Object.keys(this.clonedTempRunSqlFilters).length) {
        this.mappedItems.forEach((m) => {
          this.$set(this.clonedTempRunSqlFilters, m.filterName, "");
        });
      }
    },
    fetchSql() {
      const checkHasItemWithMandatoryAndEmpty = () => {
        let hasItemWithMandatoryAndEmpty = false;

        this.filterParams?.forEach((f) => {
          const valueOfFilterParam =
            this.clonedTempRunSqlFilters?.[`:${f.name}`];

          if (f.mandatory && valueOfFilterParam === "") {
            hasItemWithMandatoryAndEmpty = true;
          }
        });

        return hasItemWithMandatoryAndEmpty;
      };

      if (checkHasItemWithMandatoryAndEmpty()) {
        Notification({
          title: "Warning",
          type: "warning",
          message: "You should define value for all filters.",
          data: "",
          duration: 3000,
          customClass: "vis-notification",
        });
      } else {
        this.setTempStorageByKey({
          key: TEMP_STORAGE_KEYS.TEMP_RUN_SQL_FILTERS_POPUP,
          value: this.clonedTempRunSqlFilters,
        });

        if (this.parseToSql) {
          Object.keys(this.clonedTempRunSqlFilters)
            .sort((a, b) => b.length - a.length) // Sort keys by length (longer keys first)
            .forEach((key) => {
              this.clonedSql = this.clonedSql.replaceAll(
                key,
                `'${this.clonedTempRunSqlFilters[key]}'`
              );
            });

          this.$emit("runClicked", this.clonedSql);
        } else {
          const filterParams = [];

          Object.keys(this.clonedTempRunSqlFilters).forEach((k) => {
            filterParams.push({
              filterParamId: k,
              value: this.clonedTempRunSqlFilters[k], // no need to add single quote for preview endpoint
            });
          });

          this.$emit("runClicked", filterParams);
        }

        if (this.updateLocalStorage) {
          this.updateCustomFilterParams();
        }

        this.$emit("close");
      }
    },
    updateCustomFilterParams() {
      //getting params as this format [{"key":"operasyon_turu","value":"fds"}]
      const params = this.tempStorageBykey(
        TEMP_STORAGE_KEYS.TEMP_RUN_SQL_FILTERS_POPUP
      );

      const result = [];

      Object.entries(params).forEach(([key, value]) => {
        result.push({
          key: key.substring(1),
          value,
        });
      });

      const newCustomFilterParamsWithValues = result.map((p) => {
        const found = this.filterParams.find((t) => t.name === p.key);

        if (found) {
          return {
            ...found,
            localStorageValue: p.value,
          };
        }
      });

      const groupedByObjectId = _.groupBy(
        newCustomFilterParamsWithValues,
        "objectId"
      );

      Object.entries(groupedByObjectId).forEach(([objectId, params]) => {
        localStorage.setItem(
          `customFilterParams.objectId-${objectId}`,
          JSON.stringify(params)
        );
      });
    },
    getValueOfRow(filterName) {
      return this.clonedTempRunSqlFilters?.[filterName] ?? "";
    },
    setValueOfRow(filterName, value) {
      this.$set(this.clonedTempRunSqlFilters, filterName, value);
    },
    reset() {
      this.resetTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_RUN_SQL_FILTERS_POPUP,
        value: {},
      });

      this.init();
    },
    isDateType(filterName) {
      const filterParam = this.filterParams?.find(
        (param) => `:${param.name}` === filterName?.replace(/_start$|_end$/, "")
      );
      return filterParam && filterParam.type === "DATE" && !this.parseToSql;
    },
  },
};
</script>

<style scoped>
.vis-temporary-filters-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  height: 450px;
  background-color: #ffffff;
  box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.019);
  border: 1px solid var(--layout-border-color);
  border-radius: 4px;
  z-index: 10;
}

.vis-temporary-filters-popup-header {
  position: relative;
  width: 100%;
  height: 40px;
  padding: 0 24px;
}

.vis-temporary-filters-popup-body {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
}
.vis-temporary-filters-popup-run {
  margin-top: auto;
  padding: 1rem;
}
</style>
