<template>
  <div
    class="vis-control-section vis-text-editor vis-scroll-thin vis-flex-direction--column"
    :class="{ 'vis-non-edit': !editMode }"
  >
    <div
      class="vis-text-editor"
      :class="{
        'vis-non-editable-content': !editMode || dataFromDatabase,
        'popup-open': isPopupOpen,
        'vis-edit-content': editMode,
        'vis-datafromdatabase': dataFromDatabase,
      }"
    >
      <ejs-richtexteditor
        ref="rteObj"
        id="richTextEditor"
        :value="richText"
        :change="changeRichText"
        :toolbarSettings="toolbarSettings"
        :showCharCount="false"
        :quickToolbarSettings="quickToolbarSettings"
        :enableTabKey="true"
        :placeholder="placeholder"
        :enableXhtml="true"
        :insertImageSettings="imageSettings"
        :beforeImageUpload="beforeImageUpload"
        :imageSelected="imageSelected"
        :disabled="!editMode"
        :fontFamily="fontFamily"
        :dialogOpen="onDialogOpen"
        :dialogClose="onDialogClose"
      ></ejs-richtexteditor>
      <ejs-dropdownbutton
        v-if="editMode"
        id="insertField"
        :items="fieldAlias"
        :content="dropdownContent"
        cssClass="e-rte-dropdown-btn e-rte-dropdown-popup e-rte-dropdown-items e-rte-elements e-tbar-btn"
        @select="onItemSelect($event)"
      ></ejs-dropdownbutton>
      <el-color-picker
        v-if="editMode"
        id="richTextTableBorderColorPicker"
        v-model="selectedColor"
        size="small"
        @change="onTableColorChange"
      ></el-color-picker>
    </div>
    <div
      class="vis-chart-placeHolder"
      v-if="
        (!panelData.details.metrics.length && dataFromDatabase && editMode) ||
        (!editMode && !richText)
      "
    >
      <img
        src="../../../assets/images/chartEmptyStates/BAR_CHART.svg"
        alt="TEXT_EDITOR"
      />
    </div>
  </div>
</template>

<script>
import {
  RichTextEditorComponent,
  Toolbar,
  Link,
  Image,
  Count,
  HtmlEditor,
  QuickToolbar,
  Table,
  EmojiPicker,
  FormatPainter,
  PasteCleanup,
  List,
} from "@syncfusion/ej2-vue-richtexteditor";
import { CustomIcon } from "../../../assets/js/custom-icons";
import cloneDeep from "clone-deep";
import { Notify } from "../../../commons/helper";
import { notificationType } from "../../../commons/notificationTypes";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
import { DropDownButtonComponent } from "@syncfusion/ej2-vue-splitbuttons";
import { detailsKeys } from "../../../commons/dashboardAndPanel";
import { formatMeasure } from "../../panel/measures/js/formatMeasures";

export default {
  components: {
    "ejs-richtexteditor": RichTextEditorComponent,
    "ejs-dropdownbutton": DropDownButtonComponent,
  },
  watch: {
    dataFromDatabase: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;

        this.changeRichText({ value: "" });
      },
    },
    textEditorShowImage: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;

        this.$refs?.rteObj?.refreshUI();
      },
    },
    "panelData.details.metrics": {
      handler(newVal) {
        if (newVal?.length > 1 && this.dataFromDatabase) {
          const selectedPanelCloned = cloneDeep(this.panelData);
          selectedPanelCloned.details[detailsKeys.METRICS].shift();
          this.updateSelectedPanel(selectedPanelCloned);
        }
      }
    }
  },
  props: {
    panelData: {
      type: Object,
      default() {
        return {};
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    option: {
      type: Object,
      default() {
        return {};
      },
    },
    replacedValueInDashboard: {
      type: String,
      default: null,
    },
  },
  mixins: [panelDataPropertiesStyle],
  data() {
    return {
      toolbarSettings: {
        items: [
          "Undo",
          "Redo",
          "|",
          { tooltipText: `${this.$t('Insert Field')}`, template: "#insertField" },
          "Bold",
          "|",
          "Italic",
          "Underline",
          "StrikeThrough",
          "SuperScript",
          "SubScript",
          "|",
          "FontName",
          "FontSize",
          "FontColor",
          "BackgroundColor",
          "|",
          "LowerCase",
          "UpperCase",
          "|",
          "CreateTable",
          {
            tooltipText: `${this.$t('Table Border Color')}`,
            template: "#richTextTableBorderColorPicker",
          },
          "|",
          "Formats",
          "Alignments",
          "|",
          "NumberFormatList",
          "BulletFormatList",
          "|",
          "Outdent",
          "Indent",
          "FormatPainter",
          "ClearFormat",
          "|",
          this.textEditorShowImage ? "Image" : null,
          "CreateLink",
          "EmojiPicker",
        ].filter((f) => f),
        enable: this.editMode,
      },
      selectedColor: "#000000",
      customIcon: CustomIcon,
      quickToolbarSettings: {
        table: [
          "TableHeader",
          "TableRows",
          "TableColumns",
          "TableCell",
          "-",
          "BackgroundColor",
          "TableRemove",
          "TableCellVerticalAlign",
          "Styles",
        ],
        showOnRightClick: true,
      },
      imageSettings: {
        saveFormat: "Base64",
      },
      isPopupOpen: false,
      fontFamily: {
        default: "Poppins",
        items: [
          { text: "Poppins", value: "Poppins, sans-serif" },
          { text: "Arial", value: "Arial,Helvetica,sans-serif" },
          { text: "Verdana", value: "Verdana,Geneva,sans-serif" },
          { text: "Times New Roman", value: "Times New Roman, Times, serif" },
          { text: "Calibri", value: "Calibri, sans-serif" },
        ],
      },
      dropdownContent: ` <span style="display:inline-flex;">
    <span class="e-rte-dropdown-btn-text">${this.$t('Insert Field')}</span>
  </span>`,
    };
  },
  updated() {
    this.hideStuckTooltips();
  },
  mounted() {
    this.setColorPickerSelectedValue();

    setTimeout(() => {
      this.$refs?.rteObj?.refreshUI();
    }, 500);
  },
  computed: {
    dataFromDatabase() {
      return this.CHART_COMPUTED_VALUES?.TEXT_EDITOR_DATA_FROM_DATABASE;
    },
    textEditorShowImage() {
      return this.CHART_COMPUTED_VALUES?.TEXT_EDITOR_SHOW_IMAGE;
    },
    fieldAlias() {
      return (
        this.panelDataDetailsMetrics?.map((m) => {
          return {
            text: m.alias,
          };
        }) ?? []
      );
    },
    richText() {
      if (!this.dataFromDatabase) {
        if (!this.option?.response?.length) return this.rawRichText;

        const result = cloneDeep(this.option?.response);
        const clonedRawRichText = cloneDeep(this.rawRichText);

        return result.reduce((text, item) => {
          const formattedValue = formatMeasure(item?.value, item?.format);
          
          return text?.replaceAll(
            `[${item.alias}]`,
            formattedValue ?? `[${item.alias}]`
          ); // alias'ları replace ediyoruz
        }, clonedRawRichText);
      } else {
        if (!this.option || !this.panelDataDetailsMetrics?.length) return "";
        const newContent = Array.isArray(this.option.response)
          ? this.extractHtmlContent(this.option.response)
          : "";

        return newContent;
      }
    },
    rawRichText() {
      return (
        this.replacedValueInDashboard ??
        this.panelData?.properties.style.RICH_TEXT
      );
    },
    placeholder() {
      return !this.dataFromDatabase && this.editMode
        ? `<div><h2>${this.$t("dashboard.Edit Text")}</h2>
                <div>
                  <br/>
                </div>
              </div>`
        : "";
    },
  },
  methods: {
    setColorPickerSelectedValue() {
      const tableTds = document.querySelectorAll(".e-rte-table td");

      if (!this.editMode || !tableTds?.length) return;

      this.selectedColor = tableTds?.[0]?.style?.borderColor;
    },
    onTableColorChange(value) {
      const tableTds = document.querySelectorAll(".e-rte-table td");

      if (!this.editMode || !tableTds?.length) return;

      tableTds.forEach((td) => {
        td.style.borderColor = value;
      });
    },
    onItemSelect: function (args) {
      const value = args?.item?.text;

      if (value && this.$refs.rteObj && this.$refs.rteObj.ej2Instances) {
        this.$refs.rteObj.ej2Instances.executeCommand(
          "insertHTML",
          `<span contenteditable="true" class="e-mention-chip" id="${value}">[${value}]</span>`,
          { undo: true }
        );
      }
    },
    updateSelectedPanel(value) {
      this.$emit("updateSelectedPanel", {
        value,
      });
    },
    changeRichText(event) {
      const selectedPanelCloned = cloneDeep(this.panelData);

      // HTML içeriğini bir elemente set edelim (örneğin bir div)
      const div = document.createElement("div");
      div.innerHTML = event?.value;

      // `e-mention-chip` class'ına sahip tüm öğeleri seçiyoruz
      const mentionChips = div.querySelectorAll(".e-mention-chip");

      // Her bir öğeyi dolaşarak, `innerText`i `id`(field alias) ile güncelliyoruz
      //`[${element.id}] => slot içindeki field aliası. onItemSelect fonksiyonunda ekliyoruz
      //Amaç dinamik olarak eklediğimiz (örn: [Country]) editor içinde tutmak.
      mentionChips?.forEach((element) => {
        if (
          element.innerText !== `[${element.id}]` &&
          element.innerText !== ""
        ) {
          const replacedHTML = element.innerHTML.replace(
            element.innerText.trim(),
            `[${element.id}]`
          );

          element.innerHTML = replacedHTML;
        } else if (element.innerText.trim() === "") {
          element.remove();
        }
      });

      this.$set(
        selectedPanelCloned.properties.style,
        "RICH_TEXT",
        div.innerHTML
      );

      this.updateSelectedPanel(selectedPanelCloned);
    },
    beforeImageUpload(args) {
      const maxImageCount =
        this.CHART_COMPUTED_VALUES?.TEXT_EDITOR_MAX_IMAGE_COUNT || 0;
      const imageElements =
        this.$refs?.rteObj?.ej2Instances?.element.querySelectorAll("img");

      if (imageElements.length >= maxImageCount) {
        args.cancel = true;
        Notify(
          this.$t(
            "textEditor.You cannot exceed the maximum number of images you can add!"
          ),
          notificationType.WARNING
        );
        const dialog =
          this.$refs?.rteObj?.ej2Instances?.element.querySelector(".e-dialog");

        if (dialog && dialog?.ej2_instances[0]) {
          dialog?.ej2_instances[0].close();
        }
        return;
      }
    },
    imageSelected(args) {
      const file = args?.filesData?.[0]?.rawFile;
      const maxFileSize =
        this.CHART_COMPUTED_VALUES?.TEXT_EDITOR_MAX_IMAGE_SIZE * 1024;

      if (file?.size > maxFileSize) {
        args.cancel = true;
        Notify(
          this.$t(
            "textEditor.You cannot exceed the maximum image size you can add!"
          ),
          notificationType.WARNING
        );

        const dialog =
          this.$refs.rteObj.ej2Instances.element.querySelector(".e-dialog");
        if (dialog && dialog.ej2_instances[0]) {
          dialog.ej2_instances[0].close();
        }
        return;
      }
    },
    onDialogOpen() {
      this.isPopupOpen = true;
    },
    onDialogClose() {
      this.isPopupOpen = false;
    },
    hideStuckTooltips() {
      const tooltips = document?.querySelectorAll(".e-tooltip-wrap");
      tooltips.forEach((tooltip) => {
        if (tooltip.style.display !== "none") {
          tooltip.style.display = "none";
        }
      });
    },
    extractHtmlContent(datas) {
      let combinedHtml = "";
      datas.forEach((data) => {
        combinedHtml += `<div class="${data?.field}">${data?.value}</div>`;
      });
      return combinedHtml;
    },
  },
  provide: {
    richtexteditor: [
      Toolbar,
      Link,
      Image,
      Count,
      HtmlEditor,
      QuickToolbar,
      Table,
      EmojiPicker,
      FormatPainter,
      PasteCleanup,
      List,
    ],
  },
};
</script>
<style scoped>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";

::v-deep .vis-datafromdatabase .e-toolbar-wrapper,
::v-deep .vis-datafromdatabase .rte-placeholder {
  display: none !important;
}

::v-deep
  .e-richtexteditor
  .e-rte-content
  .rte-placeholder.e-rte-placeholder.enabled {
  display: block;
  font-size: 8px;
}
::v-deep .e-richtexteditor .rte-placeholder {
  top: -10px;
  left: 0px;
}

::v-deep ul li {
  list-style: inherit !important;
  margin-left: 2px !important;
}

::v-deep .sb-header {
  z-index: 100;
}

::v-deep .sb-content.e-view.hide-header {
  top: 0 !important;
}

::v-deep .sb-header.e-view.hide-header,
::v-deep .e-upload:has(#rteCustomWordUpload),
::v-deep .vis-non-editable-content .e-toolbar-wrapper {
  display: none;
}

::v-deep .non-clickable {
  pointer-events: none;
}

::v-deep .vis-non-editable-content,
::v-deep .vis-control-section.vis-non-edit .e-rte-content,
::v-deep .e-rte-container,
::v-deep .e-richtexteditor.e-rte-tb-expand,
::v-deep .e-richtexteditor.e-rte-tb-expand .e-rte-content {
  border: none;
}

::v-deep .vis-control-section.vis-non-edit .e-toolbar-wrapper,
::v-deep .vis-control-section.vis-non-edit .e-toolbar-container, {
  border-bottom: none;
}

::v-deep .e-tooltip .null {
  border-top: none !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}

::v-deep .vis-control-section.vis-non-edit .e-rte-content .e-content {
  max-height: 100%;
}

::v-deep .e-richtexteditor .e-rte-content,
::v-deep .e-richtexteditor {
  background-color: transparent !important;
}

::v-deep .vis-edit-content .e-richtexteditor .e-rte-content .e-content {
  max-height: 720px;
  overflow: auto;
  scrollbar-width: thin;
}

::v-deep .e-richtexteditor .e-rte-content .e-content {
  padding-top: 0;
}

::v-deep .vis-text-editor {
  position: relative;
  height: auto;
  max-height: 100%;
  z-index: 0;
}

::v-deep .e-disabled {
  opacity: initial !important;
}

::v-deep .e-richtexteditor .e-rte-content,
::v-deep .e-richtexteditor .e-source-content {
  overflow: visible;
}

::v-deep .popup-open {
  height: 100vh;
  overflow: auto;
}
</style>
