<template>
  <div class="vis-col vis-col-12 vis-schedule-content vis-scroll-thin">
    <div class="vis-mb--1 vis-ml--1">
      <span>
        {{
          $t(
            "schedule.Select filter values for selected content, only the filters that may affect selected content will be shown"
          )
        }}
      </span>
    </div>
    <div v-for="tab in dashboardTabs" :key="tab.id">
      <div v-if="checkTabInHasFilterChart(tab)">
        <div class="vis-mb--1 vis-ml--1">
          <h3>{{ tab.name }}</h3>
        </div>
        <div class="vis-row">
          <div
            v-for="column in scheduleFilterColumns"
            :key="column.label"
            class="vis-font-medium"
            :class="column.class"
          >
            {{ $t(`${column.label}`) }}
          </div>
        </div>
      </div>
      <div v-for="chart in mappedFilterChartList[tab.id]" :key="chart.i">
        <div class="vis-row" :class="{'align-items-center': !chart.isSelectionChartFilter}">
          <div class="vis-col vis-col-3">
            <div class="vis-ellipsis-line-2">{{ getChartAliasBySubstring(chart.alias) }} {{ chart.chartType ?? '' }}</div>
          </div>
          <div class="vis-col vis-col-2">
            <div class="mandatory-text-box">
              <span>{{ $t(`${chart.mandatoryText}`) }}</span>
            </div>
          </div>
          <div
            v-if="!getScheduleFilterIsDynamic(chart)"
            class="vis-col vis-col-5 vis-text--right"
          >
            <FilterDate
              v-if="chart.type === dashboardFilterTypes.FILTER_DATE"
              :panelData="chart"
              margin=""
              :scheduleFilterValue="getScheduleFilterValue(chart)"
              :filterOnMount="true"
              @addFiltersToPanels="
                (param) => addFiltersToSchedule(param, chart)
              "
            />
            <FilterText
              v-else-if="chart.type === dashboardFilterTypes.FILTER_TEXT"
              :panelData="chart"
              margin=""
              :scheduleFilterValue="getScheduleFilterValue(chart)"
              :filterOnMount="true"
              @addFiltersToPanels="
                (param) => addFiltersToSchedule(param, chart)
              "
            />
            <FilterDropdown
              v-else-if="isChartTypeSelect(chart.type)"
              :panelData="chart"
              margin=""
              :option="options[chart.i]"
              :scheduleFilterValue="getScheduleFilterValue(chart)"
              :applyToTabsKey="
                chart.type === dashboardFilterTypes.FILTER_DROPDOWN
                  ? 'dropdownFilterApplyToTabs'
                  : 'selectListFilterApplyToTabs'
              "
              :isChartTypeFilterDropdown="chart.type === dashboardFilterTypes.FILTER_DROPDOWN"
              @addFiltersToPanels="
                (param) => addFiltersToSchedule(param, chart)
              "
            />
            <FilterChart
              v-else
              :option="options[chart.i]"
              :panelData="chart"
              :isFilterPaneActive="true"
              :showAlias="false"
              filterChartMainClass="vis-text--left vis-display-block vis-chart-filter"
              margin="vis-mb--1"
            />
          </div>
          <div v-else class="vis-col vis-col-5 vis-text--right">
            <!-- <div v-if="chart.isDateFilterMultipleSelection" class="vis-row">
              <div class="vis-col vis-col-6 vis-pl--none vis-mb--none">
                <el-select :value="''" class="dynamic-field-select">
                  <el-option
                    v-for="option in selectableDynamicContentFields"
                    :key="option.fieldId"
                    :label="option.name"
                    :value="option.fieldId"
                  ></el-option>
                </el-select>
              </div>
              <div class="vis-col vis-col-6 vis-pr--none vis-mb--none">
                <el-select :value="''" class="dynamic-field-select">
                  <el-option
                    v-for="option in selectableDynamicContentFields"
                    :key="option.fieldId"
                    :label="option.name"
                    :value="option.fieldId"
                  ></el-option>
                </el-select>
              </div>
            </div> -->
            <div class="vis-row">
              <div class="vis-col vis-col-12 vis-pa--none vis-mb--none">
                <el-select
                  :value="getScheduleFilterDynamicField(chart)"
                  class="dynamic-field-select"
                  @change="(val) => handleChangeDynamicField(chart, val)"
                  filterable
                >
                  <el-option
                    v-for="option in selectableDynamicContentFields"
                    :key="option.name"
                    :label="option.name"
                    :value="option.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div
            v-if="isDynamicAvailable && !chart.isDateFilterMultipleSelection"
            class="vis-col vis-col-2 vis-text--center"
          >
            <!-- TODO: update this, use the values from remote data -->
            <el-switch
              :value="getScheduleFilterIsDynamic(chart)"
              @change="(val) => handleChangeIsDynamic(chart, val)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  chartList,
  dashboardFilterTypes,
  panelTypes,
} from "../../../commons/dashboardAndPanel";
import { ACTION as ACTION_TEST_QUERY } from "../../../store/modules/Visualize/TestQuery/types";
import { mapActions } from "vuex";
import { getSearchBodyParam } from "../../../util/chartSearchServices";
import FilterDate from "../../dashboard/chart/FilterDate.vue";
import FilterText from "../../dashboard/chart/FilterText.vue";
import FilterDropdown from "../../dashboard/chart/FilterDropdown.vue";
import cloneDeep from "clone-deep";
import { createFilterObj } from "../../../util/dashboard-filter/dashboardFilter";
import {
  filterType,
  panelDataDetailsKey,
} from "../../../commons/filterComponents";
import {
  DATE_FILTER_PROPERTIES_ENUM,
  selectBoxOption,
} from "../../../commons/dashboardProperties";
import { messageDefaultValue } from "./schedule";
import FilterChart from "../../dashboard/chart/FilterChart.vue";

export default {
  components: { FilterDate, FilterText, FilterDropdown, FilterChart },
  props: {
    scheduleContentMessage: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedProject: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectableDynamicContentFields: {
      type: Array,
      default: () => [],
    },
    isDynamicAvailable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dashboardFilterTypes: dashboardFilterTypes,
      options: {},
      messageUpdated: messageDefaultValue,
      mappedFilterChartList: {},
      mandatoryData: {
        mandatoryChartsI: new Set(),
      },
    };
  },
  mounted() {
    this.messageUpdated = cloneDeep(this.scheduleContentMessage);
    
    const mapFilterChartList = (charts) => {
      let filterChartList = [];
      charts
        .filter(
          (c) =>
            c.type?.toLowerCase().includes(panelTypes.FILTER) &&
            c?.details?.metrics?.length
        )
        .forEach((chart) => {
          const data = chart?.details?.metrics?.[0];
          const chartsMandatoryInProperties =
            this.getPanelDataPropertiesStyleByKey(
              chart?.properties?.style,
              "MANDATORY_SELECTION"
            );
          const dateFilterMultipleSelectionInProperties =
            this.getPanelDataPropertiesStyleByKey(
              chart?.properties?.style,
              DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_ALLOW_MULTISELECT
            );

          const fieldItem = chart?.details?.metrics[0];
          const filterKey =
            fieldItem.filterType === filterType.CUSTOM
              ? panelDataDetailsKey.PARAMS
              : panelDataDetailsKey.FILTER;

          filterChartList.push({
            alias: data.alias,
            field: data.field,
            fieldId: data.fieldId,
            mandatoryText:
              data?.mandatory || chartsMandatoryInProperties
                ? "generalPages.yes"
                : "generalPages.no",
            isShow: this.checkFilterChartIsUsedForSelectedTable(chart),
            isMandatory: data?.mandatory || chartsMandatoryInProperties,
            isDateFilterMultipleSelection:
              dateFilterMultipleSelectionInProperties,
            ...chart,
            filterKey: filterKey,
          });
        });

      return filterChartList.filter((x) => x.isShow);
    };

    const mapChartList = (charts) => {
      let newChartList = [];
      
      this.getSelectionChartList(charts).forEach(chart => {

        const chartName = chartList.find(x=> x.id === chart.type);

        newChartList.push({
            alias: `${chart.name ?? this.$t('generalPages.No Title')}`,
            chartType: `(${this.$t(chartName.name)} ${this.$t('generalPages.Chart')})`,
            mandatoryText: "generalPages.no",
            isShow: true,
            filterKey: panelDataDetailsKey.FILTER,
            isSelectionChartFilter: true,
            ...chart,
          })
      });
      
      return newChartList;
    }
    
    this.dashboardTabs.forEach((tab) => {
      this.$set(this.mappedFilterChartList, tab.id, [...mapFilterChartList(tab?.charts), ...mapChartList(tab?.charts)])
      this.createMandatoryDataWithCharts(this.mappedFilterChartList[tab.id]);
      //this.removeUnusedFilter(this.mappedFilterChartList);
    });
    
    this.fetchSearch();
  },
  computed: {
    scheduleFilterColumns() {
      return [
        {
          label: "generalPages.dataItems",
          class: "vis-col vis-col-3",
          show: true,
        },
        {
          label: "schedule.Mandatory",
          class: "vis-col vis-col-2",
          show: true,
        },
        {
          label: "generalPages.value",
          class: "vis-col vis-col-5",
          show: true,
        },
        {
          label: "schedule.Dynamic",
          class: "vis-col vis-col-2 vis-text--center",
          show: this.isDynamicAvailable,
        },
      ].filter((x) => x.show);
    },
    dashboardTabs() {
      return this.selectedProject?.tabs;
    },
    tabIdsForSelectedContent() {
      const mappedTabIds = this.messageUpdated.chartData?.map((x) => x.tabId);
      return [...new Set(mappedTabIds)];
    }
  },

  methods: {
    ...mapActions({
      fetchSearchWithoutMutate: ACTION_TEST_QUERY.FETCH_SEARCH_WITHOUT_MUTATE,
    }),
    getSelectionChartList(charts) {
      //FOR DASHBOARD SCHEDULE POPUP
      return charts?.filter((f) => !f.type?.toLowerCase().includes(panelTypes.FILTER) && f.details?.FILTER_VALUE !== '' && f.details?.FILTER_VALUE);
    },
    getChartAliasBySubstring(alias) {
      const threeDot = alias?.length >= 55 ? "..." : "";
      
      return alias.substring(0, 55) + threeDot;
    },
    selectedFilterByTab(chart) {
      const filteredByTab = this.messageUpdated.chartData?.find(
        (x) => x.uiTabId === chart.properties.uiTabId && x?.chartId !== chart?.id
      );
      const foundFilter = filteredByTab?.filter?.find(
        (f) => f.filterId === chart.i
      );
      const foundParam = filteredByTab?.params?.find(
        (f) => f.filterId === chart.i
      );
      
      if (foundFilter) {
        return foundFilter;
      }

      return foundParam;
    },
    selectedFiltersByTab(chart) {
      const filteredByTab = this.messageUpdated.chartData?.filter(
        (x) => x.uiTabId === chart.properties.uiTabId
      );
      const all = [];

      filteredByTab?.forEach((tab) => {
        const foundFilter = tab?.filter?.find(
          (f) => f.filterId === chart.i
        );
        const foundParam = tab?.params?.find(
          (f) => f.filterId === chart.i
        );

        if (foundFilter) {
          all.push(foundFilter);
        }

        if (foundParam) {
          all.push(foundParam);
        }
      });

      return all;
    },
    handleChangeDynamicField(chart, dynamicField) {
      const selectedFiltersByTab = this.selectedFiltersByTab(chart); //array

      selectedFiltersByTab.forEach((selectedFilterByTab) => {
        const dynamicFieldType = this.selectableDynamicContentFields?.find(
          (x) => x.name === dynamicField
        )?.type;

        this.$set(selectedFilterByTab, "dynamicField", dynamicField);
        this.$set(selectedFilterByTab, "dynamicFieldType", dynamicFieldType);
      });
    },
    handleChangeIsDynamic(chart, isDynamic) {
      const selectedFiltersByTab = this.selectedFiltersByTab(chart); //array

      if (selectedFiltersByTab.length === 0) {
        this.addFiltersToSchedule(
          {
            panelI: chart.i,
            operator: "eq",
            isDrillDown: false,
            applyToTabs: this.getFilterChartApplyToTabsValue(chart) ?? [],
          },
          chart,
          true
        );
      } else {
        selectedFiltersByTab.forEach((selectedFilterByTab) => {
          if (isDynamic) {
            this.$set(selectedFilterByTab, "isDynamic", isDynamic);
            this.$set(selectedFilterByTab, "value", []);
          } else {
            this.$set(selectedFilterByTab, "isDynamic", isDynamic);
            this.$set(selectedFilterByTab, "dynamicField", "");
            this.$set(selectedFilterByTab, "dynamicFieldType", "");
          }

          if (selectedFilterByTab?.isMandatory === undefined) {
          const isMandatory = this.getPanelDataPropertiesStyleByKey(
            chart?.properties?.style,
            "MANDATORY_SELECTION"
          );
          this.$set(selectedFilterByTab, "isMandatory", isMandatory);
        }
        });
      }
    },
    //TODO----- BURASI MOUNTED İÇİNDE ÇAĞIRALACAK. SEÇİLEN YENİ CONTENT'e VAR OLAN
    //FİLTRELERİN EKLENMESİ. ApplyToTabs'a göre düzenleneceği için mounted'a eklenmedi.
    setChartDataFilterAndParams() {
      const filteredChart = this.messageUpdated.chartData?.find(
        (x) => x?.filter?.length || x?.params?.length
      );

      if (filteredChart) {
        this.messageUpdated.chartData.forEach((chart) => {
          if (!chart?.filter?.length) {
            chart.filter = filteredChart.filter;
          } else if (!chart?.params?.length) {
            chart.params = filteredChart.params;
          }
        });
      }
      this.scheduleContentMessageUpdated();
    },
    getScheduleFilterDynamicField(chart) {
      const selectedFilterByTab = this.selectedFilterByTab(chart);

      return selectedFilterByTab?.dynamicField;
    },
    getScheduleFilterIsDynamic(chart) {
      const selectedFilterByTab = this.selectedFilterByTab(chart);

      return !!selectedFilterByTab?.isDynamic;
    },
    getScheduleFilterValue(chart) {
      const result = {};
      const selectedFilterByTab = this.selectedFilterByTab(chart);
      
      if (!selectedFilterByTab) return;

      if (chart.type === dashboardFilterTypes.FILTER_DATE) {
        if (chart.isDateFilterMultipleSelection) {
          result.value = selectedFilterByTab?.value;
          result.alias = chart.alias;
        } else {
          result.value = selectedFilterByTab?.value?.[0];
          result.alias = chart.alias;
        }
      } else if (chart.type === dashboardFilterTypes.FILTER_TEXT) {
        result.value = selectedFilterByTab?.value?.[0];
        result.alias = chart.alias;
      } else if (this.isChartTypeSelect(chart.type)) {
        result.value = selectedFilterByTab?.value;
        result.alias = chart.alias;
      }

      return { [chart.field]: result };
    },
    addFiltersToSchedule(filterData, chart, isDynamic = false) {
      const clonedFilterData = cloneDeep(filterData);
      const fieldItem = chart?.details?.metrics[0];
      const filterItem = {
        ...createFilterObj(fieldItem, filterData),
        isMandatory: this.getPanelDataPropertiesStyleByKey(
          chart?.properties?.style,
          "MANDATORY_SELECTION"
        ),
      };
      const filterValue = clonedFilterData?.value?.[filterItem.field]?.value;

      filterItem.value =
        !Array.isArray(filterValue) && filterValue
          ? [filterValue]
          : filterValue;

      const hasAllItem = clonedFilterData?.applyToTabs?.includes(selectBoxOption.ALL_ITEM);

      if (!clonedFilterData?.applyToTabs?.length) {
        const selectedTab = this.dashboardTabs?.find(
          (x) => x.id === chart?.tabId
        );

        clonedFilterData.applyToTabs.push(selectedTab?.properties?.uiTabId);
      }

      const filterKey =
        fieldItem.filterType === filterType.CUSTOM
          ? panelDataDetailsKey.PARAMS
          : panelDataDetailsKey.FILTER;
     
      this.messageUpdated.chartData.forEach((element) => {
        if (!clonedFilterData?.applyToTabs?.includes(element?.uiTabId) && !hasAllItem) return;
        
        const hasFilter = element?.[filterKey]?.find(
          (x) => x?.filterId === filterItem?.filterId
        );

        if (!hasFilter) {
          if (isDynamic) {
            this.$set(filterItem, "isDynamic", true);
            this.$set(filterItem, "value", []);
          }

          element?.[filterKey].push(filterItem);
        } else if (hasFilter && filterItem.value) {
          this.$set(hasFilter, "value", filterItem.value);
          this.$set(hasFilter, "operator", filterItem.operator);
          this.$set(hasFilter, "isMandatory", filterItem.isMandatory);
        } else {
          element[filterKey] = element?.[filterKey]?.filter(
            (x) => x?.filterId !== filterItem?.filterId
          );
        }
      });

      this.scheduleContentMessageUpdated();
    },
    fetchSearch() {
      this.getChartsWithChartTypeFilter().forEach((item)  => {
        this.fetchSearchWithoutMutate({
          loadingComponent: null,
          bodyParam: getSearchBodyParam(item),
        }).then((response) => {
          this.$set(this.options, item?.i, { arr: response.aggregations });
        });
      });
    },
    isChartTypeSelect(chartType) {
      return [
        dashboardFilterTypes.FILTER_DROPDOWN,
        dashboardFilterTypes.FILTER_SELECT_LIST,
      ].includes(chartType);
    },
    getPanelDataPropertiesStyleByKey(panelDataPropertiesStyle, k) {
      return panelDataPropertiesStyle[
        Object.keys(panelDataPropertiesStyle).find((key) => key.includes(k))
      ];
    },
    getFilterChartApplyToTabsValue(chart) {
      const applyToTabKey = Object.keys(chart?.properties?.style).find((x) =>
        x.includes("APPLY_TO_TABS")
      );

      return chart?.properties?.style?.[applyToTabKey];
    },
    checkFilterChartIsUsedForSelectedTable(chart) {
      const applyToTabValues = this.getFilterChartApplyToTabsValue(chart);

      if (!applyToTabValues)
        return this.messageUpdated.chartData.find(
          (x) => x.uiTabId === chart?.properties?.uiTabId
        );

      if (applyToTabValues.includes("ALL_ITEM")) {
        // Eğer "ALL_ITEM" varsa, tüm uiTabId'leri al
        return this.messageUpdated?.chartData.find((x) => x.uiTabId);
      }

      return this.messageUpdated?.chartData?.find((x) =>
        applyToTabValues?.includes(x.uiTabId)
      );
    },
    getChartsWithChartTypeFilter() {
      let filterChartList = [];

      this.dashboardTabs
        .filter((tab) => this.tabIdsForSelectedContent.includes(tab.id))
        .forEach((tab) => {
          const filteredFilterChartList = tab?.charts?.filter(
            (x) =>
              x?.details?.metrics?.length && this.isChartTypeSelect(x?.type)
          );

          filterChartList = [...filterChartList, ...filteredFilterChartList];
        });

      return filterChartList;
    },
    checkTabInHasFilterChart(tab) {
      return tab?.charts?.filter(
        (chart) =>
        chart.type?.toLowerCase().includes(panelTypes.FILTER) &&
          this.checkFilterChartIsUsedForSelectedTable(chart)
      )?.length || this.getSelectionChartList(tab?.charts)?.length;
    },
    scheduleContentMessageUpdated() {
      this.$emit("onScheduleContentMessageUpdated", this.messageUpdated);
    },
    createMandatoryDataWithCharts(charts) {
      charts?.forEach((chart) => {
        const isChartMandatory = this.getPanelDataPropertiesStyleByKey(
          chart?.properties?.style,
          "MANDATORY_SELECTION"
        );

        //chart?.isMandatory is used for custom filters.
        if (chart?.isShow && (isChartMandatory || chart?.isMandatory)) {
          this.mandatoryData.mandatoryChartsI.add({ filterId: chart.i, tabId: chart.tabId });
        }
      });

      this.messageUpdated.mandatoryData = this.mandatoryData;
      this.scheduleContentMessageUpdated();
    },
    removeUnusedFilter(mappedFilterChartList) {
      this.messageUpdated.chartData.forEach((element) => {
        element.filter = element.filter.filter((item) => {
          if (!mappedFilterChartList?.[element.tabId]?.length) return element.filter;
          return mappedFilterChartList?.[element.tabId]?.some(
            (filter) => filter.i === item.filterId
          );
        });
      });
    },
  },
};
</script>

<style scoped>
.align-items-center {
  align-items: center !important;
}
.mandatory-text-box {
  background: #ededed;
  border-radius: 3px;
  color: #4d4d4d;
  padding: 0 5px;
  width: fit-content;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-key-box {
  font-size: 0.625rem;
  color: #4d4d4d;
}

.vis-scroll-thin::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.vis-scroll-thin::-webkit-scrollbar-thumb {
  background-color: #e4e3e8 !important;
}
.vis-ellipsis-line-2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
  white-space: normal;
}
</style>
