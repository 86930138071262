<template>
  <div>
    <!-- DATA CACHE HEADER START -->
    <div class="vis-row">
      <div class="vis-col vis-admin-settings-pages-title vis-mb--1">
        <h2>{{ $t("admin.settings.Data Cache") }}</h2>
        <p class="vis-font-medium">
          {{
            $t(
              "admin.dataCache.Enter cache usage setting and expiration policy"
            )
          }}
        </p>
      </div>
    </div>
    <!-- USE CACHE START -->
    <div class="vis-row">
      <div class="vis-col vis-col-4 vis-col-xxl6 vis-col-l12">
        <label>{{ $t("admin.dataCache.Use Cache") }}</label>
        <el-switch
          :value="selectedCacheData.IS_CACHE_CONNECTION_ACTIVE"
          @change="
            (value) =>
              changeValue(
                value,
                configurationNameTypes.IS_CACHE_CONNECTION_ACTIVE
              )
          "
        >
        </el-switch>
      </div>
    </div>
    <!-- DEFAULT CACHE DURATION START -->
    <div class="vis-row">
      <div class="vis-col vis-col-4 vis-col-xxl6 vis-col-l12">
        <label>{{ $t("admin.dataCache.Default Cache Duration") }}</label>
        <el-input
          type="number"
          :value="selectedCacheData.DEFAULT_CACHE_DURATION_MIN"
          @input="
            (value) =>
              changeValue(
                value,
                configurationNameTypes.DEFAULT_CACHE_DURATION_MIN
              )
          "
        />
      </div>
    </div>
    <!-- MAX CACHE SIZE START -->
    <div class="vis-row">
      <div class="vis-col vis-col-4 vis-col-xxl6 vis-col-l12">
        <label>{{ $t("admin.dataCache.Max Cache Size") }}</label>
        <el-input
          type="number"
          :value="selectedCacheData.CACHE_MAX_MEMORY_SIZE"
          @input="
            (value) =>
              changeValue(value, configurationNameTypes.CACHE_MAX_MEMORY_SIZE)
          "
        />
      </div>
    </div>
    <!-- CACHE EXPIRATION POLICY START -->
    <div class="vis-row">
      <div class="vis-col vis-col-4 vis-col-xxl6 vis-col-l12">
        <label>{{ $t("admin.dataCache.Cache Expiration Policy") }}</label>
        <el-select
          :value="selectedCacheData.CACHE_MAX_MEMORY_POLICY"
          placeholder="Select"
          @change="
            (value) =>
              changeValue(value, configurationNameTypes.CACHE_MAX_MEMORY_POLICY)
          "
        >
          <el-option
            v-for="item in cachePolicyOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- DELETE CACHE ON SCHEDULE START -->
    <div class="vis-row">
      <div class="vis-col vis-col-4 vis-col-xxl6 vis-col-l12">
        <div class="vis-flex--spacebeetwen-Ycenter">
          <label for="deleteCacheOnSchedule">{{
            $t("admin.dataCache.Delete Cache On Schedule")
          }}</label>
          <el-switch
            id="deleteCacheOnSchedule"
            :value="selectedCacheData.IS_CACHE_CLEANUP_JOB_ENABLED"
            @change="
              (value) =>
                changeValue(
                  value,
                  configurationNameTypes.IS_CACHE_CLEANUP_JOB_ENABLED
                )
            "
          >
          </el-switch>
        </div>
      </div>
    </div>
    <!-- SCHEDULE TIME START -->
    <div class="vis-row">
      <div class="vis-col vis-col-4 vis-col-xxl6 vis-col-l12">
        <el-time-picker
          :value="scheduleTimeValue"
          placeholder="Arbitrary time"
          value-format="H:mm"
          :picker-options="{
            format: 'H:mm',
          }"
          :disabled="!selectedCacheData.IS_CACHE_CLEANUP_JOB_ENABLED"
          @input="(value) => changeScheduleValue(value)"
        >
        </el-time-picker>
      </div>
    </div>
    <!-- MEMORY INFO START -->
    <div class="vis-row">
      <div class="vis-col vis-col-4 vis-col-xxl6 vis-col-l12">
        <label>{{ $t("admin.dataCache.Usage Statistics") }}</label>
        <div class="vis-flex--spacebeetwen">
          <div class="vis-statistics-card">
            <p>{{ $t("admin.dataCache.Current Memory Usage") }}</p>
            <p>{{ currentMemoryUsage }}</p>
          </div>
          <div class="vis-statistics-card">
            <p>{{ $t("admin.dataCache.Peak Memory Usage") }}</p>
            <p>{{ peakMemoryUsage }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE ALL CACHE START -->
    <div class="vis-row">
      <div class="vis-col vis-col-4 vis-col-xxl6 vis-col-l12">
        <label>{{ $t("admin.dataCache.Delete All Cache") }}</label>
        <el-button
          @click="$emit('deleteCacheAll')"
          :loading="isDeleteCacheAllLoading"
          size="small"
          >{{ $t("admin.dataCache.Delete Cache Immediately") }}</el-button
        >
      </div>
    </div>
    <!-- SAVE BUTTON START -->
    <div class="vis-row">
      <div class="vis-col vis-col-4 vis-col-xxl6 vis-col-l12 vis-text--right">
        <el-button
          @click="saveCacheConfiguration"
          :loading="isSaveLoading"
          size="small"
          type="primary"
          :disabled="!willUpdateDataCacheKeys.length && !isScheduleDataModified"
          >{{ $t("generalPages.save") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from "clone-deep";
import deepEqual from "deep-equal";
export default {
  props: {
    selectedCacheData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    configurationNameTypes: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isSaveLoading: {
      type: Boolean,
      default: false,
    },
    isDeleteCacheAllLoading: {
      type: Boolean,
      default: false,
    },
    scheduleData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      willUpdateDataCacheKeys: [],
      tempScheduleData: {},
    };
  },
  mounted() {
    this.tempScheduleData = cloneDeep(this.scheduleData);
  },
  computed: {
    scheduleTimeValue() {
      const cronDto = this.tempScheduleData?.taskDefinitionDto?.cronDto;

      //Sunucu sistem saatinden dolayı gelen saat 3 saat geri olduğu için + 3 yapıyoruz.
      return (Number(cronDto?.hour) + 3) + ":" + cronDto?.minute;
    },
    cachePolicyOptions() {
      return [
        {
          value: "noeviction",
          label: `${this.$t("admin.dataCache.No Eviction")}`,
          disabled: true,
        },
        {
          value: "allkeys-lru",
          label: `${this.$t("admin.dataCache.Keep most recently used data")}`,
        },
        {
          value: "allkeys-lfu",
          label: `${this.$t("admin.dataCache.Keep frequently used data")}`,
        },
        {
          value: "volatile-ttl",
          label: `${this.$t("admin.dataCache.Remove data with shortest TTL")}`,
        },
      ];
    },
    currentMemoryUsage() {
      return this.selectedCacheData?.cacheMemoryInfo?.currentMemoryUsage ?? "";
    },
    peakMemoryUsage() {
      return this.selectedCacheData?.cacheMemoryInfo?.peakMemoryUsage ?? "";
    },
    isScheduleDataModified() {
      return !deepEqual(this.scheduleData, this.tempScheduleData);
    },
  },
  methods: {
    changeScheduleValue(timeValue) {
      const [hour, minute] = timeValue?.split(":");
      const convertedHour = Number(hour) - 3;

      if (convertedHour < 0) {
        this.tempScheduleData.taskDefinitionDto.cronDto.hour =
          (convertedHour + 24) % 24;
      } else {
        //Sunucu sistem saatinden dolayı girilen saati - 3 yapıyoruz
        this.tempScheduleData.taskDefinitionDto.cronDto.hour = convertedHour;
      }
     
      this.tempScheduleData.taskDefinitionDto.cronDto.minute = minute;
      this.tempScheduleData.taskDefinitionDto.activeFrom = Math.floor(Date.now() / 1000);
    },
    changeValue(value, key) {
      this.willUpdateDataCacheKeys = this.willUpdateDataCacheKeys.filter(
        (x) => x.key !== key
      );

      this.$emit("handleSelectedCacheData", { value, key });

      this.willUpdateDataCacheKeys.push({ key, value });
    },
    saveCacheConfiguration() {
      if (this.isScheduleDataModified) {
        this.$emit("handleScheduleData", this.tempScheduleData);
      }

      if (!this.willUpdateDataCacheKeys.length) return;

      this.willUpdateDataCacheKeys.forEach((cache) => {
        this.$emit("saveCacheConfiguration", {
          key: cache.key,
          value: cache.value,
        });
      });

      this.willUpdateDataCacheKeys = [];
    },
  },
};
</script>

<style scoped>
label {
  font-weight: 500;
}

.vis-statistics-card {
  position: relative;
  width: 48%;
  background-color: #f9f9f9;
  height: 60px;
  padding: 8px 12px;
}
::v-deep .el-icon-loading {
  font-size: 0.75rem !important;
}
</style>
