<template>
  <el-dialog :title="$t('generalPages.selectFolder')" :visible="true">
    <div class="vis-row-box mb-15">
      <div class="vis-vgt-card">
        <div class="vis-vgt-card-title vis-flex--spacebeetwen-Ycenter">
          <ul class="vis-user-tabs vis-flex--Ycenter">
            <li 
              @click="getFoldersById(null, null)"
              class="vis-main-breadcrumb"
            >{{ $t("home.all") }}</li>
            <div 
              v-for="folder in folderNameList"
              :key="folder.id"
              class="vis-flex--Ycenter"
            >
              <li class="vis-main-breadcrumb"><i aria-hidden="true" :class="CustomIcon.ArrowRight"></i></li>
              <li 
                @click="getFoldersById(folder.id, folder.name)"
                :class="{'vis-font-medium': folderId == folder.id}"
                class="vis-main-breadcrumb"
              >
                {{ folder.name }}
              </li>
            </div>
          </ul>
          <InputSearch @keyupSearchData="searchFolder = $event" />
        </div>
        <vue-good-table
          :columns="selectFolderColumns"
          :rows="tableRows"
          max-height="300px"
          :fixed-header="true"
          :search-options="{
            enabled: true,
            externalQuery: searchFolder,
          }"
          :row-style-class="rowStyleClassFn"
          @on-row-click="onRowClick"
          @on-row-dblclick="(item) => getFoldersById(item.row.id, item.row.name)"
        >
        </vue-good-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        @click="$emit('closeFolderSelect')"
        class="vis-cancel-btn"
        type="text"
        >{{ $t("generalPages.cancel") }}</el-button
      >
      <el-button @click="selectFolder()" type="primary" size="small">
        {{ $t("generalPages.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import InputSearch from "../helper/Form/InputSearch.vue";
import { CustomIcon } from "../../assets/js/custom-icons";
export default {
  components: { VueGoodTable, InputSearch },
  props: {
    tableRows: {
      type: Array,
    },
    folderId: {
      type: String,
    },
    folderNameList: {
      type: Array,
    }

  },
  data() {
    return {
      searchFolder: "",
      selectedRow: "",
      selectFolderColumns: [
        {
          label: this.$t("generalPages.labelName"),
          field: "name",
        }
      ],
      CustomIcon: CustomIcon,
    };
  },
  methods: {
    onRowClick(item) {
      this.selectedRow = item.row.originalIndex;
      this.$emit("setFolderId", item.row.id);
    },
    selectFolder() {
      this.$emit("selectFolder");
    },
    getFoldersById(id, name) {
      this.$emit("getFoldersById", id, name);
    },
    rowStyleClassFn(row) {
      return row.originalIndex === this.selectedRow ? 'vis-selectedRow':'';
    },
  },
};
</script>
