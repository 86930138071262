
import {
    fontsArr,
    rotateArr,
    fontSizesArr,
    fontWeightsArr,
    BAR_CHART_PROPERTIES_ENUM,
    predefineColorsArr,
    alignItems,
    locationItems,
    verticalAlignItems,
    yAxisPositionArr,
    autoRefreshTimes,
    labelMaxCharacters,
    allPropertiesKeys,
    showOnlyArr,
    legendMaxCharArr,
    legendPositionArr,
    tableColumnsInDrillThrough,
    percentageOptionsWithoutColumn
} from "../../commons/dashboardProperties";
import { BAR_CHART_DEFAULT_CONFIGURATIONS} from "../../mocks/barChartDefaultProperties";
import ColorPaletteSelector from "../../components/panel/properties/ColorPaletteSelector";
import { getPropertiesWithUndefinedCheck } from "../../util/chartViewHelper";
import { chartPropertiesCss } from "./chartPropertiesCssEnum";
import { mapActions } from "vuex";
import { ACTION as ACTION_SEARCH } from "../../store/modules/Visualize/Home/types";

export default {
    computed: {
        barChartComponentsMapping() {
            return {
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_VALUE_LABEL]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_VALUE_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_VALUE_LABEL
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_SHOW_VALUE_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_VALUE_LABEL,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_ONLY]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_ONLY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showOnly.title",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_ONLY
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_SHOW_ONLY),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_ONLY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: showOnlyArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_PERCENT_TYPE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_PERCENT_TYPE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showPercentageType.Calculate Percentage Based On",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_PERCENT_TYPE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_SHOW_PERCENT_TYPE),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_PERCENT_TYPE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: percentageOptionsWithoutColumn,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_INSIGHT]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_INSIGHT,
                        this.collapse.CHART
                    ),  
                    label: "panelProperties.showInsight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_INSIGHT
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_SHOW_INSIGHT),
                    componentType: this.componentTypes.SWITCH,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_INSIGHT,
                    flexClass: true,
                    readOnly: !this.getShowInsightControl(process.env.VUE_APP_SHOW_INSIGHT),
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_SHOW_DATA_ZOOM]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_SHOW_DATA_ZOOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Show on X axis",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_SHOW_DATA_ZOOM
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_SHOW_DATA_ZOOM),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_SHOW_DATA_ZOOM,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_START]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_START,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Start",
                    cssStyle: chartPropertiesCss.DATA_ZOOM_START,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_START
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_DATA_ZOOM_START),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_START,
                    componentType: this.componentTypes.INPUT_NUMBER,
                    minValue: 0,
                    maxValue: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_END
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_DATA_ZOOM_END),
                    stepValue: 10,
                    discardManualValues: true,
                    flexClass: false,
                    readOnly: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_END]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_END,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.End",
                    cssStyle: chartPropertiesCss.DATA_ZOOM_END,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_END
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_DATA_ZOOM_END),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_END,
                    componentType: this.componentTypes.INPUT_NUMBER,
                    minValue: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_START
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_DATA_ZOOM_START),
                    maxValue: 100,
                    stepValue: 10,
                    discardManualValues: true,
                    flexClass: false,
                    readOnly: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LOCK_DATA_ZOOM]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LOCK_DATA_ZOOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Lock X Axis Window Size",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LOCK_DATA_ZOOM
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_LOCK_DATA_ZOOM),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LOCK_DATA_ZOOM,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.X axis Background Color",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                    size: "mini",
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Show on Y axis",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_START]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_START,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Start",
                    cssStyle: chartPropertiesCss.DATA_ZOOM_START,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_START
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_DATA_ZOOM_START),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_START,
                    componentType: this.componentTypes.INPUT_NUMBER,
                    minValue: 0,
                    maxValue: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_END
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_DATA_ZOOM_END),
                    stepValue: 10,
                    discardManualValues: true,
                    flexClass: false,
                    readOnly: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_END]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_END,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.End",
                    cssStyle: chartPropertiesCss.DATA_ZOOM_END,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_END
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_DATA_ZOOM_END),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_END,
                    componentType: this.componentTypes.INPUT_NUMBER,
                    minValue: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_START
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_DATA_ZOOM_START),
                    maxValue: 100,
                    stepValue: 10,
                    discardManualValues: true,
                    flexClass: false,
                    readOnly: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Lock Y Axis Window Size",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Y axis Background Color",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                    size: "mini",
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_SQL]: {
                    id: this.getPropertyId(
                      BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_SQL,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.sqlStatement",
                    placeholder: "panelProperties.showSql",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_SQL],
                      BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_SHOW_SQL
                    ),
                    componentType: this.componentTypes.SHOW_BUTTON,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_SQL,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.Value Label Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_FAMILY
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_VALUE_LABEL_FONT_FAMILY),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_WEIGHT
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_VALUE_LABEL_FONT_WEIGHT),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_SIZE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_VALUE_LABEL_FONT_SIZE),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_COLOR
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_VALUE_LABEL_FONT_COLOR),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_POSITION]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_POSITION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.Legend Position",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_POSITION
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_LEGEND_POSITION),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_POSITION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: legendPositionArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.hideOverlap",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_LEGEND]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_LEGEND,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_LEGEND
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_SHOW_LEGEND),
                    componentType: this.componentTypes.SWITCH,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_LEGEND,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_MAX_CHARACTER]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_MAX_CHARACTER,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.Max Character",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_MAX_CHARACTER
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_LEGEND_MAX_CHARACTER),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_MAX_CHARACTER,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: legendMaxCharArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_VERTICAL_ALIGN
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_LEGEND_VERTICAL_ALIGN),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.Legend Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_FAMILY
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_LEGEND_FONT_FAMILY),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_WEIGHT
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_LEGEND_FONT_WEIGHT),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_SIZE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_SIZE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_LEGEND_FONT_SIZE),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_COLOR]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_COLOR
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_LEGEND_FONT_COLOR),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_X_AXIS_NAME]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_X_AXIS_NAME,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_X_AXIS_NAME
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_SHOW_X_AXIS_NAME),
                    componentType: this.componentTypes.SWITCH,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_X_AXIS_NAME,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.horizontalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_VERTICAL_ALIGN
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_NAME_VERTICAL_ALIGN),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.X Axis Name Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_FAMILY
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_NAME_FONT_FAMILY),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_WEIGHT
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_NAME_FONT_WEIGHT),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_SIZE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_SIZE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_NAME_FONT_SIZE),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_COLOR]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_COLOR
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_NAME_FONT_COLOR),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },

                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_Y_AXIS_NAME]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_Y_AXIS_NAME,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_Y_AXIS_NAME
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_SHOW_Y_AXIS_NAME),
                    componentType: this.componentTypes.SWITCH,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_Y_AXIS_NAME,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.horizontalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_VERTICAL_ALIGN
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_NAME_VERTICAL_ALIGN),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_LOCATION]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_LOCATION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.location",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_LOCATION
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_NAME_LOCATION),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_LOCATION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: locationItems,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.Y Axis Name Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_FAMILY
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_NAME_FONT_FAMILY),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_SIZE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_SIZE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_NAME_FONT_SIZE),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_COLOR]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_COLOR
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_NAME_FONT_COLOR),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },

                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_X_AXIS_LABEL]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_X_AXIS_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_X_AXIS_LABEL
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_SHOW_X_AXIS_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_X_AXIS_LABEL,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_WRAP]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_WRAP,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.Wrap X Axis Label",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_WRAP
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_LABEL_WRAP),
                    componentType: this.componentTypes.SWITCH,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_WRAP,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.horizontalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisLabel.X Axis Label Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_FAMILY
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_LABEL_FONT_FAMILY),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_SIZE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_LABEL_FONT_SIZE),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_COLOR
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_LABEL_FONT_COLOR),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_ROTATE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_ROTATE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.TITLE_ALIGN_ITEM,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_ROTATE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_LABEL_ROTATE),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_ROTATE,
                    componentType: this.componentTypes.ROTATE_ALIGNMENT_RADIO_BUTTON,
                    selectValues: rotateArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_Y_AXIS_LABEL]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_Y_AXIS_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_Y_AXIS_LABEL
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_SHOW_Y_AXIS_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_Y_AXIS_LABEL,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.horizontalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.Y Axis Label Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_SIZE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_LABEL_FONT_SIZE),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_COLOR
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_LABEL_FONT_COLOR),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },

                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LOGARITHMIC_SCALE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LOGARITHMIC_SCALE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.logarithmicScale",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LOGARITHMIC_SCALE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_LOGARITHMIC_SCALE),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LOGARITHMIC_SCALE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_MIN]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_MIN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.xAxis.X axis minimum value",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_MIN
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_MIN),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_MIN,
                    componentType: this.componentTypes.INPUT_WITH_ENTER,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_MAX]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_MAX,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.xAxis.X axis maximum value",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_MAX
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_MAX),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_MAX,
                    componentType: this.componentTypes.INPUT_WITH_ENTER,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_POSITION]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_POSITION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.yAxis.position",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_POSITION
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_POSITION),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_POSITION,
                    selectValues: yAxisPositionArr,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_SHOW_LINE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_SHOW_LINE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.yAxis.line",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_SHOW_LINE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_SHOW_LINE),
                    componentType: this.componentTypes.SWITCH,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_SHOW_LINE,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LINE_STYLE_COLOR]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LINE_STYLE_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.yAxis.lineColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LINE_STYLE_COLOR
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_LINE_STYLE_COLOR),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LINE_STYLE_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_GRID_LINE_COLOR]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_GRID_LINE_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Grid Line Color",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_GRID_LINE_COLOR
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_GRID_LINE_COLOR),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_GRID_LINE_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },

                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisLabel.maxCharacter",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: labelMaxCharacters,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SELECTED_COLOR_PALETTE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SELECTED_COLOR_PALETTE,
                        this.collapse.CHART
                    ),
                    label: "",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SELECTED_COLOR_PALETTE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_SELECTED_COLOR_PALETTE),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SELECTED_COLOR_PALETTE,
                    dashboardProperty: allPropertiesKeys.CUSTOM_COLOR_PALETTES,
                    componentType: this.componentTypes.OTHER,
                    component: ColorPaletteSelector
                },

                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_AUTO_REFRESH]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_AUTO_REFRESH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.autoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_AUTO_REFRESH
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_AUTO_REFRESH),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_AUTO_REFRESH,
                    selectValues: autoRefreshTimes,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                },

                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_AUTO_REFRESH_CUSTOM]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_AUTO_REFRESH_CUSTOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.customAutoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_AUTO_REFRESH_CUSTOM
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_AUTO_REFRESH_CUSTOM),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_AUTO_REFRESH_CUSTOM,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                },

                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_VALUE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_VALUE,
                        this.collapse.CHART
                    ),
                    label: this.getLabelByDynamicTitleShowValue(BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_VALUE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_DYNAMIC_TITLE_VALUE),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_VALUE,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.noSelectionContent",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                    readOnly: this.isNoContentSelectionTheReadOnly(BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE),
                    componentType: this.componentTypes.SWITCH,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_GROUP_MULTIPLE_VALUES]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_GROUP_MULTIPLE_VALUES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.group",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_GROUP_MULTIPLE_VALUES
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_GROUP_MULTIPLE_VALUES),
                    componentType: this.componentTypes.SWITCH,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_GROUP_MULTIPLE_VALUES,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_TEXT]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_TEXT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.yAxisName.yAxisNameChange",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_TEXT
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_NAME_TEXT),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_TEXT,
                    componentType: this.componentTypes.INPUT_WITH_ENTER,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                },
                
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_TEXT]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_TEXT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.xAxisName.xAxisNameChange",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_TEXT
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_NAME_TEXT),
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_TEXT,
                    componentType: this.componentTypes.INPUT_WITH_ENTER,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                },
                // START DRILL THROUGH
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_ALLOW_DRILL_THROUGH]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_ALLOW_DRILL_THROUGH,
                        this.collapse.CHART
                    ),  
                    label: "panelProperties.Allow Drill Through",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_ALLOW_DRILL_THROUGH
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_ALLOW_DRILL_THROUGH),
                    componentType: this.componentTypes.SWITCH,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_ALLOW_DRILL_THROUGH,
                    flexClass: true,
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DRILL_THROUGH_DASHBOARD_LIST]:
                {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DRILL_THROUGH_DASHBOARD_LIST,
                    this.collapse.CHART
                    ),
                    label: "",
                    value: getPropertiesWithUndefinedCheck(
                    this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM
                        .BAR_CHART_DRILL_THROUGH_DASHBOARD_LIST
                    ],
                    BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_DRILL_THROUGH_DASHBOARD_LIST
                    ),
                    componentType: this.componentTypes.AUTO_COMPLETE_INPUT_WITH_TABLE,
                    tableColumns: tableColumnsInDrillThrough,
                    property:
                    BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DRILL_THROUGH_DASHBOARD_LIST,
                    flexClass: false,
                    queryParam: (value) => {
                        return `dashboardName=${value}&dataModelId=${this.datamodelId}`;
                      },
                    serviceFn: (params) => this.fetchDashboardSearch(params),
                    mapperFn: (content) => {
                        return content.map((m) => {
                          return {
                            id: m.id,
                            value: m.name,
                            nameSubtitle: m?.folderNames?.join(" / ") ?? "Home",
                          };
                        });
                      },
                      getAutoCompleteItem: (item) => {
                        return {
                          id: item.id,
                          name: item.value,
                          nameSubtitle: item?.nameSubtitle,
                        };
                      },
                },
                [BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK]: {
                    id: this.getPropertyId(
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Drill On Double Click",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                    ], BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK),
                    componentType: this.componentTypes.SWITCH,
                    property: BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK,
                    flexClass: true,
                },
                // END DRILL THROUGH                 
            }
        },
    },
    methods: {
        ...mapActions({
            fetchDashboardSearch: ACTION_SEARCH.FETCH_DASHBOARD_SEARCH,
          }),
    }
};
