//Layout
import Layout from "../layout/Layout.vue";

import {
    GETTER as GETTER_DASHBOARD,
    MUTATION as MUTATION_DASHBOARD,
    ACTION as ACTION_DASHBOARD,
} from "../store/modules/Visualize/Dashboard/types";
import {
    GETTER as GETTER_TEST_QUERY,
    MUTATION as MUTATION_TEST_QUERY,
    ACTION as ACTION_TEST_QUERY,
} from "../store/modules/Visualize/TestQuery/types";
import {
    ACTION as ACTION_API_CALL,
    GETTER as GETTER_GENERAL,
} from "../store/modules/Visualize/General/types";
import {
    GETTER as GETTER_DATAMODEL,
    ACTION as ACTION_DATAMODEL,
    MUTATION as MUTATION_DATAMODEL,
} from "../store/modules/Visualize/DataModel/types";
import {
    GETTER as GETTER_DATASET_FIELDS,
    ACTION as ACTION_DATASET,
    MUTATION as MUTATION_DATASET
} from "../store/modules/Visualize/Dataset/types";
import {
    GETTER as GETTER_DASHBOARD_FIELDS,
    ACTION as ACTION_DASHBOARD_FIELDS,
    MUTATION as MUTATION_DASHBOARD_FIELDS
} from "../store/modules/Visualize/DashboardFields/types";

import { ACTION as ACTION_AUTHORIZATION } from "../store/modules/Visualize/Authorization/types";
import {
    TEMP_STORAGE_KEYS,
    GETTER as GETTER_TEMP_STORAGE,
    MUTATION as MUTATION_TEMP_STORAGE,
} from "../store/modules/temp-storage/types";
import {
    GETTER as GETTER_FILTER,
    ACTION as ACTION_FILTER,
} from "../store/modules/Visualize/Filter/types";
import { ACTION as ACTION_OBJECT_LOCK } from "../store/modules/Visualize/ObjectLock/types";
import { ACTION as ACTION_CHAT_GPT } from "../store/modules/Visualize/ChatGpt/types"
import { ACTION as ACTION_CALCULATED_COLUMN } from "../store/modules/Visualize/CalculatedColumn/types";
import { ACTION as ACTION_CONFIGURATIONS, GETTER as GETTER_CONFIGURATIONS } from "../store/modules/Visualize/Configurations/types"
import { ACTION as ACTION_EXPORT_METADATA } from "../store/modules/Visualize/ExportMetaData/types";
import {ACTION as ACTION_TEMPLATES, GETTER as GETTER_TEMPLATES} from "../store/modules/Visualize/AdminTemplates/types"
import { ACTION as ACTION_SCHEDULE} from "../store/modules/Visualize/Schedule/types";
import { mapActions, mapGetters, mapMutations } from "vuex";
import dashboardSearch from "../mixins/dashboardSearch";
import DashboardProperties from "../components/dashboard/properties/DashboardProperties.vue";
import SettingsBar from "../components/helper/SettingsBar.vue";
import DataModelTable from "../components/data/DataModelTable.vue";
import Chart from "../components/dashboard/chart/Chart.vue";
import Logo from "../components/dashboard/logo/Logo.vue";
import Summary from "../components/dashboard/summary/Summary.vue";
import DataTable from "../components/dashboard/table/Table.vue";
import FilterText from "../components/dashboard/chart/FilterText.vue";
import FilterDropdown from "../components/dashboard/chart/FilterDropdown.vue";
import FilterNumber from "../components/dashboard/chart/FilterNumber.vue";
import FilterDate from "../components/dashboard/chart/FilterDate.vue";
import FilterSelectList from "../components/dashboard/chart/FilterSelectList";
import FilterButton from "../components/dashboard/chart/FilterButton.vue";
import AdvancedButton from "../components/dashboard/chart/AdvancedButton.vue";
import TextEditor from "../components/dashboard/chart/TextEditor.vue";
import SelectorChart from "../components/dashboard/chart/SelectorChart.vue";
import TabPanel from "../components/dashboard/chart/TabPanel.vue"
import FilterChart from "../components/dashboard/chart/FilterChart.vue";
import DashboardChartDragend from "../components/dashboard/DashboardChartDragend.vue";
import DashboardDatasetFields from "../components/dashboard/DashboardDatasetFields.vue";
import DashboardParameters from "../components/dashboard/DashboardParameters.vue";
import DashboardCardTitle from "../components/dashboard/DashboardCardTitle.vue";
import DashboardFilterPaneIconBox from "../components/dashboard/DashboardFilterPaneIconBox.vue";
import ProjectSavePopup from "../components/helper/ProjectSavePopup.vue";
import { GridLayout, GridItem } from "vue-grid-layout";
import { includesValues, routerEnums } from "../commons/Enum";
import {
    chartTypes,
    dashboardFilterTypes,
    panelTypes,
    otherChartType,
    getAggregationField,
    getSelectedField,
    getFilteredPanelDataByAuthorize,
    detailsKeys,
    checkChartMetricEmpty,
    checkDuplicateValueInChartStyle,
    getCacheInfoByPropertyStyle,
    BOX_KEY_ENUM,
} from "../commons/dashboardAndPanel";
import {
    allPropertiesKeys,
    propertiesKeyToCSSKeyMapping,
    dashboardGridPropertiesEnum,
    DefaultValue,
    SELECTION_ACTION_KEYS,
    autoRefreshTimeValue,
    pageSizePropertiesEnum,
    pageSizeValues,
    dashboardCardTitlePropertiesCssKey,
    dashboardCardDescriptionPropertiesCssKey,
    dashboardCardFootnotePropertiesCssKey,
    selectBoxOption,
    SELECT_LIST_FILTER_PROPERTIES_ENUM,
    TEXT_FILTER_PROPERTIES_ENUM,
    DROPDOWN_FILTER_PROPERTIES_ENUM,
    DATE_FILTER_PROPERTIES_ENUM,
    positionValues,
    paperSizeValues,
    SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM,
    chartPropertiesEnum,
} from "../commons/dashboardProperties";
import cloneDeep from "clone-deep";
import {
    panelObj,
    getChartContextMenuItems,
    getChartAggregationsByIsDrillDown,
    getGridLayoutMenuItems,
    GRID_LAYOUT_CONTEXT_MENU_KEYS,
    getTabPanelChartProperties,
    ChartPropertyFormat,
    setChartDetailList,
} from "../components/dashboard/dashboard";
import {
    filterPropKey,
    filterType,
    panelDataDetailsKey,
} from "../commons/filterComponents";
import { defaultTabData } from "../commons/dashboardDefaultData";
import { LoadingComponent } from "../store/modules/Visualize/General/loadingComponentDefinitions";
import ConfirmDialog from "../components/helper/ConfirmDialog.vue";
import { formatISOToDateTime } from "../util/moment";
//import DataModelCardItem from "../components/data/DataModelCardItem";
import DataModelPreviewTable from "../components/data/DataModelPreviewTable";
import DataModelRightPropertiesPanelCalculatedColumn from "../components/data/dataModelProperties/DataModelRightPropertiesPanelCalculatedColumn";
import DataModelSqlPreview from "../components/data/sql/DataModelSqlPreview";
import DataModelPropertiesPanelCalculatedColumnUsageInfo from "../components/data/dataModelProperties/DataModelPropertiesPanelCalculatedColumnUsageInfo";
import DataModelCalculatedColumnEditor from "../components/data/sql/DataModelCalculatedColumnEditor";
import DataModelTemporaryFiltersPopup from "../components/data/DataModelTemporaryFiltersPopup";
import { COMPONENT_PRIVILEGES, ROLES } from "../util/authorization";
import componentAvailabilityCheck from "../mixins/componentAvailabilityCheck";
import PivotTable from "../components/dashboard/table/PivotTable.vue";
import { getChartRefId } from "../util/chart-ref/chartRefHelper";
import Overlay from "../components/helper/Overlay.vue";
import {
    getFilterObj,
    getAggregationOrMetricListByChartType,
    chartListSelectedChartIsNotIn,
} from "../util/dashboard-filter/dashboardFilter.js";
import { addFiltersToFilterOrParamListOfCharts, createFilterDrillThrough } from "../util/dashboard-filter/dashboardDrillThroughFilter";
import DashboardDrillThrough from "../components/dashboard/DashboardDrillThrough.vue";
import FilterPaneFilterTitle from "../components/dashboard/chart/FilterPaneFilterTitle.vue";
import { AuthorizationDashboardEnum, AuthorizationDatamodelEnum, CardType} from "../util/homePageMappers";
import { CustomIcon } from "../assets/js/custom-icons";
import { DatamodelContextDefaults, datamodelPropertyKeys, getCommonDatamodelPropertyValueByKey, ContextMenuViewType, checkTypeSelectorDataItem, } from "../commons/dataModelTypes";
import { filterObjectProperty } from "../components/panel/filters/js/filters";
import deepEqual from "deep-equal";
import { formatDataModelQueryResults } from "../util/dataModelHelper";
import { isChartHeightLimited } from "../util/chartViewHelper"
import fullscreen from "vue-fullscreen";
import Vue from "vue";
import { getQueryStringParam, parseQueryStringTopbar, settingsBarParamKeys } from "../util/embeded/queryString";
import { toPng } from "html-to-image";
import { Notify } from "../commons/helper.js";
import { notificationType } from "../commons/notificationTypes";
import { checkDashboardName } from "../commons/helper";
import uuid from "uuid";
import DashboardChatGpt from "../components/dashboard/DashboardChatGpt"
import { chatGptInsightsBodyParam } from "../util/chatGptHelper";
import { cardPropertiesTemplates } from "../mocks/cardPropertiesTemplates";
import NotificationPopup from "../components/helper/NotificationPopup.vue"
import DashboardChartMessage from "../components/dashboard/DashboardChartMessage.vue";
import { SettingsLeftTabLinkEnum } from "../commons/settingsLeftTabLinkEnum.js";
import ButtonDialog from "../components/helper/ButtonDialog.vue";
import { DASHBOARD_GRID_DEFAULT_CONFIGURATIONS } from "../mocks/dashboardGridDefaultProperties.js";
import DashboardEmptyMessage from "../components/dashboard/DashboardEmptyMessage.vue";
import DashboardCardFootnote from "../components/dashboard/DashboardCardFootnote.vue";
import SchedulePopup from "../components/data/scheduleProperties/SchedulePopup.vue";
import { createSmartTitle } from "../util/panelDataPropertiesStyle.js";
import { configurationNameTypes } from "../components/settings/settings.js";
//import { SLOT_FIELD_ID_PREFIX } from "../util/data-table/tableCommonsHelper.js";

Vue.use(fullscreen);
let mouseXY = { x: null, y: null };
let DragPos = { x: null, y: null, w: 1, h: 1, i: null };
let layoutId = null;
let layoutInfo = null;

export default {
    mixins: [dashboardSearch, componentAvailabilityCheck],
    components: {
        DashboardParameters,
        NotificationPopup,
        Layout,
        DataModelCalculatedColumnEditor,
        DataModelRightPropertiesPanelCalculatedColumn,
        DataModelTemporaryFiltersPopup,
        DataModelPreviewTable,
        DataModelSqlPreview,
        DataModelPropertiesPanelCalculatedColumnUsageInfo,
        //DataModelCardItem,
        SettingsBar,
        DashboardProperties,
        DataModelTable,
        FilterText,
        FilterDropdown,
        FilterNumber,
        FilterDate,
        FilterSelectList,
        FilterButton,
        AdvancedButton,
        TabPanel,
        FilterChart,
        TextEditor,
        SelectorChart,
        GridLayout,
        GridItem,
        DashboardChartDragend,
        DashboardDatasetFields,
        DashboardCardTitle,
        DashboardFilterPaneIconBox,
        Chart,
        Logo,
        Summary,
        DataTable,
        ProjectSavePopup,
        ConfirmDialog,
        PivotTable,
        Overlay,
        FilterPaneFilterTitle,
        DashboardDrillThrough,
        DashboardChatGpt,
        DashboardChartMessage,
        ButtonDialog,
        DashboardEmptyMessage,
        DashboardCardFootnote,
        SchedulePopup
    },
    watch: {
        // selectedDataModel: {
        //     // init içerisinde çağrılıyor!! 
        //     // test edildikten sonra sorun olursa burası geri alınıcak!
        //     // handler(newVal, oldVal) { 
        //     //        if (newVal?.id && newVal.id !== oldVal?.id) {
        //     //            this.fetchDatamodelByIdFilterParams({
        //     //                loadingComponent: LoadingComponent.DatamodelFields,
        //     //                id: newVal.id,
        //     //            });
        //     //        } 
        //     //    },
        //     // deep: true,
        //     // immediate: true,
        // },
        isDatamodelPreviewVisible: {
            handler(val) {
                if (val && this.dataModelCards?.length) {
                    this.handleCardItemClick(this.dataModelCards[0].id);
                }
            },
        },
        calculatedColumnCreateVisible: {
            handler() {
              this.calculatedPreviewTableRows = [];
              this.calculatedPreviewTableColumns = [];
            },
          },
          selectedTabDesignMode: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal && !newVal) {
                    this.viewChart();
                } else if (newVal !== oldVal && newVal) {
                    this.dashboardTab.options = [];
                    this.searchResponse = {};
                }
            }
          },
          filterGridLayoutPositionValue: {
            handler(value) {
              if (value) {
                this.reRenderChart();
              }
            },
          },
          dashboardEditMode: {
            handler(value) {
              if (
                ![
                  routerEnums.DASHBOARD_VIEW,
                  routerEnums.DASHBOARD_VIEW_TABID,
                ].includes(this.$route.name) &&
                !value && this.selectedTabDesignMode
              ) {
                this.isSetDesignModeManually = true;
                this.setDashboardPropertiesValueByKey(
                  allPropertiesKeys.DASHBOARD_GRID_DESIGN_MODE,
                  false
                );
              } else if (
                ![
                  routerEnums.DASHBOARD_VIEW,
                  routerEnums.DASHBOARD_VIEW_TABID,
                ].includes(this.$route.name) &&
                value &&
                this.isSetDesignModeManually
              ) {
                //Edit mod kaleme basıp kapatılınca ama path hala dashboard/edit olduğunda design mode istenilen gibi çalışmıyor.
                //View halinde ki gibi otomatik search atılsın istendiği için aynı pathdeyken design mode set edilerek durum kurtarıldı.
                //Pathler düzeltince bu kısım kalkacak(kaleme basıldığında /edit'den path /view'a geçme durumu)!!!
                this.setDashboardPropertiesValueByKey(
                  allPropertiesKeys.DASHBOARD_GRID_DESIGN_MODE,
                  true
                );
                this.isSetDesignModeManually = false;
              }
            },
          },
          dashboardGridPdfPaperSize: {
            handler(value) {
              if (value) {
                this.guidlinePageList = [];
                this.addNewPaperFrame();
              }
            },
          },
          dashboardGridPdfShowGuidlines: {
            handler(value) {
              if (value) {
                this.guidlinePageList = [];
                this.addNewPaperFrame();
              }
            },
          },
          dashboardGridBgColor: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue && this.guidlinePageList?.length) {
                    this.guidlinePageList.forEach(page => {
                        page.backgroundColor = newValue;
                    });
                }
            }
          },
          selectedTabDashboardStyleCacheDurationInput: {
            async handler(newVal, oldVal) {
                if ((!newVal || !oldVal || newVal === oldVal)) return;

                this.reloadCacheInfo();
            }
          }
    },
    data() {
        return {
            undoRedoStackTypes: {
                UNDO: "UNDO",
                REDO: "REDO",
            },
            undoRedoChangedTabIndex: -1,
            moveOrResizeCounter: 0,
            isDataModelTemporaryFiltersPopupVisibleInCalculatedColumn: false,
            userExpression: {
              filter: null,
              level: null,
            },
            SettingsLeftTabLinkEnum: SettingsLeftTabLinkEnum,
            calculatedColumnDatasetId: null,
            isCalculatedColumnEditMode: false,
            editCalculatedColumnName: "",
            editCalculatedColumnId: "",
            textToBeAddedToSql: "",
            calculatedColumnsFunctionsMenuOpen: false,
            toolboxContextMenuItems: [
                {
                    text: "New calculation",
                    value: "NEW_CALCULATION",
                    iconCss: CustomIcon.CalculateAlt,
                },
                {
                    text: "New filter",
                    value: "NEW_FILTER",
                    iconCss: CustomIcon.FilterAltOutlined,
                },
            ],
            calculatedColumnCreateVisible: false,
            fetchedDashboardHighestPriority: null,
            fetchedDatamodelHighestPriority: null,
            fetchedDashboardAuthorities: [],
            fetchedDatamodelAuthorities: [],
            isChartsAvailable: true,
            isFullScreen: false,
            isDataModelTemporaryFiltersPopupVisible: false,
            dataModelTemporaryFiltersPopupSql: "",
            currentResizingChartId: null,
            allPropertiesKeys: allPropertiesKeys,
            dashboardEditMode: false,
            routerEnums: routerEnums,
            selectedChartI: null,
            propertiesChartTypeName: "generalPages.dashboard",
            propertiesChartType: "",
            tabIndex: 0,
            dataModelPopupIsActive: false,
            activeComponentStyle: {},
            fullScreenChartId: "",
            isActiveProjectNamePopup: false,
            dashboardComponentTypes: {
                GENERAL: "GENERAL",
                CHART: "CHART",
            },
            activeComponentType: "GENERAL",
            isChartRefreshTriggerActive: true,
            isChartViewModeChanged: true,
            dialogVisible: false,
            confirmLeave: false,
            beforeRouteLink: "",
            selectedCardItem: null,
            drillThroughFilterParam: {
                filterValue: {},
                filterId: null,
            },
            clickedChartItemName: "",
            contextClickedChartItemId: null,
            responseStatusType: "SUCCESS",
            settingsBarQueryParams: {
                [settingsBarParamKeys.embeded]: true,
                [settingsBarParamKeys.resize]: true,
                [settingsBarParamKeys.close]: true,
                [settingsBarParamKeys.filter]: true,
                [settingsBarParamKeys.settingsBar]: true,
                [settingsBarParamKeys.verticalThreeDot]: true
            },
            autoRefrestIntervalList: [],
            isExpandedChartBgTransparent: null,
            chartOldPositionY: null,
            chatGptSelectedChartId: null,
            chatGptInsightData: {},
            chatGptSummaryData: {},
            messages: [
                {
                    agent: "bot",
                    type: "text",
                    text: this.$t('generalPages.chatBotHelpMessage'),
                    disableInput: false,
                },
            ],
            folderId: null,
            chartListForDashboardFilter: [],
            exportTypes: {
                IMAGE: "IMAGE",
                PDF: "PDF",
              },
            exportableObject: "DASHBOARD",
            calculatedColumnFunctionName: "",
            calculatedPreviewTableColumns: [],
            calculatedPreviewTableRows: [],
            copiedChartObj: null,
            gridLayoutContextMenuId: "gridLayoutMenu_" + Math.random(),
            gridLayoutMenuItems: getGridLayoutMenuItems(),
            notificationType: notificationType,
            customIcon: CustomIcon,
            isDialogActive: null,
            buttonChartData: {},
            isChartFilterChannelViewActive: false,
            tempChartFilterChannel: {},
            tabPanelIds: {},
            selectedTabPanelTabChart: {},
            folderNameList: [],
            isDashboardLocked: false,
            confirmDialogType: null,
            confirmDialogTitle: null,
            confirmDialogMessage: null,
            confirmDialogTypes: {
                LOCK: {
                  type: DatamodelContextDefaults.OBJECT_LOCK,
                  title: "dialog.Lock Dashboard?",
                  message:
                    "dialog.This dashboard will be locked and other users will not be able to make changes Do you confirm?",
                },
                UNLOCK: {
                  type: DatamodelContextDefaults.OBJECT_UNLOCK,
                  title: "dialog.Unlock Dashboard?",
                  message:
                    "dialog.This dashboard will be unlocked and other users will be able to update it Do you confirm?",
                },
                UNSAVE_DASHBOARD: {
                  type: DatamodelContextDefaults.UNSAVE_DASHBOARD,
                  title: "dialog.unsaveTitle",
                  message: "dialog.unsaveMessage",
                }
            },
            guidlinePageList: [],
            popupScheduleVisible: false,
            scheduleEditForm: {},
            formatPaintChart: null,
            isSetDesignModeManually: false,
            dblClickedFormatPaint: false,
            evictionId: "",
            shouldRefreshCache: false,
            filterOnMount: false,
            selectedDropdownFilterList: [],
            selectedSelectListFilterList: [],
            isSelectAllActive: false,
            isInverseSelect: false,
            inversedDropdownFilterList: [],
            inversedSelectListFilterList: []
        };
    },
    beforeRouteLeave(to, from, next) {
        const resetDashboardToolsState = () => {
            if (to.name === "Home") {
                this.setFilterGridLayoutActive(false);
                this.setFilterGridLayoutPinIconActive(true);
                this.setDashboardSelectionPaneActive(false);
                this.setDashboardSelectionPinIconActive(false);
            }
        }
        if (this.hasDashboardIdAndEditAuthority || this.isUserAuthorityRead) {
            resetDashboardToolsState();
            next();
        } else {
            if (
                !deepEqual(this.dashboard, this.tempSelectedDashboard) && !to.meta.isNotSaveConfirmation
            ) {
                this.setConfirmDialog(this.confirmDialogTypes.UNSAVE_DASHBOARD);
                this.dialogVisible = true;
                this.beforeRouteLink = to.name;
                if (this.confirmLeave) {
                    resetDashboardToolsState();
                    next();
                } else {
                    next(false);
                }
            } else {
                resetDashboardToolsState();
                next();
            }
        }
    },
    created() {
        this.$root.$on("addFiltersToPanelFromPanel", this.addFiltersToPanels);
        this.$root.$on("changeChartFieldFromPanel", this.changeChartField);

        //filterOnMount props olarak kullanan filtre componentlerinden default filtre value'su eklenmemiş grafiklere filtre ekletmek için true set ediyoruz.
        this.filterOnMount = true;
    },
    beforeDestroy() {
        // Remove the event listener to avoid memory leaks
        window.removeEventListener('keydown', this.handleKeyDown);
        
        this.setActiveSettingTab(SettingsLeftTabLinkEnum.Visualize);
        this.autoRefrestIntervalList.forEach(element => {
            clearInterval(element)
        });

        this.resetDatasetFilterParams();
    },
    async mounted() {
        if (!this.$route.params.previousPagePanel) {
            // reset undo redo stack
            this.resetUndoRedoStacks();
        } else {
            this.$root.$off("changeChartFieldFromPanel", this.changeChartField);
            this.$root.$off("addFiltersToPanelFromPanel", this.addFiltersToPanels);
        }

        // Add event listener for keydown when the component is mounted
        window.addEventListener('keydown', this.handleKeyDown);

        await this.init();
        this.checkCalculatedColumnQueryParamsAndOpenPopup();
        this.setTabIndexNotFocusable();
        this.handleGrildLayoutDashboard();

        this.addNewPaperFrame();
    },
    computed: {
        ...mapGetters({
            testQueryResults: GETTER_TEST_QUERY.GET_TEST_QUERY_RESULTS,
            dashboard: GETTER_DASHBOARD.GET_DASHBOARD,
            dashboardList: GETTER_DASHBOARD.GET_DASHBOARDS,
            dashboardListByDatamodelId: GETTER_DASHBOARD.GET_DASHBOARD_LIST_BY_DATAMODEL_ID,
            selectedDataModel: GETTER_DATAMODEL.GET_SELECTED_DATAMODEL,
            dashboardFields: GETTER_DASHBOARD_FIELDS.GET_DASHBOARD_BY_ID_FIELDS,
            datamodelDatasetFields: GETTER_DATAMODEL.GET_DATAMODEL_BY_ID_FIELDS,
            loading: GETTER_GENERAL.GET_LOADING,
            tempStorageBykey: GETTER_TEMP_STORAGE.GET_TEMP_STORAGE_BY_KEY,
            encodeQueryParam: GETTER_FILTER.GET_ENCODE,
            decodeQueryParam: GETTER_FILTER.GET_DECODE,
            selectedFieldList: GETTER_DATASET_FIELDS.GET_SELECTED_DATASET_FIELDS,
            getterQueryParam: GETTER_GENERAL.GET_TOPBAR_QUERY_PARAM,
            configurationList: GETTER_CONFIGURATIONS.GET_CONFIGURATIONS,
            cardTemplates: GETTER_TEMPLATES.GET_TEMPLATES,
            activeSettingTab : GETTER_DASHBOARD.GET_ACTIVE_SETTING_TAB,
            isDashboardPropertiesActive: GETTER_DASHBOARD.GET_IS_DASHBOARD_PROPERTIES_ACTIVE,
            unAuthorizedDashboardFields: GETTER_DASHBOARD_FIELDS.GET_DASHBOARD_UNAUTHORIZED_FIELDS,
            datasetIds: GETTER_DATASET_FIELDS.GET_DATASET_IDS,
            getFilterGridLayoutProperties: GETTER_DASHBOARD.GET_FILTER_GRID_LAYOUT_PROPERTIES,
            getDashboardSelectionPaneProperties: GETTER_DASHBOARD.GET_DASHBOARD_SELECTION_PANE_PROPERTIES,
            datasetFilterParams: GETTER_DATAMODEL.GET_DATAMODEL_BY_ID_FILTER_PARAMS,
            dashboardUndoStack : GETTER_DASHBOARD.GET_DASHBOARD_UNDO_STACK,
            dashboardRedoStack : GETTER_DASHBOARD.GET_DASHBOARD_REDO_STACK,
        }),
        isMoveOrResizeCounterActive() {
            return this.moveOrResizeCounter === 1;
        },
        isSelectedActionWaitForButtonClick() {
            let isSelectedActionWaitForButtonClick = false;
            
            if (!this.filterChartList.length) return isSelectedActionWaitForButtonClick;

            this.filterChartList.forEach(chart => {
                if (!isSelectedActionWaitForButtonClick) {
                    isSelectedActionWaitForButtonClick = this.getSelectionAction(chart);
                }
            });
            
            return isSelectedActionWaitForButtonClick;
        },
        undoCount() {
            return this.dashboardUndoStack.length || 0;
        },
        redoCount() {
            return this.dashboardRedoStack.length || 0;
        },
        filteredCustomDatasetFilterParams() {
          return this.datasetFilterParams?.filter(
            (t) => t.filterType !== filterType.SECURITY && t.filterType !== filterType.TOKEN
          );
        },
        isShowOverlayActive() {
            return (
              (this.isDashboardSelectionPaneActive &&
                !this.isDashboardSelectionPanePinIconActive) ||
              (this.isFilterGridLayoutActive && !this.isFilterGridLayoutPinIconActive)
            );
        },
        isDashboardSelectionPaneActive() {
            return this.getDashboardSelectionPaneProperties?.isDashboardSelectionPaneActive;
        },
        isDashboardSelectionPanePinIconActive() {
            return this.getDashboardSelectionPaneProperties?.isDashboardSelectionPanePinIconActive;
        },
        isFilterGridLayoutActive() {
            return this.getFilterGridLayoutProperties?.isFilterGridLayoutActive;
        },
        isFilterGridLayoutPinIconActive() {
            return this.getFilterGridLayoutProperties?.isFilterGridLayoutPinIconActive;
        },
        getParametersFromFilters() {
            return this.filterChartList.map((f) => {
                const nameOfFilterParam = f.properties.style?.[SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_UNIQUE_NAME] 
                    ?? f.properties.style?.[DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_UNIQUE_NAME]
                    ?? f.properties.style?.[DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_UNIQUE_NAME]
                    ?? f.properties.style?.[TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_UNIQUE_NAME];

                return { name: nameOfFilterParam, id: f.id, usageType: "parameters" };
            })
        },
        isVisualizeAiIconDisabled() {
            const isVisualizeAIEnvActive = process.env.VUE_APP_IS_VISUALIZE_AI_ACTIVE;
            return !this.isDataModelAIActionsFeatureEnabled || isVisualizeAIEnvActive === "false" || !isVisualizeAIEnvActive;
        },
        isDataModelAIActionsFeatureEnabled() {
            return this.getDatamodelPropertyValue(datamodelPropertyKeys.ALLOW_AI_ACTIONS);
        },
        isOneDatasetExpandedActive() {
            return this.getDatamodelPropertyValue(
              datamodelPropertyKeys.KEEP_ONE_DATASET_EXPANDED
            );
        },
        configurationLLMType() {
            return this.configurationList.find(x => x.name === configurationNameTypes.LLM_TYPE);
        },
        isConfigurationUseCacheActive() {
            //Admin ekranındaki data cache içindeki use cache özelliği
            const cacheConnectionActive = this.configurationList.find(x => x.name === configurationNameTypes.IS_CACHE_CONNECTION_ACTIVE);
            
            return cacheConnectionActive ? JSON.parse(cacheConnectionActive?.value) : false;
        },
        configurationDefaultCacheDurationInMinute() {
            //Admin ekranındaki data cache içindeki use cache özelliği
            const cacheConnectionActive = this.configurationList.find(x => x.name === configurationNameTypes.DEFAULT_CACHE_DURATION_MIN);
            
            return cacheConnectionActive?.value;
        },
        datamodelListAndFolderListMerge() {
            return [
              ...this.dataModels.map((m) => {
                return { ...m, type: CardType.DATAMODEL };
              }),
              ...this.folders.map((m) => {
                return { ...m, type: CardType.FOLDER };
              }),
            ];
          },
        isPageDashboard() {
            return checkDashboardName(this.$route.name);
        },
        isQueryParamsRight() {
            const filteredTopbarQueryParams = Object.keys(
                this.settingsBarQueryParams
            ).filter(
                (f) =>
                    f !== settingsBarParamKeys.embeded && this.settingsBarQueryParams[f]
            );

            return filteredTopbarQueryParams.length;
        },

        isShowSettingsBar() {
            return this.settingsBarQueryParams.settingsBar && (this.isQueryParamsRight || this.isDashboardEditAvailable || this.hasTabs) ? true : false;
        },
        calculatedColumnDatasetAliasFieldNameMapping() {
            const mappingArr = [];

            this.fields?.[this.calculatedColumnDatasetId]?.forEach?.((s) =>
                mappingArr.push({ alias: s.alias, name: s.name })
            );

            return mappingArr;
        },
        tabSectionVisibility() {
            return this.selectedTabStyle?.HAS_TABS;
        },
        hasDashboardIdAndNotHaveReadAndEditAuthority() {
            return this.dashboardId &&
                this.isUserAuthorityNotRead &&
                !this.isUserAuthorityEdit
        },
        hasDashboardIdAndEditAuthority() {
            return this.dashboardId && this.isUserAuthorityEdit
        },
        projectSavePopupTitle() {
            return this.hasDashboardIdAndEditAuthority ? "generalPages.cloneDashboard" : "generalPages.saveDashboard"
        },
        selectedConnection() {
            return this.selectedDataModel?.datasets?.[0];
        },
        calculatedColumnConnectionType() {
            return this.selectedDataset?.connection?.type;
          },
        currentUserUsername() {
            return this.$store.getters["Security/Get/Keycloak/Username"];
        },
        currentUserGroups() {
            return this.$store.getters["Security/Get/Keycloak/Groups"];
        },
        currentUserRoles(){
            return this.$store.getters["Security/Get/Keycloak/Roles"];
        },
        isDashboardEditAvailable() {
            return (
                this.isComponentAvailable(COMPONENT_PRIVILEGES.DASHBOARD_PAGE_EDIT) &&
                this.isUserAuthorityNotRead
            );
        },
        hasAdminAuthority() {
            return this.currentUserRoles?.some((r) =>
                [ROLES.ADMIN.key].includes(r)
            );
        },
        isUserAuthorityNotRead() {
            return (
              this.hasAdminAuthority ||
              this.fetchedDashboardHighestPriority?.authority ===
                AuthorizationDatamodelEnum.OWNER ||
              (this.fetchedDashboardHighestPriority?.userCanEdit &&
                this.isObjectUnlockedOrLockedByCurrentUser)
            );
          },
          isObjectUnlockedOrLockedByCurrentUser() {
            return (
              !this.fetchedDashboardHighestPriority?.objectLocked ||
              this.currentUserUsername === this.dashboardLockingInfo?.lockCreatedBy
            );
          },
          isUserDatamodelAccessAuthority() {
            const restrictedPermissions = [
              AuthorizationDatamodelEnum.DENY,
              AuthorizationDatamodelEnum.RUN,
            ];
            return (
              this.hasAdminAuthority ||
              (this.fetchedDatamodelHighestPriority &&
                !restrictedPermissions.includes(
                  this.fetchedDatamodelHighestPriority?.authority
                ))
            );
          },
        isUserAuthorityRead() {
            return (
                this.fetchedDashboardHighestPriority?.authority === AuthorizationDashboardEnum.READ
            );
        },
        isUserAuthorityEdit() {
            return (
                this.fetchedDashboardHighestPriority?.authority === AuthorizationDashboardEnum.EDIT
            );
        },
        dataModelPreviewTableColumns() {
            return this.testQueryResults?.length
                ? Object.keys(this.testQueryResults[0]).map((t) => ({
                    label: this.dashboardFields?.[this.selectedDataSetId]?.find((x) => x.name == t)?.alias || t,
                    field: t,
                    format: this.dashboardFields?.[this.selectedDataSetId]?.find((x) => x.name == t)?.format,
                }))
                : [];
        },
        previewTableRows() {
            return formatDataModelQueryResults(this.testQueryResults, this.dataModelPreviewTableColumns);
        },
        selectedDataset() {
            return this.datasets?.find((d) => d.datasetId === this.selectedCardItem);
        },
        dataModelCards() {
            return this.datasets?.map((dm) => ({
                status: "uploading",
                type: dm.type,
                id: dm.datasetId,
                name: dm.name,
                columns: dm.fields?.length ?? 0,
                rows: dm.rows ?? 0,
                lastUpdate: formatISOToDateTime(dm.connection?.updateDate) ?? "N/A",
            }));
        },
        isDatamodelPreviewVisible() {
            return this.activeSettingTab == SettingsLeftTabLinkEnum.DataModel || this.activeSettingTab == SettingsLeftTabLinkEnum.Parameters;
        },
        tempSelectedDashboard() {
            return this.tempStorageBykey(TEMP_STORAGE_KEYS.TEMP_SELECTED_DASHBOARD);
        },
        tempDatasetFields() {
            return this.tempStorageBykey(TEMP_STORAGE_KEYS.TEMP_DATASET_FIELDS);
        },
        selectedDataSetId() {
            return this.selectedDataModel?.datasets[0]?.datasetId;
        },
        datasets() {
            let selectedDatamodel = JSON.parse(JSON.stringify(this.selectedDataModel))
            return selectedDatamodel.datasets;
        },
        fields() {
            return this.dashboardId ? this.dashboardFields : this.tempDatasetFields;
        },
        isHomepageSearchListLoading() {
            return this.loading[LoadingComponent.HomepageList];
        },
        isDashboardSaveLoading() {
            return this.loading[LoadingComponent.DashboardSave];
        },
        isDatamodelFieldsLoading() {
            return this.loading[LoadingComponent.DatamodelFields]
        },
        isRunSqlLoading() {
            return this.loading[LoadingComponent.RunSql]
        },
        dashboardId() {
            return this.$route.params.id ?? this.dashboard.id;
        },
        isModeDashboardView() {
            return !this.dashboardEditMode ? true : false;
        },
        selectedTemplateId() {
            return this.dashboard.tabs[this.tabIndex].properties?.style?.[
                allPropertiesKeys.SELECTED_TEMPLATE_ID
            ];
        },
        mergedCardTemplateList(){
            return [...this.cardTemplates , ...cardPropertiesTemplates];
        },
        selectedTemplateProperties() {
            return (
                this.mergedCardTemplateList.find((d) => d.id === this.selectedTemplateId)
                    ?.properties?.style ?? cardPropertiesTemplates[0]?.properties?.style
            );
        },
        selectedTabAllPanelList() {
            return this.dashboard.tabs?.[this.tabIndex]?.charts;
        },
        selectedTabPanelTabChartList() {
            return this.selectedTabAllPanelList?.filter(
                (x) => x?.properties?.isTabPanelTabChart
            );
        },
        selectedTabPanelList() {
            return this.selectedTabAllPanelList?.filter(
                (x) => !x?.properties?.isFilterPaneChart && !x?.properties?.isTabPanelTabChart
            );
        },
        selectedTabFilterGridLayoutCharts() {
            return this.selectedTabAllPanelList?.filter((x) => x?.properties?.isFilterPaneChart) ?? [];
        },
        filterPaneList() {
            const droppedChartIndex = this.getDroppedChartIndex();
            //Chart Henüz sürükle bırak aşamasındadır.
            if(droppedChartIndex !== -1) return;

            const clonedDashboard = cloneDeep(this.dashboard);
            const uiTabId = clonedDashboard.tabs[this.tabIndex]?.properties?.uiTabId;
            let allTabCharts = [];
            clonedDashboard?.tabs?.forEach(tab => {
                const selectedTabFilteredCharts = tab?.charts?.filter(
                    (f) =>
                      !f.properties?.notShowFilterPane &&
                      f?.details?.[filterPropKey.FILTER_VALUE]
                  );
                selectedTabFilteredCharts.forEach(chart => {
                    const applyToTabKey = Object.keys?.(chart?.properties?.style)?.find(
                        (x) => x.includes("APPLY_TO_TABS")
                      );
                    const applyToTabs = chart?.properties?.style?.[applyToTabKey];
                    const activeApplyToTab = applyToTabs?.includes(uiTabId);
                    const hasAllItemApplyToTab = applyToTabs?.includes(selectBoxOption.ALL_ITEM);
                    const tabContainingTheChart = chart?.properties?.uiTabId === uiTabId;

                    if (chart?.properties?.isFilterPaneChart && tabContainingTheChart) return;
                    if ((!this.isChartTypeFilter(chart?.type) && tabContainingTheChart) ||
                        (this.isChartTypeFilter(chart?.type) && tabContainingTheChart && !activeApplyToTab?.length)) {
                        allTabCharts.push(chart);
                      } else if (this.isChartTypeFilter(chart?.type) && (activeApplyToTab || hasAllItemApplyToTab)) {
                        allTabCharts.push(chart);
                      }
                });
            });

            return [...allTabCharts];
        },

        selectedTab() {
            return this.dashboard.tabs;
        },
        dashboardTabs() {
            return this.dashboard.tabs;
        },
        selectedTabStyle() {
            return this.dashboard.tabs?.[this.tabIndex]?.properties?.style;
        },
        selectedTabDesignMode() {
            return this.selectedTabStyle?.[allPropertiesKeys.DASHBOARD_GRID_DESIGN_MODE];
        },
        selectedTabDashboardStyleCacheDurationInput() {
            return this.selectedTabStyle?.[allPropertiesKeys.DASHBOARD_CACHE_DURATION_INPUT];
        },
        selectedDashboardDynamicLeftMenuWidth() {
            return this.selectedTabStyle?.[
              allPropertiesKeys.DASHBOARD_DATAMODEL_LEFT_MENU_WIDTH
            ]
              ? this.selectedTabStyle?.[
                  allPropertiesKeys.DASHBOARD_DATAMODEL_LEFT_MENU_WIDTH
                ]
              : 0;
        },
        isActiveDashboardorDataSet() {
            if (
                this.dataModelPopupIsActive &&
                this.$route.name != routerEnums.DASHBOARD_EDIT &&
                this.$route.name != routerEnums.DASHBOARD_VIEW
            ) {
                return true;
            } else return false;
        },
        dashboardGrid() {
            return this.selectedTabStyle?.[dashboardGridPropertiesEnum.DASHBOARD_GRID_HAS_GRID_LINES] &&
                !this.isModeDashboardView ? "vis-dashboard-grid" : ""
        },
        hasTabs() {
            return this.selectedTabStyle?.[allPropertiesKeys.HAS_TABS];
        },
        dashboardGridBgColor() {
            return this.selectedTabStyle?.[dashboardGridPropertiesEnum.DASHBOARD_GRID_BACKGROUND_COLOR];
        },
        canvasStyle() {
            const gridHeight = this.selectedTabStyle?.[pageSizePropertiesEnum.HEIGHT];
            const gridWidth = this.selectedTabStyle?.[pageSizePropertiesEnum.WIDTH];
            const gridPageSizeType = this.selectedTabStyle?.[pageSizePropertiesEnum.PAGE_SIZE];
            
            return {
                [propertiesKeyToCSSKeyMapping.BACKGROUND_COLOR]: this.dashboardGridPdfShowGuidlines && !this.isModeDashboardView ? 'transparent' : this.dashboardGridBgColor,
                [propertiesKeyToCSSKeyMapping.HEIGHT]: gridHeight && gridPageSizeType === pageSizeValues.CUSTOM ? gridHeight + 'px' : 100 + '%',
                [propertiesKeyToCSSKeyMapping.WIDTH]: gridWidth && gridPageSizeType === pageSizeValues.CUSTOM ? gridWidth + 'px' : 100 + '%',
                "overflow": gridPageSizeType === pageSizeValues.CUSTOM ? "hidden !important" : ""
            };
        },
        filterPaneMainStyle() {
            const gridPageSizeType = this.selectedTabStyle?.[pageSizePropertiesEnum.PAGE_SIZE];

            if (gridPageSizeType !== pageSizeValues.CUSTOM || (!this.dashboardSelectionPaneActiveAndPinned && !this.filterGridLayoutActiveAndPinned)) {
                return;
            }

            let asideWidth, navWidth, totalWidth;
           
            asideWidth = document.querySelector?.("aside")?.offsetWidth ?? 300; //right area
            navWidth = document.querySelector?.("nav")?.offsetWidth ?? 220; //left area
            totalWidth = asideWidth + navWidth;

            const inherit = "inherit";
            const commonStyle = {
                width: inherit,
                height: 100 + "vh"
            }

            const styleValuesByKey = {
                [positionValues.RIGHT]: {
                    left: inherit,
                    right: !this.isModeDashboardView ? asideWidth + 'px' : 0,
                    ...commonStyle
                },
                [positionValues.LEFT]: {
                    left: !this.isModeDashboardView ? navWidth + 'px' : 0,
                    right: inherit,
                    ...commonStyle
                },
                [positionValues.BOTTOM]: {
                    top: inherit,
                    bottom: 0
                },
            }

            return {
                position: "fixed",
                left: !this.isModeDashboardView ? navWidth + 'px' : 0,
                top: 96 + 'px', //topbar ve header yükseklik toplamı
                width: !this.isModeDashboardView ? `calc(100% - ${totalWidth}px)` : 100 + '%',
                zIndex: 10,
                ...styleValuesByKey[this.filterGridLayoutPositionValue]
            }
        },
        filterGridLayoutWidth() {
            return this.getDashboardPropertiesValueByKey(
                dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_WIDTH,
                DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_FILTER_PANE_WIDTH
            ) + 'px';
        },
        filterGridLayoutHeight() {
            return Number(this.getDashboardPropertiesValueByKey(
                dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_HEIGHT,
                DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_FILTER_PANE_HEIGHT
            ));
        },
        contentPaddingByFilterGridLayout() {
            if (!this.isFilterGridLayoutActive || !this.isFilterGridLayoutPinIconActive) return;

            const topPositionPaddingSum = this.dashboardSelectionPaneActiveAndPinned ? this.filterGridLayoutHeight + this.dashboardSelectionPaneOffsetHeight() : this.filterGridLayoutHeight;
            
            if (this.checkFilterGridLayoutPositionByParam(positionValues.RIGHT)) {
                return { paddingRight: this.filterGridLayoutWidth + '!important' }
            } else if (this.checkFilterGridLayoutPositionByParam(positionValues.LEFT)) {
                return { paddingLeft: this.filterGridLayoutWidth + '!important' }
            } else if (this.checkFilterGridLayoutPositionByParam(positionValues.TOP)) {
                return { paddingTop: `${topPositionPaddingSum}px` + '!important' }
            } else return { paddingBottom: `${this.filterGridLayoutHeight}px` + '!important' }
        },
        filterPaneStyle() {
            let asideWidth, navWidth, totalWidth;

            asideWidth = document.querySelector?.("aside")?.offsetWidth ?? 300; //right area
            navWidth = document.querySelector?.("nav")?.offsetWidth ?? 220; //left area
            totalWidth = asideWidth + navWidth;

            return {
                position: "fixed",
                top: "96px",
                left: this.isModeDashboardView ? "0" : navWidth + "px",
                width: this.isModeDashboardView ? "100%" : `calc(100% - ${totalWidth}px)`
            };
        },
        filterGridLayoutStyle() {
            const leftRightCommonStyle = {
                width: this.filterGridLayoutWidth,
                maxHeight: "100%",
                height:"100%"
            };

            const topBottomCommonStyle = {
                width: "100%",
                maxHeight: "300px",
                height: `${this.filterGridLayoutHeight}px`,
                left: 0
            }

            if (this.dashboardSelectionPaneActiveAndPinned) {
                leftRightCommonStyle.paddingTop = this.dashboardSelectionPaneOffsetHeight() + "px";
            }
           
            if (this.checkFilterGridLayoutPositionByParam(positionValues.RIGHT)) {
                return {...leftRightCommonStyle, right: 0, left: "inherit" }
            } else if (this.checkFilterGridLayoutPositionByParam(positionValues.LEFT)) {
                return { ...leftRightCommonStyle, left: 0, right: "inherit" }
            } else if (this.checkFilterGridLayoutPositionByParam(positionValues.TOP) && (!this.isDashboardSelectionPanePinIconActive || !this.isDashboardSelectionPaneActive)) {
                return { ...topBottomCommonStyle, top: 0 };
            } else if (this.checkFilterGridLayoutPositionByParam(positionValues.TOP) && this.dashboardSelectionPaneActiveAndPinned) {
                return { ...topBottomCommonStyle, top: this.dashboardSelectionPaneOffsetHeight() + 'px' };
            } else return { ...topBottomCommonStyle, bottom: 0, top: "inherit" };
        },
        filterGridLayoutPositionRightOrLeft() {
            return [positionValues.LEFT, positionValues.RIGHT].includes(this.filterGridLayoutPositionValue);
        },
        dashboardSelectionPaneActiveAndPinned() {
            return this.isDashboardSelectionPanePinIconActive && this.isDashboardSelectionPaneActive;
        },
        filterGridLayoutActiveAndPinned() {
            return this.isFilterGridLayoutActive && this.isFilterGridLayoutPinIconActive;
        },
        filterGridLayoutIconBoxMainClassByPositionTop() {
            return [positionValues.TOP, positionValues.BOTTOM].includes(this.filterGridLayoutPositionValue) && this.isFilterGridLayoutActive ? 'vis-filter-gridLayout-iconBox-absolute' : '';
        },
        filterGridLayoutPositionValue() {
            return this.getDashboardPropertiesValueByKey(
              dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_POSITION,
              DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_FILTER_PANE_POSITION
            );
        },
        allowSelectionPaneInView() {
            return this.getDashboardPropertiesValueByKey(
                dashboardGridPropertiesEnum.DASHBOARD_GRID_SELECTION_PANE_VIEW_MODE,
                DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_SELECTION_PANE_VIEW_MODE
            );
        },
        allowFilterGridLayoutInView() {
            return this.getDashboardPropertiesValueByKey(
                dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_GRID_LAYOUT_VIEW_MODE,
                DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_FILTER_GRID_LAYOUT_VIEW_MODE
            );
        },
        isAllowSelectionPaneShown() {
            return this.isDashboardSelectionPaneActive;
        },
        isPanelPage() {
            return this.$route.name == routerEnums.PANEL;
        },
        drillThroughFilterValueLength() {
            return Object.keys(this.drillThroughFilterParam?.filterValue)?.length;
        },
        chartContextMenuItems() {
            let foundChartDataWithContextMenuClicked = this.selectedTabAllPanelList.find(p => p.i == this.contextClickedChartItemId);

            if (this.unAuthorizedDashboardFields?.size) {
                foundChartDataWithContextMenuClicked = getFilteredPanelDataByAuthorize({
                    chartData: foundChartDataWithContextMenuClicked,
                    unAuthorizedDashboardFields: this.unAuthorizedDashboardFields
                  });
            }
             
            return getChartContextMenuItems(
                this.dashboardListByDatamodelId,
                this.clickedChartItemName,
                foundChartDataWithContextMenuClicked,
                this.fields
            );
        },
        filterCount() {
            const sumCount = this.filterPaneList?.reduce((prev, current) => {

                const filterValue = current?.details?.[filterPropKey.FILTER_VALUE];
                
                const notShowFilterPane = current?.properties?.notShowFilterPane; //Filter panede gözükmediği için count'a da dahil olmayacak.

                if (filterValue && !notShowFilterPane) return prev + Object.keys(filterValue)?.length;

                return prev;
            }, 0);

            return (
                sumCount +
                Object.keys(this.drillThroughFilterParam?.filterValue)?.length
              );
        },
        filterGridLayoutFilterValueLength() {
            const sumCount = this.selectedTabFilterGridLayoutCharts?.reduce((prev, current) => {

                const filterValue = current?.details?.[filterPropKey.FILTER_VALUE];
                
                if (filterValue) return prev + Object.keys(filterValue)?.length;

                return prev;
            }, 0);

            return sumCount;
        },
        filterChartList() {
            return this.selectedTabAllPanelList.filter(x => x?.type?.toLowerCase().includes(panelTypes.FILTER));
        },
        dashboardLockingInfo() {
            return {
              lockCreatedDate: this.dashboard?.lockCreatedDate,
              lockCreatedBy: this.dashboard?.lockCreatedBy,
            };
          },
          dashboardGridPdfPaperSize() {
            return this.getDashboardPropertiesValueByKey(
                dashboardGridPropertiesEnum.DASHBOARD_GRID_PAPER_SIZE,
                DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_PAPER_SIZE
              );
          },
          dashboardGridPdfShowGuidlines() {
            return this.getDashboardPropertiesValueByKey(
                dashboardGridPropertiesEnum.DASHBOARD_GRID_SHOW_GUIDLINES,
                DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_SHOW_GUIDLINES
              );
          },
          pdfHeightCalculationByPaperSize() {
            return this.dashboardGridPdfPaperSize === paperSizeValues.A4 ? window.innerWidth / 1.414 : window.innerWidth / 1.299;
          },
    },
    methods: {
        ...mapActions({
            fetchTestQuery: ACTION_TEST_QUERY.FETCH_TEST_QUERY,
            fetchApiCall: ACTION_API_CALL.API_CALL,
            addDashboard: ACTION_DASHBOARD.CREATE_DASHBOARD,
            updateDashboard: ACTION_DASHBOARD.UPDATE_DASHBOARD,
            fetchDashboards: ACTION_DASHBOARD.FETCH_DASHBOARDS,
            fetchDashboardListByDatamodelId: ACTION_DASHBOARD.FETCH_DASHBOARD_LIST_BY_DATAMODEL_ID,
            getDashboardById: ACTION_DASHBOARD.FETCH_DASHBOARDS_BY_ID,
            getHighestPriorityByDashboardId: ACTION_DASHBOARD.FETCH_HIGHEST_PRIORITY_BY_DASHBOARD_ID,
            getHighestPriorityByDatamodelId: ACTION_DATAMODEL.FETCH_HIGHEST_PRIORITY_BY_DATAMODEL_ID,
            fetchDataModelById: ACTION_DATAMODEL.FETCH_DATAMODEL_BY_ID,
            fetchDatamodelByIdFields: ACTION_DATAMODEL.FETCH_DATAMODEL_BY_ID_FIELDS,
            deleteCalculated: ACTION_DASHBOARD.DELETE_DASHBOARD_CALCULATION,
            fetchDatasetIds: ACTION_DATASET.FETCH_DATASET_IDS,
            fetchDashboardByIdFields:
                ACTION_DASHBOARD_FIELDS.FETCH_DASHBOARD_BY_ID_FIELDS,
            duplicateDashboardDatamodelField:
                ACTION_DASHBOARD_FIELDS.DUPLICATE_DASHBOARD_DATAMODEL_FIELD,
            createDashboardField: ACTION_DASHBOARD_FIELDS.CREATE_DASHBOARD_FIELDS,
            updateDashboardField: ACTION_DASHBOARD_FIELDS.UPDATE_DASHBOARD_FIELDS,
            fetchDashboardAuthorizationById:
                ACTION_AUTHORIZATION.FETCH_DASHBOARD_AUTHORIZATION_BY_ID_WITHOUT_MUTATE,
            encode: ACTION_FILTER.FETCH_ENCODE,
            decode: ACTION_FILTER.FETCH_DECODE,

            createCalculatedField: ACTION_CALCULATED_COLUMN.CREATE_CALCULATED_FIELD,
            updateCalculatedField: ACTION_CALCULATED_COLUMN.UPDATE_CALCULATED_FIELD,
            validateCalculatedField: ACTION_CALCULATED_COLUMN.VALIDATE_CALCULATED_FIELD,

            getChatGptInsightsStore: ACTION_CHAT_GPT.FETCH_CHAT_GPT_INSIGHTS,
            getChatGptSummaryStore: ACTION_CHAT_GPT.FETCH_CHAT_GPT_SUMMARY,
            getChatGptChatBotStore: ACTION_CHAT_GPT.FETCH_CHAT_GPT_CHATBOT,
            generateAI: ACTION_CHAT_GPT.GENERATE_AI,

            fetchConfigurationsStore: ACTION_CONFIGURATIONS.FETCH_CONFIGURATIONS,

            createExportNotification: ACTION_EXPORT_METADATA.CREATE_EXPORT_NOTIFICATION,

            fetchTemplates: ACTION_TEMPLATES.FETCH_TEMPLATES,
            fetchDatamodelAuthorizationById: ACTION_AUTHORIZATION.FETCH_DATAMODEL_AUTHORIZATION_BY_ID_WITHOUT_MUTATE,
            fetchDatamodelByIdFilterParams: ACTION_DATAMODEL.FETCH_DATAMODEL_BY_ID_FILTER_PARAMS,
            fetchObjectLock: ACTION_OBJECT_LOCK.FETCH_OBJECT_LOCK,
            createObjectLock: ACTION_OBJECT_LOCK.CREATE_OBJECT_LOCK,
            deleteObjectLock: ACTION_OBJECT_LOCK.DELETE_OBJECT_LOCK,

            createSchedule: ACTION_SCHEDULE.CREATE_SCHEDULE,
        }),
        ...mapMutations({
            resetTestQuery: MUTATION_TEST_QUERY.RESET_TEST_QUERY,
            pushPanel: MUTATION_DASHBOARD.SET_DASHBOARD_PANELS,
            updatePanelList: MUTATION_DASHBOARD.UPDATE_DASHBOARD_PANEL_LIST,
            setPanels: MUTATION_DASHBOARD.SET_PANELS,
            setDashboard: MUTATION_DASHBOARD.SET_DASHBOARD,
            setDashboardWithoutUndoRedo: MUTATION_DASHBOARD.SET_DASHBOARD_WITHOUT_UNDO_REDO,
            setTempStorageByKey: MUTATION_TEMP_STORAGE.SET_TEMP_STORAGE_BY_KEY,
            setSelectedPanel: MUTATION_DASHBOARD.SET_SELECTED_PANEL,
            setTemStorageDashboardFields: MUTATION_TEMP_STORAGE.SET_TEMP_STORAGE_DATASET_FIELDS,
            updateTemStorageDashboardFields: MUTATION_TEMP_STORAGE.UPDATE_TEMP_STORAGE_DATASET_FIELDS,
            resetTempStorageDashboardFields: MUTATION_TEMP_STORAGE.RESET_TEMP_STORAGE_DATASET_FIELDS,
            addSelectedField: MUTATION_DATASET.ADD_SELECTED_FIELD,
            resetSelectedFields: MUTATION_DATASET.RESET_SELECTED_FIELDS,
            setActiveSettingTab : MUTATION_DASHBOARD.SET_ACTIVE_SETTING_TAB,
            toggleDashboardPropertiesActive: MUTATION_DASHBOARD.SET_DASHBOARD_PROPERTIES_ACTIVE,
            setDashboardUnAuthorizedFields: MUTATION_DASHBOARD_FIELDS.SET_DASHBOARD_UNAUTHORIZED_FIELDS,
            setFilterGridLayoutActive: MUTATION_DASHBOARD.SET_FILTER_GRID_LAYOUT_ACTIVE,
            setFilterGridLayoutPinIconActive: MUTATION_DASHBOARD.SET_FILTER_GRID_LAYOUT_PIN_ICON_ACTIVE,
            setDashboardSelectionPaneActive: MUTATION_DASHBOARD.SET_DASHBOARD_SELECTION_PANE_ACTIVE,
            setDashboardSelectionPinIconActive: MUTATION_DASHBOARD.SET_DASHBOARD_SELECTION_PANE_PIN_ICON_ACTIVE,
            resetDatasetFilterParams: MUTATION_DATAMODEL.RESET_DATAMODEL_FILTER_PARAMS,
            dashboardUndo: MUTATION_DASHBOARD.DASHBOARD_UNDO,
            dashboardRedo: MUTATION_DASHBOARD.DASHBOARD_REDO,
            addNewItemToUndoStack: MUTATION_DASHBOARD.ADD_NEW_ITEM_TO_UNDO_STACK,
            resetUndoRedoStacks: MUTATION_DASHBOARD.RESET_UNDO_REDO_STACKS,
        }),
        resetAllControllers() {
            this.isSelectAllActive = false;
            this.isInverseSelect = false;
        },
        async reloadCacheInfo() {
            this.evictionId = this.dashboard.id,
            this.shouldRefreshCache = true;
            
            await this.viewChart();

            this.evictionId = "";
            this.shouldRefreshCache = false;

            if (this.dialogVisible) this.dialogVisible = false;
        },
        onPropertiesEventTriggered() {
            this.reloadCacheInfo();
        },
        // Column based formattings setter method
        setDataTableColumnBasedComponentProperties(params, chartData) {
            if (this.isModeDashboardView) return;
    
            const slotFieldId = params?.fieldData?.slotFieldId;
            const fieldId = params?.fieldData?.fieldId;
            const clonedChartData = cloneDeep(chartData);

            if (!clonedChartData.properties?.columnBasedFormattings) {
              clonedChartData.properties.columnBasedFormattings = [];
            }

            const findExistingColumnBasedFormattings = () => {
              return clonedChartData.properties.columnBasedFormattings.find((f) =>
                slotFieldId ? f.slotFieldId === slotFieldId : f.fieldId === fieldId
              );
            };
            const filterExistingColumnBasedFormattingsByColumn = () => {
              return clonedChartData.properties.columnBasedFormattings.filter((f) =>
                slotFieldId ? f.slotFieldId !== slotFieldId : f.fieldId !== fieldId
              );
            };
            const updateColumnBasedFormattings = () => {
              let foundExistingColumnBasedFormattings =
                findExistingColumnBasedFormattings();

              if (!foundExistingColumnBasedFormattings) {
                foundExistingColumnBasedFormattings = {
                  formattings: {},
                  fieldId: fieldId,
                  slotFieldId: slotFieldId,
                };
              }

              foundExistingColumnBasedFormattings.formattings[params.property] =
                params.value;

              return foundExistingColumnBasedFormattings;
            };

            const updatedColumnBasedFormattingsByColumn =
              filterExistingColumnBasedFormattingsByColumn();

            updatedColumnBasedFormattingsByColumn.push(
              updateColumnBasedFormattings()
            );

            clonedChartData.properties.columnBasedFormattings =
              updatedColumnBasedFormattingsByColumn;

            this.updateSelectedPanel(
              clonedChartData,
              clonedChartData.properties?.tabIndex
            );
          },
        getCacheInfo(chart) {
            return getCacheInfoByPropertyStyle({
              isConfigurationUseCacheActive: this.isConfigurationUseCacheActive,
              configurationDefaultCacheDurationInMinute: this.configurationDefaultCacheDurationInMinute,
              chart,
              datamodelProperties: this.selectedDataModel?.properties,
              dashboardPropertiesStyle: this.selectedTabStyle,
              evictionId: this.evictionId,
              shouldRefreshCache: this.shouldRefreshCache,
            });
        },
        getWarningMessageIsMetricEmpty(chartData) {
            if (chartData.i === "drop" || this.isMoveOrResizeCounterActive) return;
            
            return checkChartMetricEmpty({ chartData, message: this.$t('notifyTitle.Please choose selector values to view chart'), showMandatoryMessage: false });
        },
        changeChartField(params) {
            const clonedDashboard = cloneDeep(params?.dashboard ?? this.dashboard);
            const chartsToSendSearchRequest = [];

            //For mandatory value is not make empty - START
            const singleMandotory = !params.isMultiple && params?.isMandatorySelection && !params?.field;
            const multipleMandatory = params.isMultiple && params?.isMandatorySelection && !params?.field?.length;
            
            if (singleMandotory || multipleMandatory) {
                Notify(this.$t('notifyMessages.You have to select at least one item'), notificationType.WARNING);
                return;
            } 
            //For mandatory value is not make empty - END
            
            //selectorChartI eklenen field hangi data item selector chartından gelmiştir onu belirtir.
            clonedDashboard?.tabs.forEach(tab => {
                tab?.charts.forEach(chart => {
                    const clonedParams = cloneDeep(params);

                    if (chart.type === chartTypes.SELECTOR_DATA_ITEM_CHART) {
                        if (chart.i === clonedParams?.selectorChartI) {
                            chart.details[detailsKeys.selectorDataitemValue] = clonedParams?.selectorDataitemValue;
                        }

                        this.updateSelectedPanel(chart, chart.properties?.tabIndex);
                        return;
                    } 
                   
                    //x.fieldId, panelde, dataset ağacındaki parameters collapsesi altındaki slotlara eklenen parametar'lardır.
                    //Her bir parameter bir selector data item chartına denk geliyor.
                    const foundMetricParameter = chart?.details?.metrics?.find(x=> x.fieldId === params?.selectorChartI);
                    const foundAggParameter = chart?.details?.aggregation?.find(x=> x.fieldId === params?.selectorChartI);
                    const foundColumnParameter = chart?.details?.columns?.find(x=> x.fieldId === params?.selectorChartI);

                    const setChartNameAndSearchRequestList = (chart) => {
                        chart.name = createSmartTitle(chart);
                        chartsToSendSearchRequest.push(chart.i);
                    }
                    const getAggAndColumnsFilter = (item) => {
                        return (
                          checkTypeSelectorDataItem(item.fieldType) ||
                          !item.selectorChartI ||
                          (item.selectorChartI &&
                            item.selectorChartI !== params?.selectorChartI)
                        );
                      };
                      //setChartDetailList çağırılmadan önce details öğlerine filtre yapmamızın sebebi, dataitem içinden eklediğimiz bir önceki field'ların silinmesi için 
                    if (foundColumnParameter && chart.type === chartTypes.TABLE) {
                        chart.details.columns = chart.details.columns.filter(x=> getAggAndColumnsFilter(x));
                        chart.details.metrics = chart.details.columns.filter(x=> x.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE);
                        chart.details.aggregation = chart.details.columns.filter((x) =>
                            [
                              DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
                              DatamodelContextDefaults.USAGE_TYPES.DATE,
                            ].includes(x.fieldUsageType)
                          );
                                                  
                        setChartDetailList(chart, [], foundColumnParameter.boxKey, clonedParams);
                        setChartNameAndSearchRequestList(chart);
                    } else if (foundMetricParameter) {
                        chart.details.metrics = chart.details.metrics.filter(x=> checkTypeSelectorDataItem(x.fieldType) || !x.selectorChartI);
                        setChartDetailList(chart, chart.details.metrics, foundMetricParameter?.boxKey, clonedParams);
                        setChartNameAndSearchRequestList(chart);
                    } else if (foundAggParameter) {
                        chart.details.aggregation = chart.details.aggregation.filter(x=> getAggAndColumnsFilter(x));
                        setChartDetailList(chart, chart.details.aggregation, foundAggParameter?.boxKey, clonedParams);
                        setChartNameAndSearchRequestList(chart);
                    }

                    this.updateSelectedPanel(chart, chart.properties?.tabIndex);
                });
            });
            
            if (chartsToSendSearchRequest?.length && !params?.triggerFromPanel) {
                this.viewChart(...chartsToSendSearchRequest);
            }
        },
        handleKeyDown(event) {
            if (this.isModeDashboardView) return;
            
            // Check if 'Ctrl + Z' or 'Cmd + Z' (on macOS) is pressed
            if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
                event.preventDefault(); // Prevent default browser behavior
                this.handleDashboardUndo();
            }

            // Check if 'Ctrl + Y' or 'Cmd + Y' (on macOS) is pressed
            if ((event.ctrlKey || event.metaKey) && event.key === 'y') {
                event.preventDefault(); // Prevent default browser behavior
                this.handleDashboardRedo();
            }
        },
        handleDashboardUndo() {
            if (this.undoCount > 0) {
                this.triggerFocus(this.undoRedoStackTypes.UNDO);
                this.dashboardUndo();
                this.setTabIndex(this.undoRedoChangedTabIndex); // Update the current tab index
                this.reRenderChart();
                this.viewChart();
            }
        },
        handleDashboardRedo() {
            if (this.redoCount > 0) {
                this.triggerFocus(this.undoRedoStackTypes.REDO);
                this.dashboardRedo();
                this.setTabIndex(this.undoRedoChangedTabIndex); // Update the current tab index
                this.reRenderChart();
                this.viewChart();
            }
        },
        triggerFocus(stackType) {
            const stack = stackType === this.undoRedoStackTypes.UNDO
                ? this.dashboardUndoStack 
                : this.dashboardRedoStack;
            const tabs = stack[stack.length - 1]?.tabs || [];
            const currentTabs = this.dashboard.tabs;
        
            this.undoRedoChangedTabIndex = -1;
        
            const getTabKey = (tab) => tab.id || tab.uniqueIdentifier; // Adjust key based on your tab structure
        
            const tabsMap = new Map(tabs.map((tab) => [getTabKey(tab), tab]));
            const currentTabsMap = new Map(currentTabs.map((tab) => [getTabKey(tab), tab]));
        
            // Find the first mismatched tab by comparing tab keys
            for (const key of new Set([...tabsMap.keys(), ...currentTabsMap.keys()])) {
                if (!deepEqual(tabsMap.get(key), currentTabsMap.get(key))) {
                    this.undoRedoChangedTabIndex = Array.from(tabsMap.keys()).indexOf(key);
                    break;
                }
            }

            if (this.undoRedoChangedTabIndex === -1) {
                this.undoRedoChangedTabIndex = 0;
            }
        },        
        handleDashboardDatasetView(datasetId) {
            this.handleCardItemClick(datasetId);
            this.callRunSqlWithSelectedDataset();
        },
        addNewPaperFrame() {
            // SEARCH "GRID PDF SETUP PAPER SIZE" IN DASHBOARD.VUE
            if (this.isModeDashboardView || !this.dashboardGridPdfShowGuidlines) return;
      
            if (!this.guidlinePageList.length) {
              this.guidlinePageList.push({
                id: uuid(),
                paperSize: this.dashboardGridPdfPaperSize,
                top: 0,
                height: this.pdfHeightCalculationByPaperSize,
                backgroundColor: this.dashboardGridBgColor,
                className: "vis-grid-pdf-frame",
              });
            }
      
            setTimeout(() => {
              const gridLayout = document.getElementById("gridLayoutDashboard");
              const a4Pages = document.querySelectorAll(".vis-grid-pdf-frame");
              let totalHeight = 0;
      
              if (!a4Pages?.length) return;
      
              const addOrRemovePagesList = () => {
                a4Pages.forEach((element) => {
                  totalHeight += element.offsetHeight;
                });
      
                if (gridLayout.offsetHeight >= totalHeight) {
                  const top = totalHeight;
      
                  this.guidlinePageList.push({
                    id: uuid(),
                    paperSize: this.dashboardGridPdfPaperSize,
                    top: top,
                    height: this.pdfHeightCalculationByPaperSize,
                    backgroundColor: this.dashboardGridBgColor,
                    className: "vis-grid-pdf-frame",
                  });
      
                  this.$nextTick(() => {
                    addOrRemovePagesList();
                  });
                } else {
                  this.guidlinePageList = this.guidlinePageList.filter(
                    (x) => x.top <= gridLayout.offsetHeight
                  );
                }
              };
      
              addOrRemovePagesList();
            }, 300);
        },
        checkCalculatedColumnQueryParamsAndOpenPopup() {
            const openCalculatedColumnDatasetId = this.$route.query?.calculatedColumnDatasetId;

            if (openCalculatedColumnDatasetId) {
                const firstFieldFromCard = this.fields?.[openCalculatedColumnDatasetId]?.[0];

                this.setSettingLeftTab(SettingsLeftTabLinkEnum.DataModel);
                this.openCreateCalculatedColumn(firstFieldFromCard, false);
            }
        },
        handleSchedule(scheduleFormData) {
            delete scheduleFormData.taskDefinitionDto.cronDto;
            
            this.createSchedule({ bodyParam: scheduleFormData });
          },
        getChartContextMenuItemsByChart(chartData) {
            if (chartData?.i === "drop" || this.isMoveOrResizeCounterActive) return;
            
            let foundChartDataWithContextMenuClicked = this.selectedTabAllPanelList.find(p => p.i == this.contextClickedChartItemId);

            if (this.unAuthorizedDashboardFields?.size) {
                foundChartDataWithContextMenuClicked = getFilteredPanelDataByAuthorize({
                    chartData: foundChartDataWithContextMenuClicked,
                    unAuthorizedDashboardFields: this.unAuthorizedDashboardFields
                  });
            }
            
            const styleKeys = Object.keys(chartData?.properties?.style);
            const foundStyleKey = styleKeys.find(x=> x.includes(includesValues.DRILL_THROUGH_DASHBOARD_LIST));

            return getChartContextMenuItems(
                chartData?.properties?.style[foundStyleKey],
                this.clickedChartItemName,
                foundChartDataWithContextMenuClicked,
                this.fields
            );
        },
        viewChartForTable(chartData) {
            if (!chartData?.i) return;
            //Tabloda ilk açılmada render sorunu olduğu için burası geçici olarak yazıldı. Çözüm bulundıktan sonra buna gerek kalmayacak.
            const templateCell = document.querySelectorAll(`.ejs-table-container${chartData.i} tbody tr td.e-rowcell.e-templatecell`);

            if (this.isPanelTypeTable(chartData) && !templateCell?.length) this.viewChart(...[chartData.i]);
        },
        updateChartFootnote(chartData, value) {
            const clonedChart = cloneDeep(chartData);

            clonedChart.footnote = value;

            this.updateSelectedPanel(clonedChart);
        },
        getSelectedTabPanelTabChart(chartI) {
            //Tap panel içindeki seçilen tab'a göre "setSelectedTabPanelTabChart" içinde set edilmiş chartı elde etmek için.
            const tabPanelTabId = this.tabPanelIds?.[chartI];
            return this.selectedTabPanelTabChart?.[tabPanelTabId];
        },
        changeTabPanelTabId(selectedTabId, chartI) {
            //Birden fazla tab panel olacağı için tabPanelIds nesnesinin key değerine o tab panel'in id'sini set ediyoruz.
            //Value'a ise hangi tab seçili ise onu set etmiş oluyoruz.
            this.tabPanelIds[chartI] = selectedTabId;
        },
        setSelectedTabPanelTabChart(selectedTabId, chartI) {
            //Tab panelde, seçilen tab'ın id'si ile kayıtlı olan chart'ı bulup this.selectedTabPanelTabChart'a onu set etmek ve tab panelde o chartı görüntülemek için
            if (!chartI || !selectedTabId) return;

            const foundChart = this.selectedTabAllPanelList.find(x=> x.properties?.connectedChartId === chartI && x.properties?.tabPanelTabId === selectedTabId);
            
            if (!foundChart) return;

            this.$set(this.selectedTabPanelTabChart, selectedTabId, foundChart);
        },
        addTabPanelNewTab(chart) {
            const clonedSelectedChart = cloneDeep(chart);
            const newTabId = 'panelTabId' + uuid();

            clonedSelectedChart.properties.tabList.push({id: newTabId, name: "Tab" + clonedSelectedChart.properties.tabList.length});

            this.updateSelectedPanel(clonedSelectedChart);

            this.changeTabPanelTabId(newTabId, clonedSelectedChart.i);
        },
        deleteTabPanelTabByTabId(tabPanelTabId, chart) {
            const clonedSelectedChart = cloneDeep(chart);

            const chartWillDeleteId = this.selectedTabAllPanelList.find(x=> x?.properties?.connectedChartId === chart.i && x?.properties?.tabPanelTabId === tabPanelTabId);
                
            if (chartWillDeleteId?.i) this.deleteChart(chartWillDeleteId.i);

            clonedSelectedChart.properties.tabList = clonedSelectedChart.properties.tabList.filter(x=> x.id !== tabPanelTabId);

            this.updateSelectedPanel(clonedSelectedChart);
        },
        chaneTabPanelTabList(chart, tabPanelTabList) {
            const clonedSelectedChart = cloneDeep(chart);

            clonedSelectedChart.properties.tabList = tabPanelTabList;

            this.updateSelectedPanel(clonedSelectedChart);
        },
        setSelectedTabPanelTabName(chart, param) {
            const clonedSelectedChart = cloneDeep(chart);

            clonedSelectedChart.properties.tabList.find(x=> x.id === param.tabId).name = param.value;

            this.updateSelectedPanel(clonedSelectedChart);
        },
        startFilterPaneVisible(isDashboardRenderActive = false) {
            const startWithSelectionPaneVisible = this.getDashboardPropertiesValueByKey(
                dashboardGridPropertiesEnum.DASHBOARD_GRID_START_WITH_SELECTION_PANE_VISIBLE,
                DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_START_WITH_SELECTION_PANE_VISIBLE
              );

            if (startWithSelectionPaneVisible) {
                if (this.isModeDashboardView) {
                    this.setDashboardSelectionPaneActive(true);
                    this.setDashboardSelectionPinIconActive(true);
                } else {
                    this.setDashboardSelectionPaneActive(false);
                    this.setDashboardSelectionPinIconActive(false);
                }
                if (this.filterGridLayoutPositionRightOrLeft && isDashboardRenderActive) {
                    this.reRenderChart();
                }
            } else {
                this.setDashboardSelectionPaneActive(false);
                this.setDashboardSelectionPinIconActive(false);
            }
        },
        startFilterGridLayoutVisible(isDashboardRenderActive = false) {
            const startWithFilterGridLayoutVisible = this.getDashboardPropertiesValueByKey(
                dashboardGridPropertiesEnum.DASHBOARD_GRID_START_WITH_FILTER_GRID_LAYOUT_VISIBLE,
                DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_START_WITH_FILTER_GRID_LAYOUT_VISIBLE
              );
              
            if (startWithFilterGridLayoutVisible) {
                if (this.isModeDashboardView) {
                    this.setFilterGridLayoutActive(true);
                    this.setFilterGridLayoutPinIconActive(true);
                } else {
                    this.setFilterGridLayoutActive(false);
                }
                if (this.filterGridLayoutPositionRightOrLeft && isDashboardRenderActive) {
                    this.reRenderChart();
                }
            } else {
                this.setFilterGridLayoutActive(false);
            }
        },
        checkFilterGridLayoutPositionByParam(positionParam) {
            return this.filterGridLayoutPositionValue === positionParam;
        },
        dashboardSelectionPaneOffsetHeight() {
            let offsetHeight = this.$refs?.dashboardSelectionPaneRef?.offsetHeight;

            if (offsetHeight <= 0 || !offsetHeight) offsetHeight = 80;

            return offsetHeight;
        },
        checkFilterPaneChart(chart) {
            return chart?.properties?.isFilterPaneChart;
        },
        getDashboardPropertiesValueByKey(key, defaultValue) {
            return this.selectedTabStyle?.[key] === undefined ? defaultValue : this.selectedTabStyle?.[key];
        },
        setDashboardPropertiesValueByKey(key, value) {
            return this.selectedTabStyle[key] = value;
        },
        goToViewDatamodel() {
            let routeData = this.$router.resolve({
                name: routerEnums.DATAMODEL_VIEW,
                params: { id: this.dashboard.datamodel_id }
            });

            window.open(routeData.href, "_blank");
        },
        getDatamodelPropertyValue(datamodelPropertyKey) {
            return getCommonDatamodelPropertyValueByKey(this.selectedDataModel?.properties, datamodelPropertyKey);
        },
        checkHasSearchResponseError(chartData) {
            if (this.isMoveOrResizeCounterActive) return;
            
            return this.searchResponseError?.[chartData?.i]?.message || this.checkHasMetricFieldIsAuthorizeAndGetMessage(chartData);
        },
        checkHasMetricFieldIsAuthorizeAndGetMessage(chartData) {
            //Search servisinden hata almayan veya search servisine istek atmayan chartlar için
            if (
              this.isPanelTypeSummary(chartData) ||
              this.isPanelTypeFilterDate(chartData) ||
              this.isPanelTypeFilterText(chartData) ||
              this.isPanelTypeSelector(chartData)
            ) {
              const clonedChartData = this.getPanelDataByAuthorize(chartData);
              if (
                clonedChartData?.details?.metrics?.length !==
                chartData?.details?.metrics?.length
              ) {
                return "dap_ec_126_not_authorized_to_access_field";
              }
            } else return false;
          },
        setTabIndexWithButton(uiTabId) {
            const foundTabIndex = this.dashboard.tabs.findIndex(x=> x.properties.uiTabId === uiTabId);
            this.tabIndex = foundTabIndex;
            this.viewChart();
        },
        redirectToUrlWithButton(url) {
            const newUrl = !url?.substring(0, 4)?.includes('http') ? `https://${url}` : url;
            window.open(newUrl, '_blank');
        },
        openButtonDialog(chartData) {
            this.buttonChartData = chartData;
            this.isDialogActive = true;
        },
        hasSearchAggregationsLength(i) {
            const selectedChart = this.getSelectedChartById(i);
            if (!selectedChart?.details?.metrics?.length) return "true";
            //for success-search attribute control to backend with selenium
            return this.searchResponse?.[i]?.aggregations?.length ? "true" : "false"
        },
        viewChartWithDesignModeStartButton() {
            this.isDesignModeSearchRequestActive = true;
            this.viewChart();
        },
        getDroppedChartIndex() {
            const charts = this.checkIsFilterGridLayoutActive()
              ? this.selectedTabFilterGridLayoutCharts
              : this.selectedTabPanelList;

            return charts?.findIndex((item) => item.i === "drop");
          },
        getChartTabInformation(chartData) {
            const selectedTabUiTabId = this.dashboard.tabs[this.tabIndex]?.properties?.uiTabId;
            //filtre componenti bulunduğu tab dışında farklı bir tab'ı etkiliyorsa, filter pane alanında info iconu çıkartmak için kontrol ediyoruz.
            const isActiveInformation = chartData?.properties?.uiTabId !== selectedTabUiTabId;
            //filtre componenti hangi tab içindeyse, o tab'ın ismini almak için find işlemi yapıyoruz.
            const tabName = this.dashboard.tabs.find((t) => t?.properties?.uiTabId === chartData?.properties?.uiTabId)?.name;

            return { tabName, isActiveInformation };
        },
       async duplicateFieldById(param) {
            await this.duplicateDashboardDatamodelField({
              dashboardId: this.dashboardId,
              bodyParam: { id: param?.id ?? param?.fieldId },
            });

            await this.fetchDashboardByIdFields(this.dashboardId);
          },
        handleGrildLayoutDashboard(){
            document
            .getElementById("gridLayoutDashboard")
            ?.addEventListener("contextmenu", (e) => {
              if(this.copiedChartObj) {
                layoutInfo = e?.target;
                this.openGridLayoutContextMenu({x:e.clientX,y:e.clientY});
              }
              window.event.returnValue = false;
            });
        },
        getGridLayoutContextMenu() {
            return document.getElementById(this.gridLayoutContextMenuId).ej2_instances[0];
        },
        openGridLayoutContextMenu(event) {
            this.getGridLayoutContextMenu().open(event.y, event.x);
        },
        copyChart(chartObj) {
            this.copiedChartObj = chartObj;
            Notify(this.$t("notifyTitle.Chart Copied"),  notificationType.INFO);  
            this.closePopoverMenu();
        },
        cutChart(chartData) {
            this.copyChart(chartData);
            this.deleteChart(chartData?.i);
        },
        inverseSelectChart(currentSelect, totalList, chartType) {
            if (!currentSelect) {
                this.selectAllChart(totalList, chartType);
            } else {
                this.isInverseSelect = true;
                const isDropdownFilter = chartType === dashboardFilterTypes.FILTER_DROPDOWN;
                const inversedFilterList = [];

                totalList.forEach((x) => inversedFilterList.push(x.value));
                const filteredList = inversedFilterList.filter((item) => !currentSelect.includes(item));

                if (isDropdownFilter) {
                    this.inversedDropdownFilterList = filteredList;
                } else {
                    this.inversedSelectListFilterList = filteredList;
                }
                this.closePopoverMenu();
            }
            
        },
        selectAllChart(totalList, chartType ) {
            this.isSelectAllActive = true;

            if (chartType === dashboardFilterTypes.FILTER_DROPDOWN) {
                this.selectedDropdownFilterList = [];
                totalList.forEach((x) => this.selectedDropdownFilterList.push(x.value));
            } else {
                this.selectedSelectListFilterList = [];
                totalList.forEach((x) => this.selectedSelectListFilterList.push(x.value));
            }
            this.closePopoverMenu();
        },
        selectGridLayoutContextItem(args) {
            const key = args?.item?.value;
            
            const addPanelListByCopiedChartObj = () => {
              const selectedTab = this.dashboardTabs[this.tabIndex];
              let chartList = cloneDeep(this.selectedTabAllPanelList);
              const clonedCopiedCharatObj =  cloneDeep(this.copiedChartObj);

              clonedCopiedCharatObj.i = "temp_" + uuid();
              clonedCopiedCharatObj.properties.generatedDivid = "chrt_" + uuid();
              clonedCopiedCharatObj.properties.tabIndex = this.tabIndex;
              clonedCopiedCharatObj.properties.uiTabId = selectedTab?.properties?.uiTabId;

              //Tab panel içine paste yapılan chart'lar için çalışır.
              if (layoutInfo.getAttribute("chart-type") === chartTypes.TAB_PANEL) {
                const dragAndDropTabPanel =  this.selectedTabAllPanelList.find(x=> x.i === layoutInfo.id);
                clonedCopiedCharatObj.properties = {...clonedCopiedCharatObj.properties,  ...getTabPanelChartProperties(layoutInfo, dragAndDropTabPanel)}
              }

              if (clonedCopiedCharatObj?.id) {
                delete clonedCopiedCharatObj.id;
                delete clonedCopiedCharatObj.tabId;
              } 
                
              chartList.push(clonedCopiedCharatObj);
              
              this.updatePanel(chartList);
              this.viewChart(clonedCopiedCharatObj.i)
              this.getGridLayoutContextMenu().close();
            };

            if (key === GRID_LAYOUT_CONTEXT_MENU_KEYS.PASTE) {
                this.addNewItemToUndoStack(cloneDeep(this.dashboard));
                addPanelListByCopiedChartObj();
            }
          },
        setDashboardTemplate() {
            const clonedDashboard = cloneDeep(this.dashboard);
            let selectedTemplate = {};

            let selectedDashboardTemplate = this.getDashboardTemplate(clonedDashboard);
      
            if (this.dashboardId && !selectedDashboardTemplate) {
              selectedTemplate = {
                [allPropertiesKeys.SELECTED_TEMPLATE_ID]:
                  cardPropertiesTemplates[0]?.id,
              };
            } else if (!this.dashboardId) {
              selectedTemplate = {
                [allPropertiesKeys.SELECTED_TEMPLATE_ID]:
                  this.cardTemplates?.find((x) => x.isDefault)?.id ||
                  cardPropertiesTemplates[0]?.id,
              };
            }
      
            clonedDashboard.tabs[this.tabIndex].properties.style = {
              ...clonedDashboard.tabs[this.tabIndex].properties.style,
              ...selectedTemplate,
            };
      
            this.setDashboardWithoutUndoRedo(clonedDashboard);
          },
          getDashboardTemplate(clonedDashboard) {
            let selectedTemplateId =
              clonedDashboard.tabs[this.tabIndex]?.properties?.style?.[
                allPropertiesKeys.SELECTED_TEMPLATE_ID
              ];
      
            return this.cardTemplates.find((x) => x.id === selectedTemplateId);
          },
        checkBreadcrumb(id, name) {
            if ((!id || id === 'root') && !name) {
                this.folderNameList = [];
            }
            if (id && name) {
                const selectedBreadcrumb = this.folderNameList.find((x) => x.id == id);
                if (!selectedBreadcrumb) {
                    this.folderNameList.push({ id, name });
                } else {
                    const selectedFolderIndex = this.folderNameList.findIndex(
                        (y) => y.id == selectedBreadcrumb.id
                    );
                    this.folderNameList = this.folderNameList.filter(
                        (f, index) => index <= selectedFolderIndex
                    );
                }
            }
        },
        setFolderId(id) {
            this.folderId = id;
        },
        setCalculatedColumnFunctionName(value) {
            this.calculatedColumnFunctionName = value;
        },
        async getChatGptInsights() {
            const bodyParam = {
                prompt: "Aşağıdaki veri setini analiz et ve bana 10 tane işime yarayacak bilgi ver." + JSON.stringify(this.searchResponse[this.chatGptSelectedChartId].aggregations),
                ...chatGptInsightsBodyParam
            }

            this.chatGptInsightData = await this.getChatGptInsightsStore({ loadingComponent: LoadingComponent.ChatGpt, bodyParam });
        },
        async getChatGptSummary() {
            const bodyParam = {
                sql: JSON.stringify(this.searchResponse[this.chatGptSelectedChartId].sql),
                language: this.$i18n.locale
            }

            this.chatGptSummaryData = await this.getChatGptSummaryStore({ queryParam: this.configurationLLMType?.value, bodyParam });
        },
        async getChatGptInsightsWithChatBot(value) {
            const bodyParam = {
                "question": value,
                "dataset": this.searchResponse[this.chatGptSelectedChartId].aggregations
            }

            const result = await this.getChatGptChatBotStore({
                loadingComponent: LoadingComponent.ChatGpt,
                queryParam: this.configurationLLMType.value,
                bodyParam
            });

            this.addMessageToChatBox({
                agent: 'bot',
                type: 'text',
                text: result.answer,
                disableInput: false,
            })
        },
        addMessageToChatBox(message) {
            this.messages.push(message)
        },
        chartCardContentByChartHeightClass(chartData) {
            if (this.isPanelTypeButton(chartData.type) || this.isPanelTypeLogo(chartData)  || this.isPanelTypeTextEditor(chartData)) return "vis-height-100";
            else if (this.isPanelTypePivotTable(chartData)) return '';
            else if (this.isPanelTypeTabPanel(chartData)) return "vis-chart-card-content-tab-panel";
            return !isChartHeightLimited(chartData?.h) && !this.isPanelTypeSummary(chartData) ? "vis-chart-card-content-by-height" : ''
        },
        //Context Menuden sağ click ile drilldown seçildiğinde çalışır.
        changeChartAggregation(param) {
            const activeFieldInAggregation = param.value.activeFieldInAggregation;
            const activeAggregations = [];
            const selectedChart = this.getSelectedChartById(param.chartId);
            const aggregationField = getAggregationField(getSelectedField(this.fields, activeFieldInAggregation.datasetId, activeFieldInAggregation.fieldId), param.value.selectedField.boxKey)
            const chartIdsToRender = this.selectedTabAllPanelList.filter(x => x.i == selectedChart.i).map((m) => m.i)

            //Aggregation içindeki veriyi tutuyoruz çünkü  ilk haline geri dönmek için.
            selectedChart.details.defaultAggregation = getChartAggregationsByIsDrillDown(selectedChart)

            activeAggregations.push(aggregationField)

            selectedChart.details.aggregation = activeAggregations;

            this.updateSelectedPanel(selectedChart);

            this.viewChart(...chartIdsToRender)
        },
        setTabIndexNotFocusable() {
            const collapseHeaders = document.getElementsByClassName("el-collapse-item__header");

            if (!collapseHeaders?.length) return;

            Array.from(collapseHeaders).forEach(c => c.tabIndex = "-1");
        },
        getKeyByIncludes(obj, includesValue) {
            let keys = Object.keys(obj?.properties?.style);
            return keys.find(x => x.includes(includesValue));
        },
        replaceValueByFilterChartParameter(chartData) {
            if (chartData?.i === "drop" || this.isMoveOrResizeCounterActive) return;

            const dataFromDataBase = chartData?.properties?.style?.TEXT_EDITOR_DATA_FROM_DATABASE ?? false;
            
            let richTextValue = cloneDeep(chartData?.properties?.style?.RICH_TEXT);

            if (dataFromDataBase || !richTextValue) return null;
            
            this.filterChartList.forEach(filterChart => {
                const key = filterChart.properties.style[this.getKeyByIncludes(filterChart, includesValues.UNIQUE_NAME)];
                const value = filterChart?.details?.FILTER_VALUE?.[filterChart?.details?.metrics[0]?.field]?.value;
                const noSelectionContentValue = filterChart?.properties?.style?.[chartPropertiesEnum.FILTER_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT];

                if (richTextValue.includes(key) && value) {
                    richTextValue = richTextValue.replaceAll(`&lt;${key}&gt;`, value);
                } else if (!value) {
                    richTextValue = richTextValue.replaceAll(`&lt;${key}&gt;`, noSelectionContentValue ?? `<${key}>`);
                }
            });
           
            return richTextValue;
        },
        getDynamicChartTitle(item) {
            if (item.i === "drop" || this.isMoveOrResizeCounterActive || !this.getDashboardTabOptionsById(item.i)) return;
           
            this.getLabelChartTextAreaHeight();

            let dynamicTitleValue = item?.properties?.style?.[this.getKeyByIncludes(item, includesValues.DYNAMIC_TITLE_VALUE)];
            const dynamicTitleShowValue = item?.properties?.style?.[this.getKeyByIncludes(item, includesValues.DYNAMIC_TITLE_SHOW_VALUE)];

            if (dynamicTitleShowValue && typeof dynamicTitleValue == "string") {
                const groupMultipleValues = item?.properties?.style?.[this.getKeyByIncludes(item, includesValues.GROUP_MULTIPLE_VALUES)];
                const noSelectionContent =  item?.properties?.style?.[this.getKeyByIncludes(item, includesValues.NO_SELECTION_CONTENT)];

                const mappedFilterChartList = this.filterChartList.map((f) => {
                    return {
                        value: f?.details?.FILTER_VALUE?.[f?.details?.metrics[0]?.field]?.value,
                        key: f.properties.style[this.getKeyByIncludes(f, includesValues.UNIQUE_NAME)]
                    }
                })

                mappedFilterChartList.forEach(element => {
                    let filterValue = "";

                    if (element?.value?.length > 1 && typeof element?.value !== "string") {
                        groupMultipleValues ? 
                        filterValue = `${element.value[0]} (+${element.value.length - 1})`
                        :
                        filterValue = element.value.join(",")
                    } else filterValue = element.value;
                    
                    dynamicTitleValue = dynamicTitleValue.replaceAll(`<${element.key}>`, filterValue );

                });
                if (dynamicTitleValue.includes(undefined) && noSelectionContent) {
                    dynamicTitleValue = noSelectionContent;
                  } else if (
                    dynamicTitleValue.includes(undefined) &&
                    !noSelectionContent
                  ) {
                    dynamicTitleValue = dynamicTitleValue.replaceAll(undefined, "");
                  }
                return { value: dynamicTitleValue, dynamicTitleShowValue };

            } else return { value: item.name, dynamicTitleShowValue };

        },
        async init() {
            addEventListener('resize', () => {
                this.reRenderChart();
            });

            document.addEventListener(
                "dragover",
                function (e) {
                    if (e?.target?.id !== '') {
                        layoutId = e?.target?.id;
                        layoutInfo = e?.target;
                    }
                    mouseXY.x = e.clientX;
                    mouseXY.y = e.clientY;
                },
                false
            );

            if ([routerEnums.DASHBOARD_VIEW, routerEnums.DASHBOARD_VIEW_TABID].includes(this.$route.name)) {
                this.dashboardEditMode = false;
            } else {
                this.dashboardEditMode = true;
            }

            if (!this.$route.params.previousPagePanel) {
                await this.fetchConfigurationsStore();

                this.fetchTemplates();
            }

            this.getDashboardHighestPriority();

            this.parseQueryParamOnDashboardPage();

            this.getLocaleStorageQuerParam();

            this.getDashboardAuthorities();

            const queryTabIndex = Number(this.$route.query.tabIndex);
            if (queryTabIndex >= 0) {
                this.tabIndex = queryTabIndex;
            }

            if (this.dashboardId && !this.$route.params.previousPagePanel) {
                await this.getDashboardById({
                    loadingComponent: LoadingComponent.DashboardPage,
                    dashboardId: this.dashboardId,
                });
                //#region  FOR ONLY USE BACKEND SCHEDULE SCREEN SHOT
                if (this.$route?.params?.tabId) {
                    const foundTabIndex = this.dashboard?.tabs?.findIndex(x=> x.id === this.$route?.params?.tabId);
                    this.tabIndex = foundTabIndex;
                }
                //#endregion FOR ONLY USE BACKEND SCHEDULE SCREEN SHOT 
                const clonedDashboard = cloneDeep(this.dashboard);
                
                this.setTempStorageByKey({
                    key: TEMP_STORAGE_KEYS.TEMP_SELECTED_DASHBOARD,
                    value: clonedDashboard,
                });

                await this.fetchDatasetIds(this.dashboard.datamodel_id);
            }

            await this.checkingDashboardLock();

            if (!this.dashboard.datamodel_id) this.dataModelPopupIsActive = true;

            if (this.dashboard.datamodel_id) {
                await this.fetchDataModelById(this.dashboard.datamodel_id);

                this.getDatamodelAuthorities();

                this.getDatamodelHighestPriority();

                if (!this.$route.params.previousPagePanel && !this.$route.params.dashboardFromHomepageWithDatamodelId) {
                    await this.getFields();
                } else if (this.$route.params.dashboardFromHomepageWithDatamodelId) {
                    this.resetTempStorageDashboardFields();
                    await this.fetchDatamodelByIdFields(this.dashboard.datamodel_id);
                }
            }
            this.setCanvasStyle();
            //Dashnoard datamodel içerisinde kullanılmak için 
            this.resetTestQuery();

            if (!this.$route.params.previousPagePanel) {
                this.setDashboardTemplate();
                
                //UPDATE ALL CHARTS DETAILS (FOR AUTHORIZE FIELD)
                this.changeDashboardMetaDataByHideField();
                
                const isDashboardRenderActive = true;

                this.startFilterPaneVisible(isDashboardRenderActive);
                this.startFilterGridLayoutVisible(isDashboardRenderActive);
              }

            //For drill through filter to work
            if (this.$route.query.filterCode) {
                await this.parseQueryStringFilterParam();
            } else {
                await this.viewChart();
            }
            
            //Drill through altındaki go to ile açılan listeledeki dashboardları çağırmak için.
            if (!this.$route.params.previousPagePanel && this.dashboard?.datamodel_id) {
                await this.fetchDashboardListByDatamodelId({
                    loadingComponent: null,
                    dataModelId: this.dashboard.datamodel_id
                })
            }

            this.getLabelChartTextAreaHeight();

            this.getAutoRefreshIntervalViewChart();
        },
        async changeDashboardMetaDataByHideField() {
            if (!this.dashboardId) return;
            
            let allDashboardFields = [];
            
            Object.keys(this.fields).forEach(element => {
                allDashboardFields = [...allDashboardFields, ...this.fields[element]];
            });
           
            const hideOrShowDashboardFields = allDashboardFields?.filter(
              (x) => !x.isAuthorize
            );
                
            if (!hideOrShowDashboardFields?.length) return;

                const hiddenFieldMap = new Map();

                hideOrShowDashboardFields.forEach(field => {
                    hiddenFieldMap.set(field.fieldId, field.name)
                });
                
                this.setDashboardUnAuthorizedFields(hiddenFieldMap);
          },
          getPanelDataByAuthorize(chartData) {
            if (!this.unAuthorizedDashboardFields?.size || this.isMoveOrResizeCounterActive) return chartData;

            return getFilteredPanelDataByAuthorize({
                chartData,
                unAuthorizedDashboardFields: this.unAuthorizedDashboardFields,
              });
          },
        async getAllFieldListByIds() {
            if (this.dashboardId) {
              await this.fetchDashboardByIdFields(this.dashboardId);
            } else {
              await this.fetchDatamodelByIdFields(this.dashboard.datamodel_id);
            }
        },

        openCreateCalculatedColumn(field, addAliasToSql = true) {
            if (this.dashboardId) {
              this.calculatedColumnCreateVisible = false;
              const selectedField = this.dataModelCards?.find(
                (card) => card.id === field?.datasetId
              );
      
              if (selectedField) this.handleCardItemClick(selectedField?.id);
      
              setTimeout(() => {
                this.isCalculatedColumnEditMode = false;
                this.editCalculatedColumnName = "";
                this.editCalculatedColumnId = "";
                this.calculatedColumnCreateVisible = true;
                this.setTextToBeAddedToSql(addAliasToSql ? `[${field?.alias}]` : " ");
                this.userExpression = {
                  filter: [],
                  level: [],
                };
                this.calculatedColumnDatasetId = field?.datasetId;
              }, 300);
            } else {
              Notify(
                this.$t(
                  "notifyMessages.To create a calculated area, you must first save the dashbaord"
                ),
                notificationType.ERROR
              );
            }
          },
        async openEditCalculatedColumn(field) {
            this.calculatedColumnCreateVisible = false;
            const selectedField = this.dataModelCards?.find(card => card.id === field?.datasetId);

            if (selectedField)
                this.handleCardItemClick(selectedField?.id);

            setTimeout(() => {
                this.isCalculatedColumnEditMode = true;
                this.calculatedColumnCreateVisible = true;
                this.editCalculatedColumnName = field?.dashboardId ? field?.name : "";
                this.editCalculatedColumnId = field?.id ?? field?.fieldId;                
                this.textToBeAddedToSql = field?.userExpression?.formula;
                this.userExpression = {
                  filter: field?.userExpression?.filter ?? [],
                  level: field?.userExpression?.level ?? [],
                };
                this.calculatedColumnDatasetId = field?.datasetId;
            }, 300);
        },
        async deleteDuplicatedField(field) {
            if (field?.id) {
              await this.deleteCalculated({ queryParam: field?.id }); // neden calculated field endpointi kullanılıyor TODO
              await this.getFields();
            }
          },
        async openDeleteCalculatedColumn(field) {
            if (field?.id) {
              await this.deleteCalculated({ queryParam: field?.id });
              this.init();
            } else {
                Notify(
                    this.$t("notifyMessages.You cannot delete a calculated field created in the Data Model"),
                    notificationType.ERROR
                );
            }
          },
        regexReplaceCalculatedColumnExpression(expression) {
            const keyValues = {};
            this.calculatedColumnDatasetAliasFieldNameMapping.forEach((dafnm) => {
                keyValues[dafnm.alias] = `${dafnm.alias}`;
            });

            let finalAns = expression;

            for (const [ke1, va1] of Object.entries(keyValues)) {
                finalAns = finalAns.replace(
                    RegExp(
                        //eslint-disable-next-line
                        "\\b" + ke1.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&") + "\\b",
                        "g"
                    ),
                    va1
                );
            }

            return finalAns;
        },
        async validateCalculatedColumn(param) {
            const paramCloned = cloneDeep(param);

            paramCloned.userExpression.formula = this.regexReplaceCalculatedColumnExpression(
                param.userExpression.formula
            );

            const result = await this.validateCalculatedField({
                bodyParam: {
                    ...paramCloned,
                    datamodelId: this.selectedDataModel.id,
                    dashboardId: this.dashboardId,
                },
                loadingComponent: LoadingComponent.RunSql,
            });

            this.calculatedPreviewTableRows = result.aggregations;
            if (this.calculatedPreviewTableRows?.length) {
              this.calculatedColumnFunctionName = null;
            }
            this.calculatedPreviewTableColumns = Object.keys(
              result.aggregations?.[0]
            )?.map((a) => ({
              field: a,
              label: a,
            }));
        },
        handleCreateOrUpdateCalculatedColumn(param) {
            if (this.isCalculatedColumnEditMode) {
                let foundField = null;

                Object.values(this.fields).forEach((fieldsByDataset) => {
                    fieldsByDataset.forEach((field) => {
                        if (field.id === this.editCalculatedColumnId || field.fieldId === this.editCalculatedColumnId) {
                            foundField = field;
                        }
                    });
                });
                
                // if we edit calculated column that is created in datamodel,
                // we cannot update it, we need to create new one
                // because the requirement is do not modify calculated columns created in datamodel
                if (foundField.dashboardId) {
                    this.updateCalculatedColumn(param);
                } else {
                    this.createCalculatedColumn(param);
                }
            } else {
                this.createCalculatedColumn(param);
            }
        },
        async createCalculatedColumn(param) {
            const paramCloned = cloneDeep(param);

            paramCloned.userExpression.formula = this.regexReplaceCalculatedColumnExpression(
                param.userExpression.formula
            );

            await this.createCalculatedField({
                bodyParam: {
                  ...paramCloned,
                  dashboardId: this.dashboardId,
                  datamodelId: this.selectedDataModel.id,
                },
                loadingComponent: LoadingComponent.RunSql,
            });
            await this.getFields();

            this.calculatedColumnCreateVisible = false;
            this.goToPanelPageWithQueryParams();
        },
        async updateCalculatedColumn(param) {
            const paramCloned = cloneDeep(param);

            paramCloned.userExpression.formula = this.regexReplaceCalculatedColumnExpression(
                param.userExpression.formula
            );

            await this.updateCalculatedField({
                queryParam: this.editCalculatedColumnId,
                bodyParam: { 
                    ...paramCloned, 
                    dashboardId: this.dashboardId,
                    datamodelId: this.selectedDataModel.id},
                    loadingComponent: LoadingComponent.RunSql,
                });
            await this.getFields();

            this.calculatedColumnCreateVisible = false;
        },
        getAutoRefreshIntervalViewChart() {
            this.selectedTabAllPanelList.forEach(element => {

                const keys = Object.keys(element?.properties?.style)

                const selectedKey = keys.find(x => x.includes("AUTO_REFRESH"))

                const selectedCustomKey = keys.find(x => x.includes("AUTO_REFRESH_CUSTOM"))

                const autoRefreshValue = element?.properties?.style?.[selectedKey];

                let time;

                if (selectedCustomKey && autoRefreshValue == autoRefreshTimeValue.custom) {
                    time = Number(element?.properties?.style?.[selectedCustomKey] + "000");
                } else {
                    time = autoRefreshValue;
                }

                let arr = [];

                arr.push(element)

                const interval = setInterval(() => {
                    if (arr.length && time > 0) {
                        this.viewChart(...arr.map(x => x.i))
                    }
                }, time)

                this.autoRefrestIntervalList.push(interval)
            });
        },
        isChartViewLimit(item) {
            if (this.isPanelTypeECharts(item) && !this.isPanelTypeLabel(item.type))
                return isChartHeightLimited(item.h);
            else return true;
        },
        setTextToBeAddedToSql(val) {
            this.textToBeAddedToSql = val;
        },
        setSettingLeftTab(e) {
            if (this.isChartFilterChannelViewActive) return;

            if (this.activeSettingTab === e && e === SettingsLeftTabLinkEnum.Visualize) {
                this.setActiveSettingTab("");
                this.reRenderChart();
                
                return;
            } 
            
            this.setActiveSettingTab(e);
            this.calculatedColumnsFunctionsMenuOpen = true;
            this.reRenderChart();
        },
        closePopoverMenu() {
            let row = document.querySelectorAll(".el-popover.vis-dropdown-popper-chart-menu");
            for (let index = 0; index < row.length; index++) {
                if (row[index].ariaHidden === "false") {
                    row[index].style.display = "none";
                }
            }
        },
        getIsMaxThresholdById(id) {
            if (this.getDashboardTabOptionsById(id))
                return this.getDashboardTabOptionsById(id).isMaxThreshold;
            else return false;
        },
        getCacheStoreTimeByChartI(chartI) {
            if (this.getDashboardTabOptionsById(chartI))
                return this.getDashboardTabOptionsById(chartI)?.cacheStoreTime;
            else return null;
        },
        async goToDashboardByIdWithFilterParameter(filterObj, dashboardId) {
            if (Object.keys(filterObj.filterValue)?.length > 5) {
                Notify(this.$t("Maximum parameters accepted to drill through action"), notificationType.WARNING);
                return;
            }
            
            await this.encode({ object: JSON.stringify(filterObj) });

            let routeData = this.$router.resolve({
                name: routerEnums.DASHBOARD_VIEW,
                params: { id: dashboardId },
                query: { filterCode: this.encodeQueryParam.object },
            });

            window.open(routeData.href, "_blank");
        },

        async parseQueryStringFilterParam() {
            await this.decode({ object: this.$route.query.filterCode });

            this.drillThroughFilterParam = JSON.parse(this.decodeQueryParam.object)
            
            if (this.drillThroughFilterValueLength) {
                this.addFiltersToChartsWithDrillThrough(
                    this.drillThroughFilterParam.filterValue
                );
            } else await this.viewChart();
        },


        parseQueryParamOnDashboardPage() {
            if (this.$route.query.embeded === 'true') {
                const parsedQueryString = parseQueryStringTopbar(this.$route.query)
                //Topbar.vue componentine url'deki embeded parametreleri gönderiliyor.
                this.$root.$emit("setTopbarQuery", parsedQueryString)
            }
        },
        getLocaleStorageQuerParam() {
            this.settingsBarQueryParams = getQueryStringParam(this.settingsBarQueryParams, this.getterQueryParam)
        },
        getLabelChartTextAreaHeight() {
            const getPanelListWhereChartTypeLabel = this.selectedTabAllPanelList.filter(x => x.type == chartTypes.LABEL);
            if (getPanelListWhereChartTypeLabel.length)
                getPanelListWhereChartTypeLabel.forEach(element => {
                    this.changeTextAreaHeightWhereCardTitleName(element.i);

                    this.changeTextAreaHeightWhereCardTitleDescription(element.i);
                });
        },
        async getDashboardHighestPriority() {
            if (!this.dashboardId) return;

            this.fetchedDashboardHighestPriority =
                await this.getHighestPriorityByDashboardId({
                    dashboardId: this.dashboardId,
                });
        },
        async getDatamodelHighestPriority() {
            this.fetchedDatamodelHighestPriority =
                await this.getHighestPriorityByDatamodelId({
                    datamodelId: this.dashboard.datamodel_id,
                });
        },
        async getDashboardAuthorities() {
            if (!this.dashboardId) return;

            this.fetchedDashboardAuthorities =
                await this.fetchDashboardAuthorizationById(this.dashboardId);
        },
        async getDatamodelAuthorities() {
            this.fetchedDatamodelAuthorities = 
                await this.fetchDatamodelAuthorizationById(this.dashboard.datamodel_id);
        },
        openFieldListPopupWithRef(id) {
            this.$refs?.[
                this.callGetChartRefId(id)
            ]?.[0]?.openFieldListPopup();
        },
        openConditionalFormattingWithRef(id) {
            this.$refs?.[
                this.callGetChartRefId(id)
            ]?.[0]?.openConditionalFormatting();
        },
        triggerFieldListChangedWithRef(id, value) {
            this.$refs?.[
                this.callGetChartRefId(id)
            ]?.[0]?.triggerFieldListChanged(value);
        },
        callTableExportWithRef(id, evt) {
            this.$refs?.[this.callGetChartRefId(id)]?.[0]?.callExport(evt);
        },
        callTableExportNotFormattedWithRef(chartData, evt) {
            if (chartData.type === chartTypes.TABLE)
                this.$refs?.[this.callGetChartRefId(chartData.i)]?.[0]?.callExportNotFormatted(evt);
            else if (chartData.type === chartTypes.PIVOT_TABLE)
                this.$refs?.[this.callGetChartRefId(chartData.i)]?.[0]?.callExportNotFormatted(evt);
            else this.callTableExportWithRef(chartData.i, evt)
        },
        getDashboardTabOptionsById(id) {
            return this.dashboardTab.options.find((x) => x.id == id);
        },
        fetchSql(sql) {
            if (this.isDataModelTemporaryFiltersPopupVisibleInCalculatedColumn) {
              setTimeout(() => {
                  this.$refs.DataModelCalculatedColumnEditor.setSelectedDatasetCustomFilterParams();
              }, 500);
              
              this.isDataModelTemporaryFiltersPopupVisibleInCalculatedColumn = false;
              this.isDataModelTemporaryFiltersPopupVisible = false;
      
              return;
            }
            
            this.fetchTestQuery({
                loadingComponent: LoadingComponent.RunSql,
                bodyParam: {
                    sqlQuery: sql,
                    connection: {
                        connectionId: this.selectedDataset?.connectionId,
                    },
                },
            });
        },
        callRunSqlWithSelectedDataset() {
            this.runSql(this.selectedDataset?.sql);
        },
        runSql(sql) {
            this.dataModelTemporaryFiltersPopupItems = sql.match(/:\w+/g);
            this.dataModelTemporaryFiltersPopupSql = sql;

            if (!this.dataModelTemporaryFiltersPopupItems?.length) this.fetchSql(sql);
            else {
                this.isDataModelTemporaryFiltersPopupVisible = true;
            }
        },
        handleCalculatedColumnFilterTabOpened() {
          const sql = this.datasets?.find(d => d.datasetId === this.calculatedColumnDatasetId)?.sql;

          this.dataModelTemporaryFiltersPopupItems = sql
            .match(/(:[a-zA-Z0-9_çğıöşüÇĞİÖŞÜ]+)/g)
            ?.filter((parametre) => !parametre?.includes(this.ldapAttribute));
          this.dataModelTemporaryFiltersPopupSql = sql;

          if (this.dataModelTemporaryFiltersPopupItems?.length) {
            this.isDataModelTemporaryFiltersPopupVisible = true;
            this.isDataModelTemporaryFiltersPopupVisibleInCalculatedColumn = true;
          }
        },
        handleCardItemClick(id) {
            this.selectedCardItem = id;
        },
        isCardItemSelected(id) {
            return this.selectedCardItem === id;
        },
        async getFields() {
            if (this.dashboardId) {
                await this.fetchDashboardByIdFields(this.dashboardId);
            } else {
                await this.fetchDataModelById(this.dashboard.datamodel_id);
                this.resetTempStorageDashboardFields();
                await this.fetchDatamodelByIdFields(this.dashboard.datamodel_id);
            }

            if (this.dashboard.datamodel_id) {
                this.fetchDatamodelByIdFilterParams({
                    loadingComponent: LoadingComponent.DatamodelFields,
                    id: this.dashboard.datamodel_id,
                });
            }

        },

        saveField({ bodyParam, index, selectedField }) {

            const selectedDatasetField = this.getSelectedDatasetField(bodyParam, selectedField)

            if (selectedDatasetField.id) {
                this.updateDashboardField(selectedDatasetField);
            } else if (this.dashboardId) {
                this.createDashboardField({...bodyParam, ...selectedDatasetField });
            } else {
                this.addSelectedField(selectedField); //To use the selectedField in the saveChangeDashboardField() function
                this.updateDataSetFields(bodyParam, index); // the body parameter is used because in order not to lose it on the store side
            }
        },

        getSelectedDatasetField(bodyParam, selectedField) {
            //Backend tarafının beklediği default değerler. Backend istemediği sürece farklı bir key eklenemeyecek!
            const newField = {
                id: bodyParam.id ?? null,
                type: bodyParam.type,
                name: bodyParam.name,
                dashboardId: this.dashboardId ?? null,
                datasetId: bodyParam.datasetId,
                fieldId: bodyParam.fieldId,
                sceenTip: bodyParam.screenTip,
            };

            Object.keys(bodyParam).forEach((element) => {
                if (bodyParam[element] !== selectedField[element]) {
                    newField[element] = bodyParam[element];
                }
            });



            return newField;
        },

        updateDataSetFields(bodyParam, index) {
            const clonedTempDatasetFields = cloneDeep(this.tempDatasetFields)
            const clonedDatasetFields = cloneDeep(this.tempDatasetFields?.[bodyParam.datasetId]).filter(
                (d) => d.fieldId !== bodyParam.fieldId
            );
               
            clonedDatasetFields.splice(index, 0, bodyParam); //Ekleme yapmak için
            clonedTempDatasetFields[bodyParam.datasetId] = clonedDatasetFields;
            this.updateTemStorageDashboardFields(clonedTempDatasetFields)
           // const allDataset = {...this.tempDatasetFields, }
           // this.setTemStorageDashboardFields(clonedDatasetFields);
        },
        toggleChatGptByChartId(chartId) {
            this.changeScreenMode(chartId)
            this.isChartRefreshTriggerActive = false;
            if (this.chatGptSelectedChartId === chartId) this.chatGptSelectedChartId = null;
            else this.chatGptSelectedChartId = chartId;

            setTimeout(() => {
                this.isChartRefreshTriggerActive = true;
            }, 300);
        },
        changeScreenMode(chartI) {
            const clonedChart = this.getSelectedChartById(chartI);

            this.isChartRefreshTriggerActive = false;
            this.isChartViewModeChanged = !this.isChartViewModeChanged;

            if (this.fullScreenChartId === chartI) {
                this.fullScreenChartId = null;
                this.isExpandedChartBgTransparent = null;
            } else {
                this.fullScreenChartId = chartI;

                //if card bg color transparent
                if (clonedChart?.properties?.style?.[allPropertiesKeys.BACKGROUND_COLOR] === null) {
                    this.isExpandedChartBgTransparent = chartI;
                }
            } 
            
            setTimeout(() => {
                this.isChartRefreshTriggerActive = true;
            }, 300);
        },
        async changeDashboardViewMode() {
            this.setCanvasStyle();
            
            this.dashboardEditMode = !this.dashboardEditMode;

            this.startFilterPaneVisible();
            this.startFilterGridLayoutVisible();
           
            this.reRenderChart();

            setTimeout(() => {
                this.addNewPaperFrame();
            }, 600);
        },
        exportChartToImage(panelData) {
            this.setDashboardSelectionPaneActive(false);
            this.setFilterGridLayoutActive(false);

            this.$nextTick(() => {
              this.loading[panelData.i] = true;

              // panelData.id'yi kullanarak doğru div'i seç
              let elementId = `grid-item-${panelData.id}`;
              let element = document.querySelector(
                `#${elementId} .vis-chart-card.vue-draggable-handle`
              );
              let imageName = this.dashboard.name
                .replace(/\s+/g, "_")
                .replace(/\./g, "_");
              const startTime = new Date();
              let _this = this;

              toPng(element, {
                backgroundColor: this.canvasStyle["background-color"],
              })
                .then(function (dataUrl) {
                  var a = document.createElement("a");
                  a.download = imageName;
                  a.href = dataUrl;
                  a.click();
                })
                .then(() => {
                  const endTime = new Date();
                  const exportNotificationData = this.getExportNotificationData({
                    startTime,
                    endTime,
                    type: this.exportTypes.IMAGE,
                  });

                  this.loading[panelData.i] = false;
                  this.setExportNotification(exportNotificationData);
                })
                .catch(function (error) {
                  if (error)
                    this.loading[panelData.i] = false;

                    Notify(
                      _this.$t("panelProperties.exportError"),
                      notificationType.ERROR
                    );
                });
            });
        },
        exportDashboardToPdf() {
            //will not be on the pdf screen START
            this.setDashboardSelectionPaneActive(false);
            this.setFilterGridLayoutActive(false);

            const classes = [".vis-topbar", "header", ".vis-dropdown-menu"];
            
            classes.forEach(selector => document.querySelector(selector).classList.add('vis-display-none'));

            document.querySelector(".main").classList.add("vis-height-auto");
            document.querySelectorAll(".vis-grid-pdf-frame").forEach((f) => {
                f.classList.add('vis-display-none')
            })
            //will not be on the pdf screen END

            //will reopen again START
            setTimeout(() => {
                window.print();

                classes.forEach(selector => document.querySelector(selector).classList.remove('vis-display-none'));

                document.querySelector(".main").classList.remove("vis-height-auto");
                document.querySelectorAll(".vis-grid-pdf-frame").forEach((f) => {
                    f.classList.remove('vis-display-none')
                })
            }, 500);
            //will reopen again END
        },
        getExportNotificationData(params) {
            return {
              type: params.type,
              exportableObject: this.exportableObject,
              objectId: this.dashboardId,
              exportProcessStartTime: params.startTime.toISOString(),
              exportProcessEndTime: params.endTime.toISOString(),
            };
          },
        setExportNotification(
            exportNotificationData
          ) {
            return this.createExportNotification({
                 ...exportNotificationData
            });
          },
        isPanelTypeLabel(chartType) {
            return chartType === chartTypes.LABEL;
        },
        isPanelTypePivotTable(panelData) {
            return panelData?.type === chartTypes.PIVOT_TABLE;
        },
        isPanelTypeButton(chartType) {
            return chartType === otherChartType.BUTTON;
        },
        isPanelTypeECharts(panelData) {
            return (
                panelData?.type?.toLowerCase().includes(panelTypes.CHART) &&
                !this.isPanelTypeLogo(panelData) &&
                !this.isPanelTypeSummary(panelData) &&
                !this.isPanelTypeSelector(panelData)
            );
        },
        isPanelTypeLogo(panelData) {
            return panelData?.type === chartTypes.LOGO;
        },
        isPanelTypeSummary(panelData) {
            return panelData?.type === chartTypes.SUMMARY;
        },
        isPanelTypeTable(panelData) {
            return panelData?.type === chartTypes.TABLE;
        },
        isPanelTypeFilterText(panelData) {
            return panelData?.type === dashboardFilterTypes.FILTER_TEXT;
        },
        isPanelTypeFilterDropdown(panelData) {
            return panelData?.type === dashboardFilterTypes.FILTER_DROPDOWN;
        },
        isPanelTypeFilterNumber(panelData) {
            return panelData?.type === dashboardFilterTypes.FILTER_NUMBER;
        },
        isPanelTypeFilterDate(panelData) {
            return panelData?.type === dashboardFilterTypes.FILTER_DATE;
        },
        isPanelTypeFilterSelectList(panelData) {
            return panelData?.type === dashboardFilterTypes.FILTER_SELECT_LIST;
        },
        isPanelTypeFilterButton(panelData) {
            return panelData?.type === otherChartType.BUTTON;
        },
        isPanelTypeAdvancedButton(panelData) {
            return panelData?.type === dashboardFilterTypes.ADVANCED_BUTTON;
        },
        isPanelTypeTextEditor(panelData) {
            return panelData?.type === otherChartType.TEXT_EDITOR;
        },
        isPanelTypeSelector(panelData) {
            return panelData?.type === chartTypes.SELECTOR_DATA_ITEM_CHART;
        },
        isPanelTypeTabPanel(panelData) {
            return panelData?.type === chartTypes.TAB_PANEL;
        },  
        isSearchLoading(panelData) {
            return (
                this.loading[panelData.i] &&
                !this.isPanelTypeLogo(panelData) &&
                !this.isPanelTypeLabel(panelData?.type)
            );
        },
        isPanelFooterText(panelData) {
            if (
                this.mergeCardAndTemplateStyleSingleProperty(
                    panelData,
                    allPropertiesKeys.HAS_FOOTNOTE
                ) &&
                !this.isPanelTypeLabel(panelData.type) &&
                !this.isChartTypeFilter(panelData.type) && isChartHeightLimited(panelData.h)
            ) {
                if (this.isModeDashboardView && !panelData.footnote) {
                    return false;
                }
                return true;
            } else {
                return false;
            }
        },
        contentHeightBySummaryChart(chartData) {
            if(!this.isPanelTypeSummary(chartData)) return '';
            
            if (chartData.name !== '' && chartData.description !== '') {
                return { height: 'calc(100% - 35px) !important' }
            } else if (chartData.name !== '' || chartData.description !== '') {
                return { height: 'calc(100% - 30px) !important' }
            } else {
                return { height: 'calc(100% - 20px) !important' }
            }
        },
        logoChartCustomClass(panelData) {
            return panelData.type == chartTypes.LOGO && this.isModeDashboardView
                ? "vis-logo-chart"
                : "";
        },
        checkHasFootNote(panelData) {
            return this.mergeCardAndTemplateStyleSingleProperty(
                panelData,
                allPropertiesKeys.HAS_FOOTNOTE
            )
        },
        checkHasChartDescription(panelData) {
            return this.mergeCardAndTemplateStyleSingleProperty(
                panelData,
                allPropertiesKeys.HAS_SUBTITLE
            )
        },
        customScrollClass(panelData) {
            return this.isPanelTypeFilterSelectList(panelData)
                ? "vis-custom-scroll-card vis-scroll-thin"
                : "";
        },
        customScrollClassTable(panelData) {
            if (panelData.type == chartTypes.TABLE) {
                if (this.isModeDashboardView && (!panelData.footnote || panelData.footnote === '')) return "vis-card-content-viewmode-notfootnote";
                else if (this.isModeDashboardView && panelData.footnote) return "vis-card-content-viewmode-footnote";
                else if (this.checkHasFootNote(panelData)) return "vis-card-content-normal";
                else return "vis-custom-scroll-card"
            } return ""
        },
        getCardPropertiesStylesCss(itemPropertiesStyles, templateCssKeys){
            const resultStyle = {};

            Object.keys(itemPropertiesStyles).forEach((propKey) => {
                const convertedCSSKey = templateCssKeys[propKey];

                const isKeyFontSize = convertedCSSKey?.includes("fontSize");

                if (convertedCSSKey) resultStyle[convertedCSSKey] = isKeyFontSize ? itemPropertiesStyles[propKey] + 'px' : itemPropertiesStyles[propKey];
            });

            return resultStyle;
        },
        mergeCardAndTemplateStyleAsCSS(chartData) {
            const merged = this.mergeCardAndTemplateStyle(chartData);

            const resultStyle = this.getCardPropertiesStylesCss(merged, propertiesKeyToCSSKeyMapping);
           
            let cssAddedObjectBasedOnTrueOrFalse = {
                boxShadow: merged?.[allPropertiesKeys.HAS_DROP_SHADOW]
                ? "5px 5px 6px #00000029"
                : "none",
                border: merged?.[allPropertiesKeys.HAS_BORDER] ? "1px solid" : "none",
            };
            
            return {...cssAddedObjectBasedOnTrueOrFalse, ...resultStyle,  };
        },
        mergeCardAndTemplateTitleStyleAsCss(cardItem){
            const merged = this.mergeCardAndTemplateStyle(cardItem);

            const resultStyle = this.getCardPropertiesStylesCss(merged, dashboardCardTitlePropertiesCssKey);

            let cssAddedObjectBasedOnTrueOrFalse = {
                display: merged?.[allPropertiesKeys.HAS_TITLE] ? "block" : "none",
            };
            
            return { ...resultStyle, ...cssAddedObjectBasedOnTrueOrFalse };
        },
        mergeCardAndTemplateDescriptionStyleAsCss(cardItem){
            const merged = this.mergeCardAndTemplateStyle(cardItem);

            const resultStyle = this.getCardPropertiesStylesCss(merged, dashboardCardDescriptionPropertiesCssKey);

            let cssAddedObjectBasedOnTrueOrFalse = {
                display: merged?.[allPropertiesKeys.HAS_SUBTITLE] ? "block" : "none",
            };
            
            return { ...resultStyle, ...cssAddedObjectBasedOnTrueOrFalse };
        },
        mergeCardAndTemplateFootnoteStyleAsCss(cardItem){
            const merged = this.mergeCardAndTemplateStyle(cardItem);

            const resultStyle = this.getCardPropertiesStylesCss(merged, dashboardCardFootnotePropertiesCssKey);

            let cssAddedObjectBasedOnTrueOrFalse = {
                display: merged?.[allPropertiesKeys.HAS_FOOTNOTE] ? "block" : "none",
            };
            
            return { ...resultStyle, ...cssAddedObjectBasedOnTrueOrFalse };
        },
        mergeCardAndTemplateStyle(cardStyle) {
            return {
                ...this.selectedTemplateProperties,
                ...cardStyle?.properties?.style,
            };
        },
        mergeCardAndTemplateStyleSingleProperty(cardStyle, propKey) {
            return this.mergeCardAndTemplateStyle(cardStyle)?.[propKey];
        },

        setActiveComponentStyle(params) {
            if (this.activeComponentType === this.dashboardComponentTypes.CHART) {
                this.$set(this.activeComponentStyle, params.property, params.value);
                this.setSelectedPanelStyle(params, this.selectedTabAllPanelList.find(item => this.selectedChartI === item.i))
            } else {
                this.addNewItemToUndoStack(cloneDeep(this.dashboard));
                this.$set(this.activeComponentStyle, params.property, params.value);
                this.setCanvasStyle();
            }
        },
        getHasDropShadow(item) {
            return item?.properties?.style?.[allPropertiesKeys.HAS_DROP_SHADOW];
        },
        
        closeFilterPane(evt) {
            if (!this.isFilterGridLayoutPinIconActive) {
                this.setFilterGridLayoutActive(evt);
            }
            
            if (!this.isDashboardSelectionPanePinIconActive) {
                this.setDashboardSelectionPaneActive(evt);
            }

            this.$emit("changeActiveClassInIconList");
        },
        setCanvasStyle() {
            this.selectedChartI = null;
            this.propertiesChartTypeName = "generalPages.dashboard";
            this.activeComponentStyle = this.selectedTabStyle;
            this.activeComponentType = this.dashboardComponentTypes.GENERAL;
            this.resetTempChartFilterChannel();
        },
        //CHART FILTER CHANNEL START
        toggleChartFilterChannelView() {
            this.selectedChartI = null;
            this.isChartFilterChannelViewActive = !this.isChartFilterChannelViewActive;

            if (this.isChartFilterChannelViewActive) {
                Notify(this.$t("notifyMessages.Select a card to see connection"), notificationType.INFO)
            }
            
            this.resetTempChartFilterChannel();
        },
        resetTempChartFilterChannel() {
            this.tempChartFilterChannel = {};
        },
        setChartFilterChannelNonFilteredCharts(chartId) {
            const foundChart = this.tempChartFilterChannel.nonFilteredCharts.find(
              (f) => f?.chartId === chartId
            );
      
            //for find selectedChart
            const chart = cloneDeep(this.selectedTabAllPanelList).find(
                (x) => x.i == this.tempChartFilterChannel?.ruleOwnerChartId
              );
              
            if (foundChart) //for delete
              this.tempChartFilterChannel.nonFilteredCharts =
                this.tempChartFilterChannel.nonFilteredCharts.filter(
                  (f) => f?.chartId !== chartId
                );
            else { //for add
              this.tempChartFilterChannel.nonFilteredCharts.push({ chartId: chartId });

              let removeChartFromFilterChannel = cloneDeep(this.selectedTabAllPanelList).find(
                (x) => x.i === chartId
              );
              //filtre kanalından çıkarılmak istenilen grafikte daha önceden seçilen grafiğe (const chart) ait filtre varsa kaldırmak için.
              removeChartFromFilterChannel.details[detailsKeys.FILTERS] = removeChartFromFilterChannel.details?.[detailsKeys.FILTERS].filter((x) => x.filterId !== chart.i);

              this.updateSelectedPanel(removeChartFromFilterChannel);
            }

            //To assign the current value to the "properties.chartFilterChannel" variable of the selected chart.
            chart.properties.chartFilterChannel = this.tempChartFilterChannel;
            
            this.addNewItemToUndoStack(cloneDeep(this.dashboard));
            this.updateSelectedPanel(chart);
        },
        selectAndFindChartCardById(chartI) {
            if (this.isModeDashboardView) return;

            this.selectedChartI = chartI;

            if (this.isChartFilterChannelViewActive) {
              //If the properties.chartFilterChannel object does not exist when a card is selected, the default value is set; otherwise, the existing value is set
              let chart = cloneDeep(this.selectedTabAllPanelList).find((x) => x.i == chartI);

              //Charts that will not work in the filter channel.
              if (this.isPanelTypeTabPanel(chart) || this.isPanelTypeSelector(chart)) return;

              if (chart.properties.chartFilterChannel === undefined) {
                chart.properties.chartFilterChannel = {
                  ruleOwnerChartId: chartI,
                  nonFilteredCharts: [],
                };
              }
              
              this.tempChartFilterChannel = chart.properties.chartFilterChannel;
            } else {
              this.activeComponentType = this.dashboardComponentTypes.CHART;
      
              let chart = cloneDeep(this.selectedTabAllPanelList).find((x) => x.i == chartI);
              let x = cloneDeep(this.selectedTemplateProperties);
              chart.properties.style = { ...chart.properties.style };
      
              this.updateCardPositionOnProperties(chart);
      
              this.propertiesChartTypeName = this.$t(chart.chartTypeName);
              this.propertiesChartType = chart.type;
              this.activeComponentStyle = { ...x, ...chart.properties.style };
      
              this.updateSelectedPanel(chart);
            }
          
            //Eğer herhangi bir chartın properties-style objesi kopyalı ise alttaki fonksiyon çalışır.
            if (this.formatPaintChart) {
                this.changeChartPropertyStyleBySelectedChart();
            }
        },
        changeChartPropertyStyleBySelectedChart() {
            const clonedSelectedChart = cloneDeep(this.selectedTabAllPanelList).find((x) => x.i === this.selectedChartI);

            if (clonedSelectedChart?.i === this.formatPaintChart?.i) return;

            this.setPropertiesStyle(clonedSelectedChart);

            if (!this.dblClickedFormatPaint) {
                this.formatPaintChart = null;
            }
        },
        handleFormatPaintChartStyleByClickType(isDblClicked) {
            this.dblClickedFormatPaint = isDblClicked;

            const clonedSelectedChart = cloneDeep(this.selectedTabAllPanelList).find((x) => x.i === this.selectedChartI);

            if (!clonedSelectedChart && !this.formatPaintChart) {
                Notify(this.$t("notifyTitle.Please first select a chart to copy its format"),  notificationType.WARNING);
                return;
            }

            if (this.formatPaintChart) {
                this.formatPaintChart = null;
                this.dblClickedFormatPaint = false;
                return;
            } 
            
            if (!clonedSelectedChart) return;
           
            this.formatPaintChart = clonedSelectedChart;
        },
        setPropertiesStyle(selectedPanelCloned) {
            const chartPropertyFormat = new ChartPropertyFormat();

            let chartUsedChartType = this.formatPaintChart?.type;
            let chartTypeToChange = selectedPanelCloned?.type;
            
            const notToCopyStyleRuleList = chartPropertyFormat.getNotToCopyStyleRuleList();
            const sameChartConditionActive = chartPropertyFormat.getSameChartCondition(chartUsedChartType, chartTypeToChange);
            const treeAndHeatmapTypes = [chartTypes.TREEMAP_CHART, chartTypes.HEATMAP_CHART];
            const propertiesStyle = {};
      
            if (this.isChartTypeFilter(this.formatPaintChart?.type)) {
              //Bu methodu kullanma sebebimiz filtre componentinin chart tipi ile properties style içindeki key'leri aynı yapmak için.
              const changeValueTextToFilterText = (value) => {
                value = value.substring(value.indexOf("_"));
                value = value.slice(1) + "_FILTER";
                return value;
              };
      
              chartTypeToChange = changeValueTextToFilterText(chartTypeToChange);
              chartUsedChartType = changeValueTextToFilterText(chartUsedChartType);
            }
      
            //treemap ve heatmap chartlarının properties enumlarında CHART ISMI SONRASI(ORN: TREEMAP_SHOW_LABEL) "_CHART" prefix olmadığı için..
            if (treeAndHeatmapTypes.includes(chartUsedChartType)) {
              chartUsedChartType = chartUsedChartType.replace("_CHART", "");
            }
      
            Object.keys(this.formatPaintChart?.properties?.style).forEach(
              (styleKey) => {
                const defaultStyleKey = cloneDeep(styleKey);
      
                if (notToCopyStyleRuleList.includes(defaultStyleKey.replace(`${chartUsedChartType}_`, ""))) return;
                
                if (styleKey.includes(chartUsedChartType)) {
                  if (sameChartConditionActive) {
                        return;
                  }

                  //Örn: BAR_CHART_SHOW_VALUE_LABEL => COLUMN_CHART_SHOW_VALUE_LABEL 
                  styleKey = styleKey.replace(chartUsedChartType, chartTypeToChange);
      
                  //treemap ve heatmap chartlarının properties enumlarında _CHART olmadığı için..
                  if (treeAndHeatmapTypes.includes(chartTypeToChange)) {
                    styleKey = styleKey.replace("_CHART", "");
                  }
                }
      
                propertiesStyle[styleKey] =
                  this.formatPaintChart?.properties?.style[defaultStyleKey];
              }
            );
      
            selectedPanelCloned.properties.style = {
              ...selectedPanelCloned.properties.style,
              ...propertiesStyle,
            };
      
            this.updateSelectedPanel(selectedPanelCloned);
          },
        getChartFilterChannelConnected(chartI) {
            return this.tempChartFilterChannel?.nonFilteredCharts?.some(x=> x?.chartId === chartI);
        },
        checkChartFilterChannelIconActive(chartData) {
            if (this.isPanelTypeTabPanel(chartData) || this.isPanelTypeSelector(chartData)) return false;
            //Show Icon on Unselected Cards When Filter Rule View is Enabled
            return this.isChartFilterChannelViewActive && this.tempChartFilterChannel.ruleOwnerChartId && this.tempChartFilterChannel.ruleOwnerChartId !== chartData?.i;
        },
         //CHART FILTER CHANNEL END
        updateCardPositionOnProperties(value) {
            this.$set(value.properties.style, allPropertiesKeys.POSITION_X, value.x);
            this.$set(value.properties.style, allPropertiesKeys.POSITION_Y, value.y);
            this.$set(
                value.properties.style,
                allPropertiesKeys.CARD_W,
                `${value.properties.width}px`
            );
            this.$set(
                value.properties.style,
                allPropertiesKeys.CARD_H,
                `${value.properties.height}px`
            );
        },

        changeDashboardSelectionPaneIcon() {
            if (this.isDashboardSelectionPanePinIconActive) {
                this.setDashboardSelectionPinIconActive(false);
                this.setDashboardSelectionPaneActive(false)
            } else this.setDashboardSelectionPinIconActive(true);
        },
        changeFilterGridLayoutPinIcon() {
            if (this.isFilterGridLayoutPinIconActive) {
                this.setFilterGridLayoutPinIconActive(false);
                this.setFilterGridLayoutActive(false)
            } else this.setFilterGridLayoutPinIconActive(true);

            if (this.filterGridLayoutPositionRightOrLeft) {
                this.reRenderChart();
            }
        },
        addFiltersToChartsWithDrillThrough(filterValue) {
           //Drill through ile gelen filtreleri, dashboard içindeki chartlara ekletmek için.
           //Burası aynı zamanda DashboardDrillThrough.vue dosyasından gelen emit ilede çalışır. Var olan filtreleri değiştirmek için
            const clonedDashboard = cloneDeep(this.dashboard);

            this.drillThroughFilterParam.filterValue = {
                ...this.drillThroughFilterParam.filterValue,
                ...filterValue,
            };

            Object.keys(filterValue).forEach((fieldKey) => {
              const filterObj = createFilterDrillThrough(
                    fieldKey,
                    filterValue,
                    this.drillThroughFilterParam.filterId
                );

                addFiltersToFilterOrParamListOfCharts(clonedDashboard, filterObj.payloadFilterObj, filterObj.selectedFieldFilterObj);
            });

            this.setDashboard(clonedDashboard);
            this.viewChart();
        },
        checkActiveMandatorySelectionAndIsEmptyValue(params, selectedPanel) {
            if (
               params?.isActiveMandatory &&
              !params?.triggerFromPanel &&
              !Object.keys(params.value)?.length &&
              !this.getSelectionAction(selectedPanel)
            ) {
              this.isRequiredFilterValue = true;
              Notify(
                this.$t("notifyMessages.mandatoryMessage"),
                notificationType.WARNING,
                this.$t("notifyMessages.mandatory")
              );
            }
          },
          getSelectedChartListByAppylyToTabs(params) {
            //params.chartList ve params.dashboard, panel.vue componentinden event trigger edildiğinde kullanılır.

            const applyToTabs = params?.applyToTabs;
            const chartList = params?.chartList ?? this.selectedTabAllPanelList;
            
            if (!applyToTabs || !applyToTabs?.length) return chartList;

            const clonedDashboard = cloneDeep(params?.dashboard ?? this.dashboard);
            const hasAllItem = applyToTabs?.includes(selectBoxOption.ALL_ITEM);
            let selectedChartList = [];

            const deleteFiltersFromChartsOnUnusedTabs = (charts) => {
                charts.forEach(chart => {
                    chart.details.filters = chart?.details?.filters?.filter(f=> f.filterId !== params?.panelI);
                    chart.details.params = chart?.details?.params?.filter(f=> f.filterId !== params?.panelI);
                    this.updateSelectedPanel(chart, chart?.properties?.tabIndex);
                });
            }
            clonedDashboard.tabs.forEach(tab => {
                const tabId = tab?.properties?.uiTabId;

                if(!applyToTabs?.includes(tabId) && !hasAllItem) {
                    deleteFiltersFromChartsOnUnusedTabs(tab?.charts);
                }
                if (hasAllItem) {
                    selectedChartList = [...selectedChartList, ...tab.charts];
                }
                else if (applyToTabs?.includes(tabId) && !hasAllItem) {
                    selectedChartList = [...selectedChartList, ...tab.charts];
                }
            });

            //Filtre ekleyen chart liste içinde var mı kontrol et.
            const hasChartInFilterableList = selectedChartList.find((x) => x.i === params?.panelI);

            //Filtre ekleyen grafik kendi bulunduğu tab'ı filtrelemiyorsa ve listede yoksa listeye ekletiyoruz.
            if (!hasChartInFilterableList) {
                const chartToAddToFilter = chartList.find((x) => x.i === params?.panelI);
                selectedChartList.push(chartToAddToFilter);
            }

            return selectedChartList;
        },
        addFiltersToPanels(params) {
            const chartList = cloneDeep(this.getSelectedChartListByAppylyToTabs(params));
            const chartI = params?.panelI;
            const selectedPanel = chartList?.find(x => x.i === chartI);
            
            let filteredCharts = [];

            if (selectedPanel?.properties?.notShowFilterPane === undefined) selectedPanel.properties.notShowFilterPane = false;

            if (params.isDrillDown) {
                //chartlara sağ click ile include yada exclude yapıldığı zaman burası çalışır.
                filteredCharts = chartList.filter((x) => x.i === chartI);
                selectedPanel.properties.notShowFilterPane = true;
            } else {
                filteredCharts = chartListSelectedChartIsNotIn(chartList, chartI);
                selectedPanel.properties.notShowFilterPane = false;
            }

            //Charts in the nonFilteredCharts list are to remain unaffected by the filters.
            if (selectedPanel?.properties?.chartFilterChannel) {
                filteredCharts = filteredCharts.filter((chart) => {
                    return !selectedPanel?.properties?.chartFilterChannel.nonFilteredCharts.some((r) => r.chartId === chart.i)
                });
            }
           
            this.checkActiveMandatorySelectionAndIsEmptyValue(params, selectedPanel);

            if (Object.keys(params.value).length) {
                selectedPanel.details[filterPropKey.FILTER_VALUE] = params.value;
            } else {
                selectedPanel.details[filterPropKey.FILTER_VALUE] = null;
            }

            this.updateSelectedPanel(selectedPanel, selectedPanel?.properties?.tabIndex);

            if (!filteredCharts.length) return null;
            
            const filterParamValues = {
                filteredCharts,
                params,
                filterObj: {},
                filterValue: selectedPanel.details[filterPropKey.FILTER_VALUE],
            };

            const aggregationOrMetricList = getAggregationOrMetricListByChartType(
                params,
                selectedPanel
            );

            aggregationOrMetricList.forEach((fieldItem) => {
                filterParamValues.filterObj = getFilterObj(
                    fieldItem,
                    params,
                    selectedPanel.type,
                    selectedPanel.details[filterPropKey.FILTER_VALUE]
                );

                if (fieldItem.filterType === filterType.CUSTOM) {
                    this.addFilterToChart(filterParamValues, panelDataDetailsKey.PARAMS);
                } else {
                    this.addFilterToChart(filterParamValues, panelDataDetailsKey.FILTERS);
                }
            });

            //Filtre uyguladığımızda searchten cevap gelmeden (success) store'da chartList set etmiyoruz. Payload olarak
            // chartListForDashboardFilter değişkenini kullanarak işlem yapıyoruz.
            this.chartListForDashboardFilter = cloneDeep(filterParamValues.filteredCharts)

            //Filtelemenin buton ile yapılıp yapılmayacağı chart'ın propertiesine göre kontrol ediliyor.
            this.controlFilterButtonAndSetFilter(
                selectedPanel,
                params,
                filteredCharts
            );

            this.chartListForDashboardFilter = []
        },
        addFilterToChart(filterParamValues, detailsKey) {
            const paramsObj = filterParamValues?.filterObj;
            const paramsValue =
                filterParamValues?.filterValue?.[paramsObj.field]?.value;

            filterParamValues.filteredCharts.forEach((chart) => {
                let filter = chart.details[detailsKey].find(
                    (x) => x.filterId == paramsObj.filterId && x.field == paramsObj.field
                );

                if (filter && filter?.fieldId === paramsObj.fieldId && deepEqual(filter, paramsObj)) return;
                
                if (!filter && paramsValue) {
                    chart.details[detailsKey].push(paramsObj);
                } else if (filter && paramsValue) {
                    this.$set(filter, filterObjectProperty.VALUE, paramsObj.value);
                    this.$set(filter, filterObjectProperty.OPERATOR, paramsObj.operator);
                    if (paramsObj?.caseInsensitiveMatching !== undefined) {
                        this.$set(
                          filter,
                          filterObjectProperty.CASE_SENSITIVE,
                          paramsObj.caseInsensitiveMatching
                        );
                    }
                } else {
                    let filterList = chart.details[detailsKey].filter((x) => {
                        return x.fieldId !== paramsObj.fieldId || x.filterId !== paramsObj.filterId;
                    });

                    this.$set(chart.details, detailsKey, filterList);
                }
            });
        },

        async controlFilterButtonAndSetFilter(selectedPanel, params, gotoSearchWithFilteredCharts) {
            const updateFilteredCharts = (gotoSearchWithFilteredCharts) => {
                gotoSearchWithFilteredCharts.forEach(chart => {
                    this.updateSelectedPanel(chart, chart?.properties?.tabIndex);
                });
            }
           
            if (this.isSelectedActionWaitForButtonClick || params?.notRunTheViewChart || params?.triggerFromPanel || (this.selectedTabDesignMode && !this.isModeDashboardView)) {
                updateFilteredCharts(gotoSearchWithFilteredCharts);
            } else if (params?.filterType === filterType.CUSTOM) {
                updateFilteredCharts(gotoSearchWithFilteredCharts);

                if (Object.keys(params?.value)?.length) this.viewChart();
            } else if (params.isDrillDown) {
                //chartlara sağ click ile include yada exclude yapıldığı zaman burası çalışır.
                this.viewChart(...[selectedPanel.i]);
            } else {
                const chartIdsToRender = gotoSearchWithFilteredCharts.map((m) => m.i);
                await this.viewChart(...chartIdsToRender)
            }
        },
        getSelectionAction(selectedPanel) {
            let selectionAction;

            SELECTION_ACTION_KEYS.some(x => {
                let actionKey = selectedPanel.properties.style[x]
                if (actionKey) {
                    selectionAction = actionKey;
                    return true;
                }
                return false;
            })

            return selectionAction
        },
        setConfirm() {
            if (
              this.confirmDialogType === DatamodelContextDefaults.UNSAVE_DASHBOARD
            ) {
              this.confirmLeave = true;
              this.dialogVisible = false;
              this.$router.push({ name: this.beforeRouteLink });
            } else if (
              this.confirmDialogType === DatamodelContextDefaults.OBJECT_LOCK
            ) {
              this.handleDashboardLocking(true);
            } else if (
              this.confirmDialogType === DatamodelContextDefaults.OBJECT_UNLOCK
            ) {
              this.handleDashboardLocking(false);
            }
          },
        updatePanelWithGetSearchApi(value) {
            let params = {
                value,
                tabIndex: this.tabIndex,
            };
            this.setPanels(params);
            this.viewChart();
        },
        updatePanel(value, tabIndex = this.tabIndex) {
            let params = {
                value,
                tabIndex
            };
            this.setPanels(params);
        },

        async getViewEndpointChart(item) {

            let params = {
                endpoint: item.properties.style.CUSTOM_ENDPOINT,
                params: item.details.params,
            };
            this.fetchApiCall({
                bodyParam: params,
            }).then((response) => {

                if (response.statusType === this.responseStatusType) {
                    this.viewChart();
                }

            })
        },
        async setDataModel(value) {
            const selectedDashboard = cloneDeep(this.dashboard);
            selectedDashboard.datamodel_id = value.id;
            this.setDashboardWithoutUndoRedo(selectedDashboard);
            this.dataModelPopupIsActive = false;
            this.folderId = null;
            this.checkBreadcrumb(null, null);
            await this.getDatamodelAuthorities();
            await this.getDatamodelHighestPriority();
            await this.getFields();
            await this.fetchDatasetIds(this.dashboard.datamodel_id);
            this.fetchDashboardListByDatamodelId({
                loadingComponent: null,
                dataModelId: selectedDashboard.datamodel_id
            })

            this.setCanvasStyle();
        },
        setTabIndex(index) {
            const beforeIndex = index;
            const beforeTabIndex = this.tabIndex;
            this.tabIndex = index;
            this.setCanvasStyle();
            this.startFilterPaneVisible();
            this.startFilterGridLayoutVisible();

            if (beforeIndex != beforeTabIndex) {
                setTimeout(() => {
                  this.viewChart();
                }, 500);
              }
        },
        addDashboardTab() {
            const clonedDashboard = cloneDeep(this.dashboard);
            const tabNamePrefix = "Tab ";

            this.tabIndex = clonedDashboard.tabs.length;
            
            let tabNameIndexNumber = this.tabIndex + 1;
            let tabOrder = Number(clonedDashboard.tabs[this.tabIndex - 1].order + 1);
            
            //#region for new tab
            const checkAndCorrectSimilarTabNames = () => {
                const tabNames = clonedDashboard.tabs.map((tab) => tab.name);
                /* eslint-disable */
                while (true) {
                    if (tabNames.includes(tabNamePrefix + tabNameIndexNumber)) tabNameIndexNumber++;
                    else break;
                }
            }
            //#endregion
            //It is written to prevent having the same tab name.
            checkAndCorrectSimilarTabNames();

            const newTabName = tabNamePrefix + tabNameIndexNumber;
            const tabData = this.createTabData(newTabName, tabOrder);

            clonedDashboard.tabs.push(tabData);

            this.setDashboard(clonedDashboard);
        },
        createTabData(newTabName, tabOrder) {
            const selectedDefaultTabData = cloneDeep(defaultTabData);
            selectedDefaultTabData.name = newTabName;
            selectedDefaultTabData.dashboardId = this.dashboard.id || null;
            selectedDefaultTabData.order = tabOrder;
            selectedDefaultTabData.properties.uiTabId = uuid();
            selectedDefaultTabData.properties.style = {
              ...selectedDefaultTabData.properties.style,
              ...{
                [allPropertiesKeys.SELECTED_TEMPLATE_ID]:
                  this.cardTemplates?.find((x) => x.isDefault)?.id ||
                  cardPropertiesTemplates[0]?.id,
              },
            };

            return selectedDefaultTabData;
        },
        duplicateDashboardTab(tabData) {
            const clonedDashboard = cloneDeep(this.dashboard);
            this.tabIndex = clonedDashboard.tabs.length;
            
            let duplicateTabNamePrefix = "Copy ";
            const selectedDefaultTabData = cloneDeep(tabData);
            const lastTabOrder = clonedDashboard.tabs[this.tabIndex - 1].order;

            //#region for duplicate tab
            const checkAndCorrectSimilarCopyTabNames = () => {
                const tabNames = clonedDashboard.tabs.map((tab) => tab.name);
                /* eslint-disable */
                while (true) {
                  if (!tabNames?.includes(duplicateTabNamePrefix + tabData.name)) {
                    break;
                  }
        
                  const str = duplicateTabNamePrefix.match(/\d+/);
        
                  if (str?.length) {
                    duplicateTabNamePrefix = duplicateTabNamePrefix.replace(str[0], "");
                    duplicateTabNamePrefix += `${Number(str[0]) + 1} `;
                  } else {
                    duplicateTabNamePrefix += `${2} `;
                  }
                }
              };
            //#endregion

            //It is written to prevent having the same copy tab name.
            checkAndCorrectSimilarCopyTabNames();
            
            selectedDefaultTabData.name = duplicateTabNamePrefix + tabData.name;
            selectedDefaultTabData.properties.uiTabId = uuid();
            selectedDefaultTabData.dashboardId = this.dashboard.id || null;
            selectedDefaultTabData.order = Number(lastTabOrder) + 1;

            delete selectedDefaultTabData.id;

            for (let i = 0; i < selectedDefaultTabData.charts.length; i++) {
                delete selectedDefaultTabData.charts[i].id;
                delete selectedDefaultTabData.charts[i].tabId;
                selectedDefaultTabData.charts[i].i = "temp_" + uuid();
                selectedDefaultTabData.charts[i].properties.tabIndex = this.tabIndex;
                selectedDefaultTabData.charts[i].properties.uiTabId = selectedDefaultTabData.properties.uiTabId;
            }

            clonedDashboard.tabs.push(selectedDefaultTabData);

            this.setDashboard(clonedDashboard);
            this.viewChart();
        },
        toggleDashboardScreenMode() {
            this.isFullScreen = !this.isFullScreen;
            this.$fullscreen.toggle();
        },
        allowTranspose(chartData){
            return chartData?.properties?.style?.PIVOT_TABLE_ALLOW_TRANSPOSE;
        },
        allowTransposeByChart(chartData) {
            if (!chartData) return;
      
            const transposeBoxKey = (items) => {
              return items.map((item) => ({
                ...item,
                boxKey:
                  item.boxKey === BOX_KEY_ENUM.COLUMN.KEY
                    ? BOX_KEY_ENUM.ROW.KEY
                    : item.boxKey === BOX_KEY_ENUM.ROW.KEY
                    ? BOX_KEY_ENUM.COLUMN.KEY
                    : item.boxKey,
              }));
            };
            // Belirtilen panelin PIVOT_TABLE_ALLOW_TRANSPOSE özelliği kontrol ediliyor
            if (chartData.properties?.style?.PIVOT_TABLE_ALLOW_TRANSPOSE) {
              // Aggregations ve Columns içindeki değişiklikleri uygula
              chartData.details.aggregation = transposeBoxKey(
                chartData?.details?.aggregation || []
              );
              chartData.details.columns = transposeBoxKey(
                chartData?.details?.columns || []
              );
      
              this.updateSelectedPanel(chartData, chartData.properties?.tabIndex);
              this.viewChart(chartData.i);
            }
          },
        deleteDashboardTab(selectedTabIndex) {
            if (selectedTabIndex != 0) {
                const selectedDashboard = cloneDeep(this.dashboard);
                selectedDashboard?.tabs?.forEach((tab, index) => {
                    tab?.charts.forEach(chart => {
                        if (index > selectedTabIndex) {
                            chart.properties.tabIndex = chart.properties.tabIndex -1;
                        }
                    });
                });
                selectedDashboard.tabs.splice(selectedTabIndex, 1);
                this.tabIndex = selectedTabIndex - 1;
                this.setDashboard(selectedDashboard);
                this.dashboardTabChanged();
            } else {
                window.alert(
                    "Dashboard için oluşturulmuş sabit tab'ı silemezsiniz. Düzenlemek için üzerine tıklayınız."
                );
            }
            this.viewChart();
        },
        goPanelPage(value) {
            this.isChartsAvailable = false;
            setTimeout(() => {
                this.$router.push({
                    name: routerEnums.PANEL,
                    query: {
                        chartType: value.type,
                        dashboardId: this.dashboard.id,
                        id: value.i,
                        tabIndex: this.tabIndex,
                    },
                });
            }, 200);
        },
        goToPanelPageWithQueryParams() {
            const chartType = this.$route.query.calculatedColumnChartType;
            const id = this.$route.query.calculatedColumnQueryPanelId;
            const tabIndex = this.$route.query.calculatedColumnTabIndex ?? 0;

            if (chartType && id) {
                this.isChartsAvailable = false;

                setTimeout(() => {
                    this.$router.push({
                        name: routerEnums.PANEL,
                        query: {
                            chartType,
                            dashboardId: this.dashboard.id,
                            id,
                            tabIndex,
                        },
                    });
                }, 200);
            }
        },
        goPanelPageWithAiAction() {
            //Dashboard içindeki en alttaki chartın dikeydeki konumunu bulmak için
            let bottomCardHeight = Math.max(
                ...this.selectedTabAllPanelList?.map((m) => m.y)
            ) + 8;

            if (!this.selectedTabAllPanelList?.length) bottomCardHeight = 0;

            const chartData = this.addPanel(0, bottomCardHeight, {
                id: chartTypes.TABLE,
                name: "generalPages.table"
              });
              
            setTimeout(() => {
                this.$router.push({
                    name: routerEnums.PANEL,
                    query: {
                        chartType: chartData.type,
                        dashboardId: this.dashboard.id,
                        id: chartData.i,
                        tabIndex: this.tabIndex,
                        isVisualizeAiActive: true
                    },
                });
            }, 200);
        },
        deleteChartForAI(chartId) {
            const selectedPanelListCloned = cloneDeep(this.selectedTabAllPanelList);

            let updatedList = selectedPanelListCloned.filter((x) => x.i != chartId);

            this.updatePanel(updatedList);
        },
        deleteChart(chartI) {
            this.deleteFilterValuesDeletedChart(chartI);
            //Works when deleted SELECTOR_DATA_ITEM_CHART
            this.deleteSelectorDataItemParamByChartI(chartI);
            
            const selectedPanelListCloned = cloneDeep(this.selectedTabAllPanelList);
            
            let updatedList = selectedPanelListCloned.filter((x) => x.i != chartI);

            this.updatePanel(updatedList);

            this.selectedTabPanelTabChart = {}

            this.setCanvasStyle();
        },
        deleteSelectorDataItemParamByChartI(chartI) {
            const clonedDashboard = cloneDeep(this.dashboard);
            const selectorParameters = clonedDashboard?.tabs[this.tabIndex]?.charts.find((x) => x.i === chartI)?.details?.[detailsKeys.SELECTOR_PARAMETERS];
            
            if (!selectorParameters) return;

            clonedDashboard.tabs.forEach(tab => {
                tab.charts.forEach(chart => {
                    if (chart.i === chartI || chart.type === chartTypes.SELECTOR_DATA_ITEM_CHART) return;
                    
                    const allDetailsList = [
                        ...(chart.details?.[detailsKeys.METRICS] ?? []),
                        ...(chart.details?.[detailsKeys.AGGREGATION] ?? []),
                        ...(chart.details?.[detailsKeys.COLUMNS] ?? [])
                    ]
                    const isUsedSelectorDataItemInChart = allDetailsList.find(x=> selectorParameters.some(s=> s.fieldId === x.fieldId));

                    if (!isUsedSelectorDataItemInChart) return;

                    chart.details[detailsKeys.METRICS] = chart.details?.[detailsKeys.METRICS].filter(x => !selectorParameters.some(s => s.fieldId === x.fieldId) && x.fieldId !== chartI);
                    chart.details[detailsKeys.AGGREGATION] = chart.details?.[detailsKeys.AGGREGATION].filter(x => !selectorParameters.some(s => s.fieldId === x.fieldId) && x.fieldId !== chartI);
                    chart.details[detailsKeys.COLUMNS] = chart.details?.[detailsKeys.COLUMNS].filter(x => !selectorParameters.some(s => s.fieldId === x.fieldId) && x.fieldId !== chartI);
                    chart.name = createSmartTitle(chart);

                    this.updateSelectedPanel(chart, chart?.properties?.tabIndex);
                    this.viewChart(...[chart.i])
                });
            });
        },
        deleteNonDefaultSelectorDataItemValues() { 
            const clonedDashboard = cloneDeep(this.dashboard);
            const defaultActiveSelectorIds = [];

            //FOR SELECTOR DATA ITEM CHARTS
            clonedDashboard.tabs.forEach(tab => {
                tab.charts.filter(x=> x.type === chartTypes.SELECTOR_DATA_ITEM_CHART).forEach(chart => {
                    if (chart?.properties?.style[SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_DEFAULT_VALUE]) {
                        defaultActiveSelectorIds.push(chart.i);
                        return;
                    } 

                    chart.details[detailsKeys.selectorDataitemValue] = "";
                    this.updateSelectedPanel(chart, chart?.properties?.tabIndex);
                });
            });

            const checkHasDefaultActive = (x) => {
                return defaultActiveSelectorIds.includes(x.selectorChartI) || !x.selectorChartI;
            }

            clonedDashboard.tabs.forEach(tab => {
                tab.charts.filter(x=> x.type !== chartTypes.SELECTOR_DATA_ITEM_CHART).forEach(chart => {
                    const allDetailsList = [...(chart.details?.[detailsKeys.METRICS] ?? []), ...(chart.details?.[detailsKeys.AGGREGATION] ?? []), ...(chart.details?.[detailsKeys.COLUMNS] ?? [])];
                    const isUsedDataItemSelector = allDetailsList.some(x=> x.fieldType === DatamodelContextDefaults.USAGE_TYPES.SELECTOR_DATA_ITEM);
                    
                    if (!isUsedDataItemSelector) return;

                    chart.details[detailsKeys.METRICS] = chart.details?.[detailsKeys.METRICS].filter(x => checkHasDefaultActive(x));
                    chart.details[detailsKeys.AGGREGATION] = chart.details?.[detailsKeys.AGGREGATION].filter(x => checkHasDefaultActive(x));
                    chart.details[detailsKeys.COLUMNS] = chart.details?.[detailsKeys.COLUMNS].filter(x => checkHasDefaultActive(x));
                    chart.name = createSmartTitle(chart);

                    this.updateSelectedPanel(chart, chart?.properties?.tabIndex);
                    this.viewChart(...[chart.i])
                });
            });
        },
        deleteFilterValuesDeletedChart(chartId) {
            //To delete the filter value added from the deleted chart from other charts.
            const clonedDashboard = cloneDeep(this.dashboard);
            const selectedPanel = clonedDashboard?.tabs[this.tabIndex]?.charts.find((x) => x.i === chartId);
            const { metrics, aggregation} = selectedPanel?.details;
            
            if (!metrics || !aggregation) return;

            let filterFieldList = [...metrics, ...aggregation];
            
            if (!filterFieldList?.length) return;

            clonedDashboard.tabs.forEach(tab => {
                tab.charts.forEach(chart => {
                    if (!chart.details?.filters || !chart.details?.params) return;

                    const chartAllFilters = [
                        ...chart?.details?.filters,
                        ...chart?.details?.params,
                    ];

                    chartAllFilters.forEach(filter => {
                        let selectedFilter = filterFieldList.find(f => f.fieldId === filter.fieldId);
                        if (selectedFilter) {
                            chart.details.filters = chart.details.filters.filter(x => x.isActiveChartFilter || x.field != selectedFilter.field);
                            chart.details.params = chart.details.params.filter(x => x.isActiveChartFilter || x.field != selectedFilter.field);
                            this.updateSelectedPanel(chart, chart?.properties?.tabIndex);
                            this.viewChart(chart.i);
                        }
                    });
                });
            });
        },
        duplicateChart(chartI) {
            this.addNewItemToUndoStack(cloneDeep(this.dashboard));

            const copiedPanel = cloneDeep(this.selectedTabAllPanelList.find(x=> x.i === chartI));

            if (this.isPanelTypeSelector({ type: copiedPanel.type })) {
                const selectorDataItems = cloneDeep(this.selectedTabAllPanelList.filter(x=> x.type === chartTypes.SELECTOR_DATA_ITEM_CHART));
                const key = SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_UNIQUE_NAME;
                const newUniqueName = checkDuplicateValueInChartStyle({charts: selectorDataItems, key, value: copiedPanel.properties.style[key], prefix: "Duplicate"});

                copiedPanel.properties.style[key] = newUniqueName;
                copiedPanel.name = newUniqueName;

            }
            
            const {
                name,
                description,
                footnote,
                details,
                x,
                y,
                w,
                h,
                type,
                properties,
                chartTypeName
            } = copiedPanel;

            const chartItem = {
                id: type,
                name: chartTypeName,
            };

            const gridItem = {
                ...panelObj({
                  x,
                  y,
                  gridItemW: w,
                  gridItemH: h,
                  chartItem,
                  tabIndex: this.tabIndex,
                  heightValue: this.heightValue,
                  widthValue: this.widthValue,
                  chartId: copiedPanel.type,
                  isDuplicated: true
                }),
                name,
                description,
                footnote,
                details,
              };
            
            properties.generatedDivid  = gridItem.properties.generatedDivid;

            gridItem.properties = {
                ...properties
            };
            
            this.pushPanel(gridItem);
            this.closePopoverMenu();

            if (
                copiedPanel.type !== chartTypes.LABEL &&
                !this.isChartTypeFilter(copiedPanel.type) &&
                !this.isPanelTypeLogo(copiedPanel)
            ) {
                this.viewChart(...[gridItem.i]);
            }
        },
        toggleDashboardSavePopup() {
            if (
                this.hasDashboardIdAndNotHaveReadAndEditAuthority
            ) {
                const selectedDashboard = cloneDeep(this.dashboard);
                this.setDashboardWithoutUndoRedo(selectedDashboard);
                this.saveDashboard();
            } else this.isActiveProjectNamePopup = !this.isActiveProjectNamePopup;
        },
        setProjectName(val) {
            this.isActiveProjectNamePopup = false;
            const selectedDashboard = cloneDeep(this.dashboard);
            selectedDashboard.name = val;
            selectedDashboard.folderId = this.folderId;
            this.setDashboardWithoutUndoRedo(selectedDashboard);
            this.saveDashboard();
        },
        async saveDashboard() {
            this.deleteNonDefaultSelectorDataItemValues();

            if (
                this.hasDashboardIdAndNotHaveReadAndEditAuthority
            ) {
                this.deleteAllFiltersInCharts();
                await this.updateDashboardAsync();
            } else if (this.hasDashboardIdAndEditAuthority) {
                this.deleteAllFiltersInCharts();
                const cloneSelectedDashboard = cloneDeep(this.dashboard);

                delete cloneSelectedDashboard.id;

                cloneSelectedDashboard?.tabs?.forEach((t) => {
                    delete t.id;

                    t?.charts?.forEach((c) => {
                        delete c.id;
                    });
                });

                await this.addDashboard({
                    loadingComponent: LoadingComponent.DashboardSave,
                    bodyParam: cloneSelectedDashboard,
                    folderId: this.folderId ?? "",
                }).then((response) => {
                    if (response) this.$router.push("/");
                })
            } else {
                this.deleteAllFiltersInCharts();
                await this.addDashboard({
                    loadingComponent: LoadingComponent.DashboardSave,
                    bodyParam: this.dashboard,
                    folderId: this.dashboard.folderId,
                });

                if (this.dashboardId) {
                    await this.saveChangeDashboardField();
                    await this.getFields();
                    this.$router.push({
                        name: routerEnums.DASHBOARD_EDIT,
                        params: { id: this.dashboardId },
                    });
                }

            }
            
            this.deleteDrillDownAllChart();

            this.setTempStorageByKey({
                key: TEMP_STORAGE_KEYS.TEMP_SELECTED_DASHBOARD,
                value: this.dashboard,
            });

            this.folderId = null;
        },
        async saveChangeDashboardField() {
            Object.keys(this.tempDatasetFields).forEach(async element => {
                this.tempDatasetFields[element].forEach(async (field, index) => {
                    if (!deepEqual(field, this.datamodelDatasetFields?.[element]?.[index])) {
                        let selectedField = this.selectedFieldList.find(x => x.fieldId == field.fieldId)
                        const newField = this.getSelectedDatasetField(field, selectedField)

                        newField.dashboardId = this.dashboardId;

                        await this.createDashboardField(newField);
                    }
                });
            });
            this.resetSelectedFields([]);
        },
        async updateDashboardAsync() {
            await this.updateDashboard({
                loadingComponent: this.isDashboardLocked ? null : LoadingComponent.DashboardSave,
                payload: {
                    dashboardId: this.dashboardId,
                    data: this.dashboard,
                },
            });
        },
        deleteFilterInChartsByUITabId(isTriggerFromFilterGridLayout = false) {
            const filterGridLayoutIds = this.selectedTabFilterGridLayoutCharts?.map((m) => m.i) ?? [];

            const cloneSelectedDashboard = cloneDeep(this.dashboard);
           
            const deleteFilterInCharts = (tabCharts, filterChart) => {
                if (isTriggerFromFilterGridLayout) {
                    //When the delete icon in the filter grid layout is triggered, this part of the code runs
                  tabCharts.forEach((chart) => {
                    if (
                      chart.i === filterChart.i &&
                      filterGridLayoutIds?.includes(chart.i)
                    ) {
                      this.deleteFilterValueByFilterType(chart);
                    }
        
                    [detailsKeys.FILTERS, detailsKeys.PARAMS].forEach(
                      (key) => {
                        if (chart?.details?.[key]?.length) {
                              chart.details[key] = chart.details?.[key].filter(
                              (x) => !filterGridLayoutIds?.includes(x.filterId) || x?.isActiveChartFilter
                            );
                        }
                      }
                    );
                  });
                } else {
                   //When the delete icon in the selection pane is triggered, this part of the code runs
                  tabCharts.forEach((chart) => {
                    if (
                      chart.i === filterChart.i &&
                      !filterGridLayoutIds?.includes(chart.i)
                    ) {
                      this.deleteFilterValueByFilterType(chart);
                    }
        
                    [detailsKeys.FILTERS, detailsKeys.PARAMS].forEach(
                      (key) => {
                        //chartda filter slotundan eklenen filter silinmemesi için "isActiveChartFilter" durumu eklendi!!
                        if (chart?.details?.[key]?.length) {
                            chart.details[key] = chart.details?.[key].filter((x) =>
                                filterGridLayoutIds?.includes(x.filterId) || x?.isActiveChartFilter
                            );
                        }
                      }
                    );
                  });
                }
              };

            this.selectedTabAllPanelList.forEach((filterChart) => {
              const applyToTabKey = Object.keys?.(
                filterChart?.properties?.style
              )?.find((x) => x?.includes("APPLY_TO_TABS"));
              const applyToTabs = filterChart?.properties?.style?.[applyToTabKey];
              const hasAllItem = applyToTabs?.includes(selectBoxOption.ALL_ITEM);
              //filterpane içinden gelen (filterChart) applyToTabs = AllItem ise tüm tablar içindeki chartların filtreleri silinir!
              if (hasAllItem) {
                cloneSelectedDashboard.tabs.forEach((tab) => {
                    deleteFilterInCharts(tab?.charts, filterChart)
                });
              } 
              //filterchartın applyToTabs = [tabIdx, tabIdy ...] eşit olan tabların içindeki chartların filtreleri silinir!
              else if (applyToTabs?.length && !hasAllItem) {
                applyToTabs.forEach((tabId) => {
                  const tabIndex = cloneSelectedDashboard.tabs.findIndex(
                    (i) => i.properties.uiTabId === tabId
                  );
                  deleteFilterInCharts(cloneSelectedDashboard.tabs[tabIndex].charts, filterChart);
                });
              } 
              //filterchartın applyToTabs'ı yok veya boş ise o anki bulunduğu tabın içindeki chartların filtreleri silinir!
              else {
                deleteFilterInCharts(cloneSelectedDashboard.tabs[this.tabIndex].charts, filterChart);
              }
            });

            this.setDashboardWithoutUndoRedo(cloneSelectedDashboard);
            this.drillThroughFilterParam.filterValue = {};
            this.viewChart();
          },
          //Iterates through all the charts within the dashboard to clear the filters and params lists.
          deleteAllFiltersInCharts() {
            const cloneSelectedDashboard = cloneDeep(this.dashboard);
            const chartsToSendSearchRequest = [];

            cloneSelectedDashboard.tabs.forEach((tab) => {
              tab?.charts.filter(x=> x.type !== chartTypes.SELECTOR_DATA_ITEM_CHART).forEach((chart) => {
                const allFilterList = [...chart?.details?.[detailsKeys.FILTERS], ...chart?.details?.[detailsKeys.PARAMS]];
                
                this.deleteFilterValueByFilterType(chart);

                if (!allFilterList.length) return;

                const selectedFilters = chart.details.filters.filter(
                  (x) => x.isActiveDefaultValue || x.isActiveChartFilter
                );
                const selectedParamFilters = chart.details.params.filter(
                  (x) => x.isActiveDefaultValue || x.isActiveChartFilter
                );

                chart.details.params = selectedParamFilters;
                chart.details.filters = selectedFilters;
                chartsToSendSearchRequest.push(chart.i);
              });
            });
            
            this.setDashboardWithoutUndoRedo(cloneSelectedDashboard);
            this.viewChart(...chartsToSendSearchRequest);
            this.drillThroughFilterParam.filterValue = {};
          },
        //Cleans the FILTER_VALUE within the details of the objects to which filters are added, thus removing the entered filter value.
        deleteFilterValueByFilterType(element) {
            const propertiesStyleValues = Object.values(element.properties.style);
            const hasDefaultValue = propertiesStyleValues.some(s=> [DefaultValue.SELECTION, DefaultValue.DYNAMIC].includes(s));
            
            if (!hasDefaultValue) {
                element.details[filterPropKey.FILTER_VALUE] = "";
            }
        },
        deleteDrillDownFilter(selectedChart) {
            const clonedSelectedChart = selectedChart;

            clonedSelectedChart.details.filters = clonedSelectedChart.details.filters.filter(f => !f.isDrillDown);

            clonedSelectedChart.details.FILTER_VALUE = null;

            this.updateAndViewSelectedPanel(clonedSelectedChart)
        },
        deleteDrillDown(selectedChart) {
            const clonedSelectedChart = selectedChart;

            clonedSelectedChart.details.aggregation = clonedSelectedChart.details.defaultAggregation;
            clonedSelectedChart.details.defaultAggregation = [];

            this.updateSelectedPanel(clonedSelectedChart);
        },
        deleteDrillDownAllChart() {
            this.selectedTabAllPanelList.forEach(chart => {
                if (chart?.details?.defaultAggregation?.length) {
                    this.deleteDrillDown(chart)
                }
            });
        },
        checkChartsToCustomSize(chartType) {
            return (
              this.isChartTypeFilter(chartType) ||
              this.isPanelTypeLabel(chartType) ||
              this.isPanelTypeButton(chartType) ||
              this.isPanelTypeSelector({ type: chartType })
            );
          },
        getParentRect() {
            return this.checkIsFilterGridLayoutActive()
              ? document
                  .getElementById("filterGridLayoutContent")
                  .getBoundingClientRect()
              : document.getElementById("content").getBoundingClientRect();
          },
        getMouseInGrid() {
            const parentRect = this.getParentRect();
            let mouseInGrid = false;
            
            if (
                mouseXY.x > parentRect.left &&
                mouseXY.x < parentRect.right &&
                mouseXY.y > parentRect.top &&
                mouseXY.y < parentRect.bottom
            ) {
                mouseInGrid = true;
            }

            return mouseInGrid;
        },
        checkIsFilterGridLayoutActive() {
            return layoutId === 'filterGridLayout';
        },
        drag: function (chartTypeId) {
            const parentRect = this.getParentRect()
            const mouseInGrid = this.getMouseInGrid();

            this.moveOrResizeCounter++;

            if (this.isMoveOrResizeCounterActive) {
                this.addNewItemToUndoStack(cloneDeep(this.dashboard));
            }
            
            if (
                mouseInGrid &&
                this.getDroppedChartIndex() === -1
            ) {
                const clonedSelectedTabAllPanelList = cloneDeep(this.selectedTabAllPanelList);
                const h = this.checkIsFilterGridLayoutActive() ? 5 : this.checkChartsToCustomSize(chartTypeId) ? 8 : 14;

                clonedSelectedTabAllPanelList.push({
                    x: mouseXY.x,
                    y: clonedSelectedTabAllPanelList.length + 14, // puts it at the bottom
                    w: this.checkChartsToCustomSize(chartTypeId) ? 8 : 16,
                    h,
                    i: "drop",
                    properties: this.checkIsFilterGridLayoutActive() ? { isFilterPaneChart: true } : {}
                });
                
                this.updatePanelList({
                    arr: clonedSelectedTabAllPanelList,
                    tabIndex: this.tabIndex,
                });
            }

            let index = this.getDroppedChartIndex();
            const ref =  this.checkIsFilterGridLayoutActive() ? this.$refs.filterGridLayoutRef : this.$refs.gridlayout;
            const chartList = this.checkIsFilterGridLayoutActive() ? this.selectedTabFilterGridLayoutCharts : this.selectedTabPanelList;

            if (index !== -1) {
                try {
                    ref.$children[
                        chartList?.length
                    ].$refs.item.style.display = "block";
                } catch (err) {
                    /* eslint-disable */
                }

                let el = ref.$children[index];

                el.dragging = {
                    top: mouseXY.y - parentRect.top,
                    left: mouseXY.x - parentRect.left,
                };
                
                if (!el.calcXY) return;

                let new_pos = el.calcXY(
                    mouseXY.y - parentRect.top,
                    mouseXY.x - parentRect.left
                );

                if (mouseInGrid) {
                    ref.dragEvent(
                        "dragstart",
                        "drop",
                        new_pos.x,
                        new_pos.y,
                        1,
                        1
                    );
                    DragPos.i = String(index);
                    DragPos.x = chartList[index].x;
                    DragPos.y = chartList[index].y;
                }
            }
        },
        dragend(chartItem) {
            const mouseInGrid = this.getMouseInGrid();
            const filteredPanelListByIdNotDrop = this.selectedTabAllPanelList.filter(f => f.i !== "drop");
           
            if (mouseInGrid) {
                const ref =  this.checkIsFilterGridLayoutActive() ? this.$refs.filterGridLayoutRef : this.$refs.gridlayout;

                ref.dragEvent(
                    "dragend",
                    "drop",
                    DragPos.x,
                    DragPos.y,
                    1,
                    1
                );

                this.updatePanelList({
                    arr: filteredPanelListByIdNotDrop,
                    tabIndex: this.tabIndex,
                });

                //Dashboard'a sürüklenen veya Tab Panel içine sürüklenen chartların eklenmesi burada başlıyor.
                this.addPanel(DragPos.x, DragPos.y, chartItem);

            } else {
                this.updatePanelList({
                    arr: filteredPanelListByIdNotDrop,
                    tabIndex: this.tabIndex,
                });
            }

            mouseXY.x = null;
            mouseXY.y = null;
            layoutId = null;

            this.moveOrResizeCounter = 0;
        },
        addPanel(x, y, chartItem) {
            const selectedTab = this.dashboardTabs[this.tabIndex];
            const chartId = chartItem.id;
            let widthValue = "200";
            let heightValue = "280";
            let gridItemW = 16;
            let gridItemH = 14;
            let isFilterPaneChart = false;
            
            if (this.checkChartsToCustomSize(chartId)) {
                gridItemW = 8;
                gridItemH = 8;
            }
            
            if (layoutId === 'filterGridLayout') {
                isFilterPaneChart = true;
                gridItemH = 5;
            }

            let addGridItem = panelObj({
                x,
                y,
                gridItemW,
                gridItemH,
                chartItem,
                tabIndex: this.tabIndex,
                heightValue,
                widthValue,
                chartId,
                selectedTabPanelList: this.selectedTabPanelList,
                selectedTab,
                isFilterGridLayoutItem: isFilterPaneChart,
                //layoutInfo kontrolü tab panel içine eklenen chartlar için çalışır
                layoutInfo: layoutInfo
            });
           
            this.pushPanel(addGridItem);
            
            return addGridItem;
        },
        getGridLayoutItemMinHeight(chartType) {
            return this.isChartTypeFilter(chartType) ? 4 : 0;
        },
        isChartTypeFilter(chartType) {
            return chartType?.toLowerCase().includes(panelTypes.FILTER);
        },
        callGetChartRefId(id) {
            return getChartRefId(id);
        },
        getSelectedChartById(id) {
            //find edilmiş chart için değişiklik yapılacaksa "updateSelectedPanel" methodunun kullanılması gerekmektedir.
            return cloneDeep(this.selectedTabAllPanelList.find((x) => x.i == id))
        },
        checkExpandIconActiveByChartType(chartData) {
            const chartType = chartData.type;
            
            return !this.isChartTypeFilter(chartType) && 
            !this.isPanelTypeLabel(chartType) &&
            !this.isPanelTypeButton(chartType) &&
            !this.isPanelTypeLogo(chartData.type) && 
            !this.isPanelTypeSelector(chartData)
        },
        resizeEvent: function (i) {
            this.moveOrResizeCounter++;

            if (this.isMoveOrResizeCounterActive) {
                this.addNewItemToUndoStack(cloneDeep(this.dashboard));
            }

            const clonedChart = this.getSelectedChartById(i)

            if (clonedChart.type === chartTypes.TABLE) return;
            
            this.currentResizingChartId = i;

            const chartRef = this.$refs?.[this.callGetChartRefId(i)];

            if (chartRef && chartRef.length) {
                chartRef[0].containerResizing?.();
            }
        },
        resizedEvent: function (i, _newH, _newW, newHPx, newWPx) {
            const clonedChart = this.getSelectedChartById(i);

            const setChartSizeAndRender = (chart, { minusHeight }) => {
                this.$set(chart.properties, "height", newHPx - minusHeight);
                this.$set(chart.properties, "width", newWPx);
    
                this.updateSelectedPanel(chart)
    
                this.currentResizingChartId = null;
    
                const chartRef = this.$refs?.[this.callGetChartRefId(chart.i)];
    
                if (chartRef && chartRef.length) {
                    chartRef[0].containerResized?.();
                }
            }

            const tabPanelCharts = this.selectedTabAllPanelList.filter(x=> x.properties.isTabPanelTabChart);
            
            if (tabPanelCharts?.length) {
                tabPanelCharts.forEach(tabPanelChart => {
                    if (tabPanelChart?.properties?.connectedChartId === i) {
                        setChartSizeAndRender(tabPanelChart, { minusHeight: 50 });
                    } 
                });
            }
            
            setChartSizeAndRender(clonedChart, { minusHeight: 0 });

            if (clonedChart.type === chartTypes.LABEL) {
                this.changeTextAreaHeightWhereCardTitleName(i);

                this.changeTextAreaHeightWhereCardTitleDescription(i);
            }
            
            this.moveOrResizeCounter = 0;
        },
        moveGridItem: function (i, newX, newY) {
            this.moveOrResizeCounter++;

            if (this.isMoveOrResizeCounterActive) {
                this.addNewItemToUndoStack(cloneDeep(this.dashboard));
            }
            
            if (!this.chartOldPositionY) {
                this.chartOldPositionY = newY;
            }

            this.notWorkAgainMethod = true;
        },
        movedGridItem: function (i, newX, newY, old) {
            let chart = this.getSelectedChartById(i);

            if (document.querySelectorAll(".vue-grid-layout")[0].offsetHeight > Number(this.canvasStyle.height.replace("px", "")) + 10) {

                chart.y = this.chartOldPositionY;
                chart.x = chart.x;

                this.updateSelectedPanel(chart)
            }
            this.chartOldPositionY = null;
            this.notWorkAgainMethod = false;

            this.addNewPaperFrame();
            
            this.moveOrResizeCounter = 0;
        },
        changeTextAreaHeightWhereCardTitleName(panelDataId) {
            let textarea = document.querySelector(`#dashboardCardTitleNameTextarea${panelDataId}`);
            this.changeTextAreaHeight(textarea);
        },
        changeTextAreaHeightWhereCardTitleDescription(panelDataId) {
            let textarea = document.querySelector(`#dashboardCardTitleDescriptionTextarea${panelDataId}`);
            this.changeTextAreaHeight(textarea);
        },

        changeTextAreaHeight(textarea) {
            if (textarea) {
                textarea.style.height = "auto";
                textarea.style.height = textarea.scrollHeight + "px";
            }
        },

        toggleDasboardSettings() {
            this.isChartRefreshTriggerActive = false;
            this.toggleDashboardPropertiesActive();
            setTimeout(() => {
                this.isChartRefreshTriggerActive = true;
            }, 500);
        },
        toggleDashboardSelectionPane() {
            if (this.isDashboardSelectionPaneActive) {
                this.setDashboardSelectionPaneActive(false);
            } else this.setDashboardSelectionPaneActive(true);
        },
        toggleFilterGridLayout() {
            if (this.isFilterGridLayoutActive) {
                this.setFilterGridLayoutActive(false);
            } else this.setFilterGridLayoutActive(true);
        },
        dashboardTabChanged() {
            const clonedDashboard = cloneDeep(this.dashboard);
            let order = 1;
            clonedDashboard.tabs.forEach((tab, tabIndex) => {
                tab.order = order++;
                tab?.charts?.forEach(chart => {
                    chart.properties.tabIndex = tabIndex;
                });
            });
            this.setDashboardWithoutUndoRedo(clonedDashboard);
            this.viewChart();
        },
        setSelectedPanelStyle(params, selectedPanel) {
            this.addNewItemToUndoStack(cloneDeep(this.dashboard));

            const selectedPanelCloned = cloneDeep(selectedPanel);

            selectedPanelCloned.properties.style[params.property] = params.value;

            this.updateSelectedPanel(selectedPanelCloned);
        },
        updateSelectedPanel(panelData, tabIndex = this.tabIndex) {
            const payload = {
                panelId: panelData.i,
                tabIndex,
                value: panelData,
            };

            this.setSelectedPanel(payload);
        },
        updateAndViewSelectedPanel(panelToUpdate) {
            this.updateSelectedPanel(panelToUpdate);
            this.viewChart(panelToUpdate.i);
        },
        reRenderChart() {
            //This method renders all charts 
            this.isChartRefreshTriggerActive = false;

            setTimeout(() => {
                this.isChartRefreshTriggerActive = true;
            }, 500);
        },
        changeCurrentResizingChartId(chartId) {
            //This method renders the corresponding chart.
            this.currentResizingChartId = chartId;

            setTimeout(() => {
                this.currentResizingChartId = null;
            }, 300);
        },
        async checkingDashboardLock() {
            if (!this.dashboardId) return;

            this.isDashboardLocked = await this.fetchObjectLock({
              loadingComponent: LoadingComponent.ObjectLock,
              bodyParam: {
                objectId: this.dashboardId,
                objectType: ContextMenuViewType.DASHBOARD,
              },
            });
          },
        async lockDashboard(isLocked) {
            const dialogConfig = isLocked
              ? this.confirmDialogTypes.LOCK
              : this.confirmDialogTypes.UNLOCK;
      
            this.setConfirmDialog(dialogConfig);
            this.dialogVisible = true;
        },
        setConfirmDialog(dialogConfig) {
            this.confirmDialogType = dialogConfig.type;
            this.confirmDialogTitle = this.$t(dialogConfig.title);
            this.confirmDialogMessage = this.$t(dialogConfig.message);
        },
        handleDashboardLocking(isLocking) {
            const lockAction = isLocking
              ? this.createObjectLock
              : this.deleteObjectLock;
      
            lockAction({
              loadingComponent: LoadingComponent.ObjectLock,
              bodyParam: {
                objectId: this.dashboardId,
                objectType: ContextMenuViewType.DASHBOARD,
              },
            });
      
            this.dialogVisible = false;
            this.init();
          },
        setDatamodelMenuWidthInDashboard(value) {
            this.$set(
              this.selectedTabStyle,
              allPropertiesKeys.DASHBOARD_DATAMODEL_LEFT_MENU_WIDTH,
              value
            );
        },
    },
};
