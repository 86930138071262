<template>
  <div class="properties-db-general-container">
    <DataModelSqlFilter
      v-for="customFilter in customFiltersMapping"
      :key="customFilter.paramId"
      :label="customFilter.label"
      :labelPrefix="customFilter.labelPrefix"
      :value="customFilter.value"
      :showActions="true"
      :filterId="customFilter.paramId"
      @filterClicked="(value) => $emit('filterClicked', value)"
      @editItem="showEditItemPopup(customFilter)"
      @deleteItem="deleteItem(customFilter)"
    />
    <div class="vis-mb--1" :class="{ 'vis-mt--1': customFiltersMapping.length }">
      <div class="vis-text--center">
        <el-button @click="openAddNewFilterPopup" size="small">{{
          $t("generalPages.addFilter")
        }}</el-button>
      </div>
      <DataModelCustomFilterPopup
        :isCustomFilterPopupOpen="isCustomFilterPopupOpen"
        :filter="customFilterPopupEditItem"
        :mode="customFilterPopupMode"
        @save="saveCustomFilterParam"
        @update="saveCustomFilterParam"
        @close="isCustomFilterPopupOpen = false"
      />
    </div>
  </div>
</template>

<script>
import DataModelSqlFilter from "../sql/DataModelSqlFilter";
import DataModelCustomFilterPopup from "../sql/DataModelCustomFilterPopup";
import cloneDeep from "clone-deep";
import {
  customFilterPopupDefaultFormData,
  customFilterPopupModes,
  filterSources,
} from "../sql/types";
import { ACTION } from "../../../store/modules/Visualize/DataModel/types";
import { LoadingComponent } from "../../../store/modules/Visualize/General/loadingComponentDefinitions";
import { mapActions } from "vuex";

export default {
  components: { DataModelSqlFilter, DataModelCustomFilterPopup },
  props: {
    customFilters: {
      type: Array,
      required: true,
    },
    datamodelId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isCustomFilterPopupOpen: false,
      customFilterPopupEditItem: null,
      customFilterPopupMode: null,
      tempCustomFilterParams: [],
    };
  },
  watch: {
    customFilters: {
      handler(val) {
        this.tempCustomFilterParams = [];

        if (val?.length) {
          this.tempCustomFilterParams = val;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.tempCustomFilterParams = cloneDeep(this.customFilters);
  },
  computed: {
    customFilterParamsOfCurrentDatamodel() {
      return this.tempCustomFilterParams.filter(
        (t) => !t.objectId || t.objectId === this.datamodelId
      );
    },
    customFiltersMapping() {
      return this.customFilterParamsOfCurrentDatamodel?.map((a) => {
        return {
          label: a.name,
          labelPrefix: "",
          value: `:${a.name}`,
          paramId: a.paramId,
          temporaryFilterParamId: a?.temporaryFilterParamId,
        };
      });
    },
  },
  methods: {
    ...mapActions({
      upsertDatamodelFilterParam: ACTION.UPSERT_DATAMODEL_FILTER_PARAM,
      deleteDatamodelFilterParam: ACTION.DELETE_DATAMODEL_FILTER_PARAM,
    }),
    openAddNewFilterPopup() {
      this.customFilterPopupEditItem = customFilterPopupDefaultFormData;
      this.customFilterPopupMode = customFilterPopupModes.Create;
      this.isCustomFilterPopupOpen = true;
    },
    async saveCustomFilterParam(newParam) {
      newParam.objectId = this.datamodelId;
      newParam.filterSource = filterSources.DATAMODEL;

      await this.upsertDatamodelFilterParam({
        loadingComponent: LoadingComponent.CustomFilters,
        datamodelId: this.datamodelId,
        bodyParam: newParam,
      });

      this.$emit("refreshCustomFilters");

      this.isCustomFilterPopupOpen = false;
    },
    async deleteItem(filter) {
      await this.deleteDatamodelFilterParam({
        loadingComponent: LoadingComponent.CustomFilters,
        datamodelId: this.datamodelId,
        paramId: filter?.paramId,
      });

      this.$emit("refreshCustomFilters");

      this.isCustomFilterPopupOpen = false;
    },
    showEditItemPopup(filter) {
      this.isCustomFilterPopupOpen = true;
      this.customFilterPopupMode = customFilterPopupModes.Edit;

      if (filter.temporaryFilterParamId)
        this.customFilterPopupEditItem = cloneDeep(this.customFilters).find(
          (c) => c?.temporaryFilterParamId === filter?.temporaryFilterParamId
        );
      else
        this.customFilterPopupEditItem = cloneDeep(this.customFilters).find(
          (c) => c?.paramId === filter?.paramId
        );
    },
  },
};
</script>