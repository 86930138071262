
import {
    autoRefreshTimes,
    fontsArr,
    fontSizesArr,
    fontWeightsArr,
    gridLinesArr,
    pivotTableGrandTotalPositions,
    pivotTableGrandTotalsSelections,
    pivotTableSelectionModes,
    pivotTableSelectionTypes,
    pivotTableSubTotalsSelections,
    PIVOT_TABLE_PROPERTIES_ENUM,
    predefineColorsArr,
    chartTablePageSize,
    tableColumnsInDrillThrough
} from "../../commons/dashboardProperties";
import { getPropertiesWithUndefinedCheck, PIVOT_TABLE_DEFAULT_CONFIGURATIONS } from "../../mocks/pivotTableDefaultProperties";
import { mapActions } from "vuex";
import { ACTION as ACTION_SEARCH } from "../../store/modules/Visualize/Home/types";
import { chartPropertiesCss } from "./chartPropertiesCssEnum";

export default {
    computed: {
        pivotTableComponentsMapping() {
            return {
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SHOW_FIELD_LIST]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SHOW_FIELD_LIST,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showFieldList",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SHOW_FIELD_LIST
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_SHOW_FIELD_LIST),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SHOW_FIELD_LIST,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_VALUE_SORTING]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_VALUE_SORTING,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.enableSorting",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_VALUE_SORTING
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ENABLE_VALUE_SORTING),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_VALUE_SORTING,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_EXPORT_MENU]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_EXPORT_MENU,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.exportMenu",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_EXPORT_MENU
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ALLOW_EXPORT_MENU),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_EXPORT_MENU,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_EXCEL_EXPORT]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_EXCEL_EXPORT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.allowExcelExport",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_EXCEL_EXPORT
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ALLOW_EXCEL_EXPORT),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_EXCEL_EXPORT,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_CSV_EXPORT]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_CSV_EXPORT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.allowCSVExport",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_CSV_EXPORT
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ALLOW_CSV_EXPORT),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_CSV_EXPORT,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_PDF_EXPORT]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_PDF_EXPORT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.allowPDFExport",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_PDF_EXPORT
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ALLOW_PDF_EXPORT),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_PDF_EXPORT,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SELECTION]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SELECTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.allowSelection",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SELECTION
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ALLOW_SELECTION),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SELECTION,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SHOW_INSIGHT]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SHOW_INSIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showInsight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SHOW_INSIGHT
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ALLOW_SHOW_INSIGHT),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SHOW_INSIGHT,
                    flexClass: true,
                    readOnly: !this.getShowInsightControl(process.env.VUE_APP_SHOW_INSIGHT),
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SHOW_SQL]: {
                    id: this.getPropertyId(
                      PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SHOW_SQL,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.sqlStatement",
                    placeholder: "panelProperties.showSql",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SHOW_SQL
                      ],
                      PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ALLOW_SHOW_SQL
                    ),
                    componentType: this.componentTypes.SHOW_BUTTON,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SHOW_SQL,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILLED_MEMBERS]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILLED_MEMBERS,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Show First Level Open",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILLED_MEMBERS
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_DRILLED_MEMBERS),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILLED_MEMBERS,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_TRANSPOSE]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_TRANSPOSE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Allow Transpose",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_TRANSPOSE
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ALLOW_TRANSPOSE),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_TRANSPOSE,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_CONTEXT_MENU]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_CONTEXT_MENU,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showContextMenu",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_CONTEXT_MENU
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ALLOW_CONTEXT_MENU),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_CONTEXT_MENU,
                    flexClass: true,
                },

                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECTION_MODE]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECTION_MODE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.selectionMode",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECTION_MODE
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_SELECTION_MODE),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECTION_MODE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: pivotTableSelectionModes,
                    flexClass: false,
                },

                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECTION_TYPE]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECTION_TYPE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.selectionType",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECTION_TYPE
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_SELECTION_TYPE),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECTION_TYPE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: pivotTableSelectionTypes,
                    flexClass: false,
                },

                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SUB_TOTALS]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SUB_TOTALS,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.subTotals",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SUB_TOTALS
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_SUB_TOTALS),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SUB_TOTALS,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: pivotTableSubTotalsSelections,
                    flexClass: false,
                },

                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTALS]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTALS,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.grandTotals",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTALS
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_GRAND_TOTALS),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTALS,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: pivotTableGrandTotalsSelections,
                    flexClass: false,
                },

                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_POSITION]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_POSITION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.grandTotalPosition",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_POSITION
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_GRAND_TOTAL_POSITION),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_POSITION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: pivotTableGrandTotalPositions,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_HEATMAP]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_HEATMAP,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.allowHeatMap",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_HEATMAP
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ALLOW_HEATMAP),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_HEATMAP,
                    flexClass: true,
                },

                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_TEXT_WRAP]: {
                    id: this.getPropertyId(
                      PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_TEXT_WRAP,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.wrapText",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle?.[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_TEXT_WRAP
                      ],
                      PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_TITLE_TEXT_WRAP
                    ),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_TEXT_WRAP,
                    flexClass: true,
                  },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.rowTitle.Row Title Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_FAMILY
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_TITLE_FONT_FAMILY),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_WEIGHT
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_TITLE_FONT_WEIGHT),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_SIZE]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_SIZE
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_TITLE_FONT_SIZE),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_COLOR]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_COLOR
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_TITLE_FONT_COLOR),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_TEXT_WRAP]: {
                    id: this.getPropertyId(
                      PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_TEXT_WRAP,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.wrapText",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle?.[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_TEXT_WRAP
                      ],
                      PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_TITLE_TEXT_WRAP
                    ),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_TEXT_WRAP,
                    flexClass: true,
                  },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.columnTitle.Column Title Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_FAMILY
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_TITLE_FONT_FAMILY),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_WEIGHT
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_TITLE_FONT_WEIGHT),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_SIZE]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_SIZE
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_TITLE_FONT_SIZE),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_COLOR]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_COLOR
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_TITLE_FONT_COLOR),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.measure.Measure Title Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_FAMILY
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_MEASURE_FONT_FAMILY),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_WEIGHT
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_MEASURE_FONT_WEIGHT),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_SIZE]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_SIZE
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_MEASURE_FONT_SIZE),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_COLOR]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_COLOR
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_MEASURE_FONT_COLOR),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.grandTotalTitle.Grand Total Title Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_FAMILY
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_FAMILY),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_WEIGHT
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_WEIGHT),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_SIZE]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_SIZE
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_SIZE),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_COLOR]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_COLOR
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_COLOR),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                }, [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.grandTotalMeasure.Grand Total Measure Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_FAMILY
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_FAMILY),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_WEIGHT
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_WEIGHT),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_SIZE]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_SIZE
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_SIZE),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_COLOR]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_COLOR
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_COLOR),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRID_LINES]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRID_LINES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.gridLines",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRID_LINES
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_GRID_LINES),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRID_LINES,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: gridLinesArr,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_HEIGHT]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_HEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.rowHeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_HEIGHT
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_HEIGHT),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_HEIGHT,
                    componentType: this.componentTypes.INPUT_WITH_ENTER,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                },

                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AUTO_REFRESH]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.BUBBLE_CHART_AUTO_REFRESH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.autoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AUTO_REFRESH
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_AUTO_REFRESH),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AUTO_REFRESH,
                    selectValues: autoRefreshTimes,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                },

                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AUTO_REFRESH_CUSTOM]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AUTO_REFRESH_CUSTOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.customAutoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AUTO_REFRESH_CUSTOM
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_AUTO_REFRESH_CUSTOM),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AUTO_REFRESH_CUSTOM,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                },

                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_VALUE]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_VALUE,
                        this.collapse.CHART
                    ),
                    label: this.getLabelByDynamicTitleShowValue(PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_SHOW_VALUE),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_VALUE
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_DYNAMIC_TITLE_VALUE),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_VALUE,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.noSelectionContent",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                    readOnly: this.isNoContentSelectionTheReadOnly(PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_SHOW_VALUE),
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_SHOW_VALUE]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_SHOW_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_SHOW_VALUE
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_DYNAMIC_TITLE_SHOW_VALUE),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_SHOW_VALUE,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GROUP_MULTIPLE_VALUES]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GROUP_MULTIPLE_VALUES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.group",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GROUP_MULTIPLE_VALUES
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_GROUP_MULTIPLE_VALUES),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GROUP_MULTIPLE_VALUES,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_PAGING]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_PAGING,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.paging",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_PAGING
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ENABLE_PAGING),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_PAGING,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_ROW_PAGING]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_ROW_PAGING,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Row Paging",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_ROW_PAGING
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ENABLE_ROW_PAGING),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_ROW_PAGING,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROWS_PER_PAGE]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROWS_PER_PAGE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.rowsPerPage",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROWS_PER_PAGE
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROWS_PER_PAGE),
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: chartTablePageSize,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROWS_PER_PAGE,
                    flexClass: false,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_COLS_PAGING]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_COLS_PAGING,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Column Paging",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_COLS_PAGING
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ENABLE_COLS_PAGING),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_COLS_PAGING,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_WIDTH]: {
                    id: this.getPropertyId(
                      PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_WIDTH,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.Row Header Width",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_WIDTH
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_WIDTH),
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_WIDTH,
                    componentType: this.componentTypes.INPUT_WITH_ENTER,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                  },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLS_PER_PAGE]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLS_PER_PAGE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Cols Per Page",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLS_PER_PAGE
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLS_PER_PAGE),
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: chartTablePageSize,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLS_PER_PAGE,
                    flexClass: false,
                },
                // START DRILL THROUGH
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_DRILL_THROUGH]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_DRILL_THROUGH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Allow Drill Through",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_DRILL_THROUGH
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ALLOW_DRILL_THROUGH),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_DRILL_THROUGH,
                    flexClass: true,
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILL_THROUGH_DASHBOARD_LIST]:
                {
                    id: this.getPropertyId(
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILL_THROUGH_DASHBOARD_LIST,
                    this.collapse.CHART
                    ),
                    label: "",
                    value: getPropertiesWithUndefinedCheck(
                    this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM
                        .PIVOT_TABLE_DRILL_THROUGH_DASHBOARD_LIST
                    ],
                    PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_DRILL_THROUGH_DASHBOARD_LIST
                    ),
                    componentType: this.componentTypes.AUTO_COMPLETE_INPUT_WITH_TABLE,
                    tableColumns: tableColumnsInDrillThrough,
                    property:
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILL_THROUGH_DASHBOARD_LIST,
                    flexClass: false,
                    queryParam: (value) => {
                        return `dashboardName=${value}&dataModelId=${this.datamodelId}`;
                      },
                    serviceFn: (params) => this.fetchDashboardSearch(params),
                    mapperFn: (content) => {
                        return content.map((m) => {
                          return {
                            id: m.id,
                            value: m.name,
                            nameSubtitle: m?.folderNames?.join(" / ") ?? "Home",
                          };
                        });
                      },
                      getAutoCompleteItem: (item) => {
                        return {
                          id: item.id,
                          name: item.value,
                          nameSubtitle: item?.nameSubtitle,
                        };
                      },
                },
                [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK]: {
                    id: this.getPropertyId(
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Drill On Double Click",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK
                    ], PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK),
                    componentType: this.componentTypes.SWITCH,
                    property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK,
                    flexClass: true,
                },
                // END DRILL THROUGH
            }
        },
    },
    methods: {
        ...mapActions({
          fetchDashboardSearch: ACTION_SEARCH.FETCH_DASHBOARD_SEARCH,
        }),
      },
};
