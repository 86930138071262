import {
  AXIS_TYPES_ENUM,
  Orientation,
  STACKED_COLUMN_CHART_PROPERTIES_ENUM,
  dashboardSPEnums,
  percentageValueKeys,
  showOnlyTypesExceptOnlyItem,
} from "../../commons/dashboardProperties";
import { STACKED_COLUMN_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/stackedColumnChartDefaultProperties";
import { getCommonChartItemStyle } from "../chart-filter/filterChartStyles";
import {
  calculateGridDistances,
  formatValueAxisLabels,
  getGroupingDataSeriesTotal,
  getForScaleGroupingDataSeries,
  isAxisLocationDefault,
  isAxisNameActive,
  isNameLocationDefault,
  setShowOnlyValue,
  truncateText,
  getSelectedColorPaletteByProperty,
  aggregationDateFormatController,
  colorConverter,
  sliderPositionHandler,
  xAxisWidthHandler,
  xAxisLabelPaddingByRotate,
} from "../chartOptionsHelper";
import { getAlignPositionByLegend, getOrientPositionByLegend, isChartHeightLimited, isChartWidthLimited } from "../chartViewHelper";
export const getStackedColumnChartOptions = (
  stackedColumnChartComputedValues,
  paramsFromSearch,
  yAxisName,
  xAxisName,
  panelData
) => {
  const { gridLeft, gridRight, gridBottom, gridTop } = calculateGridDistances({
    yAxisPosition:
      stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_POSITION,
    showYAxisLabels:
      stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_Y_AXIS_LABEL,
    showXAxisLabels:
      stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_X_AXIS_LABEL,
    showLegend:
      stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_LEGEND,
    legendPosition: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_LEGEND_POSITION,
    legendMaxCharacter: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_LEGEND_MAX_CHARACTER,
    yAxisNameLocation:
      stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_NAME_LOCATION,
    showOnly: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_ONLY,
    showPercentage:
      stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_PERCENT_TYPE,
    xAxisDataZoom: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_SHOW_DATA_ZOOM,
    xAxisRotate: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_ROTATE,
    yAxisDataZoom: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_SHOW_DATA_ZOOM,
    panelData,
    wrapXaxisLabel: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_WRAP,
    xAxisLabelMaxCharacter: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_MAX_CHARACTER,
    dataAxis: paramsFromSearch?.dataAxis
  });

  const isShowHundredPercent =
    stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_HUNDRED_PERCENT

  const isPercentTypeColumn =
    stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_PERCENT_TYPE ===
      percentageValueKeys.COLUMN || isShowHundredPercent;

  const percentType = stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_PERCENT_TYPE

  let groupingDataSeries = getForScaleGroupingDataSeries(
    isShowHundredPercent,
    paramsFromSearch
  );
 
  const optionObject = {
    grid: {
      top: gridTop +20,
      bottom: gridBottom,
      left: gridLeft,
      right: gridRight,
      containLabel: true,
    },
    title: {
      text: "",
    },
    legend: {
      show: isChartWidthLimited(panelData.w)
        ? stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_LEGEND
        : false,
      orient: getOrientPositionByLegend(stackedColumnChartComputedValues.STACKED_COLUMN_CHART_LEGEND_POSITION).toLowerCase(),
      left: getAlignPositionByLegend(stackedColumnChartComputedValues.STACKED_COLUMN_CHART_LEGEND_POSITION),
      top: getOrientPositionByLegend(stackedColumnChartComputedValues.STACKED_COLUMN_CHART_LEGEND_POSITION) === Orientation.VERTICAL ? "4%" : stackedColumnChartComputedValues.STACKED_COLUMN_CHART_LEGEND_VERTICAL_ALIGN,
      textStyle: {
        fontWeight:
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_LEGEND_FONT_WEIGHT,
        fontFamily:
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_LEGEND_FONT_FAMILY,
        fontSize:
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_LEGEND_FONT_SIZE,
        color:
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_LEGEND_FONT_COLOR,
      },
      backgroundColor: 'transparent',
      formatter: function (value) {
        return truncateText(aggregationDateFormatController(value, paramsFromSearch?.aggregationFormats), stackedColumnChartComputedValues.STACKED_COLUMN_CHART_LEGEND_MAX_CHARACTER);
      },
      type: "scroll",
    },
    tooltip: {
      show: true,
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      textStyle: {
        fontFamily: "Poppins",
        fontSize: 12,
      },
      formatter: function (value) {
        value.name = aggregationDateFormatController(value.name, paramsFromSearch?.aggregationFormats);

        return showOnlyTypesExceptOnlyItem.includes(
        stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_ONLY
      )
        ? setShowOnlyValue(
            value,
            paramsFromSearch,
            stackedColumnChartComputedValues,
            STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_ONLY,
            dashboardSPEnums.TOOLTIP,
            panelData,
            isPercentTypeColumn,
            percentType
          )
        :
        value.name;
      },
    },
    xAxis: {
      show: paramsFromSearch?.dataSeries ? true : false,
      name: "",
      axisLabel: {
        show: isChartHeightLimited(panelData.h)
          ? stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_X_AXIS_LABEL
          : false,
        fontWeight:
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_WEIGHT,
        fontFamily:
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_FAMILY,
        fontSize:
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_SIZE,
        color:
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_COLOR,
        rotate:
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_ROTATE,
        overflow: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_WRAP ? 'break' : 'none',
        padding: xAxisLabelPaddingByRotate(stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_ROTATE),
        width: xAxisWidthHandler(stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_WRAP,
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_MAX_CHARACTER),
        formatter: function (value) {
          const formattedValue = aggregationDateFormatController(value, paramsFromSearch?.aggregationFormats);
          return truncateText(
            formattedValue,
            stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_MAX_CHARACTER,
            stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LABEL_WRAP
          );
        },
        hideOverlap: true,
      },
      type: "category",
      axisTick: {
        show: false,
      },
      data: paramsFromSearch?.dataAxis ?? [],
      axisLine: {
        show: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_X_AXIS_LINE,
      },
    },
    yAxis: {
      show: paramsFromSearch?.dataSeries ? true : false,
      name: "",
      axisLabel: {
        show: isChartWidthLimited(panelData.w)
          ? stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_Y_AXIS_LABEL
          : false,
        hideOverlap: true,
        fontWeight:
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
        fontFamily:
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_FAMILY,
        fontSize:
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_SIZE,
        color:
          stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_COLOR,
        formatter: function (value) {
          return formatValueAxisLabels(
            paramsFromSearch?.metricFormats,
            value,
            isShowHundredPercent
          );
        },
      },
      type: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_LOGARITHMIC_SCALE
        ? AXIS_TYPES_ENUM.LOG
        : AXIS_TYPES_ENUM.VALUE,

      min: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_SHOW_DATA_ZOOM ?
        null : stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_MIN || null,
      max: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_SHOW_DATA_ZOOM ?
        null : stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_MAX || null,
      axisLine: {
        show: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_SHOW_LINE,
        lineStyle: {
          color:
            stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_LINE_STYLE_COLOR,
        },
      },
      axisTick: {
        show: false,
      },
      position:
        stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_POSITION,
      splitLine: {
          lineStyle: {
            color: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_GRID_LINE_COLOR,
          }
      },
    },
    dataZoom: Object.keys(paramsFromSearch).length && !stackedColumnChartComputedValues?.STACKED_COLUMN_CHART_Y_AXIS_MAX?.length
    ? [
        {
          type: 'slider',
          show: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_SHOW_DATA_ZOOM,
          start: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_DATA_ZOOM_START,
          end: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_DATA_ZOOM_END,
          zoomLock: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LOCK_DATA_ZOOM,
          brushSelect: !stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LOCK_DATA_ZOOM,
          backgroundColor: colorConverter(stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
            STACKED_COLUMN_CHART_DEFAULT_CONFIGURATIONS.STACKED_COLUMN_CHART_X_AXIS_DATA_ZOOM_PALETTE),
          fillerColor: colorConverter(stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_DATA_ZOOM_PALETTE,
            STACKED_COLUMN_CHART_DEFAULT_CONFIGURATIONS.STACKED_COLUMN_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
            STACKED_COLUMN_CHART_DEFAULT_CONFIGURATIONS.STACKED_COLUMN_CHART_DATA_ZOOM_FILLER_COLOR),
          dataBackground: {
            areaStyle: {
              color: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_DATA_ZOOM_PALETTE
            }
          },
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          disabled: !stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_SHOW_DATA_ZOOM,
          zoomLock: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_LOCK_DATA_ZOOM,
          xAxisIndex: [0],
        },
        {
          type: 'slider',
          show: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_SHOW_DATA_ZOOM,
          left:  sliderPositionHandler(stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_LEGEND,
            stackedColumnChartComputedValues.STACKED_COLUMN_CHART_LEGEND_POSITION),
          start: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_DATA_ZOOM_START,
          end: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_DATA_ZOOM_END,
          zoomLock: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_LOCK_DATA_ZOOM,
          brushSelect: !stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_LOCK_DATA_ZOOM,
          backgroundColor: colorConverter(stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
            STACKED_COLUMN_CHART_DEFAULT_CONFIGURATIONS.STACKED_COLUMN_CHART_Y_AXIS_DATA_ZOOM_PALETTE),
          fillerColor: colorConverter(stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
            STACKED_COLUMN_CHART_DEFAULT_CONFIGURATIONS.STACKED_COLUMN_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
            STACKED_COLUMN_CHART_DEFAULT_CONFIGURATIONS.STACKED_COLUMN_CHART_DATA_ZOOM_FILLER_COLOR),
          dataBackground: {
            areaStyle: {
              color: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_DATA_ZOOM_PALETTE
            }
          },
          yAxisIndex: [0],
        },
        {
          type: 'inside',
          disabled: !stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_SHOW_DATA_ZOOM,
          zoomLock: stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_LOCK_DATA_ZOOM,
          yAxisIndex: [0],
        },
      ]
    : [],
    series: paramsFromSearch?.dataSeries?.length
      ? paramsFromSearch?.dataSeries?.map((s, i) => {
          return {
            data: s.map((a, index) => {
              const total = getGroupingDataSeriesTotal(
                isShowHundredPercent,
                groupingDataSeries,
                index
              );
              return {
                value: isShowHundredPercent ? (a / total) * 100 : a,
                itemStyle: getCommonChartItemStyle(
                  paramsFromSearch?.dataAxis[index],
                  panelData
                ),
                displayValue: a,
              };
            }),
            type: "bar",
            stack: "total",
            name: paramsFromSearch?.dataSeriesNames?.[i],
            label: {
              show: isChartWidthLimited(panelData.w)
                ? stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_VALUE_LABEL
                : false,
              fontSize:
                stackedColumnChartComputedValues.STACKED_COLUMN_CHART_VALUE_LABEL_FONT_SIZE,
              fontFamily:
                stackedColumnChartComputedValues.STACKED_COLUMN_CHART_VALUE_LABEL_FONT_FAMILY,
              fontWeight:
                stackedColumnChartComputedValues.STACKED_COLUMN_CHART_VALUE_LABEL_FONT_WEIGHT,
              color:
                stackedColumnChartComputedValues.STACKED_COLUMN_CHART_VALUE_LABEL_FONT_COLOR,
              rotate:
                stackedColumnChartComputedValues.STACKED_COLUMN_CHART_VALUE_LABEL_ROTATE,
              formatter: function (value) {
                value.name = aggregationDateFormatController(value.name, paramsFromSearch?.aggregationFormats);
                
                return showOnlyTypesExceptOnlyItem.includes(
                stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_ONLY
              )
                ? setShowOnlyValue(
                    value,
                    paramsFromSearch,
                    stackedColumnChartComputedValues,
                    STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_ONLY,
                    dashboardSPEnums.LABEL,
                    panelData,
                    isPercentTypeColumn,
                    percentType
                )
                :
                value.name
              },
            },
            itemStyle: {
              borderRadius: [3, 3, 0, 0],
            },
            labelLayout: {
              hideOverlap:
                stackedColumnChartComputedValues.STACKED_COLUMN_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
            },
          };
        })
      : null,
    color: getSelectedColorPaletteByProperty(stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SELECTED_COLOR_PALETTE),
  };

  return {
    options: optionObject,
    yAxis: {
      name: isAxisNameActive(paramsFromSearch, yAxisName),
      show: isChartWidthLimited(panelData.w)
        ? stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_Y_AXIS_NAME
        : false,
      fontFamily:
        stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_FAMILY,
      fontSize:
        stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_SIZE,
      fontWeight:
        stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_WEIGHT,
      fontColor:
        stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_COLOR,
      isAxisLocationDefault: isAxisLocationDefault(
        stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_POSITION
      ),
      isNameLocationDefault: isNameLocationDefault(
        stackedColumnChartComputedValues.STACKED_COLUMN_CHART_Y_AXIS_NAME_LOCATION
      ),
    },
    xAxis: {
      name: isAxisNameActive(paramsFromSearch, xAxisName),
      show: isChartHeightLimited(panelData.h)
        ? stackedColumnChartComputedValues.STACKED_COLUMN_CHART_SHOW_X_AXIS_NAME
        : false,
      fontFamily:
        stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_FAMILY,
      fontSize:
        stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_SIZE,
      fontWeight:
        stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_WEIGHT,
      fontColor:
        stackedColumnChartComputedValues.STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_COLOR,
    },
  };
};
