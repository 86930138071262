<template>
  <div class="vis-tools-box vis-page vis-bg-color-white">
    <div v-if="showRemoveItemOverlay" class="remove-item-overlay">
      <draggable
        class="list-group draggable-item-placeholder"
        :list="[]"
        :group="{ name: 'people', pull: 'clone', put: true }"
        :sort="false"
        @change="handleDatasetListDrag"
      >
        <div class="list-group-item"></div>
        <div class="remove-item-overlay-container"></div>
      </draggable>
    </div>
    <div class="vis-row vis-tools-tab">
      <Typography
        variant="h5"
        :text="selectedDatamodelName"
        :tooltipEnabled="true"
        class="vis-ellipsis-fieldsMenu"
      >
        <template v-slot:tooltipContent>{{ selectedDatamodelName }}</template>
      </Typography>
    </div>
    <InputSearch
      v-if="Object.keys(selectedDataModel).length"
      customComponentClass="vis-dataset-search-input"
      @keyupSearchData="searchField = $event"
    />
    <!-- ROW AND VIS-TOOLS-TAB -->
    <el-collapse v-model="activeCollapseItem">
      <el-collapse-item
        v-if="parameterList.length && isParameterCollapseActive"
        :title="$t('Dynamic Items')"
        :name="$t('Dynamic Items')"
        class="vis-collapse-item vis-collapse-dynamic-items"
      >
        <draggable
          class="list-group"
          :list="parameterList"
          :group="{ name: 'people', pull: 'clone', put: false }"
          :sort="false"
        >
          <ul
            class="list-group-item"
            v-for="parameter in parameterList"
            :key="parameter.fieldId"
          >
            <li
              draggable
              class="vis-flex--spacebeetwen-Ycenter vis-cursor-pointer"
            >
              <div class="vis-flex--Ycenter vis-dataModel-list-icons">
                <i
                  :class="CustomIcon.DynamicFormWght300"
                  aria-hidden="true"
                ></i>
                <span class="vis-field-item-span"> {{ parameter.alias }}</span>
              </div>
            </li>
          </ul>
        </draggable>
      </el-collapse-item>
      <el-collapse-item
        v-if="datamodelFilterParams.length"
        title="Datamodel Paramaters"
        name="Datamodel Paramaters"
        class="vis-collapse-item vis-collapse-dynamic-items"
      >
        <draggable
          class="list-group vis-mb--1"
          :list="datamodelFilterParams"
          :group="{ name: 'people', pull: 'clone', put: false }"
          :sort="false"
        >
          <ul
            class="list-group-item"
            v-for="parameter in datamodelFilterParams"
            :key="parameter.paramId"
          >
            <li
              draggable
              class="vis-flex--spacebeetwen-Ycenter vis-cursor-pointer"
            >
              <div class="vis-flex--Ycenter vis-dataModel-list-icons">
                <i :class="CustomIcon.FilterAlt" aria-hidden="true"></i>
                <span class="vis-field-item-span"> {{ parameter.name }}</span>
              </div>
            </li>
          </ul>
        </draggable>
      </el-collapse-item>
      <el-collapse-item
        v-for="dataSet in selectedDataModel.datasets"
        :key="dataSet.datasetId"
        :title="shortCollapseItemTitle(dataSet.name)"
        :name="dataSet.name"
        class="vis-collapse-item"
        :class="{
          'vis-white-space-nowrap': checkDatasetNameLength(dataSet.name),
        }"
        v-show="getDatasetFieldsByDatasetId(dataSet.datasetId).length"
      >
        <template slot="title">
          <span>{{ dataSet.name }} </span>
          <!-- show dashboard context menu -->
          <template>
            <div class="vis-datasetfield-radiogroup">
              <ejs-contextmenu
                :id="`dashboardDatasetTitleContextMenu-${dataSet.datasetId}`"
                :items="contextMenuItems"
                :select="
                  (arg) =>
                    handleDashboardDatasetTitleContextMenuItemSelect(
                      arg,
                      dataSet.datasetId
                    )
                "
              />
            </div>
            <i
              @click.stop="
                openDashboardDatasetTitleContextMenu($event, dataSet.datasetId)
              "
              aria-hidden="true"
              class="vis-collapse-item-three-dot"
              :class="CustomIcon.VerticalThreeDot"
            >
            </i>
          </template>
        </template>
        <div class="vis-tools-content vis-mb--1">
          <draggable
            class="list-group"
            :list="getDatasetFieldsByDatasetId(dataSet.datasetId)"
            :group="{ name: 'people', pull: 'clone', put: false }"
            :sort="false"
          >
            <ul
              class="list-group-item"
              v-for="element in getDatasetFieldsByDatasetId(dataSet.datasetId)"
              :key="element.fieldId || element.paramId"
            >
              <li
                draggable
                @dragend="$emit('passiveFieldBox', false)"
                @dragstart="
                  dragAndDropField(dataSet, element),
                    $emit('activeFieldBox', true)
                "
                class="vis-flex--spacebeetwen-Ycenter vis-cursor-pointer"
                :class="[
                  {
                    selectedFieldActiveClass: checkSelectedFieldActive(element),
                  },
                ]"
                @click.ctrl.stop="
                  $emit('addSelectedFieldsWithKeyCtrl', element)
                "
                @click.shift.stop="
                  addSelectedFieldsWithKeyShift(
                    getDatasetFieldsByDatasetId(dataSet.datasetId),
                    element
                  )
                "
              >
                <el-tooltip
                  class="item"
                  placement="right-start"
                  popper-class="vis-bg-light-gray vis-br--1"
                  effect="light"
                  :open-delay="2000"
                >
                  <div
                    slot="content"
                    class="vis-mx--05 vis-display-flex vis-flex-direction--column vis-tooltip-popup-content"
                    v-show="element.filterType !== filterType.CUSTOM"
                  >
                    <span class="vis-color-neon-blue vis-text--xsmall">
                      {{ $t("generalPages.Dashboard") }}
                    </span>
                    <span class="vis-color-black vis-text--xsmall vis-mt--05">{{
                      $t("generalPages.Data Item Alias")
                    }}</span>
                    <span class="vis-color-black vis-font-bold">
                      {{ `${dataSet.name}.${element.alias}` }}
                    </span>
                    <div class="field-border vis-mt--05"></div>
                    <span
                      class="vis-color-neon-blue vis-text--xsmall vis-mt--05"
                    >
                      {{ $t("generalPages.Datamodel") }}
                    </span>
                    <span class="vis-color-black vis-text--xsmall vis-mt--05">{{
                      $t("generalPages.Data Item Alias")
                    }}</span>
                    <span class="vis-color-black vis-font-bold">
                      {{
                        `${
                          datamodelDatasetField(element)?.alias ||
                          datamodelDatasetAlias(element)
                        }`
                      }}
                    </span>
                    <span class="vis-color-black vis-text--xsmall vis-mt--05">{{
                      $t("generalPages.Data Item Name")
                    }}</span>
                    <span class="vis-color-black vis-font-bold">
                      {{
                        `${dataSet.name}.${
                          datamodelDatasetField(element)?.name || element.name
                        }`
                      }}
                    </span>
                  </div>
                  <div
                    class="vis-flex--Ycenter vis-dataModel-list-icons vis-field-item"
                  >
                    <i :class="datasetIcon(element)" aria-hidden="true"></i>
                    <span class="vis-field-item-span">{{
                      element.alias || element.name
                    }}</span>
                  </div>
                  <i
                    class="vis-icon icon-dots vis-dataset-dots"
                    aria-hidden="true"
                  ></i>
                </el-tooltip>
              </li>
            </ul>
          </draggable>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div v-if="!disableArea" class="vis-mask"></div>
  </div>
</template>
<script>
import { DatamodelContextDefaults } from "../../commons/dataModelTypes";
import draggable from "vuedraggable";
import cloneDeep from "clone-deep";
import { CustomIcon } from "../../assets/js/custom-icons";
import {
  checkIfDatasetFieldSame,
  detailsKeys,
} from "../../commons/dashboardAndPanel";
import { filterType } from "../../commons/filterComponents";
import InputSearch from "../helper/Form/InputSearch.vue";
import eventBus from "../../eventBus";
import {
  getDatamodelDatasetField,
  getDatamodelDatesetAlias,
} from "../../util/dataset-fields/datasetFields";
import { filterSources } from '../data/sql/types';

export default {
  components: {
    draggable,
    InputSearch,
  },
  props: {
    selectedDataModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedPanel: {
      type: Object,
    },
    datasetFields: {
      type: Object,
      default: () => {
        return {};
      },
    },
    datasetFilterParams: {
      type: Array,
    },
    isShowDatasetFilterParam: {
      type: Boolean,
    },
    disableArea: {
      type: Boolean,
      default: true,
    },
    datamodelDatasetFields: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedFieldsWithKeyCtrlShift: {
      type: Array,
      default: () => {
        return [];
      },
    },
    parameterList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isParameterCollapseActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeCollapseItem: [],
      searchField: null,
      CustomIcon: CustomIcon,
      contextMenuItemEnum: {
        CREATE_CALCULATION: "CREATE_CALCULATION",
      },
      showRemoveItemOverlay: false,
      filterType: filterType,
    };
  },
  watch: {
    searchField: {
      handler(search) {
        if (search) {
          let datasetHasTheSearchedFile = [];
          this.selectedDataModel?.datasets?.forEach((dataset) => {
            const searchedField = this.getDatasetFieldsByDatasetId(
              dataset.datasetId
            );
            if (searchedField.length)
              datasetHasTheSearchedFile.push(dataset?.name);
          });
          this.activeCollapseItem = datasetHasTheSearchedFile;
        } else {
          this.activeCollapseItem = [
            this.selectedDataModel?.datasets?.[0]?.name,
          ];
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    eventBus.$off("onDragStart");
    eventBus.$off("onDragEnd");
  },
  mounted() {
    this.activeCollapseItem.push(this.selectedDataModel?.datasets?.[0]?.name);

    //ShowRemoveItemOverlay aktif olduğunda draggable alanı etkin olur ve handleDatasetListDrag fonksiyonu çalışır.
    eventBus.$on("onDragStart", () => {
      this.showRemoveItemOverlay = true;
    });
    eventBus.$on("onDragEnd", () => {
      this.showRemoveItemOverlay = false;
    });
  },
  computed: {
    datamodelFilterParams() {
      const filtered = this.datasetFilterParams
        ?.filter((x) => x.filterSource === filterSources.DATAMODEL)
        ?.map((fp) => {
          return {
            ...fp,
            alias: fp.name,
          };
        });

      // in the filtered list there are some objects duplicated, use paramId in this objects to make it flat list

      return filtered.filter(
        (v, i, a) => a.findIndex((t) => t.paramId === v.paramId) === i
      );
    },
    contextMenuItems() {
      return [
        {
          text: this.$t("generalPages.createCalculation"),
          value: this.contextMenuItemEnum.CREATE_CALCULATION,
        },
      ];
    },
    datasetName() {
      return this.selectedDataModel?.properties?.dataSet?.name;
    },
    selectedDatamodelName() {
      return this.selectedDataModel?.name;
    },
  },
  methods: {
    datamodelDatasetField(element) {
      return getDatamodelDatasetField(this.datamodelDatasetFields, element);
    },
    datamodelDatasetAlias(element) {
      return getDatamodelDatesetAlias(this.datasetFields, element);
    },
    handleDatasetListDrag(evt) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      const field = evt?.added?.element;
      if (field) {
        selectedPanelCloned.details[detailsKeys.METRICS] =
          selectedPanelCloned.details?.[detailsKeys.METRICS]?.filter((m) => {
            return !(m.boxKey == field.boxKey && m.fieldId == field.fieldId);
          });
        selectedPanelCloned.details[detailsKeys.AGGREGATION] =
          selectedPanelCloned.details?.[detailsKeys.AGGREGATION]?.filter(
            (a) => {
              return !(a.boxKey == field.boxKey && a.fieldId == field.fieldId);
            }
          );
        selectedPanelCloned.details[detailsKeys.ORDERS] =
          selectedPanelCloned.details?.[detailsKeys.ORDERS]?.filter((o) => {
            return !(o.boxKey == field.boxKey && o.fieldId == field.fieldId);
          });
        selectedPanelCloned.details[detailsKeys.FILTERS] =
          selectedPanelCloned.details?.[detailsKeys.FILTERS]?.filter((f) => {
            return !(f.boxKey == field.boxKey && f.fieldId == field.fieldId);
          });
        selectedPanelCloned.details[detailsKeys.SECTIONS] =
          selectedPanelCloned.details?.[detailsKeys.SECTIONS]?.filter((s) => {
            return !(s.boxKey == field.boxKey && s.fieldId == field.fieldId);
          });
        selectedPanelCloned.details[detailsKeys.COLUMNS] =
          selectedPanelCloned.details?.[detailsKeys.COLUMNS]?.filter((c) => {
            return !(c.boxKey == field.boxKey && c.fieldId == field.fieldId);
          });
        selectedPanelCloned.details[detailsKeys.SELECTOR_PARAMETERS] =
          selectedPanelCloned.details?.[
            detailsKeys.SELECTOR_PARAMETERS
          ]?.filter((c) => {
            return !(c.boxKey == field.boxKey && c.fieldId == field.fieldId);
          });
      }
      this.updateSelectedPanelAndViewChart(selectedPanelCloned);
    },
    openDashboardDatasetTitleContextMenu(event, datasetId) {
      document
        .getElementById(`dashboardDatasetTitleContextMenu-${datasetId}`)
        .ej2_instances[0].open(event.y, event.x);
    },
    handleDashboardDatasetTitleContextMenuItemSelect(arg, datasetId) {
      const value = arg?.item?.value;

      if (value === this.contextMenuItemEnum.CREATE_CALCULATION) {
        this.$emit("goToCalculatedColumn", datasetId);
      }
    },
    addSelectedFieldsWithKeyShift(fieldList, value) {
      this.$emit("addSelectedFieldsWithKeyShift", { fieldList, value });
    },
    checkDatasetNameLength(datasetName) {
      return datasetName.length > 20;
    },
    isActiveFields(field) {
      return checkIfDatasetFieldSame(this.selectedPanel, field);
    },
    dragAndDropField(value, field) {
      const datasetId = value.datasetId.toString();

      if (field.filterType != filterType.CUSTOM) {
        let x = cloneDeep(this.selectedPanel);
        x.properties.dataSet.id = datasetId;
        x.properties.dataSet.name = value.name;
        x.properties.dataSet.filterProperty = this.getDatasetFilterProperty(
          datasetId,
          field
        );

        this.updateSelectedPanel(x);
      }
    },
    // TODO
    getDatasetFilterProperty(datasetId, field) {
      if (!this.datasetFilterParams?.length) {
        return { filterType: filterType.SECURITY };
      } else {
        let selectedFilterParam = this.datasetFilterParams.find(
          (x) => x.objectId == datasetId
        );

        return {
          filterType: selectedFilterParam
            ? selectedFilterParam.filterType
            : field.filterType,
          mandatory: selectedFilterParam
            ? selectedFilterParam.mandatory
            : field.mandatory,
        };
      }
    },
    getDatasetFilterParamsByDatasetId(datasetId) {
      return this.datasetFilterParams
        ?.filter(
          (x) =>
            x?.objectId == datasetId &&
            x.filterType != filterType.SECURITY &&
            x.filterType != filterType.TOKEN
        )
        .map((fp) => {
          return {
            ...fp,
            alias: fp.name,
          };
        });
    },
    getSearchFieldByFieldAlias(fieldAlias) {
      return fieldAlias.toLowerCase().includes(this.searchField.toLowerCase());
    },
    getDatasetFieldsByDatasetId(datasetId) {
      let visibleDatasetFields = this.datasetFields?.[datasetId]?.filter(
        (x) => !x.isHidden && x?.isAuthorize
      );
      const datasetFieldsAndDatasetFilterParams = [
        ...visibleDatasetFields,
        ...this.getDatasetFilterParamsByDatasetId(datasetId),
      ];
      //"isShowDatasetFilterParam" chart tipi filtersa filterParamları getirmek için. Filter olmayan chartlarda datasetFilterParamları görmek istemiyoruz.
      if (this.searchField) {
        return this.isShowDatasetFilterParam
          ? datasetFieldsAndDatasetFilterParams.filter((field) => {
              return this.getSearchFieldByFieldAlias(field.alias);
            })
          : visibleDatasetFields.filter((field) => {
              return this.getSearchFieldByFieldAlias(field.alias);
            });
      } else {
        return this.isShowDatasetFilterParam
          ? datasetFieldsAndDatasetFilterParams
          : visibleDatasetFields.filter((x) => !x.isHidden);
      }
    },
    datasetIcon(element) {
      const iconByTypes = {
        [DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE]: CustomIcon.Abc,
        [DatamodelContextDefaults.USAGE_TYPES.DATE]: CustomIcon.Clock_Time,
        [DatamodelContextDefaults.USAGE_TYPES.MEASURE]: CustomIcon.Measure,
        [filterType.CUSTOM]: CustomIcon.FilterAlt,
      };
      return iconByTypes[element.usageType || element.filterType];
    },
    shortCollapseItemTitle(name) {
      return name.substring(0, 23);
    },
    updateSelectedPanel(value) {
      this.$emit("updateSelectedPanel", {
        value,
      });
    },
    updateSelectedPanelAndViewChart(value) {
      this.$emit("updateSelectedPanelAndViewChart", {
        value,
      });
    },
    checkSelectedFieldActive(value) {
      return this.selectedFieldsWithKeyCtrlShift.find(
        (s) => s.fieldId === value.fieldId
      );
    },
  },
};
</script>
<style scoped>
.vis-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  opacity: 0.3;
  cursor: not-allowed;
}
.selectedFieldActiveClass {
  background-color: var(--primary) !important;
  border: none !important;
  color: #fff;
  padding: 2px 5px;
  border-radius: 2px;
}
.selectedFieldActiveClass i {
  color: #fff !important;
}
.vis-field-item {
  max-width: calc(100% - 10px);
}
.vis-field-item-span {
  max-width: 100%;
}
::v-deep .vis-dataset-search-input .vis-input-icon {
  right: 5px !important;
}
.remove-item-overlay {
  z-index: 2;
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
}
.remove-item-overlay-container {
  width: 100%;
  height: 100%;
}
.draggable-item-placeholder {
  opacity: 0;
  height: 100%;
}
</style>
