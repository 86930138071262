
import {
    TEXT_FILTER_PROPERTIES_ENUM,
    filterSelectionActions,
    filterDefaultValues
} from "../../commons/dashboardProperties";
import { TEXT_FILTER_DEFAULT_CONFIGURATIONS, getPropertiesWithUndefinedCheck } from "../../mocks/textFilterDefaultProperties";


export default {
    computed: {
        textFilterComponentsMapping() {
            return {
                [TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_MANDATORY_SELECTION]: {
                    id: this.getPropertyId(
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_MANDATORY_SELECTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.mandatorySelection",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_MANDATORY_SELECTION
                    ], TEXT_FILTER_DEFAULT_CONFIGURATIONS.TEXT_FILTER_MANDATORY_SELECTION),
                    property: TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_MANDATORY_SELECTION,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_CASE_INSENSITIVE]: {
                    id: this.getPropertyId(
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_CASE_INSENSITIVE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Case Insensitive",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_CASE_INSENSITIVE
                    ], TEXT_FILTER_DEFAULT_CONFIGURATIONS.TEXT_FILTER_CASE_INSENSITIVE),
                    property: TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_CASE_INSENSITIVE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_SELECTION_ACTION]: {
                    id: this.getPropertyId(
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_SELECTION_ACTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.selectionAction",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_SELECTION_ACTION
                    ], TEXT_FILTER_DEFAULT_CONFIGURATIONS.TEXT_FILTER_SELECTION_ACTION),

                    property: TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_SELECTION_ACTION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: filterSelectionActions,
                    flexClass: false,
                },
                [TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_APPLY_TO_TABS]: {
                    id: this.getPropertyId(
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_APPLY_TO_TABS,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Apply to Tabs",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_APPLY_TO_TABS
                    ], [this.dashboardTabsOptionList[this.chartTabIndex]?.value]),

                    property: TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_APPLY_TO_TABS,
                    componentType: this.componentTypes.CUSTOM_MULTIPLE_SELECT_BOX,
                    selectValues: this.dashboardTabsOptionList,
                    flexClass: false,
                },
                [TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_DEFAULT_SELECTION]: {
                    id: this.getPropertyId(
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_DEFAULT_SELECTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.defaultValue",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_DEFAULT_SELECTION
                    ], TEXT_FILTER_DEFAULT_CONFIGURATIONS.TEXT_FILTER_DEFAULT_SELECTION),
                    property: TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_DEFAULT_SELECTION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: filterDefaultValues,
                    flexClass: false,
                },

                [TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_UNIQUE_NAME]: {
                    id: this.getPropertyId(
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_UNIQUE_NAME,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.uniqueName",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_UNIQUE_NAME
                    ], TEXT_FILTER_DEFAULT_CONFIGURATIONS.TEXT_FILTER_UNIQUE_NAME),
                    property: TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_UNIQUE_NAME,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                    inputType: "text",
                },

                [TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_TAB_INDEX]: {
                    id: this.getPropertyId(
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_TAB_INDEX,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.tabIndex",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_TAB_INDEX
                    ], TEXT_FILTER_DEFAULT_CONFIGURATIONS.TEXT_FILTER_TAB_INDEX),
                    property: TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_TAB_INDEX,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                    inputType: "text",
                },
            }
        },
    },
};
