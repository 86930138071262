import { tableSubTotalPositionsEnums } from "../commons/dashboardProperties"

export const datatableDefaultConfigurations = {
    datatableAggregateResult: true,
    datatablePageSize: 100,
    datatableRowHeight: 40,
    datatableColumnAlign: undefined,
    datatableEnableSorting: true,
    datatableEnableFiltering: false,
    datatableEnableExportMenu: false,
    datatableEnableExcelExport: true,
    datatableEnableCSVExport: true,
    datatableEnablePDFExport: true,
    datatableEnablePaging: true,
    datatableEnableSearch: false,
    datatableEnableResizing: true,
    datatableEnableReordering: true,
    datatableEnableSelection: true,
    datatableEnableTextWrap: false,
    datatableEnableShowInsight: true,
    datatableEnableShowSql: false,
    datatableEnableContextMenu: true,
    datatableColumnFontFamily: "Poppins, sans-serif",
    datatableColumnFontSize: 12,
    datatableColumnFontWeight: 600,
    datatableRowFontFamily: "Poppins, sans-serif",
    datatableRowFontSize: 12,
    datatableRowFontWeight: "normal",
    datatableTotalFontFamily: "Poppins, sans-serif",
    datatableTotalFontSize: 12,
    datatableTotalFontWeight: 600,
    datatableTotalFontColor: "",
    datatableTotalBackgroundColor: "",
    datatableSubTotalFontFamily: "Poppins, sans-serif",
    datatableSubTotalFontSize: 12,
    datatableSubTotalFontWeight: 600,
    datatableSubTotalFontColor: "",
    datatableSubTotalBackgroundColor: "",
    datatableGridLines: "Horizontal",
    datatableShowTotal: true,
    datatableShowSubTotal: true,
    datatableSubTotalPosition: tableSubTotalPositionsEnums.Below,
    datatableAutoRefresh: 0,
    datatableAutoRefreshCustom: null,
    datatableSectionIndentCell: false,
    datatableSectionFontFamily: "Poppins, sans-serif",
    datatableSectionFontSize: 12,
    datatableSectionFontWeight: 600,
    datatableSectionBackgroundColor: "",
    datatableSectionFontColor: "",
    CHART_DATA_TABLE_DYNAMIC_TITLE_VALUE: "",
    CHART_DATA_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
    CHART_DATA_TABLE_DYNAMIC_TITLE_SHOW_VALUE: false,
    CHART_DATA_TABLE_GROUP_MULTIPLE_VALUES: true,
    CHART_DATA_TABLE_BACKGROUND_COLOR: "",
    CHART_DATA_TABLE_FONT_COLOR: "",
    CHART_DATA_TABLE_COLUMN_BACKGROUND_COLOR: "",
    CHART_DATA_TABLE_COLUMN_FONT_COLOR: "",
    CHART_DATA_TABLE_COLUMN_WIDTH: "",
    CHART_DATA_TABLE_MINIMUM_COLUMN_WIDTH: 150,
    CHART_DATA_TABLE_ALLOW_DRILL_THROUGH: true,
    CHART_DATA_TABLE_DRILL_THROUGH_DASHBOARD_LIST: [],
    CHART_DATA_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK: false,
    CHART_DATA_TABLE_PDF_EXPORT_HEADER_IMAGE: null,
    CHART_DATA_TABLE_PDF_EXPORT_COLUMN_REPEAT: true,
    CHART_DATA_TABLE_PDF_EXPORT_SHOW_HEADER_IMAGE: true,
    CHART_DATA_TABLE_PDF_EXPORT_SHOW_HEADER: true,
    CHART_DATA_TABLE_PDF_EXPORT_HEADER: "",
    CHART_DATA_TABLE_PDF_EXPORT_PAGE_SIZE: "A4",
    CHART_DATA_TABLE_PDF_EXPORT_ORIENTATION_TYPE: "Portrait",
    CHART_DATA_TABLE_PDF_EXPORT_CALCULATED_PAGE_SIZE: true,
    CHART_DATA_TABLE_PDF_EXPORT_SHOW_PAGE_NUMBER: false,
    CHART_DATA_TABLE_PDF_EXPORT_PAGE_NUMBER_POSITION: "center",
    CHART_DATA_TABLE_PDF_EXPORT_HEADER_FONT_SIZE: 20,
    CHART_DATA_TABLE_PDF_EXPORT_SHOW_GRID_BORDER: true
}


export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
    return typeof value != "undefined" ? value : computedValue
}