import { heatmapDefaultColorPalette } from "../commons/chartOptions"
import { getItemPercent, legendPositionEnums } from "../commons/dashboardProperties"

export const HEATMAP_DEFAULT_CONFIGURATIONS = {
    HEATMAP_SHOW_VALUE_LABEL: false,
    HEATMAP_SHOW_ONLY: getItemPercent(),
    HEATMAP_SHOW_INSIGHT: true,
    HEATMAP_SHOW_SQL: false,
    HEATMAP_VALUE_LABEL_FONT_FAMILY: "Poppins, sans-serif",
    HEATMAP_VALUE_LABEL_FONT_WEIGHT: 500,
    HEATMAP_VALUE_LABEL_FONT_SIZE: 12,
    HEATMAP_VALUE_LABEL_FONT_COLOR: "#000",
    HEATMAP_SHOW_LEGEND: false,
    HEATMAP_SHOW_Y_AXIS_NAME: true,
    HEATMAP_Y_AXIS_NAME_FONT_FAMILY: "Poppins, sans-serif",
    HEATMAP_Y_AXIS_NAME_FONT_WEIGHT: 500,
    HEATMAP_Y_AXIS_NAME_FONT_SIZE: 12,
    HEATMAP_Y_AXIS_NAME_FONT_COLOR: "#000",
    HEATMAP_SHOW_X_AXIS_LINE: true,
    HEATMAP_SHOW_X_AXIS_NAME: true,
    HEATMAP_X_AXIS_NAME_FONT_FAMILY: "Poppins, sans-serif",
    HEATMAP_X_AXIS_NAME_FONT_WEIGHT: 500,
    HEATMAP_X_AXIS_NAME_HORIZONTAL_ALIGN: "center",
    HEATMAP_X_AXIS_NAME_VERTICAL_ALIGN: "top",
    HEATMAP_X_AXIS_NAME_FONT_SIZE: 12,
    HEATMAP_X_AXIS_NAME_FONT_COLOR: "#000",
    HEATMAP_SHOW_Y_AXIS_LABEL: true,
    HEATMAP_Y_AXIS_LABEL_FONT_FAMILY: "Poppins, sans-serif",
    HEATMAP_Y_AXIS_LABEL_FONT_WEIGHT: 500,
    HEATMAP_Y_AXIS_LABEL_HORIZONTAL_ALIGN: "left",
    HEATMAP_Y_AXIS_LABEL_VERTICAL_ALIGN: "middle",
    HEATMAP_Y_AXIS_LABEL_FONT_SIZE: 12,
    HEATMAP_Y_AXIS_LABEL_FONT_COLOR: "#000",
    HEATMAP_SHOW_X_AXIS_LABEL: true,
    HEATMAP_X_AXIS_LABEL_WRAP: false,
    HEATMAP_X_AXIS_LABEL_FONT_FAMILY: "Poppins, sans-serif",
    HEATMAP_X_AXIS_LABEL_FONT_WEIGHT: 500,
    HEATMAP_X_AXIS_LABEL_HORIZONTAL_ALIGN: "center",
    HEATMAP_X_AXIS_LABEL_VERTICAL_ALIGN: "top",
    HEATMAP_X_AXIS_LABEL_FONT_SIZE: 12,
    HEATMAP_X_AXIS_LABEL_FONT_COLOR: "#000",
    HEATMAP_X_AXIS_LABEL_ROTATE: "0",
    HEATMAP_X_AXIS_LABEL_MAX_CHARACTER: 10,
    HEATMAP_Y_AXIS_POSITION: "left",
    HEATMAP_Y_AXIS_SHOW_LINE: false,
    HEATMAP_Y_AXIS_LINE_STYLE_COLOR: "#000",
    HEATMAP_Y_AXIS_NAME_LOCATION: "middle",
    HEATMAP_SHOW_VISUALMAP: false,
    HEATMAP_VISUALMAP_TYPE: "continuous",
    HEATMAP_VISUALMAP_MAX_CHARACTER: 20,
    HEATMAP_VISUALMAP_POSITION: legendPositionEnums.TOP_CENTER,
    HEATMAP_VISUALMAP_VERTICAL_ALIGN: "top",
    HEATMAP_VISUALMAP_FONT_SIZE: 12,
    HEATMAP_VISUALMAP_FONT_FAMILY: "Poppins, sans-serif",
    HEATMAP_VISUALMAP_FONT_WEIGHT: 500,
    HEATMAP_VISUALMAP_FONT_COLOR: "#000",
    HEATMAP_VISUALMAP_WIDTH: 18,
    HEATMAP_SELECTED_COLOR_PALETTE: heatmapDefaultColorPalette,
    HEATMAP_AUTO_REFRESH: 0,
    HEATMAP_AUTO_REFRESH_CUSTOM: null,
    HEATMAP_DYNAMIC_TITLE_VALUE: "",
    HEATMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
    HEATMAP_DYNAMIC_TITLE_SHOW_VALUE: false,
    HEATMAP_GROUP_MULTIPLE_VALUES: true,
    HEATMAP_X_AXIS_SHOW_DATA_ZOOM: false,
    HEATMAP_X_AXIS_DATA_ZOOM_START: 0,
    HEATMAP_X_AXIS_DATA_ZOOM_END: 100,
    HEATMAP_X_AXIS_LOCK_DATA_ZOOM: false,
    HEATMAP_X_AXIS_DATA_ZOOM_PALETTE: 'rgba(47,69,84,0)',
    HEATMAP_DATA_ZOOM_FILLER_COLOR: 'rgba(62, 122, 201, 0.25)',
    HEATMAP_Y_AXIS_SHOW_DATA_ZOOM: false,
    HEATMAP_Y_AXIS_DATA_ZOOM_START: 0,
    HEATMAP_Y_AXIS_DATA_ZOOM_END: 100,
    HEATMAP_Y_AXIS_DATA_ZOOM_PALETTE: 'rgba(47,69,84,0)',
    HEATMAP_Y_AXIS_LOCK_DATA_ZOOM: false,
    HEATMAP_ALLOW_DRILL_THROUGH: true,
    HEATMAP_DRILL_THROUGH_DASHBOARD_LIST: [],
    HEATMAP_DRILL_THROUGH_ON_DOUBLE_CLICK: false
}