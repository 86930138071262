<template>
  <div class="vis-tools-box vis-left-border vis-page vis-bg-color-white">
    <div class="propertiesTopBar vis-display-flex">
      <span class="vis-font-bold">{{ $t("generalPages.properties") }}</span
      ><i
        aria-hidden="true"
        class="vis-cursor-pointer vis-ml--auto e-icons e-large"
        :class="customIcon.Close"
        @click="$emit('close')"
      ></i>
    </div>
    <el-collapse
      :value="activeCollapses"
      @change="(e) => (activeCollapses = e)"
      class="vis-scroll-thin vis-authorization-properties"
    >
      <el-collapse-item
        v-loading="collapse.isLoading"
        class="vis-collapse-item"
        v-for="collapse in authorizationPropertiesCollapses"
        :key="collapse.key"
        :title="$t(`${collapse.title}`)"
        :name="collapse.key"
        :disabled="collapse.disabled"
      >
        <div
          v-if="collapse.key === authorizationPropertiesCollapseTypes.General"
        >
          <div class="vis-mb--1">
            <label>{{ $t("generalPages.labelName") }}</label>
            <el-input v-model="generalForm.name"></el-input>
          </div>
          <div class="vis-mb--1">
            <label>{{ $t("generalPages.labelDescription") }}</label>
            <el-input
              type="textarea"
              :rows="5"
              v-model="generalForm.description"
            >
            </el-input>
          </div>
          <div
            v-if="isGeneralFormChanged"
            class="vis-mb--1 update-button-container"
          >
            <el-button
              @click="updateGeneralProperties"
              size="small"
              class="update-button"
              >{{ $t("generalPages.update") }}</el-button
            >
          </div>

          <div
            v-for="row in generalDescriptionTable"
            :key="row.title"
            class="vis-mb--1"
          >
            <div class="vis-display-flex" v-if="row">
              <span class="vis-font-medium">{{ $t(`${row.title}`) }}</span>
              <span class="vis-ml--auto">{{ row.value }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="
            collapse.key ===
            authorizationPropertiesCollapseTypes.AccessPermissions
          "
        >
          <div class="vis-display-flex vis-mb--0625">
            <label class="">{{
              $t("generalPages.Use Parent")
            }}</label>
            <el-switch
              class="vis-ml--auto"
              :value="useParentAuthorization"
              @change="(val) => handleChangeUseParentAuthorization(val)"
            />
          </div>
          <div class="vis-mb--1">
            <label>{{ $t("generalPages.searchUsersOrGroups") }}</label
            ><el-autocomplete
              class="vis-mb--1 vis-width-100"
              :debounce="1000"
              v-model="state"
              :fetch-suggestions="querySearchAsync"
              :placeholder="$t('generalPages.searchInput')"
              @select="handleSelect"
              :disabled="useParentAuthorization"
            >
              <i
                class="el-input__icon addAction"
                :class="[customIcon.Plus,{'disabled-icons': useParentAuthorization}]"
                slot="suffix"
                @click="handleCreateAuthorization"
                aria-hidden="true"
              >
              </i>
              <template slot-scope="{ item }">
                <div class="value">
                  <i
                    class="vis-mr--1"
                    :class="
                      item.type === userTypes.USER
                        ? 'el-icon-user'
                        : 'el-icon-office-building'
                    "
                    aria-hidden="true"
                  >
                  </i
                  ><span class="link">{{ item.value }}</span>
                </div>
              </template>
            </el-autocomplete>
            <vue-good-table
              v-if="!useParentAuthorization"
              max-height="400px"
              :columns="accessPermissionsColumns"
              :rows="accessPermissionsSortedByOwner"
              :fixed-header="true"
              :sort-options="{
                enabled: false,
              }"
            >
              <template slot="table-column" slot-scope="props">
                <span>
                  {{ $t(`${props.column.label}`) }}
                </span>
              </template>
              <template slot="table-row" slot-scope="props">
                <div
                  v-if="
                    props.column.field === accessPermissionsColumnsFields.Name
                  "
                >
                  <span
                    class="vis-float--left vis-field-item-span"
                    :title="props.row.username || props.row.name"
                    >{{ props.row.username || props.row.name }}</span
                  >
                </div>
                <div
                  v-else-if="
                    props.column.field ===
                    accessPermissionsColumnsFields.Authority
                  "
                >
                  <span
                    v-if="permissionsByAuthorityTypeCheck(props.row)"
                    class="vis-float--left"
                    >{{ permissionsByAuthorityType(props.row) }}</span
                  >
                  <el-select
                    v-else
                    :value="permissionsByAuthorityType(props.row)"
                    placeholder="Permission"
                    size="mini"
                    @change="(val) => controlAuthorityChanged(val, props.row)"
                  >
                    <el-option
                      v-for="item in optionsAuthority(props.row)"
                      :key="item.key"
                      :label="item.label"
                      :value="item.key"
                      :disabled="useParentAuthorization"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  v-else-if="
                    props.column.field ===
                      accessPermissionsColumnsFields.Actions &&
                    !hasPermissionAnyOwnerAuthority(props.row)
                  "
                >
                  <div>
                    <span
                      ><i
                        @click="callRemoveAuthorizationById(props.row.id)"
                        :class="customIcon.TrashCircle"
                        class="vis-cursor-pointer"
                        aria-hidden="true"
                    /></span>
                  </div>
                </div>
                <span v-else class="vis-limit-long-words">
                  {{ props.formattedRow[props.column.field] }}
                </span></template
              >
            </vue-good-table>
          </div>
        </div>
        <div
          v-if="
            collapse.key === authorizationPropertiesCollapseTypes.Schedule &&
            !isComponentFolder
          "
        >
          <SchedulePopup
            v-if="popupScheduleVisible"
            :months="months"
            :days="days"
            :monthlyCron="monthlyCron"
            :dailyCron="dailyCron"
            :taskCron="taskCron.filter((x) => x.key !== schedulePeriodOptions.Hourly)"
            :timeRanges="timeRanges"
            :selectedProject="selectedProject"
            :currentUserUsername="currentUserUsername"
            :scheduleEditForm="scheduleEditForm"
            :isScheduleEditMode="isScheduleEditMode"
            @closePopupSchedule="closePopupSchedule"
            @handleSchedule="handleSchedule"
          />
          <ScheduleCard
            :schedules="schedules"
            :dialogVisible="dialogVisible"
            @handleEditSchedule="handleEditSchedule"
            @deleteScheduleData="deleteScheduleData"
            class="vis-schedule-card-comp"
          />
          <div
            class="vis-flex--Xright addAction vis-mb--1"
            @click="showSchedulePopup"
          >
            <i :class="customIcon.Plus" aria-hidden="true"></i>
            <span class="vis-font-bold"
              >{{ $t("generalPages.addSchedule") }}
            </span>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <ConfirmDialog
      :title="$t('dialog.deleteParentAuthorizationTitle')"
      :message="$t('dialog.deleteParentAuthorizationMessage')"
      :dialogVisible="deleteParentAuthorizationDialogVisible"
      @confirm="handleParentAuthorizationConfirmDialog"
      @cancel="deleteParentAuthorizationDialogVisible = false"
    />
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { formatISOToDateTime } from "../../util/moment";
import {
  ACTION,
  GETTER,
} from "../../store/modules/Visualize/Authorization/types";
import {
  ACTION as ACTION_DASHBOARD,
  GETTER as GETTER_DASHBOARD,
} from "../../store/modules/Visualize/Dashboard/types";
import { ACTION as ACTION_DATAMODEL } from "../../store/modules/Visualize/DataModel/types";
import { ACTION as ACTION_FOLDER } from "../../store/modules/Visualize/Folder/types";
import {
  ACTION as ACTION_SCHEDULE,
  GETTER as GETTER_SCHEDULE,
} from "../../store/modules/Visualize/Schedule/types";
import {
  ACTION as ACTION_SCHEDULE_TASK_TYPES,
  GETTER as GETTER_SCHEDULE_TASK_TYPES,
} from "../../store/modules/Visualize/TaskTypes/types";
import { ACTION as ACTION_DATAFLOW } from "../../store/modules/Ingest/DataFlow/types";
import { mapActions, mapGetters } from "vuex";
import {
  AuthorizationDashboardEnum,
  AuthorizationDatamodelEnum,
  AuthorizationGeneralEnum,
  CardType,
} from "../../util/homePageMappers";
import componentAvailabilityCheck from "../../mixins/componentAvailabilityCheck";
import { COMPONENT_PRIVILEGES, ROLES } from "../../util/authorization";
import { CustomIcon } from "../../assets/js/custom-icons";
import SchedulePopup from "../data/scheduleProperties/SchedulePopup";
import ScheduleCard from "../data/scheduleProperties/ScheduleCard";
import { schedulePeriodOptions } from "../data/scheduleProperties/schedule";
import ConfirmDialog from "../helper/ConfirmDialog.vue";

export default {
  props: {
    authorizationPropertiesItem: {
      type: Object,
      required: true,
    },
    showAuthorizationPropertiesGeneral: {
      type: Boolean,
      default: false,
    },
    parentAuthorizationConfirmed: {
      type: Boolean,
      default: false,
    },
    showAuthorizationPropertiesAccessPermissions: {
      type: Boolean,
      default: false,
    },
    isDashboardProperties: {
      type: Boolean,
      default: false,
    },
    currentUserUsername: {
      type: String,
    },
    isAuthorizationLoading : {
      type: Boolean,
      default: false,
    }
  },
  components: { VueGoodTable, SchedulePopup, ScheduleCard, ConfirmDialog },
  watch: {
    authorizationPropertiesItem: {
      handler(val) {
        if (val) {
          this.setGeneralForm();
        }
      },
      deep: true,
    },
  },
  mixins: [componentAvailabilityCheck],
  data() {
    return {
      authorizationPropertiesCollapseTypes: {
        General: "General",
        AccessPermissions: "AccessPermissions",
        Schedule: "Schedule",
      },
      state: "",
      timeout: null,
      activeCollapses: [],
      accessPermissionsColumnsFields: {
        Name: "name",
        Authority: "authority",
        Actions: "actions",
      },
      userTypes: {
        USER: "USER",
        GROUP: "GROUP",
      },
      usersAndGroups: [],
      selectedItem: null,
      generalForm: {
        name: "",
        description: "",
      },
      authorizationDashboardEnum: AuthorizationDashboardEnum,
      authorizationDatamodelEnum: AuthorizationDatamodelEnum,
      authorizationFolderEnum: AuthorizationGeneralEnum,
      authorizationDataFlowEnum: AuthorizationGeneralEnum,
      customIcon: CustomIcon,
      popupScheduleVisible: false,
      scheduleEditForm: {},
      isScheduleEditMode: false,
      dialogVisible: false,
      schedulePeriodOptions : schedulePeriodOptions,
      deleteParentAuthorizationDialogVisible: false,
    };
  },
  async mounted() {
    await this.init();
    this.setGeneralForm();
  },
  computed: {
    ...mapGetters({
      dashboardAuthorizationById: GETTER.GET_DASHBOARD_AUTHORIZATION_BY_ID,
      datamodelAuthorizationById: GETTER.GET_DATAMODEL_AUTHORIZATION_BY_ID,
      dataFlowAuthorizationById: GETTER.GET_DATAFLOW_AUTHORIZATION_BY_ID,
      folderAuthorizationById: GETTER.GET_FOLDER_AUTHORIZATION_BY_ID,
      schedules: GETTER_SCHEDULE.GET_SCHEDULES,
      months: GETTER_SCHEDULE_TASK_TYPES.GET_MONTHS,
      days: GETTER_SCHEDULE_TASK_TYPES.GET_DAYS,
      monthlyCron: GETTER_SCHEDULE_TASK_TYPES.GET_MONTHLY_CRON,
      dailyCron: GETTER_SCHEDULE_TASK_TYPES.GET_DAILY_CRON,
      taskCron: GETTER_SCHEDULE_TASK_TYPES.GET_CRON,
      timeRanges: GETTER_SCHEDULE_TASK_TYPES.GET_TIME_RANGES,
      selectedProject: GETTER_DASHBOARD.GET_DASHBOARD,
      currentUserRoles: "Security/Get/Keycloak/Roles",
    }),
    isGeneralFormChanged() {
      if (
        this.generalForm.name !== this.name ||
        this.generalForm.description !== this.description
      )
        return true;
      return false;
    },
    accessPermissionsRows() {
      if (this.isComponentDashboard) {
        return this.dashboardAuthorizationById;
      } else if (this.isComponentDatamodel) {
        return this.datamodelAuthorizationById;
      } else if (this.isComponentFolder) {
        return this.folderAuthorizationById;
      } else if (this.isComponentDataFlow) {
        return this.dataFlowAuthorizationById;
      } else return [];
    },
    accessPermissionsSortedByOwner() {
      return [
        ...this.accessPermissionsRows.filter((item) => {
          return this.hasPermissionAnyOwnerAuthority(item);
        }),
        ...this.accessPermissionsRows.filter((item) => {
          return !this.hasPermissionAnyOwnerAuthority(item);
        }),
      ];
    },
    accessPermissionsColumns() {
      return [
        {
          label: "generalPages.user",
          field: this.accessPermissionsColumnsFields.Name,
          width: "60px",
        },
        {
          label: "generalPages.permission",
          field: this.accessPermissionsColumnsFields.Authority,
        },
        {
          label: "generalPages.labelActions",
          field: this.accessPermissionsColumnsFields.Actions,
          width: "60px",
        },
      ];
    },
    authorizationPropertiesCollapses() {
      return [
        {
          title: "generalPages.general",
          key: this.authorizationPropertiesCollapseTypes.General,
        },
        {
          title: "generalPages.accessPermissions",
          key: this.authorizationPropertiesCollapseTypes.AccessPermissions,
          disabled: this.isAuthorizationLoading,
          isLoading: this.isAuthorizationLoading,
        },
        this.isScheduleActive &&
        !this.isComponentFolder &&
        !this.isComponentDataFlow
          ? {
              title: "generalPages.schedule",
              key: this.authorizationPropertiesCollapseTypes.Schedule,
              disabled: !(
                this.authorizationPropertiesItem.item.type ===
                CardType.DASHBOARD
              ),
            }
          : null,
      ].filter((x) => x);
    },
    name() {
      return this.authorizationPropertiesItem?.item?.name ?? "";
    },
    description() {
      return this.authorizationPropertiesItem?.item?.description ?? "";
    },
    isComponentDashboard() {
      return this.authorizationPropertiesItem.item.type === CardType.DASHBOARD;
    },
    isComponentDatamodel() {
      return this.authorizationPropertiesItem.item.type === CardType.DATAMODEL;
    },
    isComponentFolder() {
      return this.authorizationPropertiesItem.item.type === CardType.FOLDER;
    },
    isComponentDataFlow() {
      return this.authorizationPropertiesItem.item.type === CardType.DATAFLOW;
    },
    isObjectLock() {
      return (
        this.isComponentDatamodel &&
        this.authorizationPropertiesItem?.item?.lockCreatedDate &&
        this.authorizationPropertiesItem?.item?.lockCreatedBy
      );
    },
    useParentAuthorization() {
      return this.authorizationPropertiesItem?.item?.useParentAuthorization ?? false
    },
    generalDescriptionTable() {
      return [
        {
          title: "generalPages.labelOwner",
          value: this.authorizationPropertiesItem?.item?.ownerName ?? "-",
        },
        {
          title: "generalPages.labelCreatedBy",
          value: this.authorizationPropertiesItem?.item?.createdBy ?? "-",
        },
        {
          title: "generalPages.labelCreatedAt",
          value:
            formatISOToDateTime(
              this.authorizationPropertiesItem?.item?.createdDate
            ) ?? "-",
        },
        {
          title: "generalPages.labelLastUpdatedBy",
          value: this.authorizationPropertiesItem?.item?.updatedBy ?? "-",
        },
        {
          title: "generalPages.labelLastUpdatedAt",
          value:
            formatISOToDateTime(
              this.authorizationPropertiesItem?.item?.updatedDate
            ) ?? "-",
        },
        this.isObjectLock ?
            {
              title: "generalPages.Locked By",
              value: this.authorizationPropertiesItem?.item?.lockCreatedBy ?? "-",
            }:"",
            this.isObjectLock ? {
              title: "generalPages.Locked Date",
              value:
                formatISOToDateTime(
                  this.authorizationPropertiesItem?.item?.lockCreatedDate
                ) ?? "-",
            }: "",
      ];
    },
    isDashboardPropertiesScheduleAvailable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_DASHBOARD_PROPERTIES_SCHEDULE_SECTION
      );
    },
    forScheduleUserHasScheduleManageRole() {
      return this.currentUserRoles?.some((r) =>
        [ROLES.ADMIN.key, ROLES.SCHEDULE_MANAGER.key].includes(r)
      );
    },
    isScheduleActive() {
      return this.isDashboardPropertiesScheduleAvailable && this.forScheduleUserHasScheduleManageRole;
    },
  },
  methods: {
    ...mapActions({
      fetchUsers: ACTION.FETCH_USERS,
      fetchGroups: ACTION.FETCH_GROUPS,
      fetchDashboardAuthorizationById:
        ACTION.FETCH_DASHBOARD_AUTHORIZATION_BY_ID,
      fetchDatamodelAuthorizationById:
        ACTION.FETCH_DATAMODEL_AUTHORIZATION_BY_ID,
      fetchDataFlowAuthorizationById: ACTION.FETCH_DATAFLOW_AUTHORIZATION_BY_ID,
      fetchFolderAuthorizationById: ACTION.FETCH_FOLDER_AUTHORIZATION_BY_ID,
      fetchSchedules: ACTION_SCHEDULE.FETCH_SCHEDULES,
      fetchMonths: ACTION_SCHEDULE_TASK_TYPES.FETCH_MONTHS,
      fetchDays: ACTION_SCHEDULE_TASK_TYPES.FETCH_DAYS,
      fetchMonthlyCron: ACTION_SCHEDULE_TASK_TYPES.FETCH_MONTHLY_CRON,
      fetchDailyCron: ACTION_SCHEDULE_TASK_TYPES.FETCH_DAILY_CRON,
      fetchTaskCron: ACTION_SCHEDULE_TASK_TYPES.FETCH_CRON,
      fetchTimeRanges: ACTION_SCHEDULE_TASK_TYPES.FETCH_TIME_RANGES,
      createDashboardAuthorization: ACTION.CREATE_DASHBOARD_AUTHORIZATION,
      createDatamodelAuthorization: ACTION.CREATE_DATAMODEL_AUTHORIZATION,
      createDataFlowAuthorization: ACTION.CREATE_DATAFLOW_AUTHORIZATION,
      createFolderAuthorization: ACTION.CREATE_FOLDER_AUTHORIZATION,
      createSchedule: ACTION_SCHEDULE.CREATE_SCHEDULE,
      deleteDashboardAuthorizationById:
        ACTION.DELETE_DASHBOARD_AUTHORIZATION_BY_ID,
      deleteDatamodelAuthorizationById:
        ACTION.DELETE_DATAMODEL_AUTHORIZATION_BY_ID,
      deleteFolderAuthorizationById: ACTION.DELETE_FOLDER_AUTHORIZATION_BY_ID,
      deleteDataFlowAuthorizationById:
        ACTION.DELETE_DATAFLOW_AUTHORIZATION_BY_ID,
      deleteSchedule: ACTION_SCHEDULE.DELETE_SCHEDULE,
      updateDashboardAuthorizationById:
        ACTION.UPDATE_DASHBOARD_AUTHORIZATION_BY_ID,
      updateDatamodelAuthorizationById:
        ACTION.UPDATE_DATAMODEL_AUTHORIZATION_BY_ID,
      updateDataFlowAuthorizationById:
        ACTION.UPDATE_DATAFLOW_AUTHORIZATION_BY_ID,
      updateFolderAuthorizationById: ACTION.UPDATE_FOLDER_AUTHORIZATION_BY_ID,
      createFolderOwnerAuthorizationById: ACTION.CREATE_FOLDER_OWNER_AUTHORIZATION_BY_ID,
      updateFolderOwnerAuthorizationById: ACTION.UPDATE_FOLDER_OWNER_AUTHORIZATION_BY_ID,
      updateDashboardParentAuthorizationById:
        ACTION.UPDATE_DASHBOARD_PARENT_AUTHORIZATION_BY_ID,
      updateDatamodelParentAuthorizationById:
        ACTION.UPDATE_DATAMODEL_PARENT_AUTHORIZATION_BY_ID,
      updateDataFlowParentAuthorizationById:
        ACTION.UPDATE_DATAFLOW_PARENT_AUTHORIZATION_BY_ID,
      updateFolderParentAuthorizationById:
        ACTION.UPDATE_FOLDER_PARENT_AUTHORIZATION_BY_ID,
      updateDashboardName: ACTION_DASHBOARD.UPDATE_DASHBOARD_NAME,
      updateDatamodelName: ACTION_DATAMODEL.UPDATE_DATAMODEL_NAME,
      updateFolderName: ACTION_FOLDER.UPDATE_FOLDER_NAME,
      updateSchedule: ACTION_SCHEDULE.UPDATE_SCHEDULE,
      fetchDashboardsById:
        ACTION_DASHBOARD.FETCH_DASHBOARDS_BY_ID,
      updateDataFlowNameAndDescription:
        ACTION_DATAFLOW.UPDATE_DATAFLOW_NAME_AND_DESCRIPTION,
    }),
    async updateGeneralProperties() {
      const bodyParam = {
        description: this.generalForm.description,
        id: this.authorizationPropertiesItem?.item?.id,
        name: this.generalForm.name,
      };

      if (this.isComponentDashboard) {
        await this.updateDashboardName({ bodyParam });
      } else if (this.isComponentDatamodel) {
        await this.updateDatamodelName({ bodyParam });
      } else if (this.isComponentDataFlow) {
        await this.updateDataFlowNameAndDescription({
          dataFlowId: bodyParam.id,
          bodyParam: {
            name: bodyParam.name,
            description: bodyParam.description,
          },
        });
      } else if (this.isComponentFolder) {
        const folderId = this.authorizationPropertiesItem?.item?.id;
        await this.updateFolderName({
          queryParam: folderId,
          bodyParam,
        });
      }
      this.$emit('resetHomepageSearch'); 
      this.$emit('searchProjectList'); 
    },
    setGeneralForm() {
      this.generalForm = {
        name: this.name,
        description: this.description,
      };
    },
    async showSchedulePopup() {
      this.popupScheduleVisible = true;
    },
    closePopupSchedule() {
      this.isScheduleEditMode = false;
      this.popupScheduleVisible = !this.popupScheduleVisible;
    },
    async handleSchedule(bodyParam) {
      if (this.isScheduleEditMode) {
        await this.updateSchedule({ bodyParam });
        this.isScheduleEditMode = false;
      } else {
        await this.createSchedule({ bodyParam });
      }

      await this.fetchSchedules({
          dashboardId: this.authorizationPropertiesItem.item.id,
      });
    },
    handleEditSchedule(payload) {
      this.scheduleEditForm = payload;
      this.scheduleEditForm.taskDefinitionDto.cronDto.cronType = this.scheduleEditForm.taskDefinitionDto.cronDto.cronType === 
      schedulePeriodOptions.Hourly ? schedulePeriodOptions.IntraDay : this.scheduleEditForm.taskDefinitionDto.cronDto.cronType
      this.isScheduleEditMode = true;
      this.showSchedulePopup();
    },
    deleteScheduleData(taskId) {
      this.deleteSchedule({
        dashboardId: this.authorizationPropertiesItem?.item?.id,
        taskId: taskId,
      });
    },
    hasPermissionAnyOwnerAuthority(item) {
      return (
        item.authority === this.authorizationDashboardEnum?.OWNER ||
        item.authority === this.authorizationDatamodelEnum?.OWNER ||
        item.folderAuthorityType === this.authorizationFolderEnum?.OWNER ||
        item.dataFlowAuthorityType === this.authorizationDataFlowEnum?.OWNER
      );
    },
    optionsAuthority(data) {
      const permissions = data?.grantableAuthorities;
      if (this.isComponentDashboard) {
        return permissions?.map((key) => ({
          key,
          label: key,
        }));
      } else if (this.isComponentDatamodel)
        return permissions?.map((key) => ({
          key,
          label: key,
        }));
      else if (this.isComponentFolder) {
        return Object.values(AuthorizationGeneralEnum)?.map((value) => ({
          key: value,
          label: value,
        }));
      } else if (this.isComponentDataFlow) {
        return Object.values(AuthorizationGeneralEnum)?.map((value) => ({
          key: value,
          label: value,
        }));
      } else return [];
    },
    async onAuthorityChanged(val, row) {
      if (this.isComponentDashboard) {
        await this.updateDashboardAuthorizationById({
          updateId: row.id,
          bodyParam: {
            authority: val,
          },
          refreshId: this.authorizationPropertiesItem.item.id,
        });
      } else if (this.isComponentDatamodel) {
        await this.updateDatamodelAuthorizationById({
          updateId: row.id,
          bodyParam: {
            authority: val,
          },
          refreshId: this.authorizationPropertiesItem.item.id,
        });
      } else if (this.isComponentFolder) {
        if(val === this.authorizationFolderEnum?.OWNER) {
          await this.updateFolderOwnerAuthorizationById({
            updateId: row.id,
            refreshId: this.authorizationPropertiesItem.item.id,
          })
        } else {
          await this.updateFolderAuthorizationById({
            updateId: row.id,
            bodyParam: {
              folderAuthorityTypes: val,
            },
            refreshId: this.authorizationPropertiesItem.item.id,
          });
        }
      } else if (this.isComponentDataFlow) {
        await this.updateDataFlowAuthorizationById({
          updateId: row.id,
          bodyParam: {
            dataFlowAuthorityType: val,
            dataFlowId: row.dataFlowId,
            keycloakAccessType: row.keycloakAccessType,
            name: row.name,
          },
          refreshId: this.authorizationPropertiesItem.item.id,
        });
      }
    },
    controlAuthorityChanged(val, row) {
      if (
        val !== this.authorizationDashboardEnum?.OWNER &&
        val !== this.authorizationDatamodelEnum?.OWNER &&
        val !== this.authorizationFolderEnum?.OWNER &&
        val !== this.authorizationDataFlowEnum?.OWNER
      ) {
        this.onAuthorityChanged(val, row);

        return;
      }

      this.$confirm("Do you want to give 'OWNER' authority?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.onAuthorityChanged(val, row);
        })
        .catch(() => {});
    },
    async callRemoveAuthorizationById(id) {
      if (this.isComponentDashboard) {
        await this.deleteDashboardAuthorizationById({
          removeId: id,
          refreshId: this.authorizationPropertiesItem.item.id,
        });
      } else if (this.isComponentDatamodel) {
        await this.deleteDatamodelAuthorizationById({
          removeId: id,
          refreshId: this.authorizationPropertiesItem.item.id,
        });
      } else if (this.isComponentFolder) {
        await this.deleteFolderAuthorizationById({
          removeId: id,
          refreshId: this.authorizationPropertiesItem.item.id,
        });
      } else if (this.isComponentDataFlow) {
        await this.deleteDataFlowAuthorizationById({
          removeId: id,
          refreshId: this.authorizationPropertiesItem.item.id,
        });
      }
    },
    permissionsByAuthorityType(authorizationData) {
      if (this.isComponentDashboard || this.isComponentDatamodel) {
        return authorizationData.authority;
      } else if (this.isComponentFolder) {
        return authorizationData.folderAuthorityType;
      } else if (this.isComponentDataFlow) {
        return authorizationData.dataFlowAuthorityType;
      }
    },
    permissionsByAuthorityTypeCheck(authorizationData) {
      if (this.isComponentDashboard) {
        return (
          authorizationData.authority === this.authorizationDashboardEnum.OWNER
        );
      } else if (this.isComponentDatamodel) {
        return (
          authorizationData.authority === this.authorizationDatamodelEnum.OWNER
        );
      } else if (this.isComponentFolder) {
        return (
          authorizationData.folderAuthorityType ===
          this.authorizationFolderEnum.OWNER
        );
      } else if (this.isComponentDataFlow) {
        return (
          authorizationData.dataFlowAuthorityType ===
          this.authorizationDataFlowEnum.OWNER
        );
      }
    },
    async init() {
      if (this.showAuthorizationPropertiesGeneral) {
        this.activeCollapses.push(
          this.authorizationPropertiesCollapseTypes.General
        );
      }
      if (this.showAuthorizationPropertiesAccessPermissions) {
        this.activeCollapses.push(
          this.authorizationPropertiesCollapseTypes.AccessPermissions
        );
      }

      if (this.isComponentDashboard) {
        //DASHBOARD

        this.fetchDashboardAuthorizationById(
          this.authorizationPropertiesItem.item.id
        );
      } else if (this.isComponentDatamodel) {
        //DATAMODEL
        this.fetchDatamodelAuthorizationById(
          this.authorizationPropertiesItem.item.id
        );
      } else if (this.isComponentFolder) {
        //FOLDER
        this.fetchFolderAuthorizationById(
          this.authorizationPropertiesItem.item.id
        );
      } else if (this.isComponentDataFlow) {
        //DATAFLOW
        this.fetchDataFlowAuthorizationById(
          this.authorizationPropertiesItem.item.id
        );
      }

      if (this.isComponentDashboard && this.isScheduleActive) {
        //if dashboard and is schedule active
        this.activeCollapses.push(
          this.authorizationPropertiesCollapseTypes.Schedule
        );
        this.fetchSchedules({
          dashboardId: this.authorizationPropertiesItem.item.id,
        });
        await this.fetchDashboardsById({
          dashboardId: this.authorizationPropertiesItem?.item?.id,
        });
        await this.fetchMonths();
        await this.fetchDays();
        await this.fetchMonthlyCron();
        await this.fetchDailyCron();
        await this.fetchTaskCron();
        await this.fetchTimeRanges();
      }
    },
    async loadUsersAndGroups(queryString) {
      const users = await this.fetchUsers(queryString ?? "");
      const groups = await this.fetchGroups();
      const userMapped =
        users?.map((u) => {
          return {
            value: u.username,
            id: u.id,
            type: this.userTypes.USER,
          };
        }) ?? [];
      const groupsMapped =
        groups?.map((g) => {
          return {
            value: g.name,
            type: this.userTypes.GROUP,
          };
        }) ?? [];

      return [...userMapped, ...groupsMapped];
    },
    async querySearchAsync(queryString, cb) {
      clearTimeout(this.timeout);
      if (queryString != "") {
        this.usersAndGroups = await this.loadUsersAndGroups(queryString);
        var usersAndGroups = this.usersAndGroups;
        var results = queryString
          ? usersAndGroups.filter(this.createFilter(queryString))
          : usersAndGroups;
        this.timeout = setTimeout(() => {
          cb(results);
        }, 2000 * Math.random());
      } else cb([]);
    },
    createFilter(queryString) {
      return (usersAndGroups) => {
        return (
          usersAndGroups.value
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      this.selectedItem = item;
    },
    handleParentAuthorizationConfirmDialog() {
      this.updateParentAuthorizationByComponentType(false);
    },
    async handleChangeUseParentAuthorization(val) {
      if(val) {
        this.updateParentAuthorizationByComponentType(val);
      } else {
        this.deleteParentAuthorizationDialogVisible = true;
      }
    },
    async updateParentAuthorizationByComponentType(val) {
      if (this.isComponentDashboard) {
        await this.updateDashboardParentAuthorizationById({
          dashboardId: this.authorizationPropertiesItem.item.id,
          useParentAuthorization: val,
        });
      } else if (this.isComponentDatamodel) {
        await this.updateDatamodelParentAuthorizationById({
          datamodelId: this.authorizationPropertiesItem.item.id,
          useParentAuthorization: val,
        });
      } else if (this.isComponentFolder) {
        await this.updateFolderParentAuthorizationById({
          folderId: this.authorizationPropertiesItem.item.id,
          useParentAuthorization: val,
        });
      } else if (this.isComponentDataFlow) {
        await this.updateDataFlowParentAuthorizationById({
          dataFlowId: this.authorizationPropertiesItem.item.id,
          useParentAuthorization: val,
        });
      }
      this.$emit('resetHomepageSearch'); 
      this.$emit('searchProjectList');
      this.deleteParentAuthorizationDialogVisible = false;
    },
    handleCreateAuthorization() {
      if (this.isComponentDashboard) {
        this.createDashboardAuthorization({
          payload: {
            authority: this.authorizationDashboardEnum?.READ,
            dashboardId: this.authorizationPropertiesItem.item.id,
            datamodelId: this.authorizationPropertiesItem.item.datamodel_id,
            empty: true,
            name: this.selectedItem.value,
            type: this.selectedItem.type,
          },
          id: this.authorizationPropertiesItem.item.id,
        });
      } else if (this.isComponentDatamodel && !this.isComponentFolder) {
        this.createDatamodelAuthorization({
          payload: {
            authority: this.authorizationDashboardEnum?.READ,
            datamodelId: this.authorizationPropertiesItem.item.id,
            empty: true,
            name: this.selectedItem.value,
            type: this.selectedItem.type,
          },
          id: this.authorizationPropertiesItem.item.id,
        });
      } else if (this.isComponentFolder) {
        this.createFolderAuthorization({
          payload: {
            id: this.selectedItem.id,
            folderId: this.authorizationPropertiesItem.item.id,
            keycloakAccessType: this.selectedItem.type,
            folderAuthorityType: this.authorizationFolderEnum?.READ,
            username: this.selectedItem.value,
          },
          id: this.authorizationPropertiesItem.item.id,
        });
      } else if (this.isComponentDataFlow) {
        this.createDataFlowAuthorization({
          payload: {
            dataFlowAuthorityType: this.authorizationDataFlowEnum?.EDIT,
            dataFlowId: this.authorizationPropertiesItem.item.id,
            name: this.selectedItem.value,
            keycloakAccessType: this.selectedItem.type,
          },
          id: this.authorizationPropertiesItem.item.id,
        });
      }
    },
  },
};
</script>

<style scoped>
label {
  font-weight: 500;
}
::v-deep .vgt-table.bordered th {
  padding: 5px 0px 5px 10px;
}
::v-deep .vgt-table.bordered td {
  padding: 5px 0px 5px 10px;
  text-align: center;
  vertical-align: middle;
}
::v-deep .vgt-table.bordered td:last-child {
  padding-right: 2px;
}
::v-deep .vgt-table thead tr {
  background: #f4f7fd;
}
::v-deep .vgt-wrap {
  border-left: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
}
.propertiesTopBar {
  height: 40px;
  border-top: 1px solid var(--layout-border-color);
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}
.update-button {
  background-color: rgb(32, 111, 255) !important;
  color: white !important;
  float: right;
}
.update-button-container {
  width: 100% !important;
  display: inline-block;
}
.vis-authorization-properties {
  max-height: 90vh;
  overflow-y: auto;
}
.disabled-icons{
  color: gray;
}
</style>
