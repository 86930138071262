<template>
  <div class="vis-upload-images-popup">
    <slot name="imagesPopupContent"></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.vis-upload-images-popup {
  position: fixed;
  background-color: #fff;
  width: 60%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 15px #c5c1c1;
}
</style>
