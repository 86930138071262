<template>
  <div v-loading="isBeautifyLoading">
    <InputSearch
      v-if="Object.keys(selectedDataModel).length"
      customComponentClass="vis-dataset-search-input"
      @keyupSearchData="searchField = $event"
    />
    <el-collapse
      v-model="activeCollapseItem"
      :accordion="isOneDatasetExpandedActive"
    >
      <el-collapse-item
        v-for="dataSet in selectedDataModel.datasets"
        :key="dataSet.datasetId"
        :title="shortCollapseItemTitle(dataSet.name)"
        :name="dataSet.name"
        class="vis-collapse-item vis-dataset-tree"
        :class="{
          'vis-white-space-nowrap': checkDatasetNameLength(dataSet.name),
          'vis-display-none': checkDatasetEmpty(dataSet.datasetId),
        }"
      >
        <template slot="title">
          <span>{{ dataSet.name }}</span>
          <div class="vis-datasetfield-radiogroup">
            <ejs-contextmenu
              :id="`beautifyMenu-${dataSet.datasetId}`"
              :items="getAllFieldPropertyContextMenuItems()"
              :select="
                (arg) => handleContextMenuItemSelect(arg, dataSet.datasetId)
              "
              :beforeItemRender="beforeContextItemHighlight"
            />
          </div>
          <i
            @click.stop="openBeautifyMenu($event, dataSet.datasetId)"
            aria-hidden="true"
            class="vis-collapse-item-three-dot"
            :class="customIcon.VerticalThreeDot"
          >
          </i>
        </template>
        <!-- Tooltip START -->
        <Typography
          variant="h5"
          text="..."
          :showText="checkDatasetNameLength(dataSet.name)"
          :tooltipEnabled="checkDatasetNameLength(dataSet.name)"
          class="vis-collapse-item-more-text"
        >
          <template v-slot:tooltipContent>
            <span>{{ dataSet.name }}</span>
          </template>
        </Typography>
        <!-- Tooltip END -->
        <div class="vis-tools-content vis-mb--1">
          <draggable
            class="list-group"
            :list="getDatasetFieldsByDatasetId(dataSet.datasetId)"
            :group="{ name: 'datamodelFields', pull: 'clone', put: true }"
            :disabled="
              selectedFieldDatasetId != dataSet.datasetId &&
              selectedFieldDatasetId
            "
            :sort="enabledDraggableSort && !openPopupFieldId"
            @sort="onDatamodelFieldMoved(dataSet.datasetId)"
          >
            <ul
              class="list-group-item vis-flex--spacebeetwen-Ycenter"
              v-for="(field, index) in getDatasetFieldsByDatasetId(
                dataSet.datasetId
              )"
              :key="getIdOrFieldId(field)"
            >
              <div class="vis-w--full">
                <li
                  v-if="checkIsHidden(field)"
                  class="vis-flex--spacebeetwen-Ycenter"
                >
                  <div
                    class="vis-flex--Ycenter vis-dataModel-list-icons vis-cursor-pointer vis-field-item"
                    :class="{
                      'vis-disabled-fields':
                        selectedFieldDatasetId != dataSet.datasetId &&
                        drillPathPopupMenu,
                    }"
                    @click="
                      $emit('addFunctionToCalculatedColumnEditor', field.alias)
                    "
                  >
                    <i
                      :class="[
                        datasetIcon(field),
                        { 'vis-color-purple': isCalculatedColumn(field) },
                        { 'vis-color-warning': field.sourceFieldId },
                      ]"
                      aria-hidden="true"
                    />
                    <el-tooltip
                      class="item"
                      effect="primary"
                      :content="field.screenTip"
                      placement="right-start"
                      :disabled="!field.screenTip"
                    >
                      <el-tooltip
                        class="item"
                        placement="right-start"
                        popper-class="vis-bg-light-gray vis-br--1"
                        effect="light"
                        :open-delay="2000"
                      >
                        <div
                          slot="content"
                          class="vis-mx--05 vis-display-flex vis-flex-direction--column vis-tooltip-popup-content"
                          v-if="
                            field.filterType !== filterType.CUSTOM &&
                            (isPageDashboard || isPanelPage)
                          "
                        >
                          <span class="vis-color-neon-blue vis-text--xsmall">
                            {{ $t("generalPages.Dashboard") }}
                          </span>
                          <span
                            class="vis-color-black vis-text--xsmall vis-mt--05"
                            >{{ $t("generalPages.Data Item Alias") }}</span
                          >
                          <span class="vis-color-black vis-font-bold">
                            {{ `${dataSet.name}.${field.alias}` }}
                          </span>
                          <div class="field-border vis-mt--05"></div>
                          <span
                            class="vis-color-neon-blue vis-text--xsmall vis-mt--05"
                          >
                            {{ $t("generalPages.Datamodel") }}
                          </span>
                          <span
                            class="vis-color-black vis-text--xsmall vis-mt--05"
                            >{{ $t("generalPages.Data Item Alias") }}</span
                          >
                          <span class="vis-color-black vis-font-bold">
                            {{
                              `${
                                datamodelDatasetField(field)?.alias ||
                                datamodelDatasetAlias(field)
                              }`
                            }}
                          </span>
                          <span
                            class="vis-color-black vis-text--xsmall vis-mt--05"
                            >{{ $t("generalPages.Data Item Name") }}</span
                          >
                          <span class="vis-color-black vis-font-bold">
                            {{
                              `${dataSet.name}.${
                                datamodelDatasetField(field)?.name || field.name
                              }`
                            }}
                          </span>
                        </div>
                        <div
                          slot="content"
                          class="vis-mx--05 vis-display-flex vis-flex-direction--column vis-tooltip-popup-content"
                          v-if="
                            field.filterType !== filterType.CUSTOM &&
                            !isPageDashboard &&
                            !isPanelPage
                          "
                        >
                          <span
                            class="vis-color-black vis-text--xsmall vis-mt--05"
                            >{{ $t("generalPages.alias") }}</span
                          >
                          <span class="vis-color-black vis-font-bold">
                            {{ `${field.alias}` }}
                          </span>
                          <span
                            class="vis-color-black vis-text--xsmall vis-mt--05"
                            >{{ $t("generalPages.name") }}</span
                          >
                          <span class="vis-color-black vis-font-bold">
                            {{ `${dataSet.name}.${field.name}` }}
                          </span>
                        </div>
                        <span
                          class="vis-field-item-span"
                          :class="{
                            'vis-disable-opacity': field.isHidden && field.valid,
                            'vis-field-invalid': !field.valid,
                          }"
                          >{{ field.alias }}</span
                        >
                      </el-tooltip>
                    </el-tooltip>
                  </div>
                  <!-- RIGHT ICON BOX START-->
                  <div
                    class="vis-dataset-fields-right-icon-box vis-flex--Ycenter"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('Security Applied')"
                    >
                      <i
                        v-if="!isPageDashboard && !field.accessField"
                        aria-hidden="true"
                        :class="customIcon.AdminPanelSettings"
                      ></i>
                    </el-tooltip>
                    <i
                      v-if="!drillPathPopupMenu"
                      @click="
                        openContextMenu($event, { item: field, index: index })
                      "
                      class="vis-dataset-dots"
                      :class="[
                        customIcon.VerticalThreeDot,
                      ]"
                      aria-hidden="true"
                    />
                  </div>
                  <!-- RIGHT ICON BOX END-->
                </li>
              </div>
              <el-popover
                v-if="
                  activeContextMenuId ===
                  contextMenuIdPrefix + getIdOrFieldId(field)
                "
                placement="right"
                trigger="manual"
                popper-class="vis-dropdown-popper"
                :value="openPopupFieldId === getIdOrFieldId(field)"
              >
                <div slot="reference">
                  <ejs-contextmenu
                    :id="contextMenuIdPrefix + getIdOrFieldId(field)"
                    :items="contextMenuItems"
                    :select="handleContextMenuItemSelect"
                    :beforeItemRender="beforeContextItemHighlight"
                  />
                </div>
                <FieldPopup
                  v-if="
                    fieldPopupId === getIdOrFieldId(field) &&
                    selectedFieldDatasetId === field.datasetId
                  "
                  :selectedField="selectedContextMenuItemValue"
                  :selectedItem="selectedContextMenuItem"
                  :datamodelInfo="
                    getDatamodelInfo(selectedContextMenuItemValue)
                  "
                  :datamodelInfoLabel="
                    $t(
                      `generalPages.${
                        isPageDashboard ? 'Datamodel Alias' : 'Datamodel Name'
                      }`
                    )
                  "
                  :aliasRenameLabel="
                    $t(
                      `generalPages.${
                        isPageDashboard
                          ? 'Dashboard Datamodel Alias'
                          : 'Datamodel Alias'
                      }`
                    )
                  "
                  @saveField="saveField"
                  @close="() => onPopupClose(popupTypes.fieldPopupId)"
                />
                <FormatNumberPopup
                  v-if="
                    formatNumberPopupId === getIdOrFieldId(field) &&
                    selectedFieldDatasetId === field.datasetId
                  "
                  :selectedFormat="selectedFormat"
                  :field="selectedContextMenuItemValue.alias"
                  @saveFormat="saveFormat"
                  @closeFormat="
                    () => onPopupClose(popupTypes.formatNumberPopupId)
                  "
                />
                <FormatDatePopup
                  v-if="
                    formatDatePopupId === getIdOrFieldId(field) &&
                    selectedFieldDatasetId === field.datasetId
                  "
                  :selectedFormat="selectedFormat"
                  :field="selectedContextMenuItemValue.alias"
                  @saveFormat="saveFormat"
                  @closeFormat="
                    () => onPopupClose(popupTypes.formatDatePopupId)
                  "
                />
                <FiltersPopup
                  v-if="
                    filterPopupId === getIdOrFieldId(field) &&
                    selectedFieldDatasetId === field.datasetId
                  "
                  :filterType="field.usageType"
                  :selectedComponent="selectedFilterTabComponent"
                  :filterDetailData="filterDetailData"
                  :attributeFilterSearchParam="attributeFilterSearchParam"
                  @saveFilterByAttribute="saveFilter"
                  @saveFilterByDate="saveFilter"
                  @saveFilterByMeasure="saveFilter"
                  @setFilterTabComponent="setFilterTabComponent"
                  @closeFilters="onPopupClose('filterPopupId')"
                />
                <DrillPathPopup
                  v-if="drillPathPopupMenu === getIdOrFieldId(field)"
                  :selectedField="selectedContextMenuItemValue"
                  :fields="fields"
                  @saveField="saveField"
                  @closeDrillPathPopupMenu="
                    () => onPopupClose(popupTypes.drillPathPopupMenu)
                  "
                />
                <GroupPopup
                  v-if="groupPopupMenu === getIdOrFieldId(field)"
                  :selectedField="selectedContextMenuItemValue"
                  @closeGroupPopupMenu="
                    () => onPopupClose(popupTypes.groupPopupMenu)
                  "
                  @getFieldListById="$emit('getFieldListById')"
                />
              </el-popover>
            </ul>
          </draggable>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {
  DatamodelContextDefaults,
  getDatamodelFieldContextMenu,
  ContextMenuViewType,
  customFilterTypes,
  contextMenuItemKeys,
  getBeautifyMenuItemList,
  // fieldBeautifyEnums,
  SORT,
  AUTO_RENAME,
  DIAGRAM_LIST,
} from "../../commons/dataModelTypes";
import FieldPopup from "../helper/FieldPopup.vue";
import DrillPathPopup from "../helper/DrillPathPopup.vue";
import GroupPopup from "../helper/GroupPopup.vue";
import cloneDeep from "clone-deep";
import FormatNumberPopup from "../panel/measures/FormatNumberPopup.vue";
import FormatDatePopup from "../panel/measures/FormatDatePopup.vue";
import { CustomIcon } from "../../assets/js/custom-icons";
import FiltersPopup from "../panel/filters/FiltersPopup.vue";
import { filterPopupTabValue } from "../panel/filters/js/filters";
import Typography from "../helper/Typography.vue";
import draggable from "vuedraggable";
import { ACTION as ACTION_DATASET } from "../../store/modules/Visualize/Dataset/types";
import { mapActions } from "vuex";
import InputSearch from "../helper/Form/InputSearch";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import {
  getDatamodelDatasetField,
  getDatamodelDatesetAlias,
} from "../../util/dataset-fields/datasetFields";
import { filterType } from "../../commons/filterComponents";

export default {
  components: {
    FieldPopup,
    DrillPathPopup,
    GroupPopup,
    FormatNumberPopup,
    FormatDatePopup,
    FiltersPopup,
    Typography,
    draggable,
    InputSearch,
  },
  props: {
    selectedDataModel: {
      type: Object,
    },
    fields: {
      type: Object,
    },
    showPopup: {
      type: Boolean,
      default: true,
    },
    attributeFilterSearchParam: {
      type: Object,
    },
    isPageDashboard: {
      type: Boolean,
    },
    isPanelPage: {
      type: Boolean,
    },
    isBeautifyLoading: {
      type: Boolean,
      default: false,
    },
    isOneDatasetExpandedActive: {
      type: Boolean,
      required: true,
    },
    enabledDraggableSort: {
      type: Boolean,
      default: true,
    },
    datamodelDatasetFields: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      activeCollapseItem: [],
      fieldIndex: null,
      contextMenuId: "datasetFieldsContextMenu",
      contextMenuItems: [],
      selectedContextMenuItemValue: null,
      selectedContextMenuItem: null,
      fieldPopupId: null,
      selectedFieldDatasetId: null,
      datasetTitleId: null,
      formatDatePopupId: null,
      formatNumberPopupId: null,
      selectedFormat: {},
      openPopupFieldId: null,
      activeContextMenuId: null,
      contextMenuIdPrefix: "contextMenu_",
      filterPopupId: null,
      drillPathPopupMenu: null,
      groupPopupMenu: null,
      selectedFilterTabComponent: filterPopupTabValue.SELECTION,
      filterDetailData: {
        alias: "",
        fieldId: "",
        field: "",
        usageType: "",
      },
      selectedFieldItem: "",
      filterPopupTabValue: filterPopupTabValue,
      dataSet: {
        field: [this.selectedDataModel?.datasets?.[0]?.name],
        alias: [this.selectedDataModel?.datasets?.[0]?.alias],
      },
      popupTypes: {
        drillPathPopupMenu: "drillPathPopupMenu",
        groupPopupMenu: "groupPopupMenu",
        formatDatePopupId: "formatDatePopupId",
        formatNumberPopupId: "formatNumberPopupId",
        fieldPopupId: "fieldPopupId",
      },
      searchField: null,
      customIcon: CustomIcon,
      isShowCollapsePopover: false,
      fieldBeautifyValue: null,
      fieldBeautifyContextValue: false,
      contextMenuDatasetId: null,
      beautifyMenuDatasetId: null,
      filterType: filterType,
    };
  },
  watch: {
    selectedDataModel: {
      handler(newVal, oldVal) {
        if (_.isEqual(newVal, oldVal)) {
          return;
        }

        if (newVal) {
          this.activeCollapseItem = [
            this.selectedDataModel?.datasets?.[0]?.name,
          ];
        }
      },
      deep: true,
      immediate: true,
    },
    searchField: {
      handler(search) {
        if (search) {
          let datasetHasTheSearchedFile = [];
          this.selectedDataModel?.datasets.forEach((dataset) => {
            const searchedField = this.getDatasetFieldsByDatasetId(
              dataset.datasetId
            );
            if (searchedField.length)
              datasetHasTheSearchedFile.push(dataset?.name);
          });
          this.activeCollapseItem = datasetHasTheSearchedFile;
        } else {
          this.activeCollapseItem = [
            this.selectedDataModel?.datasets?.[0]?.name,
          ];
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateFieldsOrder: ACTION_DATASET.UPDATE_FIELDS_ORDER,
    }),
    getDatamodelInfo(field) {
      return this.isPageDashboard
        ? this.datamodelDatasetAlias(field)
        : field?.name;
    },
    datamodelDatasetField(field) {
      return getDatamodelDatasetField(this.datamodelDatasetFields, field);
    },
    datamodelDatasetAlias(field) {
      return getDatamodelDatesetAlias(this.fields, field);
    },
    handlePopoverClick(dataSet) {
      this.datasetTitleId = dataSet.datasetId;
      this.isShowCollapsePopover = !this.isShowCollapsePopover;
    },
    updateActiveCollapseItem(datasetName) {
      if (!this.activeCollapseItem || this.isOneDatasetExpandedActive) {
        this.activeCollapseItem = [];
      }

      if (!this.activeCollapseItem.includes(datasetName)) {
        this.activeCollapseItem.push(datasetName);
      }
    },
    resetActiveCollapseItem() {
      this.activeCollapseItem = [];
    },
    getAllFieldPropertyContextMenuItems() {
      return getBeautifyMenuItemList({
        isVieWDataActive: this.isPageDashboard,
      });
    },
    //this method is for takin the ID or fieldID of a field, for duplicate method backend needs ID but if there is no ID
    //(null or undefined) in dashboardfields or datasetfields fieldID is needed. This method gives ID or fieldID.
    //(ID privileged)
    getIdOrFieldId(field) {
      return field?.id ?? field?.fieldId;
    },
    checkDatasetNameLength(datasetName) {
      return datasetName.length > 20;
    },
    checkDatasetEmpty(datasetId) {
      return this.getDatasetFieldsByDatasetId(datasetId).length < 1;
    },
    getDatasetFieldsByDatasetId(datasetId) {
      //Dashboard ve datamodel sayfalarında bu component ortak kullanılıyor. Yetkisi olmayan field'lar dashboard içindeki dataset ağacında gözükmesin,
      //Datamodel içindeki dataset ağacında gözüksün istenildiği için isPageDashboard'a göre kontrol set ediliyor.
      let filteredFieldsByAuthorize = this.isPageDashboard
        ? this.fields?.[datasetId]?.filter((x) => x.isAuthorize)
        : this.fields?.[datasetId];

      return this.searchField
        ? filteredFieldsByAuthorize.filter((field) => {
            return field.alias
              .toUpperCase()
              .includes(this.searchField.replace("İ", "I").toUpperCase());
          })
        : filteredFieldsByAuthorize ?? [];
    },
    openBeautifyMenu(event, datasetId) {
      this.beautifyMenuDatasetId = datasetId;
      document
        .getElementById(`beautifyMenu-${datasetId}`)
        .ej2_instances[0].open(event.y, event.x);
    },
    openContextMenu(event, params) {
      this.contextMenuItems = getDatamodelFieldContextMenu(
        params?.item?.isCalculated,
        params?.item?.type,
        ContextMenuViewType.DATAMODEL,
        params?.item?.usageType,
        params?.item,
        {
          isShowButtonActive: params?.item?.isHidden,
          isColumnBasedFormattingActive: false,
          isPageDatamodel: !this.isPageDashboard,
        }
      );

      //filter context menu with DatamodelContextDefaults.DELETE_CALCULATION if field is not valid
      if (!params?.item?.valid) {
        this.contextMenuItems = this.contextMenuItems.filter(
          (item) => item.value === DatamodelContextDefaults.DELETE_CALCULATION
        );
      }

      const { alias, datasetId, fieldId } = params?.item;

      this.$emit("getSelectedField", {
        field: params?.item?.name,
        alias,
        datasetId,
        fieldId,
        fieldType: params?.item?.type,
      });
      this.fieldIndex = params.index;
      this.selectedContextMenuItemValue = params.item;
      this.activeContextMenuId =
        this.contextMenuIdPrefix + this.getIdOrFieldId(params.item);
      this.selectedFieldDatasetId = params.item.datasetId;
      this.selectedFormat = this.selectedContextMenuItemValue?.format ?? {};
      this.filterDetailData = { ...params.item, field: params.item.name };
      this.filterSelectedIndex = params.index;
      this.$nextTick(() => {
        if (this.contextMenuItems.length) {
          document
            .getElementById(this.activeContextMenuId)
            .ej2_instances[0].open(event.y, event.x);
        }
      });
    },
    checkIsHidden(field) {
      if (this.isPageDashboard) {
        if (
          (field.isHidden && field?.tempId) ||
          (field.isHidden && field.id) ||
          field.isHidden == null ||
          !field.isHidden
        ) {
          return true;
        } else return false;
      } else return true;
    },
    getFilterPopupData() {
      const fieldUsageType = this.filterDetailData.usageType;
      if (this.filterDetailData.filterType) {
        this.selectedFilterTabComponent = this.filterDetailData.filterType;
      } else {
        if (fieldUsageType == DatamodelContextDefaults.USAGE_TYPES.MEASURE)
          this.selectedFilterTabComponent = filterPopupTabValue.RULE;
        else this.selectedFilterTabComponent = filterPopupTabValue.SELECTION;
      }

      this.filterPopupId = this.getIdOrFieldId(
        this.selectedContextMenuItemValue
      );
      this.selectedFieldItem = this.filterDetailData.name;
    },
    setFilterTabComponent(value) {
      this.selectedFilterTabComponent = value;
    },
    saveFilter() {
      this.onPopupClose(this.selectedFieldItem);
    },
    shortCollapseItemTitle(name) {
      return name.substring(0, 23);
    },
    isCalculatedColumn(field) {
      return field.isCalculated;
    },
    changeFieldBeautifyEnum(value, datamodelId) {
      const bodyParam = {
        fieldBeautifyOperation: value,
      };

      this.$emit("updateDatamodelFieldBeautify", {
        datamodelId,
        bodyParam,
      });
    },
    datasetIcon(element) {
      const iconByTypes = {
        [DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE]: CustomIcon.Abc,
        [DatamodelContextDefaults.USAGE_TYPES.DATE]: CustomIcon.Clock_Time,
      };
      return iconByTypes[element.usageType] ?? CustomIcon.Measure;
    },
    saveField(fieldObj) {
      if (!fieldObj.id) {
        fieldObj.tempId = uuidv4();
      }
      //Not: The saveField function is used on datamodel.vue and dashboard.vue(dashboard.js) files.
      //The index and selectedField parameters are used in the saveField function in file dashboard.js

      this.$emit("saveField", {
        bodyParam: fieldObj,
        index: this.fieldIndex,
        selectedField: this.selectedContextMenuItemValue,
      });
    },
    beforeContextItemHighlight(args) {
      const val = args.item.value;
      const selectedDataset = this.selectedDataModel?.datasets?.find(
        (x) => x.datasetId === this.beautifyMenuDatasetId
      );

      if (!val) return;

      const checkAndHighlight = (collection, selectedValue) => {
        Object.values(collection).forEach((key) => {
          if (val === key && selectedValue === key) {
            args.element.innerHTML = `<strong>${args.item.text}</strong> <i class="mdi mdi-check"></i>`;
          }
        });
      };

      checkAndHighlight(
        DatamodelContextDefaults.USAGE_TYPES,
        this.selectedContextMenuItemValue?.usageType
      );
      checkAndHighlight(
        DatamodelContextDefaults.SUMMARIZATION_RULE,
        this.selectedContextMenuItemValue?.defaultAggFunction
      );
      checkAndHighlight(SORT, selectedDataset?.sortType);
      checkAndHighlight(AUTO_RENAME, selectedDataset?.autoRenameType);
    },
    draggableTriggerContextMenuSortItem(value) {
      if (Object.keys(SORT).includes(value)) {
        this.$emit("updateSortAndRenameSettings", {
          datasetId: this.beautifyMenuDatasetId,
          bodyParam: { sortType: value, autoRenameType: null },
        });
      }
    },
    handleContextMenuItemSelect(arg, datasetId) {
      const value = arg?.item?.value;
      const argItemKey = arg?.item?.key;
      const field = arg?.item?.field;

      if (!value) return;

      if (value === DIAGRAM_LIST.VIEW_DATA) {
        this.$emit("viewData", datasetId);

        return;
      }

      if (value === DIAGRAM_LIST.SHOW_ON_DIAGRAM) {
        this.$emit("showOnDiagram", datasetId);

        return;
      }

      if (Object.keys(AUTO_RENAME).includes(value)) {
        this.$emit("updateDatasetFieldsAlias", {
          datasetId: this.beautifyMenuDatasetId,
          bodyParam: { sortType: null, autoRenameType: value },
        });
      } else if (Object.keys(SORT).includes(value) && value !== SORT.CUSTOM) {
        this.$emit("updateDatasetFieldsAlias", {
          datasetId: this.beautifyMenuDatasetId,
          bodyParam: { sortType: value, autoRenameType: null },
        });
      } else {
        this.selectedFieldDatasetId =
          this.selectedContextMenuItemValue.datasetId;
        this.openPopupFieldId = this.getIdOrFieldId(
          this.selectedContextMenuItemValue
        );
      }

      if (value == DatamodelContextDefaults.ALIAS) {
        this.fieldPopupId = this.getIdOrFieldId(
          this.selectedContextMenuItemValue
        );
        this.selectedContextMenuItem = DatamodelContextDefaults.ALIAS;
      } else if (value == DatamodelContextDefaults.SCREEN_TIP) {
        this.fieldPopupId = this.getIdOrFieldId(
          this.selectedContextMenuItemValue
        );
        this.selectedContextMenuItem = DatamodelContextDefaults.SCREEN_TIP;
      } else if (value == DatamodelContextDefaults.DESCRIPTION) {
        this.fieldPopupId = this.getIdOrFieldId(
          this.selectedContextMenuItemValue
        );
        this.selectedContextMenuItem = DatamodelContextDefaults.DESCRIPTION;
      } else if (value == DatamodelContextDefaults.FORMAT) {
        // For format popup
        if (
          this.selectedContextMenuItemValue.usageType ==
          DatamodelContextDefaults.USAGE_TYPES.MEASURE
        ) {
          this.formatNumberPopupId = this.getIdOrFieldId(
            this.selectedContextMenuItemValue
          );
        } else if (
          this.selectedContextMenuItemValue.usageType ==
          DatamodelContextDefaults.USAGE_TYPES.DATE
        ) {
          this.formatDatePopupId = this.getIdOrFieldId(
            this.selectedContextMenuItemValue
          );
        } else {
          this.openPopupFieldId = null;
        }
      } else if (value == DatamodelContextDefaults.FILTER) {
        // For filter popup
        this.getFilterPopupData();
      } else if (value == DatamodelContextDefaults.DRILL_PATH) {
        // For drillpath Popup
        this.drillPathPopupMenu = this.getIdOrFieldId(
          this.selectedContextMenuItemValue
        );
      } else if (value == DatamodelContextDefaults.GROUP) {
        this.groupPopupMenu = this.getIdOrFieldId(
          this.selectedContextMenuItemValue
        );
        // for group Popup
      } else if (value == DatamodelContextDefaults.DUPLICATE) {
        this.$emit("duplicateFieldById", {
          id: this.selectedContextMenuItemValue?.id,
          fieldId: this.selectedContextMenuItemValue?.fieldId,
        });
        this.openPopupFieldId = null;
      } else {
        // no popup has been opened
        this.openPopupFieldId = null;
      }

      if (
        value === DatamodelContextDefaults.HIDE ||
        value === DatamodelContextDefaults.SHOW
      ) {
        const cloned = cloneDeep(this.selectedContextMenuItemValue);

        cloned.isHidden = !cloned.isHidden;
        this.saveField(cloned);
      }

      if (argItemKey === contextMenuItemKeys.USAGE_TYPES) {
        const cloned = cloneDeep(this.selectedContextMenuItemValue);

        cloned.usageType = value;
        this.saveField(cloned);
      }

      if (argItemKey === contextMenuItemKeys.AGG_FUNCTION) {
        const cloned = cloneDeep(this.selectedContextMenuItemValue);

        cloned.defaultAggFunction = value;
        this.saveField(cloned);
      }

      if (value == DatamodelContextDefaults.NEW_CALCULATION) {
        this.$emit("openCreateCalculatedColumn", field);
      } else if (value == DatamodelContextDefaults.EDIT_CALCULATION) {
        this.$emit("openEditCalculatedColumn", field);
      }

      if (value == DatamodelContextDefaults.DELETE_CALCULATION) {
        if (!field?.baseColumn) {
            this.$emit("deleteDuplicatedField", field);

        } else {
            this.$emit("openDeleteCalculatedColumn", field);
        }
      }

      if (value == DatamodelContextDefaults.SECURITY) {
        this.$emit("openColumnSecurity", this.selectedContextMenuItemValue);
      }

      if (value == DatamodelContextDefaults.PROPERTIES) {
        this.$emit("openColumnProperties", this.selectedContextMenuItemValue);
      }
    },
    saveFormat(params) {
      const clonedField = cloneDeep(this.selectedContextMenuItemValue);
      clonedField.format = params;
      this.saveField(clonedField);
      switch (clonedField.type) {
        case [customFilterTypes.DATE]:
          this.onPopupClose(this.popupTypes.formatDatePopupId);
          break;
        case [customFilterTypes.NUMBER]:
          this.onPopupClose(this.popupTypes.formatNumberPopupId);
          break;
        default:
          this.onPopupClose(this.popupTypes.fieldPopupId);
      }
    },
    onPopupClose(popupVariableName) {
      this[popupVariableName] = null;
      this.openPopupFieldId = null;
    },
    onDatamodelFieldMoved(datasetId) {
      if (!this.openPopupFieldId) {
        let fields = this.getDatasetFieldsByDatasetId(datasetId);
        let bodyParam = [];
        for (let i = 0; i < fields.length; i++) {
          let param = {
            fieldId: fields[i].fieldId,
            order: i,
            datasetId,
          };
          bodyParam.push(param);
        }
        this.draggableTriggerContextMenuSortItem(SORT.CUSTOM);
        this.updateFieldsOrder({ bodyParam });
      } else return;
    },
  },
};
</script>
<style scoped>
.vis-dataset-fields-right-icon-box i {
  margin: 0 !important;
}
.vis-field-item {
  max-width: calc(100% - 40px);
}
.vis-field-item-span {
  max-width: 100%;
}
</style>
