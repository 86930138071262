<template>
  <div class="layout-container">
    <Topbar />
    <router-view id="router-view" :key="$route.fullPath" />
  </div>
</template>
<script>
import Topbar from "./components/layout/Topbar.vue";

export default {
  data() {
    return {
      customerEnv: process.env.VUE_APP_CUSTOMER_NAME,
    };
  },
  components: { Topbar },
  mounted() {
    this.setFavIcon();
  },
  methods: {
    setFavIcon() {
      const favIconEnv =
        window.customerConfig?.[this.customerEnv]?.BROWSER?.BASE_ICON;
        const visualyzeFavIcon = "DataPlateauVisualize.ico";

      if (favIconEnv) {
        document.querySelector('link[rel="icon"]').href = `${window.location.origin}/${favIconEnv}`;
      } else {
        document.querySelector('link[rel="icon"]').href = `${window.location.origin}/${visualyzeFavIcon}`;
      }
    },
  },
};
</script>

<style>
/* Context Menu target */
#target {
  border: 1px dashed;
  height: 150px;
  padding: 10px;
  position: relative;
  text-align: justify;
  color: gray;
  user-select: none;
}
.layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#router-view {
  width: 100%;
  height: calc(100% - 48px);
  margin: 0;
}
</style>
