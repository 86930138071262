import { gridLinesArr, pivotTableGrandTotalPositions, pivotTableGrandTotalsSelections, pivotTableSelectionModes, pivotTableSelectionTypes, pivotTableSubTotalsSelections } from "../commons/dashboardProperties"

export const PIVOT_TABLE_DEFAULT_CONFIGURATIONS = {
    PIVOT_TABLE_SHOW_FIELD_LIST: false,
    PIVOT_TABLE_ENABLE_VALUE_SORTING: false,
    PIVOT_TABLE_ALLOW_EXPORT_MENU: false,
    PIVOT_TABLE_ALLOW_EXCEL_EXPORT: true,
    PIVOT_TABLE_ALLOW_CSV_EXPORT: true,
    PIVOT_TABLE_ALLOW_PDF_EXPORT: false,
    PIVOT_TABLE_ALLOW_SELECTION: false,
    PIVOT_TABLE_ALLOW_SHOW_INSIGHT: true,
    PIVOT_TABLE_ALLOW_SHOW_SQL: false,
    PIVOT_TABLE_ALLOW_CONTEXT_MENU: true,
    PIVOT_TABLE_SELECTION_MODE: pivotTableSelectionModes[0].value,
    PIVOT_TABLE_SELECTION_TYPE: pivotTableSelectionTypes[0].value,
    PIVOT_TABLE_SUB_TOTALS: pivotTableSubTotalsSelections[0].value,
    PIVOT_TABLE_GRAND_TOTALS: pivotTableGrandTotalsSelections[0].value,
    PIVOT_TABLE_GRAND_TOTAL_POSITION: pivotTableGrandTotalPositions[1].value,
    PIVOT_TABLE_ALLOW_HEATMAP: false,
    PIVOT_TABLE_CONDITIONAL_FORMAT: [],
    PIVOT_TABLE_ROW_TITLE_FONT_FAMILY: "Poppins, sans-serif",
    PIVOT_TABLE_ROW_TITLE_FONT_WEIGHT: 500,
    PIVOT_TABLE_ROW_TITLE_FONT_SIZE: 12,
    PIVOT_TABLE_ROW_TITLE_FONT_COLOR: "#000",
    PIVOT_TABLE_COLUMN_TITLE_FONT_FAMILY: "Poppins, sans-serif",
    PIVOT_TABLE_COLUMN_TITLE_FONT_WEIGHT: 500,
    PIVOT_TABLE_COLUMN_TITLE_FONT_SIZE: 12,
    PIVOT_TABLE_COLUMN_TITLE_FONT_COLOR: "#000",
    PIVOT_TABLE_MEASURE_FONT_FAMILY: "Poppins, sans-serif",
    PIVOT_TABLE_MEASURE_FONT_WEIGHT: 500,
    PIVOT_TABLE_MEASURE_FONT_SIZE: 12,
    PIVOT_TABLE_MEASURE_FONT_COLOR: "#000",
    PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_FAMILY: "Poppins, sans-serif",
    PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_WEIGHT: 500,
    PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_SIZE: 12,
    PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_COLOR: "#000",
    PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_FAMILY: "Poppins, sans-serif",
    PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_WEIGHT: 500,
    PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_SIZE: 12,
    PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_COLOR: "#000",
    PIVOT_TABLE_GRID_LINES: gridLinesArr[0].value,
    PIVOT_TABLE_ROW_HEIGHT: 40,
    PIVOT_TABLE_ROW_WIDTH: 120,
    PIVOT_TABLE_AUTO_REFRESH: 0,
    PIVOT_TABLE_AUTO_REFRESH_CUSTOM: null,
    PIVOT_TABLE_DYNAMIC_TITLE_VALUE: "",
    PIVOT_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
    PIVOT_TABLE_DYNAMIC_TITLE_SHOW_VALUE: false,
    PIVOT_TABLE_GROUP_MULTIPLE_VALUES: true,
    PIVOT_TABLE_ENABLE_ROW_PAGING: true,
    PIVOT_TABLE_ROWS_PER_PAGE: 100,
    PIVOT_TABLE_ENABLE_COLS_PAGING: true,
    PIVOT_TABLE_COLS_PER_PAGE: 100,
    PIVOT_TABLE_DRILLED_MEMBERS: true,
    PIVOT_TABLE_ALLOW_DRILL_THROUGH: true,
    PIVOT_TABLE_DRILL_THROUGH_DASHBOARD_LIST: [],
    PIVOT_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK: false,
    PIVOT_TABLE_COLUMN_TITLE_TEXT_WRAP: false,
    PIVOT_TABLE_ROW_TITLE_TEXT_WRAP: false,
    PIVOT_TABLE_ALLOW_TRANSPOSE: false,
}


export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
    return typeof value != "undefined" ? value : computedValue
}