export const LoadingComponent = {
    ConnectionTest: "ConnectionTest",
    ConnectionSave: "ConnectionSave",
    DashboardPage: "DashboardPage",
    ScheduleList: "ScheduleList",
    PanelPage: "PanelPage",
    HomePage: "HomePage",
    HomepageList: "HomepageList",
    DatamodelList: "DatamodelList",
    FolderList: "FolderList",
    RunSql: "RunSql",
    GetSearch: "GetSearch",
    SecurityFilters: "SecurityFilters",
    CustomFilters: "CustomFilters",
    CSVExcelUpload: "CSVExcelUpload",
    ChatGpt: "ChatGpt",
    Environments: "Environments",
    MigrateContent: "MigrateContent",
    ColorPalettes: "ColorPalettes",
    SelectedScenario: "SelectedScenario",
    DashboardSave: "DashboardSave",
    DataFlow: "DataFlow",
    VisualizeAIGenerate: "VisualizeAIGenerate",
    DatamodelFieldBeautify: "DatamodelFieldBeautify",
    DatamodelFields: "DatamodelFields",
    ObjectLock: "ObjectLock",
    DatasetWizard: "DatasetWizard",
    SaveConfiguration: "SaveConfiguration",
    DeleteCacheAll: "DeleteCacheAll",
    DatasetSave: "DatasetSave",
    AuthorizationList: "AuthorizationList", 
    UploadImages: "UploadImages",
}
