<template>
  <div class="data-model-sql-left-panel">
    <el-collapse
      :value="activeCollapses"
      @change="(e) => (activeCollapses = e)"
    >
      <el-collapse-item
        v-loading="isSecurityFiltersLoading"
        class="vis-collapse-item data-model-sql-left-collapse"
        :title="$t(`${collapses.security.title}`)"
        :name="collapses.security.name"
      >
        <DataModelSqlFilter
          v-for="securityFilter in securityFilters"
          :key="securityFilter.value"
          :label="securityFilter.label"
          :labelPrefix="securityFilter.labelPrefix"
          :value="securityFilter.value"
          :coloredPrefix="true"
          @filterClicked="(value) => $emit('filterClicked', value)"
        />
      </el-collapse-item>
      <el-collapse-item
        v-loading="isCustomFiltersLoading"
        class="vis-collapse-item data-model-sql-left-collapse"
        :title="$t(`${collapses.datamodel.title}`)"
        :name="collapses.datamodel.name"
      >
        <DataModelSqlFilter
          v-for="(customFilter, index) in datamodelFiltersMapping"
          :key="customFilter.value + index"
          :label="customFilter.label"
          :labelPrefix="customFilter.labelPrefix"
          :value="customFilter.value"
          :showActions="false"
          :filterId="customFilter.paramId"
          @filterClicked="(value) => $emit('filterClicked', value)"
          @editItem="editItem(customFilter)"
          @deleteItem="deleteItem(customFilter)"
        />
      </el-collapse-item>
      <el-collapse-item
        v-loading="isCustomFiltersLoading"
        class="vis-collapse-item data-model-sql-left-collapse"
        :title="$t(`${collapses.custom.title}`)"
        :name="collapses.custom.name"
      >
        <DataModelSqlFilter
          v-for="(customFilter, index) in customFiltersMapping"
          :key="customFilter.value + index"
          :label="customFilter.label"
          :labelPrefix="customFilter.labelPrefix"
          :value="customFilter.value"
          :showActions="true"
          :filterId="customFilter.paramId"
          @filterClicked="(value) => $emit('filterClicked', value)"
          @editItem="editItem(customFilter)"
          @deleteItem="deleteItem(customFilter)"
        />
        <div class="vis-mt--1">
          <div class="vis-text--center">
            <el-button @click="openAddNewFilterPopup" size="small">{{
              $t("generalPages.addFilter")
            }}</el-button>
          </div>
          <DataModelCustomFilterPopup
            :isCustomFilterPopupOpen="isCustomFilterPopupOpen"
            :filter="customFilterPopupEditItem"
            :mode="customFilterPopupMode"
            @save="saveCustomFilterParams"
            @update="updateCustomFilterParams"
            @delete="deleteItem"
            @close="isCustomFilterPopupOpen = false"
          />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import DataModelSqlFilter from "./DataModelSqlFilter";
import DataModelCustomFilterPopup from "./DataModelCustomFilterPopup";
import cloneDeep from "clone-deep";
import {
  customFilterPopupDefaultFormData,
  customFilterPopupModes,
  filterSources,
} from "./types";

export default {
  components: { DataModelSqlFilter, DataModelCustomFilterPopup },
  props: {
    securityFilters: {
      type: Array,
      required: true,
    },
    customFilters: {
      type: Array,
      required: true,
    },
    isSecurityFiltersLoading: {
      type: Boolean,
      default: false,
    },
    datasetId: {
      type: String,
      default: null,
    },
    isCustomFiltersLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCustomFilterPopupOpen: false,
      customFilterPopupEditItem: null,
      customFilterPopupModes: customFilterPopupModes,
      customFilterPopupMode: null,
      collapses: {
        security: {
          title: "generalPages.securityFilters",
          name: "security_filters",
        },
        datamodel: {
          title: "generalPages.Datamodel Parameters",
          name: "datamodel_filters",
        },
        custom: {
          title: "generalPages.customFilters",
          name: "custom_filters",
        },
      },
      activeCollapses: [],
      tempCustomFilterParams: [],
    };
  },
  watch: {
    customFilters: {
      handler(val) {
        this.tempCustomFilterParams = [];
        if (val?.length) {
          this.tempCustomFilterParams = val;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.activeCollapses = [
      this.collapses.security.name,
      this.collapses.datamodel.name,
      this.collapses.custom.name,
    ];
    this.tempCustomFilterParams = cloneDeep(this.customFilters);
  },
  computed: {
    datamodelFilterParams() {
      return this.tempCustomFilterParams.filter(
        (t) => t.filterSource === filterSources.DATAMODEL
      );
    },
    datamodelFiltersMapping() {
      return this.datamodelFilterParams?.map((a) => {
        return {
          label: a.name,
          labelPrefix: "",
          value: `:${a.name}`,
          paramId: a.paramId,
          temporaryFilterParamId: a?.temporaryFilterParamId,
        };
      });
    },
    customFilterParamsOfCurrentDataset() {
      return this.tempCustomFilterParams.filter(
        (t) => !t.objectId || t.objectId === this.datasetId
      );
    },
    customFiltersMapping() {
      return this.customFilterParamsOfCurrentDataset?.map((a) => {
        return {
          label: a.name,
          labelPrefix: "",
          value: `:${a.name}`,
          paramId: a.paramId,
          temporaryFilterParamId: a?.temporaryFilterParamId,
        };
      });
    },
  },
  methods: {
    openAddNewFilterPopup() {
      this.customFilterPopupEditItem = customFilterPopupDefaultFormData;
      this.customFilterPopupMode = this.customFilterPopupModes.Create;
      this.isCustomFilterPopupOpen = true;
    },
    saveCustomFilterParams(newParams) {
      const clonedCustomFilterParams = cloneDeep(this.tempCustomFilterParams);
      if (this.datasetId) {
        newParams.objectId = this.datasetId;
      }
      clonedCustomFilterParams.push(newParams);
      this.$emit("saveCustomFilterParams", clonedCustomFilterParams);
      this.isCustomFilterPopupOpen = false;
    },
    updateCustomFilterParams(newParams) {
      let clonedCustomFilterParams = [];
      if (newParams.temporaryFilterParamId) {
        clonedCustomFilterParams = cloneDeep(
          this.tempCustomFilterParams
        ).filter(
          (c) => c.temporaryFilterParamId !== newParams.temporaryFilterParamId
        );
      } else {
        clonedCustomFilterParams = cloneDeep(
          this.tempCustomFilterParams
        ).filter((c) => c.paramId !== newParams.paramId);
      }

      clonedCustomFilterParams.push(newParams);
      this.$emit("updateCustomFilterParams", clonedCustomFilterParams);
      this.isCustomFilterPopupOpen = false;
    },
    editItem(filter) {
      this.isCustomFilterPopupOpen = true;
      this.customFilterPopupMode = this.customFilterPopupModes.Edit;
      if (filter.temporaryFilterParamId)
        this.customFilterPopupEditItem = cloneDeep(this.customFilters).find(
          (c) => c?.temporaryFilterParamId === filter?.temporaryFilterParamId
        );
      else
        this.customFilterPopupEditItem = cloneDeep(this.customFilters).find(
          (c) => c?.paramId === filter?.paramId
        );
    },
    deleteItem(filter) {
      const clonedCustomFilterParams = cloneDeep(
        this.tempCustomFilterParams
      ).filter((c) => {
        if (c?.paramId && c?.paramId !== filter?.paramId) {
          return true;
        }
        if (
          c?.temporaryFilterParamId &&
          c?.temporaryFilterParamId !== filter?.temporaryFilterParamId
        ) {
          return true;
        }

        return false;
      });

      this.$emit("updateCustomFilterParams", clonedCustomFilterParams);
      this.$emit("deleteCustomFilterParams", filter?.paramId);
      this.isCustomFilterPopupOpen = false;
    },
  },
};
</script>

<style scoped>
.data-model-sql-left-panel {
  height: 100%;
}
.data-model-sql-left-collapse {
  margin-bottom: 25px;
  border-bottom: none !important;
}
.data-model-sql-left-collapse-add-new-filter-plus-icon {
  font-size: 25px;
  margin-right: 10px;
}
</style>
