<template>
  <div class="connection-pool-settings">
    <div class="connection-pool-settings-header">
      <span class="connection-pool-settings-header-title">{{
        $t("connection.All Services")
      }}</span>
      <el-switch
        v-model="customizeForEachService"
        :active-text="$t('connection.Customize for each service')"
      ></el-switch>
    </div>

    <div
      v-for="service in customizeForEachService ? serviceList : ['allServices']"
      :key="service"
      class="connection-pool-settings-service-section"
    >
      <el-divider v-if="customizeForEachService"></el-divider>
      <h3 v-if="customizeForEachService">
        {{ service.charAt(0).toUpperCase() + service.slice(1) }}
        {{ $t("connection.Service") }}
      </h3>

      <!-- Form Section -->
      <el-form
        label-position="left"
        label-width="200px"
        :model="formData[service]"
        class="connection-pool-settings-form-section"
      >
        <el-form-item
          v-for="(item, key) in parameterDefinitions"
          :key="key"
          :label="item.label"
        >
          <div class="connection-pool-settings-form-item-container">
            <el-input v-model="formData[service][key]" type="number" />
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.tooltip"
              placement="top"
            >
              <i :class="CustomIcon.Information"></i>
            </el-tooltip>
          </div>
        </el-form-item>
      </el-form>

      <!-- Key-Value Parameters Section -->
      <div class="connection-pool-settings-key-value-section">
        <div
          class="connection-pool-settings-connection-pool-settings-add-parameter-container"
        >
          <el-link
            class="connection-pool-settings-add-parameter"
            type="primary"
            @click="addParameter(service)"
            >{{ $t("connection.Add Parameter") }}</el-link
          >
        </div>
        <div
          v-for="(param, index) in formData[service].customProperties"
          :key="index"
          class="connection-pool-settings-key-value-row"
        >
          <el-input
            v-model="param.key"
            :placeholder="$t('connection.Key')"
          ></el-input>
          <el-input
            v-model="param.value"
            :placeholder="$t('connection.Value')"
          ></el-input>
          <i
            :class="CustomIcon.TrashCanOutline"
            @click="removeParameter(service, index)"
          ></i>
        </div>
      </div>
    </div>

    <div class="connection-pool-settings-footer">
      <el-button
        class="connection-pool-settings-footer-left-button"
        size="small"
        @click="$emit('back')"
        >{{ $t("connection.Back") }}</el-button
      >
      <div class="connection-pool-settings-footer-right-buttons">
        <el-button size="small" @click="testConnection">{{
          $t("connection.Test")
        }}</el-button>
        <el-button type="primary" size="small" @click="saveConnection">{{
          $t("connection.Save")
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  ACTION,
  GETTER,
} from "../../store/modules/Visualize/Connections/types";
import cloneDeep from "clone-deep";
import { CustomIcon } from "../../assets/js/custom-icons";

const services = {
  query: "query",
  ingest: "ingest",
  exporter: "exporter",
};

export default {
  props: {
    poolProperties: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      CustomIcon: CustomIcon,
      customizeForEachService: false,
      serviceList: Object.keys(services),
      formData: {
        allServices: {
          customProperties: [],
        },
        [services.query]: {
          customProperties: [],
        },
        [services.ingest]: {
          customProperties: [],
        },
        [services.exporter]: {
          customProperties: [],
        },
      },
      parameterDefinitions: {
        connectionTimeout: {
          label: this.$t("connection.Connection Timeout (ms)"),
          tooltip: this.$t("connection.Time to wait for a connection"),
        },
        idleTimeout: {
          label: this.$t("connection.Idle Timeout (ms)"),
          tooltip: this.$t(
            "connection.Time before an idle connection is closed"
          ),
        },
        keepaliveTime: {
          label: this.$t("connection.Keep Alive Time (ms)"),
          tooltip: this.$t("connection.Time to keep the connection alive"),
        },
        maxLifetime: {
          label: this.$t("connection.Max Life Time (ms)"),
          tooltip: this.$t("connection.Maximum lifetime of a connection"),
        },
        maximumPoolSize: {
          label: this.$t("connection.Max Pool Size"),
          tooltip: this.$t(
            "connection.Maximum number of connections in the pool"
          ),
        },
        minimumIdle: {
          label: this.$t("connection.Min Idle Size"),
          tooltip: this.$t(
            "connection.Minimum number of idle connections in the pool"
          ),
        },
      },
    };
  },
  async mounted() {
    await this.fetchConnectionDefaults();

    this.formData = {
      allServices: {
        ...cloneDeep(this.connectionDefaults),
        customProperties: [],
      },
      [services.query]: {
        ...cloneDeep(this.connectionDefaults),
        customProperties: [],
      },
      [services.ingest]: {
        ...cloneDeep(this.connectionDefaults),
        customProperties: [],
      },
      [services.exporter]: {
        ...cloneDeep(this.connectionDefaults),
        customProperties: [],
      },
    };

    if (this.poolProperties) {
      // if poolProperties contains key services.query, services.ingest, services.exporter, then set formData[service] to poolProperties[service]
      // and set customizeForEachService to true

      if (
        Object.keys(this.poolProperties).some((service) => services[service])
      ) {
        this.customizeForEachService = true;

        Object.keys(this.formData).forEach((service) => {
          if (this.poolProperties[service]) {
            this.formData[service] = cloneDeep(this.poolProperties[service]);
          }
        });
      } else {
        this.formData.allServices = cloneDeep(this.poolProperties);
      }
    }
  },
  computed: {
    ...mapGetters({
      connectionDefaults: GETTER.GET_CONNECTION_DEFAULTS,
    }),
  },
  methods: {
    ...mapActions({
      fetchConnectionDefaults: ACTION.FETCH_CONNECTION_DEFAULTS,
    }),
    addParameter(service) {
      this.formData[service].customProperties.push({ key: "", value: "" });
    },
    removeParameter(service, index) {
      this.formData[service].customProperties.splice(index, 1);
    },
    // use this method from parent component to get form data
    getFormData() {
      if (this.customizeForEachService) {
        // eslint-disable-next-line no-unused-vars
        const { allServices, ...rest } = this.formData;

        return rest;
      } else {
        return this.formData.allServices;
      }
    },
    testConnection() {
      this.$emit("testConnection");
    },
    saveConnection() {
      this.$emit("saveConnection");
    },
  },
};
</script>

<style scoped>
.connection-pool-settings {
  padding: 20px;
}
.connection-pool-settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.connection-pool-settings-form-section {
  margin-bottom: 20px;
}
.connection-pool-settings-key-value-section {
  margin-bottom: 20px;
}
.connection-pool-settings-key-value-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.connection-pool-settings-form-item-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.connection-pool-settings-header-title {
  color: #404040;
  font: normal normal bold 13px/20px Poppins;
}
.connection-pool-settings-add-parameter {
  color: #1c64ff !important;
}
.connection-pool-settings-connection-pool-settings-add-parameter-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.connection-pool-settings-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.connection-pool-settings-footer-left-button {
  margin-right: auto;
}
.connection-pool-settings-footer-right-buttons {
  display: flex;
}
::v-deep .el-switch__label.is-active {
  color: #1c64ff;
}
</style>
