/* eslint-disable */
import { ACTION, STATE, MUTATION, GETTER } from "./types";
import Vue from "vue";
import { MUTATION_GENERAL } from "../General/types";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";

const initialPagination = { number: 0, totalPages: 0, numberOfElements: 0 };

export const visUploadImages = {
  state: {
    uploadImages: [],
    [STATE.SEARCH_IMAGES_RESULTS]: [],
    [STATE.SEARCH_IMAGES_PAGINATION]: initialPagination,
  },
  getters: {
    [GETTER.GET_IMAGES]: (state) => {
      return state[STATE.SEARCH_IMAGES_RESULTS];
    },
    [GETTER.GET_IMAGES_PAGINATION]: (state) => {
      return state[STATE.SEARCH_IMAGES_PAGINATION];
    },
  },
  actions: {
    [ACTION.FETCH_IMAGES]: async (
      { commit },
      { loadingComponent, queryParam }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.UploadImages,
        serviceMethodSub: serviceMethodSub.readUploadImages,
        queryParam,
        successMutation: MUTATION.SET_SEARCH_IMAGES_RESULTS,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent,
      });
    },
    [ACTION.CREATE_IMAGES]: async ({ commit }, bodyParam) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.UploadImages,
        serviceMethodSub: serviceMethodSub.createUploadImages,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.UPDATE_IMAGES]: async ({ commit }, { bodyParam, queryParam }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.UploadImages,
        serviceMethodSub: serviceMethodSub.updateUploadImages,
        bodyParam,
        queryParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.DELETE_IMAGES]: async ({ commit }, imageId) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.UploadImages,
        serviceMethodSub: serviceMethodSub.deleteUploadImages,
        queryParam: imageId,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });

      commit(MUTATION.REMOVE_IMAGE_FROM_RESULTS, imageId);
    },
    [ACTION.FETCH_IMAGE_BY_ID]: async (
      { commit },
      { imageId, loadingComponent }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.UploadImages,
        serviceMethodSub: serviceMethodSub.readUploadImageById,
        queryParam: imageId,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: false,
        loadingComponent,
      });
    },
    [ACTION.FETCH_IMAGE_THUMBNAIL]: async (
      { commit },
      { imageId, loadingComponent }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.UploadImages,
        serviceMethodSub: serviceMethodSub.readThumbnailUploadImage,
        queryParam: imageId,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent,
      });
    },
    [ACTION.FETCH_IMAGE_BASE64]: async ({ commit }, { imageId }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.UploadImages,
        serviceMethodSub: serviceMethodSub.readImageBase64,
        queryParam: imageId,
        resultSelector: (result) => result.data,
        withLoading: true
      });
    },
  },
  mutations: {
    [MUTATION.SET_IMAGES](state, payload) {
      Vue.set(state, "uploadImages", payload);
    },
    [MUTATION.SET_SEARCH_IMAGES_RESULTS](state, items) {
      const allItems = [...state[STATE.SEARCH_IMAGES_RESULTS], ...items.content];
      //State içerisinden ve tekrar atılan get den gelen itemlerde tekrar edilme durumu kontrol ediliyor!!
      const uniqueAllItems = Array.from(
        new Map(allItems.map((item) => [item.id, item])).values()
      );

      const pagination = {
        number: items.number,
        totalPages: items.totalPages,
        numberOfElements: items.numberOfElements,
      };
      Vue.set(state, [STATE.SEARCH_IMAGES_RESULTS], uniqueAllItems);
      Vue.set(state, [STATE.SEARCH_IMAGES_PAGINATION], pagination);
    },
    [MUTATION.REMOVE_IMAGE_FROM_RESULTS](state, imageId) {
      const updatedResults = state[STATE.SEARCH_IMAGES_RESULTS].filter(
        (item) => item.id !== imageId
      );

      Vue.set(state, [STATE.SEARCH_IMAGES_RESULTS], updatedResults);
    },
    [MUTATION.RESET_SEARCH_IMAGES_RESULTS](state) {
      Vue.set(state, [STATE.SEARCH_IMAGES_RESULTS], []);
    },
  },
};
