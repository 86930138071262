export const TEXT_EDITOR_DEFAULT_CONFIGURATIONS = {
  TEXT_EDITOR_DYNAMIC_TITLE_VALUE: "",
  TEXT_EDITOR_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
  TEXT_EDITOR_DYNAMIC_TITLE_SHOW_VALUE: false,
  TEXT_EDITOR_GROUP_MULTIPLE_VALUES: true,
  TEXT_EDITOR_MAX_IMAGE_COUNT: 1,
  TEXT_EDITOR_MAX_IMAGE_SIZE: 200,
  TEXT_EDITOR_SHOW_IMAGE: true,
  TEXT_EDITOR_DATA_FROM_DATABASE: false
};

export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
  return typeof value != "undefined" ? value : computedValue;
};