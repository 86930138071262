<template>
  <div>
    <h3 class="create-and-edit-connection-title">
      {{
        connectionItem
          ? $t("connection.Edit Connection")
          : $t("connection.Create Connection")
      }}
    </h3>
    <el-tabs v-model="selectedTab">
      <el-tab-pane :label="standardTab.label" :name="standardTab.name">
        <div class="vis-col vis-col-6">
          <el-radio-group
            class="vis-mb--1 vis-pl--1"
            v-model="selectedTabValue"
          >
            <el-radio
              v-for="tab in connectionTypeFilter"
              :key="tab.value"
              :label="tab.value"
              @change="setTabValue(tab.value)"
              >{{ $t(tab.label) }}</el-radio
            >
          </el-radio-group>
        </div>
        <BasicConnection
          v-show="selectedTabValue == selectDatabaseConnectionType.BASIC"
          ref="basicConnection"
          :basicConnectionData="basicConnectionData"
          :connectionItem="connectionItem"
          :saveConnectionLoading="saveConnectionLoading"
          :testConnectionLoading="testConnectionLoading"
          :connectionTypes="connectionTypes"
          @saveConnection="saveConnection"
          @testConnection="testConnection" />
        <CustomConnection
          v-show="selectedTabValue == selectDatabaseConnectionType.CUSTOM"
          ref="customConnection"
          :customConnectionData="customConnectionData"
          :connectionItem="connectionItem"
          :saveConnectionLoading="saveConnectionLoading"
          :testConnectionLoading="testConnectionLoading"
          :connectionTypes="connectionTypes"
          @saveConnection="saveConnection"
          @testConnection="testConnection"
      /></el-tab-pane>
      <el-tab-pane :label="advancedTab.label" :name="advancedTab.name">
        <div class="vis-col vis-col-6">
          <ConnectionPoolSettings
            ref="connectionPoolSettings"
            :poolProperties="connectionItem?.poolProperties"
            @back="selectedTab = standardTab.name"
            @testConnection="testConnection"
            @saveConnection="saveConnection"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  SELECT_DATABASE_CONNECTION_TYPE,
  SELECT_DATABASE_CONNECTION_TYPE_LIST,
} from "./SelectDatabaseConnection.config.js";
import BasicConnection from "./BasicConnection.vue";
import CustomConnection from "./CustomConnection.vue";
import ConnectionPoolSettings from "./ConnectionPoolSettings.vue";

const tabs = {
  standard: "standard",
  advanced: "advanced",
};

export default {
  components: {
    BasicConnection,
    CustomConnection,
    ConnectionPoolSettings,
  },
  props: {
    connectionItem: {
      type: Object,
      default: () => null,
    },
    testConnectionLoading: {
      type: Boolean,
      default: false,
    },
    saveConnectionLoading: {
      type: Boolean,
      default: false,
    },
    connectionTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      standardTab: {
        label: this.$t("connection.Standard"),
        name: tabs.standard,
      },
      advancedTab: {
        label: this.$t("connection.Advanced"),
        name: tabs.advanced,
      },
      selectedTab: tabs.standard,
      selectedTabValue: SELECT_DATABASE_CONNECTION_TYPE.BASIC,
      connectionTypeFilter: SELECT_DATABASE_CONNECTION_TYPE_LIST,
      selectDatabaseConnectionType: SELECT_DATABASE_CONNECTION_TYPE,
      basicConnectionData: {},
      customConnectionData: {},
    };
  },
  mounted() {
    if (this.connectionItem && this.connectionItem.isCustomURL) {
      this.selectedTabValue = SELECT_DATABASE_CONNECTION_TYPE.CUSTOM;
    }
  },
  methods: {
    getConnectionFormAndJdbcURL() {
      let connectionFormAndJdbcURL = {};

      if (this.selectedTabValue === SELECT_DATABASE_CONNECTION_TYPE.BASIC) {
        connectionFormAndJdbcURL =
          this.$refs.basicConnection.getConnectionFormAndJdbcURL();
      } else {
        connectionFormAndJdbcURL =
          this.$refs.customConnection.getConnectionFormAndJdbcURL();
      }

      return connectionFormAndJdbcURL;
    },
    testConnection() {
      let connectionFormAndJdbcURL = this.getConnectionFormAndJdbcURL();

      this.$emit("testConnection", {
        ...connectionFormAndJdbcURL.connectionForm,
        jdbcURL: connectionFormAndJdbcURL.jdbcURL,
        poolProperties: this.$refs.connectionPoolSettings.getFormData(),
      });
    },
    saveConnection() {
      let connectionFormAndJdbcURL = this.getConnectionFormAndJdbcURL();

      this.$emit("saveConnection", {
        ...connectionFormAndJdbcURL.connectionForm,
        jdbcURL: connectionFormAndJdbcURL.jdbcURL,
        poolProperties: this.$refs.connectionPoolSettings.getFormData(),
      });
    },
    setTabValue(value) {
      this.selectedTabValue = value;
    },
  },
};
</script>

<style scoped>
::v-deep .el-tabs__header {
  margin-left: 30px;
  margin-bottom: 30px;
}
.create-and-edit-connection-title {
  font: normal normal bold 26px/39px Poppins;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
  padding-top: 16px;
  padding-left: 28px;
}
</style>
