<template>
  <div>
    <div class="data-model-sql-editor-header">
      <span>SQL</span>
      <div class="data-model-sql-editor-header-action-buttons">
        <i
          :class="CustomIcon.Play"
          aria-hidden="true"
          @click="$emit('runSql', sql)"
        />
        <el-tooltip
          :disabled="hasDatasetName"
          :content="$t('datamodel.datasetNameEmpty')"
          effect="light"
        >
          <i
            v-if="sqlSaveAction"
            :class="[
              CustomIcon.SaveOutlined,
              { 'vis-disable-opacity': !hasDatasetName },
            ]"
            aria-hidden="true"
            @click="$emit('saveSql', sql)"
          />
        </el-tooltip>
        <i :class="CustomIcon.Copy" aria-hidden="true" @click="copySql" />
        <i
          :class="CustomIcon.Close"
          aria-hidden="true"
          @click="$emit('close')"
        />
      </div>
    </div>
    <CodeMirrorSql
      class="data-model-sql-editor"
      :connectionId="connection.connectionId"
      :sql.sync="sql"
      :textToBeAddedToSql="textToBeAddedToSql"
      @resetTextToBeAddedToSql="$emit('resetTextToBeAddedToSql')"
    />
  </div>
</template>

<script>
import CodeMirrorSql from "../../helper/CodeMirrorSql";
import { CustomIcon } from "../../../assets/js/custom-icons";
import { Notify } from '../../../commons/helper';
import { notificationType } from "../../../commons/notificationTypes";
import { copyToClipboard } from '../../../util/copyClipboard';

export default {
  components: { CodeMirrorSql },
  props: {
    textToBeAddedToSql: {
      type: String,
      default: "",
    },
    connection: {
      type: Object,
      default: null,
    },
    datasetName: {
      type: String,
      default: "",
    },
    sqlSaveAction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sql: "",
      properties: [],
      CustomIcon: CustomIcon,
    };
  },
  methods: {
    copySql() {
      copyToClipboard(this.sql);
      Notify(
        this.$t("notifyTitle.copiedToClipboard"),
        notificationType.SUCCESS
      );
    },
  },
  computed: {
    hasDatasetName() {
      return this.datasetName != "";
    },
  },
  beforeDestroy() {
    this.$emit("resetTextToBeAddedToSql");
  },
};
</script>

<style scoped>
.data-model-sql-editor-header {
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 600;
  font-size: 0.875rem;
}

.data-model-sql-editor-header-action-buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.data-model-sql-editor-header-action-buttons i {
  cursor: pointer;
}
.data-model-sql-editor-header-action-buttons i:not(:last-child) {
  margin-right: 1rem;
}
.data-model-sql-editor {
  border: 1px solid #e4e3e8;
  border-right: none;
  border-left: none;
}
</style>
