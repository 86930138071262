<template>
  <div class="vis-sql-card">
    <div class="vis-sql-title vis-flex--spacebeetwen-Ycenter">
      <span>{{ $t("panelProperties.sqlStatement") }}</span>
      <div class="vis-flex--Ycenter">
        <i
          @click="copySql"
          aria-hidden="true"
          :class="customIcon.Copy"
          class="vis-cursor-pointer vis-mr--1"
        />
        <i
          @click="$emit('closePopup')"
          aria-hidden="true"
          :class="customIcon.Close"
          class="vis-cursor-pointer"
        ></i>
      </div>
    </div>
    <!-- vis-sql-title-end -->
    <div class="vis-sql-content">
      <div class="vis-mb--1">
        <span class="vis-sql-text"><pre><code>{{ formattedSqlQuery }}</code></pre></span>
      </div>
    </div>
  </div>
</template>
<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import { mapGetters } from "vuex";
import { GETTER as GETTER_TEST_QUERY } from "../../store/modules/Visualize/TestQuery/types";
import { format } from 'sql-formatter';
import { DB_TYPE } from '../../commons/connection';
import { Notify } from '../../commons/helper';
import { notificationType } from "../../commons/notificationTypes";
import { copyToClipboard } from '../../util/copyClipboard';

export default {
  props: {
    sqlQuery: {
      type: String,
    },
    selectedDbType: {
      type: String,
    }
  },
  data() {
    return {
      customIcon: CustomIcon,
      formattedSqlQuery: '',
    };
  },
  methods: {
    copySql() {
      copyToClipboard(this.formattedSqlQuery);
      Notify(
        this.$t("notifyTitle.copiedToClipboard"),
        notificationType.SUCCESS
      );
    },
  },
  computed: {
    ...mapGetters({
      getSearch: GETTER_TEST_QUERY.GET_SEARCH_RESULTS,
    }),
  }, 
  mounted() {
    const dbTypeMap = {
      [DB_TYPE.ORACLE]: 'plsql',
      [DB_TYPE.MYSQL]: 'mysql',
      [DB_TYPE.TRINO]: 'trino',
    };

    const type = dbTypeMap[this.selectedDbType];

    if (type) {
      this.formattedSqlQuery = format(this.sqlQuery, {language: type});
    } else {
      this.formattedSqlQuery = format(this.sqlQuery, {language: 'postgresql'})
    }
  },
};
</script>
<style scoped>
.vis-sql-card {
  position: relative;
  width: 650px;
  height: 500px;
  border: 1px solid var(--layout-border-color);
  box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
}

.vis-sql-title {
  position: relative;
  width: 100%;
  height: 40px;
  padding: 10px;
  font-weight: 500;
  border-bottom: 2px solid var(--primary-lighteen-1);
}
.vis-sql-content {
  position: relative;
  width: 100%;
  padding: 10px 10px 0 10px;
  height: 450px;
  max-height: 500px;
  overflow: auto;
}
.vis-sql-text {
  word-wrap: break-word;
}
</style>
