import cloneDeep from "clone-deep";
import { createSmartTitle } from "../util/panelDataPropertiesStyle";
import { checkTypeSelectorDataItem, DatamodelContextDefaults, datamodelPropertyKeys, getCommonDatamodelPropertyValueByKey } from "./dataModelTypes";
import { filterType } from "./filterComponents";
import { CustomIcon } from "../assets/js/custom-icons";
import i18n from "../i18n";
import { chartPropertiesEnum, dashboardGridPropertiesEnum } from "./dashboardProperties";
import { DASHBOARD_GRID_DEFAULT_CONFIGURATIONS } from "../mocks/dashboardGridDefaultProperties";
import { filterSources } from "../components/data/sql/types";

export const chartTypes = {
  TABLE: "DATA_TABLE",
  PIE: "PIE_CHART",
  BAR: "BAR_CHART",
  COLUMN_CHART: "COLUMN_CHART",
  LABEL: "LABEL_CHART",
  LINE_CHART: "LINE_CHART",
  LOGO: "LOGO_CHART",
  TREEMAP_CHART: "TREEMAP_CHART",
  PIVOT_TABLE: "PIVOT_TABLE",
  BUBBLE_CHART: "BUBBLE_CHART",
  HEATMAP_CHART: "HEATMAP_CHART",
  AREA_CHART: "AREA_CHART",
  SCATTER_CHART: "SCATTER_CHART",
  COMBINATION_CHART: "COMBINATION_CHART",
  STACKED_COLUMN_CHART: "STACKED_COLUMN_CHART",
  STACKED_BAR_CHART: "STACKED_BAR_CHART",
  SUMMARY: "SUMMARY_CHART",
  OTHER: "OTHER",
  GAUGE_CHART: "GAUGE_CHART",
  RADAR_CHART: "RADAR_CHART",
  GEO_MAP_CHART: "GEO_MAP_CHART",
  TAB_PANEL: "TAB_PANEL",
  SANKEY_CHART: "SANKEY_CHART",
  SELECTOR_DATA_ITEM_CHART: "SELECTOR_DATA_ITEM_CHART"
};

export const seriesTypeWithoutColorBy = {
  MAP: "map",
  TREEMAP: "treemap",
  HEATMAP: "heatmap"
};

export const seriesTypeRadar = {
  RADAR: "radar",
}

export const seriesTypeSankey = "sankey";

export const seriesTypeScatter = {
  SCATTER: "scatter",
}

export const panelTypes = {
  CHART: "chart",
  LOGO: "logo",
  SUMMARY: "summary",
  FILTER: "filter",
  TABLE: "table"
};

export const detailsKeys = {
  LOGO: "logo",
  METRICS: "metrics",
  AGGREGATION: "aggregation",
  COLUMNS: "columns",
  SECTIONS: "sections",
  FILTERS: "filters",
  ORDERS: "orders",
  PARAMS: "params",
  ISFILTER_PANE_CHART: "isFilterPaneChart",
  SERVERSIDE_FILTERS: "serversideFilters",
  CUSTOM_FILTERS: "customFilters",
  SELECTOR_PARAMETERS: "selectorParameters",
  selectorDataitemValue: "selectorDataitemValue",
  CONTENT: "content"
};
export const collapseName = {
  CHARTS: "CHARTS",
  FILTERS: "FILTERS",
  OTHER: "OTHER",
};
export const onPropertiesEventOptions = {
  openConditionalFormatting: "openConditionalFormatting",
  refreshCache: "refreshCache",
  imagesPopup: "imagesPopup"
};
export const chartList = [
  { id: chartTypes.COLUMN_CHART, icon: "bar-column-chart", name: "generalPages.column" },
  {
    id: chartTypes.STACKED_COLUMN_CHART,
    icon: "stacked-column-chart",
    name: "generalPages.stackedColumn",
  },
  { id: chartTypes.BAR, icon: "bar-chart", name: "generalPages.bar" },
  {
    id: chartTypes.STACKED_BAR_CHART,
    icon: "stacked-bar-chart",
    name: "generalPages.stackedBar",
  },
  { id: chartTypes.LINE_CHART, icon: "line-chart", name: "generalPages.line" },
  { id: chartTypes.AREA_CHART, icon: "area-chart", name: "generalPages.area" },
  { id: chartTypes.PIE, icon: "pie-chart", name: "generalPages.pie" },
  { id: chartTypes.TREEMAP_CHART, icon: "treemap-chart", name: "generalPages.treeMap" },
  {
    id: chartTypes.COMBINATION_CHART,
    icon: "combination-chart",
    name: "generalPages.combination",
  },
  { id: chartTypes.HEATMAP_CHART, icon: "heatmap-chart", name: "generalPages.heatMap" },
  { id: chartTypes.SCATTER_CHART, icon: "scatter-chart", name: "generalPages.scatter" },
  { id: chartTypes.BUBBLE_CHART, icon: "bubble-chart", name: "generalPages.bubble" },
  { id: chartTypes.GAUGE_CHART, icon: "gauge-chart", name: "generalPages.gauge" },
  { id: chartTypes.TABLE, icon: "table-chart", name: "generalPages.table" },
  { id: chartTypes.PIVOT_TABLE, icon: "pivot-table", name: "generalPages.pivot" },
  { id: chartTypes.SUMMARY, icon: "summary-chart", name: "generalPages.summary" },
  {
    id: chartTypes.RADAR_CHART,
    icon: "radar-chart",
    name: "generalPages.radar",
  },
  {
    id: chartTypes.GEO_MAP_CHART,
    icon: "geo-map-chart",
    name: "generalPages.geoMap",
  },
  {
    id: chartTypes.TAB_PANEL,
    icon: "tab-panel",
    name: "Tab Panel",
  },
  {
    id: chartTypes.SANKEY_CHART,
    icon: "sankey-chart",
    name: "Sankey Chart"
  }
];

export const dashboardFilterTypes = {
  FILTER_TEXT: "FILTER_TEXT",
  FILTER_DROPDOWN: "FILTER_DROPDOWN",
  FILTER_NUMBER: "FILTER_NUMBER",
  FILTER_DATE: "FILTER_DATE",
  FILTER_SELECT_LIST: "FILTER_SELECT_LIST",
  ADVANCED_BUTTON: "ADVANCED_BUTTON",
};
export const otherChartType = {
  BUTTON: "BUTTON",
  TEXT_EDITOR: "TEXT_EDITOR",
};
export const notSearchServiceFilterTypes = [
  { value: dashboardFilterTypes.FILTER_TEXT },
  { value: dashboardFilterTypes.FILTER_NUMBER },
  { value: dashboardFilterTypes.FILTER_DATE },
  { value: chartTypes.SELECTOR_DATA_ITEM_CHART }
];

export const chartWithNotSeeFilterIcon = [
  chartTypes.LOGO,
  chartTypes.LABEL,
  dashboardFilterTypes.FILTER_TEXT,
  dashboardFilterTypes.FILTER_DATE,
  otherChartType.BUTTON,
  chartTypes.SELECTOR_DATA_ITEM_CHART
];

export const otherList = () => {
  let check = process.env.VUE_APP_CALL_API
  return [
    { id: chartTypes.LABEL, icon: "text-filter", name: "generalPages.labelText" },
    { id: chartTypes.LOGO, icon: "logo-chart", name: "generalPages.images" },
    {
      id: otherChartType.BUTTON,
      icon: "button-filter",
      name: "generalPages.button",
    },
    check === "true" ?
      {
        id: dashboardFilterTypes.ADVANCED_BUTTON,
        icon: "button-filter",
        name: "generalPages.advancedButton",
      } : null,
      {
        id: otherChartType.TEXT_EDITOR,
        icon: "text-editor",
        name: "generalPages.Rich Text",
      },
      { id: chartTypes.SELECTOR_DATA_ITEM_CHART, icon: "selector-chart", name: "generalPages.Dynamic Item" },
  ].filter((x) => x);
};

export const filterList = [
  {
    id: dashboardFilterTypes.FILTER_TEXT,
    icon: "text-filter",
    name: "generalPages.textInput",
  },
  {
    id: dashboardFilterTypes.FILTER_DROPDOWN,
    icon: "dropdown-filter",
    name: "generalPages.dropDown",
  },
  // {
  //     id: dashboardFilterTypes.FILTER_NUMBER,
  //     icon: "slider-filter",
  //     name: ""
  // },
  {
    id: dashboardFilterTypes.FILTER_SELECT_LIST,
    icon: "selectList-filter",
    name: "generalPages.selectList",
  },
  {
    id: dashboardFilterTypes.FILTER_DATE,
    icon: "date-filter",
    name: "generalPages.calendar",
  },
];

export const logos = {
  CUMHURBASKANLIGI: "cumhurbaskanligi-logo",
  TICARETBAKANLIGI: "ticaret-bakanligi-logo",
  IS: "is-bankasi-logo",
  SOFTTECH: "softtech-logo",
  ANADOLU: "anadolu-sigorta-logo",
  ECOMMERCE: "e-comm-logo",
  ISYATIRIM: "is-yatirim-logo",
  HAVELSAN: "havelsan-logo",
  ISFAKTORING: "is-faktoring-logo",
  MOKA: "moka-logo",
  EMTIA: "emtia-logo",
  BORUSAN: "borusan-logo",
  EFESANGRUP: "efesan-grup-logo",
  KAYSERISEKER: "kayseri-seker-logo",
  AZERSUN: "azersun-logo",
  SOFTTECHSEMBOL: "softtech-sembol-logo",
  SMARTDISPERSE: "smartdisperse-logo"
};

export const defaultLogo = "default-logo";


export const BOX_KEY_ENUM = {
  MEASURES: { KEY: "measures", TEXT: "Measures" },
  SLICES: { KEY: "slices", TEXT: "Slices" },
  CITY: { KEY: "city", TEXT: "City" },
  DISTRICT: { KEY: "district", TEXT: "District" },
  COLUMN: { KEY: "column", TEXT: "Columns" },
  ROW: { KEY: "row", TEXT: "Rows" },
  POINTS: { KEY: "points", TEXT: "Points" },
  XAXIS: { KEY: "x-axis", TEXT: "X-Axis" },
  YAXIS: { KEY: "y-axis", TEXT: "Y-Axis" },
  POINT_SIZE: { KEY: "point_size", TEXT: "Point Size" },
  DATA_ITEM: { KEY: "data_item", TEXT: "Data Items" },
  BARS: { KEY: "bars", TEXT: "Bars" },
  LINES: { KEY: "lines", TEXT: "Lines" },
  MIN: { KEY: "min", TEXT: "Min" },
  MAX: { KEY: "max", TEXT: "Max" },
  BRANCHES: { KEY: "branches", TEXT: "Branches" },
  COLORBY: { KEY: "colorBy", TEXT: "Color By" },
  CUSTOM_FILTERS: { KEY: "customFilter", TEXT: "Custom Filters" },
  SECTION: { KEY: "section", TEXT: "Section" },
  FILTERS: { KEY: "filters", TEXT: "Filters" },
  ORDERS: { KEY: "orders", TEXT: "Orders" },
  TARGET: { KEY: "target", TEXT: "Target" },
  SELECTOR: { KEY: "selector", TEXT: "Selector" },
  CONTENT: { KEY: "content", TEXT: "Content" },
};
const commonFilterChartRules = {
  maxMeasureCount: 1,
  maxSliceCount: 1,
  boxKeys: [BOX_KEY_ENUM.DATA_ITEM.KEY],
  isMetricRequired: true
}
export const chartRules = {
  [chartTypes.TREEMAP_CHART]: {
    maxMeasureCount: 1,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.BRANCHES.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    isMetricRequired: true
  },
  [chartTypes.LINE_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.POINTS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.POINTS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.AREA_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.POINTS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.POINTS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.PIE]: {
    maxMeasureCount: 1,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.SLICES.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.SLICES.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.BAR]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.BARS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.BARS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.COLUMN_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.BARS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.BARS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.HEATMAP_CHART]: {
    maxMeasureCount: 1,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.COLUMN.KEY, BOX_KEY_ENUM.ROW.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    isMetricRequired: true
  },
  [chartTypes.SCATTER_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.POINTS.KEY, BOX_KEY_ENUM.XAXIS.KEY, BOX_KEY_ENUM.YAXIS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.XAXIS.KEY, BOX_KEY_ENUM.YAXIS.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.XAXIS.TEXT, BOX_KEY_ENUM.YAXIS.TEXT, BOX_KEY_ENUM.POINTS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT],
    isMetricRequired: true
  },
  [chartTypes.BUBBLE_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.POINTS.KEY, BOX_KEY_ENUM.XAXIS.KEY, BOX_KEY_ENUM.YAXIS.KEY, BOX_KEY_ENUM.POINT_SIZE.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.XAXIS.KEY, BOX_KEY_ENUM.YAXIS.KEY, BOX_KEY_ENUM.POINT_SIZE.TEXT],
    tooltipKeyOrder: [BOX_KEY_ENUM.XAXIS.TEXT, BOX_KEY_ENUM.YAXIS.TEXT, BOX_KEY_ENUM.POINTS.TEXT, BOX_KEY_ENUM.POINT_SIZE.TEXT, BOX_KEY_ENUM.COLORBY.TEXT],
    isMetricRequired: true
  },
  [chartTypes.COMBINATION_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.BARS.KEY, BOX_KEY_ENUM.LINES.KEY, BOX_KEY_ENUM.SLICES.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.BARS.KEY, BOX_KEY_ENUM.LINES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.SLICES.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.BARS.TEXT, BOX_KEY_ENUM.LINES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.GAUGE_CHART]: {
    maxMeasureCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.MIN.KEY, BOX_KEY_ENUM.MAX.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.MIN.KEY, BOX_KEY_ENUM.MAX.KEY],
    isMetricRequired: true
  },
  [chartTypes.RADAR_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.SLICES.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.SLICES.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.STACKED_COLUMN_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.BARS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.BARS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.STACKED_BAR_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.BARS.KEY, BOX_KEY_ENUM.COLORBY.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.BARS.TEXT, BOX_KEY_ENUM.COLORBY.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.SANKEY_CHART]: {
    maxMeasureCount: 5,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.BARS.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.BARS.TEXT, BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [chartTypes.PIVOT_TABLE]: {
    maxMeasureCount: 20,
    maxSliceCount: 5,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.COLUMN.KEY, BOX_KEY_ENUM.ROW.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.MEASURES.TEXT, BOX_KEY_ENUM.COLUMN.TEXT, BOX_KEY_ENUM.ROW.TEXT],
    isMetricRequired: true
  },
  [chartTypes.TABLE]: {
    isMetricRequired: false,
  },
  [chartTypes.GEO_MAP_CHART]: {
    maxMeasureCount: 1,
    maxSliceCount: 2,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.CITY.KEY, BOX_KEY_ENUM.DISTRICT.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.MEASURES.TEXT, BOX_KEY_ENUM.CITY.TEXT, BOX_KEY_ENUM.DISTRICT.TEXT],
    isMetricRequired: true
  },
  [chartTypes.SUMMARY]: {
    maxMeasureCount: 2,
    maxSliceCount: 0,
    boxKeys: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.TARGET.KEY],
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY, BOX_KEY_ENUM.TARGET.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.MEASURES.TEXT, BOX_KEY_ENUM.TARGET.KEY],
    isMetricRequired: true
  },
  [otherChartType.TEXT_EDITOR]: {
    boxKeys: [BOX_KEY_ENUM.CONTENT.KEY],
    fieldTypesToUse: [DatamodelContextDefaults.USAGE_TYPES.MEASURE, DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE],
    maxMeasureCount: 20, //TEXT EDITOR componentinde watch kısmında, metrics için özelliğe göre tekrar kontrol yapılıyor
    maxSliceCount: 0,
    metricSlots: [BOX_KEY_ENUM.MEASURES.KEY],
    tooltipKeyOrder: [BOX_KEY_ENUM.MEASURES.TEXT],
    isMetricRequired: true
  },
  [dashboardFilterTypes.FILTER_SELECT_LIST]: {
    ...commonFilterChartRules,
    fieldTypesToUse: [DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE, DatamodelContextDefaults.USAGE_TYPES.DATE, DatamodelContextDefaults.USAGE_TYPES.MEASURE],
  },
  [dashboardFilterTypes.FILTER_DROPDOWN]: {
    ...commonFilterChartRules,
    fieldTypesToUse: [DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE, DatamodelContextDefaults.USAGE_TYPES.MEASURE, DatamodelContextDefaults.USAGE_TYPES.DATE],
  },
  [dashboardFilterTypes.FILTER_TEXT]: {
    ...commonFilterChartRules,
    fieldTypesToUse: [DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE, DatamodelContextDefaults.USAGE_TYPES.MEASURE, DatamodelContextDefaults.USAGE_TYPES.DATE],
  },
  [dashboardFilterTypes.FILTER_DATE]: {
    ...commonFilterChartRules,
    fieldTypesToUse: [DatamodelContextDefaults.USAGE_TYPES.DATE],
  },
}

export const getCommonsFieldProperty = (fieldData) => {
    const result = {
        fieldId: fieldData?.fieldId ? fieldData?.fieldId : fieldData?.id || fieldData?.paramId,
        field: fieldData?.name || fieldData?.field,
        alias: fieldData?.alias,
        datasetId: fieldData?.datasetId,
        fieldType: fieldData.fieldType || fieldData.type,
        isAuthorize: fieldData?.isAuthorize ?? true
    };

    if (fieldData?.filterSource === filterSources.DATAMODEL) {
        result.filterSource = filterSources.DATAMODEL;
    }

    return result;
};

export const checkIfDatasetFieldSame = (selectedPanel, field) => {
  const boxList = [
    ...selectedPanel.details[detailsKeys.METRICS],
    ...selectedPanel.details[detailsKeys.AGGREGATION],
  ];

  const hasDataset = boxList.find((x) => x.datasetId == field.datasetId);

  if (hasDataset || field.filterType == filterType.CUSTOM || !boxList.length) {
    return true;
  } else return false;
}

export const getAggregationField = (field, boxKey) => {
  return {
    ...getCommonsFieldProperty(field),
    size: 5,
    orderType: null,
    fieldUsageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
    format: field?.format,
    boxKey: boxKey,
    defaultAggFunction: field?.defaultAggFunction,
    drillPaths: field?.drillPaths,
    isDrillDownAgg: true,
  }
}

export const getSelectedField = (fieldList, datasetId, fieldId) => {
  return fieldList[datasetId].find(x => x.fieldId == fieldId);
}

export const getFilteredPanelDataByAuthorize = ({chartData, unAuthorizedDashboardFields}) => {
  if (!chartData?.details) return chartData;
 
  const clonedChartData = cloneDeep(chartData);

  clonedChartData.details.metrics = getFilteredArrayByParam(clonedChartData.details.metrics, unAuthorizedDashboardFields);
  clonedChartData.details.aggregation = getFilteredArrayByParam(clonedChartData.details.aggregation, unAuthorizedDashboardFields);
  clonedChartData.details.columns = getFilteredArrayByParam(clonedChartData.details.columns, unAuthorizedDashboardFields);
  clonedChartData.details.filters = getFilteredArrayByParam(clonedChartData.details.filters, unAuthorizedDashboardFields);
  clonedChartData.details.params = getFilteredArrayByParam(clonedChartData.details.params, unAuthorizedDashboardFields);
  clonedChartData.details.sections = getFilteredArrayByParam(clonedChartData.details.sections, unAuthorizedDashboardFields);

  setTimeout(() => {
    if (clonedChartData?.properties?.smartTitle) {
      chartData.name = createSmartTitle(clonedChartData);
    }
  }, 500);
  
  return clonedChartData;
}

//this function using in dashboardSearch and panelSearch
export const getDetailsDataByAuthorize = ({chartDataDetails, unAuthorizedDashboardFields}) => {
  const clonedDetails = cloneDeep(chartDataDetails);

  clonedDetails.metric = getFilteredArrayByParam(clonedDetails.metric, unAuthorizedDashboardFields);
  clonedDetails.aggregation = getFilteredArrayByParam(clonedDetails.aggregation, unAuthorizedDashboardFields);
  clonedDetails.columns = getFilteredArrayByParam(clonedDetails.columns, unAuthorizedDashboardFields);
  clonedDetails.filter = getFilteredArrayByParam(clonedDetails.filter, unAuthorizedDashboardFields);
  clonedDetails.params = getFilteredArrayByParam(clonedDetails.params, unAuthorizedDashboardFields);
  clonedDetails.sections = getFilteredArrayByParam(clonedDetails.sections, unAuthorizedDashboardFields);

  return clonedDetails;
}

const getFilteredArrayByParam = (filteredArray, unAuthorizedDashboardFields) => {
  if (!filteredArray) return;

  return filteredArray?.filter((x) => !unAuthorizedDashboardFields.has(x.fieldId));
}

export const panelTools = {
  VISUALIZE_AI_TOOLBOX: "VISUALIZE_AI_TOOLBOX",
  CHART_PROPERTIES_TOOLBOX: "CHART_PROPERTIES_TOOLBOX"
};

export const checkChartMetricEmpty = ({ chartData, message, showMandatoryMessage }) => {
  const { metrics, aggregation, columns = [] } = chartData?.details;

  if (!metrics || !aggregation) return false;
  
  const tableWarning = showMandatoryMessage && chartData.type === chartTypes.TABLE && !columns?.length;

  if (showMandatoryMessage && (!metrics?.length && chartData.type !== chartTypes.TABLE) || tableWarning) {
    return {
      message: i18n.tc('notifyTitle.Please drop data items into mandatory slot'),
      icon: null
    } 
  }
 
  if (!columns) return;
  
  const hasSelectorDataItem = [...metrics, ...columns]?.find(x => checkTypeSelectorDataItem(x.fieldType));
  const metricFields = [...metrics, ...columns].filter(x=> !checkTypeSelectorDataItem(x.fieldType));
  
  if (hasSelectorDataItem && !metricFields?.length) {
      return {
        message,
        icon: CustomIcon.Information
      } 
  } else {
      return null;
  }
}

export const checkDuplicateValueInChartStyle = ({
  charts,
  key,
  value,
  prefix,
}) => {
  let newVal;

  for (let index = 0; index < charts.length; index++) {
    const checkControl = (value) => {
      const foundSelector = charts?.find(
        (x) => x?.properties?.style?.[key] === value
      );

      if (foundSelector?.properties?.style?.[key] === value) {
        newVal = `Selector Parameter ${prefix ?? ""}${index++}`;
        checkControl(newVal);
      }
    }

    checkControl(value);
  }

  return newVal ?? value;
};

export const getCacheInfoByPropertyStyle = ({ isConfigurationUseCacheActive, configurationDefaultCacheDurationInMinute, chart, datamodelProperties, dashboardPropertiesStyle, evictionId, shouldRefreshCache }) => {
  const chartPropertiesStyle = chart?.properties?.style;
 
  const selectedChartUseCache = chartPropertiesStyle?.[chartPropertiesEnum.CHART_USE_CACHE] ?? true;
  const selectedChartCacheDuration = chartPropertiesStyle?.[chartPropertiesEnum.CHART_CACHE_DURATION_INPUT];
  const selectedChartUseDefaultCacheDuration = chartPropertiesStyle?.[chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION] ?? true;

  const dashboardUseCache = dashboardPropertiesStyle?.[dashboardGridPropertiesEnum.DASHBOARD_USE_CACHE] ?? DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_USE_CACHE;
  const dashboardCacheDuration = dashboardPropertiesStyle?.[dashboardGridPropertiesEnum.DASHBOARD_CACHE_DURATION_INPUT];
  const dashboardUseDefaultCacheDuration = dashboardPropertiesStyle?.[dashboardGridPropertiesEnum.DASHBOARD_USE_DEFAULT_CACHE_DURATION] ?? DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_USE_DEFAULT_CACHE_DURATION;
  
  const datamodelUseCache = getCommonDatamodelPropertyValueByKey(datamodelProperties, datamodelPropertyKeys.DATAMODEL_USE_CACHE);
  const datamodelCacheDuration = getCommonDatamodelPropertyValueByKey(datamodelProperties, datamodelPropertyKeys.DATAMODEL_CACHE_DURATION);
  const datamodelUseDefaultCacheDuration = getCommonDatamodelPropertyValueByKey(datamodelProperties, datamodelPropertyKeys.DATAMODEL_USE_DEFAULT_CACHE_DURATION);
  const datamodelForceCacheDuration = getCommonDatamodelPropertyValueByKey(datamodelProperties, datamodelPropertyKeys.DATAMODEL_FORCE_CACHE_DURATION);

  //Son kontrolde ise chartın use cache değerine, yoksa dashboard'un, oda yoksa datamodel use cache göre hareket edilecek.
  const cacheInfo = {
    temporaryId: !chart?.id ? chart.i : "",
    evictionId: evictionId,
    shouldRefreshCache: shouldRefreshCache,
    useCache: null,
    duration: null
  }
  

  if (!isConfigurationUseCacheActive) {
     //Eğer admin sayfasında use cache false ise her yerde use cache false olacak.
    cacheInfo.useCache = isConfigurationUseCacheActive;
  } else if (!datamodelUseCache) {
    //Datamodel içinde use cache false ise o datamodel ile oluşturulmuş dashboard use cache false olacak.
    cacheInfo.useCache = datamodelUseCache;
  } else if (!dashboardUseCache) {
    //Dashboard içinde use cache false ise tüm chartların use cache false olacak.
    cacheInfo.useCache = dashboardUseCache;
  } else {
    //Ana hatlarda use cache true ise chart'a özel kapatılabilir olacak.
    cacheInfo.useCache = selectedChartUseCache ?? dashboardUseCache ?? datamodelUseCache;
  }

  //Eğer datamodel içindeki force cache duration açık ise bu kısım çalışır
  if (datamodelForceCacheDuration && datamodelUseDefaultCacheDuration) {
    cacheInfo.duration = configurationDefaultCacheDurationInMinute;
    return cacheInfo;
  } else if (datamodelForceCacheDuration && !datamodelUseDefaultCacheDuration) {
    cacheInfo.duration = datamodelCacheDuration;
    return cacheInfo;
  }
 
  //Eğer chart içindeki use default cache duration aktif ise dashboard duration'ı, yoksa datamodel duration kullanır. Aktif değil ise kendi duration değerini kullanır.
  if (selectedChartUseDefaultCacheDuration) {
    if (dashboardUseDefaultCacheDuration && datamodelUseDefaultCacheDuration) {
      cacheInfo.duration = configurationDefaultCacheDurationInMinute;
    } else if (dashboardUseDefaultCacheDuration && !datamodelUseDefaultCacheDuration) {
      cacheInfo.duration = datamodelCacheDuration;
    } else {
      cacheInfo.duration = dashboardCacheDuration;
    }
  } else {
    cacheInfo.duration = selectedChartCacheDuration ?? dashboardCacheDuration ?? datamodelCacheDuration;
  }

  return cacheInfo;
};