var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vis-control-section vis-text-editor vis-scroll-thin vis-flex-direction--column",class:{ 'vis-non-edit': !_vm.editMode }},[_c('div',{staticClass:"vis-text-editor",class:{
      'vis-non-editable-content': !_vm.editMode || _vm.dataFromDatabase,
      'popup-open': _vm.isPopupOpen,
      'vis-edit-content': _vm.editMode,
      'vis-datafromdatabase': _vm.dataFromDatabase,
    }},[_c('ejs-richtexteditor',{ref:"rteObj",attrs:{"id":"richTextEditor","value":_vm.richText,"change":_vm.changeRichText,"toolbarSettings":_vm.toolbarSettings,"showCharCount":false,"quickToolbarSettings":_vm.quickToolbarSettings,"enableTabKey":true,"placeholder":_vm.placeholder,"enableXhtml":true,"insertImageSettings":_vm.imageSettings,"beforeImageUpload":_vm.beforeImageUpload,"imageSelected":_vm.imageSelected,"disabled":!_vm.editMode,"fontFamily":_vm.fontFamily,"dialogOpen":_vm.onDialogOpen,"dialogClose":_vm.onDialogClose}}),(_vm.editMode)?_c('ejs-dropdownbutton',{attrs:{"id":"insertField","items":_vm.fieldAlias,"content":_vm.dropdownContent,"cssClass":"e-rte-dropdown-btn e-rte-dropdown-popup e-rte-dropdown-items e-rte-elements e-tbar-btn"},on:{"select":function($event){return _vm.onItemSelect($event)}}}):_vm._e(),(_vm.editMode)?_c('el-color-picker',{attrs:{"id":"richTextTableBorderColorPicker","size":"small"},on:{"change":_vm.onTableColorChange},model:{value:(_vm.selectedColor),callback:function ($$v) {_vm.selectedColor=$$v},expression:"selectedColor"}}):_vm._e()],1),(
      (!_vm.panelData.details.metrics.length && _vm.dataFromDatabase && _vm.editMode) ||
      (!_vm.editMode && !_vm.richText)
    )?_c('div',{staticClass:"vis-chart-placeHolder"},[_c('img',{attrs:{"src":require("../../../assets/images/chartEmptyStates/BAR_CHART.svg"),"alt":"TEXT_EDITOR"}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }