<template>
  <div>
    <div class="vis-display-flex data-model-sql-preview-header-container">
      <span class="data-model-sql-preview-header">{{
        $t("generalPages.preview")
      }}</span>
    </div>
    <vue-good-table
      :max-height="getMaxHeight()"
      :columns="columns"
      :rows="rows"
    >
      <div class="vis-text--center" slot="emptystate">
        {{ $t("generalPages.There are no results to display") }}
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";

export default {
  components: { VueGoodTable },
  props: {
    columns: {
      type: Array,
      required: false,
    },
    rows: {
      type: Array,
      required: false,
    },
  },
  methods: {
    getMaxHeight() {
      const datamodelSqlEditorContainer = document.querySelectorAll(
        ".data-model-sql-editor-container"
      );
      const datamodelSqlPreview = document.querySelectorAll(".data-model-sql-preview-header-container");

      const sum = datamodelSqlEditorContainer?.[0]?.offsetHeight + datamodelSqlPreview?.[0]?.offsetHeight + 94 + 4;

      return `calc(100vh - ${sum}px)`;
    },
  },
};
</script>
