<template>
  <el-popover
    placement="right"
    trigger="manual"
    popper-class="vis-dropdown-popper"
    :value="isAnyPopupVisible"
  >
    <FormatNumberPopup
      v-if="isFormatNumberPopupVisible"
      :selectedFormat="selectedFormat"
      :field="selectedPanelFields[selectedFieldIndex].alias"
      @saveFormat="saveFormat"
      @closeFormat="closeFormatPopup"
    />
    <FormatDatePopup
      v-if="isFormatDatePopupVisible"
      :selectedFormat="selectedFormat"
      :field="selectedPanelFields[selectedFieldIndex].alias"
      @saveFormat="saveFormat"
      @closeFormat="closeFormatPopup"
    />
    <CalculatePopup
      v-if="isCalculatePopupVisible"
      @closeCalculate="closeCalculatePopup"
      @deleteCalculateItem="deleteCalculateItem"
      :datasetFields="datasetFieldsByDatasetId"
    />
    <TopBottomPopup
      v-if="isTopBottomPopupVisible"
      :allMeasureFieldsMapped="allMeasureFieldsMapped"
      :selectedField="selectedField"
      @saveTopBottom="saveTopBottom"
      @closeTopBottomPopup="closeTopBottomPopup"
    />
    <FieldPopup
      v-if="isFieldPopupVisible"
      :selectedField="selectedField"
      :selectedItem="selectedContextMenuItem"
      :infoInputValue="datasetFieldsAlias"
      :aliasRenameLabel="$t('generalPages.Chart Alias')"
      :isInfoInputActive="true"
      @saveField="saveField"
      @close="isFieldPopupVisible = false"
    />
    <div slot="reference">
      <PanelCommonsBox
        :boxList="selectedPanelFields"
        :boxName="boxName"
        :contextMenuId="contextMenuId"
        :draggableList="containerItems"
        :panelDataBoxEmptyClass="panelDataBoxEmptyClass"
        :activeFieldBoxClass="activeFieldBoxClass"
        :contextMenuItems="contextMenuItems"
        :selectedDataModel="selectedDataModel"
        :datasetFields="datasetFields"
        :datamodelDatasetFields="datamodelDatasetFields"
        @changeDraggableList="onDraggableChanged($event)"
        @openContextMenu="aggregrationContext"
        @setSelectType="handleSelectedField"
        @beforeContextItemHighlight="fieldHighlight"
        @onSortMethodSwitched="onSortMethodSwitched"
        @onSelectColumnBasedPropertiesColumn="openColumnBasedFormattingProperties($event)"
      />
    </div>
  </el-popover>
</template>

<script>
import cloneDeep from "clone-deep";
import CalculatePopup from "./bars/CalculatePopup.vue";
import TopBottomPopup from "./bars/TopBottomPopup.vue";
import FieldPopup from "../helper/FieldPopup.vue";
import FormatNumberPopup from "./measures/FormatNumberPopup.vue";
import FormatDatePopup from "./measures/FormatDatePopup.vue";
import {
  getSliceItem,
  getDeleteItemAction,
  DatamodelContextDefaults,
  getDatamodelFieldContextMenu,
  ContextMenuViewType,
  DatasetFieldTypes,
  BOX_OPTIONS,
  checkMaxOrMinTypeIncludesByParam,
contextMenuItemKeys,
checkTypeSelectorDataItem
} from "../../commons/dataModelTypes";
import { getHighlightFields } from "../../util/panelDataBoxes";
import PanelCommonsBox from "./PanelCommonsBox.vue";
import {
  checkLimitAndSetPanelFields,
  defaultNumberFormat,
} from "./measures/js/measures";
import { groupBy } from "lodash";
import {
  BOX_KEY_ENUM,
  chartRules,
  chartTypes,
  dashboardFilterTypes,
  detailsKeys,
  getCommonsFieldProperty
} from "../../commons/dashboardAndPanel";
import { Notify } from "../../commons/helper.js";
import { notificationType } from "../../commons/notificationTypes";
import { filterType } from "../../commons/filterComponents";
import { setDefaultFormatValue } from "../../util/data-table/tableCommonsHelper";
import {
  getAllJoinedDatasetFields,
  getAllMeasureFields,
  getDatasetFieldsAlias,
  getJoinedDatasetIdsByDatasetId,
} from "../../util/dataset-fields/datasetFields";
import { filterSources } from '../data/sql/types';

export default {
  components: {
    CalculatePopup,
    TopBottomPopup,
    PanelCommonsBox,
    FormatNumberPopup,
    FormatDatePopup,
    FieldPopup,
  },
  props: {
    selectedPanel: {
      type: Object,
    },
    isActiveFieldBox: {
      type: Boolean,
    },
    datasetFields: {
      type: Object,
      default: () => {
        return {};
      },
    },
    datamodelDatasetFields: {
      type: Object,
      default: () => {
        return {};
      },
    },
    designMode: {
      type: Boolean,
    },
    boxKey: {
      type: String,
      required: true,
    },
    boxName: {
      type: String,
    },
    usageType: {
      type: String,
    },
    boxOptions: {
      type: Array,
      default: () => [],
    },
    datasetIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedDataModel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedField: {},
      selectedFieldIndex: null,
      calculatePopup: "",
      topBottomPopup: "",
      FormatNumberPopup: "",
      measuresfirstValue: "",
      contextMenuId: "boxMenu_" + Math.random(),
      selectedPanelFields: [],
      contextMenuItems: [],
      isFieldPopupVisible: false,
      selectedContextMenuKey: "",
      selectedContextMenuItem: null,
    };
  },
  watch: {
    selectedPanel: {
      handler(val) {
        if (val) {
          this.selectedPanelFields = this.getFieldContainer(val);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.selectedPanelFields = this.getFieldContainer(this.selectedPanel);
  },
  computed: {
    datasetFieldsAlias() {
      return getDatasetFieldsAlias(this.datasetFields, this.selectedField)
    },
    allMeasureFieldsMapped() {
      let joinedDatasetIds = getJoinedDatasetIdsByDatasetId(
        this.datasetIds,
        this.selectedField.datasetId
      );

      const allJoinedDatasetFields = getAllJoinedDatasetFields(
        this.datasetFields,
        joinedDatasetIds
      );

      return getAllMeasureFields(allJoinedDatasetFields);
    },
    datasetFieldsByDatasetId() {
      return this.datasetFields?.[this.selectedField.datasetId];
    },
    selectedFormat() {
      return (
        this.selectedPanelFields[this.selectedFieldIndex]?.format ??
        this.selectedField?.format ??
        {}
      );
    },
    activeFieldBoxClass() {
      return this.isActiveFieldBox && !this.selectedPanelFields.length
        ? "vis-activeFieldBox"
        : "";
    },
    panelDataBoxEmptyClass() {
      return !this.selectedPanelFields?.length
        ? "vis-panel-data-card-empty"
        : null;
    },
    isAnyPopupVisible() {
      return (
        this.isCalculatePopupVisible ||
        this.isTopBottomPopupVisible ||
        this.isFormatNumberPopupVisible ||
        this.isFormatDatePopupVisible ||
        this.isFieldPopupVisible
      );
    },
    isCalculatePopupVisible() {
      return this.calculatePopup == this.selectedField.field;
    },
    isTopBottomPopupVisible() {
      return this.topBottomPopup === this.selectedField.field;
    },
    isFormatNumberPopupVisible() {
      return (
        this.FormatNumberPopup == this.selectedField.field &&
        this.selectedField.fieldUsageType ==
          DatamodelContextDefaults.USAGE_TYPES.MEASURE &&
        !this.isFieldTypeDateAndTypeMaxOrMin
      );
    },
    isFormatDatePopupVisible() {
      return (
        this.FormatNumberPopup === this.selectedField.field &&
        (this.selectedField.fieldUsageType ===
          DatamodelContextDefaults.USAGE_TYPES.DATE ||
          this.isFieldTypeDateAndTypeMaxOrMin)
      );
    },
    isFieldTypeDateAndTypeMaxOrMin() {
      return (
        this.selectedField.fieldType === DatasetFieldTypes.DATE &&
        checkMaxOrMinTypeIncludesByParam(this.selectedField.type)
      );
    },
    containerItems() {
      return this.getFieldContainer(this.selectedPanel);
    },
    chartType() {
      return this.selectedPanel.type;
    },
  },
  methods: {
    setPivotTableColumns(selectedPanelCloned) {
      if (this.chartType !== chartTypes.PIVOT_TABLE) return;
      selectedPanelCloned.details[detailsKeys.COLUMNS] = [];
      selectedPanelCloned.details[detailsKeys.COLUMNS] = [
        ...selectedPanelCloned?.details?.[detailsKeys.AGGREGATION]?.filter(x=> x.boxKey === BOX_KEY_ENUM.COLUMN.KEY),
        ...selectedPanelCloned?.details?.[detailsKeys.AGGREGATION]?.filter(x=> x.boxKey === BOX_KEY_ENUM.ROW.KEY),
        ...selectedPanelCloned?.details?.[detailsKeys.METRICS],
      ];

      let indexChangeMap = new Map();
      if (indexChangeMap.size !== 0) {
        this.changeConditionalAndColumnBasedSlotFieldId(selectedPanelCloned, indexChangeMap);
      }
      this.changeConditionalAndColumnBasedSlotFieldId(selectedPanelCloned, indexChangeMap);

    },
    changeConditionalAndColumnBasedSlotFieldId(selectedPanelCloned, indexChangeMap) {
      selectedPanelCloned?.properties?.columnBasedFormattings?.forEach(columnBased => {
        let slotId = columnBased.slotFieldId?.substring(columnBased.slotFieldId?.lastIndexOf("_"));
            if (indexChangeMap.has(slotId)) {
              const replacedSlotFieldId = columnBased.slotFieldId?.replace(slotId, indexChangeMap.get(slotId));
              columnBased.slotFieldId = replacedSlotFieldId;
            }
      });
      selectedPanelCloned?.properties?.conditionalFormattings?.forEach(element => {
        let slotId = element.slotFieldId?.substring(element.slotFieldId?.lastIndexOf("_"));
            if (indexChangeMap.has(slotId)) {
              const replacedSlotFieldId = element.slotFieldId?.replace(slotId, indexChangeMap.get(slotId));
              element.slotFieldId = replacedSlotFieldId;
            }
          element?.conditions?.forEach(condition => {
              condition?.rules.forEach(rule => {
                let slotId = rule.dataItem.substring(rule.dataItem.lastIndexOf("_"));
                if (indexChangeMap.has(slotId)) {
                 const replacedDataItem = rule.dataItem.replace(slotId, indexChangeMap.get(slotId));
                 rule.dataItem = replacedDataItem;
                }
              });
          });
      });
    },
    getFieldContainer(selectedPanel) {
      if (this.usageType === DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE) {
        if (this.boxKey) {
          return selectedPanel?.details?.[detailsKeys.AGGREGATION]?.filter(
            (i) => i.boxKey === this.boxKey && !i.selectorChartI
          );
        }

        return selectedPanel?.details?.[detailsKeys.AGGREGATION];
      } else if (
        this.usageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE
      ) {
        if (this.boxKey) {
          return selectedPanel?.details?.[detailsKeys.METRICS].filter(
            (i) => i.boxKey === this.boxKey && !i.selectorChartI
          );
        }

        return selectedPanel?.details?.[detailsKeys.METRICS];
      } else if (checkTypeSelectorDataItem(this.usageType)) {
        if (this.boxKey) {
          return selectedPanel?.details?.[detailsKeys.SELECTOR_PARAMETERS].filter(
            (i) => i.boxKey === this.boxKey
          );
        }
      } else if (
        this.usageType === DatamodelContextDefaults.USAGE_TYPES.CUSTOM_FILTERS
      ) {
        if (this.boxKey) {
          return selectedPanel?.details?.[detailsKeys.CUSTOM_FILTERS]?.filter(
            (i) => i.boxKey === this.boxKey
          );
        }
      } else {
        return [
          ...selectedPanel?.details?.[detailsKeys.METRICS],
          ...selectedPanel?.details?.[detailsKeys.AGGREGATION],
        ].filter((i) => i.boxKey === this.boxKey && !i.selectorChartI);
      }
    },
    setDefaultAggFunction(field) {
      if (!field) {
        return;
      }

      const fieldType = field.fieldType;

      if (!field.type) {
        if (
          fieldType === DatasetFieldTypes.TEXT ||
          fieldType === DatasetFieldTypes.DATE ||
          fieldType === DatasetFieldTypes.BOOLEAN
        )
          field.type = DatamodelContextDefaults.SUMMARIZATION_RULE.COUNT;
        else if (fieldType === DatasetFieldTypes.NUMBER) {
          field.type = DatamodelContextDefaults.SUMMARIZATION_RULE.SUM;
        }
      }
    },
    deleteField() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      const selectedField = this.getSelectedField(selectedPanelCloned);
     
      if (this.boxKey === BOX_KEY_ENUM.SELECTOR.KEY) {
        this.deleteFieldByDetailsKey(
            selectedPanelCloned,
            selectedField,
            detailsKeys.SELECTOR_PARAMETERS
          );

          this.updateSelectedPanelByDesignMode(selectedPanelCloned);
          return;
      }

      if (
        this.selectedField.fieldUsageType ===
          DatamodelContextDefaults.USAGE_TYPES.MEASURE ||
        this.isBoxKeyDataItem(selectedField.boxKey)
      ) {
        this.deleteFieldByDetailsKey(
          selectedPanelCloned,
          selectedField,
          detailsKeys.METRICS
        );

        this.deleteSelectorDataItemValues(
          selectedPanelCloned,
          selectedField,
          detailsKeys.METRICS
        );
      } else if (
        this.selectedField.fieldUsageType ===
        DatamodelContextDefaults.USAGE_TYPES.CUSTOM_FILTERS
      ) {
        this.deleteFieldByDetailsKey(
          selectedPanelCloned,
          selectedField,
          detailsKeys.CUSTOM_FILTERS
        );
      } else {
        this.deleteFieldByDetailsKey(
          selectedPanelCloned,
          selectedField,
          detailsKeys.AGGREGATION
        );

        this.deleteSelectorDataItemValues(
          selectedPanelCloned,
          selectedField,
          detailsKeys.AGGREGATION
        );
      }

      this.checkAndSwitchPanelVisType(selectedPanelCloned);
      this.setPivotTableColumns(selectedPanelCloned);
      this.updateSelectedPanelByDesignMode(selectedPanelCloned);
    },

    deleteFieldByDetailsKey(selectedPanelCloned, selectedField, detailsKey) {
      const index =
        selectedPanelCloned.details?.[detailsKey]?.indexOf(selectedField);
      selectedPanelCloned.details?.[detailsKey]?.splice(index, 1);
    },
    deleteSelectorDataItemValues(selectedPanelCloned, selectedField, detailsKey) {
      if (!checkTypeSelectorDataItem(selectedField.fieldType)) return;

      selectedPanelCloned.details[detailsKey] = selectedPanelCloned.details?.[detailsKey].filter(x=> x.selectorChartI !== selectedField.fieldId);
    },
    deleteDraggedFieldFromOldSlot({ fieldId, boxKey, selectedPanelCloned }) {
       if (chartRules[this.selectedPanel?.type]?.metricSlots.includes(boxKey)) {
        const findIndex = selectedPanelCloned.details.metrics.findIndex(x=> x.fieldId === fieldId);
        selectedPanelCloned.details?.metrics?.splice(findIndex, 1);
      }
      else {
        const findIndex = selectedPanelCloned.details.aggregation.findIndex(x=> x.fieldId === fieldId);
        selectedPanelCloned.details?.aggregation?.splice(findIndex, 1);
      }
    },
    deleteCalculateItem(event) {
      //DURUMA GÖRE BURA KALDIRILABİLİR
      var deleteItem = this.selectedPanelFields.find(
        (x) => x.field == event.field
      );
      if (deleteItem) {
        this.$delete(deleteItem, "orderBy");
      }
    },
    resetFieldList(value) {
      let field = {};
      let newField = {};
      field = value.added.element;
      let params = {
        newField,
        field,
      };
      return params;
    },
    handleBarMoved(event) {
      if (event?.moved && event.moved.element) {
        const { newIndex, oldIndex } = event.moved;
        const selectedPanelCloned = cloneDeep(this.selectedPanel);

        const field = event.moved.element;

        const containerName = this.getContainerName(this.usageType ?? field.fieldUsageType);

        if (!containerName) return false;

        const container = selectedPanelCloned.details[containerName]?.filter(
          (i) => i.boxKey === this.boxKey
        );

        const temp1 = cloneDeep(container[newIndex]);
        const temp2 = cloneDeep(container[oldIndex]);

        container[newIndex] = temp2;
        container[oldIndex] = temp1;

        selectedPanelCloned.details[containerName] =
          selectedPanelCloned.details[containerName].filter(
            (i) =>
              i.field !== temp1.field &&
              i.field !== temp2.field &&
              i.boxKey !== this.boxKey
          );

        selectedPanelCloned.details[containerName].push(...container);

        this.setPivotTableColumns(selectedPanelCloned);

        this.updateSelectedPanelByDesignMode(selectedPanelCloned);

        return true;
      }

      return false;
    },
    getFieldUsageType(fieldData) {
      //this.usageType panelDataBoxes.js içerisinden geliyor. Slot otomatik usageType tanımlamış oluyor
      //usageType sol taraftan sürükle bırak yaparken field'dan gelen.
      //this.usageType yoksa fieldUsageType slotlar arası taşıma yaparken kullanılıyor.
      return this.usageType || fieldData?.usageType || fieldData?.fieldUsageType;
    },
    handleNewFieldAdded(event) {
      const params = this.resetFieldList(event);

      if (!this.checkBoxOptions(params.field)) {
        return;
      }

      //Slotlar arası taşımada slot içindeki field daha önceden map'lendiği için usageType => fieldUsageType olarak kullanılıyor.
      //panelDataBoxes.js boxes[] içerisinde usageType tanımlı olduğu için ilk aşamada o slot için oradaki usageType'ı alır. (this.usageType)
      const usageType = this.getFieldUsageType(params.field);

      if (DatamodelContextDefaults.USAGE_TYPES.MEASURE === usageType) {
        this.setMeasureList(params);
      } else if (
        (DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE === usageType ||
          DatamodelContextDefaults.USAGE_TYPES.DATE === usageType) &&
        !this.checkAggregationColumn(params.field)
      ) {
        this.setAggregrationList(params);
      } else if (checkTypeSelectorDataItem(usageType)) {
        this.setSelectorParameters(params);
      } else if (
        DatamodelContextDefaults.USAGE_TYPES.CUSTOM_FILTERS == usageType
      ) {
        this.setCustomFilterList(params);
      }
    },
    setCustomFilterList(params) {
      params.newField = {
        type: params.field.defaultAggFunction,
        field: params.field.name,
        alias: params.field.alias ?? params.field.name,
        fieldType: params.field.type,
        fieldUsageType: DatamodelContextDefaults.USAGE_TYPES.CUSTOM_FILTERS,
        format: params.field.format,
        boxKey: this.boxKey,
        filterType: params.field.filterType ?? null,
        orderType: null,
        datasetId: params?.field?.datasetId,
        fieldId: params?.field?.paramId,
        mandatory: params?.field?.mandatory,
        multi_select: params?.field?.multi_select,
        isAuthorize: params?.field?.isAuthorize
      };

      let selectedPanelCloned = cloneDeep(this.selectedPanel);

      selectedPanelCloned.details.customFilters.push(params.newField);

      this.updateSelectedPanel(selectedPanelCloned);

      this.$emit("changeFilterChartPropertyValue");
    },
    setSelectorParameters(params) {
      let selectedPanelCloned = cloneDeep(this.selectedPanel);
      
      params.newField = {
        ...getCommonsFieldProperty(params.field),
        type: params?.field?.defaultAggFunction || params?.field?.type,
        fieldUsageType: params?.field?.usageType,
        format: params.field.format,
        boxKey: this.boxKey,
        screenTip: params?.field.screenTip, //For Front End
        description: params?.field.description, //For Front End
      };

      selectedPanelCloned.details[detailsKeys.SELECTOR_PARAMETERS].push(params.newField);

      //Slot içerisinde field sürükle bırak yapıldıktan sonra son çalışan kısım. Chartı objesini update eder ve viewChart fonksiyonu çalışır.
      this.updateSelectedPanelByDesignMode(selectedPanelCloned);
    },
    setMeasureList(params) {
      let selectedPanelCloned = cloneDeep(this.selectedPanel);
      //fieldBoxKey, taşınan field'ın slot içinden geldiğini ifade ediyor.
      const fieldBoxKey = params?.field?.boxKey;
      const fieldUsageType =  fieldBoxKey
        ? this.getFieldUsageType(params.field)
        : params?.field?.usageType || params?.field?.fieldUsageType;

      let formatValue = setDefaultFormatValue(params.field, fieldUsageType);

      //SLOTLAR ARASI SÜRÜKLE BIRAKTA AŞAMASINDA ÇALIŞAN KISIM
      if (fieldBoxKey) {
        const { fieldId, boxKey, fieldUsageType, format } = params.field;
        //SLOTLAR ARASI SÜRÜKLE BIRAKTA, TAŞINAN FIELD'IN ESKİ SLOTUNDAN SİLİNMESİ İÇİN.
        this.deleteDraggedFieldFromOldSlot({
          fieldId,
          boxKey,
          selectedPanelCloned,
        });

        //Slotlar arası taşımada aggregate(DATE) slotundan measure slotuna taşıma yapılırken...
        if (fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.DATE && format) {
          formatValue = defaultNumberFormat;
        }
      }

      params.newField = {
        ...getCommonsFieldProperty(params.field),
        type: params?.field?.defaultAggFunction || params?.field?.type,
        fieldUsageType: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
        format: formatValue,
        boxKey: this.boxKey,
        filterType: params.field.filterType ?? null,
        orderType: null,  //For Front End
        screenTip: params?.field.screenTip, //For Front End
        description: params?.field.description, //For Front End
      };

      if (params?.field?.filterSource === filterSources.DATASET) {
        params.newField.datasetId = params?.field?.objectId;
      }
      
      if(this.boxKey === BOX_KEY_ENUM.CONTENT.KEY) {
        params.newField.type = DatamodelContextDefaults.SUMMARIZATION_RULE.MIN;
      }

      //For custom/param filter field.
      if (params?.field?.filterType === filterType.CUSTOM) {
        params.newField.mandatory = params?.field?.mandatory;
        params.newField.multi_select = params?.field?.multi_select;
        params.newField.default_value = params?.field?.default_value;
        params.newField.rangeSelect = params?.field?.rangeSelect;
      }

      this.setBoxKeyDataItemField(params);

      selectedPanelCloned.details[detailsKeys.METRICS].push(params.newField);

      checkLimitAndSetPanelFields(selectedPanelCloned);

      this.checkAndSwitchPanelVisType(selectedPanelCloned);

      this.setPivotTableColumns(selectedPanelCloned);

      //Slot içerisinde field sürükle bırak yapıldıktan sonra son çalışan kısım. Chartı objesini update eder ve viewChart fonksiyonu çalışır.
      this.updateSelectedPanelByDesignMode(selectedPanelCloned);

      this.$emit("changeFilterChartPropertyValue");
    },
    setAggregrationList(params) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      //fieldBoxKey, taşınan field'ın slot içinden geldiğini ifade ediyor.
      const fieldBoxKey = params?.field?.boxKey;

      const usageType = fieldBoxKey
        ? this.getFieldUsageType(params.field)
        : params?.field?.usageType || params?.field?.fieldUsageType;


      if (usageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE) {
        Notify(this.$t("panel.measureDragWarning"),"warning",this.$t("notifyTitle.warning"))
        return;
      }

       //SLOTLAR ARASI SÜRÜKLE BIRAKTA AŞAMASINDA ÇALIŞAN KISIM
      if (fieldBoxKey) {
        const { fieldId, boxKey, fieldUsageType, format } = params.field;
        //SLOTLAR ARASI SÜRÜKLE BIRAKTA, TAŞINAN FIELD'IN ESKİ SLOTUNDAN SİLİNMESİ İÇİN.
        this.deleteDraggedFieldFromOldSlot({
          fieldId,
          boxKey,
          selectedPanelCloned,
        });

         //Slotlar arası taşımada measure slotundan aggregate slotlarına taşıma yapılırken...
        if (fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE && format) {
          params.field.format = null;
        }
      }

      params.newField = {
        ...getCommonsFieldProperty(params.field),
        defaultAggFunction: params?.field?.defaultAggFunction || params?.field?.type,
        format: null, //For Front End
        drillPaths: params?.field?.drillPaths, //For Front End
        fieldUsageType: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
        size: 5, //For Front End
        boxKey: this.boxKey, //For Front End
        orderType: null, //For Front End
        rank: params?.field?.rank || null,
      };

      if (
        usageType === DatamodelContextDefaults.USAGE_TYPES.DATE ||
        (fieldBoxKey &&
          params?.field?.fieldType ===
            DatamodelContextDefaults.USAGE_TYPES.DATE)
      ) {
        params.newField.format = params?.field?.format;
        params.newField.fieldUsageType =
          DatamodelContextDefaults.USAGE_TYPES.DATE;
        this.setDefaultAggFunction(params.newField);
      }

      this.addAggregation(selectedPanelCloned, params.newField);

      checkLimitAndSetPanelFields(selectedPanelCloned);

      this.checkAndSwitchPanelVisType(selectedPanelCloned);

      this.setPivotTableColumns(selectedPanelCloned);

      this.updateSelectedPanelByDesignMode(selectedPanelCloned);
    },
    addAggregation(selectedPanelCloned, params) {
      if (
        this.boxKey == BOX_KEY_ENUM.ROW.KEY &&
        this.chartType == chartTypes.HEATMAP_CHART
      ) {
        selectedPanelCloned.details[detailsKeys.AGGREGATION].unshift(params);
      } else selectedPanelCloned.details[detailsKeys.AGGREGATION].push(params);
    },
    checkAggregationColumn(param) {
      return (
        param.isCalculated &&
        param.defaultAggFunction === DatamodelContextDefaults.USAGE_TYPES.NONE
      );
    },
    onDraggableChanged(event) {
      if (event?.moved) {
        this.handleBarMoved(event);
      } else if (event?.added) {
        this.handleNewFieldAdded(event);
      }
    },
    aggregrationContext(event, params) {
      const usageType = this.usageType || params?.item?.fieldUsageType;
       
      if (!params?.item?.isAuthorize || checkTypeSelectorDataItem(params?.item?.fieldType)) {
        this.contextMenuItems = [getDeleteItemAction()];
      } else if (usageType === DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE) {
        this.contextMenuItems = getSliceItem({
          fieldUsageType: params?.item?.fieldUsageType,
        });
      } else if (this.boxKey === BOX_KEY_ENUM.CONTENT.KEY ) {
        this.contextMenuItems = getSliceItem({
          fieldUsageType: params?.item?.fieldUsageType,
          slotType: params?.item?.boxKey,
        });
      } else {
        this.contextMenuItems = getDatamodelFieldContextMenu(
          params?.item?.isCalculated,
          params?.item?.fieldType,
          ContextMenuViewType.CHART,
          params?.item?.fieldUsageType,
          params?.item,
          {
            isShowButtonActive: params?.item?.isHidden,
            isColumnBasedFormattingActive: false,
          }
        );
      }

      this.selectedField = params.item;
      this.selectedFieldIndex = params.index;

      document
        .getElementById(this.contextMenuId)
        .ej2_instances[0].open(event.y, event.x);
    },
    setBoxKeyDataItemField(params) {
      if (this.isBoxKeyDataItem(params.newField.boxKey)) {
        params.newField.type = DatamodelContextDefaults.DISTINCT;
        params.newField.fieldUsageType = params.field.usageType;
      }
    },
    handleSelectedField(arg) {
      const value = arg?.item?.value;
      const key = arg?.item?.key;

      if (!arg || !arg.item || !value) {
        return;
      }

      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      const selectedField = this.getSelectedField(selectedPanelCloned);

      if (key == DatamodelContextDefaults.FORMAT) {
        this.FormatNumberPopup = this.selectedField.field;
      } else if (key == DatamodelContextDefaults.BARS.TOP_BOTTOM_POPUP) {
        this.topBottomPopup = this.selectedField.field;
      } else if (value === DatamodelContextDefaults.BARS.SUM_ASC_DESC) {
        this.calculatePopup = this.selectedField.field;
      } else if (key === DatamodelContextDefaults.DELETE) {
        this.deleteField();
      } else if (Object.values(DatamodelContextDefaults.BARS).includes(value)) {
        this.setFieldOrder(selectedField, value, selectedPanelCloned);
      } else if (
        [
          DatamodelContextDefaults.ALIAS,
          DatamodelContextDefaults.SCREEN_TIP,
          DatamodelContextDefaults.DESCRIPTION,
        ].includes(value)
      ) {
        this.isFieldPopupVisible = true;
        this.selectedContextMenuKey = value;
        this.selectedContextMenuItem = value;
      } else if (
        Object.values(DatamodelContextDefaults.USAGE_TYPES).includes(value)
      ) {
        this.changeUsageType(
          selectedPanelCloned,
          selectedField,
          value
        );
      } else if (key === contextMenuItemKeys.AGG_FUNCTION) {
        const isValueMaxOrMin = checkMaxOrMinTypeIncludesByParam(value);
        const isSelectedFieldTypeMaxOrMin = checkMaxOrMinTypeIncludesByParam(selectedField.type);
        const isFieldTypeTextOrDateWithFormat = [DatasetFieldTypes.TEXT, DatasetFieldTypes.DATE].includes(selectedField?.fieldType) && selectedField?.format;

        if (
          (isValueMaxOrMin && !isSelectedFieldTypeMaxOrMin && isFieldTypeTextOrDateWithFormat) ||
          (!isValueMaxOrMin && isSelectedFieldTypeMaxOrMin && isFieldTypeTextOrDateWithFormat)
        ) {
          selectedField.format = null;
        }

        selectedField.type = value;

        this.setChartFields(selectedPanelCloned, value);

        this.updateSelectedPanelByDesignMode(selectedPanelCloned);
      }
    },
    setChartFields(selectedPanelCloned, newValue, key) {
      const { aggregation, filters } = selectedPanelCloned.details;

      if (!aggregation?.length && !filters?.length) return;

      //key parametresi sonraki aşamalarda kullanılacak. Şuan alias için kullanılıyor.
      if (!key) key = this.selectedContextMenuKey;

      const detailSearchList = [...aggregation, ...filters];

      detailSearchList.forEach((element) => {
        if (element.field === this.selectedField.field) {
          //changeAlias
          if (key == DatamodelContextDefaults.ALIAS) element.alias = newValue;

          //changeType(aggFunction exp:sum,avg,max,min)
          if (element.type) element.type = newValue;

          //changeUsageType (usageType = TEXT,NUMBER,MEASURE)
          if (element.fieldUsageType) element.fieldUsageType = newValue;
        }
      });

      this.selectedContextMenuKey = "";
    },
    changeUsageType(panel, field, usageType) {
      if (usageType === field.fieldUsageType || usageType) {
        return;
      }
      const previousUsageType = field.fieldUsageType;
      const fieldClone = cloneDeep(field);
      fieldClone.usageType = usageType;

      const checkResult = this.checkBoxOptions(fieldClone);

      if (!checkResult) return;

      let currentContainer = this.getContainerName(previousUsageType),
        containerToAddTo = this.getContainerName(usageType);

      if (
        currentContainer &&
        containerToAddTo &&
        currentContainer !== containerToAddTo
      ) {
        if (currentContainer != DatamodelContextDefaults.USAGE_TYPES.NONE)
          return;
        // remove from current container
        const index = panel.details[currentContainer]?.indexOf(field);
        if (!index || index === -1) return;
        panel.details[currentContainer]?.splice(index, 1);

        // add to new container
        if (usageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE) {
          this.setDefaultAggFunction(field);
        }
        panel.details[containerToAddTo]?.push(field);
      }

      // set selected usageType
      field.fieldUsageType = usageType;
      this.checkAndSwitchPanelVisType(panel);
      this.setChartFields(panel, usageType);
      this.updateSelectedPanelByDesignMode(panel);
    },
    fieldHighlight(args) {
      getHighlightFields(args, this.selectedField);
    },
    updateSelectedPanel(value) {
      this.$emit("updateSelectedPanel", {
        value,
      });
    },
    updateSelectedPanelAndViewChart(value) {
      this.$emit("updateSelectedPanelAndViewChart", {
        value,
      });
    },
    updateSelectedPanelByDesignMode(cloneSelectedPanel) {
      if (this.designMode) this.updateSelectedPanel(cloneSelectedPanel);
      else this.updateSelectedPanelAndViewChart(cloneSelectedPanel);
    },
    closeTopBottomPopup(event) {
      this.topBottomPopup = event;
    },
    closeCalculatePopup(event) {
      this.calculatePopup = event;
    },
    saveFormat(params) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      const selectedField = this.getSelectedField(selectedPanelCloned);
      
      if (selectedField) {
        selectedField.format = params;
        this.updateSelectedPanelAndViewChart(selectedPanelCloned);
      }

      if (Object.keys(params)?.length) this.closeFormatPopup();
    },
    closeFormatPopup(event) {
      this.FormatNumberPopup = event;
    },
    getSelectedField(panel, fieldName) {
      return this.getFieldContainer(panel).find(
        (i) => i.field === (fieldName || this.selectedField?.field) || i.fieldId === this.selectedField.fieldId
      );
    },
    saveField(value) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      const metrics = selectedPanelCloned?.details?.metrics || [];
      const existingMetricIndex = metrics.findIndex(
        (metric) => metric.fieldId === value.fieldId
      );

      if (existingMetricIndex !== -1) {
        const existingMetric = metrics[existingMetricIndex];
        if (
          existingMetric.alias !== value.alias ||
          existingMetric.screenTip !== value.screenTip ||
          existingMetric.description !== value.description
        ) {
          metrics[existingMetricIndex] = value;
        }
      } else {
        metrics.push(value);
      }
      selectedPanelCloned.details.metrics = metrics;

      // TO DO Ersoy abiyle konuşulup bu alan için task açılacak
      // this.setChartFields(selectedPanelCloned, value.alias);
      this.updateSelectedPanel(selectedPanelCloned);
    },
    saveTopBottom(params) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      selectedPanelCloned.details.aggregation.find(
        (x) => x.fieldId === params.fieldId
      ).rank = params.rank;

      this.updateSelectedPanelByDesignMode(selectedPanelCloned);
    },
    checkBoxOptions(addedField) {
      const ATTRIBUTE = DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE;
      const MEASURE = DatamodelContextDefaults.USAGE_TYPES.MEASURE;
      const DATE = DatamodelContextDefaults.USAGE_TYPES.DATE;
      const currentFields = this.getFieldContainer(this.selectedPanel).filter(
        (i) => i.field !== addedField.field
      );
      const group = groupBy(currentFields, (val) => val.fieldUsageType);
      const attrCount = group[ATTRIBUTE]?.length;
      const measureCount = group[MEASURE]?.length;
      const dateCount = group[DATE]?.length;

      if (this.boxOptions.includes(BOX_OPTIONS.SINGLE_TYPE)) {
        if (
          (addedField.usageType === ATTRIBUTE && measureCount > 0) ||
          (addedField.usageType === MEASURE && (attrCount > 0 || dateCount > 0))
        ) {
          return false;
        }
      }

      if (this.boxOptions.includes(BOX_OPTIONS.SINGLE_METRIC)) {
        if (
          (addedField.usageType === MEASURE || this.usageType === MEASURE) &&
          measureCount > 0
        ) {
          const existingMetric = this.selectedPanel.details.metrics.find(
            (i) => i.boxKey === this.boxKey
          );
          const index =
            this.selectedPanel.details.metrics.indexOf(existingMetric);
          this.selectedPanel.details.metrics.splice(index, 1);
          return true;
        }
      }

      if (
        this.selectedPanel.type === dashboardFilterTypes.FILTER_DATE &&
        addedField.type !== DatasetFieldTypes.DATE
      ) {
        Notify("Type Date Olan Bir Field Ekleyin.", notificationType.WARNING);
        return false;
      }

      return true;
    },
    getContainerName(usageType) {
      if (!usageType) return null;

      switch (usageType) {
        case DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE:
        case DatamodelContextDefaults.USAGE_TYPES.DATE:
          return detailsKeys.AGGREGATION;
        case DatamodelContextDefaults.USAGE_TYPES.MEASURE:
          return detailsKeys.METRICS;
        case DatamodelContextDefaults.USAGE_TYPES.SELECTOR_DATA_ITEM:
          return detailsKeys.SELECTOR_PARAMETERS;
        case DatamodelContextDefaults.USAGE_TYPES.NONE:
          return null;
        default:
          break;
      }
    },
    isBoxKeyDataItem(boxKey) {
      return boxKey == BOX_KEY_ENUM.DATA_ITEM.KEY;
    },
    checkAndSwitchPanelVisType(selectedPanel) {
      const rules = chartRules[this.chartType];

      if (!rules || rules.isMetricRequired === undefined) {
        return;
      }

      if (
        rules.isMetricRequired ||
        (!rules.isMetricRequired && selectedPanel?.details?.metrics?.length)
      ) {
        selectedPanel.properties.vismetadata.vistype = chartTypes.OTHER;
      } else {
        selectedPanel.properties.vismetadata.vistype = chartTypes.TABLE;
      }
    },
    setFieldOrder(field, orderValue, selectedPanel) {
      if (!field) return;

      field.orderType = orderValue;
      const panelParams = selectedPanel?.details;

      if (orderValue === null || orderValue === undefined) {
        panelParams.orders = panelParams?.orders?.filter(
          (orderField) => orderField.fieldId !== field.fieldId
        );
      } else {
        const foundField = panelParams?.orders?.find(
          (orderField) => orderField.fieldId === field.fieldId
        );

        if (!panelParams?.orders?.length) panelParams.orders = [];

        if (foundField) {
          if (foundField.orderType != field.orderType) {
            foundField.orderType = field.orderType;
          }
        } else {
          const orderField = cloneDeep(field);

          if (orderField.boxKey === BOX_KEY_ENUM.DATA_ITEM.KEY) {
            orderField.type = DatamodelContextDefaults.SUMMARIZATION_RULE.NONE;
          }

          panelParams?.orders?.push(orderField);
        }
      }
      this.updateSelectedPanelByDesignMode(selectedPanel);
    },
    onSortMethodSwitched(sortMethod, field) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      const selectedField = this.getSelectedField(
        selectedPanelCloned,
        field.field
      );
      this.setFieldOrder(selectedField, sortMethod, selectedPanelCloned);
    },
    openColumnBasedFormattingProperties(value) {
      if (
        (this.selectedPanel.type === chartTypes.PIVOT_TABLE) &&
        [BOX_KEY_ENUM.MEASURES.KEY,BOX_KEY_ENUM.COLUMN.KEY,BOX_KEY_ENUM.ROW.KEY ].includes(value.boxKey)
      )
        this.$emit("onSelectColumnBasedPropertiesColumn", value);
    },
  },
};
</script>
<style scoped></style>
