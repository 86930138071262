import { HOMEPAGE_SEARH_PARAMS } from "../../commons/homeDataTypes";
import { ObjectType } from "../../util/homePageMappers";

export const defaultFilterValues = {
  objectNameDropdown: "Contains",
  objectName: "",
  objectType: "",
  owner: "",
  createdAt: "",
  updatedAt: "",
  objectId: "",
};

export const defaultAppliedFilterValues = {
  objectNameDropdown: "Contains",
  objectName: "",
  objectType: "",
  owner: "",
  createdAt: "",
  updatedAt: "",
  objectId: "",
};

export const columnKeys = {
  TYPEICON: "typeIcon",
  NAME: "name",
  CREATED_BY: "created_by",
  TYPE: "type",
  CREATE_DATE: "created_date",
  UPDATED_DATE: "updated_date",
  ID: "id",
  FOLDER_NAMES: "folderNames",
  OWNER: "ownerName",
};

export const DEFAULT_FOLDER_QUERY_DATA = [
  { key: HOMEPAGE_SEARH_PARAMS.PAGE, value: "" },
  {
    key: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE,
    value: [ObjectType.FOLDER],
  },
  { key: HOMEPAGE_SEARH_PARAMS.FOLDER_ID, value: "root" },
  { key: HOMEPAGE_SEARH_PARAMS.SORT_PARAMS, value: "" },
  { key: HOMEPAGE_SEARH_PARAMS.SEARCH_KEY, value: "" },
];

export const MIGRATE_CONTENT_SELECTION_LIST = [
  { key: HOMEPAGE_SEARH_PARAMS.PAGE, value: "" },
  {
    key: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE,
    value: [ObjectType.DASHBOARD, ObjectType.DATAMODEL],
  },
  { key: HOMEPAGE_SEARH_PARAMS.FOLDER_ID, value: "" },
  { key: HOMEPAGE_SEARH_PARAMS.SORT_PARAMS, value: "" },
  { key: HOMEPAGE_SEARH_PARAMS.SEARCH_KEY, value: "" },
];
