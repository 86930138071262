<template>
  <div class="migrate-content-container">
    <el-dialog
      :title="$t('generalPages.Select filter criteria')"
      :visible.sync="showFilterPopup"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="filter-row">
        <span class="filter-row-title">{{
          $t("generalPages.Object Name")
        }}</span>
        <el-select
          style="padding-right: 25px"
          v-model="filterValue.objectNameDropdown"
          :placeholder="$t('generalPages.Select')"
          size="mini"
        >
          <el-option
            v-for="item in filterOptions.objectNameDropdown"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          size="mini"
          :placeholder="$t('generalPages.Object Name')"
          v-model="filterValue.objectName"
        >
        </el-input>
      </div>
      <div class="filter-row">
        <span class="filter-row-title">{{
          $t("generalPages.Object Type")
        }}</span>
        <el-select
          v-model="filterValue.objectType"
          :placeholder="$t('generalPages.Select')"
          size="mini"
        >
          <el-option
            v-for="item in filterOptions.objectType"
            :key="item.value"
            :label="$t(item.label)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="filter-row">
        <span class="filter-row-title">{{ $t("generalPages.Owner") }}</span>
        <el-select
          v-model="filterValue.owner"
          :placeholder="$t('generalPages.Select')"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in filterOptions.owner"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="filter-row">
        <span class="filter-row-title">{{
          $t("generalPages.Created At")
        }}</span>
        <el-date-picker
          v-model="filterValue.createdAt"
          type="daterange"
          align="right"
          :start-placeholder="$t('generalPages.Start Date')"
          :end-placeholder="$t('generalPages.End Date')"
          :default-value="new Date()"
        >
        </el-date-picker>
      </div>
      <div class="filter-row">
        <span class="filter-row-title">{{
          $t("generalPages.Updated At")
        }}</span>
        <el-date-picker
          v-model="filterValue.updatedAt"
          type="daterange"
          align="right"
          :start-placeholder="$t('generalPages.Start Date')"
          :end-placeholder="$t('generalPages.End Date')"
          :default-value="new Date()"
        >
        </el-date-picker>
      </div>
      <div class="filter-row">
        <span class="filter-row-title">{{ $t("generalPages.Object Id") }}</span>
        <el-input
          size="mini"
          :placeholder="$t('generalPages.Object Id')"
          v-model="filterValue.objectId"
        >
        </el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeFilterPopup">{{
          $t("generalPages.Close")
        }}</el-button>
        <el-button type="primary" @click="applyFilters">{{
          $t("generalPages.Apply")
        }}</el-button>
      </span>
    </el-dialog>

    <div class="migrate-content-header">
      <span class="migrate-content-header-title">{{
        $t("admin.menuItem.labelMigrateContent")
      }}</span>

      <div class="migrate-content-header-filters">
        <el-input
          style="width: 250px"
          size="mini"
          placeholder="Search..."
          v-model="quickSearchValue"
          @keyup.enter.native="searchFolder()"
        >
        </el-input>
        <i
          style="cursor: pointer"
          @click="showFilterPopup = true"
          aria-hidden="true"
          :class="CustomIcon.FilterAltOutlined"
        />
      </div>
    </div>
    <div class="migrate-content-selection-table">
      <div class="vis-flex--spacebeetwen-Ycenter">
        <span class="vis-font-medium"
          >{{ $t("admin.migrate.Select objects to migrate") }}
        </span>
      </div>
      <div class="vis-vgt-card">
        <vue-good-table
          v-loading="isLoading"
          ref="migrate-selection-table"
          :columns="columns"
          :rows="filteredRows"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: false,
            disableSelectInfo: true,
          }"
          :pagination-options="paginationOptions"
          :fixed-header="true"
          max-height="540px"
        >
          <template slot="pagination-bottom">
            <el-pagination
              :current-page.sync="currentPage"
              :page-count="pagination.totalPages"
              :page-size="30"
              layout="prev, pager, next, jumper"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </template>
          <template slot="table-column" slot-scope="props">
            <span>
              {{ $t(`${props.column.label}`) }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == columnKeys.TYPEICON">
              <i :class="props.row.iconClassName" aria-hidden="true"></i>
            </span>
            <span
              v-else-if="props.column.field == columnKeys.NAME"
              class="vis-cursor-pointer"
            >
              {{ props.row.name }}
            </span>
            <span v-else-if="props.column.field == columnKeys.TYPE">
              <span>{{ $t(`generalPages.${props.row.type}`) }}</span>
            </span>
            <span v-else-if="props.column.field == columnKeys.OWNER">
              <span>{{ props.row.item.ownerName }}</span>
            </span>
            <span v-else-if="props.column.field == columnKeys.CREATE_DATE">
              <span>{{ formatISOToDateTime(props.row.created_date) }}</span>
            </span>
            <span v-else-if="props.column.field == columnKeys.UPDATED_DATE">
              <span>{{ formatISOToDateTime(props.row.updated_date) }}</span>
            </span>
            <span v-else-if="props.column.field == columnKeys.FOLDER_NAMES">
              <span>{{
                handleDirectoryNames(props) || $t("generalPages.Home Page")
              }}</span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>

      <el-button
        class="vis-ml--auto"
        style="width: 100px"
        type="primary"
        size="small"
        @click="migrateSelections"
      >
        {{ $t("generalPages.Continue") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { formatISOToDateTime } from "../../util/moment";
import { CustomIcon } from "../../assets/js/custom-icons";
import cloneDeep from "clone-deep";

// Store
import { mapActions, mapState, mapMutations } from "vuex";
// import {
//   MUTATION as MUTATION_MIGRATE_CONTENT,
// } from "../../store/modules/Transfer/MigrateContent/types";
import {
  STATE as STATE_SEARCH,
  ACTION as ACTION_SEARCH,
  GETTER as GETTER_SEARCH,
  MUTATION as MUTATION_HOME,
} from "../../store/modules/Visualize/Home/types";
import { GETTER as GETTER_GENERAL } from "../../store/modules/Visualize/General/types";
import { LoadingComponent } from "../../store/modules/Visualize/General/loadingComponentDefinitions";
import { Notification } from "element-ui";
import moment from "moment";
import { mapGetters } from "vuex/dist/vuex.common.js";
import {
  columnKeys,
  defaultAppliedFilterValues,
  defaultFilterValues,
  MIGRATE_CONTENT_SELECTION_LIST,
} from "./migrateContentDataTypes";
import {
  convertListToAccordingToType,
  getSearchQueryParams,
  handleDirectoryNames,
} from "../../commons/helper";
import { HOMEPAGE_SEARH_PARAMS } from "../../commons/homeDataTypes";
import { ObjectType } from "../../util/homePageMappers";

function filterByValue(array, string = "") {
  return array?.filter((o) => {
    return Object.keys(o).some((k) => {
      return String(o[k]).toLowerCase().includes(string.toLowerCase());
    });
  });
}

function removeusingSet(arr) {
  let outputArray = Array.from(new Set(arr));
  return outputArray;
}

export default {
  components: { VueGoodTable },
  props: {
    lastQueryData: {
      type: Object,
    },
    lastSelectedObject: {
      type: Object,
    },
  },
  data() {
    return {
      filterValue: {},
      filterValueApplied: {},
      columnKeys: columnKeys,
      showFilterPopup: false,
      quickSearchValue: "",
      CustomIcon: CustomIcon,
      searchQueryData: {},
      paginationOptions: {
        enabled: true,
        mode: "pages",
        perPageDropdownEnabled: false,
        perPage: 30,
        setCurrentPage: 1,
        position: "bottom",
        jumpFirstOrLast: false,
        nextLabel: "",
        prevLabel: "",
        ofLabel: "/",
        pageLabel: this.$t("generalPages.Page"),
      },
      handleDirectoryNames: handleDirectoryNames,
      currentPage: 1,
    };
  },
  watch: {
    quickSearchValue: {
      handler() {
        this.resetCurrentPage();
      },
    },
    filterValueApplied: {
      deep: true,
      handler() {
        this.resetCurrentPage();
      },
    },
  },
  mounted() {
    this.filterValue = cloneDeep(defaultFilterValues);
    this.filterValueApplied = cloneDeep(defaultAppliedFilterValues);

    if (
      this.lastQueryData?.find((x) => x.key === HOMEPAGE_SEARH_PARAMS.PAGE)
        ?.value
    ) {
      this.searchQueryData = cloneDeep(this.lastQueryData);
      this.currentPage =
        this.lastQueryData?.find((x) => x.key === HOMEPAGE_SEARH_PARAMS.PAGE)
          ?.value + 1; // value indexe göre değer aldığı için 1 ekledik
    } else {
      this.searchQueryData = cloneDeep(MIGRATE_CONTENT_SELECTION_LIST);
    }

    this.resetHomepageSearch();
    this.getDashboardAndDatamodelList(this.searchQueryData);
    

    if (this.lastSelectedObject) {
      //Sayfaya breadcrumb üzerinden dönüldüğünde checkbox'ı dolduran kısım
      const rowList = this.$refs["migrate-selection-table"].filteredRows?.[0]?.children;
      this.$set(this.findSelectedRow(rowList), 'vgtSelected', true);
    }
  },
  computed: {
    ...mapState({
      dashboardsWithFolders: (state) =>
        state.visHome[STATE_SEARCH.SEARCH_HOMEPAGE_RESULTS],
      pagination: (state) =>
        state.visHome[STATE_SEARCH.SEARCH_HOMEPAGE_PAGINATION],
    }),
    ...mapGetters({
      loading: GETTER_GENERAL.GET_LOADING,
      getSearchResults: GETTER_SEARCH.GET_SEARCH_HOMEPAGE_RESULTS,
      searchPagination: GETTER_SEARCH.GET_HOMEPAGE_PAGINATION,
    }),
    isLoading() {
      return this.loading[LoadingComponent.HomepageList];
    },
    columns() {
      return [
        {
          label: "",
          field: columnKeys.TYPEICON,
          width: "30px",
          sortable: false,
        },
        {
          label: "generalPages.labelName",
          field: columnKeys.NAME,
        },
        {
          label: "generalPages.labelType",
          field: columnKeys.TYPE,
        },
        {
          label: "generalPages.labelOwner",
          field: columnKeys.OWNER,
        },
        {
          label: "generalPages.labelCreatedAt",
          field: columnKeys.CREATE_DATE,
        },
        {
          label: "generalPages.labelUpdatedAt",
          field: columnKeys.UPDATED_DATE,
        },
        {
          label: "generalPages.Object Id",
          field: columnKeys.ID,
        },
        {
          label: "generalPages.Directory",
          field: columnKeys.FOLDER_NAMES,
        },
      ];
    },

    filteredRows() {
      let filtered = filterByValue(this.dashboardsWithFolders);

      if (this.filterValueApplied.owner)
        filtered = filtered.filter(
          (f) => f.created_by === this.filterValueApplied.owner
        );

      if (this.filterValueApplied.objectName)
        filtered = filtered.filter((f) =>
          f.name.includes(this.filterValueApplied.objectName)
        );

      if (this.filterValueApplied.objectId)
        filtered = filtered.filter((f) =>
          f.id.includes(this.filterValueApplied.objectId)
        );

      if (this.filterValueApplied.objectType)
        filtered = filtered.filter((f) =>
          f.type === this.filterValueApplied.objectType
        );

      if (this.filterValueApplied.createdAt?.length === 2) {
        const momentCreatedAtStart = moment(
          this.filterValueApplied.createdAt[0]
        );
        const momentCreatedAtEnd = moment(this.filterValueApplied.createdAt[1]);

        filtered = filtered.filter((f) => {
          return moment(f.created_date).isBetween(
            momentCreatedAtStart,
            momentCreatedAtEnd
          );
        });
      }

      if (this.filterValueApplied.updatedAt?.length === 2) {
        const momentUpdatedAtStart = moment(
          this.filterValueApplied.updatedAt[0]
        );
        const momentUpdatedAtEnd = moment(this.filterValueApplied.updatedAt[1]);

        filtered = filtered.filter((f) => {
          return moment(f.updated_date).isBetween(
            momentUpdatedAtStart,
            momentUpdatedAtEnd
          );
        });
      }

      return convertListToAccordingToType(filtered);
    },

    owners() {
      return this.dashboardsWithFolders.map((d) => d.created_by);
    },

    filterOptions() {
      const distinctOwners = removeusingSet(this.owners);

      return {
        objectNameDropdown: [
          {
            value: "Contains",
            label: this.$t("generalPages.Contains"),
          },
        ],
        objectType: [
          { label: "generalPages.Datamodel", value: ObjectType.DATAMODEL },
          { label: "generalPages.Dashboard", value: ObjectType.DASHBOARD },
        ],
        owner: distinctOwners.map((o) => ({
          value: o,
          label: o,
        })),
      };
    },
  },
  methods: {
    ...mapActions({
      fetchHomePageSearch: ACTION_SEARCH.FETCH_HOMEPAGE_SEARCH,
    }),
    ...mapMutations({
      resetHomepageSearch: MUTATION_HOME.RESET_SEARCH_HOMEPAGE_RESULTS,
    }),
    searchFolder() {
      this.searchQueryData.find(
        (data) => data.key === HOMEPAGE_SEARH_PARAMS.SEARCH_KEY
      ).value = this.quickSearchValue;
      this.resetHomepageSearch();
      this.getDashboardAndDatamodelList(this.searchQueryData);
    },
    async getDashboardAndDatamodelList(queryData) {
      await this.fetchHomePageSearch({
        LoadingComponent: LoadingComponent.HomepageList,
        queryParam: getSearchQueryParams(queryData),
      });
    },
    async findSelectedRow(rows) {
      let rowIndex;
      rows?.find((row, index) =>
        row.id === this.lastSelectedObject.id ? (rowIndex = index) : ""
      );

      return rows?.[rowIndex];
    },
    handleCurrentChange(val) {
      let queryDataPageValue = this.searchQueryData.find(
        (x) => x.key === HOMEPAGE_SEARH_PARAMS.PAGE
      ).value;
      if (queryDataPageValue !== val - 1) {
        this.searchQueryData.find(
          (x) => x.key === HOMEPAGE_SEARH_PARAMS.PAGE
        ).value = val - 1;
        this.resetHomepageSearch();
        this.getDashboardAndDatamodelList(this.searchQueryData);
      }
    },
    resetCurrentPage() {
      this.$refs["migrate-selection-table"].changePage(1);
    },
    applyFilters() {
      this.filterValueApplied = cloneDeep(this.filterValue);
      this.showFilterPopup = false;
    },
    closeFilterPopup() {
      this.filterValue = cloneDeep(this.filterValueApplied);
      this.showFilterPopup = false;
    },
    migrateSelections() {
      const selectedRows = this.$refs["migrate-selection-table"].selectedRows;

      if (selectedRows?.length !== 1) {
        // throw notification error
        const resp = {
          message: this.$t("admin.migrate.You should select one object"),
          data: "",
          duration: 3000,
          customClass: "center vis-notification",
          title: this.$t("notifyTitle.warning"),
          type: "warning",
        };

        Notification(resp);

        return;
      }

      this.$emit("onContinueClicked", selectedRows[0], this.searchQueryData);
    },
    formatISOToDateTime(val) {
      return formatISOToDateTime(val);
    },
    getDirectoryText(folders) {
      if (folders?.length) {
        return cloneDeep(folders).reverse().join(" > ");
      }

      return "";
    },
  },
};
</script>

<style scoped>
.migrate-content-container {
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.migrate-content-header {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding: 20px 30px 0;
  font-size: 26px;
  font-weight: 600;
  color: #3e3e3e;
}
.migrate-content-header-filters {
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.migrate-content-header-add-button {
  margin-left: auto;
}
.migrate-content-selection-table {
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  overflow-y: hidden;
}

::v-deep table.vgt-table thead tr {
  background-color: #fff !important;
}
::v-deep .vgt-table th.vgt-checkbox-col {
  background: #fff !important;
}
::v-deep table.vgt-table tbody tr:hover {
  background-color: #fff !important;
}
::v-deep .vgt-wrap__footer {
  background: white !important;
}
::v-deep .footer__navigation__page-btn .chevron.left::after {
  border-right: 6px solid var(--primary-lighteen-1) !important;
}
::v-deep .footer__navigation__page-btn .chevron.right::after {
  border-left: 6px solid var(--primary-lighteen-1) !important;
}
::v-deep .el-dialog__title {
  font-weight: 500;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid var(--primary-lighteen-1);
  padding: 20px 20px;
}
::v-deep .el-pager li {
  font-weight: 400 !important;
}
.filter-row {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.filter-row-title {
  min-width: 150px;
}
.migrate-content-header-title {
  font-size: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
