import { checkTypeSelectorDataItem, DatamodelContextDefaults } from "../commons/dataModelTypes";
import {
  formatMeasure,
  formatNumberValues,
} from "../components/panel/measures/js/formatMeasures";
import { THOUSAND_SEPERATOR_ENUM, dateType } from "../components/panel/measures/js/measures";
import {
  BOX_KEY_ENUM,
  chartRules,
  chartTypes,
  detailsKeys,
  seriesTypeRadar,
  seriesTypeSankey,
  seriesTypeScatter,
  seriesTypeWithoutColorBy,
} from "../commons/dashboardAndPanel";
import { purgeDuplicatesFromArray } from "./utils";
import i18n from "../i18n";
import {
  LegendDirection,
  breakCharEnums,
  dashboardSPEnums,
  legendGridDistanceAccordingToMaxCharacterArr,
  legendPositionEnums,
  percentageValueKeys,
  showOnlyTypes,
  toolTipEnums,
  xAxisWidthList,
} from "../commons/dashboardProperties";
import { isChartHeightLimited, isChartWidthLimited } from "./chartViewHelper";

export const getLeftValueWithLegendOrientPosition = (
  horizontalAlign,
  left,
  right
) => {
  if (horizontalAlign.toLowerCase() === LegendDirection.LEFT) {
    return left;
  } else if (horizontalAlign.toLowerCase() === LegendDirection.RIGHT) {
    return right;
  }
};
export const checkLengthColorbySlot = (panelData) => {
  return panelData?.details?.aggregation?.filter(
    (x) => x.boxKey === BOX_KEY_ENUM.COLORBY.KEY
  )?.length;
};

export const checkLengthMeasuresSlot = (panelData) => {
  return panelData?.details?.[detailsKeys.METRICS]?.filter(
    (x) => x.boxKey === BOX_KEY_ENUM.MEASURES.KEY
  )?.length > 1;
};

export const isAxisNameActive = (paramsFromSearch, axisName) => {
  return paramsFromSearch?.dataSeriesNames?.length > 0 ? axisName : "";
};
export const isAxisLocationDefault = (location) => {
  if (location == LegendDirection.RIGHT) return false;
  else return true;
};
export const isNameLocationDefault = (location) => {
  if (location == "end") return false;
  else return true;
};
export const defaultLabel = "Value";

export const truncateText = (text, maxCharacter = 10, isWrapped) => {
  if(!isWrapped && text?.length >= maxCharacter) {
    return text?.toString().substring(0, maxCharacter) + " ...";
  } else {return text;}
};

export const xAxisWidthHandler = (isWrapped, maxCharacter = 10) => {
  if(isWrapped) return xAxisWidthList.find((x) => x.label === maxCharacter.toString()).value;
}

export const getConcatArrayWithPipe = (items) => {
  return items?.join(" | ");
};

export const getDataSeriesNames = (colorBy, point, dataSeriesNames) => {
  if (colorBy && !dataSeriesNames.includes(colorBy)) {
    return dataSeriesNames.push(colorBy);
  } else if (!colorBy && !dataSeriesNames.includes(point)) {
    return dataSeriesNames.push(point);
  }
};

export const getSplitWithPipe = (item) => {
  return item?.split(" | ");
};

export const formatValueController = (format, value) => {
  return format && value ? formatMeasure(value, format) : value;
};

export const xAxisLabelPaddingByRotate = (rotate) => {
  return rotate == "45" ? 12 : 0;
};

const formatPercentageValue = (val, total, values) => {
  const flattenValues = values?.flat();
  const containsNumberValue = flattenValues?.find(item => typeof item === "number");
 
  if (!flattenValues?.length || !containsNumberValue) return ((val / total) * 100).toFixed();
  //TODO -- Charts that use the generalChartSearch function will be handled in this section. A more comprehensive approach will be developed for other charts.
  //Exmp: Pie, Bar, Column ..
  const percentage = (val / total) * 100;
  
  const totalPercentage = flattenValues.reduce((sum, value) => sum + (value / total) * 100, 0);

  return ((percentage / totalPercentage) * 100).toFixed(2);
};

export const formatPercentageType = (val, index, totalArr) => {
  return ((val / totalArr[index]) * 100).toFixed(2);
};

export const calculateGridDistances = ({
  yAxisPosition,
  showYAxisLabels,
  showSecondYAxisLabels,
  showXAxisLabels,
  xAxisRotate,
  showLegend,
  yAxisNameLocation,
  maxGridTopValue = 40,
  labelRotate,
  showLabel,
  legendPosition,
  legendMaxCharacter,
  xAxisDataZoom,
  yAxisDataZoom,
  panelData,
  showOnly,
  wrapXaxisLabel,
  xAxisLabelMaxCharacter,
  dataAxis
}) => {
  let gridLeft = 0,
    gridRight = 0,
    gridBottom = 0,
    gridTop = 0;
  const isAxisLocationDefaultVar = isAxisLocationDefault(yAxisPosition);

  if (
    !isChartWidthLimited(panelData?.w) &&
    isChartHeightLimited(panelData?.h) &&
    showXAxisLabels
  ) {
    gridLeft = 35;
  }

  if (!showXAxisLabels || !isChartHeightLimited(panelData?.h)) {
    gridBottom = 5;
  }
  if ((showLabel && labelRotate == 45) || labelRotate == 90) {
    gridTop = maxGridTopValue;
  }
  if (showXAxisLabels && ["45", "90"].includes(xAxisRotate)) {
    gridBottom = 15;
  }
 
  const setGridBottomByXaxisMaxCharacter = (longValue, shortValue) => {
    if (xAxisLabelMaxCharacter <= 15) {
      gridBottom = longValue;
    } 
    else if (xAxisLabelMaxCharacter > 15 && xAxisLabelMaxCharacter <= 25) gridBottom = shortValue;
  }
  const aggregations = panelData?.details?.aggregation;
  const isFieldTypeDate = aggregations?.length === 1 && aggregations?.find((x) => x.fieldType === DatamodelContextDefaults.USAGE_TYPES.DATE);
  
  if (wrapXaxisLabel && showXAxisLabels && xAxisRotate == 45 && !isFieldTypeDate) {
    if (dataAxis?.find((x) => x.length > 80)) setGridBottomByXaxisMaxCharacter(-60, -30);
    else if (dataAxis?.find((x) => x.length > 25 && x.length < 60)) setGridBottomByXaxisMaxCharacter(-5, 20);
  }
  
  if (
    [
      showOnlyTypes.itemPercent,
      showOnlyTypes.itemValue,
      showOnlyTypes.valuePercent,
      showOnlyTypes.itemPercentValue,
    ].includes(showOnly) &&
    showLegend
  ) {
    gridTop = 40;
  }

  if (showSecondYAxisLabels) {
    showYAxisLabels ? (gridLeft = 10) : (gridLeft = 40);
    showSecondYAxisLabels ? (gridRight = 10) : (gridRight = 40);
  } else {
    if (isAxisLocationDefaultVar) {
      gridRight = 30;
      showYAxisLabels ? (gridLeft = 10) : (gridLeft = 40);
    } else {
      gridLeft = 40;
      showYAxisLabels ? (gridRight = 10) : (gridRight = 40);
    }
  }

  if (
    [
      showOnlyTypes.itemPercent,
      showOnlyTypes.itemValue,
      showOnlyTypes.valuePercent,
    ].includes(showOnly)
  ) {

    if ([chartTypes.BAR, chartTypes.SCATTER_CHART].includes(panelData.type)) {
      gridRight = 50;
    }

    gridTop = 30;
  }

  if ([showOnlyTypes.itemPercentValue].includes(showOnly)) {
    
    if ([chartTypes.BAR, chartTypes.SCATTER_CHART].includes(panelData.type)) {
      gridRight = 50;
    }

    gridTop = 40;
  }
  
  if (xAxisDataZoom) {

    if (panelData.type === chartTypes.HEATMAP_CHART) {
      gridTop = 0;
      gridBottom = 25;
    } else {
      gridBottom = 50;
    }
  }
 
  if (yAxisDataZoom) {
    gridRight = 50;
  }

  if (showLegend) {
    if (
      legendPosition !== legendPositionEnums.RIGHT &&
      legendPosition !== legendPositionEnums.LEFT
    ) {
      gridTop = maxGridTopValue;
    } else if (legendPosition === legendPositionEnums.RIGHT) {
      gridRight = legendGridDistanceAccordingToMaxCharacterArr.find(
        (x) => x.maxCharacter === legendMaxCharacter).gridDistance;
      gridLeft = yAxisDataZoom ? 50 : 0;
    } else if (legendPosition === legendPositionEnums.LEFT) {
      gridLeft = legendGridDistanceAccordingToMaxCharacterArr.find(
        (x) => x.maxCharacter === legendMaxCharacter
      ).gridDistance;
      gridRight = yAxisDataZoom ? 50 : 0;
    } else if (
      !isAxisLocationDefaultVar &&
      !isNameLocationDefault(yAxisNameLocation)
    ) {
      gridTop = maxGridTopValue;
    } else {
      gridTop = 20;
      gridLeft = 0;
      gridRight = 0;
    }
  }

  if (!isChartHeightLimited(panelData?.h)) gridTop = 0;

  return { gridLeft, gridRight, gridBottom, gridTop };
};

const tooltipNameLabel = (aggregation) => {
  let nameList = aggregation
    ?.filter((x) => x?.boxKey !== BOX_KEY_ENUM.COLORBY.KEY)
    ?.map((y) => y?.alias);
  let nameData = nameList?.join(" | ");
  return nameData;
};
const tooltipSeriesNameLabel = (aggregation, key) => {
  let seriesNameList = aggregation
    ?.filter((x) => x?.boxKey === key)
    ?.map((y) => y?.alias);
  let seriesNameData = seriesNameList?.join(" | ");
  return seriesNameData;
};
const tooltipFormattedValueLabel = (metrics) => {
  let valueFormattedList = metrics?.map((x) => x?.alias);
  let valueFormattedData = valueFormattedList?.join(" | ");
  return valueFormattedData;
};

export const generateHTMLForTooltipWithSinglePoint = (
  serie,
  format,
  panelData
) => {
  if (!serie) return;
  let { name, seriesName, value, color } = serie;
  const aggregation = panelData?.details?.[detailsKeys.AGGREGATION];
  const metrics = panelData?.details?.[detailsKeys.METRICS];
  let defaultSeriesName = [];
  let formatSeriesName = [];
  let formattedValueLabel;

  if (
    metrics?.length > 1 &&
    aggregation?.filter((x) => x.boxKey === BOX_KEY_ENUM.COLORBY.KEY).length >=
      1
  ) {
    formatSeriesName = seriesName.split(" | ");
    defaultSeriesName = seriesName.split(formatSeriesName?.[0] + " | ");
    seriesName = defaultSeriesName?.[1]; // ColorBy alanındaki field'ı çeker
    formattedValueLabel = formatSeriesName?.[0]; // Measures alanındaki field'ı çeker
  } else {
    defaultSeriesName = metrics?.map((x) => x?.alias);
    formattedValueLabel = seriesName;
  }

  const formattedValue = formatValueController(format, value);
  const values = [name, seriesName, formattedValue];
  const valuesLabel = [
    tooltipNameLabel(aggregation),
    tooltipSeriesNameLabel(aggregation, BOX_KEY_ENUM.COLORBY.KEY),
    defaultSeriesName?.length < 2
      ? tooltipFormattedValueLabel(metrics)
      : formattedValueLabel,
  ];
  const colorOrder = [null, color, null];

  const rows = valuesLabel.map((name, index) => {
    return name?.length
      ? generateHTMLForTooltipSerieRow(colorOrder[index], name, values[index])
      : "";
  });

  return `<div>
              ${rows.join("")}
          </div>`;
};

export const generateHTMLForTooltipWithCategoryAxes = (
  serie,
  formatMap,
  aggregation,
  metrics
) => {
  const {
    seriesName,
    value: [, , data],
    name,
    rowName,
    color,
  } = serie;
  const formattedValue = formatValueController(formatMap?.[seriesName], data);
  const measures = generateHTMLForTooltipSerieRow(
    color,
    tooltipFormattedValueLabel(metrics),
    formattedValue
  );
  const column = generateHTMLForTooltipSerieRow(
    null,
    tooltipSeriesNameLabel(aggregation, BOX_KEY_ENUM.COLUMN.KEY),
    name
  );
  const row = generateHTMLForTooltipSerieRow(
    null,
    tooltipSeriesNameLabel(aggregation, BOX_KEY_ENUM.ROW.KEY),
    rowName
  );

  return `<div>
                <div>
                ${row}
                </div>
                <div>
                ${column}
                </div>
                ${measures}
            </div>`;
};

export const generateHTMLForScatterTooltip = (
  serie,
  formats,
  aggregation,
  metrics
) => {
  if (!serie || !aggregation?.length) return;
  let { value, seriesName, color } = serie;
  const defaultSeriesName = seriesName?.split("|");
  seriesName =
    defaultSeriesName?.length > 1
      ? defaultSeriesName?.[1]
      : defaultSeriesName?.[0];
  // Axislerin attribute veya metric değer almasına göre değişmesini sağlıyor
  const axisValueList = (boxkey) =>
    metrics.filter((x) => x.boxKey === boxkey).length ? metrics : aggregation;
  // Scatter ve Bubble chartlarda ortak kullanıldığı için, point-size box yapısını düzenlemek için eklendi
  value = value.filter((x) => x !== undefined && x !== "");
  let labelList = [
    tooltipSeriesNameLabel(
      axisValueList(BOX_KEY_ENUM.XAXIS.KEY),
      BOX_KEY_ENUM.XAXIS.KEY
    ) ?? "",
    tooltipSeriesNameLabel(
      axisValueList(BOX_KEY_ENUM.YAXIS.KEY),
      BOX_KEY_ENUM.YAXIS.KEY
    ) ?? "",
    tooltipSeriesNameLabel(aggregation, BOX_KEY_ENUM.POINTS.KEY) ?? "",
    tooltipSeriesNameLabel(metrics, BOX_KEY_ENUM.POINT_SIZE.KEY) ?? "",
    tooltipSeriesNameLabel(aggregation, BOX_KEY_ENUM.COLORBY.KEY) ?? "",
  ];
  labelList = labelList?.filter((x) => x !== undefined && x !== ""); // Field undefiend geldiği durumlarda almıyor
  const colorLabelList = labelList.map((x, index) =>
    index + 1 === labelList.length ? (x = color) : (x = null)
  );
  const rows = value.map((v, index) => {
    switch (index) {
      case 0:
        v = formatValueController(formats.x?.[0], v);
        break;
      case 1:
        v = formatValueController(formats.y?.[0], v);
        break;
      case 3:
        v = formatValueController(formats.pointSize?.[0], v);
        break;
      default:
        break;
    }
    return generateHTMLForTooltipSerieRow(
      colorLabelList[index],
      labelList[index],
      v
    );
  });
  return `<div>
                ${rows.join("")}
            </div>`;
};

export const generateHTMLForTreemapTooltip = (
  color,
  seriesName,
  formattedValue,
  aggregation,
  metrics
) => {
  if (!seriesName || !formattedValue) return;
  return (
    generateHTMLForTooltipSerieRow(
      null,
      tooltipSeriesNameLabel(aggregation, BOX_KEY_ENUM.BRANCHES.KEY),
      seriesName
    ) +
    generateHTMLForTooltipSerieRow(
      color,
      tooltipFormattedValueLabel(metrics),
      formattedValue
    )
  );
};

const generateHTMLForTooltipSerieRow = (color, seriesName, value) => {
  return `
        <div style="display: flex; align-items:center; gap: 8px;">
            <span style="width: 10px; height: 10px; background-color: ${color}; border-radius: 50%"></span>
            <div style="display: flex; flex: 1; gap: 10px; justify-content: space-between;">
                <span>${seriesName || defaultLabel}</span>
                <b>${value || "-"}</b>
            </div>
        </div>
    `;
};

export const formatValueAxisLabels = (metricFormats, value, percentType) => {
  if (!metricFormats || !value || typeof value !== "number") {
    return value;
  }

  const axisFormat = metricFormats.reduce((prev, current) => {
    if (!current) {
      return prev;
    }

    if (Object.keys(prev).length === 0) {
      return current;
    }

    if (prev.scale < current.scale) {
      prev.scale = current.scale;
    }

    if (prev.thousandSeperator === THOUSAND_SEPERATOR_ENUM.NONE) {
      prev.thousandSeperator = current.thousandSeperator;
    }

    return prev;
  }, {});

  if (percentType) {
    let formattedValue = formatNumberValues(value, null);
    return formattedValue + "%";
  }

  return formatNumberValues(value, axisFormat);
};

export const createMetricKey = (field) => {
  if (!field) return;

  return field.field + field.type;
};

export const mapAxisItems = (field) => {
  let fieldName = "";
  if (field.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE) {
    fieldName = field.field + field.type;
  } else {
    fieldName = field.field;
  }

  return { field: fieldName, format: field.format, alias: field.alias };
};
export const isColorBySlotFullAndBarsSlotEmpty = (aggregations) => {
  const aggregationKey = aggregations.find(
    (x) => x.boxKey !== BOX_KEY_ENUM.COLORBY.KEY
  );
  const colorByKey = aggregations.find(
    (x) => x.boxKey === BOX_KEY_ENUM.COLORBY.KEY
  );

  return !aggregationKey && colorByKey;
};
export const getBarColorItemStyle = (value, panelData) => {
  if (isColorBySlotFullAndBarsSlotEmpty(panelData.details.aggregation)) {
    return { color: value };
  } else return undefined;
};
export const getSelectedColorPaletteByProperty = (property, index) => {
  if (index || index === 0) {
    return (
      property?.paletteColors?.[index || 0] || property?.colors?.[index || 0]
    );
  } else {
    return property?.paletteColors || property?.colors;
  }
};

export const colorConverter = (color, defaultColor, defaultFillerColor) => {
  if (color === defaultColor){
    return defaultFillerColor ?? defaultColor;
  }

  else {
    return hexColorToRGBAConverter(color);
  }
  
}

export const sliderPositionHandler = (isLegendActive, legendPosition) => {
  return legendPosition === legendPositionEnums.RIGHT ? 0 : 'auto'
}

export const hexColorToRGBAConverter = (color) => {
  const convertedColor = hexColorToRGBConvertWithObject(color);
  
  return `rgba(${convertedColor.r}, ${convertedColor.g}, ${convertedColor.b}, ${0.25})`
}

export const hexColorToRGBConvertWithObject = (color) => {
  if (!color || color === "") return null;

  let hexColor = color?.replace('#', '');
  
  let r = parseInt(hexColor.substring(0, 2), 16)
  let g = parseInt(hexColor.substring(2, 4), 16)
  let b = parseInt(hexColor.substring(4, 6), 16)

  return { r, g, b };
}

export const mapChartDataByColorSlot = (
  metrics,
  aggregations,
  searchData,
  xAxisKeys,
  isTooltipAxisTrigger = false
) => {
  if (!metrics) return;

  const metricKeys = metrics?.map(mapAxisItems);
  const aggregationKeys = aggregations
    ?.filter((i) => i.boxKey !== BOX_KEY_ENUM.COLORBY.KEY)
    .map((a) => a.field);
  const colorByKeys = aggregations
    ?.filter((i) => i.boxKey === BOX_KEY_ENUM.COLORBY.KEY)
    .map(mapAxisItems);

  let dataSeries = [],
    dataSeriesNames = [],
    metricFormats = [];

  if (!colorByKeys.length) return null;

  // collect every existing colorBy value combinations
  let colorByValues = searchData?.map((i) => {
    return getConcatArrayWithPipe(
      colorByKeys.map((c) => {
        return formatValueController(c.format, i[c.field]);
      })
    );
  });

  colorByValues = purgeDuplicatesFromArray(colorByValues);
  // map data series
  metricKeys.forEach((metricKey) => {
    const result = [];
    searchData?.forEach((data) => {
      const colorValue = getConcatArrayWithPipe(
          colorByKeys.map((i) => formatValueController(i.format, data[i.field]))
        ),
        axisValue = isColorBySlotFullAndBarsSlotEmpty(aggregations)
          ? getConcatArrayWithPipe(colorByKeys.map((i) => data[i.field]))
          : getConcatArrayWithPipe(aggregationKeys.map((i) => data[i])),
        colorIndex = colorByValues.indexOf(colorValue),
        valueIndex = xAxisKeys.indexOf(axisValue);

      if (!result[colorIndex]) {
        result[colorIndex] = [];
        metricFormats.push(metricKey.format);
      }

      result[colorIndex][valueIndex] = data[metricKey.field];
    });

    dataSeries = [...dataSeries, ...result];
  });

  // prepare legend values
  if (metricKeys.length > 1 || isTooltipAxisTrigger) {
    metrics?.forEach((m) => {
      colorByValues.forEach((colorVal) => {
        dataSeriesNames.push(`${m.alias} | ${colorVal}`);
      });
    });
  } else {
    dataSeriesNames = colorByValues;
  }

  return { dataSeries, dataSeriesNames, metricFormats };
};

export const getTooltipHeadersForGeneralCharts = (aggregations, chartType) => {
  const result = Array(3).fill("");
  const hasColorByField = aggregations.find(
    (i) => i.boxKey === BOX_KEY_ENUM.COLORBY.KEY
  );
  const boxNames = chartRules[chartType].tooltipKeyOrder;

  if (aggregations?.length) result[0] = boxNames[0];

  if (hasColorByField) result[1] = BOX_KEY_ENUM.COLORBY.TEXT;

  result[2] = boxNames[boxNames.length - 1];

  return result;
};

const isSeriesTypeRadar = (value) => {
  return value.seriesType === seriesTypeRadar.RADAR;
};

const isSeriesTypeSankey = (value) => {
  return value.seriesType === seriesTypeSankey;
};

const isColorByFieldExist = (aggregation) => {
  return aggregation.find((x) => x.boxKey === BOX_KEY_ENUM.COLORBY.KEY);
}

const isSeriesTypeScatterOrBubble = (value) => {
  return value.seriesType === seriesTypeScatter.SCATTER
};

const percentageForScatterOrBubble = (
  dataValue,
  totalList
) => {
  let formattedPercentage = dataValue?.map((v, index) => {
      v = formatPercentageValue(v, totalList[index])
      return [v]
    })
  return formattedPercentage.join("% | ");
};

const formatValueForScatterOrBubble = (
  paramsFromSearch,
  dataValue
) => {
  const formats = paramsFromSearch.formatMap;
  let formattedData = dataValue.map((v, index) => {
    switch (index) {
      case 0:
        v = formatValueController(formats.x?.[0], v);
        break;
      case 1:
        v = formatValueController(formats.y?.[0], v);
        break;
      case 3:
        v = formatValueController(formats.pointSize?.[0], v);
        break;
      default:
        break;
    }
    return [v];
  });
  return formattedData.join(" | ");
};

const isDateItem = (value) => {
  const date = new Date(value)

  return !isNaN(date.getTime());
}

export const aggregationDateFormatController = (name, aggregationFormats) => {
  const nameList = name.split(" | ");
  let formatIndex = []

  aggregationFormats?.forEach((item, index) => item?.type === dateType ? formatIndex.push(index) : "");

  return nameList
    .map((n, i) => isDateItem(n) ?
      getChartLabelFormatByAggregation(n, [aggregationFormats?.[formatIndex[i]]])
      :
      n
    )
    .join(" | ");
};

// bu fonksiyon formatlanan değeri manipüle etmektir
export const setShowOnlyValue = (
  value,
  paramsFromSearch,
  computedValues,
  showOnlyEnumKey,
  dashboarEnumKey,
  panelData,
  showPercentType,
  percentType
) => {
  //The tooltip should not appear if neither name nor value is present.
  if (!value.name && !value.value) return "";
  const aggregation = panelData?.details?.[detailsKeys.AGGREGATION].filter(x=> !checkTypeSelectorDataItem(x.fieldType));
  const metrics = panelData?.details?.[detailsKeys.METRICS].filter(x=> !checkTypeSelectorDataItem(x.type));
  let defaultSeriesName = [];
  let formatSeriesName = [];
  let formattedValueLabel;
  if (metrics?.length > 1) {
    formatSeriesName = isSeriesTypeRadar(value)
      ? value?.name?.split(" | ")
      : value?.seriesName?.split(" | ");
    formattedValueLabel = isSeriesTypeScatterOrBubble(value) ? metrics?.map((x) => x?.alias).join(" | ") : formatSeriesName?.[0]; // Measures alanındaki field'ın alias'ını çeker
    if (
      aggregation?.filter((x) => x.boxKey === BOX_KEY_ENUM.COLORBY.KEY).length > 1
    ) {
      isSeriesTypeScatterOrBubble(value) ? "" : formatSeriesName.shift();
      defaultSeriesName = formatSeriesName;
      value.seriesName = defaultSeriesName.join(" | "); // ColorBy alanındaki field'ın alias'ını çeker
    } else if (
      aggregation?.filter((x) => x.boxKey === BOX_KEY_ENUM.COLORBY.KEY).length === 1
    ) {
      defaultSeriesName = isSeriesTypeRadar(value)
        ? value?.name?.split(formatSeriesName?.[0] + " | ")
        : value?.seriesName?.split(formatSeriesName?.[0] + " | ");
      value.seriesName = defaultSeriesName?.[1] || value.seriesName; // ColorBy alanındaki field'ın alias'ını çeker
    } else {
      defaultSeriesName = isSeriesTypeRadar(value)
        ? value?.name?.split(formatSeriesName?.[0])
        : value?.seriesName?.split(formatSeriesName?.[0]);
    }
  } else {
    defaultSeriesName = metrics?.map((x) => x?.alias);
    isSeriesTypeRadar(value) ? (value.seriesName = value.name) : "";
    formattedValueLabel = defaultSeriesName[0];
  }

  if (panelData.type === chartTypes.SANKEY_CHART) {
    value.seriesName = tooltipFormattedValueLabel(metrics);
    formattedValueLabel = tooltipFormattedValueLabel(metrics);
  }
  
  const dataValue = value?.data?.displayValue || value.value;
  const valueFormatted = isSeriesTypeScatterOrBubble(value)
    ? formatValueForScatterOrBubble(
        paramsFromSearch,
        dataValue
      )
    : formatValueController(
        getMetricFormat(
          formattedValueLabel,
          metrics
        ),
        dataValue
      ) || "";
  let percentage;

  if (showPercentType) {
    let indexTotal = [];
    paramsFromSearch?.dataSeries?.forEach((item) => {
      item?.forEach((y, index) => {
        if (indexTotal[index]) {
          indexTotal[index] += y;
        } else {
          indexTotal[index] = y;
        }
      });
    });
    percentage = formatPercentageType(dataValue, value?.dataIndex, indexTotal);
  } else if (percentType === percentageValueKeys.COLOR) {
      let indexTotal = [];
      paramsFromSearch?.dataSeries?.forEach((item, index) => {
        item?.forEach((y) => {
          if (indexTotal[index]) {
            indexTotal[index] += y;
          } else {
            indexTotal[index] = y;
          }
        })
      })
    percentage = formatPercentageType(dataValue, value?.seriesIndex, indexTotal);
  } else {
    percentage = isSeriesTypeScatterOrBubble(value) ?
    percentageForScatterOrBubble(
      dataValue, 
      paramsFromSearch?.dataSeriesTotal
    )
    :
    formatPercentageValue(
      dataValue,
      paramsFromSearch?.dataSeriesTotal,
      paramsFromSearch?.dataSeries
    )
  }
  // Bu kısım series kısmını showOnly'ye göre değiştiriyor
  let seriesObj = {
    [showOnlyTypes.value]: {
      serviceFn: () => {
        return " " + valueFormatted + " ";
      },
    },
    [showOnlyTypes.itemValue]: {
      serviceFn: () => {
        return value?.name + breakCharEnums.CHARN + valueFormatted;
      },
    },
    [showOnlyTypes.itemPercent]: {
      serviceFn: () => {
        return value?.name + breakCharEnums.CHARN + percentage + "%";
      },
    },
    [showOnlyTypes.itemPercentValue]: {
      serviceFn: () => {
        return (
          value?.name +
          breakCharEnums.CHARN +
          percentage +
          "%" +
          breakCharEnums.CHARN +
          valueFormatted
        );
      },
    },
    [showOnlyTypes.valuePercent]: {
      serviceFn: () => {
        return valueFormatted + breakCharEnums.CHARN + percentage + "%";
      },
    },
    [showOnlyTypes.percent]: {
      serviceFn: () => {
        return " " + percentage + "%" + " ";
      },
    },
  };
  // Bu kısım tooltip yapısını showOnly'ye göre değiştiriyor
  const getValue = () => {
    let val;
    val =
      ((isColorByFieldExist(aggregation) && !isSeriesTypeScatterOrBubble(value)) && 
        !isSeriesTypeSankey(value) && 
        !checkSeriesTypeContainsMap(value.seriesType)
        ? generateHTMLForTooltipSerieRow(
            value?.color,
            tooltipSeriesNameLabel(aggregation, BOX_KEY_ENUM.COLORBY.KEY),
            value?.seriesName
          )
        : "") +
      generateHTMLForTooltipSerieRow(
        !checkSeriesTypeContainsMap(value.seriesType) ? null : value?.color,
        defaultSeriesName.length < 2
          ? tooltipFormattedValueLabel(metrics)
          : formattedValueLabel,
        valueFormatted
      );
    return val;
  };
  const getItemValue = () => {
    return (
      (isSeriesTypeRadar(value)
        ? ""
        : generateHTMLForTooltipSerieRow(
            null,
            tooltipNameLabel(aggregation),
            value?.name
          )) +
      ((isColorByFieldExist(aggregation) && !isSeriesTypeScatterOrBubble(value)) &&
      !isSeriesTypeSankey(value) && 
      !checkSeriesTypeContainsMap(value.seriesType)
        ? generateHTMLForTooltipSerieRow(
            value?.color,
            tooltipSeriesNameLabel(aggregation, BOX_KEY_ENUM.COLORBY.KEY),
            value?.seriesName
          )  : "" ) +
      generateHTMLForTooltipSerieRow(
        !checkSeriesTypeContainsMap(value.seriesType) ? null : value?.color,
        defaultSeriesName?.length < 2
          ? tooltipFormattedValueLabel(metrics)
          : formattedValueLabel,
        valueFormatted
      )
    );
  };
  const getItemPercent = () => {
    return (
      (isSeriesTypeRadar(value)
        ? ""
        : generateHTMLForTooltipSerieRow(
            null,
            tooltipNameLabel(aggregation),
            value?.name
          )) +
      ((isColorByFieldExist(aggregation) && !isSeriesTypeScatterOrBubble(value)) &&
        !isSeriesTypeSankey(value) && 
        !checkSeriesTypeContainsMap(value.seriesType)
        ? generateHTMLForTooltipSerieRow(
            value?.color,
            tooltipSeriesNameLabel(aggregation, BOX_KEY_ENUM.COLORBY.KEY),
            value?.seriesName
          )
        : "") +
      generateHTMLForTooltipSerieRow(
        !checkSeriesTypeContainsMap(value.seriesType) ? null : value?.color,
        i18n.tc(`${toolTipEnums.PERCENT}`),
        percentage + "%"
      )
    );
  };
  const getItemPercentValue = () => {
    return (
      (isSeriesTypeRadar(value)
        ? ""
        : generateHTMLForTooltipSerieRow(
            null,
            tooltipNameLabel(aggregation),
            value?.name
          )) +
      ((isColorByFieldExist(aggregation) && !isSeriesTypeScatterOrBubble(value)) && 
        !isSeriesTypeSankey(value) && 
        !checkSeriesTypeContainsMap(value.seriesType)
        ? generateHTMLForTooltipSerieRow(
            value?.color,
            tooltipSeriesNameLabel(aggregation, BOX_KEY_ENUM.COLORBY.KEY),
            value?.seriesName
          )
        : "") +
      generateHTMLForTooltipSerieRow(
        !checkSeriesTypeContainsMap(value.seriesType) ? null : value?.color,
        defaultSeriesName?.length < 2
          ? tooltipFormattedValueLabel(metrics)
          : formattedValueLabel,
        valueFormatted
      ) +
      generateHTMLForTooltipSerieRow(
        null,
        i18n.tc(`${toolTipEnums.PERCENT}`),
        percentage + "%"
      )
    );
  };
  const getValuePercent = () => {
    return (
      ((isColorByFieldExist(aggregation) && !isSeriesTypeScatterOrBubble(value)) && 
        !isSeriesTypeSankey(value) && 
        !checkSeriesTypeContainsMap(value.seriesType)
        ? generateHTMLForTooltipSerieRow(
            value?.color,
            tooltipSeriesNameLabel(aggregation, BOX_KEY_ENUM.COLORBY.KEY),
            value?.seriesName
          )
        : "") +
      generateHTMLForTooltipSerieRow(
        !checkSeriesTypeContainsMap(value.seriesType) ? null : value?.color,
        defaultSeriesName.length < 2
          ? tooltipFormattedValueLabel(metrics)
          : formattedValueLabel,
        valueFormatted
      ) +
      generateHTMLForTooltipSerieRow(
        null,
        i18n.tc(`${toolTipEnums.PERCENT}`),
        percentage + "%"
      )
    );
  };
  const getPercent = () => {
    return (
      ((isColorByFieldExist(aggregation) && !isSeriesTypeScatterOrBubble(value)) && 
        !isSeriesTypeSankey(value) && 
        !checkSeriesTypeContainsMap(value.seriesType)
        ? generateHTMLForTooltipSerieRow(
            value?.color,
            tooltipSeriesNameLabel(aggregation, BOX_KEY_ENUM.COLORBY.KEY),
            value?.seriesName
          )
        : "") +
      generateHTMLForTooltipSerieRow(
        !checkSeriesTypeContainsMap(value.seriesType) ? null : value?.color,
        i18n.tc(`${toolTipEnums.PERCENT}`),
        percentage + "%"
      )
    );
  };

  const checkSeriesTypeContainsMap = (seriesType) => {
    return [
      seriesTypeWithoutColorBy.HEATMAP,
      seriesTypeWithoutColorBy.MAP,
      seriesTypeWithoutColorBy.TREEMAP,
    ].includes(seriesType);
  };

  let tooltipObj = {
    [showOnlyTypes.value]: {
      serviceFn: () => getValue(),
    },
    [showOnlyTypes.itemValue]: {
      serviceFn: () => getItemValue(),
    },
    [showOnlyTypes.itemPercent]: {
      serviceFn: () => getItemPercent(),
    },
    [showOnlyTypes.itemPercentValue]: {
      serviceFn: () => getItemPercentValue(),
    },
    [showOnlyTypes.valuePercent]: {
      serviceFn: () => getValuePercent(),
    },
    [showOnlyTypes.percent]: {
      serviceFn: () => getPercent(),
    },
  };

  if (dashboarEnumKey === dashboardSPEnums.TOOLTIP) {
    return tooltipObj[computedValues[showOnlyEnumKey]].serviceFn();
  } else {
    return seriesObj[computedValues[showOnlyEnumKey]].serviceFn();
  }
};

export const getMetricFormat = (
  formattedValueLabel,
  metrics
) => {
  return metrics?.find((x) => x.alias === formattedValueLabel)?.format;
};

export const getLabelPositionByRotate = (labelRotate) => {
  if (labelRotate == 45) return [0, -10];
  else if (labelRotate == 90) return [0, -10];
  else return "outside";
};
export const getForScaleGroupingDataSeries = (
  percentType,
  paramsFromSearch
) => {
  if (!percentType) return;
  let groupingDataSeries = {};
  paramsFromSearch?.dataAxis?.forEach((element, axisIndex) => {
    paramsFromSearch?.dataSeries?.forEach((ds) => {
      if (!groupingDataSeries[axisIndex]) {
        groupingDataSeries[axisIndex] = [ds[axisIndex]];
      } else {
        groupingDataSeries[axisIndex].push(ds[axisIndex]);
      }
    });
  });
  return groupingDataSeries;
};
export const getGroupingDataSeriesTotal = (
  percentType,
  groupingDataSeries,
  index
) => {
  if (!percentType) return;
  const total = groupingDataSeries[index]?.reduce((prev, cur) => {
    if (!prev) prev = 0;
    else if (!cur) cur = 0;
    return prev + cur;
  }, 0);
  return total;
};

export const getChartLabelFormatByAggregation = (value, aggregationFormats) => {
  const splittedValues = value?.split(" | ");
  let arr = [];
  splittedValues?.forEach((splitValue, index) => {
    const formattedValue = formatValueController(
      aggregationFormats?.[index],
      splitValue
    );
    arr.push(formattedValue);
  });

  return arr?.filter((x) => x).join(" | ");
};