<template>
  <div class="vis-chart-error-content-messages">
    <i v-if="icon" aria-hidden="true" :class="icon"></i>
    <div>
      {{ message }}
    </div>
  </div>
</template>

<script>
import { CustomIcon } from "../../assets/js/custom-icons";
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
};
</script>
