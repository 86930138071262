<template>
  <ContextPopup>
    <template v-slot:title>
      <span
        >{{ $t("measures.title") }} -
        <span class="vis-color-primary">{{ field }}</span>
      </span>
      <div class="vis-text--right">
        <i
          @click="resetFormat"
          class="mdi mdi-refresh vis-cursor-pointer vis-mr--1"
          aria-hidden="true"
        ></i>
        <i
          @click="$emit('closeFormat', null)"
          class="mdi mdi-close vis-cursor-pointer"
          aria-hidden="true"
        ></i>
      </div>
    </template>
    <template v-slot:content>
      <div class="vis-row">
        <div class="vis-col vis-col-5">
          <label class="vis-mb--1 vis-font-medium" for="">{{
            $t("measures.display")
          }}</label>
          <draggable
            :list="dateList"
            class="list-group vis-datePopup"
            ghost-class="ghost"
          >
            <div
              class="list-group-item"
              :class="{ active: item.isActive }"
              v-for="(item, index) in dateList"
              :key="index"
              @click="item.isActive = !item.isActive"
            >
              {{ $t(item.label) }}
              <i
                @click="
                  openContextMenu($event, item, `contextMenu_${containerId}`)
                "
                class="mdi mdi-dots-vertical"
                aria-hidden="true"
              />
              <ejs-contextmenu
                :id="`contextMenu_${containerId}`"
                :items="contextMenuItems"
                :select="handleContextMenuItemSelect"
                @beforeItemRender="itemBeforeEvent($event)"
              ></ejs-contextmenu>
            </div>
          </draggable>
        </div>
        <div class="vis-col vis-col-7 vis-datePopup-right">
          <div class="vis-row-box mb-5">
            <label class="vis-mb--1 vis-font-medium" for="">{{
              $t("measures.dateSeparator")
            }}</label>
            <el-select
              class="vis-mb--1"
              v-model="dateFormat"
              :filterable="true"
              :remote="true"
              :remote-method="filter"
              placeholder="Select Date"
            >
              <el-option
                v-for="item in dateFormats"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button
              @click="addNewDateFormat()"
              class="vis-ml--1"
              type="primary"
              size="mini"
              icon="el-icon-plus"
              circle
              :disabled="disabledBtn"
            ></el-button>
          </div>
          <div class="vis-row-box mb-5">
            <label class="vis-mb--1 vis-font-medium" for="">{{
              $t("measures.timeSeparator")
            }}</label>
            <el-select
              class="vis-mb--1"
              @change="clockFormatChange()"
              v-model="clockFormat"
              :filterable="true"
              placeholder="Select"
            >
              <el-option
                v-for="item in clockFormats"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="vis-row-box mb-5">
            <label class="vis-mb--1 vis-font-medium" for="">{{
              $t("measures.displayTime")
            }}</label>
            <el-select
              class="vis-mb--1"
              @change="clockFormatChange()"
              v-model="clockView"
              :filterable="true"
              placeholder="Select"
            >
              <el-option
                v-for="item in clockviews"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="vis-row-box">
            <label class="vis-mb--1 vis-font-medium" for="">{{
              $t("measures.example")
            }}</label>
            <div class="vis-date-resultText vis-mb-1">
              <span>{{ exampleDisplay }}</span>
            </div>
          </div>
        </div>
        <div class="vis-col vis-col-12 vis-text--right">
          <el-button size="small" @click="$emit('closeFormat', null)" plain>
            {{ $t("generalPages.cancel") }}</el-button
          >
          <el-button
            type="primary"
            size="small"
            class="vis-mt--2"
            @click="saveFormat"
            >{{ $t("generalPages.apply") }}</el-button
          >
        </div>
      </div>
    </template>
  </ContextPopup>
</template>
<script>
import draggable from "vuedraggable";
import ContextPopup from "../ContextPopup.vue";
import {
  dateType,
  defaultClockFormat,
  defaultDateFormat,
  defaultClockView,
  defaultDateList,
  dateList,
  clockFormats,
  dateFormats,
  clockviews,
  dateOptions,
} from "./js/measures";
import { createElement } from "@syncfusion/ej2-base";
import { formatMeasure } from "./js/formatMeasures";
import { v4 as uuidv4 } from "uuid";
import cloneDeep from "clone-deep";

export default {
  components: {
    draggable,
    ContextPopup,
  },
  props: {
    selectedFormat: {
      type: Object,
    },
    field: {
      type: String,
    },
  },
  data() {
    return {
      dateList: dateList,
      clockFormats: clockFormats,
      clockFormat: defaultClockFormat,
      dateFormats: dateFormats,
      dateFormat: defaultDateFormat,
      clockviews: clockviews,
      clockView: defaultClockView,
      disabledBtn: true,
      dt: new Date(),
      format: {
        dateList: defaultDateList,
        clockFormat: defaultClockFormat,
        dateFormat: defaultDateFormat,
        clockView: defaultClockView,
      },
      contextMenuItems: [],
      activeContext: "",
      containerId: uuidv4(),
    };
  },
  mounted() {
    if (this.selectedFormat && Object.keys(this.selectedFormat)?.length) {
      this.format = cloneDeep(this.selectedFormat);

      this.dateList.forEach((date) => {
        if (this.selectedFormat?.dateList?.includes(date.value))
          date.isActive = true;
      });
    } else {
      this.dateList.forEach((date) => (date.isActive = false));
    }
  },
  watch: {
    activeDateList(value) {
      this.updateFormat({ dateList: value });
    },
    clockFormat(value) {
      this.updateFormat({ clockFormat: value });
    },
    dateFormat(value) {
      this.updateFormat({ dateFormat: value });
    },
    clockView(value) {
      this.updateFormat({ clockView: value });
    },
  },
  methods: {
    itemBeforeEvent(args) {
      var shortCutSpan = createElement("span");
      var text = args.item.summary;
      var shortCutText = text;
      shortCutSpan.textContent = shortCutText;
      args.element.appendChild(shortCutSpan);
      shortCutSpan.style.cssText = "color: gray;";
    },
    openContextMenu(event, item, contextMenuId) {
      this.contextMenuItems = dateOptions()[item.value + "Options"] ?? [];
      if (this.contextMenuItems.length) {
        document
          .getElementById(contextMenuId)
          .ej2_instances[0].open(event.y, event.x);
        this.activeContext = item.value;
        item.isActive = !item.isActive;
      }
    },
    handleContextMenuItemSelect(params) {
      if (params?.item?.value && this.activeContext.length)
        this.updateFormat({ [this.activeContext]: params?.item?.value });
    },
    dateContext(event) {
      document
        .getElementById("datePopupMenu")
        .ej2_instances[0].open(event.y, event.x);
    },
    clockFormatChange() {
      formatMeasure(this.dt, this.format);
    },
    filter(e) {
      if (e != "" || e != null) {
        this.disabledBtn = false;
      } else {
        this.disabledBtn = true;
      }
      this.dateFormat = e;
    },
    addNewDateFormat() {
      this.dateFormats.push({
        value: this.dateFormat,
        label: this.dateFormat,
      });
    },
    updateFormat(params) {
      this.format = { ...this.format, ...params };
    },
    saveFormat() {
      this.$emit("saveFormat", { ...this.format, type: dateType });
    },
    resetFormat() {
      this.$emit("saveFormat", {});
      this.dateList.forEach((item) => (item.isActive = false));
    },
  },
  computed: {
    activeDateList() {
      return (
        this.dateList
          .filter((item) => item.isActive)
          .map((item) => item?.value)
          .filter((a) => a) ?? []
      );
    },
    exampleDisplay() {
      return formatMeasure(this.dt, {
        ...this.format,
        type: dateType,
      });
    },
  },
};
</script>
<style scoped>
.vis-panel-dropdown.vis-datePopup-w500 {
  width: 500px;
}
.vis-datePopup {
  position: relative;
  width: 100%;
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.vis-datePopup-right .el-select {
  width: 66%;
}
.vis-datePopup .list-group-item {
  position: relative;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  background-color: rgb(233, 233, 233);
  padding: 5px;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.vis-datePopup .list-group-item.active {
  /* background-color: rgb(24, 170, 238); */
  background-color: var(--primary-lighteen-1) !important;
  color: #fff;
}
.vis-date-resultText {
  position: relative;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dcdfe6;
}
.vis-date-resultText div span:nth-child(3) span {
  opacity: 0;
  margin-right: 1px;
}
</style>
