<template>
  <div class="vis-mr--1 vis-chart-filter">
    <div
      v-if="!isFilterPaneActive"
      class="vis-filter-selectList vis-scroll-thin"
    >
      <div
        :class="flexClassHorizontal"
        v-if="!selectListFilterMultipleSelection"
      >
        <div
          class="vis-position-relative"
          v-for="item in filterList"
          :key="item.value"
        >
          <el-radio
            v-model="selectedValue"
             class="vis-ellipsis"
            :label="item.value"
            :border="true"
            :style="[filterDirectionStyle, valueLabelStyle]"
            :class="{ activeClass: filterValueParse == item.value }"
            :disabled="disableSelectList"
            :tabindex="tabIndex"
            @change="addFiltersToPanels"
          >
            <span>{{ filterItem(item.label) }}</span>
          </el-radio>

          <!-- For Uncheck Radio -->
          <div
            v-if="item.value == selectedValue"
            @click="addFiltersToPanels"
            class="uncheckRadioButton"
          ></div>
        </div>
      </div>
      <div v-else>
        <el-checkbox-group
          :class="flexClassHorizontal"
          v-model="selectedMultipleValues"
        >
          <el-checkbox-button
            @change="addFiltersToPanels"
            v-for="item in filterList"
            :label="item.value"
            :key="item.value"
            :style="[filterDirectionStyle, valueLabelStyle]"
            :disabled="disableSelectList"
            :tabindex="tabIndex"
            >{{ filterItem(item.label) }}</el-checkbox-button
          >
        </el-checkbox-group>
      </div>
    </div>

    <!-- For Filter Pane. Because want to view select box -->
    <div v-else-if="isFilterPaneActive">
      <el-select
        :value="filterValueByMultipleSelection"
        @change="addFiltersToPanels"
        :multiple="selectListFilterMultipleSelection"
        clearable
        filterable
        collapse-tags
        :size="selectBoxSize"
        placeholder="Choose tags for your article"
        :disabled="disableSelectList"
      >
        <el-option
          v-for="item in filterList"
          :key="`dropdown${item.value}`"
          :label="item.label"
          :value="item.value"
          :tabindex="tabIndex"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
import { SELECT_LIST_FILTER } from "../../../commons/style";

import {
  DefaultValue,
  Orientation,
} from "../../../commons/dashboardProperties";

import {
  checkSelectBoxSize,
  getOperator,
} from "../../../util/dashboard-filter/dashboardChartFilter";
import { getFilterList } from "../../../util/chart-filter/filterListHelper";
import { getSelectedFieldDatasetId } from "../../../util/dataset-fields/datasetFields";
import { formatMeasure } from "../../panel/measures/js/formatMeasures";
import { checkIfFieldTypeIsDateAndFormatDateFilterValue } from "../../../util/chart-filter/filterValueSetters";
import { checkFilterDefaultValueNone } from "../../../util/chart-filter/filterChartCommon";

export default {
  mixins: [panelDataPropertiesStyle],

  props: {
    option: {
      type: Object,
      default() {
        return {};
      },
    },
    panelData: {
      type: Object,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isFilterPaneActive: {
      type: Boolean,
      default: false,
    },
    filterOnMount: {
      type: Boolean,
      default: false
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    inversedList: {
      type: Array,
      default: () => [],
    },
    isInverseSelect: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    selectListFilterMultipleSelection: {
      handler(oldVal, newVal) {
        if (oldVal !== newVal) {
          this.resetValue();
        }
      },
    },
    selectListFilterDefaultValue: {
      handler(oldVal, newVal) {
        if (oldVal !== newVal) {
          this.resetValue();
        }
      },
    },
    inversedList: {
      handler(newVal) {
        this.selectedMultipleValues = newVal;
        this.addFiltersToPanels();
      }
    },
    selectedItems: {
      handler(newVal) {
        if (newVal === this.option.arr.map((item) => item.value)) {
          this.isSelectAllClicked = true;
        }
        this.selectedMultipleValues = newVal;
        this.addFiltersToPanels();
      },
    },
    filterValue(val) {
      if (this.selectListFilterMultipleSelection) {
        this.filterValueByMultipleSelection =
          val?.[this.metricField]?.value ?? [];
      } else {
        this.selectedValue = val?.[this.metricField]?.value;
      }
    }
  },
  data() {
    return {
      selectedValue: "",
      selectedMultipleValues: [],
      isFilterAddActiveWithDefaultValue: false,
      isSelectAllClicked: false,
    };
  },
  mounted() {
    if (this.filterValue) {
      this.filterValueByMultipleSelection = this.filterValueParse;
    }

    this.addFiltersToPanelWithFilterDefaultValue();
  },
  computed: {
    tabIndex() {
      return this.CHART_COMPUTED_VALUES.SELECT_LIST_FILTER_TAB_INDEX;
    },
    filterValueByMultipleSelection: {
      get() {
        return this.selectListFilterMultipleSelection
          ? this.filterValueParse ?? []
          : this.filterValueParse ?? "";
      },
      set(value) {
        this.selectListFilterMultipleSelection
        ? (this.selectedMultipleValues = value)
        : (this.selectedValue = value);
      },
    },
    disableSelectList() {
      return (
        this.isDisabled &&
        this.selectListFilterDefaultValue == DefaultValue.NONE
      );
    },
    filterList() {
      const selectAllList = this.isInverseSelect ? this.option.arr : this.selectedMultipleValues.map((item) => ({ value: item}));
      const filteredList = (this.isSelectAllClicked ||  this.isInverseSelect) ? getFilterList(selectAllList): getFilterList(this.option.arr);

      const transformedList = filteredList.map((item) => {
        item.value = this.checkFilterItemValue(item.value);
        return item;
      });

      return transformedList;
    },
    filterDirectionStyle() {
      return this.selectListFilterDirection == Orientation.VERTICAL
        ? SELECT_LIST_FILTER.VERTICAL_STYLE
        : SELECT_LIST_FILTER.HORIZONTAL_STYLE;
    },
    flexClassHorizontal() {
      if (this.selectListFilterDirection == Orientation.HORIZONTAL)
        return "vis-display-flex";
      else if (this.selectListFilterDirection == Orientation.STACK)
        return "vis-row";
      else return "";
    },
    valueLabelStyle() {
      return {
        color: this.selectListFilterValueLabelColor,
        "font-family": this.selectListFilterValueLabelFontFamily,
        "font-weight": this.selectListFilterValueLabelFontWeight,
        "font-size": this.selectListFilterValueLabelFontSize + "px",
        "background-color": this.selectListFilterValueLabelBackgroundColor,
      };
    },

    selectBoxSize() {
      return checkSelectBoxSize(this.isFilterPaneActive);
    },
    filterValueParse() {
      return this.filterValue?.[this.metricField]?.value;
    },

    metricField() {
      return this.panelDataDetailsCustomFilters?.length
        ? this.panelDataDetailsCustomFilters?.[0]?.field
        : this.panelDataDetailsMetrics?.[0]?.field;
    },
    metricAlias() {
      return this.panelDataDetailsCustomFilters?.length
        ? this.panelDataDetailsCustomFilters?.[0]?.alias
        : this.panelDataDetailsMetrics?.[0]?.alias;
    },
  },
  methods: {
    addFiltersToPanels() {
      if (!this.selectListFilterMultipleSelection) this.toggleSelection();
      
      const params = {
        value: this.getValue(),
        operator: getOperator(
          this.selectListFilterMultipleSelection,
          this.panelDataDetailsCustomFilters?.length
        ),
        panelI: this.panelData.i,
        isActiveDefaultValue:
          this.selectListFilterDefaultValue == DefaultValue.SELECTION,
        datasetId: getSelectedFieldDatasetId(this.panelData),
        hasCustomFilter: this.panelDataDetailsCustomFilters?.length,
        applyToTabs: this.selectListFilterApplyToTabs,
        notRunTheViewChart: this.isFilterAddActiveWithDefaultValue
      };

      this.$emit("addFiltersToPanels", params);
      this.$emit("resetSelectAll", false);
    },

    getValue() {
      if (!this.selectListFilterMultipleSelection) {
        return this.selectedValue
          ? {
              [this.metricField]: {
                value: this.selectedValue,
                alias: this.metricAlias,
              },
            }
          : {};
      } else {
        return this.selectedMultipleValues.length
          ? {
              [this.metricField]: {
                value: this.selectedMultipleValues,
                alias: this.metricAlias,
              },
            }
          : {};
      }
    },

    toggleSelection() {
      //"In this component, for single use, when clicking the same value again, I want to clear the value."
      if (this.filterValueParse == this.selectedValue && !this.isFilterAddActiveWithDefaultValue) {
        this.selectedValue = "";
      }
    },

    filterItem(value) {
      return formatMeasure(
        value?.charAt(0).toUpperCase() + value?.slice(1),
        this.panelDataDetailsMetrics?.[0]?.format
      );
    },
    checkFilterItemValue(filterValue) {
      return checkIfFieldTypeIsDateAndFormatDateFilterValue(
        this.panelDataDetailsMetrics?.[0],
        filterValue
      );
    },

    resetValue() {
      this.selectedValue = "";
      this.selectedMultipleValues = [];
      this.addFiltersToPanels();
    },
    addFiltersToPanelWithFilterDefaultValue() {
      if (
        !checkFilterDefaultValueNone(this.selectListFilterDefaultValue) &&  
        this.filterValueParse &&
        this.filterOnMount
      ) {
        this.isFilterAddActiveWithDefaultValue = true;
        this.addFiltersToPanels();
        this.isFilterAddActiveWithDefaultValue = false;
      }
    }
  },
};
</script>
<style scoped>
.vis-filter-selectList {
  position: relative;
  height: auto;
  max-height: 100%;
}

.activeClass {
  background-color: #3d62f8 !important;
  color: #ffffff !important;
}

.uncheckRadioButton {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
}
::v-deep .el-checkbox-button span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
