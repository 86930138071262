export const routerEnums = {
    HOME: 'Home',
    DEV: 'Dev',
    DATAMODEL: 'Datamodel',
    SCENARIO_CREATE: 'ScenarioCreate',
    SCENARIO_EDIT: 'ScenarioEdit',
    DATAMODEL_VIEW: 'Datamodel/View',
    DASHBOARD: 'Dashboard',
    DASHBOARD_EDIT: 'Dashboard/Edit',
    DASHBOARD_VIEW: 'Dashboard/View',
    DASHBOARD_VIEW_TABID:"DASHBOARD_VIEW_TABID",
    DATA: 'Data',
    PANEL: 'Panel',
    ADMINISTRATION: 'Administration',
    INGEST_CREATE: 'IngestCreate',
    INGEST_EDIT: 'IngestEdit',
    EVENT_CREATE: 'EventCreate',
    EVENT_EDIT: 'EventEdit',
    ENRICHMENT_CREATE: 'EnrichmentCreate',
    ENRICHMENT_EDIT: 'EnrichmentEdit',
}

export const includesValues = {
    DYNAMIC_TITLE_VALUE: "DYNAMIC_TITLE_VALUE",
    UNIQUE_NAME: "UNIQUE_NAME",
    MANDATORY: "MANDATORY",
    ALLOW_MULTISELECT: "ALLOW_MULTISELECT",
    DYNAMIC_TITLE_SHOW_VALUE: "DYNAMIC_TITLE_SHOW_VALUE",
    FILTER_DEFAULT_SELECTION:"FILTER_DEFAULT_SELECTION",
    GROUP_MULTIPLE_VALUES: "GROUP_MULTIPLE_VALUES",
    NO_SELECTION_CONTENT: "NO_SELECTION_CONTENT",
    CACHE_DURATION_INPUT: "CACHE_DURATION_INPUT",
    USE_CACHE: "USE_CACHE",
    USE_DEFAULT_CACHE_DURATION: "USE_DEFAULT_CACHE_DURATION",
    DRILL_THROUGH_DASHBOARD_LIST: "DRILL_THROUGH_DASHBOARD_LIST"
}
export const COMPONENT_TYPES = {
    BUTTON: "BUTTON",
    COLOR_PICKER: "COLOR_PICKER",
    INPUT: "INPUT",
    INPUT_NUMBER: "INPUT_NUMBER",
    SELECT_BOX: "SELECT_BOX",
    CUSTOM_MULTIPLE_SELECT_BOX: "CUSTOM_MULTIPLE_SELECT_BOX",
    CUSTOM_ICON_SELECT_BOX: "CUSTOM_ICON_SELECT_BOX",
    SWITCH: "SWITCH",
    INPUT_WITH_ENTER: "INPUT_WITH_ENTER",
    OTHER: "OTHER",
    TEXTAREA: "TEXTAREA",
    TEXT:"TEXT",
    SHOW_BUTTON: "SHOW_BUTTON",
    DATE_RANGE: "DATE_RANGE",
    LABEL: "LABEL",
    AUTO_COMPLETE_INPUT_WITH_TABLE: "AUTO_COMPLETE_INPUT_WITH_TABLE",
    TEXT_ALIGNMENT_RADIO_BUTTON: "TEXT_ALIGNMENT_RADIO_BUTTON",
    ROTATE_ALIGNMENT_RADIO_BUTTON: "ROTATE_ALIGNMENT_RADIO_BUTTON",
    SELECT_AND_VIEW_IMAGE: "SELECT_AND_VIEW_IMAGE"
  }