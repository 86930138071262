<template>
  <div>
    <div class="vis-tools-label-box">
      <span class="vis-font-bold">
        {{ $t("datamodel.dataModelProperties") }}</span
      >
    </div>
    <el-collapse
      :value="activeCollapses"
      @change="(e) => (activeCollapses = e)"
    >
      <el-collapse-item
        class="vis-collapse-item"
        v-for="collapse in allCollapses"
        :key="collapse.key"
        :title="$t(`${collapse.label}`)"
        :name="collapse.key"
      >
        <DataModelRightPropertiesPanelDBGeneral
          v-if="collapse.key === collapseTypes.general"
          :selectedDataset="selectedDataset"
          :sqlViewVisible="sqlViewVisible"
          :selectedDataModel="selectedDataModel"
          :isDataModelLocked="isDataModelLocked"
          :adminCacheConfigurationInfo="adminCacheConfigurationInfo"
          @setDatamodelName="(value) => $emit('setDatamodelName', value)"
          @setDatamodelPropertiesValue="
            (params) => $emit('setDatamodelPropertiesValue', params)
          "
          @setQueryTimeout="(value) => $emit('setQueryTimeout', value)"
          @setQueryStatus="(value) => $emit('setQueryStatus', value)"
        />
        <DataModelRightPropertiesPanelDBParameters
          v-else-if="collapse.key === collapseTypes.parameters"
          :customFilters="customFilters"
          :datamodelId="selectedDataModel.id"
          @setDatamodelPropertiesValue="
            (params) => $emit('setDatamodelPropertiesValue', params)
          "
          @refreshCustomFilters="() => $emit('refreshCustomFilters')"
        />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import DataModelRightPropertiesPanelDBGeneral from "./DataModelRightPropertiesPanelDBGeneral";
import DataModelRightPropertiesPanelDBParameters from "./DataModelRightPropertiesPanelDBParameters";
import { COMPONENT_PRIVILEGES } from "../../../util/authorization";
import componentAvailabilityCheck from "../../../mixins/componentAvailabilityCheck";

export default {
  components: {
    DataModelRightPropertiesPanelDBGeneral,
    DataModelRightPropertiesPanelDBParameters,
  },
  mixins: [componentAvailabilityCheck],
  props: {
    selectedDataset: {
      type: Object,
    },
    sqlViewVisible: {
      type: Boolean,
    },
    selectedDataModel: {
      type: Object,
    },
    isDataModelLocked: {
      type: Boolean,
      default: false,
    },
    adminCacheConfigurationInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customFilters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      collapseTypes: {
        general: "general",
        parameters: "parameters",
      },
      activeCollapses: [],
    };
  },
  mounted() {
    this.activeCollapses = Object.keys(this.collapseTypes);
  },
  computed: {
    allCollapses() {
      return [
        {
          key: this.collapseTypes.general,
          label: "generalPages.general",
          isShow: true,
        },
        {
          key: this.collapseTypes.parameters,
          label: "generalPages.Parameters",
          isShow: true,
        },
      ].filter((x) => x && x.isShow);
    },
    isSchedulingAvailable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SCHEDULE_SECTION
      );
    },
  },
};
</script>
