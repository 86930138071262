<template>
  <div class="vis-scroll-thin" @scroll.stop="handleScroll">
    <div class="vis-vgt-card">
      <vue-good-table 
        :columns="columns" 
        :rows="mappedProjects"
        class="vis-fixed-header"
        :fixed-header="true"
        @on-row-mouseenter="onRowMouseover"
        @on-row-mouseleave="onRowMouseleave"
      >
        <template slot="table-column" slot-scope="props">
          <span>
            {{ $t(`${props.column.label}`) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == CardFieldsTypeEnum.TYPEICON">
            <i :class="props.row.iconClassName" aria-hidden="true"></i>
          </span>
          <span
            v-else-if="props.column.field == 'name'"
            @click.stop="goViewProject(props)"
            class="vis-cursor-pointer"
          >
            {{ props.row.name }}
          </span>
          <span v-else-if="props.column.field == CardFieldsTypeEnum.DESCRIPTION">
            <span>{{ props.row.item.description }}</span>
          </span>
          <span v-else-if="props.column.field == CardFieldsTypeEnum.DIRECTORY">
            <span>{{
              isInSearch
                ? handleDirectoryNames(props)
                : $t("generalPages.Home Page")
            }}</span>
          </span>
          <span v-else-if="props.column.field == CardFieldsTypeEnum.TYPE">
            <span>{{ $t(`generalPages.${props.row.type}`) }}</span>
          </span>
          <span v-else-if="props.column.field == CardFieldsTypeEnum.CREATE_DATE">
            <span>{{ formatISOToDateTime(props.row.createDate) }}</span>
          </span>
          <span v-else-if="props.column.field == CardFieldsTypeEnum.UPDATED_DATE">
            <span>{{ formatISOToDateTime(props.row.updatedDate) }}</span>
          </span>
          <span v-else-if="props.column.field == CardFieldsTypeEnum.ACTIONS">
            <span class="vis-table-actions vis-flex--Ycenter vis-flex--Xright">
              <!-- START FAVORITE ICON -->
              <i
                @click.stop="goFavoriteProject(props)"
                :class="handleFavoriteIcon(props)"
                class="line-height-1"
                aria-hidden="true"
              ></i>
               <!-- END FAVORITE ICON -->
              <!-- START EDIT ICON -->
              <i
                v-if="checkHasEditIcon(props.row.dropdowns)"
                @click.stop="
                  handleActionIcon({
                    actionKey: cardDropdownAction.EDIT,
                    params: props,
                  })
                "
                :class="customIcon.Pencil"
                aria-hidden="true"
              ></i>
              <!-- END EDIT ICON -->
              <el-dropdown
                trigger="click"
                placement="bottom"
                @command="handleActionIcon"
              >
                <span class="el-dropdown-link">
                  <i :class="customIcon.VerticalThreeDot"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="vis-dropdown-menu">
                  <el-dropdown-item
                    v-for="icon in getFilteredActionIcons(props.row.dropdowns)"
                    :key="icon.actionKey"
                    :command="{ actionKey: icon.actionKey, params: props }"
                  >
                    <i
                      v-if="icon.isHidden !== false"
                      :class="icon.iconClassName"
                      aria-hidden="true"
                    ></i>
                    {{ $t(icon.localizationKey) }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import { formatISOToDateTime } from "../../util/moment";
import { homeProjectTableColumns } from "./home";
import { mapActions } from "vuex";
import { ACTION as ACTION_FAVORITES } from "../../store/modules/Visualize/Favorites/types";
import { CustomIcon } from "../../assets/js/custom-icons";
import {
  CardDropdownAction,
  CardFieldsTypeEnum,
  MainCardAction,
} from "../../util/homePageMappers";
import { handleDirectoryNames } from "../../commons/helper";
export default {
  components: {
    VueGoodTable,
  },
  props: {
    mappedProjects: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isInSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: homeProjectTableColumns,
      customIcon: CustomIcon,
      CardFieldsTypeEnum: CardFieldsTypeEnum,
      cardDropdownAction: CardDropdownAction,
      selectedTableRowId: null,
      handleDirectoryNames: handleDirectoryNames
    };
  },
  methods: {
    ...mapActions({
      updateFavorite: ACTION_FAVORITES.UPDATE_FAVORITE,
      deleteFavorite: ACTION_FAVORITES.DELETE_FAVORITE,
    }),
    onRowMouseover(params) {
      this.selectedTableRowId = params?.row.id;
    },
    onRowMouseleave() {
      this.selectedTableRowId = null;
    },
    getFilteredActionIcons(icons) {
      return icons.filter((i) => i.actionKey !== CardDropdownAction.EDIT);
    },
    checkHasEditIcon(icons) {
      return icons.find((i) => i.actionKey === CardDropdownAction.EDIT);
    },
    handleFavoriteIcon(props) {
      return props.row.mainActions.find(
        (x) => x.actionKey === MainCardAction.STAR
      ).iconClassName;
    },
    handleScroll(event) {
      this.$emit("handleScroll", event);
    },
    goViewProject(params) {
      this.$emit("view", {
        type: params.row.type,
        id: params.row.item.id,
        name: params.row.item.name,
      });
    },
    async goFavoriteProject(params) {
      if (!params.row.item.favorite) {
        let payload = {
          objectId: params.row.item.id,
          objectType: params.row.item.objectType,
        };

        await this.updateFavorite({ payload });
      } else {
        await this.deleteFavorite(params.row.item.id);
      }
     
      const isFavorite = this.mappedProjects?.find(
        (x) => x.item.id === params.row.item.id
      );
      this.$emit("updateFavorite", isFavorite);
    },
    handleActionIcon({ actionKey, params }) {
      //example actionKey in CardDropdownAction Object
      this.$emit(actionKey.toLowerCase(), {
        item: params.row.item,
        type: params.row.type,
        id: params.row.item.id,
      });
    },
    formatISOToDateTime(val) {
      return formatISOToDateTime(val);
    },
  },
};
</script>
<style scoped>
::v-deep .vgt-fixed-header {
  position: fixed !important;
  margin-top: -1px;
}
.vis-table-actions i {
  margin-right: 18px;
  font-size: 1rem;
  cursor: pointer;
  vertical-align: middle;
}
.el-dropdown .icon-vertical_three_dot {
  margin-right: 0;
}
.vgt-table thead th {
  padding-right: 24px;
}
</style>
