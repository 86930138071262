<template>
  <div v-loading="loading.PanelPage" class="vis-flex-scrollbar vis-height-100">
    <PanelSettingsBar
      :selectedToolBoxRightArea="selectedToolBoxRightArea"
      :isDatasetFieldMenuActive="isDatasetFieldMenuActive"
      :isSlotMenuActive="isSlotMenuActive"
      :isVisualizeAiIconDisabled="isVisualizeAiIconDisabled"
      :isShowLogoTitle="isPanelTypeLogo"
      @handleChangeSelectedToolBoxRightArea="
        handleChangeSelectedToolBoxRightArea
      "
      @toggleDatasetFieldMenu="toggleDatasetFieldMenu"
      @toggleSlotMenu="toggleSlotMenu"
    />
    <div class="vis-row vis-overflow-auto">
      <splitpanes class="default-theme vis-splitpane" @resized="handleResize">
        <!-- DATAMODEL-FIELDS-MENU START-->
        <pane
          :size="isDatasetFieldMenuActive ? selectedPanelDynamicLeftMenuWidth : 0"
          max-size="26"
          :min-size="splitNavMenuMinWidth"
          class="vis-dashboard-custom-col vis-right-border vis-height-100 vis-scroll-thin"
        >
          <PanelDatamodelFieldsMenu
            v-if="isDatasetFieldMenuActive"
            :selectedDataModel="selectedDataModel"
            :datasetFields="fields"
            :selectedPanel="selectedPanel"
            :datamodelDatasetFields="datamodelDatasetFields"
            :datasetFilterParams="datasetFilterParams"
            :isShowDatasetFilterParam="isChartTypeFilter"
            :disableArea="showSlotMenu || !isPanelTypeLogo"
            :selectedFieldsWithKeyCtrlShift="selectedFieldsWithKeyCtrlShift"
            :parameterList="parameterList"
            :isParameterCollapseActive="isParameterCollapseActive"
            :leftSplitMenuWidth="leftSplitMenuWidth"
            @updateSelectedPanel="updateSelectedPanel"
            @activeFieldBox="isActiveFieldBox = $event"
            @passiveFieldBox="isActiveFieldBox = $event"
            @addSelectedFieldsWithKeyCtrl="addSelectedFieldsWithKeyCtrl"
            @addSelectedFieldsWithKeyShift="addSelectedFieldsWithKeyShift"
            @goToCalculatedColumn="goToCalculatedColumn"
            @updateSelectedPanelAndViewChart="updateSelectedPanelAndViewChart"
          />
        </pane>
        <!-- DATAMODEL-FIELDS-MENU END-->
        <pane 
          :size="isDatasetFieldMenuActive ? 100 - selectedPanelDynamicLeftMenuWidth : 100">
          <div
            v-if="showSlotMenu"
            class="vis-dashboard-custom-col vis-right-border vis-bg-color-white vis-scroll-thin vis-overflow-auto vis-height-100"
          >
            <ChartPopup
              :selectedChartType="chartType"
              :selectedChartTypeName="chartName"
              :isChartTypeFilter="isChartTypeFilter"
              @changeChartType="changeChartType"
            />

            <div v-if="!isPanelTypeLogo">
              <div v-if="isPanelTypeTable">
                <PanelColumnsBox
                  :selectedPanel="selectedPanel"
                  :isActiveFieldBox="isActiveFieldBox"
                  :designMode="designMode"
                  :selectedFieldsWithKeyCtrlShift="
                    selectedFieldsWithKeyCtrlShift
                  "
                  :datasetFields="fields"
                  :datasetIds="datasetIds"
                  :selectedDataModel="selectedDataModel"
                  :isColumnBasedPropertiesSelected="
                    isColumnBasedPropertiesSelected
                  "
                  :selectedColumnField="selectedColumnBasedPropertiesColumn"
                  @updateSelectedPanel="updateSelectedPanel"
                  @updateSelectedPanelAndViewChart="
                    updateSelectedPanelAndViewChart
                  "
                  @deleteSelectedFieldsWithKeyCtrlShift="
                    selectedFieldsWithKeyCtrlShift = []
                  "
                  @onSelectColumnBasedPropertiesColumn="
                    setSelectedColumnBasedPropertiesColumn
                  "
                />
                <PanelSectionsBox
                  :selectedPanel="selectedPanel"
                  :isActiveFieldBox="isActiveFieldBox"
                  :datasetFields="fields"
                  :designMode="designMode"
                  :selectedDataModel="selectedDataModel"
                  @updateSelectedPanel="updateSelectedPanel"
                  @updateSelectedPanelAndViewChart="
                    updateSelectedPanelAndViewChart
                  "
                />
              </div>
              <div v-else>
                <div v-for="box in panelDataBoxes" :key="box.title">
                  <PanelDataBox
                    :selectedPanel="selectedPanel"
                    :isActiveFieldBox="isActiveFieldBox"
                    :datasetFields="fields"
                    :designMode="designMode"
                    :boxName="$t(box.title)"
                    :usageType="box.usageType"
                    :boxKey="box.boxKey"
                    :boxOptions="box.boxOptions"
                    :datasetIds="datasetIds"
                    :selectedDataModel="selectedDataModel"
                    :datamodelDatasetFields="datamodelDatasetFields"
                    @updateSelectedPanel="updateSelectedPanel"
                    @updateSelectedPanelAndViewChart="
                      updateSelectedPanelAndViewChart
                    "
                    @changeFilterChartPropertyValue="
                      changeFilterChartPropertyValue
                    "
                    @onSelectColumnBasedPropertiesColumn="
                      setSelectedColumnBasedPropertiesColumn
                    "
                  />
                </div>
              </div>
              <PanelFiltersBox
                v-if="showFiltersBoxByChartType"
                :selectedPanel="selectedPanel"
                :datasetFields="fields"
                :isActiveFieldBox="isActiveFieldBox"
                :designMode="designMode"
                :selectedDataModel="selectedDataModel"
                @updateSelectedPanel="updateSelectedPanel"
                @updateSelectedPanelAndViewChart="
                  updateSelectedPanelAndViewChart
                "
              />
              <PanelSortBox
                v-if="showSortBoxByChartType"
                :selectedPanel="selectedPanel"
                :isActiveFieldBox="isActiveFieldBox"
                :designMode="designMode"
                :selectedDataModel="selectedDataModel"
                :datasetFields="fields"
                :isChartTypeFilter="isChartTypeFilter"
                @updateSelectedPanel="updateSelectedPanel"
                @updateSelectedPanelAndViewChart="
                  updateSelectedPanelAndViewChart
                "
              />
            </div>
          </div>

          <!-- LEFT-MENU-2 -->
          <div
            class="vis-col vis-pa--none vis-ma--none vis-scroll-thin vis-height-100"
          >
            <div
              v-loading="isSearchLoading"
              class="vis-chart-card min-h-89_5 vis-not-border"
              :style="mergeCardAndTemplateStyleAsCSS()"
              :class="[hasBorderinChartCard, { 'min-h-88_5': isPanelTypeLogo }]"
            >
              <PanelCardTitle
                ref="panelCardTitle"
                :panelData="selectedPanel"
                :designMode="designMode"
                :isActiveSaveIcon="true"
                :hasTitle="
                  mergeCardAndTemplateStyleSingleProperty(
                    allPropertiesKeys.HAS_TITLE
                  )
                "
                :hasSubTitle="
                  mergeCardAndTemplateStyleSingleProperty(
                    allPropertiesKeys.HAS_SUBTITLE
                  )
                "
                :isPanelPage="isPanelPage"
                :isChartAreaShow="isChartAreaShow"
                :isSaveAndPreviousDashboardActive="
                  isSelectorChartDefaultValueActiveAndHasDefaultValue &&
                  hasFilterComponentDefaultValue
                "
                :isDisabledSortIcon="isPanelTypeLogo"
                @saveAndPreviousDashboard="saveAndPreviousDashboard"
                @previousDashboard="previousDashboard"
                @getViewChart="viewChart"
                @getOpenLogosSortMenu="getOpenLogosSortMenu"
              />
              <div
                v-if="!checkHasSearchResponseError() && isChartAreaShow"
                :class="{
                  'vis-chart-card-content vis-scroll-thin': isPanelTypeTable,
                }"
              >
                <Chart
                  v-if="isChartsAvailable && isPanelTypeECharts"
                  :panelData="getPanelDataByAuthorize()"
                  :paramsFromSearch="options"
                  :isPanelTypeLabel="isPanelTypeLabel"
                  :selectedTabDesignMode="designMode"
                />
                <Logo
                  ref="logoComponent"
                  v-if="isPanelTypeLogo"
                  :panelData="selectedPanel"
                  :option="options"
                  :isPanelPage="true"
                  :externalIcon="iconRef"
                  @updateSelectedPanel="updateSelectedPanel"
                />
                <!-- Fields Düzeltilecek -->
                <Summary
                  v-if="isPanelTypeSummary"
                  :panelData="getPanelDataByAuthorize()"
                  :option="options"
                  :fields="fields"
                />
                <!-- Fields Düzeltilecek -->
                <DataTable
                  v-else-if="isPanelTypeTable"
                  :panelData="getPanelDataByAuthorize()"
                  :option="options"
                  :paramsFromSearch="options"
                  :isPanelPage="isPanelPage"
                  @addFiltersToPanels="addFiltersToPanelFromPanel"
                  @updateAndViewSelectedPanel="mapSelectedPanelAndViewChart"
                  @updateSelectedPanel="mapSelectedPanelUpdate"
                  @onSelectColumnBasedPropertiesColumn="
                    setSelectedColumnBasedPropertiesColumn
                  "
                  @setDataTableColumnBasedComponentProperties="
                    setDataTableColumnBasedComponentProperties
                  "
                  @onTableWrapperClicked="handleTableWrapperClicked"
                />
                <PivotTable
                  v-if="isPanelTypePivotTable"
                  :panelData="getPanelDataByAuthorize()"
                  :option="options"
                  :showFieldListEnabled="false"
                  :refreshOnStyleChanges="isPanelPage"
                  :selectedColumnField="selectedColumnBasedPropertiesColumn"
                  :isSelectionCellActive="false"
                  @updateAndViewSelectedPanel="mapSelectedPanelAndViewChart"
                  @onSelectColumnBasedPropertiesColumn="
                    setSelectedColumnBasedPropertiesColumn
                  "
                  @setPivotTableColumnBasedComponentProperties="
                    setDataTableColumnBasedComponentProperties
                  "
                />
                <FilterText
                  v-else-if="isPanelTypeFilterText"
                  :panelData="getPanelDataByAuthorize()"
                  :isPanelPage="isPanelPage"
                  @addFiltersToPanels="addFiltersToPanelFromPanel"
                  @setSelectedPanelStyle="setSelectedPanelStyle"
                />
                <FilterDropdown
                  v-else-if="isPanelTypeFilterDropdown"
                  :panelData="getPanelDataByAuthorize()"
                  :option="options"
                  :isDisabled="isPanelPage"
                  @addFiltersToPanels="addFiltersToPanelFromPanel"
                />
                <FilterNumber
                  v-else-if="isPanelTypeFilterNumber"
                  :panelData="getPanelDataByAuthorize()"
                  @addFiltersToPanels="addFiltersToPanelFromPanel"
                />
                <FilterDate
                  v-else-if="isPanelTypeFilterDate"
                  :panelData="getPanelDataByAuthorize()"
                  :isPanelPage="isPanelPage"
                  @addFiltersToPanels="addFiltersToPanelFromPanel"
                  @setSelectedPanelStyle="setSelectedPanelStyle"
                />
                <FilterSelectList
                  v-else-if="isPanelTypeFilterSelectList"
                  :panelData="getPanelDataByAuthorize()"
                  :option="options"
                  :isDisabled="isPanelPage"
                  @addFiltersToPanels="addFiltersToPanelFromPanel"
                />
                <AdvancedButton
                  v-if="isPanelTypeAdvancedButton"
                  :panelData="selectedPanel"
                />
                <TextEditor
                  v-if="isPanelTypeTextEditor"
                  :panelData="selectedPanel"
                  :option="options"
                  :editMode="true"
                  @updateSelectedPanel="updateSelectedPanel"
                />
                <SelectorChart
                  v-if="isPanelTypeSelector"
                  :panelData="selectedPanel"
                  :isDisabledSelect="true"
                  @setSelectedPanelStyle="setSelectedPanelStyle"
                  @changeChartField="changeChartField"
                  @handleCheckNameUnique="checkIfSelectorDataItemNameIsUnique"
                />
                <FilterButton
                  v-if="isPanelTypeFilterButton"
                  :panelData="selectedPanel"
                />
              </div>
              <DashboardChartMessage
                v-if="checkHasSearchResponseError()"
                :message="$t(`errorCode.${checkHasSearchResponseError()}`)"
                :icon="customIcon.AdminPanelSettings"
              />
              <DashboardChartMessage
                v-if="getWarningMessageIsMetricEmpty()"
                :message="getWarningMessageIsMetricEmpty().message"
                :icon="getWarningMessageIsMetricEmpty().icon"
              />
              <ButtonDialog
                class="vis-mt--2 vis-flex--Xcenter"
                :isDialogActive="isPanelTypeFilterButton"
                :isPositionFixed="false"
                :panelData="selectedPanel"
              />
              <div class="vis-chart-card-footer vis-chart-card-footer-padding">
                {{ selectedPanelFootNote }}
              </div>
            </div>
          </div>
          <!-- CENTER-PANEL -->
          <div
            v-if="!isPanelTypeLogo"
            class="vis-col vis-pa--none vis-ma--none vis-scroll-thin vis-height-100 vis-overflow-auto vis-tools-box vis-left-border vis-bg-color-white"
            :style="{
              'max-width': isSelectedRightToolBoxVisualizeAI
                ? '400px'
                : '300px',
            }"
          >
            <DashboardVisualizeAI
              v-if="isSelectedRightToolBoxVisualizeAI"
              :aiMessages="aiMessages"
              :isLoading="isVisualizeAIGenerateLoading"
              :visualizeAIChartId="visualizeAIChartId"
              :fields="fields"
              @addNewChartWithChatBot="addNewChartWithChatBot"
            />
            <PanelProperties
              v-else
              ref="PanelProperties"
              :chartType="chartType"
              :chartTypeName="chartTypeName"
              :propertiesPanel="selectedPanel.properties"
              :editMode="true"
              :colorPalettes="colorPalettes"
              :summaryResponse="options"
              :customFilterType="checkFilterTypeIsCustom()"
              :isColumnBasedPropertiesSelected="isColumnBasedPropertiesSelected"
              :selectedColumnBasedFormattingsProperties="
                selectedColumnBasedFormattingsProperties
              "
              :selectedColumnField="selectedColumnBasedPropertiesColumn"
              :datamodelId="datamodelId"
              :dashboardTabsOptionList="dashboardTabsOptionList"
              :datamodelProperty="selectedDataModel.properties"
              :selectedDashboardTabStyle="selectedTabStyle"
              :isConfigurationUseCacheActive="isConfigurationUseCacheActive"
              :configurationDefaultCacheDurationInMinute="
                configurationDefaultCacheDurationInMinute
              "
              @setPanelStyle="setSelectedPanelStyle"
              @setDataTableColumnBasedComponentProperties="
                setDataTableColumnBasedComponentProperties
              "
              @setDashboardStyle="setDashboardStyle"
              @closeColumnBasedProperties="
                resetSelectedColumnBasedPropertiesColumn
              "
              @onPropertiesEventTriggered="onPropertiesEventTriggered"
              @handleSelectedStyleProperty="handleSelectedStyleProperty"
              @deletePropertyValue="deletePropertyValue"
            />
          </div>
          <!-- PROPERTIES-MENU -->
        </pane>
      </splitpanes>
    </div>
    <!-- ROW -->
    <ConfirmDialog
      :title="$t('dialog.unsaveTitle')"
      :message="$t('dialog.unsaveMessage')"
      :dialogVisible="dialogVisible"
      @cancel="dialogVisible = $event"
      @confirm="setConfirm()"
    />
    <ConditionalFormattingPopup
      v-if="isConditionalFormattingPopupVisible"
      :selectedPanel="selectedPanel"
      :selectedColumn="selectedColumnBasedPropertiesColumn"
      :isShowConditionalRowFormatting="isShowConditionalRowFormatting"
      @updateSelectedPanel="updateSelectedPanel"
      @closePopup="isConditionalFormattingPopupVisible = false"
    />
    <SqlShowPopup
      v-if="isSqlPopupVisible"
      :sqlQuery="sqlQuery"
      :selectedDbType="selectedDbType"
      @closePopup="isSqlPopupVisible = false"
    />
    <ImagesPopup v-if="showImagesPopup && isPanelTypeTable">
      <template v-slot:imagesPopupContent>
        <UploadImages
          :isEditEnabled="false"
          title="Select Image"
          imageWrapperHeight="80px"
          @handleSelectedImage="setSelectedImageChartProperty"
          @close="showImagesPopup = false"
        />
      </template>
    </ImagesPopup>
  </div>
</template>
<script>
import Vue from "vue";
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
Vue.use(ContextMenuPlugin);
import {
  GETTER as GETTER_DASHBOARD,
  ACTION as ACTION_DASHBOARD,
  MUTATION as MUTATION_DASHBOARD,
} from "../store/modules/Visualize/Dashboard/types";
import {
  GETTER as GETTER_DATASET,
  ACTION as ACTION_DATASET,
  MUTATION as MUTATION_DATASET,
} from "../store/modules/Visualize/Dataset/types";
import {
  GETTER as GETTER_DASHBOARD_FIELDS,
  ACTION as ACTION_DASHBOARD_FIELDS,
} from "../store/modules/Visualize/DashboardFields/types";
import {
  GETTER as GETTER_CHART_FIELDS,
  ACTION as ACTION_CHART_FIELDS,
} from "../store/modules/Visualize/ChartFields/types";
import {
  GETTER as GETTER_COLOR_PALETTES,
  ACTION as ACTION_COLOR_PALETTES,
} from "../store/modules/Visualize/ColorPalette/types";
import {
  GETTER as GETTER_DATAMODEL,
  ACTION as ACTION_DATAMODEL,
} from "../store/modules/Visualize/DataModel/types";
import { GETTER as GETTER_GENERAL } from "../store/modules/Visualize/General/types";
import {
  TEMP_STORAGE_KEYS,
  GETTER as GETTER_TEMP_STORAGE,
} from "../store/modules/temp-storage/types";
import { GETTER as GETTER_TEMPLATES } from "../store/modules/Visualize/AdminTemplates/types";
import { ACTION as ACTION_CHAT_GPT } from "../store/modules/Visualize/ChatGpt/types";
import { GETTER as GETTER_CONFIGURATIONS } from "../store/modules/Visualize/Configurations/types";
import { mapGetters, mapActions, mapMutations } from "vuex";
import panelSearch from "../mixins/panelSearch.js";
import PanelProperties from "../components/panel/PanelProperties.vue";
import SqlShowPopup from "../components/panel/SqlShowPopup.vue";
import PanelDatamodelFieldsMenu from "../components/panel/PanelDatamodelFieldsMenu.vue";
import PanelColumnsBox from "../components/panel/PanelColumnsBox.vue";
import PanelSectionsBox from "../components/panel/PanelSectionsBox.vue";
import PanelFiltersBox from "../components/panel/PanelFiltersBox.vue";
import PanelSortBox from "../components/panel/PanelSortBox.vue";
import PanelDataBox from "../components/panel/PanelDataBox.vue";
import PanelCardTitle from "../components/panel/PanelCardTitle.vue";
import Chart from "../components/dashboard/chart/Chart.vue";
import Logo from "../components/dashboard/logo/Logo.vue";
import Summary from "../components/dashboard/summary/Summary.vue";
import DataTable from "../components/dashboard/table/Table.vue";
import FilterText from "../components/dashboard/chart/FilterText.vue";
import FilterDropdown from "../components/dashboard/chart/FilterDropdown.vue";
import FilterNumber from "../components/dashboard/chart/FilterNumber.vue";
import FilterDate from "../components/dashboard/chart/FilterDate.vue";
import FilterSelectList from "../components/dashboard/chart/FilterSelectList.vue";
import AdvancedButton from "../components/dashboard/chart/AdvancedButton.vue";
import TextEditor from "../components/dashboard/chart/TextEditor.vue";
import SelectorChart from "../components/dashboard/chart/SelectorChart.vue";
import FilterButton from "../components/dashboard/chart/FilterButton.vue";
import ChartPopup from "../components/panel/ChartPopup.vue";
import { includesValues, routerEnums } from "../commons/Enum";
import ButtonDialog from "../components/helper/ButtonDialog.vue";
import {
  chartTypes,
  dashboardFilterTypes,
  panelTypes,
  otherChartType,
  onPropertiesEventOptions,
  BOX_KEY_ENUM,
  getFilteredPanelDataByAuthorize,
  panelTools,
  chartList,
  checkChartMetricEmpty,
  detailsKeys,
  checkDuplicateValueInChartStyle,
  getCacheInfoByPropertyStyle,
} from "../commons/dashboardAndPanel";
import {
  DefaultValue,
  allPropertiesKeys,
  datatablePropertiesEnum,
  GEO_MAP_CHART_PROPERTIES_ENUM,
  propertiesKeyToCSSKeyMapping,
  countryValues,
  SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM,
  chartPropertiesEnum,
  TEXT_EDITOR_PROPERTIES_ENUM,
} from "../commons/dashboardProperties";
import cloneDeep from "clone-deep";
import { LoadingComponent } from "../store/modules/Visualize/General/loadingComponentDefinitions";
import { createSmartTitle } from "../util/panelDataPropertiesStyle";
import ConfirmDialog from "../components/helper/ConfirmDialog.vue";
import { diff } from "deep-object-diff";
import { checkLimitAndSetPanelFields } from "../components/panel/measures/js/measures";
import PivotTable from "../components/dashboard/table/PivotTable.vue";
import { chartBoxes } from "../util/panelDataBoxes";
import { setDefaultProperties } from "../util/panel-properties/panelDefaultPropertiesSetter";
import ConditionalFormattingPopup from "../components/panel/conditionalFormatting/ConditionalFormattingPopup.vue";
import {
  changeVisTypeForSearchParamByAggregateResult,
  setSelectedPanelDetailAggregationAndMetrics,
} from "../util/data-table/tableCommonsHelper";
import { cardPropertiesTemplates } from "../mocks/cardPropertiesTemplates";
import {
  configurationNameTypes,
  defaultColorPalettes,
} from "../components/settings/settings";
import DashboardChartMessage from "../components/dashboard/DashboardChartMessage.vue";
import DashboardVisualizeAI from "../components/dashboard/DashboardVisualizeAI.vue";
import uuid from "uuid";
import { addNewKeyInDetailsDataArrays } from "../components/dashboard/dashboard.js";
import PanelSettingsBar from "../components/helper/PanelSettingsBar.vue";
import {
  datamodelPropertyKeys,
  getCommonDatamodelPropertyValueByKey,
  mapSelectorChartParameterList,
} from "../commons/dataModelTypes.js";
import { Notify } from "../commons/helper.js";
import { notificationType } from "../commons/notificationTypes";
import { filterPropKey, filterType } from "../commons/filterComponents.js";
import { CustomIcon } from "../assets/js/custom-icons.js";
import UploadImages from "../components/settings/UploadImages.vue";
import ImagesPopup from "../components/helper/ImagesPopup.vue";

export default {
  mixins: [panelSearch],
  components: {
    Chart,
    Logo,
    Summary,
    DataTable,
    FilterText,
    FilterDropdown,
    FilterNumber,
    FilterDate,
    FilterSelectList,
    ChartPopup,
    PanelDatamodelFieldsMenu,
    PanelColumnsBox,
    PanelSectionsBox,
    PanelFiltersBox,
    PanelSortBox,
    PanelCardTitle,
    PanelProperties,
    ConfirmDialog,
    PanelDataBox,
    PivotTable,
    AdvancedButton,
    FilterButton,
    TextEditor,
    SelectorChart,
    ConditionalFormattingPopup,
    SqlShowPopup,
    DashboardChartMessage,
    ButtonDialog,
    DashboardVisualizeAI,
    PanelSettingsBar,
    UploadImages,
    ImagesPopup
  },
  watch: {
    dynamicTitleShowValue(val) {
      const dynamicTitleValue = this.getPanelPropertyKey(
        includesValues.DYNAMIC_TITLE_VALUE
      );

      if (typeof val == "boolean" && dynamicTitleValue)
        this.setSelectedPanelStyle({
          property: dynamicTitleValue,
          value: val ? "" : this.selectedPanel.name,
        });
    },
    chartStyleCacheDurationInput: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;

        this.reloadCacheInfo();
      },
    },
    chartStyleUseDefaultCacheDuration: {
      handler(newVal, oldVal) {
        if (
          newVal === oldVal ||
          this.chartStyleCacheDurationInput === "" ||
          !this.chartStyleCacheDurationInput
        )
          return;

        this.reloadCacheInfo();
      },
    },
  },
  data() {
    return {
      isSqlPopupVisible: false,
      isConditionalFormattingPopupVisible: false,
      selectedColumnBasedPropertiesColumn: null,
      isChartsAvailable: true,
      boxEmptyMessage:
        "Sol menüden sürükle-bırak yaparak alanı doldurabilirsiniz.",
      chartType: this.$route.query.chartType,
      isActiveFieldBox: false,
      dashboardFilterTypes: dashboardFilterTypes,
      allPropertiesKeys: allPropertiesKeys,
      tempSelectedPanel: {},
      tempDashboard: {},
      dialogVisible: false,
      beforeRouteLink: "",
      selectedFieldsWithKeyCtrlShift: [],
      aiMessages: [],
      isSlotsActive: true,
      visualizeAIChartId: null,
      panelTools: panelTools,
      selectedToolBoxRightArea: panelTools.CHART_PROPERTIES_TOOLBOX,
      isDatasetFieldMenuActive: true,
      isSlotMenuActive: true,
      iconRef: null,
      parameterList: [],
      isUniqueNameCheckActive: false,
      leftSplitMenuWidth: null,
      evictionId: "",
      shouldRefreshCache: false,
      totalWidth: window.innerWidth,
      customIcon: CustomIcon,
      showImagesPopup: false,
      selectedStyleProperty: null
    };
  },
  beforeDestroy() {
    this.resetDatasetFilterParams();

    window.removeEventListener("resize", this.updatePaneSize);
  },
  async created() {
    this.parameterList = this.getSelectorChartParameterList();

    if (this.isVisualizeAiQueryParamActive) {
      this.handleChangeSelectedToolBoxRightArea(
        panelTools.VISUALIZE_AI_TOOLBOX
      );
      this.isDatasetFieldMenuActive = false;
      this.isSlotMenuActive = false;
    }

    if (!this.dashboard?.id && this.$route.query.dashboardId) {
      await this.getDashboardById({
        loadingComponent: LoadingComponent.DashboardPage,
        dashboardId: this.dashboardId,
      });
    }
    await this.fetchDataModelById(this.dashboard.datamodel_id);
  },
  async mounted() {
    if (this.isPanelTypeLogo) {
      this.iconRef = this.$refs?.panelCardTitle?.$refs?.externalIcon;
    }

    await this.getDatasetFieldsControl();
    //FOR FIELD AUTHORIZE
    this.changePanelDataDetailsData();

    this.clickOnBody();

    this.tempSelectedPanel = cloneDeep(this.selectedPanel);
    this.tempDashboard = cloneDeep(this.dashboard);

    if (this.isChartTypeFilter) await this.getCustomFilterParams();

    window.onbeforeunload = function (event) {
      event.returnValue = "Are you really want to perform the action?";
    };

    if (this.isChartTypeFilter) {
      setDefaultProperties(this?.selectedPanel?.type, {
        checkHasDynamicTitleValue: this.checkHasDynamicTitleValue,
        checkHasGroupMultipleValues: this.checkHasGroupMultipleValues,
        checkHasUniqueName: this.checkHasUniqueName,
        setSelectedPanelStyleByNoKey: this.setSelectedPanelStyleByNoKey,
      }).fn.forEach((f) => {
        f();
      });
    }

    if (!this.designMode) this.viewChart();

    this.fetchAndControlColorPalettes();

    window.addEventListener("resize", this.updatePaneSize);

    if (this.isVisualizeAiQueryParamActive) this.visualizeAIChartId = uuid();
  },
  computed: {
    ...mapGetters({
      dashboard: GETTER_DASHBOARD.GET_DASHBOARD,
      dataModels: GETTER_DATAMODEL.GET_DATAMODELS,
      selectedDataModel: GETTER_DATAMODEL.GET_SELECTED_DATAMODEL,
      datasetFilterParams: GETTER_DATASET.GET_DATASET_BY_ID_FILTER_PARAMS,
      dashboardFields: GETTER_DASHBOARD_FIELDS.GET_DASHBOARD_BY_ID_FIELDS,
      datamodelDatasetFields: GETTER_DATAMODEL.GET_DATAMODEL_BY_ID_FIELDS,
      chartFields: GETTER_CHART_FIELDS.GET_CHART_BY_ID_FIELDS, //TODO yapı belli olunca tekrar bakılacak.
      tempStorageBykey: GETTER_TEMP_STORAGE.GET_TEMP_STORAGE_BY_KEY,
      loading: GETTER_GENERAL.GET_LOADING,
      getColorPalettes: GETTER_COLOR_PALETTES.GET_COLOR_PALETTES,
      getCustomColorPalettes: GETTER_DASHBOARD.GET_CUSTOM_COLOR_PALETTES,
      datasetIds: GETTER_DATASET.GET_DATASET_IDS,
      cardTemplates: GETTER_TEMPLATES.GET_TEMPLATES,
      unAuthorizedDashboardFields:
        GETTER_DASHBOARD_FIELDS.GET_DASHBOARD_UNAUTHORIZED_FIELDS,
      configurationList: GETTER_CONFIGURATIONS.GET_CONFIGURATIONS,
    }),
    isDataFromDatabaseActive() {
      if (!this.isPanelTypeTextEditor) return true;

      return this.selectedPanel?.properties?.style?.[TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DATA_FROM_DATABASE];
    },
    isConfigurationUseCacheActive() {
      //Admin ekranındaki data cache içindeki use cache özelliği
      const cacheConnectionActive = this.configurationList.find(
        (x) => x.name === configurationNameTypes.IS_CACHE_CONNECTION_ACTIVE
      );
      return JSON.parse(cacheConnectionActive?.value);
    },
    configurationDefaultCacheDurationInMinute() {
      //Admin ekranındaki data cache içindeki use cache özelliği
      const cacheDefaultCacheDurationMin = this.configurationList.find(
        (x) => x.name === configurationNameTypes.DEFAULT_CACHE_DURATION_MIN
      );

      return cacheDefaultCacheDurationMin?.value;
    },
    chartStyleCacheDurationInput() {
      return this.selectedPanel?.properties?.style?.[
        this.getPanelPropertyKey(includesValues.CACHE_DURATION_INPUT)
      ];
    },
    chartStyleUseDefaultCacheDuration() {
      return this.selectedPanel?.properties?.style?.[
        this.getPanelPropertyKey(includesValues.USE_DEFAULT_CACHE_DURATION)
      ];
    },
    isParameterCollapseActive() {
      return !this.isChartTypeFilter && !this.isPanelTypeSelector;
    },
    isShowConditionalRowFormatting() {
      //Sadece tüm satır formatlanması için eklendi, şimdilik tabloda kullanılıyor!
      return this.isPanelTypeTable;
    },
    isSelectorChartDefaultValueActiveAndHasDefaultValue() {
      const getPanelPropertyStyleByKey = (key) => {
        return this.selectedPanel?.properties?.style?.[key];
      };
      const isDefaultValueActive = getPanelPropertyStyleByKey(
        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_DEFAULT_VALUE
      );

      if (!isDefaultValueActive) return true;

      const multipleSelect = getPanelPropertyStyleByKey(
        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_ALLOW_MULTISELECT
      );
      const selectorDataItemValue =
        this.selectedPanel?.details?.[detailsKeys.selectorDataitemValue];
      const value = multipleSelect
        ? selectorDataItemValue?.length !== 0
        : selectorDataItemValue !== "";

      return isDefaultValueActive && value;
    },
    hasFilterComponentDefaultValue() {
      if (!this.selectedPanel) return;

      let panelDataPropertyDefaultValue = Object.values(
        this.selectedPanel.properties?.style
      ).includes(DefaultValue.SELECTION);

      if (!panelDataPropertyDefaultValue) return true;

      const filterValue =
        this.selectedPanel.details?.[filterPropKey.FILTER_VALUE];

      return filterValue ? true : false;
    },
    isChartAreaShow() {
      return (
        (this.isVisualizeAiQueryParamActive &&
          this.selectedPanel?.details?.metrics?.length > 0) ||
        !this.isVisualizeAiQueryParamActive
      );
    },
    isVisualizeAiIconDisabled() {
      const isVisualizeAIEnvActive = process.env.VUE_APP_IS_VISUALIZE_AI_ACTIVE;

      return (
        !this.getDatamodelPropertyValue(
          datamodelPropertyKeys.ALLOW_AI_ACTIONS
        ) ||
        isVisualizeAIEnvActive === "false" ||
        !isVisualizeAIEnvActive
      );
    },
    isSelectedRightToolBoxVisualizeAI() {
      return this.selectedToolBoxRightArea === panelTools.VISUALIZE_AI_TOOLBOX;
    },
    selectedColumnBasedFormattingsProperties() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      return selectedPanelCloned?.properties?.columnBasedFormattings?.find(
        (f) =>
          this.selectedColumnBasedPropertiesColumn?.slotFieldId
            ? f.slotFieldId ===
              this.selectedColumnBasedPropertiesColumn?.slotFieldId
            : f.fieldId === this.selectedColumnBasedPropertiesColumn?.fieldId
      )?.formattings;
    },
    dashboardTabsOptionList() {
      return this.dashboard.tabs.map((m) => {
        return {
          label: m.name,
          value: m?.properties?.uiTabId,
        };
      });
    },
    tempDatasetFields() {
      return this.tempStorageBykey(TEMP_STORAGE_KEYS.TEMP_DATASET_FIELDS);
    },
    tempSelectedDashboard() {
      return this.tempStorageBykey(TEMP_STORAGE_KEYS.TEMP_SELECTED_DASHBOARD);
    },
    fields() {
      return this.dashboardId ? this.dashboardFields : this.tempDatasetFields;
    },

    dashboardId() {
      return this.$route.query.dashboardId ?? this.dashboard.id;
    },
    datamodelId() {
      return this.selectedDataModel?.id;
    },
    tabIndex() {
      return this.$route.query.tabIndex;
    },
    chartList() {
      return this.dashboard.tabs[this.tabIndex].charts;
    },
    selectedPanel() {
      var panel = this.dashboard?.tabs?.[this.tabIndex]?.charts?.find(
        (x) => x?.i == this.queryPanelId
      );
      return panel;
    },
    panelId() {
      return this.selectedPanel?.id;
    },
    queryPanelId() {
      return this.$route.query.id;
    },
    isVisualizeAiQueryParamActive() {
      return this.$route.query.isVisualizeAiActive;
    },
    datasetName() {
      return this.selectedPanel?.properties?.dataSet?.name;
    },
    chartTypeName() {
      return this.isColumnBasedPropertiesSelected
        ? "Column " + this.selectedColumnBasedPropertiesColumn.alias
        : this.selectedPanel?.chartTypeName;
    },
    chartName() {
      return this.selectedPanel?.chartTypeName;
    },
    selectedPanelFootNote() {
      return this.selectedPanel?.footnote;
    },
    selectedPanelDynamicLeftMenuWidth() {
      return this.dashboard?.properties?.style?.[
        allPropertiesKeys.CHART_DATAMODEL_LEFT_MENU_WIDTH
      ]
        ? this.dashboard?.properties?.style?.[
            allPropertiesKeys.CHART_DATAMODEL_LEFT_MENU_WIDTH
          ]
        : this.splitNavMenuMinWidth;
    },
    splitNavMenuMinWidth() {
      return (220 / this.totalWidth) * 100;
    },
    isColumnBasedPropertiesSelected() {
      return this.selectedColumnBasedPropertiesColumn ? true : false;
    },
    selectedTemplateId() {
      return this.dashboard.tabs[this.tabIndex].properties?.style?.[
        allPropertiesKeys.SELECTED_TEMPLATE_ID
      ];
    },
    selectedTabStyle() {
      return this.dashboard?.tabs[this.tabIndex].properties?.style;
    },
    mergedCardTemplateList() {
      return [...this.cardTemplates, ...cardPropertiesTemplates];
    },
    selectedTemplateProperties() {
      return (
        this.mergedCardTemplateList.find(
          (d) => d.id === this.selectedTemplateId
        )?.properties?.style ?? {}
      );
    },

    isPanelTypeECharts() {
      return (
        this.selectedPanel?.type?.toLowerCase().includes(panelTypes.CHART) &&
        !this.isPanelTypeLogo &&
        !this.isPanelTypeSummary &&
        !this.isPanelTypeSelector
      );
    },
    isPanelTypeLogo() {
      return this.selectedPanel?.type?.toLowerCase().includes(panelTypes.LOGO);
    },
    isPanelTypeSummary() {
      return this.selectedPanel?.type
        ?.toLowerCase()
        .includes(panelTypes.SUMMARY);
    },
    isPanelTypeTable() {
      return this.selectedPanel?.type === chartTypes.TABLE;
    },
    isPanelTypePivotTable() {
      return this.selectedPanel?.type === chartTypes.PIVOT_TABLE;
    },
    isPanelTypeFilterText() {
      return this.selectedPanel?.type === dashboardFilterTypes.FILTER_TEXT;
    },
    isPanelTypeFilterDropdown() {
      return this.selectedPanel?.type === dashboardFilterTypes.FILTER_DROPDOWN;
    },
    isPanelTypeFilterNumber() {
      return this.selectedPanel?.type === dashboardFilterTypes.FILTER_NUMBER;
    },
    isPanelTypeFilterDate() {
      return this.selectedPanel?.type === dashboardFilterTypes.FILTER_DATE;
    },
    isPanelTypeAdvancedButton() {
      return this.selectedPanel?.type === dashboardFilterTypes.ADVANCED_BUTTON;
    },
    isPanelTypeTextEditor() {
      return this.selectedPanel?.type === otherChartType.TEXT_EDITOR;
    },
    isPanelTypeSelector() {
      return this.selectedPanel?.type === chartTypes.SELECTOR_DATA_ITEM_CHART;
    },
    isPanelTypeFilterButton() {
      return this.selectedPanel?.type === otherChartType.BUTTON;
    },
    isPanelTypeFilterSelectList() {
      return (
        this.selectedPanel?.type === dashboardFilterTypes.FILTER_SELECT_LIST
      );
    },
    isPanelTypeLabel() {
      return this.selectedPanel?.type === chartTypes.LABEL;
    },
    isSearchLoading() {
      return this.loading[LoadingComponent.GetSearch];
    },
    isVisualizeAIGenerateLoading() {
      return this.loading[LoadingComponent.VisualizeAIGenerate];
    },
    showFiltersBoxByChartType() {
      return (
        !this.isPanelTypeFilterText &&
        !this.isPanelTypeFilterDate &&
        !this.isPanelTypeAdvancedButton &&
        !this.isPanelTypeSelector
      );
    },
    showSortBoxByChartType() {
      return this.showFiltersBoxByChartType && !this.isPanelTypeTextEditor;
    },
    chartsWithoutSlotsOrMandatoryInputs() {
      return (
        this.isPanelTypeAdvancedButton ||
        this.isPanelTypeSelector ||
        this.isPanelTypeLabel ||
        this.isPanelTypeLogo ||
        this.isPanelTypeFilterButton
      );
    },
    designMode() {
      return (
        this.selectedTabStyle[allPropertiesKeys.DASHBOARD_GRID_DESIGN_MODE] ||
        this.selectedPanel?.properties?.style?.[chartPropertiesEnum.CHART_DESIGN_MODE] ||
        this.selectedPanel?.type === chartTypes.TABLE
      );
    },
    isChartTypeFilter() {
      return this.selectedPanel?.type?.toLowerCase().includes("filter");
    },
    isPanelPage() {
      return this.$route.name == routerEnums.PANEL;
    },
    panelDataBoxes() {
      const selectedPropertyStyle = this.getPanelPropertyStyleForShowSlot();

      return chartBoxes({ isShowSlot: selectedPropertyStyle })
        .find((i) => i.chartType === this.chartType)
        ?.boxes?.filter((x) => x);
    },
    isChartTypeGeoMap() {
      return this.selectedPanel?.type === chartTypes.GEO_MAP_CHART;
    },
    colorPalettes() {
      return [
        ...(this.getCustomColorPalettes || []),
        ...(this.getColorPalettes || []),
      ];
    },
    showSlotMenu() {
      return (
        (!this.isPanelTypeAdvancedButton &&
          !this.isPanelTypeFilterButton &&
          !this.isPanelTypeLabel &&
          !this.isVisualizeAiQueryParamActive &&
          !this.isPanelTypeLogo &&
          this.isSlotMenuActive) ||
        (this.isVisualizeAiQueryParamActive && this.isSlotMenuActive)
      );
    },
    dynamicTitleShowValue() {
      return this.selectedPanel?.properties?.style?.[
        this.getPanelPropertyKey(includesValues.DYNAMIC_TITLE_SHOW_VALUE)
      ];
    },
  },
  methods: {
    ...mapActions({
      getDashboardById: ACTION_DASHBOARD.FETCH_DASHBOARDS_BY_ID,
      fetchDataModelById: ACTION_DATAMODEL.FETCH_DATAMODEL_BY_ID,
      updateDashboardPanel: ACTION_DASHBOARD.UPDATE_DASHBOARD,
      fetchDatasetByIdFields: ACTION_DATASET.FETCH_DATASET_BY_ID_FIELDS,
      fetchDatasetByIdFilterParams:
        ACTION_DATASET.FETCH_DATASET_BY_ID_FILTER_PARAMS,
      fetchChartByIdFields: ACTION_CHART_FIELDS.FETCH_CHART_BY_ID_FIELDS,
      fetchDashboardByIdFields:
        ACTION_DASHBOARD_FIELDS.FETCH_DASHBOARD_BY_ID_FIELDS,
      fetchColorPalettes: ACTION_COLOR_PALETTES.FETCH_COLOR_PALETTES,
      createColorPalettes: ACTION_COLOR_PALETTES.CREATE_COLOR_PALETTES,
      generateAI: ACTION_CHAT_GPT.GENERATE_AI,
    }),
    ...mapMutations({
      setSelectedPanel: MUTATION_DASHBOARD.SET_SELECTED_PANEL,
      setDashboardProperty: MUTATION_DASHBOARD.SET_DASHBOARD_PROPERTY,
      resetDatasetFilterParams: MUTATION_DATASET.RESET_DATASET_FILTER_PARAMS,
      setDashboardWithoutUndoRedo:
        MUTATION_DASHBOARD.SET_DASHBOARD_WITHOUT_UNDO_REDO,
      addNewItemToUndoStack: MUTATION_DASHBOARD.ADD_NEW_ITEM_TO_UNDO_STACK,
    }),
    deletePropertyValue(params) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      selectedPanelCloned.properties.style[params?.property] = null;

      this.updateSelectedPanel({ value: selectedPanelCloned });
    },
    handleSelectedStyleProperty(params) {
      this.selectedStyleProperty = params?.property;

      if (params?.eventId === undefined) return;

      if (params?.eventId === onPropertiesEventOptions.imagesPopup) {
        this.showImagesPopup = true;
      }
    },
    setSelectedImageChartProperty(imageId) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      selectedPanelCloned.properties.style[this.selectedStyleProperty] = imageId;

      this.updateSelectedPanel({ value: selectedPanelCloned });
      this.selectedStyleProperty = null;
      this.showImagesPopup = false;
    },
    reloadCacheInfo() {
      (this.evictionId = this.panelId ?? this.selectedPanel.i),
        (this.shouldRefreshCache = true);

      this.viewChart();

      this.evictionId = "";
      this.shouldRefreshCache = false;
    },
    getCacheInfo(chart) {
      return getCacheInfoByPropertyStyle({
        isConfigurationUseCacheActive: this.isConfigurationUseCacheActive,
        configurationDefaultCacheDurationInMinute:
          this.configurationDefaultCacheDurationInMinute,
        chart,
        datamodelProperties: this.selectedDataModel?.properties,
        dashboardPropertiesStyle: this.selectedTabStyle,
        evictionId: this.evictionId,
        shouldRefreshCache: this.shouldRefreshCache,
      });
    },
    getWarningMessageIsMetricEmpty() {
      if (!this.selectedPanel || this.isSelectedRightToolBoxVisualizeAI || this.chartsWithoutSlotsOrMandatoryInputs) return;
      
      return checkChartMetricEmpty({
        chartData: this.selectedPanel,
        message: this.$t(
          "notifyTitle.Content will be generated dynamically after selecting fields"
        ),
        showMandatoryMessage: this.isDataFromDatabaseActive,
      });
    },
    checkIfSelectorDataItemNameIsUnique(uniqueName) {
      if (!this.isPanelTypeSelector) return true;

      const sameUniqueName = this.parameterList.find(
        (x) => x.alias?.toLowerCase() === uniqueName?.toLowerCase()
      );

      if (sameUniqueName || uniqueName === "") {
        Notify(
          this.$t("notifyMessages.Create a unique name"),
          notificationType.WARNING
        );
        this.isUniqueNameCheckActive = true;
        return false;
      } else {
        this.isUniqueNameCheckActive = false;
        return true;
      }
    },
    changeChartField(params) {
      params.dashboard = cloneDeep(this.dashboard);
      params.triggerFromPanel = true;
      this.$root.$emit("changeChartFieldFromPanel", params);
    },
    getSelectorChartParameterList() {
      let selectorCharts = [];
      const clonedDashboard = cloneDeep(this.dashboard);

      clonedDashboard?.tabs.forEach((tab) => {
        selectorCharts = [
          ...selectorCharts,
          ...tab?.charts?.filter(
            (x) => x.type === chartTypes.SELECTOR_DATA_ITEM_CHART
          ),
        ];
      });

      const mappedSelectorCharts =
        mapSelectorChartParameterList(selectorCharts);

      if (!this.isPanelTypeSelector) return mappedSelectorCharts;

      const uniqueName =
        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_UNIQUE_NAME;
      let newUniqueName;

      if (this.selectedPanel?.properties?.style?.[uniqueName] !== undefined) {
        return mappedSelectorCharts;
      } else {
        newUniqueName = checkDuplicateValueInChartStyle({
          charts: selectorCharts,
          key: uniqueName,
          value: "Selector Parameter",
        });

        this.setSelectedPanelStyle({
          property: uniqueName,
          value: newUniqueName,
        });
      }

      return mappedSelectorCharts;
    },
    reRenderChart() {
      //This method renders all charts
      this.isChartsAvailable = false;

      setTimeout(() => {
        this.isChartsAvailable = true;
      }, 300);
    },
    toggleSlotMenu() {
      this.isSlotMenuActive = !this.isSlotMenuActive;
      this.reRenderChart();
    },
    toggleDatasetFieldMenu() {
      this.isDatasetFieldMenuActive = !this.isDatasetFieldMenuActive;
      this.reRenderChart();
    },
    getDatamodelPropertyValue(datamodelPropertyKey) {
      return getCommonDatamodelPropertyValueByKey(
        this.selectedDataModel?.properties,
        datamodelPropertyKey
      );
    },
    handleChangeSelectedToolBoxRightArea(val) {
      this.selectedToolBoxRightArea = val;
      this.reRenderChart();
    },
    async addNewChartWithChatBot(message) {
      //Message from user
      this.aiMessages.push(message);

      const bodyParam = {
        context: {
          name: "dashboard",
          id: "",
          data: Object.values(this.fields).flat(),
          chatId: this.visualizeAIChartId,
        },
        messages: this.aiMessages.map((m) => {
          return {
            content: m.text,
            role: m.agent === "user" ? "USER" : "AGENT",
          };
        }),
      };

      const response = await this.generateAI({
        bodyParam,
        loadingComponent: LoadingComponent.VisualizeAIGenerate,
      });

      this.aiMessages.push({
        agent: "bot",
        type: "text",
        text: response.message,
      });

      if (!response?.functionCall?.length) return;

      const detailsData = response.functionCall[0]?.param;

      let selectedPanelCloned = cloneDeep(this.selectedPanel);

      const { metrics, aggregation, filters = [], orders = [], columns = [] } =
        addNewKeyInDetailsDataArrays(detailsData);

      selectedPanelCloned.chartTypeName = chartList.find(
        (x) => x.id === detailsData?.chartType
      ).name;
      selectedPanelCloned.type = detailsData?.chartType;
      this.chartType = detailsData?.chartType;

      selectedPanelCloned.details = {
        FILTER_VALUE: "",
        metrics,
        aggregation,
        filters,
        orders,
        columns,
        params: [],
        sections: [],
      };

      this.updateSelectedPanel({ value: selectedPanelCloned });

      await this.viewChart();
    },
    checkHasSearchResponseError() {
      return this.searchResponseError?.[this.selectedPanel?.i]?.message;
    },
    changePanelDataDetailsData() {
      if (!this.unAuthorizedDashboardFields?.size) return;

      let selectedPanelCloned = cloneDeep(this.selectedPanel);
      const changeAuthorizeValue = (arr) => {
        if (!arr?.length) return;
        arr.forEach((field) => {
          if (this.unAuthorizedDashboardFields.has(field.fieldId)) {
            field.isAuthorize = false;
          }
        });
      };

      changeAuthorizeValue(selectedPanelCloned?.details?.metrics);
      changeAuthorizeValue(selectedPanelCloned?.details?.aggregation);
      changeAuthorizeValue(selectedPanelCloned?.details?.columns);
      changeAuthorizeValue(selectedPanelCloned?.details?.filters);
      changeAuthorizeValue(selectedPanelCloned?.details?.orders);
      changeAuthorizeValue(selectedPanelCloned?.details?.sections);

      this.updateSelectedPanel({ value: selectedPanelCloned });
    },
    setDefaultAuthorizeValueInFields() {
      //Kaydedip çıkmak istediğimizde key true set edilecek. Çünkü slot içindeki fieldlarda isAuthorize değeri false olarak kalmayacak
      if (!this.unAuthorizedDashboardFields?.size) return;

      let selectedPanelCloned = cloneDeep(this.selectedPanel);

      const changeAuthorizeValue = (arr) => {
        arr.forEach((field) => {
          if (this.unAuthorizedDashboardFields.has(field.fieldId)) {
            field.isAuthorize = true;
          }
        });
      };

      changeAuthorizeValue(selectedPanelCloned?.details?.metrics);
      changeAuthorizeValue(selectedPanelCloned?.details?.aggregation);
      changeAuthorizeValue(selectedPanelCloned?.details?.columns);
      changeAuthorizeValue(selectedPanelCloned?.details?.filters);
      changeAuthorizeValue(selectedPanelCloned?.details?.orders);
      changeAuthorizeValue(selectedPanelCloned?.details?.sections);

      this.updateSelectedPanel({ value: selectedPanelCloned });
    },
    getPanelDataByAuthorize() {
      if (!this.unAuthorizedDashboardFields?.size) return this.selectedPanel;

      return getFilteredPanelDataByAuthorize({
        chartData: this.selectedPanel,
        unAuthorizedDashboardFields: this.unAuthorizedDashboardFields,
      });
    },
    handleTableWrapperClicked() {
      this.$refs.PanelProperties?.closeColumnBasedProperties();
    },
    getPanelPropertyStyleForShowSlot() {
      if (this.isChartTypeGeoMap) {
        const style =
          this.selectedPanel?.properties?.style?.[
            GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SELECTED_COUNTRY
          ];
        return style;
      } else return true;
    },

    onPropertiesEventTriggered(event) {
      if (
        event.eventId === onPropertiesEventOptions.openConditionalFormatting
      ) {
        this.isConditionalFormattingPopupVisible = true;
      } else if (event.eventId === onPropertiesEventOptions.refreshCache) {
        this.reloadCacheInfo();
      } else {
        this.isSqlPopupVisible = true;
      }
    },
    setSelectedColumnBasedPropertiesColumn(value) {
      this.selectedColumnBasedPropertiesColumn = value;
    },
    resetSelectedColumnBasedPropertiesColumn() {
      this.selectedColumnBasedPropertiesColumn = null;
    },
    clickOnBody() {
      document.body.addEventListener("click", (e) => {
        if (this.selectedFieldsWithKeyCtrlShift.length && !e.ctrlKey)
          this.selectedFieldsWithKeyCtrlShift = [];
      });
    },
    addSelectedFieldsWithKeyCtrl(value) {
      if (!this.isPanelTypeTable) return; //used for table chart for now.

      const selectedItem = this.getSelectedFieldWithKeyCtrlShiftByFieldId(
        value.fieldId
      );

      if (selectedItem) {
        const selectedItemFindIndex =
          this.selectedFieldsWithKeyCtrlShift.findIndex(
            (f) => f.fieldId === selectedItem.fieldId
          );
        this.selectedFieldsWithKeyCtrlShift.splice(selectedItemFindIndex, 1);
      } else this.selectedFieldsWithKeyCtrlShift.push(value);
    },
    addSelectedFieldsWithKeyShift(params) {
      if (!this.isPanelTypeTable) return; //used for table chart for now.

      const fieldList = params.fieldList;
      const firstItemIndex = fieldList.findIndex(
        (x) => x.fieldId === this.selectedFieldsWithKeyCtrlShift[0]?.fieldId
      );
      const lastItemIndex = fieldList.findIndex(
        (x) => x.fieldId === params.value.fieldId
      );

      fieldList.forEach((element, index) => {
        const addedField = this.getSelectedFieldWithKeyCtrlShiftByFieldId(
          element.fieldId
        );

        if (index >= firstItemIndex && index <= lastItemIndex && !addedField) {
          this.selectedFieldsWithKeyCtrlShift.push(element);
        }
      });
    },
    getSelectedFieldWithKeyCtrlShiftByFieldId(fieldId) {
      return this.selectedFieldsWithKeyCtrlShift.find(
        (f) => f.fieldId === fieldId
      );
    },
    setSelectedPanelStyleByNoKey(property, value) {
      if (this.selectedPanel.properties.style[property] === undefined) {
        this.setSelectedPanelStyle({
          property,
          value,
        });
      }
    },
    //Chart'ların defaultta DynamicTitleValue properties alanı olmadığı için kontrol sağlatamıyoruz. Bundan kaynaklı eğer undefined ise
    // chart'ın name'ini dynamicTitle properties kısmına set ettiriyoruz.
    checkHasDynamicTitleValue(property) {
      const panelDataPropertiesStyle =
        this.selectedPanel.properties.style[property];
      if (
        !panelDataPropertiesStyle ||
        (panelDataPropertiesStyle && !this.dynamicTitleShowValue)
      ) {
        this.setSelectedPanelStyle({
          property,
          value: this.selectedPanel.name,
        });
      }
    },
    checkHasGroupMultipleValues(property, defaultValue) {
      const panelDataPropertiesStyle =
        this.selectedPanel.properties.style[property];

      if (panelDataPropertiesStyle === undefined) {
        this.setSelectedPanelStyle({
          property,
          value: defaultValue,
        });
      }
    },

    checkHasUniqueName(property) {
      if (this.selectedPanel.properties.style[property] == undefined) {
        const date = new Date();
        const uniqueDate = date.getTime().toString();

        this.setSelectedPanelStyle({
          property,
          value: `Parameter${uniqueDate.substring(5, 10)}`,
        });
      }
    },

    checkFilterTypeIsCustom() {
      if (this.isChartTypeFilter) {
        const customFilter = this.getCustomFilterParamsMappedProperty();

        if (customFilter) {
          let newObj = {};
          Object.keys(customFilter).forEach((element) => {
            newObj[element] = customFilter[element];
          });
          return {
            key: newObj,
            isDisabled: true,
          };
        } else
          return {
            key: {},
            isDisabled: false,
          };
      }
    },

    getCustomFilterParamsMappedProperty() {
      const fieldList = [
        ...this.selectedPanel?.details?.metrics,
        ...this.selectedPanel?.details?.customFilters,
      ];

      return fieldList
        .filter(
          (x) => x.mandatory !== undefined || x.multi_select !== undefined
        )
        .map((m) => {
          return {
            [this.getPanelPropertyKey(includesValues.MANDATORY)]: m.mandatory,
            [this.getPanelPropertyKey(includesValues.ALLOW_MULTISELECT)]:
              m.multi_select,
            [this.getPanelPropertyKey(includesValues.FILTER_DEFAULT_SELECTION)]:
              m?.default_value ? DefaultValue.SELECTION : DefaultValue.NONE,
          };
        })[0];
    },

    getPanelPropertyKeyMultiple(includesKeys) {
      const keys = Object.keys(this.selectedPanel.properties.style);
      const newKeys = [];

      includesKeys.forEach((element) => {
        const selectedKey = keys.find((x) => x.includes(element));

        newKeys.push(selectedKey);
      });

      return newKeys;
    },
    getPanelPropertyKey(includesKey) {
      const keys = Object.keys(this.selectedPanel.properties.style);
      const selectedKey = keys.find((x) => x.includes(includesKey));
      return selectedKey;
    },
    //For Filter Chart Component.
    //Dataset listesinden slot'a sürükle bırak yapıldığında bu method çalışır.
    changeFilterChartPropertyValue() {
      if (this.isChartTypeFilter) {
        const customFilterPropertyValues =
          this.getCustomFilterParamsMappedProperty();

        const selectedKeys = this.getPanelPropertyKeyMultiple([
          includesValues.MANDATORY,
          includesValues.ALLOW_MULTISELECT,
          includesValues.FILTER_DEFAULT_SELECTION,
        ]);

        if (customFilterPropertyValues) {
          selectedKeys.forEach((element) => {
            if (element)
              this.setSelectedPanelStyle({
                property: element,
                value: customFilterPropertyValues[element],
              });
          });
        }
      }
    },

    async getCustomFilterParams() {
      const datasetsByDatamodelId = this.selectedDataModel.datasets;

      for (let index = 0; index < datasetsByDatamodelId.length; index++) {
        const element = datasetsByDatamodelId[index];
        await this.fetchDatasetByIdFilterParams({
          loadingComponent: LoadingComponent.CustomFilters,
          id: element.datasetId,
        });
      }
    },
    async getDatasetFieldsControl() {
      if (this.panelId) {
        await this.fetchChartByIdFields(this.panelId);
      } else if (this.dashboardId)
        await this.fetchDashboardByIdFields(this.dashboardId);
    },
    mergeCardAndTemplateStyleAsCSS() {
      const merged = this.mergeCardAndTemplateStyle();
      const resultStyle = {};

      Object.keys(merged).forEach((propKey) => {
        const convertedCSSKey = propertiesKeyToCSSKeyMapping[propKey];

        if (convertedCSSKey) resultStyle[convertedCSSKey] = merged[propKey];
      });

      return resultStyle;
    },
    mergeCardAndTemplateStyle() {
      return {
        ...this.selectedTemplateProperties,
        ...this.selectedPanel?.properties?.style,
      };
    },
    mergeCardAndTemplateStyleSingleProperty(propKey) {
      return this.mergeCardAndTemplateStyle()?.[propKey];
    },
    hasBorderinChartCard() {
      return this.mergeCardAndTemplateStyle?.[allPropertiesKeys.HAS_BORDER]
        ? "vis-border"
        : "";
    },

    setSelectedPanelStyle(params) {
      let selectedPanelCloned = cloneDeep(this.selectedPanel);

      selectedPanelCloned.properties.style[params.property] = params.value;

      selectedPanelCloned = this.updateTableDataForSearchParamByAggregateResult(
        selectedPanelCloned,
        params
      );

      selectedPanelCloned = this.updateGeoMapForSearchParamByAggregateResult(
        selectedPanelCloned,
        params.value
      );

      this.updateSelectedPanel({ value: selectedPanelCloned });

      if (
        this.isChartTypeGeoMap &&
        diff(this.selectedPanel.details, selectedPanelCloned.details)
      ) {
        this.viewChart();
      }
    },

    updateTableDataForSearchParamByAggregateResult(
      selectedPanelCloned,
      params
    ) {
      if (
        selectedPanelCloned.type === chartTypes.TABLE &&
        params.property ===
          datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
      ) {
        selectedPanelCloned =
          changeVisTypeForSearchParamByAggregateResult(selectedPanelCloned);

        selectedPanelCloned =
          setSelectedPanelDetailAggregationAndMetrics(selectedPanelCloned);
      }
      return selectedPanelCloned;
    },
    updateGeoMapForSearchParamByAggregateResult(selectedPanelCloned, value) {
      if (this.isChartTypeGeoMap && countryValues.TR.includes(value)) {
        selectedPanelCloned.details.aggregation =
          selectedPanelCloned?.details?.aggregation?.filter(
            (x) => x.boxKey === BOX_KEY_ENUM.CITY.KEY
          );
      }
      return selectedPanelCloned;
    },
    // Column based formattings setter method
    setDataTableColumnBasedComponentProperties(params) {
      const slotFieldId =
        params?.fieldData?.slotFieldId ??
        this.selectedColumnBasedPropertiesColumn?.slotFieldId;
      const fieldId =
        params?.fieldData?.fieldId ??
        this.selectedColumnBasedPropertiesColumn?.fieldId;

      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      if (!selectedPanelCloned.properties?.columnBasedFormattings) {
        selectedPanelCloned.properties.columnBasedFormattings = [];
      }

      const findExistingColumnBasedFormattings = () => {
        return selectedPanelCloned.properties.columnBasedFormattings.find((f) =>
          slotFieldId ? f.slotFieldId === slotFieldId : f.fieldId === fieldId
        );
      };

      const filterExistingColumnBasedFormattingsByColumn = () => {
        return selectedPanelCloned.properties.columnBasedFormattings.filter(
          (f) =>
            slotFieldId ? f.slotFieldId !== slotFieldId : f.fieldId !== fieldId
        );
      };

      const updateColumnBasedFormattings = () => {
        let foundExistingColumnBasedFormattings =
          findExistingColumnBasedFormattings();

        if (!foundExistingColumnBasedFormattings) {
          foundExistingColumnBasedFormattings = {
            formattings: {},
            fieldId: fieldId,
            slotFieldId: slotFieldId,
          };
        }

        foundExistingColumnBasedFormattings.formattings[params.property] =
          params.value;

        return foundExistingColumnBasedFormattings;
      };

      const updatedColumnBasedFormattingsByColumn =
        filterExistingColumnBasedFormattingsByColumn();

      updatedColumnBasedFormattingsByColumn.push(
        updateColumnBasedFormattings()
      );

      selectedPanelCloned.properties.columnBasedFormattings =
        updatedColumnBasedFormattingsByColumn;
      this.updateSelectedPanel({ value: selectedPanelCloned });
    },

    setDashboardStyle(params) {
      this.setDashboardProperty(params);
    },
    addFiltersToPanelFromPanel(param) {
      param.triggerFromPanel = true;
      param.chartList = this.chartList;
      param.dashboard = this.dashboard;

      this.$root.$emit("addFiltersToPanelFromPanel", param);
    },

    setConfirm() {
      this.confirmLeave = true;
      this.dialogVisible = false;
      this.$router.push({ name: this.beforeRouteLink });
    },

    updateSelectedPanel(params) {
      params.tabIndex = this.tabIndex;
      params.panelId = this.queryPanelId;
      this.setSmartTitle(params.value);
      this.setSelectedPanel(params);
    },
    updateSelectedPanelAndViewChart(params) {
      this.updateSelectedPanel(params);
      this.viewChart();
    },
    mapSelectedPanelAndViewChart(panel) {
      this.updateSelectedPanelAndViewChart({
        value: panel,
      });
    },
    mapSelectedPanelUpdate(panel) {
      this.updateSelectedPanel({
        value: panel,
      });
    },
    async setSmartTitle(chartData) {
      const {
        name,
        properties: { smartTitle },
      } = chartData;

      if (!name || (name && smartTitle)) {
        chartData.name = createSmartTitle(chartData);
        chartData.properties.smartTitle = true;
      }
    },
    async updatePanel(payload) {
      await this.updateDashboardPanel({
        loadingComponent: LoadingComponent.DashboardPage,
        payload,
      });
    },
    async saveAndPreviousDashboard(queryParam) {
      if (this.isUniqueNameCheckActive) {
        const isSelectorDataItemNameUnique =
          this.checkIfSelectorDataItemNameIsUnique(
            this.selectedPanel.properties.style[
              SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM
                .SELECTOR_DATA_ITEM_CHART_UNIQUE_NAME
            ]
          );

        if (!isSelectorDataItemNameUnique) return;
      }

      this.addNewItemToUndoStack(this.tempDashboard);
      //Eğer chartın filter listesine field eklendi ve filtre değeri atanmadıysa eklenen fieldı sildirmek için. Çünkü backend filter içindeki fieldlardan value değeri bekler.
      this.deleteNoneValueInFilterList();
      //FOR FILTER CHARTS
      this.deleteFilterInChartsIfMetricsEmptyOrChanged();

      setDefaultProperties(this?.selectedPanel?.type, {
        checkHasDynamicTitleValue: this.checkHasDynamicTitleValue,
        checkHasGroupMultipleValues: this.checkHasGroupMultipleValues,
        checkHasUniqueName: this.checkHasUniqueName,
        setSelectedPanelStyleByNoKey: this.setSelectedPanelStyleByNoKey,
      })?.fn?.forEach((f) => {
        f();
      });

      this.setDefaultAuthorizeValueInFields();

      if (this.dashboardId) {
        this.isChartsAvailable = false;
        this.goDashboardEdit(queryParam);
      } else {
        this.goDashboardPage(queryParam);
      }
    },

    goToCalculatedColumn(datasetId) {
      if (!this.dashboardId) {
        Notify(
          this.$t(
            "notifyMessages.To create a calculated area, you must first save the dashbaord"
          ),
          notificationType.WARNING
        );

        return;
      }

      this.saveAndPreviousDashboard({
        calculatedColumnDatasetId: datasetId,
        calculatedColumnQueryPanelId: this.queryPanelId,
        calculatedColumnChartType: this.chartType,
        calculatedColumnTabIndex: this.tabIndex,
      });
    },

    previousDashboard() {
      if (this.isVisualizeAiQueryParamActive) {
        const clonedDashboard = cloneDeep(this.dashboard);

        clonedDashboard.tabs[this.tabIndex].charts = clonedDashboard?.tabs[
          this.tabIndex
        ]?.charts.filter((x) => x.i !== this.queryPanelId);
        this.setDashboardWithoutUndoRedo(clonedDashboard);
      } else {
        this.updateSelectedPanel({ value: this.tempSelectedPanel });
      }

      this.goDashboardPage();
    },

    getOpenLogosSortMenu() {
      this.$refs?.logoComponent?.triggerUploadImageSortMenu();
    },

    goDashboardPage() {
      this.isChartsAvailable = false;
      if (this.dashboardId) this.goDashboardEdit();
      else this.goDashboard();
    },

    goDashboard(queryParam) {
      setTimeout(() => {
        this.$router.push({
          name: routerEnums.DASHBOARD,
          params: { previousPagePanel: true },
          query: { tabIndex: this.tabIndex, ...queryParam },
        });
      }, 500);
    },
    goDashboardEdit(queryParam) {
      setTimeout(() => {
        this.$router.push({
          name: routerEnums.DASHBOARD_EDIT,
          params: { id: this.dashboardId, previousPagePanel: true },
          query: { tabIndex: this.tabIndex, ...queryParam },
        });
      }, 500);
    },

    deleteNoneValueInFilterList() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      const panelFilterList = selectedPanelCloned?.details?.filters;

      if (!panelFilterList?.length) return;

      panelFilterList.forEach((element, index) => {
        if (!element.value) {
          panelFilterList.splice(index, 1);
          this.updateSelectedPanel({ value: selectedPanelCloned });
        }
      });
    },
    deleteFilterInChartsIfMetricsEmptyOrChanged() {
      if (
        !this.isChartTypeFilter ||
        !this.tempSelectedPanel?.details?.metrics[0]?.fieldId
      )
        return;

      const clonedDashboard = cloneDeep(this.dashboard);
      const tempMetric = this.tempSelectedPanel?.details?.metrics[0];
      const panelMetric = this.selectedPanel?.details?.metrics[0];
      const isMetricFieldDifferent = tempMetric?.fieldId !== panelMetric?.fieldId;
      const isCustomFilterDifferent = panelMetric?.filterType === filterType.CUSTOM && tempMetric?.default_value !== panelMetric?.default_value && !panelMetric?.default_value;
      const metricsLength = this.selectedPanel?.details?.metrics?.length;

      if (
        (this.selectedPanel.details?.FILTER_VALUE && !metricsLength) ||
        (metricsLength && (isMetricFieldDifferent || isCustomFilterDifferent))
      ) {
        clonedDashboard.tabs.forEach((tab) => {
          tab.charts.forEach((chart) => {
            if (!chart?.details?.filters) return;

            if (chart.i === this.selectedPanel.i) {
              chart.details.FILTER_VALUE = null;
            }

            if (isCustomFilterDifferent && chart?.details?.[detailsKeys.PARAMS]) {
              chart.details[detailsKeys.PARAMS] = chart.details?.[
                detailsKeys.PARAMS
              ].filter((x) => x.filterId !== this.selectedPanel.i);
            } else {
              chart.details.filters = chart.details.filters.filter(
                (x) => x.filterId !== this.selectedPanel.i
              );
            }
          });
        });

        this.setDashboardWithoutUndoRedo(clonedDashboard);
      }
    },
    async fetchAndControlColorPalettes() {
      const colorPalettes = await this.fetchColorPalettes({
        loadingComponent: LoadingComponent.ColorPalettes,
      });
      if (colorPalettes.length < 2) {
        defaultColorPalettes.forEach(async (palette) => {
          await this.createColorPalettes({
            name: palette.name,
            paletteColors: palette.paletteColors,
          });
        });
      }
    },

    setPropertiesStyle(selectedPanelCloned, chartTypeToChange) {
      let chartUsedChartType = this.selectedPanel?.type;
      const propertiesStyle = {};

      if (this.isChartTypeFilter) {
        //Bu methodu kullanma sebebimiz filtre componentinin chart tipi ile properties style içindeki key'leri aynı yapmak için.
        const changeValueTextToFilterText = (value) => {
          value = value.substring(value.indexOf("_"));
          value = value.slice(1) + "_FILTER";
          return value;
        };

        chartTypeToChange = changeValueTextToFilterText(chartTypeToChange);
        chartUsedChartType = changeValueTextToFilterText(chartUsedChartType);
        selectedPanelCloned.details.FILTER_VALUE = null;
      }

      Object.keys(selectedPanelCloned?.properties?.style).forEach(
        (styleKey) => {
          const defaultStyleKey = cloneDeep(styleKey);

          if (styleKey.includes(chartUsedChartType)) {
            styleKey = styleKey.replace(chartUsedChartType, chartTypeToChange);
          }
          propertiesStyle[styleKey] =
            selectedPanelCloned?.properties?.style[defaultStyleKey];
        }
      );
      selectedPanelCloned.properties.style = propertiesStyle;
    },
    changeChartType(chartItem) {
      const chartTypeToChange = chartItem.id;
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      selectedPanelCloned.type = chartTypeToChange;
      selectedPanelCloned.chartTypeName = chartItem.name;

      this.setPropertiesStyle(selectedPanelCloned, chartTypeToChange);
      this.chartType = chartTypeToChange;

      if (chartTypeToChange == chartTypes.TABLE) {
        selectedPanelCloned.properties.vismetadata.vistype = chartTypes.TABLE;
        for (
          let index = 0;
          index < selectedPanelCloned.details.metrics.length;
          index++
        ) {
          selectedPanelCloned.details.metrics.splice(index, 1);
        }
      } else {
        checkLimitAndSetPanelFields(selectedPanelCloned);
        selectedPanelCloned.properties.vismetadata.vistype = chartTypes.OTHER;
      }

      this.updateSelectedPanel({ value: selectedPanelCloned });
      this.viewChart();
    },
    handleResize(sizes) {
      this.leftSplitMenuWidth = sizes[0]?.size * 10;
      this.setDashboardStyle({
        property: allPropertiesKeys.CHART_DATAMODEL_LEFT_MENU_WIDTH,
        value: sizes[0]?.size,
      });
    },
    updatePaneSize() {
      this.totalWidth = window.innerWidth;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.diffDashboardData = diff(this.dashboard, this.tempSelectedDashboard);
    if (
      Object.keys(this.diffDashboardData).length &&
      to.name === routerEnums.HOME
    ) {
      this.dialogVisible = true;
      this.beforeRouteLink = to.name;
      if (this.confirmLeave) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>
<style scoped>
.vis-chart-card.min-h-89_5 {
  min-height: 89.5vh;
}
.vis-chart-card.min-h-88_5 {
  min-height: 88.5vh;
}
.vis-not-border {
  border: none !important;
}
::v-deep .e-gridcontent {
  height: calc(100% - 160px) !important;
}
.vis-settings-bar-left-area {
  position: relative;
  width: 220px;
}
::v-deep .splitpanes__pane {
  display: flex;
}
/* to get rid of the default transition */
.splitpanes .splitpanes__pane {
    transition: none;
}
::v-deep .vis-chart-placeHolder {
  top: 35% !important;
}
</style>
