import { createMetricKey, getConcatArrayWithPipe, mapAxisItems, mapChartDataByColorSlot } from "../chartOptionsHelper";
import { groupBy } from "lodash";
import { BOX_KEY_ENUM } from "../../commons/dashboardAndPanel";
import { purgeDuplicatesFromArray } from "../utils";

export const combinationChartSearch = (params, searchData, chartI) => {
  const groups = groupBy(
    [...params.aggregation, ...params.metric],
    (val) => val.boxKey
  );
  const metricFormatsMapping = params?.metric?.reduce((accumulator, value) => {
    return { ...accumulator, [value.field]: value.format };
  }, {});
  if(!groups) return {};
  
  const barKeys = groups[BOX_KEY_ENUM.BARS.KEY]?.map(i => ({key: createMetricKey(i), field:i.field, alias:i.alias, format:i.format})) || [];
  const lineKeys = groups[BOX_KEY_ENUM.LINES.KEY]?.map(i => ({key: createMetricKey(i), field:i.field, alias:i.alias, format:i.format})) || [];
  const sliceItems = groups[BOX_KEY_ENUM.SLICES.KEY]?.map(mapAxisItems) || [];
  const aggregationFormats = params?.aggregation?.map((a) => a.format);

  let xAxisData = searchData.aggregations.map((i) => {
     return getConcatArrayWithPipe(sliceItems?.map((item) => i[item.field])) || "";
  });

  if (!params?.orders?.length) {
    xAxisData = purgeDuplicatesFromArray(xAxisData);
  }

  let barSeries = [],
      lineSeries = [],
      metricFormats = [],
      dataSeriesNames = [];

  const barData = mapChartDataByColorSlot(groups[BOX_KEY_ENUM.BARS.KEY], params.aggregation, searchData.aggregations, xAxisData, true);
  const lineData = mapChartDataByColorSlot(groups[BOX_KEY_ENUM.LINES.KEY], params.aggregation, searchData.aggregations, xAxisData, true);

  if(barData) {
    barData.dataSeries.forEach((item) => {
      barSeries.push({
        type: "bar",
        data: item
      })
    })
    dataSeriesNames.push(...barData.dataSeriesNames);
    metricFormats.push(...barData.metricFormats);
  } else {
    barSeries = barKeys.map(i => {
      dataSeriesNames.push(i.alias);
      metricFormats.push(i.format);

      return {
        type: "bar",
        data: [],
        metricKey: i.key
      }
    });

    searchData.aggregations.forEach((i) => {
      barSeries.forEach(bar => {
        bar.data.push(i[bar.metricKey]);
      })
    })
  }
  
  if(lineData) {
    lineData.dataSeries.forEach((item) => {
      lineSeries.push({
        type: "line",
        data: item
      })
    })
    dataSeriesNames.push(...lineData.dataSeriesNames);
    metricFormats.push(...lineData.metricFormats);
  } else {
    lineSeries = lineKeys.map(i => {
      dataSeriesNames.push(i.alias);
      metricFormats.push(i.format);

      return {
        type: "line",
        data: [],
        metricKey: i.key
      }
    })

    searchData.aggregations.forEach((i) => {
      lineSeries.forEach(line => {
        line.data.push(i[line.metricKey]);
      })
    })
  }

  const axisData = {
    x: {
      name: getConcatArrayWithPipe(sliceItems?.map(i => i.alias)),
      data: [...xAxisData]
    },
    y: {
      name: getConcatArrayWithPipe(barKeys?.map(i => i.alias)),
      formats: barKeys?.map(i => i.format)
    },
    y2: {
      name: getConcatArrayWithPipe(lineKeys?.map(i => i.alias)),
      formats: lineKeys?.map(i => i.format)
    }
  };

  const dataSeries = [...barSeries, ...lineSeries]
  let dataSeriesTotal = 0;
  
  if (dataSeries?.length) {
    dataSeries?.forEach((item) =>
      item?.data?.forEach((x) => (dataSeriesTotal += parseInt(x)))
    );
  }

  return {
    dataSeries,
    dataSeriesTotal,
    dataSeriesNames: purgeDuplicatesFromArray(dataSeriesNames),
    axisData,
    id: chartI,
    metricFormats,
    metricFormatsMapping,
    aggregationFormats
  };
};

