<template>
  <div class="no-drag vis-chart-filter" :class="margin">
    <input
      @change.stop="addFiltersToPanels({ value: $event.target.value, fieldHasNoDefaultValue: true })"
      :value="filterValueParse"
      type="text"
      class="vis-input"
      :class="{ 'vis-bg-color-edf1ff': isFilterPaneActive }"
      :disabled="disabledTextField"
      :placeholder="$t('filterComponents.enterFilterValue')"
      :required="isRequiredActive"
      :tabindex="tabIndex"
    />
  </div>
</template>
<script>
import {
  DefaultValue,
  TEXT_FILTER_PROPERTIES_ENUM,
} from "../../../commons/dashboardProperties";
import { filterOperator } from "../../../commons/filterComponents";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
import {
  checkFilterDefaultValueSelection,
  checkFilterDefaultValueNone,
} from "../../../util/chart-filter/filterChartCommon";
import { getSelectedFieldDatasetId } from "../../../util/dataset-fields/datasetFields";
import deepEqual from "deep-equal";

export default {
  mixins: [panelDataPropertiesStyle],

  props: {
    panelData: {
      type: Object,
    },
    isFilterPaneActive: {
      type: Boolean,
      default: false,
    },
    isRequiredFilterValue: {
      type: Boolean,
      default: false,
    },
    isPanelPage: {
      type: Boolean,
      default: false,
    },
    scheduleFilterValue: {
      type: Object,
      default: null,
    },
    margin: {
      type: String,
      default: "vis-mr--1",
    },
    filterOnMount: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    textFilterDefaultValue: {
      handler(val, newVal) {
        if (this.hasDefaultValue) {
          this.addFiltersToPanels({ value: this.selectedField?.default_value, fieldHasNoDefaultValue: false });
        } else if (checkFilterDefaultValueNone(val) || val !== newVal) {
          this.addFiltersToPanels({ value: null });
        }
      },
    },
    textFilterCaseInsensitive: {
      handler(newVal, oldVal) {
        if (newVal === oldVal || oldVal === null) {
          return;
        }
        this.addFiltersToPanels({ value: this.filterValueParse });
      }
    },
    selectedField: {
      handler(val) {
        if (
          !val &&
          !this.isFilterPaneActive &&
          !checkFilterDefaultValueNone(this.textFilterDefaultValue)
        ) {
          this.setSelectedPanelStyle(
            DefaultValue.NONE,
            TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_DEFAULT_SELECTION
          );
        }
      },
      sync: true,
    },
  },
  mounted() {
    //It works if the field inside the slot has a default_value.
    this.addFiltersToPanelsWithFieldDefaultValue();

    this.addFiltersToPanelWithFilterDefaultValue();
  },
  computed: {
    isRequiredActive() {
      return (
        this.textFilterMandatorySelection &&
        !this.filterValueParse &&
        this.isRequiredFilterValue
      );
    },
    hasDefaultValue() {
      return (
        this.selectedField?.default_value &&
        checkFilterDefaultValueSelection(this.textFilterDefaultValue)
      );
    },
    tabIndex() {
      return this.CHART_COMPUTED_VALUES.TEXT_FILTER_TAB_INDEX;
    },
    disabledTextField() {
      return (
        (checkFilterDefaultValueNone(this.textFilterDefaultValue) &&
          this.isPanelPage) ||
        !this.panelDataDetailsMetrics?.length
      );
    },

    filterValueParse() {
      //this.scheduleFilterValue => schedule popup'ında filters tabında kullanılır.
      const filterValue = this.scheduleFilterValue ? this.scheduleFilterValue?.[this.selectedField?.field]?.value : this.filterValue?.[this.selectedField?.field]?.value;
      return typeof filterValue == "object"
        ? filterValue.join(",")
        : filterValue;
    },
    selectedField() {
      return this.panelDataDetailsMetrics?.[0];
    },
  },
  methods: {
    addFiltersToPanels({ value, fieldHasNoDefaultValue = true, isFilterAddActiveWithDefaultValue = false }) {
      this.setTextFilterDefaultValueActive(fieldHasNoDefaultValue);

      let splitedValue = value?.split(/[,;]+/);

      //If first character space, delete first character.
      splitedValue = splitedValue?.map((m) => m.trimStart());

      let splitedValueLength = splitedValue?.length > 1;

      const params = {
        value: value
          ? {
              [this.selectedField.field]: {
                value: splitedValueLength
                  ? splitedValue
                  : splitedValue.join(""),
                alias: this.selectedField?.alias,
              },
            }
          : {},
        operator: splitedValueLength ? filterOperator.IN : filterOperator.EQ,
        panelI: this.panelData.i,
        isActiveDefaultValue: checkFilterDefaultValueSelection(
          this?.textFilterDefaultValue
        ),
        datasetId: getSelectedFieldDatasetId(this.panelData),
        isActiveMandatory: this.textFilterMandatorySelection,
        filterType: this.selectedField?.filterType,
        caseInsensitiveMatching: this.textFilterCaseInsensitive,
        applyToTabs: this.textFilterApplyToTabs,
        notRunTheViewChart: isFilterAddActiveWithDefaultValue
      };
      this.$emit("addFiltersToPanels", params);
    },
    setTextFilterDefaultValueActive(event) {
      let defaulValue =
        event && this.textFilterDefaultValueActive && this.isPanelPage
          ? false
          : true;

      this.setSelectedPanelStyle(
        defaulValue,
        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_DEFAULT_VALUE_ACTIVE
      );
    },
    addFiltersToPanelsWithFieldDefaultValue() {
      if (
        this.textFilterDefaultValueActive &&
        this.hasDefaultValue &&
        !this.isFilterPaneActive &&
        !deepEqual(this.selectedField?.default_value, this.filterValueParse) &&
        !this.scheduleFilterValue
      ) {
        this.addFiltersToPanels({ value: this.selectedField?.default_value, fieldHasNoDefaultValue: false });
      }  
    },
    addFiltersToPanelWithFilterDefaultValue() {
      if (
        !checkFilterDefaultValueNone(this.textFilterDefaultValue) &&
        this.filterValueParse &&
        this.filterOnMount
      ) {
        this.addFiltersToPanels({ value: this.filterValueParse, isFilterAddActiveWithDefaultValue: true });
      }
    },
    setSelectedPanelStyle(value, property) {
      this.$emit("setSelectedPanelStyle", {
        property: property,
        value: value,
      });
    },
  },
};
</script>
