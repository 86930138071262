var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vis-display-flex vis-flex-direction--column"},[_c('AdministrationTopBar'),_c('div',{staticClass:"vis-dataset--view administration-left-panel-and-body"},[_c('div',{staticClass:"vis-row vis-height-100"},[_c('div',{staticClass:"vis-height-100 administration-left-panel"},[_c('AdministrationLeftPanel',{attrs:{"activeMenuItem":_vm.activeMenuItem,"menuItemTypes":_vm.menuItemTypes},on:{"connectionClicked":function($event){_vm.administrationState = _vm.administrationStates.connectionView},"manageUsersClicked":function($event){_vm.administrationState = _vm.administrationStates.manageUsers},"environmentsClicked":function($event){_vm.administrationState = _vm.administrationStates.environments},"migrateContentClicked":_vm.handleMigrateContentSelected,"settingsClicked":function($event){_vm.administrationState = _vm.administrationStates.settings},"schedulesClicked":function($event){_vm.administrationState = _vm.administrationStates.schedules},"uploadImagesClicked":function($event){_vm.administrationState = _vm.administrationStates.uploadImages},"licenseClicked":function($event){_vm.administrationState = _vm.administrationStates.license}}})],1),_c('div',{staticClass:"administration-right-panel vis-ma--none vis-pa--none vis-height-100"},[(
            _vm.administrationState === _vm.administrationStates.connectionView ||
            _vm.administrationState === _vm.administrationStates.connectionAccess
          )?_c('SelectDatabaseConnection',{attrs:{"hideCreateButton":false,"hideSelectOptions":true,"currentUserRoles":_vm.currentUserRoles},on:{"createNewConnection":_vm.createNewConnection,"deleteClicked":_vm.deleteClicked,"editClicked":_vm.editClicked,"checkClicked":_vm.checkClicked}}):_vm._e(),(
            _vm.administrationState === _vm.administrationStates.connectionCreate ||
            _vm.administrationState === _vm.administrationStates.connectionEdit
          )?_c('CreateAndEditConnection',{staticClass:"create-and-edit-connection-wrapper",attrs:{"connectionItem":_vm.connectionEditItem,"testConnectionLoading":_vm.isTestConnectionLoading,"saveConnectionLoading":_vm.isSaveConnectionLoading,"connectionTypes":_vm.connectionTypes},on:{"back":function($event){_vm.administrationState = _vm.administrationStates.connectionView},"testConnection":_vm.callTestConnection,"saveConnection":_vm.callSaveConnection}}):_vm._e(),(_vm.administrationState === _vm.administrationStates.environments)?_c('EnvironmentsList'):_vm._e(),(
            _vm.administrationState ===
            _vm.administrationStates.migrateContentSelectionTable
          )?_c('MigrateContentSelectionTable',{attrs:{"lastQueryData":_vm.lastQueryData,"lastSelectedObject":_vm.lastSelectedObject},on:{"onContinueClicked":_vm.onMigrateContentSelectionTableContinueClicked}}):_vm._e(),(
            _vm.administrationState ===
            _vm.administrationStates.migrateContentSelectTargetEnvAndOptions
          )?_c('MigrateContentSelectTargetEnvAndOptions',{attrs:{"selectedObject":_vm.migrateContentSelectedObject},on:{"onDoneClicked":_vm.migrateContentSelectTargetEnvAndOptionsDoneClicked}}):_vm._e(),(_vm.administrationState === _vm.administrationStates.settings)?_c('Settings'):_vm._e(),(_vm.administrationState === _vm.administrationStates.schedules)?_c('Schedules'):_vm._e(),(_vm.administrationState === _vm.administrationStates.uploadImages)?_c('UploadImages'):_vm._e(),(_vm.administrationState === _vm.administrationStates.license)?_c('LicenseInfo'):_vm._e(),(_vm.administrationState === _vm.administrationStates.manageUsers)?_c('iframe',{staticClass:"vis-keyCloackLoginİframe",attrs:{"src":_vm.keyCloackUrl,"title":"Keycloack Login","name":"keycloack"}}):_vm._e()],1)])]),(_vm.administrationState === _vm.administrationStates.connectionAccess)?_c('div',{staticClass:"vis-position-fixed vis-dashboard-custom-col administrationPropertiesContainer w300 vis-tools-box vis-ma--none vis-mobile-hidden vis-scroll-thin vis-height-100 vis-overflow-auto"},[_c('AdministrationProperties',{attrs:{"connection":_vm.connectionEditItem},on:{"close":_vm.closeAdministrationProperties}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }