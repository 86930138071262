<template>
  <textarea
    class="no-drag"
    rows="1"
    :value="value"
    :id="textAreaId"
    :class="textAreaClass"
    :placeholder="$t(placeHolder)"
    :style="fontStyle"
    :disabled="isModeDashboardView || dynamicTitleShowValue"
    @click.stop
    @change.stop="$emit('updateValue', $event, textAreaType)"
    @keyup="$emit('changeTextAreaHeight', textAreaType)"
  ></textarea>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
    },
    fontStyle: {
      type: Object,
    },
    isModeDashboardView: {
      type: Boolean,
    },
    textAreaId: {
      type: String,
    },
    placeHolder: {
      type: String,
    },
    textAreaClass: {
      type: String,
    },
    textAreaType: {
      type: String,
    },
    dynamicTitleShowValue: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
