<template>
  <div class="vis-flex-scrollbar">
    <header :style="getCustomStyleHeaderByCustomer">
      <div class="vis-container">
        <div class="vis-row">
          <div class="vis-col vis-pa--none vis-col-xl8 vis-col-l12 vis-col-m12">
            <HomeCreateCard
              :forDataFlowDoesUserHaveEditRoles="
                forDataFlowDoesUserHaveEditRoles
              "
              @createClicked="createClicked"
            />
          </div>
          <div
            class="vis-col vis-col-3 vis-col-xl4 vis-col-l12 vis-col-m12 vis-welcome-message"
            :class="[
              { 'vis-col-4': !isBannerImageShow },
              { 'vis-flex--Xright': !isBannerImageShow },
            ]"
          >
            <WelcomeBox :message="customerWelcomeMessage" />
          </div>
          <div
            v-if="isBannerImageShow"
            class="vis-col vis-col-3 vis-col-xxxl2 vis-col-xl2 vis-col-s12 vis-tablet-hidden"
          >
            <img
              class="vis-header-image"
              :style="getCustomStyleHeaderImageByCustomer"
              :src="getHeaderImage()"
              :alt="imageName"
            />
          </div>
        </div>
      </div>
    </header>

    <div :class="{ 'vis-big-size': bigSize }" class="vis-flex-scrollbar">
      <section class="vis-bg-color-white">
        <div class="vis-container">
          <div class="vis-row">
            <div class="vis-col vis-col-12 vis-mb--none">
              <HomeTabsAndActions
                ref="homeTabs"
                :iconName="iconName"
                :selectedProjectsLength="selectedProjects.length"
                :currentFolderId="currentFolderId"
                :folderNameList="getStoreFolderNameList"
                :forDataFlowDoesUserHaveEditRoles="
                  forDataFlowDoesUserHaveEditRoles
                "
                :homePageSearchQueryData="getStoreSearchQueryData"
                :isInSearch="isInSearch"
                :defaultSearchValue="defaultSearchValue"
                :showCardOrTable="showCardOrTable"
                :isCreateFolderAuthorized="isCreateFolderAuthorized"
                :lastSelectedTab="getStoreLastSelectedTab"
                :entityFilterList="entityFilterList"
                @resetHomepagePagination="resetPagination"
                @filterByEntity="filterByEntity"
                @resetHomepageSearch="resetHomepageSearch"
                @resetHomepageSearchParameters="resetHomepageSearchParameters"
                @handleSearch="handleSearch"
                @handleSearchQueryData="handleSearchQueryData"
                @resetAllFilters="resetAllFilters"
                @viewFolder="viewFolder"
                :isActiveBulkDelete="
                  Boolean(selectedProjects.length) && !dialogVisibleBulkDelete
                "
                :paginationResult="paginationResult"
                @tabChanged="mapServerDataToProjects"
                @openNewFolderPopup="openNewFolderPopup"
                @toggleView="toggleView"
                @changeResize="changeResize"
                @closeHomePageSearch="closeHomePageSearch"
                @searchProjectList="handleHomePageSearch"
                @deleteSelectedProjects="dialogVisibleBulkDelete = true"
              />
            </div>
          </div>
        </div>
      </section>
      <div
        v-loading="isHomepageListLoading"
        class="vis-scroll-thin vis-min-h-60"
        :class="{ 'vis-container': showCardOrTable }"
        @scroll.stop="handleScroll"
      >
        <div class="vis-row">
          <div
            class="vis-col vis-col-12"
            :class="[
              { 'vis-pa--none': showCardOrTable },
              { 'vis-pl--none vis-pr--none': !showCardOrTable },
            ]"
          >
            <HomeProjectCards
              v-if="showCardOrTable"
              :mappedProjects="mappedProjectsFilteredByProjectName"
              :selectedProjects="selectedProjects"
              @view="viewProject"
              @viewWithCtrl="viewProjectWithCtrl"
              @edit="editProject"
              @delete="deleteProjectConfirm"
              @share="clickShare"
              @move="clickMove"
              @properties="clickProperties"
              @duplicate="clickDuplicate"
              @addSelectedProjects="addSelectedProjects"
              @setSelectedProjects="selectedProjects = $event"
              @searchProjectList="handleHomePageSearch"
              @resetHomepageSearch="resetHomepageSearch"
              @updateFavorite="updateFavorite"
              @handleNewDashboard="handleNewDashboard"
            />
            <HomeProjectTable
              v-else
              :mappedProjects="mappedProjectsFilteredByProjectName"
              :isInSearch="isInSearch"
              @view="viewProject"
              @edit="editProject"
              @properties="clickProperties"
              @duplicate="clickDuplicate"
              @share="clickShare"
              @move="clickMove"
              @setMappedProjectFavoriteById="setMappedProjectFavoriteById"
              @delete="deleteProjectConfirm"
              @handleScroll="handleScroll"
              @updateFavorite="updateFavorite"
              @newdashboard="handleNewDashboard"
            />
            <FolderSavePopup
              v-if="isActiveFolderPopup"
              :tableRows="folders"
              :folderId="folderId"
              :folderNameList="popupFolderNameList"
              @setFolderName="setFolderName"
              @getFoldersById="getFoldersById"
              @setFolderId="setFolderId"
              @removeTableClass="removeTableClass"
              @closeFolderSave="closeFolderSave"
            />
            <SelectFolderPopup
              v-if="isMoveClicked"
              :tableRows="folders"
              :folderId="folderId"
              :folderNameList="popupFolderNameList"
              @selectFolder="selectFolder"
              @getFoldersById="getFoldersById"
              @setFolderId="setFolderId"
              @removeTableClass="removeTableClass"
              @closeFolderSelect="closeFolderSelect"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        showAuthorizationPropertiesGeneral ||
        showAuthorizationPropertiesAccessPermissions
      "
      class="vis-position-fixed vis-dashboard-custom-col authorizationPropertiesContainer w350 vis-tools-box vis-ma--none vis-mobile-hidden vis-scroll-thin vis-height-100 vis-overflow-auto vis-z-index-11"
    >
      <AuthorizationProperties
        :showAuthorizationPropertiesGeneral="showAuthorizationPropertiesGeneral"
        :showAuthorizationPropertiesAccessPermissions="
          showAuthorizationPropertiesAccessPermissions
        "
        :isDashboardProperties="isDashboardProperties"
        :authorizationPropertiesItem="authorizationPropertiesItem"
        :currentUserUsername="currentUserUsername"
        :isAuthorizationLoading="isAuthorizationListLoading"
        @resetHomepageSearch="resetHomepageSearch"
        @searchProjectList="handleHomePageSearch"
        @close="closeAuthorizationProperties"
        @refresh="init"
      />
    </div>
    <Overlay
      :isShow="showAuthorizationPropertiesGeneral || showAuthorizationPropertiesAccessPermissions"
      @closePopup="closeAuthorizationProperties"
    />
    <ConfirmDialog
      :title="deleteDialogTitle()"
      :message="deleteDialogMessage()"
      :dialogVisible="dialogVisible"
      @confirm="deleteProject"
      @cancel="dialogVisible = $event"
    />
    <ConfirmDialog
      :title="$t('dialog.deleteBulkProjectTitle')"
      :message="$t('dialog.deleteBulkProjectMessage')"
      :dialogVisible="dialogVisibleBulkDelete"
      @confirm="deleteSelectedProjects"
      @cancel="dialogVisibleBulkDelete = $event"
    />
  </div>
</template>

<script>
import HomeCreateCard from "../components/home/HomeCreateCard.vue";
import HomeProjectCards from "../components/home/HomeProjectCards.vue";
import HomeProjectTable from "../components/home/HomeProjectTable.vue";
import FolderSavePopup from "../components/home/FolderSavePopup.vue";
import SelectFolderPopup from "../components/home/SelectFolderPopup.vue";
import WelcomeBox from "../components/home/WelcomeBox.vue";
import HomeTabsAndActions from "../components/home/HomeTabsAndActions.vue";
import {
  ACTION as ACTION_DASHBOARD,
  MUTATION as MUTATION_DASHBOARD,
} from "../store/modules/Visualize/Dashboard/types";
import { ACTION as ACTION_FOLDER } from "../store/modules/Visualize/Folder/types";
import { ACTION as ACTION_DATAMODEL } from "../store/modules/Visualize/DataModel/types";
import { ACTION as ACTION_DATAFLOW } from "../store/modules/Ingest/DataFlow/types";
import {
  ACTION as ACTION_HOME,
  GETTER as GETTER_HOME,
  MUTATION as MUTATION_HOME,
} from "../store/modules/Visualize/Home/types";
import { GETTER as GETTER_GENERAL } from "../store/modules/Visualize/General/types";
import { ACTION as ACTION_BULK_DELETE } from "../store/modules/Visualize/BulkDelete/types";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  HOMEPAGE_SEARH_PARAMS,
  HOMEPAGE_SEARH_QUERY_DATA_LIST,
  HOME_TABS_KEY,
  HOME_TABS_SORT_VALUES,
  SORT_BY,
} from "../commons/homeDataTypes";
import { MainCardAction } from "../util/homePageMappers";
import { CardType, ObjectType } from "../util/homePageMappers";
import { routerEnums } from "../commons/Enum";
import { LoadingComponent } from "../store/modules/Visualize/General/loadingComponentDefinitions";
import ConfirmDialog from "../components/helper/ConfirmDialog.vue";
import { CustomIcon } from "../assets/js/custom-icons";
import AuthorizationProperties from "../components/home/AuthorizationProperties.vue";
import { COMPONENT_PRIVILEGES, ROLES } from "../util/authorization";
import componentAvailabilityCheck from "../mixins/componentAvailabilityCheck";
import { parseQueryStringTopbar } from "../util/embeded/queryString";
import cloneDeep from "clone-deep";
import deepEqual from "deep-equal";
import { dashboardData } from "../commons/dashboardDefaultData";
import Overlay from "../components/helper/Overlay.vue"
import DOMPurify from "dompurify";
import { convertListToAccordingToType } from "../commons/helper";

export default {
  components: {
    HomeCreateCard,
    HomeProjectCards,
    HomeProjectTable,
    FolderSavePopup,
    WelcomeBox,
    HomeTabsAndActions,
    ConfirmDialog,
    AuthorizationProperties,
    SelectFolderPopup,
    Overlay,
  },
  mixins: [componentAvailabilityCheck],
  data() {
    return {
      showCardOrTable: true,
      bigSize: false,
      iconName: {
        changeViewIcon: CustomIcon.ListView,
        resizeIcon: CustomIcon.OpenInFull,
        closeIcon: CustomIcon.Close,
      },
      imageName: "home-header-bg-image",
      mappedProjects: [],
      projectName: "",
      dialogVisible: false,
      deleteProjectParams: {
        type: "",
        id: "",
        name: ""
      },
      showAuthorizationPropertiesGeneral: false,
      showAuthorizationPropertiesAccessPermissions: false,
      authorizationPropertiesItem: null,
      selectedProjects: [],
      isActiveFolderPopup: false,
      folderId: null,
      isMoveClicked: false,
      isInPopup: false,
      move: {
        type: "",
        id: "",
      },
      popupFolderNameList: [],
      dialogVisibleBulkDelete: false,
      currentFolderId: null,
      isDashboardProperties: false,
      scrollPosition: null,
      homepageQueryParam: "",
      isInSearch: false,
      defaultSearchValue: "",
      popupSearchQueryData: {},
      folders: [],
      ObjectType: ObjectType,
      HOMEPAGE_SEARH_PARAMS: HOMEPAGE_SEARH_PARAMS,
      paginationResult: {},
      entityFilterList: [],
      customerEnv: process.env.VUE_APP_CUSTOMER_NAME
    };
  },
  watch: {
    isHomepageListLoading: {
      handler(val) {
        if (val) return;
        this.changeCardColMaxWidthByResponsive();
      },
    },
  },
  destroyed() {
    window.removeEventListener(
      "resize",
      this.changeCardColMaxWidthByResponsive
    );
    this.resetHomepageSearch();
  },
  created() {
    window.addEventListener("resize", this.changeCardColMaxWidthByResponsive);
  },
  mounted() {
    this.resetHomepageSearch();
    this.init();
    this.isActiveFolderPopup;
  },
  computed: {
    ...mapGetters({
      loading: GETTER_GENERAL.GET_LOADING,
      homepageSearch: GETTER_HOME.GET_SEARCH_HOMEPAGE_RESULTS,
      homepagePagination: GETTER_HOME.GET_HOMEPAGE_PAGINATION,
      getStoreFolderNameList: GETTER_HOME.GET_FOLDER_NAME_LIST,
      getStoreSearchQueryData: GETTER_HOME.GET_SEARCH_QUERY_DATA,
      getStoreLastSelectedTab: GETTER_HOME.GET_LAST_SELECTED_TAB,
      currentUserRoles: "Security/Get/Keycloak/Roles",
    }),
    customerConfig() {
      return window.customerConfig?.[this.customerEnv];
    },
    customerWelcomeMessage() {
      const hasWelcomeMessage = this.customerConfig?.HOME_BANNER?.WELCOME_MESSAGE;
      
      if (!hasWelcomeMessage) return "";

      return DOMPurify.sanitize(hasWelcomeMessage);
    },
    getCustomStyleHeaderByCustomer() {
      const hasBannerBackground = this.customerConfig?.HOME_BANNER?.BACKGROUND;

      if (!hasBannerBackground) return {};

      return {
        background: hasBannerBackground
      }
    },
    getCustomStyleHeaderImageByCustomer() {
      const hasBannerImageCss = this.customerConfig?.HOME_BANNER?.IMAGE_CSS;

      if (!hasBannerImageCss) return {};

      return hasBannerImageCss;
    },
    isBannerImageShow() {
      const bannerShowImage = this.customerConfig?.HOME_BANNER?.SHOW_IMAGE ?? true;
      
      return bannerShowImage;
    },
    forDataFlowDoesUserHaveEditRoles() {
      return this.currentUserRoles?.some((r) =>
        [ROLES.ADMIN.key, ROLES.DATAFLOW_EDITOR.key].includes(r)
      );
    },
    isDatamodelCardCreateNewDashboardVisible() {
        return this.isComponentAvailable(
            COMPONENT_PRIVILEGES.HOME_PAGE_DATAMODEL_CARD_CREATE_NEW_DASHBOARD
        );
    },
    isHomeCardEditVisible() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_CARD_EDIT
      );
    },
    isCreateFolderAuthorized() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_FOLDER_CREATE_VISIBLE
      );
    },
    isMoveDashboardToFolderAuthorized() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_MOVE_DASHBOARD_TO_FOLDER
      );
    },
    isMoveDatamodelToFolderAuthorized() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_MOVE_DATAMODEL_TO_FOLDER
      );
    },
    isHomepageListLoading() {
      return this.loading[LoadingComponent.HomepageList];
    },
    isAuthorizationListLoading() {
      return this.loading[LoadingComponent.AuthorizationList];
    },

    mappedProjectsFilteredByProjectName() {
      if (this.projectName) {
        return this.mappedProjects.filter((item) => {
          return this.projectName
            ?.toLowerCase()
            ?.split(" ")
            ?.every((x) => item.name.toLowerCase().includes(x));
        });
      } else {
        return this.mappedProjects;
      }
    },
    currentUserUsername() {
      return this.$store.getters["Security/Get/Keycloak/Username"];
    },
  },
  methods: {
    ...mapActions({
      deleteDatamodel: ACTION_DATAMODEL.DELETE_DATAMODEL,
      moveDatamodel: ACTION_DATAMODEL.MOVE_DATAMODEL,
      moveDataFlow: ACTION_DATAFLOW.MOVE_DATAFLOW,
      deleteDashboard: ACTION_DASHBOARD.DELETE_DASHBOARD,
      moveDashboard: ACTION_DASHBOARD.MOVE_DASHBOARD,
      copyDashboard: ACTION_DASHBOARD.COPY_DASHBOARD,
      copyDatamodel: ACTION_DATAMODEL.COPY_DATAMODEL,
      addFolder: ACTION_FOLDER.CREATE_FOLDER,
      moveFolder: ACTION_FOLDER.MOVE_FOLDER,
      deleteFolder: ACTION_FOLDER.DELETE_FOLDER,
      bulkDeleteProjects: ACTION_BULK_DELETE.BULK_DELETE_PROJECTS,
      deleteDataFlowById: ACTION_DATAFLOW.DELETE_DATAFLOW_BY_ID,
      fetchHomePageSearch: ACTION_HOME.FETCH_HOMEPAGE_SEARCH,
    }),
    ...mapMutations({
      resetDashboardState: MUTATION_DASHBOARD.RESET_DASHBOARD,
      resetHomepageSearch: MUTATION_HOME.RESET_SEARCH_HOMEPAGE_RESULTS,
      setStoreFolderNameList: MUTATION_HOME.SET_FOLDER_NAME_LIST,
      setStoreSearchQueryData: MUTATION_HOME.SET_SEARCH_QUERY_DATA,
      setStoreLastSelectedTab: MUTATION_HOME.SET_LAST_SELECTED_TAB,
      setDashboard: MUTATION_DASHBOARD.SET_DASHBOARD
    }),
    handleNewDashboard({ id }) {
      //id = datamodelId
      this.resetDashboardState();

      const clonedDashboardData = cloneDeep(dashboardData);

      clonedDashboardData.datamodel_id = id;
      this.setDashboard(clonedDashboardData);

      this.$router.push({ name: routerEnums.DASHBOARD, params: { dashboardFromHomepageWithDatamodelId: true } });
    },
    filterByEntity(type) {
      if(this.entityFilterList.includes(type)) {
        this.entityFilterList = this.entityFilterList.filter((x) => x !== type)
      } else this.entityFilterList.push(type)

      this.resetHomepageSearch();
      this.resetPagination();
      this.handleSearchQueryData({val: this.entityFilterList,type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE});
      this.handleHomePageSearch();
    },
    updateFavorite(params) {
      this.mappedProjects?.find((x) => {
        if (x?.item?.id === params.item.id) {
          const starAction = x.mainActions.find(
            (x) => x.actionKey === MainCardAction.STAR
          );

          this.$set(x.item, "favorite", !params.item.favorite);
          this.$set(
            starAction,
            "iconClassName",
            `vis-font-size-1-25 vis-cursor-pointer ${
              params.item.favorite
                ? `vis-color-orange ${CustomIcon.StarInFull}`
                : CustomIcon.StarOutlined
            }`
          );
        }
      });

      if (this.getStoreLastSelectedTab === HOME_TABS_KEY.FAVORITES) {
            this.resetQueryDataExceptSearchKey();
            this.handleSearchQueryData({
              val: true,
              type: HOMEPAGE_SEARH_PARAMS.FAVORITE,
            });
            this.handleHomePageSearch();
          }
    },
    changeCardColMaxWidthByResponsive() {
      let colList = document.querySelectorAll(
        ".vis-user-tabs-content .vis-row .vis-col"
      );
      let firstCardWidth = null;

      colList.forEach((col) => {
        if (!firstCardWidth) {
          firstCardWidth = cloneDeep(col.clientWidth);
        }
        if (firstCardWidth && col.clientWidth !== firstCardWidth) {
          col.style.maxWidth = firstCardWidth + "px";
        }
      });
    },
    resetPagination() {
      this.handleSearchQueryData({val: "", type: HOMEPAGE_SEARH_PARAMS.PAGE})
    },
    resetSearchParametersExceptType() {
      const clonedSearchQueryData = cloneDeep(this.getStoreSearchQueryData);

      clonedSearchQueryData?.forEach((x) => {
        if (x.key === HOMEPAGE_SEARH_PARAMS.FOLDER_ID) {
          x.value = "root";
        } else if (x.key !== HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE) {
          x.value = "";
        }
      });

      this.setStoreSearchQueryData(clonedSearchQueryData);
      this.resetHomepageSearch();
    },
    resetHomepageSearchParameters() {
      const clonedSearchQueryData = cloneDeep(this.getStoreSearchQueryData);

      clonedSearchQueryData?.forEach((x) => {
        if (x.key === HOMEPAGE_SEARH_PARAMS.FOLDER_ID) {
          x.value = "root";
        } 
        else if (x.key === HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE) {
          x.value = [];
        } else x.value = "";
      });

      this.setStoreSearchQueryData(clonedSearchQueryData);
      this.entityFilterList = []
      this.resetHomepageSearch();
    },
    resetQueryDataExceptSearchKey() {
      const clonedSearchQueryData = cloneDeep(this.getStoreSearchQueryData);

      this.currentFolderId = null;

      clonedSearchQueryData?.forEach((x) => {
        if (x.key === HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE) {
            x.value = [];
        } else if (this.isInSearch && x.key !== HOMEPAGE_SEARH_PARAMS.SEARCH_KEY) {
           x.value = "";
        } else if (
          x.key !== HOMEPAGE_SEARH_PARAMS.SEARCH_KEY &&
          x.key !== HOMEPAGE_SEARH_PARAMS.FOLDER_ID
        ) {
          x.value = "";
        }
      });

      this.setStoreSearchQueryData(clonedSearchQueryData);
      this.resetHomepageSearch();
    },
    getList(query) {
      return () => {
        this.resetQueryDataExceptSearchKey();
        this.handleSearchQueryData(query);
        this.handleHomePageSearch();
      };
    },
    async handleScroll(e) {
      let currentScrollPosition = Math.ceil(e.srcElement.scrollTop);
      this.scrollPosition =
        e.srcElement.scrollHeight - e.srcElement.offsetHeight;
      if (
        currentScrollPosition === this.scrollPosition &&
        this.homepagePagination.number + 1 <
          this.homepagePagination.totalPages &&
        currentScrollPosition !== 0
      ) {
        this.handleSearchQueryData({
          val: this.homepagePagination.number + 1,
          type: HOMEPAGE_SEARH_PARAMS.PAGE
        });
        await this.handleHomePageSearch();
        this.changeCardColMaxWidthByResponsive();
      }
      this.scrollPosition = currentScrollPosition;
    },
    handleSearch() {
      this.isInSearch = true;
      this.currentFolderId = null;
      this.folderId = null;
      this.setStoreFolderNameList([]);
    },
    handleSearchQueryData(data, queryData) {
      if (!deepEqual(queryData, this.popupSearchQueryData)) {
        const clonedSearchQueryData = cloneDeep(this.getStoreSearchQueryData);
        
        clonedSearchQueryData.find((x) => x?.key === data.type).value =
          data.val;
       
        this.setStoreSearchQueryData(clonedSearchQueryData);
      } else {
        this.popupSearchQueryData.find((x) => x?.key === data.type).value =
          data.val;
      }
    },
    setFolderId(id) {
      if (id !== this.folderId) {
        if (!this.isInPopup) {
          this.currentFolderId = this.folderId;
        }
        this.folderId = id;
      }
    },
    setMappedProjectFavoriteById(id) {
      let selectedProject = this.mappedProjects.find((x) => x.id === id);
      selectedProject.item.favorite = !selectedProject.item.favorite;
    },
    setPaginationResults() {
      if (this.homepagePagination.number !== 0) {
        this.paginationResult.number = this.homepagePagination.number;
        this.paginationResult.numberOfElements +=
          this.homepagePagination.numberOfElements;
        this.paginationResult.totalPages = this.homepagePagination.totalPages;
      } else {
        this.paginationResult = this.homepagePagination;
      }
    },
    async handleHomePageSearch(queryData) {
      if (!deepEqual(queryData, this.popupSearchQueryData)) {
        queryData = cloneDeep(this.getStoreSearchQueryData);
      }

      this.homepageQueryParam = "";
      const params = new URLSearchParams;

      queryData?.forEach((x) => {
        if (x.value) {
          if(x.key === HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE && x.value.length) {
            params.append(x.key, x.value.join(','));
          } else if (x.key !== HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE) {
            params.append(x.key, x.value);
          }
        }
      });

      this.homepageQueryParam = params.toString();

      await this.fetchHomePageSearch({
        loadingComponent: LoadingComponent.HomepageList,
        queryParam: this.homepageQueryParam,
      });

      let payload = {
        editHidden: !this.isHomeCardEditVisible,
        authorizationToMove: this.isMoveDatamodelToFolderAuthorized,
      };

      if (this.isInPopup) {
        this.folders = convertListToAccordingToType(this.homepageSearch, payload);
      } else {
        this.setPaginationResults();
        this.mappedProjects = convertListToAccordingToType(
            this.homepageSearch, 
            payload, 
            { isDatamodelCardCreateNewDashboardVisible: this.isDatamodelCardCreateNewDashboardVisible }
        );
      }

      this.updateAuthorizationPropertiesItem();
    },
    updateAuthorizationPropertiesItem() {
      let payload = {
        editHidden: !this.isHomeCardEditVisible,
        authorizationToMove: this.isMoveDatamodelToFolderAuthorized,
      };
      const list = convertListToAccordingToType(this.homepageSearch, payload);
      const foundItem = list?.find(
        (item) => item.id === this.authorizationPropertiesItem?.item?.id
      );

      if (foundItem) {
        this.authorizationPropertiesItem.item = { ...foundItem.item };
      }
    },
    async init() {
      // if the user in different page and come back to home page
      // we need to reload the last state of the home page
      const reloadHomePageLastState = async () => {
        const searchKey = this.getStoreSearchQueryData?.find(
          (x) => x.key === HOMEPAGE_SEARH_PARAMS.SEARCH_KEY
        );

        if (searchKey?.value?.length) {
          this.isInSearch = true;
          this.defaultSearchValue = searchKey.value;
        }

        if (this.getStoreFolderNameList.length) {
          this.currentFolderId =
            this.getStoreFolderNameList[
              this.getStoreFolderNameList.length - 1
            ].id;
        }

        await this.handleHomePageSearch(this.getStoreSearchQueryData);

        this.entityFilterList = this.getStoreSearchQueryData.find(
          (x) => x.key === HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE).value
      };

      this.handleSearchQueryData({ val: "", type: HOMEPAGE_SEARH_PARAMS.PAGE });
      reloadHomePageLastState();

      this.parseQueryParamOnHomePage();
      this.resetDashboardState();
      this.resetAuthorizationProperties();
    },
    removeTableClass() {
      var row = document.querySelectorAll(".clickable td");
      for (let index = 0; index < row.length; index++) {
        const element = row[index];
        element.classList.remove("vis-selectedRow");
      }
    },
    closeFolderSave() {
      this.isActiveFolderPopup = !this.isActiveFolderPopup;
      this.isInPopup = false;

      if (this.isInSearch) this.isInSearch = false;
      
      this.popupFolderNameList = [];
    },
    async closeFolderSelect() {
      this.isMoveClicked = !this.isMoveClicked;
      this.isInPopup = false;
      this.popupFolderNameList = [];
    },
    resetAllFilters() {
      this.folderId = null;
      this.currentFolderId = null;
      this.entityFilterList = [];
      this.setStoreFolderNameList([]);
      this.popupFolderNameList = [];
      this.resetHomepageSearchParameters();
    },
    checkBreadcrumb(id, name) {
      if (!id && !name) {
        if (this.isInPopup) {
          this.popupFolderNameList = [];
        } else {
          this.setStoreFolderNameList([]);
        }
      }

      if (id && name) {
        if (this.isInPopup) {
          const selectedBreadcrumb = this.popupFolderNameList.find(
            (x) => x.id == id
          );
          if (!selectedBreadcrumb) {
            this.popupFolderNameList.push({ id, name });
          } else {
            const selectedFolderIndex = this.popupFolderNameList.findIndex(
              (y) => y.id == selectedBreadcrumb.id
            );
            this.popupFolderNameList = this.popupFolderNameList.filter(
              (f, index) => index <= selectedFolderIndex
            );
          }
        } else {
          const selectedBreadcrumb = this.getStoreFolderNameList.find(
            (x) => x.id == id
          );

          if (!selectedBreadcrumb) {
            this.setStoreFolderNameList([
              ...this.getStoreFolderNameList,
              { id, name },
            ]);
          } else {
            const selectedFolderIndex = this.getStoreFolderNameList.findIndex(
              (y) => y.id == selectedBreadcrumb.id
            );

            this.setStoreFolderNameList(
              this.getStoreFolderNameList.filter(
                (f, index) => index <= selectedFolderIndex
              )
            );
          }
        }
      }
    },
    async getFoldersById(id, name) {
      if ((!this.isInPopup || id) && !this.isInSearch && !this.folderId) {
        this.currentFolderId = id;
      }
      this.folderId = id;
      this.checkBreadcrumb(id, name);
      if (id || this.isInSearch) {
        if (this.isInPopup) {
          this.handleSearchQueryData(
            { val: id, type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID },
            this.popupSearchQueryData
          );
          this.handleSearchQueryData(
            { val: [ObjectType.FOLDER], type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE },
            this.popupSearchQueryData
          );
        }
        this.handleSearchQueryData({
          val: id,
          type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID,
        });
      }

      this.resetHomepageSearch();
      if (this.isInPopup) {
        if (!id) {
          this.handleSearchQueryData(
            { val: "root", type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID },
            this.popupSearchQueryData
          );
        }
        await this.handleHomePageSearch(this.popupSearchQueryData);
      } else {
        await this.handleHomePageSearch();
      }
    },
    selectFolder() {
      let itemId = this.move.id;
      this.handleMoveTypes(this.move.type, itemId, this.folderId);
      this.isInPopup = false;
      this.isMoveClicked = !this.isMoveClicked;
      this.popupFolderNameList = [];
    },

    async setFolderName(id, val) {
      if (!this.isInPopup) {
        this.currentFolderId = this.folderId;
      }

      this.isInPopup = false;
      this.popupFolderNameList = [];
      this.isActiveFolderPopup = !this.isActiveFolderPopup;
      await this.saveFolder(id, { name: val }, this.currentFolderId);
    },
    async saveFolder(parentFolderId, bodyParam) {
      await this.addFolder({
        loadingComponent: LoadingComponent.HomePage,
        parentFolderId,
        bodyParam,
      });

      this.resetHomepageSearchParameters();

      if (this.currentFolderId) {
        this.handleSearchQueryData({
          val: this.currentFolderId,
          type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID,
        });
      }

      await this.handleHomePageSearch();
    },

    parseQueryParamOnHomePage() {
      if (this.$route.query.embeded == "true") {
        const parsedQueryString = parseQueryStringTopbar(this.$route.query);

        this.$root.$emit("setTopbarQuery", parsedQueryString);
      }
    },
    resetAuthorizationProperties() {
      this.showAuthorizationPropertiesGeneral = false;
      this.showAuthorizationPropertiesAccessPermissions = false;
      this.authorizationPropertiesItem = null;
    },
    closeAuthorizationProperties() {
      this.showAuthorizationPropertiesGeneral = false;
      this.showAuthorizationPropertiesAccessPermissions = false;
      this.isDashboardProperties = false;
    },
    clickShare(item) {
      this.closeAuthorizationProperties();
      setTimeout(() => {
        this.showAuthorizationPropertiesAccessPermissions = true;
        this.authorizationPropertiesItem = item;
      }, 300);
    },
    async clickMove({ item }) {
      this.isInPopup = true;
      this.isMoveClicked = true;
      this.move.type = item.type;
      this.move.id = item.id;
      this.popupSearchQueryData = cloneDeep(this.getStoreSearchQueryData);
      this.resetHomepageSearch();

      if (this.isInSearch) {
        if (item?.folderId) {
          this.checkBreadcrumb(item?.folderId, item?.folderNames[0]);
          this.handleSearchQueryData(
            { val: item.folderId, type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID },
            this.popupSearchQueryData
          );
        } else {
          this.handleSearchQueryData(
            { val: "root", type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID },
            this.popupSearchQueryData
          );
        }
        this.handleSearchQueryData(
          { val: "", type: HOMEPAGE_SEARH_PARAMS.SEARCH_KEY },
          this.popupSearchQueryData
        );
        this.handleSearchQueryData(
          { val: "", type: HOMEPAGE_SEARH_PARAMS.PAGE },
          this.popupSearchQueryData
        );
      }
      if (item.folderId && !this.isInSearch) {
        this.handleSearchQueryData(
          { val: this.folderId, type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID },
          this.popupSearchQueryData
        );
      }

      this.handleSearchQueryData(
        { val: [ObjectType.FOLDER], type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE },
        this.popupSearchQueryData
      );
      this.handleSearchQueryData(
        { val: "", type: HOMEPAGE_SEARH_PARAMS.PAGE },
        this.popupSearchQueryData
      );
      await this.handleHomePageSearch(this.popupSearchQueryData);
      if (!this.isInSearch) {
        this.popupFolderNameList = cloneDeep(this.getStoreFolderNameList);
      }
    },
    async handleMoveTypes(types, itemId, targetFolderId) {
      if (!targetFolderId) {
        this.folderId = null;
      }

      let payload = { itemId, targetFolderId };

      if (types === CardType.FOLDER) {
        await this.moveFolder(payload);
      }
      if (types === CardType.DASHBOARD) {
        await this.moveDashboard(payload);
      }
      if (types === CardType.DATAMODEL) {
        await this.moveDatamodel(payload);
      }
      if (types === CardType.DATAFLOW) {
        await this.moveDataFlow(payload);
      }
      this.resetHomepageSearch();
      if (!this.isInSearch && this.currentFolderId) {
        this.handleSearchQueryData({
          val: this.currentFolderId,
          type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID,
        });
      } else if (!this.isInSearch && !this.currentFolderId) {
        this.handleSearchQueryData({
          val: "root",
          type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID,
        });
      } else if (this.isInSearch) {
        this.handleSearchQueryData({
          val: "",
          type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID,
        });
      }
      await this.handleHomePageSearch();
    },
    clickProperties(item) {
      this.closeAuthorizationProperties();
      this.showAuthorizationPropertiesAccessPermissions = false;
      setTimeout(() => {
        this.showAuthorizationPropertiesGeneral = true;
        this.authorizationPropertiesItem = item;
      }, 300);
      if (item.item.type === CardType.DASHBOARD) {
        this.isDashboardProperties = true;
      }
    },
    async clickDuplicate(item) {
      if (item.item.type === CardType.DASHBOARD) {
        let payload = {
          id: item.item.id,
          name: item.item.name + " Copy",
        };
        await this.copyDashboard({ payload });
      } else if (item.item.type === CardType.DATAMODEL) {
        let payload = {
          id: item.item.id,
        };
        await this.copyDatamodel({ payload });
      }
      this.resetHomepageSearch();
      this.handleSearchQueryData({ val: "", type: HOMEPAGE_SEARH_PARAMS.PAGE });
      await this.handleHomePageSearch();
    },
    sortByProjectList(item) {
      let sortedProjects = this.mappedProjectsFilteredByProjectName;
      sortedProjects.sort((a, b) => {
        let fa = a[item?.value]?.toLowerCase(),
          fb = b[item?.value]?.toLowerCase();
        if (item?.type == SORT_BY.ASC) {
          return fa < fb ? -1 : 1;
        } else {
          return fa < fb ? 1 : -1;
        }
      });
    },
    createClicked(name) {
      if (routerEnums.DASHBOARD == name) {
        this.resetDashboardState();
      }
      this.$router.push({ name });
    },
    viewProject({ type, id, name }) {
      if (type === CardType.FOLDER) {
        if (this.isInSearch && !this.showCardOrTable) {
          this.toggleView();
        }

        this.$refs["homeTabs"]?.resetSearchValue();
        this.isInSearch = false;
        this.viewFolder(id, name);
      }
      if (type === CardType.DASHBOARD) {
        this.$router.push({
          name: routerEnums.DASHBOARD_VIEW,
          params: { id },
        });
      } else if (type === CardType.DATAMODEL) {
        this.$router.push({
          name: routerEnums.DATAMODEL_VIEW,
          params: { id },
        });
      } else if (type === CardType.DATAFLOW) {
        this.$router.push({
          name: routerEnums.INGEST_EDIT,
          params: { id },
        });
      }
    },
    viewProjectWithCtrl({ type, id }) {
      if (type === CardType.FOLDER) return;
      
      let routeData = null;

      if (type === CardType.DASHBOARD) {
        routeData = this.$router.resolve({
          name: routerEnums.DASHBOARD_VIEW,
          params: { id },
        });
      } else if (type === CardType.DATAMODEL) {
        routeData = this.$router.resolve({
          name: routerEnums.DATAMODEL_VIEW,
          params: { id },
        });
      } else if (type === CardType.DATAFLOW) {
        routeData = this.$router.resolve({
          name: routerEnums.INGEST_EDIT,
          params: { id },
        });
      }

      window.open(routeData.href, "_blank");
    },
    viewFolder(id, name) {
      //for breadcrumb
      this.checkBreadcrumb(id, name);
      this.folderId = id;
      this.currentFolderId = id;
      this.resetHomepageSearchParameters();
      this.handleSearchQueryData({
        val: id,
        type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID,
      });
      this.handleHomePageSearch();
    },
    editProject({ type, id }) {
      this.resetHomepageSearchParameters();
      if (type === CardType.DASHBOARD)
        this.$router.push({
          name: routerEnums.DASHBOARD_EDIT,
          params: { id },
        });
      else if (type === CardType.DATAMODEL)
        this.$router.push({
          name: routerEnums.DATAMODEL_VIEW,
          params: { id },
        });
        else if (type === CardType.DATAFLOW)
        this.$router.push({
          name: routerEnums.INGEST_EDIT,
          params: { id },
        });
    },
    deleteProjectConfirm({ type, id, name }) {
      this.dialogVisible = true;
      this.deleteProjectParams.type = type;
      this.deleteProjectParams.id = id;
      this.deleteProjectParams.name = name;
    },
    async deleteProject() {
      let payload = {
        queryParam: this.deleteProjectParams.id,
      };
      if (this.deleteProjectParams.type === CardType.DASHBOARD) {
        await this.deleteDashboard(payload);
      } else if (this.deleteProjectParams.type === CardType.DATAMODEL) {
        await this.deleteDatamodel(payload);
      } else if (this.deleteProjectParams.type === CardType.FOLDER) {
        await this.deleteFolder(payload);
      } else if (this.deleteProjectParams.type === CardType.DATAFLOW) {
        await this.deleteDataFlowById(payload);
      }
      this.dialogVisible = false;
      this.resetHomepageSearch();
      await this.handleHomePageSearch();
    },
    deleteDialogTitle() {
        if (this.deleteProjectParams.type === CardType.DASHBOARD)
        return this.$t("dialog.deleteDashboardTitle", {
          name: this.deleteProjectParams?.name,
        });
      else if (this.deleteProjectParams.type === CardType.DATAMODEL)
        return this.$t("dialog.deleteDatamodelTitle", {
          name: this.deleteProjectParams?.name,
        });
      else if (this.deleteProjectParams.type === CardType.DATAFLOW)
        return this.$t("dialog.deleteDataflowTitle", {
          name: this.deleteProjectParams?.name,
        });
      else
        return this.$t("dialog.deleteFolderTitle", {
          name: this.deleteProjectParams?.name,
        });
     },
    deleteDialogMessage() {
        if (this.deleteProjectParams.type === CardType.DASHBOARD)
        return this.$t("dialog.deleteDashboardMessage", {
          name: this.deleteProjectParams?.name,
        });
      else if (this.deleteProjectParams.type === CardType.DATAMODEL)
        return this.$t("dialog.deleteDatamodelMessage", {
          name: this.deleteProjectParams?.name,
        });
      else if (this.deleteProjectParams.type === CardType.DATAFLOW)
        return this.$t("dialog.deleteDataflowMessage", {
          name: this.deleteProjectParams?.name,
        });
      else
        return this.$t("dialog.deleteFolderMessage", {
          name: this.deleteProjectParams?.name,
        });
    },
    async deleteSelectedProjects() {
      this.dialogVisibleBulkDelete = false;
      await this.bulkDeleteProjects(this.selectedProjects);
      this.selectedProjects = [];
    },
    addSelectedProjects(item) {
      this.selectedProjects.push(item);
    },
    mapServerDataToProjects(value) {
      this.setStoreLastSelectedTab(value);

      const renderTab = {
        [HOME_TABS_KEY.ALL]: this.getList({
          val: [],
          type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE,
        }),
        [HOME_TABS_KEY.RECENTLY_USED]: this.getList({
          val: `${HOME_TABS_SORT_VALUES.UPDATED_DATE},${SORT_BY.DESC}`,
          type: HOMEPAGE_SEARH_PARAMS.SORT_PARAMS,
        }),
        [HOME_TABS_KEY.SHARED_WITH_ME]: this.getList({
          val: true,
          type: HOMEPAGE_SEARH_PARAMS.SHARED_WITH_ME,
        }),
        [HOME_TABS_KEY.MY_CONTENT]: this.getList({
          val: true,
          type: HOMEPAGE_SEARH_PARAMS.MY_CONTENT,
        }),
        [HOME_TABS_KEY.FAVORITES]: this.getList({
          val: true,
          type: HOMEPAGE_SEARH_PARAMS.FAVORITE,
        }),
      };

      this.mappedProjects = renderTab[value]();
    },
    async openNewFolderPopup() {
      this.isActiveFolderPopup = true;
      this.isInPopup = true;
      this.popupSearchQueryData = cloneDeep(this.getStoreSearchQueryData);
      this.resetHomepageSearch();
      this.handleSearchQueryData(
        { val: "", type: HOMEPAGE_SEARH_PARAMS.PAGE },
        this.popupSearchQueryData
      );
      this.handleSearchQueryData(
        { val: "", type: HOMEPAGE_SEARH_PARAMS.SEARCH_KEY },
        this.popupSearchQueryData
      );
      this.handleSearchQueryData(
        { val: [ObjectType.FOLDER], type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE },
        this.popupSearchQueryData
      );
      await this.handleHomePageSearch(this.popupSearchQueryData);
      this.popupFolderNameList = cloneDeep(this.getStoreFolderNameList);
    },
    toggleView() {
      this.showCardOrTable = !this.showCardOrTable;
      if (this.showCardOrTable) {
        this.iconName.changeViewIcon = CustomIcon.ListView;
      } else {
        this.iconName.changeViewIcon = CustomIcon.Grid;
      }
    },
    changeResize() {
      this.bigSize = !this.bigSize;
      if (this.bigSize) {
        this.iconName.resizeIcon = CustomIcon.CloseFullScreen;
      } else {
        this.iconName.resizeIcon = CustomIcon.OpenInFull;
      }
    },
    async closeHomePageSearch() {
      if (!this.showCardOrTable) {
        this.toggleView();
      }

      this.homepageQueryParam = "";
      this.setStoreSearchQueryData(HOMEPAGE_SEARH_QUERY_DATA_LIST);
      this.isInSearch = false;
      this.resetAllFilters();
      this.$refs["homeTabs"]?.resetTabValue();
    },
    getHeaderImage() {
      let headerImageName = "home-header-bg-image.png";
        
      if (this.customerConfig?.HOME_BANNER?.IMAGE) {
        headerImageName = this.customerConfig?.HOME_BANNER?.IMAGE;
      }

      var images = require.context("../assets/images/home/", false, /\.png$/);
      return images("./" + headerImageName);
    },
  },
};
</script>
<style scoped>
.authorizationPropertiesContainer {
  top: 48px;
  right: 0;
}
.vis-min-h-60 {
  min-height: 60vh;
}
</style>
