import {
  allPropertiesKeys,
  COLUMN_CHART_PROPERTIES_ENUM,
  datatablePropertiesEnum,
  LINE_CHART_PROPERTIES_ENUM,
  AREA_CHART_PROPERTIES_ENUM,
  PIE_CHART_PROPERTIES_ENUM,
  SUMMARY_CHART_PROPERTIES_ENUM,
  BAR_CHART_PROPERTIES_ENUM,
  SELECT_LIST_FILTER_PROPERTIES_ENUM,
  TREEMAP_PROPERTIES_ENUM,
  DATE_FILTER_PROPERTIES_ENUM,
  DROPDOWN_FILTER_PROPERTIES_ENUM,
  HEATMAP_PROPERTIES_ENUM,
  SCATTER_CHART_PROPERTIES_ENUM,
  BUBBLE_CHART_PROPERTIES_ENUM,
  COMBINATION_CHART_PROPERTIES_ENUM,
  GAUGE_CHART_PROPERTIES_ENUM,
  PIVOT_TABLE_PROPERTIES_ENUM,
  RADAR_CHART_PROPERTIES_ENUM,
  STACKED_COLUMN_CHART_PROPERTIES_ENUM,
  STACKED_BAR_CHART_PROPERTIES_ENUM,
  SANKEY_CHART_PROPERTIES_ENUM,
  GEO_MAP_CHART_PROPERTIES_ENUM,
  BUTTON_PROPERTIES_ENUM,
  TEXT_FILTER_PROPERTIES_ENUM,
  TEXT_EDITOR_PROPERTIES_ENUM,
  SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM,
} from "../commons/dashboardProperties";
import { detailsKeys } from "../commons/dashboardAndPanel";
import { filterPropKey } from "../commons/filterComponents";
import { datatableDefaultConfigurations } from "../mocks/datatableDefaultProperties";
import { PIE_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/pieChartDefaultProperties";
import { COLUMN_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/columnChartDefaultProperties";
import { BAR_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/barChartDefaultProperties";
import { LINE_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/lineChartDefaultProperties";
import { AREA_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/areaChartDefaultProperties";
import { TREEMAP_DEFAULT_CONFIGURATIONS } from "../mocks/treemapDefaultProperties";
import { SUMMARY_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/summaryChartDefaultProperties";
import { getPanelDataPropertiesStyle } from "../util/panelDataPropertiesStyle";
import { SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS } from "../mocks/selectListFilterDefaultProperties";
import { DATE_FILTER_DEFAULT_CONFIGURATIONS } from "../mocks/dateFilterDefaultProperties";
import { DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS } from "../mocks/dropdownFilterDefaultProperties";
import { HEATMAP_DEFAULT_CONFIGURATIONS } from "../mocks/heatmapDefaultProperties";
import { SCATTER_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/scatterChartDefaultProperties";
import { BUBBLE_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/bubbleChartDefaultProperties";
import { COMBINATION_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/combinationChartDefaultProperties";
import { GAUGE_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/gaugeChartDefaultProperties";
import { PIVOT_TABLE_DEFAULT_CONFIGURATIONS } from "../mocks/pivotTableDefaultProperties";
import { RADAR_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/radarChartDefaultProperties";
import { STACKED_COLUMN_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/stackedColumnChartDefaultProperties";
import { STACKED_BAR_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/stackedBarChartDefaultProperties";
import { SANKEY_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/sankeyChartDefaultProperties";
import { GEO_MAP_CHART_DEFAULT_CONFIGURATIONS } from "../mocks/geoMapDefaultProperties";
import { BUTTON_DEFAULT_CONFIGURATIONS } from "../mocks/buttonDefaultProperties";
import { TEXT_FILTER_DEFAULT_CONFIGURATIONS } from "../mocks/textFilterDefaultProperties";
import { TEXT_EDITOR_DEFAULT_CONFIGURATIONS } from "../mocks/textEditorDefaultProperties";
import { checkTypeSelectorDataItem } from "../commons/dataModelTypes";
import { SELECTOR_DATA_ITEM_DEFAULT_CONFIGURATIONS } from "../mocks/selectorDataitemChartDefaultProperties";

export default {
  computed: {
    chartsEnumAndDefaults() {
      return {
        line: {
          enums: LINE_CHART_PROPERTIES_ENUM,
          defaults: LINE_CHART_DEFAULT_CONFIGURATIONS,
        },
        summary: {
          enums: SUMMARY_CHART_PROPERTIES_ENUM,
          defaults: SUMMARY_CHART_DEFAULT_CONFIGURATIONS,
        },
        column: {
          enums: COLUMN_CHART_PROPERTIES_ENUM,
          defaults: COLUMN_CHART_DEFAULT_CONFIGURATIONS,
        },
        bar: {
          enums: BAR_CHART_PROPERTIES_ENUM,
          defaults: BAR_CHART_DEFAULT_CONFIGURATIONS,
        },
        pie: {
          enums: PIE_CHART_PROPERTIES_ENUM,
          defaults: PIE_CHART_DEFAULT_CONFIGURATIONS,
        },
        area: {
          enums: AREA_CHART_PROPERTIES_ENUM,
          defaults: AREA_CHART_DEFAULT_CONFIGURATIONS,
        },
        treemap: {
          enums: TREEMAP_PROPERTIES_ENUM,
          defaults: TREEMAP_DEFAULT_CONFIGURATIONS,
        },
        heatmap: {
          enums: HEATMAP_PROPERTIES_ENUM,
          defaults: HEATMAP_DEFAULT_CONFIGURATIONS,
        },
        scatter: {
          enums: SCATTER_CHART_PROPERTIES_ENUM,
          defaults: SCATTER_CHART_DEFAULT_CONFIGURATIONS,
        },
        bubble: {
          enums: BUBBLE_CHART_PROPERTIES_ENUM,
          defaults: BUBBLE_CHART_DEFAULT_CONFIGURATIONS,
        },
        combination: {
          enums: COMBINATION_CHART_PROPERTIES_ENUM,
          defaults: COMBINATION_CHART_DEFAULT_CONFIGURATIONS,
        },
        gauge: {
          enums: GAUGE_CHART_PROPERTIES_ENUM,
          defaults: GAUGE_CHART_DEFAULT_CONFIGURATIONS,
        },
        pivotTable: {
          enums: PIVOT_TABLE_PROPERTIES_ENUM,
          defaults: PIVOT_TABLE_DEFAULT_CONFIGURATIONS,
        },
        radar: {
          enums: RADAR_CHART_PROPERTIES_ENUM,
          defaults: RADAR_CHART_DEFAULT_CONFIGURATIONS,
        },
        stackedColumn: {
          enums: STACKED_COLUMN_CHART_PROPERTIES_ENUM,
          defaults: STACKED_COLUMN_CHART_DEFAULT_CONFIGURATIONS,
        },
        stackedBar: {
          enums: STACKED_BAR_CHART_PROPERTIES_ENUM,
          defaults: STACKED_BAR_CHART_DEFAULT_CONFIGURATIONS,
        },
        sankey: {
          enums: SANKEY_CHART_PROPERTIES_ENUM,
          defaults: SANKEY_CHART_DEFAULT_CONFIGURATIONS,
        },
        geoMap: {
          enums: GEO_MAP_CHART_PROPERTIES_ENUM,
          defaults: GEO_MAP_CHART_DEFAULT_CONFIGURATIONS,
        },
        textFilter: {
          enums: TEXT_FILTER_PROPERTIES_ENUM,
          defaults: TEXT_FILTER_DEFAULT_CONFIGURATIONS,
        },
        dateFilter: {
          enums: DATE_FILTER_PROPERTIES_ENUM,
          defaults: DATE_FILTER_DEFAULT_CONFIGURATIONS,
        },
        dropdownFilter: {
          enums: DROPDOWN_FILTER_PROPERTIES_ENUM,
          defaults: DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS,
        },
        selectListFilter: {
          enums: SELECT_LIST_FILTER_PROPERTIES_ENUM,
          defaults: SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS,
        },
        selectorDataItem: {
          enums: SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM,
          defaults: SELECTOR_DATA_ITEM_DEFAULT_CONFIGURATIONS,
        },
        //Other collapse altındaki buton
        buttonChart: {
          enums: BUTTON_PROPERTIES_ENUM,
          defaults: BUTTON_DEFAULT_CONFIGURATIONS,
        },
        textEditor: {
          enums: TEXT_EDITOR_PROPERTIES_ENUM,
          defaults: TEXT_EDITOR_DEFAULT_CONFIGURATIONS,
        }
      };
    },

    CHART_COMPUTED_VALUES() {
      const chartComputedValues = {};

      Object.keys(this.chartsEnumAndDefaults).forEach((chartName) => {
        const chartConfig = this.chartsEnumAndDefaults[chartName];

        const keys = Object.keys(chartConfig.enums);

        keys.forEach((k) => {
          chartComputedValues[k] =
            this.getPanelDataPropertiesStyleWithUndefinedCheck(
              chartConfig.enums[k],
              chartConfig.defaults[k]
            );
        });
      });

      return chartComputedValues;
    },

    //datatable
    datatableAggregateResult() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT,
        datatableDefaultConfigurations.datatableAggregateResult
      );
    },
    datatablePageSize() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_SELECT_PAGE_SIZE,
        datatableDefaultConfigurations.datatablePageSize
      );
    },
    datatableRowHeight() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ROW_HEIGHT,
        datatableDefaultConfigurations.datatableRowHeight
      );
    },
    datatableColumnAlign() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_SELECT_COLUMN_ALIGN,
        datatableDefaultConfigurations.datatableColumnAlign
      );
    },
    datatableEnableSorting() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SORTING,
        datatableDefaultConfigurations.datatableEnableSorting
      );
    },
    datatableEnableFiltering() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_FILTERING,
        datatableDefaultConfigurations.datatableEnableFiltering
      );
    },
    datatableEnableExportMenu() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_EXPORT_MENU,
        datatableDefaultConfigurations.datatableEnableExportMenu
      );
    },
    datatableEnableExcelExport() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_EXCEL_EXPORT,
        datatableDefaultConfigurations.datatableEnableExcelExport
      );
    },
    datatableEnableCSVExport() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_CSV_EXPORT,
        datatableDefaultConfigurations.datatableEnableCSVExport
      );
    },
    datatableEnablePDFExport() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_PDF_EXPORT,
        datatableDefaultConfigurations.datatableEnablePDFExport
      );
    },
    datatableEnablePaging() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_PAGING,
        datatableDefaultConfigurations.datatableEnablePaging
      );
    },
    datatableEnableSearch() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SEARCH,
        datatableDefaultConfigurations.datatableEnableSearch
      );
    },
    datatableEnableResizing() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_RESIZING,
        datatableDefaultConfigurations.datatableEnableResizing
      );
    },
    datatableEnableReordering() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_REORDERING,
        datatableDefaultConfigurations.datatableEnableReordering
      );
    },
    datatableEnableSelection() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SELECTION,
        datatableDefaultConfigurations.datatableEnableSelection
      );
    },
    datatableEnableTextWrap() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_TEXT_WRAP,
        datatableDefaultConfigurations.datatableEnableTextWrap
      );
    },
    datatableEnableShowInsight() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SHOW_INSIGHT,
        datatableDefaultConfigurations.datatableEnableShowInsight
      );
    },
    datatableEnableContextMenu() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_CONTEXT_MENU,
        datatableDefaultConfigurations.datatableEnableContextMenu
      );
    },
    // TODO move datatable related computed to chartsEnumAndDefaults config
    datatableBackgroundColor() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_BACKGROUND_COLOR,
        datatableDefaultConfigurations.CHART_DATA_TABLE_BACKGROUND_COLOR
      );
    },
    datatableFontColor() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_FONT_COLOR,
        datatableDefaultConfigurations.CHART_DATA_TABLE_FONT_COLOR
      );
    },
    datatableColumnBackgroundColor() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_BACKGROUND_COLOR,
        datatableDefaultConfigurations.CHART_DATA_TABLE_COLUMN_BACKGROUND_COLOR
      );
    },
    datatableColumnFontColor() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_COLOR,
        datatableDefaultConfigurations.CHART_DATA_TABLE_COLUMN_FONT_COLOR
      );
    },
    datatableColumnFontFamily() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_FAMILY,
        datatableDefaultConfigurations.datatableColumnFontFamily
      );
    },
    datatableColumnFontSize() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_SIZE,
        datatableDefaultConfigurations.datatableColumnFontSize
      );
    },
    datatableColumnFontWeight() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT,
        datatableDefaultConfigurations.datatableColumnFontWeight
      );
    },
    datatableRowFontFamily() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_FAMILY,
        datatableDefaultConfigurations.datatableRowFontFamily
      );
    },
    datatableRowFontSize() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_SIZE,
        datatableDefaultConfigurations.datatableRowFontSize
      );
    },
    datatableRowFontWeight() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_WEIGHT,
        datatableDefaultConfigurations.datatableRowFontWeight
      );
    },
    datatableTotalFontFamily() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_FAMILY,
        datatableDefaultConfigurations.datatableTotalFontFamily
      );
    },
    datatableTotalFontSize() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_SIZE,
        datatableDefaultConfigurations.datatableTotalFontSize
      );
    },
    datatableTotalFontWeight() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_WEIGHT,
        datatableDefaultConfigurations.datatableTotalFontWeight
      );
    },
    datatableTotalFontColor() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_COLOR,
        datatableDefaultConfigurations.datatableTotalFontColor
      );
    },
    datatableTotalBackgroundColor() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_BACKGROUND_COLOR,
        datatableDefaultConfigurations.datatableTotalBackgroundColor
      );
    },
    datatableSubTotalFontFamily() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_FAMILY,
        datatableDefaultConfigurations.datatableSubTotalFontFamily
      );
    },
    datatableSubTotalFontSize() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_SIZE,
        datatableDefaultConfigurations.datatableSubTotalFontSize
      );
    },
    datatableSubTotalFontWeight() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_WEIGHT,
        datatableDefaultConfigurations.datatableSubTotalFontWeight
      );
    },
    datatableSubTotalFontColor() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_COLOR,
        datatableDefaultConfigurations.datatableSubTotalFontColor
      );
    },
    datatableSubTotalBackgroundColor() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_BACKGROUND_COLOR,
        datatableDefaultConfigurations.datatableSubTotalBackgroundColor
      );
    },
    datatableGridLines() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_GRID_LINES,
        datatableDefaultConfigurations.datatableGridLines
      );
    },
    datatableShowTotal() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_TOTAL,
        datatableDefaultConfigurations.datatableShowTotal
      );
    },
    datatableShowSubTotal() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SUBTOTAL,
        datatableDefaultConfigurations.datatableShowSubTotal
      );
    },
    datatableSubTotalPosition() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_POSITION,
        datatableDefaultConfigurations.datatableSubTotalPosition
      );
    },
    datatableSectionBackgroundColor() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_BACKGROUND_COLOR,
        datatableDefaultConfigurations.datatableSectionBackgroundColor
      );
    },
    datatableSectionFontColor() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_COLOR,
        datatableDefaultConfigurations.datatableSectionFontColor
      );
    },
    datatableSectionIndentCell() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_INDENT_CELL,
        datatableDefaultConfigurations.datatableSectionIndentCell
      );
    },
    datatableSectionFontFamily() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_FAMILY,
        datatableDefaultConfigurations.datatableSectionFontFamily
      );
    },
    datatableSectionFontSize() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_SIZE,
        datatableDefaultConfigurations.datatableSectionFontSize
      );
    },
    datatableSectionFontWeight() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_WEIGHT,
        datatableDefaultConfigurations.datatableSectionFontWeight
      );
    },
    datatableMinimumColumnWidth() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_MINIMUM_COLUMN_WIDTH,
        datatableDefaultConfigurations.CHART_DATA_TABLE_MINIMUM_COLUMN_WIDTH
      );
    },
    datatableDrillThroughOnDoubleClick() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK,
        datatableDefaultConfigurations.CHART_DATA_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK
      );
    },
    datatableDrillThroughDashboardList() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_DRILL_THROUGH_DASHBOARD_LIST,
        datatableDefaultConfigurations.CHART_DATA_TABLE_DRILL_THROUGH_DASHBOARD_LIST
      );
    },
    datatablePdfExportHeaderImage() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_PDF_EXPORT_HEADER_IMAGE,
        datatableDefaultConfigurations.CHART_DATA_TABLE_PDF_EXPORT_HEADER_IMAGE
      );
    },
    datatablePdfExportRepeatColumnHeader() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_PDF_EXPORT_COLUMN_REPEAT,
        datatableDefaultConfigurations.CHART_DATA_TABLE_PDF_EXPORT_COLUMN_REPEAT
      );
    },
    datatablePdfExportHeaderText() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_PDF_EXPORT_HEADER,
        datatableDefaultConfigurations.CHART_DATA_TABLE_PDF_EXPORT_HEADER
      );
    },
    datatablePdfExportShowHeader() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_PDF_EXPORT_SHOW_HEADER,
        datatableDefaultConfigurations.CHART_DATA_TABLE_PDF_EXPORT_SHOW_HEADER
      );
    },
    datatablePdfExportShowHeaderImage() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_PDF_EXPORT_SHOW_HEADER_IMAGE,
        datatableDefaultConfigurations.CHART_DATA_TABLE_PDF_EXPORT_SHOW_HEADER_IMAGE
      );
    },
    datatablePdfExportPageSize() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_PDF_EXPORT_PAGE_SIZE,
        datatableDefaultConfigurations.CHART_DATA_TABLE_PDF_EXPORT_PAGE_SIZE
      );
    },
    datatablePdfExportCalculatedPageSizeAutomatically() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_PDF_EXPORT_CALCULATED_PAGE_SIZE,
        datatableDefaultConfigurations.CHART_DATA_TABLE_PDF_EXPORT_CALCULATED_PAGE_SIZE
      );
    },
    datatablePdfExportPageOrientationType() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_PDF_EXPORT_ORIENTATION_TYPE,
        datatableDefaultConfigurations.CHART_DATA_TABLE_PDF_EXPORT_ORIENTATION_TYPE
      );
    },
    datatablePdfExportShowPageNumber() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_PDF_EXPORT_SHOW_PAGE_NUMBER,
        datatableDefaultConfigurations.CHART_DATA_TABLE_PDF_EXPORT_SHOW_PAGE_NUMBER
      );
    },
    datatablePdfExportShowPageNumberPosition() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_PDF_EXPORT_PAGE_NUMBER_POSITION,
        datatableDefaultConfigurations.CHART_DATA_TABLE_PDF_EXPORT_PAGE_NUMBER_POSITION
      );
    },
    datatablePdfExportHeaderTextSize() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_PDF_EXPORT_HEADER_FONT_SIZE,
        datatableDefaultConfigurations.CHART_DATA_TABLE_PDF_EXPORT_HEADER_FONT_SIZE
      );
    },
    datatablePdfExportShowGridBorder() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        datatablePropertiesEnum.CHART_DATA_TABLE_PDF_EXPORT_SHOW_GRID_BORDER,
        datatableDefaultConfigurations.CHART_DATA_TABLE_PDF_EXPORT_SHOW_GRID_BORDER
      );
    },
    
    //SELECT_LIST_FILTER_CHART
    selectListFilterDirection() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_DIRECTION,
        SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_DIRECTION
      );
    },
    selectListFilterMultipleSelection() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_ALLOW_MULTISELECT,
        SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_ALLOW_MULTISELECT
      );
    },
    selectListFilterApplyToTabs() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_APPLY_TO_TABS,
        SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_APPLY_TO_TABS
      );
    },
    selectListFilterDefaultValue() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_DEFAULT_SELECTION,
        SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_DEFAULT_SELECTION
      );
    },
    selectListFilterValueLabelColor() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_COLOR,
        SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_VALUE_FONT_COLOR
      );
    },
    selectListFilterValueLabelBackgroundColor() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_BACKGROUND_COLOR,
        SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_VALUE_LABEL_BACKGROUND_COLOR
      );
    },
    selectListFilterValueLabelFontFamily() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_FAMILY,
        SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_VALUE_LABEL_FONT_FAMILY
      );
    },
    selectListFilterValueLabelFontWeight() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_WEIGHT,
        SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_VALUE_LABEL_FONT_WEIGHT
      );
    },
    selectListFilterValueLabelFontSize() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_SIZE,
        SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_VALUE_LABEL_FONT_SIZE
      );
    },

    //DATE_FILTER_CHART
    dateFilterMultipleSelection() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_ALLOW_MULTISELECT,
        DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_ALLOW_MULTISELECT
      );
    },
    dateFilterApplyToTabs() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_APPLY_TO_TABS,
        DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_APPLY_TO_TABS
      );
    },
    dateFilterMandatorySelection() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_MANDATORY_SELECTION,
        DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_MANDATORY_SELECTION
      );
    },

    dateFilterDefaultProperty() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_DEFAULT_SELECTION,
        DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_DEFAULT_SELECTION
      );
    },
    dateFilterShowQuickOptions() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SHOW_QUICK_OPTIONS,
        DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_SHOW_QUICK_OPTIONS
      );
    },
    dateFilterSelectedQuickOptions() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SELECTED_QUICK_OPTIONS,
        DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_SELECTED_QUICK_OPTIONS
      );
    },
    dateFilterUniqueName() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_UNIQUE_NAME,
        DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_UNIQUE_NAME
      );
    },
    dateFilterDefaultValueActive() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_DEFAULT_VALUE_ACTIVE,
        DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_DEFAULT_VALUE_ACTIVE
      );
    },
    dateFilterStartDate() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_RANGE_SELECTOR_START_DATE,
        DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_RANGE_SELECTOR_START_DATE
      );
    },
    dateFilterEndDate() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_RANGE_SELECTOR_END_DATE,
        DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_RANGE_SELECTOR_END_DATE
      );
    },
    dateFilterMaxRange() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_MAX_RANGE,
        DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_MAX_RANGE
      );
    },
    //DROPDOWN_FILTER_CHART
    dropdownFilterMultipleSelection() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_ALLOW_MULTISELECT,
        DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS.DROPDOWN_FILTER_ALLOW_MULTISELECT
      );
    },
    dropdownFilterDefaultValue() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_DEFAULT_SELECTION,
        DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS.DROPDOWN_FILTER_DEFAULT_SELECTION
      );
    },
    dropdownFilterApplyToTabs() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_APPLY_TO_TABS,
        DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS.DROPDOWN_FILTER_APPLY_TO_TABS
      );
    },
    textFilterMandatorySelection() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_MANDATORY_SELECTION,
        TEXT_FILTER_DEFAULT_CONFIGURATIONS.TEXT_FILTER_MANDATORY_SELECTION
      );
    },
    textFilterCaseInsensitive() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_CASE_INSENSITIVE,
        TEXT_FILTER_DEFAULT_CONFIGURATIONS.TEXT_FILTER_CASE_INSENSITIVE
      );
    },
    textFilterApplyToTabs() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_APPLY_TO_TABS,
        TEXT_FILTER_DEFAULT_CONFIGURATIONS.TEXT_FILTER_APPLY_TO_TABS
      );
    },
    textFilterDefaultValue() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_DEFAULT_SELECTION,
        TEXT_FILTER_DEFAULT_CONFIGURATIONS.TEXT_FILTER_DEFAULT_SELECTION
      );
    },

    textFilterDefaultValueActive() {
      return this.getPanelDataPropertiesStyleWithUndefinedCheck(
        TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_DEFAULT_VALUE_ACTIVE,
        TEXT_FILTER_DEFAULT_CONFIGURATIONS.TEXT_FILTER_DEFAULT_VALUE_ACTIVE
      );
    },

    //COMMON_FILTER_CHART
    filterDefaultValue() {
      return this.getPanelDataPropertiesStyle(
        allPropertiesKeys.CHART_FILTER_DEFAULT_SELECTION
      );
    },
    filterValue() {
      return this.getPanelDataDetails(filterPropKey.FILTER_VALUE);
    },
    panelDataDetailsLogo() {
      return this.getPanelDataDetails(detailsKeys.LOGO);
    },
    panelDataDetailsMetrics() {
      return this.getPanelDataDetails(detailsKeys.METRICS).filter(x=> !checkTypeSelectorDataItem(x.type));
    },
    panelDataDetailsAggs() {
      return this.getPanelDataDetails(detailsKeys.AGGREGATION).filter(x=> !checkTypeSelectorDataItem(x.fieldType));
    },
    panelDataDetailsColumns() {
      return this.getPanelDataDetails(detailsKeys.COLUMNS).filter(x=> !checkTypeSelectorDataItem(x.type));
    },
    panelDataDetailsSections() {
      return this.getPanelDataDetails(detailsKeys.SECTIONS);
    },
    panelDataDetailsFilters() {
      return this.getPanelDataDetails(detailsKeys.FILTERS);
    },
    panelDataDetailsServersideFilters() {
      return this.getPanelDataDetails(detailsKeys.SERVERSIDE_FILTERS);
    },
    panelDataDetailsParams() {
      return this.getPanelDataDetails(detailsKeys.PARAMS);
    },
    panelDataDetailsCustomFilters() {
      return this.getPanelDataDetails(detailsKeys.CUSTOM_FILTERS);
    },

    panelDataPropertiesIsFilterPaneChart() {
      return this.getPanelDataProperties(detailsKeys.ISFILTER_PANE_CHART);
    },
  },
  methods: {
    /**
     * @param {allPropertiesKeys} key
     */
    getPanelDataPropertiesStyle(key) {
      return getPanelDataPropertiesStyle(this.panelData, key);
    },
    getPanelDataPropertiesStyleWithUndefinedCheck(key, computedValue) {
      const value = this.getPanelDataPropertiesStyle(key);

      return typeof value != "undefined" ? value : computedValue;
    },
    getPanelDataDetails(key) {
      return this.panelData?.details?.[key] ?? null;
    },
    getPanelDataProperties(key) {
      return this.panelData?.properties?.[key] ?? null;
    },
    getPanelDataColumnBasedProperties(fieldId, key, computedValue, slotFieldId) {
      const columnBasedPropertiesByFieldId =
        this.panelData?.properties?.columnBasedFormattings?.find(
          (c) => slotFieldId ? c.slotFieldId === slotFieldId : c.fieldId === fieldId
        );

      const value = columnBasedPropertiesByFieldId?.formattings?.[key];

      return typeof value != "undefined" ? value : computedValue;
    },
  },
};
