<template>
  <code-mirror
    v-loading="isLoading"
    class="code-mirror-sql"
    ref="editor"
    :model-value="sql"
    :lang="sqlLang"
    :extensions="[keymap.of(customKeymap)]"
    basic
    autofocus
    @change="onChange"
  >
  </code-mirror>
</template>

<script>
import CodeMirror from "vue-codemirror6";
import { sql } from "@codemirror/lang-sql";
import { keymap } from "@codemirror/view";
import { completionKeymap, startCompletion } from "@codemirror/autocomplete";
import { mapActions, mapGetters } from "vuex";
import {
  GETTER as GETTER_CONNECTIONS,
  ACTION as ACTION_CONNECTIONS,
} from "../../store/modules/Visualize/Connections/types";
import { GETTER as GETTER_GENERAL } from "../../store/modules/Visualize/General/types";
import { LoadingComponent } from "../../store/modules/Visualize/General/loadingComponentDefinitions";

export default {
  components: {
    CodeMirror,
  },
  props: {
    textToBeAddedToSql: {
      type: String,
      default: "",
    },
    sql: {
      type: String,
      default: "",
    },
    connectionId: {
      type: String,
      default: null,
    },
  },
  watch: {
    textToBeAddedToSql: {
      handler(val) {
        setTimeout(() => {
          this.insertTextAtCursor(val);
        }, 500);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      keymap: keymap,
      sqlLang: null,
      schemaConfig: {},
      customKeymap: [
        ...completionKeymap, // Include default completion key bindings
        {
          key: "Tab",
          run: async (view) => {
            await this.handleTab();

            // Trigger completion suggestions
            startCompletion(view);

            return true; // Indicate that the keybinding was handled
          },
        },
      ],
    };
  },
  async mounted() {
    await this.fetchSchemaByConnectionId({
      connectionId: this.connectionId,
      loadingComponent: LoadingComponent.DatasetWizard,
    });

    this.schemas.forEach((schema) => {
      this.schemaConfig[schema.schemaName] = {};
    });

    this.updateSqlLang();
  },
  computed: {
    ...mapGetters({
      schemas: GETTER_CONNECTIONS.GET_SCHEMAS,
      loading: GETTER_GENERAL.GET_LOADING,
    }),
    isLoading() {
      return this.loading[LoadingComponent.DatasetWizard];
    },
  },
  methods: {
    ...mapActions({
      fetchSchemaByConnectionId:
        ACTION_CONNECTIONS.FETCH_SCHEMA_BY_CONNECTION_ID,
      fetchTablesBySchemaAndConnectionId:
        ACTION_CONNECTIONS.FETCH_TABLES_BY_SCHEMA_AND_CONNECTION_ID,
      fetchFieldsBySchemaAndTable:
        ACTION_CONNECTIONS.FETCH_FIELDS_BY_SCHEMA_AND_TABLE,
    }),
    updateSqlLang() {
      this.sqlLang = sql({
        upperCaseKeywords: true,
        schema: this.schemaConfig,
      });
    },
    async handleTab() {
      const state = this.$refs.editor.view?.state;
      const cursorPos = state.selection.main.head; // Get the cursor position
      const textBeforeCursor = state.doc.sliceString(0, cursorPos); // Get text before the cursor
      const matchTableName = textBeforeCursor.match(
        /"?([\w]+)"?\."?([\w]+)"?\./
      );
      const matchSchemaName = textBeforeCursor.match(/"?([\w]+)"?\.$/);

      if (matchTableName) {
        const schemaName = matchTableName[1]; // Extracted schema name
        const tableName = matchTableName[2]; // Extracted table name
        const tables = this.schemaConfig[schemaName];

        if (tables[tableName]?.length === 0) {
          const fields = await this.fetchFieldsBySchemaAndTable({
            connectionId: this.connectionId,
            schema: schemaName,
            table: tableName,
            loadingComponent: LoadingComponent.DatasetWizard,
          });

          fields.forEach((field) => {
            tables[tableName].push(field.columnName);
          });

          this.updateSqlLang();
        }
      }

      if (matchSchemaName) {
        const matchName = matchSchemaName[1]; // Extracted schema name

        if (
          this.schemaConfig[matchName] &&
          Object.keys(this.schemaConfig[matchName]).length === 0
        ) {
          const tables = await this.fetchTablesBySchemaAndConnectionId({
            connectionId: this.connectionId,
            schemaName: matchName,
            loadingComponent: LoadingComponent.DatasetWizard,
          });

          tables.forEach((table) => {
            this.schemaConfig[matchName][table.name] = [];
          });

          this.updateSqlLang();
        }
      }
    },
    onChange(editorState) {
      this.$emit("update:sql", editorState.doc.toString());
    },
    insertTextAtCursor(text) {
      const editor = this.$refs.editor; // Access the editor instance

      if (editor) {
        const docLength = editor.view.state.doc.length;

        editor.view.dispatch({
          changes: {
            from: docLength,
            to: docLength,
            insert: text,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.code-mirror-sql ::v-deep .cm-scroller {
  overflow: auto;
  min-height: 350px;
  background: white;
}
</style>
