<template>
  <el-dialog :title="title" width="800px" :visible="isActiveProjectNamePopup">
    <div v-if="!isMigrateTargetPopup" class="vis-row-box mb-15">
      <label>{{ $t("generalPages.labelName") }}</label>
      <input
        :value="projectName"
        @keyup="(e) => changeProjectName(e)"
        class="vis-input"
        type="text"
      />
    </div>
    <div class="vis-row-box mb-15">
      <label>{{ $t("generalPages.labelLocation") }}</label>
      <div class="vis-vgt-card">
        <div class="vis-vgt-card-title vis-flex--spacebeetwen-Ycenter">
          <ul class="vis-user-tabs vis-flex--Ycenter">
            <li 
              @click="getFoldersById(null, null)"
              class="vis-main-breadcrumb vis-ml--none"
            >{{ $t("home.all") }}</li>
            <div 
              v-for="folder in folderNameList"
              :key="folder.id"
              class="vis-flex--Ycenter"
            >
              <li class="vis-main-breadcrumb"><i aria-hidden="true" :class="CustomIcon.ArrowRight"></i></li>
              <li 
                @click="getFoldersById(folder.id, folder.name)"
                :class="{'vis-font-medium': folderId == folder.id}"
                class="vis-main-breadcrumb"
              >
                {{ folder.name }}
              </li>
            </div>
          </ul>
          <div class="vis-position-relative">
            <el-input
              v-model="searchDataValue"
              size="mini"
              :placeholder="$t('filterComponents.typeToSeach')"
              @keyup.enter.native="searchProjectList(HOMEPAGE_SEARH_PARAMS.SEARCH_KEY)"
            />
            <i 
              :class="CustomIcon.Search" 
              class="vis-input-icon" 
              aria-hidden="true" 
              @click="searchProjectList(HOMEPAGE_SEARH_PARAMS.SEARCH_KEY)" 
            />
          </div>
        </div>
        <div class="vis-scroll-thin vis-schedule-content" @scroll.stop="handleScroll">
          <div class="vis-vgt-card">
            <vue-good-table
              :columns="columns"
              :rows="folders"
              :fixed-header="true"
              :search-options="{
                enabled: true,
                externalQuery: searchFolder,
              }"
              :row-style-class="rowStyleClassFn"
              @on-row-click="onOnRowClick"
              @on-row-dblclick="(item) => getFoldersById(item.row.id, item.row.name)"
            >
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        @click="$emit('closePopup', false)"
        class="vis-cancel-btn"
        type="text"
        >{{ $t("generalPages.cancel") }}</el-button
      >
      <el-button @click="setProjectName()" type="primary" size="small">{{
        $t("generalPages.save")
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import { CustomIcon } from "../../assets/js/custom-icons";
import { HOMEPAGE_SEARH_PARAMS, HOMEPAGE_SEARH_QUERY_DATA_LIST } from "../../commons/homeDataTypes";
import cloneDeep from "clone-deep";
import projectPopupSearch from "../../mixins/projectPopupSearch";
import { ObjectType } from "../../util/homePageMappers";
import { isSearchingWithDifferentKey } from "../../commons/helper";

export default {
  components: { VueGoodTable},
  mixins: [projectPopupSearch],
  props: {
    title: {
      type: String,
    },
    projectName: {
      type: String,
    },
    isActiveProjectNamePopup: {
      type: Boolean,
    },
    isMigrateTargetPopup: {
      type: Boolean,
      default: false
    },
    targetEnvInfo: {
      type: Object,
      default: () => null,
    },
    targetEnvId: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      projectNameValue: "",
      searchFolder: "",
      selectedRow: "",
      selectedRowName: "",
      columns: [
        {
          label: this.$t("generalPages.labelName"),
          field: "name",
        }
      ],
      folders: [],
      folderId: null,
      folderNameList: [],
      CustomIcon: CustomIcon,
      searchDataValue: "",
      HOMEPAGE_SEARH_PARAMS: HOMEPAGE_SEARH_PARAMS,
      searchQueryData: {},
    };
  },
  mounted() {
    this.projectNameValue = this.projectName;
    this.searchQueryData = cloneDeep(HOMEPAGE_SEARH_QUERY_DATA_LIST);

    if (this.targetEnvId) {
      this.searchQueryData = [
        ...this.searchQueryData,
        { key: "environmentId", value: this.targetEnvId },
      ];
    }
    
    this.getTotalList();
  },
  methods: {
    async getTotalList() {
      this.handleSearchQueryData({
        val: [ObjectType.FOLDER],
        type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE,
      });
      
      if (!this.isMigrateTargetPopup) {
        this.resetHomepageSearch();
        await this.getHomepageSearchResults();
        this.folders = [...this.folders, ...this.getSearchResults];
      } else {
        this.resetMigrateTargetSearch();
        await this.getMigrateTargetFolders(this.targetEnvInfo?.address);
        this.folders = [...this.folders, ...this.getMigrateTargetFolderList];
      }
    },
    searchProjectList(type) {
      if (
        this.searchDataValue.length > 2 &&
        isSearchingWithDifferentKey(this.searchDataValue, this.searchQueryData)
      ) {
        if (this.folderId === "root") {
          this.folderId = null;
        }

        this.resetHomepageSearchParameters();
        this.handleSearchQueryData({ val: this.searchDataValue, type: type });
        this.getTotalList();
      }
    },
    setProjectName() {
      this.$emit("setFolderId", this.folderId, this.folderNameList, this.selectedRowName);
      if (!this.isMigrateTargetPopup) {
        this.$emit("setProjectName", this.projectNameValue, false);
      }
    },
    changeProjectName(e) {
      this.projectNameValue = e.target.value;
    },
    onOnRowClick(item) {
      this.selectedRow = item.row.originalIndex;
      this.folderId = item.row.id;
      this.selectedRowName = item.row.name;
    },
    getFoldersById(id, name) {
      this.checkBreadcrumb(id, name);
      this.folderId = id ?? 'root';
      this.handleSearchQueryData({val: "", type: HOMEPAGE_SEARH_PARAMS.SEARCH_KEY });
      this.handleSearchQueryData({val: this.folderId, type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID });
      this.searchDataValue = "";
      this.folders = [];
      this.getTotalList();
    },
    rowStyleClassFn(row) {
      return row.originalIndex === this.selectedRow ? 'vis-selectedRow':'';
    },
  },
};
</script>
<style scoped></style>