<template>
  <div class="vis-row vis-mt--1">
    <div class="vis-col vis-col-6">
      <div class="vis-row">
        <div class="vis-col vis-col-6">
          <div class="vis-mb--2">
            <label>{{ $t("connection.labelName") }}</label>
            <el-input
              v-model="connectionForm.name"
              :class="validateCustomInput(connectionForm.name)"
            />
          </div>
          <div>
            <label>{{ $t("connection.labelType") }}</label>
            <el-select
              v-model="connectionForm.type"
              :class="validateCustomInput(connectionForm.type)"
              :placeholder="$t('connection.typePlaceholder')"
            >
              <el-option
                v-for="item in customConnectionTypes"
                :key="item.key"
                :label="item.label"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="vis-col vis-col-6">
          <label>{{ $t("connection.labelDescription") }}</label>
          <textarea
            style="width: 100%; height: 112px"
            class="vis-input"
            v-model="connectionForm.description"
          />
        </div>
      </div>
      <div class="vis-row vis-mt--2">
        <div class="vis-col vis-col-12">
          <div>
            <label>{{ $t("connection.labelCustomJDBCUrl") }}</label>
            <el-input
              v-model="connectionForm.jdbcURL"
              :class="validateCustomInput(connectionForm.jdbcURL)"
            />
          </div>
        </div>
      </div>
      <div class="vis-row vis-mt--2">
        <div class="vis-col vis-col-6">
          <div>
            <label>{{ $t("connection.labelUserName") }}</label>
            <el-input
              v-model="connectionForm.userName"
              :class="validateCustomInput(connectionForm.userName)"
            />
          </div>
        </div>
        <div class="vis-col vis-col-6">
          <div>
            <label>{{ $t("connection.labelPassword") }}</label>
            <el-input
              v-model="connectionForm.password"
              :class="validateCustomInput(connectionForm.password)"
              show-password
            />
          </div>
        </div>
      </div>

      <div class="vis-row">
        <div class="vis-col">
          <div class="vis-row-box vis-mt--2 vis-text--right">
            <el-button
              v-loading="testConnectionLoading"
              @click="testConnection"
              size="small"
              >{{ $t("connection.test") }}</el-button
            >
            <el-button
              v-loading="saveConnectionLoading"
              @click="saveConnection"
              type="primary"
              size="small"
              >{{ $t("connection.theSave") }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  validateInput,
  validateRequiredFields,
} from "../../util/validateFormHelper.js";
import cloneDeep from "clone-deep";
import { OTHER_CONNECTION_TYPES } from "../../commons/connection";
export default {
  props: {
    connectionItem: {
      type: Object,
      default: () => null,
    },
    testConnectionLoading: {
      type: Boolean,
      default: false,
    },
    saveConnectionLoading: {
      type: Boolean,
      default: false,
    },
    customConnectionData: {
      type: Object,
      default: () => null,
    },
    connectionTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      connectionForm: {},
      formSubmitted: false,
    };
  },
  mounted() {
    if (this.connectionItem && this?.connectionItem?.isCustomURL)
      this.connectionForm = cloneDeep(this.connectionItem);
  },
  computed: {
    customConnectionTypes() {
      return this.connectionTypes.filter(
        (item) =>
          item.key !== OTHER_CONNECTION_TYPES.FTP &&
          item.key !== OTHER_CONNECTION_TYPES.SMB
      );
    },
  },

  methods: {
    validateCustomInput(formValue) {
      return validateInput({ value: formValue, submit: this.formSubmitted });
    },
    validateCustomForm() {
      this.formSubmitted = true;

      return validateRequiredFields(this.connectionForm, [
        "name",
        "type",
        "jdbcURL",
        "userName",
        "password",
      ]);
    },
    // use this method from parent component to get form data
    getConnectionFormAndJdbcURL() {
      this.connectionForm.isCustomURL = true;

      return {
        connectionForm: this.connectionForm,
        jdbcURL: this.connectionForm.jdbcURL,
      };
    },
    saveConnection() {
      if (!this.validateCustomForm()) return;

      this.$emit("saveConnection");
    },
    testConnection() {
      if (!this.validateCustomForm()) return;

      this.$emit("testConnection");
    },
  },
};
</script>
