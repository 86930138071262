<template>
  <div class="vis-simple-tab">
    <div class="vis-row">
      <div class="vis-col vis-col-12">
        <el-input
          class="vis-mb--1"
          :placeholder="$t('filterComponents.typeToSeach')"
          v-model="search"
          suffix-icon="vis-icon icon-search"
          size="small"
        />
        <ul
          class="vis-filter-list-box vis-scroll-thin"
          v-loading="isLoading"
        >
          <li v-for="(item, index) in resultQuery" :key="index">
            <input
              type="checkbox"
              v-model="item.checked"
              :id="index"
              class="vis-mr--1"
            />
            <label class="vis-display-inline-block" :for="index">{{
              getSelectionItemValue(item)
            }}</label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    selectionList: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: null,
    };
  },

  computed: {
    resultQuery() {
      if (this.search) {
        return this.selectionList.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.value?.toString().toLowerCase().includes(v));
        });
      } else {
        return this.selectionList;
      }
    },
  },
  methods: {
    getSelectionItemValue(item) {
      const value = item.value ?? item.VALUE;
      return value;
    },
  },
};
</script>
<style scoped>
.vis-simple-tab-title {
  border-bottom: 1px solid gray;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.vis-filter-list-box {
  position: relative;
  height: 170px;
}
</style>
