
export const filterPopupTabValue = {
    SELECTION: "SELECTION",
    RULE: "RULE",
    ADVANCED: "ADVANCED",
    RELATIVE: "RELATIVE"
}
export const filterObjectProperty = {
    VALUE: "value",
    OPERATOR: "operator",
    CASE_SENSITIVE: "caseInsensitiveMatching",
    CHECKED: "checked"
}
export const operator = {
    EQ: "eq",
    NotEq: "notEq",
    QT: "qt",
    QTE: "qte",
    LT: "lt",
    LTE: "lte",
    IN: "in",
    NOT_IN: "notIn",
    LIKE: "like",
    NOT_LIKE: "notLike",
    OR: "or",
    AND: "and",
    StartsWith: "startsWith",
    NotStartsWith: "notStartsWith",
    EndsWith: "endsWith",
    NotEndsWith: "notEndsWith",
    BETWEEN: "between",
    NOT_BETWEEN: "notBetween",
    ISNULL: "isNull",
    ISNOTNULL: "isNotNull"
}

export const operatorSelectList = [
    { value: operator.EQ, label: "filterComponents.equals" },
    { value: operator.NotEq, label: "filterComponents.not_equal" },
    { value: operator.StartsWith, label: "filterComponents.startWith" },
    { value: operator.NotStartsWith, label: "filterComponents.not_startWith" },
    { value: operator.EndsWith, label: "filterComponents.endWith" },
    { value: operator.NotEndsWith, label: "filterComponents.not_endWith" },
    { value: operator.LIKE, label: "filterComponents.contains" },
    { value: operator.NOT_LIKE, label: "filterComponents.not_contains" },
    { value: operator.IN, label: "operatorLocale.in" },
    { value: operator.NOT_IN, label: "operatorLocale.notIn" },
    { value: operator.ISNULL, label: "filterComponents.isNull" },
    { value: operator.ISNOTNULL, label: "filterComponents.isNotNull" },
]
export const measureOptions = [
    { value: operator.EQ, label: "=" },
    { value: operator.NotEq, label: "!=" },
    { value: operator.BETWEEN, label: "filterComponents.between" },
    { value: operator.QT, label: ">" },
    { value: operator.LT, label: "<" },
    { value: operator.QTE, label: ">=" },
    { value: operator.LTE, label: "<=" },
    { value: operator.ISNULL, label: "filterComponents.isNull" },
    { value: operator.ISNOTNULL, label: "filterComponents.isNotNull" },
]
export const objectsIncludesNull = {
    ISNULL: operator.ISNULL,
    ISNOTNULL: operator.ISNOTNULL
}
export const objectsIncludesBetween = {
    BETWEEN: operator.BETWEEN,
    NOT_BETWEEN: operator.NOT_BETWEEN
}
export const objectsIncludesIn = {
    BETWEEN: operator.IN,
    NOT_BETWEEN: operator.NOT_IN
}
export const filterTabList = [
    {
        value: filterPopupTabValue.SELECTION,
        label: "filterComponents.selection",
    },
    { value: filterPopupTabValue.RULE, label: "filterComponents.rule" },
    // {
    //     value: filterPopupTabValue.ADVANCED,
    //     label: "filterComponents.advanced",
    // },
]

export const measureFilterTabList = [
    {
        value: filterPopupTabValue.RULE,
        label: "filterComponents.measureRule",
    },
    // {
    //     value: filterPopupTabValue.ADVANCED,
    //     label: "filterComponents.advanced",
    // }
]


export const dateFilterTabList = [
    {
        value: filterPopupTabValue.SELECTION,
        label: "filterComponents.selection",
    },
    {
        value: filterPopupTabValue.RELATIVE,
        label: "filterComponents.relative",
    },
]
export const dateOperator = {
    EQ: operator.EQ,
    BETWEEN: operator.BETWEEN,
    NOT_BETWEEN: "not_between",
    BEFORE: "before",
    AFTER: "after",
    ISNULL: operator.ISNULL,
    ISNOTNULL: operator.ISNOTNULL
}
export const startDateOperators = {
    EQ: dateOperator.EQ,
    BETWEEN: dateOperator.BETWEEN,
    NOT_BETWEEN: dateOperator.NOT_BETWEEN,
    AFTER: dateOperator.AFTER
}
export const endDateOperators = {
    BETWEEN: dateOperator.BETWEEN,
    NOT_BETWEEN: dateOperator.NOT_BETWEEN,
    BEFORE: dateOperator.BEFORE
}
export const dateOperatorSelectList = [
    { value: dateOperator.EQ, label: "filterComponents.equals" },
    { value: dateOperator.BETWEEN, label: "filterComponents.between" },
    { value: dateOperator.NOT_BETWEEN, label: "filterComponents.notBetween" },
    { value: dateOperator.BEFORE, label: "filterComponents.before" },
    { value: dateOperator.AFTER, label: "filterComponents.after" },
    { value: dateOperator.ISNULL, label: "filterComponents.isNull" },
    { value: dateOperator.ISNOTNULL, label: "filterComponents.isNotNull" },
]

export const commonPeriodType = {
    LAST: "last_duration",
    PERIODS_AGO: "periods_ago"
}
export const datePeriodDay = {
    TODAY: "this",
    YESTERDAY: "last",
}
export const datePeriodDaysSelectList = [
    { value: datePeriodDay.TODAY, label: "filterComponents.filterPeriodType.today" },
    { value: datePeriodDay.YESTERDAY, label: "filterComponents.filterPeriodType.yesterday" },
    { value: commonPeriodType.LAST, label: "filterComponents.filterPeriodType.last" },
    { value: commonPeriodType.PERIODS_AGO, label: "filterComponents.filterPeriodType.periodsAgo" },

]


export const datePeriodWeek = {
    THIS_WEEK: "this",
    LAST_WEEK: "last",
    WEEK_TO_DATE: "duration_to_date"
}

export const datePeriodWeekSelectList = [
    { value: datePeriodWeek.THIS_WEEK, label: "filterComponents.filterPeriodType.thisWeek" },
    { value: datePeriodWeek.LAST_WEEK, label: "filterComponents.filterPeriodType.lastWeek" },
    { value: commonPeriodType.LAST, label: "filterComponents.filterPeriodType.last" },
    { value: commonPeriodType.PERIODS_AGO, label: "filterComponents.filterPeriodType.periodsAgo" },
    { value: datePeriodWeek.WEEK_TO_DATE, label: "filterComponents.filterPeriodType.weekToDate" },
]


export const datePeriodMonth = {
    THIS_MONTH: "this",
    LAST_MONTH: "last",
    MONTH_TO_DATE: "duration_to_date"
}

export const datePeriodMonthSelectList = [
    { value: datePeriodMonth.THIS_MONTH, label: "filterComponents.filterPeriodType.thisMonth" },
    { value: datePeriodMonth.LAST_MONTH, label: "filterComponents.filterPeriodType.lastMonth" },
    { value: commonPeriodType.LAST, label: "filterComponents.filterPeriodType.last" },
    { value: commonPeriodType.PERIODS_AGO, label: "filterComponents.filterPeriodType.periodsAgo" },
    { value: datePeriodMonth.MONTH_TO_DATE, label: "filterComponents.filterPeriodType.monthToDate" },
]


export const datePeriodQuarter = {
    THIS_QUARTER: "this",
    LAST_QUARTER: "last",
    QUARTER_TO_DATE: "duration_to_date"
}

export const datePeriodQuarterSelectList = [
    { value: datePeriodQuarter.THIS_QUARTER, label: "filterComponents.filterPeriodType.thisQuarter" },
    { value: datePeriodQuarter.LAST_QUARTER, label: "filterComponents.filterPeriodType.lastQuarter" },
    { value: commonPeriodType.LAST, label: "filterComponents.filterPeriodType.last" },
    { value: commonPeriodType.PERIODS_AGO, label: "filterComponents.filterPeriodType.periodsAgo" },
    { value: datePeriodQuarter.QUARTER_TO_DATE, label: "filterComponents.filterPeriodType.quarterToDate" },
]

export const datePeriodYear = {
    THIS_YEAR: "this",
    LAST_YEAR: "last",
    YEAR_TO_DATE: "duration_to_date"
}

export const datePeriodYearSelectList = [
    { value: datePeriodYear.THIS_YEAR, label: "filterComponents.filterPeriodType.thisYear" },
    { value: datePeriodYear.LAST_YEAR, label: "filterComponents.filterPeriodType.lastYear" },
    { value: commonPeriodType.LAST, label: "filterComponents.filterPeriodType.last" },
    { value: commonPeriodType.PERIODS_AGO, label: "filterComponents.filterPeriodType.periodsAgo" },
    { value: datePeriodYear.YEAR_TO_DATE, label: "filterComponents.filterPeriodType.yearToDate" },
]

export const ruleTabOrOperator = {
    label: "Or",
    value: operator.OR
}
export const ruleTabAndOperator = {
    label: "And",
    value: operator.AND
}

export const datePeriod = {
    DAY: "DAY",
    WEEK: "WEEK",
    MONTH: "MONTH",
    QUARTER: "QUARTER",
    YEAR: "YEAR"

}
export const datePeriodSelectList = [
    { value: datePeriod.DAY, label: "generalPages.day", selectList: datePeriodDaysSelectList },
    { value: datePeriod.WEEK, label: "generalPages.week", selectList: datePeriodWeekSelectList },
    { value: datePeriod.MONTH, label: "generalPages.month", selectList: datePeriodMonthSelectList },
    { value: datePeriod.QUARTER, label: "generalPages.quarter", selectList: datePeriodQuarterSelectList },
    { value: datePeriod.YEAR, label: "generalPages.year", selectList: datePeriodYearSelectList }
]