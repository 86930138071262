<template>
  <div>
    <div class="ingest-sql-editor-header">
      <span>SQL</span>
      <div class="ingest-sql-editor-header-action-buttons">
        <i
          :class="CustomIcon.Play"
          aria-hidden="true"
          @click="$emit('runSql', sql)"
        />
        <i :class="CustomIcon.SaveOutlined" aria-hidden="true" @click="saveSql" v-show="isSaveAvailable" />
        <i
          :class="CustomIcon.Close"
          aria-hidden="true"
          @click="$emit('close')"
        />
      </div>
    </div>
    <CodeMirrorSql
      class="ingest-sql-editor"
      :sql.sync="sql"
      :connectionId="connectionId"
      :textToBeAddedToSql="textToBeAddedToSql"
      @resetTextToBeAddedToSql="$emit('resetTextToBeAddedToSql')"
    />
  </div>
</template>

<script>
import CodeMirrorSql from "../helper/CodeMirrorSql";
import { CustomIcon } from "../../assets/js/custom-icons";

export default {
  components: { CodeMirrorSql },
  props: {
    textToBeAddedToSql: {
      type: String,
      default: "",
    },
    connectionId: {
      type: String,
      default: null,
    },
    isSaveAvailable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sql: "",
      properties: [],
      CustomIcon: CustomIcon,
    };
  },
  methods: {
    saveSql() {
      this.$emit("saveSql", this.sql);
    },
  },
};
</script>

<style scoped>
.ingest-sql-editor-header {
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 600;
  font-size: 0.875rem;
}

.ingest-sql-editor-header-action-buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.ingest-sql-editor-header-action-buttons i {
  cursor: pointer;
}
.ingest-sql-editor-header-action-buttons i:not(:last-child) {
  margin-right: 1rem;
}
.ingest-sql-editor {
  border: 1px solid #e4e3e8;
  border-right: none;
  border-left: none;
}
</style>
