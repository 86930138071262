<template>
  <div class="migrate-content-container" v-loading="isMigrateLoading">
    <div class="migrate-content-header">
      <span class="migrate-content-header-title">{{
        $t("admin.menuItem.labelMigrateContent")
      }}</span>
    </div>
    <div class="migrate-content-breadcrumb-and-content">
      <div class="vis-flex--spacebeetwen-Ycenter">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>
            <span
              class="vis-font-medium vis-font-size-1 clickable-breadcrumb"
              @click="goToSelectObjectsToMigrate"
              >{{ $t("admin.migrate.Select objects to migrate") }}
            </span></el-breadcrumb-item
          >
          <el-breadcrumb-item>
            <span
              class="vis-font-medium vis-font-size-1 clickable-breadcrumb"
              @click="goToSelectTargetEnvAndOptions"
              >{{ $t("admin.migrate.Select target environment and options") }}
            </span></el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="isSummaryVisible">
            <span class="vis-font-medium vis-font-size-1"
              >{{ $t("admin.migrate.Summary") }}
            </span></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div v-if="!isSummaryVisible" class="migrate-content-body">
        <el-row>
          <el-col :span="11" class="migrate-content-source-target-container">
            <label class="vis-font-medium">{{
              $t("admin.migrate.Source environment")
            }}</label>
            <el-select
              v-model="sourceEnv"
              disabled
              placeholder="Source environment"
            >
              <el-option
                v-for="item in environments"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <EnvironmentItem :item="sourceEnvObject" :isDisabled="true"
          /></el-col>
          <el-col :span="2" class="migrate-content-arrow"
            ><i class="el-icon-arrow-right migrate-content-arrow-icon"></i
          ></el-col>
          <el-col :span="11" class="migrate-content-source-target-container">
            <label class="vis-font-medium">{{
              $t("admin.migrate.Target environment")
            }}</label>
            <el-select v-model="targetEnv" placeholder="Target environment">
              <el-option
                v-for="item in environmentsWithoutThisEnvironment"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <EnvironmentItem :item="targetEnvObject" :isDisabled="true"
          /></el-col>
        </el-row>

        <el-row class="migrate-content-options-container">
          <el-col :span="11" class="migrate-content-source-target-container">
            <span class="vis-font-medium migrate-content-section-title"
              >{{ $t("admin.migrate.Migration options") }}
            </span>
            <label
              ><div class="migrate-content-options-item">
                <span>
                  {{ $t("admin.migrate.Migrate object authorizations") }}</span
                >

                <el-checkbox
                  v-model="hasAuthorizations"
                  class="vis-ml--auto"
                ></el-checkbox></div
            ></label>
            <label
              ><div class="migrate-content-options-item">
                <span>
                  {{
                    $t(
                      "admin.migrate.Assign new data connection in target environment"
                    )
                  }}</span
                >

                <el-checkbox
                  v-model="hasCustomConnection"
                  class="vis-ml--auto"
                ></el-checkbox></div
            ></label>
            <label v-if="isDashboard">
              <div class="migrate-content-options-item">
                <span>
                  {{ $t("admin.migrate.Migrate relevant Datamodel") }}</span
                >

                <el-checkbox
                  v-model="migrateWithDatamodel"
                  class="vis-ml--auto"
                ></el-checkbox></div
            ></label>
            <label
              ><div class="migrate-content-options-item">
                <span class="vis-no-wrap">
                  {{ $t("admin.migrate.Select Target Folder") }}</span
                >
                <div class="vis-flex--Yend">
                  <el-input
                    :value="projectPath"
                    size="mini"
                    :disabled="true"
                    type="text"
                  />
                  <i
                    :class="CustomIcon.Pencil"
                    aria-hidden="true"
                    @click="openTargetFolderPopup"
                  ></i>
                </div></div
            ></label>
            <div
              v-if="hasCustomConnection"
              class="select-target-connection-title vis-pl--1"
            >
              <span>
                {{ $t("admin.migrate.Select target data connection") }}</span
              >
            </div>

            <div v-if="hasCustomConnection" class="vis-pl--1">
              <div
                v-for="connectionPair in connectionPairs"
                :key="connectionPair.sourceId"
                class="migrate-content-options-item vis-mb--0625"
              >
                <div>
                  <span> {{ connectionPair.sourceName }}</span>
                </div>

                <el-select
                  v-model="connectionPair.targetId"
                  :placeholder="$t('admin.migrate.Target data connection')"
                  class="vis-ml--auto target-connection-select"
                >
                  <el-option
                    v-for="item in connectionByEnv"
                    :key="item.connectionId"
                    :label="item.name"
                    :value="item.connectionId"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div v-if="isSummaryVisible" class="migrate-content-body">
        <el-row>
          <el-col :span="6" class="migrate-content-source-target-container">
            <label class="vis-font-medium">{{
              $t("admin.migrate.Source environment")
            }}</label>
            <el-select
              v-model="sourceEnv"
              disabled
              placeholder="Source environment"
            >
              <el-option
                v-for="item in environments"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="18"></el-col> </el-row
        ><el-row class="vis-mt--2">
          <el-col :span="6" class="migrate-content-source-target-container">
            <label class="vis-font-medium">{{
              $t("admin.migrate.Target environment")
            }}</label>
            <el-select
              v-model="targetEnv"
              disabled
              placeholder="Target environment"
            >
              <el-option
                v-for="item in environmentsWithoutThisEnvironment"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="18"></el-col>
        </el-row>

        <el-row class="migrate-content-options-container">
          <el-col :span="11" class="migrate-content-source-target-container">
            <span class="vis-font-medium migrate-content-section-title"
              >{{ $t("admin.migrate.Migration options") }}
            </span>
            <label
              ><div class="migrate-content-options-item">
                <span>
                  {{ $t("admin.migrate.Migrate object authorizations") }}</span
                >

                <el-checkbox
                  v-model="hasAuthorizations"
                  class="vis-ml--auto"
                  :disabled="isSummaryVisible"
                ></el-checkbox></div
            ></label>
            <label
              ><div class="migrate-content-options-item">
                <span>
                  {{
                    $t(
                      "admin.migrate.Assign new data connection in target environment"
                    )
                  }}</span
                >

                <el-checkbox
                  v-model="hasCustomConnection"
                  class="vis-ml--auto"
                  :disabled="isSummaryVisible"
                ></el-checkbox></div
            ></label>
            <label v-if="isDashboard"
              ><div class="migrate-content-options-item">
                <span>
                  {{ $t("admin.migrate.Migrate relevant Datamodel") }}</span
                >

                <el-checkbox
                  v-model="migrateWithDatamodel"
                  class="vis-ml--auto"
                  :disabled="isSummaryVisible"
                ></el-checkbox></div
            ></label>
            <label
              ><div class="migrate-content-options-item">
                <span class="vis-no-wrap">
                  {{ $t("admin.migrate.Select Target Folder") }}</span
                >
                <div class="vis-flex--Yend">
                  <el-input
                    :value="projectPath"
                    size="mini"
                    :disabled="true"
                    type="text"
                  />
                  <i
                    :class="CustomIcon.Pencil"
                    aria-hidden="true"
                    @click="!isSummaryVisible && openTargetFolderPopup"
                  ></i>
                </div></div
            ></label>

            <div
              v-if="hasCustomConnection"
              class="select-target-connection-title vis-pl--1"
            >
              <span>
                {{ $t("admin.migrate.Select target data connection") }}</span
              >
            </div>

            <div v-if="hasCustomConnection" class="vis-pl--1">
              <div
                v-for="connectionPair in connectionPairs"
                :key="connectionPair.sourceId"
                class="migrate-content-options-item vis-mb--0625"
              >
                <div>
                  <span> {{ connectionPair.sourceName }}</span>
                </div>

                <el-select
                  v-model="connectionPair.targetId"
                  :placeholder="$t('admin.migrate.Target data connection')"
                  class="vis-ml--auto target-connection-select"
                  :disabled="isSummaryVisible"
                >
                  <el-option
                    v-for="item in connectionByEnv"
                    :key="item.connectionId"
                    :label="item.name"
                    :value="item.connectionId"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row
          v-if="isSummaryVisible"
          class="migrate-content-options-container"
        >
          <el-col :span="11" class="migrate-content-source-target-container">
            <span class="vis-font-medium migrate-content-section-title"
              >{{ $t("admin.migrate.Content") }}
            </span>
            <div class="vis-scroll-thin">
              <div class="vis-vgt-card">
                <vue-good-table
                  ref="migrate-selection-table"
                  :columns="columns"
                  :rows="migrationResult"
                >
                  <template slot="table-column" slot-scope="props">
                    <span>
                      {{ $t(`${props.column.label}`) }}
                    </span>
                  </template>
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == columnKeys.STATUS">
                      <span>{{ props.row.statusType }}</span>
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row
          v-if="isSummaryVisible"
          class="migrate-content-options-container"
        >
          <el-col :span="11" class="migrate-content-source-target-container">
            <span class="vis-font-medium migrate-content-section-title"
              >{{ $t("admin.migrate.Result") }}
            </span>
            <el-input
              type="textarea"
              :rows="3"
              :value="errorCause"
              :disabled="true"
            >
            </el-input>
          </el-col>
        </el-row>
      </div>

      <template v-if="isSummaryVisible">
        <el-button
          v-if="!isDoneButtonActive"
          class="migrate-content-continue-with-selections-button"
          type="primary"
          size="small"
          @click="migrateSelections"
        >
          {{ $t("admin.migrate.Migrate") }}
        </el-button>

        <el-button
          v-if="isDoneButtonActive"
          class="migrate-content-continue-with-selections-button"
          type="primary"
          size="small"
          @click="redirectToMigrationPage"
        >
          {{ $t("admin.migrate.Done") }}
        </el-button>
      </template>

      <el-button
        v-if="!isSummaryVisible"
        class="migrate-content-continue-with-selections-button"
        type="primary"
        size="small"
        @click="continueWithSelections"
      >
        {{ $t("generalPages.Continue") }}
      </el-button>
    </div>
    <ProjectSavePopup
      v-if="isActiveFolderPopup"
      :title="$t('admin.migrate.Select Target Folder')"
      :isActiveProjectNamePopup="isActiveFolderPopup"
      :isMigrateTargetPopup="true"
      :targetEnvId="targetEnvId"
      :targetEnvInfo="targetEnvObject"
      @setFolderId="setFolderId"
      @closePopup="isActiveFolderPopup = $event"
    />
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { formatISOToDateTime } from "../../util/moment";
import { CustomIcon } from "../../assets/js/custom-icons";
import cloneDeep from "clone-deep";
import EnvironmentItem from "../environments/EnvironmentItem.vue";

// Store
import { mapActions, mapGetters, mapState } from "vuex";
import {
  ACTION as ACTION_ENVIRONMENT,
  GETTER as GETTER_ENVIRONMENT,
} from "../../store/modules/Transfer/Environments/types";
import {
  ACTION as ACTION_MIGRATE_CONTENT,
  STATE as STATE_MIGRATE_CONTENT,
} from "../../store/modules/Transfer/MigrateContent/types";
import { ACTION as ACTION_CONNECTIONS } from "../../store/modules/Visualize/Connections/types";
import { GETTER as GETTER_GENERAL } from "../../store/modules/Visualize/General/types";
import { LoadingComponent } from "../../store/modules/Visualize/General/loadingComponentDefinitions";
import { Notification } from "element-ui";
import { CardType, ObjectType } from "../../util/homePageMappers";
import ProjectSavePopup from "../helper/ProjectSavePopup.vue";

export default {
  components: {
    EnvironmentItem,
    VueGoodTable,
    ProjectSavePopup,
  },
  props: {
    selectedObject: {
      type: Object,
    },
  },
  data() {
    return {
      columnKeys: {
        OBJECT_NAME: "objectName",
        OBJECT_TYPE: "objectTypes", // TODO backend update singular
        OWNER: "ownerUsername",
        STATUS: "statusType",
      },
      CustomIcon: CustomIcon,
      sourceEnv: {},
      targetEnv: null,
      connectionPairs: [],
      hasAuthorizations: false,
      hasCustomConnection: false,
      migrateWithDatamodel: false,
      isSummaryVisible: false,
      migrationResult: null,
      isDoneButtonActive: false,
      CardType: CardType,
      isActiveFolderPopup: false,
      projectPath: "",
      targetFolderId: null,
      targetEnvId: null,
    };
  },
  watch: {
    targetEnv(newEnv, oldEnv) {
      if (newEnv !== oldEnv) {
        this.targetEnvId = newEnv;
      }
      this.projectPath = "";
      this.fetchConnectionsByEnv({
        loadingComponent: LoadingComponent.MigrateContent,
        environmentId: this.targetEnv,
      });
    },
    hasCustomConnection: {
      async handler() {
        if (this.hasCustomConnection) {
          const allConnectionsInProject = this.isDashboard ? await this.readConnectionsByDashboardId(this.selectedObject.id) : await this.readConnectionsByDatamodelId(this.selectedObject.id);

          this.connectionPairs = allConnectionsInProject.map((c) => ({
              sourceId: c.connectionId,
              sourceName: c.connectionName,
              targetId: "",
              targetName: "",
          }));
        }
      },
    },
  },
  async mounted() {
    await this.fetchEnvironments({
      loadingComponent: LoadingComponent.Environments,
    });

    this.sourceEnv = this.environments[0].id;
  },
  computed: {
    ...mapState({
      connectionByEnv: (state) =>
        state.visMigrateContent[STATE_MIGRATE_CONTENT.CONNECTIONS_BY_ENV],
    }),
    ...mapGetters({
      environments: GETTER_ENVIRONMENT.GET_ENVIRONMENTS,
      loading: GETTER_GENERAL.GET_LOADING,
    }),
    columns() {
      return [
        {
          label: "admin.migrate.Object Name",
          field: this.columnKeys.OBJECT_NAME,
        },
        {
          label: "admin.migrate.Object Type",
          field: this.columnKeys.OBJECT_TYPE,
        },
        {
          label: "admin.migrate.Owner",
          field: this.columnKeys.OWNER,
        },
        {
          label: "admin.migrate.Status",
          field: this.columnKeys.STATUS,
        },
      ];
    },
    environmentsWithoutThisEnvironment() {
      return this.environments.filter((e) => !e?.isThisEnvironment);
    },
    targetEnvObject() {
      return this.environments.find((e) => e.id === this.targetEnv);
    },
    sourceEnvObject() {
      return this.environments.find((e) => e.id === this.sourceEnv);
    },
    errorCause() {
      return this.migrationResult?.[0].errorCause ?? "";
    },
    isMigrateLoading() {
      return this.loading[LoadingComponent.MigrateContent];
    },
    isDashboard() {
      return this.selectedObject.type === this.CardType.DASHBOARD;
    },
  },
  methods: {
    ...mapActions({
      fetchEnvironments: ACTION_ENVIRONMENT.FETCH_ENVIRONMENTS,
      fetchConnectionsByEnv: ACTION_MIGRATE_CONTENT.FETCH_CONNECTIONS_BY_ENV,
      moveObject: ACTION_MIGRATE_CONTENT.MOVE_OBJECT,
      readConnectionsByDashboardId:
        ACTION_CONNECTIONS.FETCH_CONNECTION_BY_DASHBOARD_ID,
      readConnectionsByDatamodelId: ACTION_CONNECTIONS.FETCH_CONNECTION_BY_DATAMODEL_ID,
    }),
    setFolderId(folderId, path, folderName) {
      if (path.length > 0) {
        this.projectPath = path.reduce((acc, line, index) => {
          const isLast = index === path.length - 1;
          return acc + "/" + line.name + (isLast && line.name !== folderName ? `/${folderName}` : "");
        }, "");
      } else {
        this.projectPath = `./${folderName}`;
      }

      this.targetFolderId = folderId;
      this.isActiveFolderPopup = false;
    },
    async openTargetFolderPopup() {
      if (this.targetEnv) {
        this.isActiveFolderPopup = true;
      } else {
        const resp = {
          message: this.$t(
            "admin.migrate.You should select target environment and connection"
          ),
          data: "",
          duration: 3000,
          customClass: "center vis-notification",
          title: this.$t("notifyTitle.warning"),
          type: "warning",
        };

        Notification(resp);

        return;
      }
    },
    goToSelectObjectsToMigrate() {
      this.redirectToMigrationPage();
    },
    goToSelectTargetEnvAndOptions() {
      this.isSummaryVisible = false;
    },
    redirectToMigrationPage() {
      this.$emit("onDoneClicked");
    },
    async continueWithSelections() {
      const isAnyTargetIdEmpty = this.connectionPairs.some((c) => !c.targetId);

      if (!this.targetEnv || (this.hasCustomConnection && isAnyTargetIdEmpty)) {
        // throw notification error
        const resp = {
          message: this.$t(
            "admin.migrate.You should select target environment and connection"
          ),
          data: "",
          duration: 3000,
          customClass: "center vis-notification",
          title: this.$t("notifyTitle.warning"),
          type: "warning",
        };

        Notification(resp);

        return;
      }

      this.isSummaryVisible = true;
      this.migrationResult = [
        {
          objectId: this.selectedObject.id,
          objectName: this.selectedObject.name,
          ownerUsername: this.selectedObject?.item?.ownerName,
          objectTypes: this.selectedObject.type,
          actionType: null,
          statusType: "-",
          errorCause: "",
        },
      ];
    },
    async migrateSelections() {
      const payload = {
        loadingComponent: LoadingComponent.MigrateContent,
        bodyParam: {
          environmentId: this.targetEnv,
          hasAuthorizations: this.hasAuthorizations,
          hasCustomConnection: this.hasCustomConnection,
          dashboardId: this.isDashboard ? this.selectedObject.id : null,
          dataModelId: !this.isDashboard ? this.selectedObject.id : null,
          migrateRelatedDataModel: this.isDashboard
            ? this.migrateWithDatamodel
            : null,
          targetDashboardFolderId:
            this.selectedObject.type === CardType.DASHBOARD &&
            this.targetFolderId
              ? this.targetFolderId
              : null,
          targetDataModelFolderId:
            (this.migrateWithDatamodel && this.targetFolderId) ||
            (!this.isDashboard && this.targetFolderId)
              ? this.targetFolderId
              : null,
        },
      };

      if (this.hasCustomConnection) {
        payload.bodyParam.connectionPairs = this.connectionPairs.map((c) => {
          const foundConnection = this.connectionByEnv.find(
            (conn) => conn.connectionId === c.targetId
          );

          return {
            sourceId: c.sourceId,
            targetId: c.targetId,
            targetName: foundConnection.name,
          };
        });
      }

      const results = await this.moveObject(payload);
      const result = results.find((r) =>
        this.isDashboard
          ? r.objectTypes === ObjectType.DASHBOARD
          : r.objectTypes === ObjectType.DATAMODEL
      );
      
      if (result.errorCause?.length) {
        this.migrationResult = [
          {
            ...result,
            ownerUsername: this.selectedObject?.item?.ownerName,
            statusType: this.$t("notifyTitle.error"),
          },
        ];
      } else {
        this.migrationResult = [
          {
            ...result,
            ownerUsername: this.selectedObject?.item?.ownerName,
            statusType: this.$t("notifyTitle.success"),
            errorCause: `${
              result.objectName
            } successfully ${result.actionType.toLowerCase()} in ${
              this.targetEnvObject.name
            } environment.`,
          },
        ];
      }

      this.isDoneButtonActive = true;
    },
    formatISOToDateTime(val) {
      return formatISOToDateTime(val);
    },
    getDirectoryText(folders) {
      if (folders?.length) {
        return cloneDeep(folders).reverse().join(" > ");
      }

      return "";
    },
  },
};
</script>

<style scoped>
.migrate-content-container {
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.migrate-content-header {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding: 20px 30px 0;
  font-size: 26px;
  font-weight: 600;
  color: #3e3e3e;
}
.migrate-content-header-add-button {
  margin-left: auto;
}
.migrate-content-breadcrumb-and-content {
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  overflow-y: auto;
  height: calc(100% - 60px);
}
.migrate-content-body {
  padding: 50px 100px;
  height: calc(100% - 96px);
  overflow: auto;
  border: 1px solid #e3e3e3;
}
.migrate-content-continue-with-selections-button {
  width: 100px;
  margin-left: auto;
  margin-top: 20px;
}
.migrate-content-source-target-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.migrate-content-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 275px;
}
.migrate-content-arrow-icon {
  font-size: 100px;
  color: #d5d5d5;
}

.migrate-content-section-title {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 10px;
}

.migrate-content-options-container {
  margin-top: 50px;
}
.migrate-content-options-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

::v-deep table.vgt-table thead tr {
  background-color: #fff !important;
}
::v-deep .vgt-table th.vgt-checkbox-col {
  background: #fff !important;
}
::v-deep table.vgt-table tbody tr:hover {
  background-color: #fff !important;
}

::v-deep .el-input.is-disabled .el-input__inner {
  color: var(--body-color) !important;
}
::v-deep .el-textarea.is-disabled .el-textarea__inner {
  color: var(--body-color) !important;
}

.clickable-breadcrumb {
  cursor: pointer !important;
}
.migrate-content-header-title {
  font-size: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select-target-connection-title {
  font-weight: 500;
  margin-top: 10px;
}
.target-connection-select {
  max-width: 200px;
}
</style>
