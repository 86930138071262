import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import { HOME_TABS_KEY, HOMEPAGE_SEARH_QUERY_DATA_LIST } from "../../../../commons/homeDataTypes";

const initialPagination = {number: 0, totalPages:0, numberOfElements:0};

export const visHome = {
    state: {
        [STATE.SEARCH_HOMEPAGE_RESULTS]: [],
        [STATE.SEARCH_HOMEPAGE_PAGINATION]: initialPagination,
        [STATE.FOLDER_NAME_LIST]: [],
        [STATE.SEARCH_QUERY_DATA]: HOMEPAGE_SEARH_QUERY_DATA_LIST,
        [STATE.LAST_SELECTED_TAB]: HOME_TABS_KEY.ALL,
        [STATE.SEARCH_MIGRATION_TARGET_FOLDERS_RESULTS]: [],
        [STATE.SEARCH_MIGRATION_TARGET_PAGINATION]: initialPagination
    },
    getters: {
        [GETTER.GET_SEARCH_HOMEPAGE_RESULTS]: (state) => {
            return state[STATE.SEARCH_HOMEPAGE_RESULTS];
        },
        [GETTER.GET_HOMEPAGE_PAGINATION]: (state) => {
            return state[STATE.SEARCH_HOMEPAGE_PAGINATION];
        },
        [GETTER.GET_FOLDER_NAME_LIST]: (state) => {
            return state[STATE.FOLDER_NAME_LIST];
        },
        [GETTER.GET_SEARCH_QUERY_DATA]: (state) => {
            return state[STATE.SEARCH_QUERY_DATA];
        },
        [GETTER.GET_LAST_SELECTED_TAB]: (state) => {
            return state[STATE.LAST_SELECTED_TAB];
        },
        [GETTER.GET_SEARCH_MIGRATION_TARGET_FOLDERS_RESULTS]: (state) => {
            return state[STATE.SEARCH_MIGRATION_TARGET_FOLDERS_RESULTS];
        },
        [GETTER.GET_MIGRATION_TARGET_FOLDERS_PAGINATION]: (state) => {
            return state[STATE.SEARCH_MIGRATION_TARGET_PAGINATION];
        },
    },
    actions: {
        [ACTION.FETCH_HOMEPAGE_SEARCH]: async ({ commit}, { loadingComponent, queryParam} ) => {
           return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Search,
                    serviceMethodSub: serviceMethodSub.readMainPageSearch,
                    queryParam,
                    successMutation: MUTATION.SET_SEARCH_HOMEPAGE_RESULTS,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    loadingComponent,
                    withLoading: true,
                    withNotify: true,
                    withSuccessNotify: false,
                },
            );
        },
        [ACTION.FETCH_MIGRATE_TARGET_FOLDER_SEARCH]: async ({commit}, { loadingComponent, queryParam, adress} ) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Search,
                    serviceMethodSub: serviceMethodSub.readMigrateFoldersSearch,
                    queryParam: {queryParam, adress},
                    successMutation: MUTATION.SET_SEARCH_MIGRATION_TARGET_FOLDERS_RESULTS,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    loadingComponent,
                    withLoading: true,
                    withNotify: true,
                    withSuccessNotify: false, 
                }
            )
        },
        [ACTION.FETCH_DASHBOARD_SEARCH]: async ({ commit}, { loadingComponent, queryParam} ) => {
            return await vuexActionHandler(
                 {
                     commit,
                     serviceMethodParent: serviceMethodParent.Search,
                     serviceMethodSub: serviceMethodSub.readDashboardSearch,
                     queryParam,
                     errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                     resultSelector: (result) => result.data,
                     loadingComponent,
                     withLoading: true
                 },
             );
         },
    },
    mutations: {
        [MUTATION.SET_LAST_SELECTED_TAB](state, tab) {
            Vue.set(state, [STATE.LAST_SELECTED_TAB], tab);
        },
        [MUTATION.SET_SEARCH_QUERY_DATA](state, queryParam) {
            Vue.set(state, [STATE.SEARCH_QUERY_DATA], queryParam);
        },
        [MUTATION.SET_FOLDER_NAME_LIST](state, items) {
            Vue.set(state, [STATE.FOLDER_NAME_LIST], items);
        },
        [MUTATION.SET_SEARCH_HOMEPAGE_RESULTS](state, items) {
            const newItems = [...state[STATE.SEARCH_HOMEPAGE_RESULTS], ...items.content]
            const pagination = { number:items.number, totalPages: items.totalPages, numberOfElements: items.numberOfElements}

            Vue.set(state, [STATE.SEARCH_HOMEPAGE_RESULTS], newItems);
            Vue.set(state, [STATE.SEARCH_HOMEPAGE_PAGINATION], pagination);
        },
        [MUTATION.SET_SEARCH_MIGRATION_TARGET_FOLDERS_RESULTS](state, items) {
            const newItems = [...state[STATE.SEARCH_MIGRATION_TARGET_FOLDERS_RESULTS], ...items.content]
            const pagination = { number:items.number, totalPages: items.totalPages, numberOfElements: items.numberOfElements}

            Vue.set(state, [STATE.SEARCH_MIGRATION_TARGET_FOLDERS_RESULTS], newItems);
            Vue.set(state, [STATE.SEARCH_MIGRATION_TARGET_PAGINATION], pagination);
        },
        [MUTATION.RESET_SEARCH_HOMEPAGE_RESULTS](state) {
            Vue.set(state, [STATE.SEARCH_HOMEPAGE_RESULTS], []);
        },
        [MUTATION.RESET_SEARCH_MIGRATION_TARGET_FOLDERS_RESULTS](state) {
            Vue.set(state, [STATE.SEARCH_MIGRATION_TARGET_FOLDERS_RESULTS], []);
        },
    },
}