export const domain = "VISUALIZE_IMAGES";

export const STATE = {
    SEARCH_IMAGES_RESULTS: `${domain}/SEARCH_IMAGES_RESULTS`,
    SEARCH_IMAGES_PAGINATION: `${domain}/SEARCH_IMAGES_PAGINATION`,
  };

export const GETTER = {
  GET_IMAGES: `${domain}/GET_IMAGES`,
  GET_IMAGES_PAGINATION: `${domain}/GET_IMAGES_PAGINATION`,

};

export const ACTION = {
  FETCH_IMAGES: `${domain}/FETCH_IMAGES`,
  DELETE_IMAGES: `${domain}/DELETE_IMAGES`,
  CREATE_IMAGES: `${domain}/CREATE_IMAGES`,
  UPDATE_IMAGES: `${domain}/UPDATE_IMAGES`,
  FETCH_IMAGE_BY_ID: `${domain}/FETCH_IMAGE_BY_ID`,
  FETCH_IMAGE_THUMBNAIL: `${domain}/FETCH_IMAGE_THUMBNAIL`,
  FETCH_IMAGE_BASE64: `${domain}/FETCH_IMAGE_BASE64`
};

export const MUTATION = {
  SET_IMAGES: `${domain}/SET_IMAGES`,
  SET_SEARCH_IMAGES_RESULTS: `${domain}/SET_SEARCH_IMAGES_RESULTS`,
  REMOVE_IMAGE_FROM_RESULTS: `${domain}/REMOVE_IMAGE_FROM_RESULTS`,
  RESET_SEARCH_IMAGES_RESULTS: `${domain}/RESET_SEARCH_IMAGES_RESULTS`,
};
