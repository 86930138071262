import cloneDeep from "clone-deep";

export const scheduleDailyOnDaysOptions = {
  Everyday: "EVERYDAY",
  SelectedDays: "ON_SELECTED_DAYS",
  RepeatAfter: "REPEAT_AFTER",
};

export const schedulePeriodOptions = {
  Hourly: "HOURLY",
  IntraDay: "INTRADAY",
  Daily: "DAILY",
  Monthly: "MONTHLY",
};

export const scheduleMonthlyOnWeeksOptions = {
  specificDays: "SPECIFIC_DAYS",
  firstDayOfMonth: "FIRST_DAY_OF_MONTH",
  lastDayOfMonth: "LAST_DAY_OF_MONTH",
};

export const scheduleTabOptions = {
  General: "General",
  When: "When",
  Distribution: "Distribution",
  Content: "Content",
  Filters: "Filters",
};

export const scheduleAllSelect = {
  All: "ALL",
  Sunday: "SUNDAY",
};

export const scheduleDistributionOptions = {
  Email: "Email",
};

export const timeRangeTypes = {
  MINUTES: "schedule.Minutes",
};

export const scheduleCronDtoDefaultValue = {
  hour: "00",
  minute: "00",
  startHour: "09:00",
  endHour: "17:00",
  dailyCronType: "EVERYDAY",
  selectedDays: [],
  repeatDay: "1",
  monthlyCronType: scheduleMonthlyOnWeeksOptions.specificDays,
  selectedMonths: [],
  selectedSpecificDays: [],
  cronType: "DAILY",
};

export const scheduleStartEndDefaultHours = {
  startHour: "09:00",
  endHour: "17:00",
};

export const dailyAtTimeDefaultValue = {
  dailyTime: "06:00",
};

export const scheduleCronDtoSelectDefaultValue = {
  selectedMonths: [
    "ALL",
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ],
  selectedDays: [],
  selectedSpecificDays: [],
};
export const scheduleGeneralDefaultValue = {
  name: "",
  description: "",
};
export const epochTimeDefaultValue = {
  activeFrom: `${new Date()} `,
  activeUntil: `${new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  )}`,
};
export const defaultMail = {
  address: "",
  isDynamic: false,
  dynamicField: "",
};
export const defaultDynamicMail = {
  address: "",
  isDynamic: true,
  dynamicField: "",
};
export const notificationDtoDefaultValue = {
  receiver: [cloneDeep(defaultMail)],
  cc: [cloneDeep(defaultMail)],
  bcc: [cloneDeep(defaultMail)],
  subject: "",
  body: "",
  attachment: "",
};
export const distributionOptionsDefaultValue = [
  { label: "Email", value: "Email" },
  { label: "Other", value: "Other" },
];
export const messageDefaultValue = {
  chartData: [],
  isDynamic: false,
  dynamicDatasetId: "",
};
export const defaultCacheScheduleTaskDefinitionDto = {
  taskDefinitionDto: {
    cronDto: {
      hour: 0,
      minute: "00",
      dailyCronType: "EVERYDAY",
      repeatDay: "1",
      cronType: "DAILY",
    },
    taskType: "CACHE_CLEANUP",
    message: {},
    taskStatus: "ACTIVE",
    name: "CACHE_CLEANUP",
    description: null,
    activeFrom: null,
    activeUntil: 253402300799,
    objectId: "",
    objectType: "CACHE_CLEANUP_JOB",
  },
};