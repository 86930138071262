import { BOX_KEY_ENUM, chartTypes, chartWithNotSeeFilterIcon, getCommonsFieldProperty } from "../../commons/dashboardAndPanel";
import { singleFilterOperator } from "../../commons/filterComponents";

const dashboardFilterServices =
{
    [chartTypes.HEATMAP_CHART]: { serviceFn: (filterObj, params) => addFilterValueByHeatMap(filterObj, params) },
    [chartTypes.TREEMAP_CHART]: { serviceFn: (filterObj, selectedPanelFilterValue) => addFilterValueByTreeMap(filterObj, selectedPanelFilterValue) },
}
export const getMappedAndFilteredByBoxKeyAggregationList = (aggregationList) => {
    aggregationList = aggregationList.map((agg) => {
    const { alias, boxKey, datasetId,  field, fieldId, fieldType, fieldUsageType, type, slotFieldId } = agg;
    return {
        alias,
        boxKey,
        datasetId,
        field,
        fieldId,
        fieldType,
        fieldUsageType,
        type,
        slotFieldId,
      };
    });
    
    return aggregationList?.length === 1
      ? aggregationList
      : aggregationList.filter((x) => x.boxKey != BOX_KEY_ENUM.COLORBY.KEY);
  };
 
export const createFilterObj = (fieldItem, params, value) => {
    return {
        ...getCommonsFieldProperty(fieldItem),
        fieldUsageType: fieldItem.fieldUsageType,
        filterId: params.panelI,
        operator: params.operator,
        value: value || [],
        isDrillDown: params.isDrillDown,
        isActiveDashboardFilter: params.isActiveDashboardFilter ?? true,
        isActiveFilterIcon: true,
        isActiveDefaultValue: params.isActiveDefaultValue ?? false,
        isServerSideTableFilter: params.isServerSideTableFilter ?? false,
        filterType: fieldItem?.filterType,
        caseInsensitiveMatching: params.caseInsensitiveMatching ?? false,
    };
}

export const getFilterObj = (fieldItem, params, chartType, selectedPanelFilterValue) => {

    const filterObj = createFilterObj(fieldItem, params);
    const selectedFieldItemFieldValue = selectedPanelFilterValue?.[fieldItem.field]?.value;

    if (selectedPanelFilterValue) {
        if (!selectedFieldItemFieldValue && params.isChart) {
            delete selectedPanelFilterValue[fieldItem.field]
        }
    }

    if (isOperatorSingle(params.operator)) {
        if (dashboardFilterServices[chartType]) {
            dashboardFilterServices[chartType].serviceFn(filterObj, selectedPanelFilterValue)
        }
        else addFilterValueGeneral(filterObj, selectedPanelFilterValue)
    } else {
        //in operatorunde gelen değer [] içerisinde olmalıdır
        if (typeof selectedFieldItemFieldValue == "string")
            filterObj.value = [selectedFieldItemFieldValue];
        else filterObj.value = selectedFieldItemFieldValue;
    }

    return filterObj;
}

const addFilterValueByHeatMap = (filterObj, params) => {
    addFilterValueGeneral(filterObj, params);
}

const addFilterValueGeneral = (filterObj, selectedPanelFilterValue) => {
    const value = selectedPanelFilterValue?.[filterObj.field]?.value;

    if (!value) return;

    filterObj.value.push(value);
}

const addFilterValueByTreeMap = (filterObj, selectedPanelFilterValue) => {
    const value = selectedPanelFilterValue?.[filterObj.field]?.value;

    if (!value) return;

    filterObj.value.push(value)
}

const isOperatorSingle = (operator) => {
    return Object.values(singleFilterOperator).find(x => x == operator);
}

export const getAggregationOrMetricListByChartType = (params, selectedPanel) => {
    if (params.isChart) {
        return getMappedAndFilteredByBoxKeyAggregationList(selectedPanel?.details?.aggregation);
    } else if (params.hasCustomFilter) {
        return selectedPanel?.details?.customFilters;
    } else {
        return selectedPanel?.details?.metrics;
    }
}

export const chartListSelectedChartIsNotIn = (chartList, chartId) => {
    return chartList.filter(x => x.i !== chartId && x.details?.filters && !chartWithNotSeeFilterIcon.includes(x.type));
}