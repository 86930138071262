import { defaultColorPalette } from "../commons/chartOptions"
import { getItemPercent, legendPositionEnums, percentageValueKeys} from "../commons/dashboardProperties"

export const BAR_CHART_DEFAULT_CONFIGURATIONS = {
    BAR_CHART_SHOW_VALUE_LABEL: true,
    BAR_CHART_SHOW_ONLY: getItemPercent(),
    BAR_CHART_SHOW_PERCENT_TYPE: percentageValueKeys.COLOR,
    BAR_CHART_SHOW_INSIGHT: true,
    BAR_CHART_SHOW_SQL: false,
    BAR_CHART_VALUE_LABEL_FONT_FAMILY: "Poppins, sans-serif",
    BAR_CHART_VALUE_LABEL_FONT_WEIGHT: 500,
    BAR_CHART_VALUE_LABEL_FONT_SIZE: 12,
    BAR_CHART_VALUE_LABEL_FONT_COLOR: "#000",
    BAR_CHART_SHOW_LEGEND: false,
    BAR_CHART_LEGEND_MAX_CHARACTER: 15,
    BAR_CHART_LEGEND_POSITION: legendPositionEnums.TOP_CENTER,
    BAR_CHART_LEGEND_FONT_FAMILY: "Poppins, sans-serif",
    BAR_CHART_LEGEND_FONT_WEIGHT: 500,
    BAR_CHART_LEGEND_VERTICAL_ALIGN: "top",
    BAR_CHART_LEGEND_FONT_SIZE: 12,
    BAR_CHART_LEGEND_FONT_COLOR: "#000",
    BAR_CHART_SHOW_Y_AXIS_NAME: true,
    BAR_CHART_Y_AXIS_NAME_FONT_FAMILY: "Poppins, sans-serif",
    BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT: 500,
    BAR_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN: "center",
    BAR_CHART_Y_AXIS_NAME_VERTICAL_ALIGN: "top",
    BAR_CHART_Y_AXIS_NAME_FONT_SIZE: 12,
    BAR_CHART_Y_AXIS_NAME_FONT_COLOR: "#000",
    BAR_CHART_SHOW_X_AXIS_NAME: true,
    BAR_CHART_X_AXIS_NAME_FONT_FAMILY: "Poppins, sans-serif",
    BAR_CHART_X_AXIS_NAME_FONT_WEIGHT: 500,
    BAR_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN: "left",
    BAR_CHART_X_AXIS_NAME_VERTICAL_ALIGN: "top",
    BAR_CHART_X_AXIS_NAME_FONT_SIZE: 12,
    BAR_CHART_X_AXIS_NAME_FONT_COLOR: "#000",
    BAR_CHART_X_AXIS_NAME_TEXT: "",
    BAR_CHART_SHOW_Y_AXIS_LABEL: true,
    BAR_CHART_X_AXIS_LABEL_WRAP: false,
    BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY: "Poppins, sans-serif",
    BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT: 500,
    BAR_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN: "left",
    BAR_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN: "middle",
    BAR_CHART_Y_AXIS_LABEL_FONT_SIZE: 12,
    BAR_CHART_Y_AXIS_LABEL_FONT_COLOR: "",
    BAR_CHART_Y_AXIS_NAME_TEXT: "",
    BAR_CHART_SHOW_X_AXIS_LABEL: true,
    BAR_CHART_X_AXIS_LABEL_FONT_FAMILY: "Poppins, sans-serif",
    BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT: 500,
    BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN: "left",
    BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN: "top",
    BAR_CHART_X_AXIS_LABEL_FONT_SIZE: 12,
    BAR_CHART_X_AXIS_LABEL_FONT_COLOR: "#000",
    BAR_CHART_X_AXIS_LABEL_ROTATE: "0",
    BAR_CHART_X_AXIS_LOGARITHMIC_SCALE: false,
    BAR_CHART_X_AXIS_MIN: null,
    BAR_CHART_X_AXIS_MAX: null,
    BAR_CHART_Y_AXIS_POSITION: "left",
    BAR_CHART_Y_AXIS_SHOW_LINE: false,
    BAR_CHART_Y_AXIS_LINE_STYLE_COLOR: "#000",
    BAR_CHART_X_AXIS_GRID_LINE_COLOR: "#e0e6f1",
    BAR_CHART_Y_AXIS_NAME_LOCATION: "middle",
    BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS: true,
    BAR_CHART_SELECTED_COLOR_PALETTE: defaultColorPalette,
    BAR_CHART_AUTO_REFRESH: 0,
    BAR_CHART_AUTO_REFRESH_CUSTOM: null,
    BAR_CHART_DYNAMIC_TITLE_VALUE: "",
    BAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
    BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE: false,
    BAR_CHART_GROUP_MULTIPLE_VALUES: true,
    BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER: 10,
    BAR_CHART_X_AXIS_SHOW_DATA_ZOOM: false,
    BAR_CHART_X_AXIS_DATA_ZOOM_START: 0,
    BAR_CHART_X_AXIS_DATA_ZOOM_END: 100,
    BAR_CHART_X_AXIS_LOCK_DATA_ZOOM: false,
    BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE: 'rgba(47,69,84,0)',
    BAR_CHART_DATA_ZOOM_FILLER_COLOR: 'rgba(62, 122, 201, 0.25)',
    BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM: false,
    BAR_CHART_Y_AXIS_DATA_ZOOM_START: 0,
    BAR_CHART_Y_AXIS_DATA_ZOOM_END: 100,
    BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE: 'rgba(47,69,84,0)',
    BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM: false,
    BAR_CHART_ALLOW_DRILL_THROUGH: true,
    BAR_CHART_DRILL_THROUGH_DASHBOARD_LIST: [],
    BAR_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK: false
}
