<template>
  <div class="vis-schedule-popup">
    <div class="vis-schedule-popup-title vis-flex--spacebeetwen-Ycenter">
      <span class="vis-font-bold">{{ schedulePopupHeaderTitle ?? $t("schedule.scheduleHeader") }}</span>
      <i aria-hidden="true" class="vis-cursor-pointer" :class="customIcon.Close" @click="handleCancel"></i>
    </div>
    <div class="vis-schedule-popup-content">
      <div class="vis-row">
        <div class="vis-col vis-col-12">
          <div class="vis-font-bold">
            {{ schedulePopupHeaderSubTitle ?? $t("schedule.scheduleContent") }}
          </div>
        </div>
        <div class="vis-col vis-col-12">
          <div class="vis-schedule-popup-tab">
            <el-tabs v-model="activeTab"  class="tabs">
              <el-tab-pane
                :name="scheduleTabOptions.General"
                :label="$t('generalPages.general')"
              ></el-tab-pane>
              <el-tab-pane
                v-if="isWhenTabAvailable"
                :name="scheduleTabOptions.When"
                :label="$t('schedule.labelWhen')"
                :disabled="!isGeneralDone"
              ></el-tab-pane>
              <el-tab-pane
                v-if="isDistributionAvailable"
                :name="scheduleTabOptions.Distribution"
                :label="$t('schedule.labelDistribution')"
                :disabled="(!isWhenDone && isWhenTabAvailable) || !isGeneralDone"
              ></el-tab-pane>
              <el-tab-pane
                v-if="isContentAvailable"
                :name="scheduleTabOptions.Content"
                :label="$t('schedule.labelContent')"
                :disabled="!isDistributionDone"
              ></el-tab-pane>
              <el-tab-pane
                v-if="isFilterAvailable"
                :name="scheduleTabOptions.Filters"
                :label="$t('generalPages.filters')"
                :disabled="!isContentDone"
              ></el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      <!-- row end -->
      <ScheduleGeneralTab
        v-if="activeTab === scheduleTabOptions.General"
        :scheduleGeneral="scheduleForm.taskDefinitionDto"
        :epochTime="epochTime"
        :isGeneralFieldSolidity="isGeneralFieldSolidity"
        :selectedDataModel="selectedDataModel"
        :showDynamicContent="showDynamicContent"
        :isFromUntilAvailable="isFromUntilAvailable"
        @onScheduleUpdated="updateScheduleGeneral"
        @onEpochTimeUpdated="updateEpochTime"
        @onIsDynamicUpdated="updateIsDynamic"
        @onDynamicContentDatasetSelected="handleDynamicContentDatasetSelected"
      />
      <ScheduleWhenTab
        v-if="activeTab === scheduleTabOptions.When"
        :monthsOptions="months"
        :daysOptions="days"
        :monthlyCronOptions="monthlyCron"
        :dailyCronOptions="dailyCron"
        :taskCronOptions="taskCron"
        :timeRangeOptions="timeRanges"
        :selectedTimeRange="selectedTimeRange"
        :scheduleCronDto="scheduleForm.taskDefinitionDto.cronDto"
        :dailyAtTime="dailyAtTime"
        :scheduleStartEndHours="scheduleStartEndHours"
        :cronDtoSelect="cronDtoSelect"
        @onScheduleUpdated="updateScheduleCronDto"
        @onDailyAtTimeUpdated="updateDailyAtTime"
        @onTimeRangeUpdated="updateSelectedTimeRange"
        @onscheduleStartEndHoursUpdated="updateScheduleStartEndHours"
        @onCronDtoSelectUpdated="updateCronDtoSelect"
      />
      <ScheduleDistributionTab
        v-if="activeTab === scheduleTabOptions.Distribution"
        :scheduleNotificationDto="scheduleForm.taskNotificationDto"
        :isDistributionFieldSolidity="isDistributionFieldSolidity"
        :isEmailValid="isEmailValid"
        :selectableDynamicContentFields="selectableDynamicContentFields"
        :isDynamicAvailable="isDynamicAvailable"
        @onScheduleUpdated="updateScheduleNotificationDto"
      />
      <ScheduleContentTab
        v-if="activeTab === scheduleTabOptions.Content"
        :scheduleContentMessage="scheduleForm.taskDefinitionDto.message"
        :selectedProject="selectedProject"
        @onScheduleContentMessageUpdated="updateScheduleContentMessage"
      />
      <ScheduleFiltersTab
        v-if="activeTab === scheduleTabOptions.Filters"
        :scheduleContentMessage="scheduleForm.taskDefinitionDto.message"
        :selectedProject="selectedProject"
        :selectableDynamicContentFields="selectableDynamicContentFields"
        :isDynamicAvailable="isDynamicAvailable"
        @onScheduleContentMessageUpdated="updateScheduleContentMessage"
      />
    </div>
    <div class="vis-schedule-popup-footer vis-flex--Xright vis-mb--1">
      <el-button type="text" class="vis-cancel-btn" @click="handleCancel()">
        {{ $t("dialog.cancel") }}
      </el-button>
      <el-button type="primary" @click="saveScheduleCard()">{{
        isConfirmState ? $t("dialog.done") : $t("dialog.next")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import ScheduleGeneralTab from "./ScheduleGeneralTab.vue";
import ScheduleWhenTab from "./ScheduleWhenTab.vue";
import ScheduleDistributionTab from "./ScheduleDistributionTab.vue";
import ScheduleContentTab from "./ScheduleContentTab.vue";
import ScheduleFiltersTab from "./ScheduleFiltersTab.vue";
import {
  scheduleTabOptions,
  schedulePeriodOptions,
  scheduleAllSelect,
  dailyAtTimeDefaultValue,
  scheduleCronDtoSelectDefaultValue,
  epochTimeDefaultValue,
  scheduleDailyOnDaysOptions,
  scheduleMonthlyOnWeeksOptions,
  scheduleCronDtoDefaultValue,
  notificationDtoDefaultValue,
  timeRangeTypes,
  scheduleStartEndDefaultHours,
  messageDefaultValue,
  defaultMail,
} from "./schedule.js";
import scheduleMixin from "./mixin/scheduleMixin";
import { CustomIcon } from "../../../assets/js/custom-icons";
import cloneDeep from "clone-deep";
import { validateEmails, Notify } from "../../../commons/helper";
import { notificationType } from "../../../commons/notificationTypes";
import { mapActions, mapGetters } from "vuex";
import {
  GETTER as GETTER_DATAMODEL,
  ACTION as ACTION_DATAMODEL,
} from "../../../store/modules/Visualize/DataModel/types";

export default {
  components: {
    ScheduleGeneralTab,
    ScheduleWhenTab,
    ScheduleDistributionTab,
    ScheduleContentTab,
    ScheduleFiltersTab,
  },
  mixins: [scheduleMixin],
  props: {
    scheduleEditForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
    months: {
      type: Array,
    },
    days: {
      type: Array,
    },
    monthlyCron: {
      type: Array,
    },
    dailyCron: {
      type: Array,
    },
    taskCron: {
      type: Array,
    },
    timeRanges: {
      type: Array,
    },
    selectedProject: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentUserUsername: {
      type: String,
      default: "",
    },
    isScheduleEditMode: {
      type: Boolean,
    },
    isDistributionAvailable: {
      type: Boolean,
      default: true,
    },
    isContentAvailable: {
      type: Boolean,
      default: true,
    },
    isFilterAvailable: {
      type: Boolean,
      default: true,
    },
    showDynamicContent: {
      type: Boolean,
      default: true,
    },
    isWhenTabAvailable: {
      type: Boolean,
      default: true
    },
    isFromUntilAvailable: {
      type: Boolean,
      default: true
    },
    schedulePopupHeaderTitle: {
      type: String,
      default: null
    },
    schedulePopupHeaderSubTitle: {
      type: String,
      default: null
    },
    isMandatoryControlActive: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activeTab: null,
      scheduleForm: {
        taskDefinitionDto: {
          cronDto: scheduleCronDtoDefaultValue,
          taskType: "EXCEL",
          message: {
            ...messageDefaultValue,
            createdBy: this.currentUserUsername,
            realmName: "dataplatrealm",
          },
          taskStatus: "ACTIVE",
          name: "",
          description: "",
          activeFrom: "",
          activeUntil: "",
          objectId: this.selectedProject.id,
          objectType: "CHART",
        },
        taskNotificationDto: notificationDtoDefaultValue,
      },
      selectedTimeRange: this.timeRangesDefaultValue,
      dailyAtTime: dailyAtTimeDefaultValue,
      cronDtoSelect: scheduleCronDtoSelectDefaultValue,
      epochTime: epochTimeDefaultValue,
      scheduleTabOptions: scheduleTabOptions,
      scheduleStartEndHours: scheduleStartEndDefaultHours,
      customIcon: CustomIcon,
      isGeneralDone: false,
      isWhenDone: false,
      isDistributionDone: false,
      isContentDone: false,
      isFiltersDone: false,
      isGeneralFieldSolidity: null,
      isDistributionFieldSolidity: null,
      isEmailValid: null,
      addFilters: null,
    };
  },
  mounted() {
    this.fetchDataModelById(this.selectedProject.datamodel_id);
    this.fetchDatamodelFieldsById(this.selectedProject.datamodel_id);
    if (this.isScheduleEditMode) {
      this.scheduleForm = cloneDeep(this.scheduleEditForm);
      this.scheduleStartEndHours = {
        startHour: this.handleComingTimeDifference(
          this.scheduleForm.taskDefinitionDto.cronDto.startHour
        ),
        endHour: this.handleComingTimeDifference(
          this.scheduleForm.taskDefinitionDto.cronDto.endHour
        ),
      };

      this.dailyAtTime = {
        dailyTime: this.handleDailyTime(
          this.scheduleEditForm.taskDefinitionDto.cronDto.hour,
          this.scheduleEditForm.taskDefinitionDto.cronDto.minute
        ),
      };
      this.cronDtoSelect = {
        selectedMonths: this.handleCronDtoSelect(
          this.scheduleEditForm.taskDefinitionDto.cronDto.selectedMonths
        ),
        selectedDays: this.handleCronDtoSelect(
          this.scheduleEditForm.taskDefinitionDto.cronDto.selectedDays
        ),
        selectedSpecificDays: this.handleCronDtoSelect(
          this.scheduleEditForm.taskDefinitionDto.cronDto.selectedSpecificDays
        ),
      };
      this.epochTime = {
        activeFrom: this.humanBeingTimer(
          this.scheduleEditForm.taskDefinitionDto.activeFrom
        ),
        activeUntil: this.humanBeingTimer(
          this.scheduleEditForm.taskDefinitionDto.activeUntil
        ),
      };
      this.isGeneralDone = true;
      this.isWhenDone = true;
      this.isDistributionDone = true;
      this.isContentDone = true;
    }
    this.selectedTimeRange = {
      timeRange: this.handleTimeRange(
        this.scheduleEditForm?.taskDefinitionDto?.cronDto?.hour,
        this.scheduleEditForm?.taskDefinitionDto?.cronDto?.minute
      ),
    };
    this.activeTab = scheduleTabOptions.General;
  },
  computed: {
    ...mapGetters({
      selectedDataModel: GETTER_DATAMODEL.GET_SELECTED_DATAMODEL,
      datasetFields: GETTER_DATAMODEL.GET_DATAMODEL_BY_ID_FIELDS,
    }),
    receiverKey() {
      return this.scheduleForm.taskNotificationDto?.receiver[0]?.isDynamic
        ? "dynamicField"
        : "address";
    },
    isDynamicAvailable() {
      return !!this.scheduleForm?.taskDefinitionDto?.message?.isDynamic;
    },
    isConfirmState() {
      return (
        this.activeTab === scheduleTabOptions.Filters ||
        (this.activeTab === scheduleTabOptions.Distribution &&
          !this.isContentAvailable) ||
        (this.activeTab === scheduleTabOptions.When &&
          !this.isContentAvailable &&
          !this.isDistributionAvailable)
      );
    },
    selectableDynamicContentFields() {
      return this.datasetFields[
        this.scheduleForm.taskDefinitionDto.message.dynamicDatasetId
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchDataModelById: ACTION_DATAMODEL.FETCH_DATAMODEL_BY_ID,
      fetchDatamodelFieldsById: ACTION_DATAMODEL.FETCH_DATAMODEL_BY_ID_FIELDS,
    }),
    handleComingTimeDifference(time) {
      // removed 3 hours time difference here
      let val = parseInt(time);
      return (val < 10 ? "0" : "") + val.toString() + ":00";
    },
    handleGoingTimeDifference(source) {
      let val = source.slice(0, 2); // removed 3 hours time difference here
      return (val < 10 ? "0" : "") + val.toString();
    },
    handleTimeRange(hour, minute) {
      if (minute) {
        return minute.toString() + " " + this.$t("schedule.Minutes");
      } else if (hour <= 6) {
        return hour.toString() + hour === 1
          ? " " + this.$t("schedule.Hour")
          : " " + this.$t("schedule.Hours");
      } else return this.timeRangesDefaultValue.timeRange;
    },
    isFieldFilled(val) {
      return val?.length !== 0 && val !== null;
    },
    checkGenerelTabFieldSolidity() {
      const isDynamicFieldFilled = () => {
        if (!this.isDynamicAvailable) return true;

        if (this.scheduleForm.taskDefinitionDto.message.isDynamic) {
          return this.isFieldFilled(
            this.scheduleForm.taskDefinitionDto.message.dynamicDatasetId
          );
        } else {
          return true;
        }
      };

      return (
        this.isFieldFilled(this.scheduleForm.taskDefinitionDto.name) &&
        this.isFieldFilled(this.epochTime.activeFrom) &&
        isDynamicFieldFilled()
      );
    },
    checkDistributionTabFieldSolidity() {
      return (
        this.isFieldFilled(this.scheduleForm.taskNotificationDto.subject) &&
        this.isFieldFilled(this.scheduleForm.taskNotificationDto.body) &&
        this.isFieldFilled(this.scheduleForm.taskNotificationDto.receiver?.[0]?.[this.receiverKey])
      );
    },
    updateScheduleContentMessage(value) {
      this.scheduleForm.taskDefinitionDto.message = value;
    },
    updateScheduleNotificationDto(value) {
      this.scheduleForm.taskNotificationDto = value;
    },
    updateEpochTime(value) {
      this.epochTime = value;
    },
    updateScheduleGeneral(value) {
      this.scheduleForm.taskDefinitionDto = value;
    },
    updateScheduleCronDto(value) {
      this.scheduleForm.taskDefinitionDto.cronDto = value;
      if (value.cronType === schedulePeriodOptions.IntraDay) {
        this.scheduleForm.taskDefinitionDto.cronDto.dailyCronType =
          scheduleDailyOnDaysOptions.SelectedDays;
      }
    },
    updateDailyAtTime(value) {
      this.dailyAtTime = value;
    },
    updateCronDtoSelect(value) {
      this.cronDtoSelect = value;
    },
    updateScheduleStartEndHours(value) {
      this.scheduleStartEndHours = value;
    },
    updateSelectedTimeRange(value) {
      this.selectedTimeRange = value;
    },
    updateIsDynamic(value) {
      this.scheduleForm.taskDefinitionDto.message.isDynamic = value;
      this.scheduleForm.taskDefinitionDto.message.dynamicDatasetId = "";

      //dynamic switch false gelirse, dynamic secilmis su alanları bosalt veya sil:
      //receiver, cc, bcc, filter, params
      if (!value) {
        const resetDistributionFields = () => {
          const taskNotificationDtoCloned = cloneDeep(
            this.scheduleForm.taskNotificationDto
          );

          taskNotificationDtoCloned.receiver = [cloneDeep(defaultMail)];
          taskNotificationDtoCloned.cc = [cloneDeep(defaultMail)];
          taskNotificationDtoCloned.bcc = [cloneDeep(defaultMail)];

          this.updateScheduleNotificationDto(taskNotificationDtoCloned);
        };

        const removeSelectedDynamicFiltersAndParams = () => {
          const taskNotificationDtoMessageCloned = cloneDeep(
            this.scheduleForm.taskDefinitionDto.message
          );

          taskNotificationDtoMessageCloned.chartData.forEach((chart) => {
            chart.filter = chart.filter.filter((f) => !f.isDynamic);
            chart.params = chart.params.filter((p) => !p.isDynamic);
          });

          this.updateScheduleContentMessage(taskNotificationDtoMessageCloned);
        };

        resetDistributionFields();
        removeSelectedDynamicFiltersAndParams();
      }
    },
    handleDynamicContentDatasetSelected(datasetId) {
      if (datasetId) {
        this.scheduleForm.taskDefinitionDto.message.dynamicDatasetId =
          datasetId;
      } else {
        this.scheduleForm.taskDefinitionDto.message.dynamicDatasetId = "";
      }
    },
    handleDailyTime(hour, minute) {
      // removed 3 hours time difference here
      const selectedHour = String(parseInt(hour));
      return selectedHour < 10
        ? "0" + selectedHour + ":" + String(minute)
        : selectedHour + ":" + String(minute);
    },
    handleCancel() {
      this.$emit("closePopupSchedule", false);
      this.isGeneralFieldSolidity = null;
      this.isDistributionFieldSolidity = null;
      this.isEmailValid = null;
      this.selectedTimeRange = this.timeRangesDefaultValue;
    },
    epochTimer(time) {
      if (!time) return null;
      
      const epochTime = new Date(time);
      return Math.floor(epochTime.getTime() / 1000) - 10800;
    },
    humanBeingTimer(time) {
      if (!time) return null;

      const myDate = new Date((time + 10800) * 1000);
      return myDate;
    },
    scheduleActiveFrom(date) {
      this.scheduleForm.taskDefinitionDto.activeFrom = this.epochTimer(date);
    },
    scheduleActiveUntil(date) {
      this.scheduleForm.taskDefinitionDto.activeUntil = this.epochTimer(date);
    },
    showScheduleNotify(message) {
      return Notify(this.$t(message), notificationType.WARNING);
    },
    saveScheduleCard() {
      const handleGeneralTab = () => {
        if (!this.isWhenTabAvailable && this.checkGenerelTabFieldSolidity()) {
          this.activeTab = scheduleTabOptions.Distribution;
          this.isGeneralDone = true;
        } else if (this.checkGenerelTabFieldSolidity()) {
          this.isGeneralDone = true;
          this.activeTab = scheduleTabOptions.When;
        } else {
          this.showScheduleNotify("generalPages.Fill In All Fields");
        }
      };

      const handleWhenTab = () => {
        if (
          this.scheduleForm.taskDefinitionDto.cronDto.cronType ===
            schedulePeriodOptions.IntraDay &&
          this.cronDtoSelect.selectedDays.length === 0
        ) {
          this.showScheduleNotify(
            "notifyMessages.You should select at least one day"
          );
        } else if (
          this.scheduleForm.taskDefinitionDto.cronDto.cronType ===
            schedulePeriodOptions.Monthly &&
          this.cronDtoSelect.selectedMonths.length === 0
        ) {
          this.showScheduleNotify(
            "notifyMessages.You should select at least one month"
          );
        } else if (
          (this.scheduleForm.taskDefinitionDto.cronDto.cronType ===
            schedulePeriodOptions.Daily &&
            this.scheduleForm.taskDefinitionDto.cronDto.dailyCronType ===
              scheduleDailyOnDaysOptions.SelectedDays &&
            this.cronDtoSelect.selectedDays.length === 0) ||
          (this.scheduleForm.taskDefinitionDto.cronDto.cronType ===
            schedulePeriodOptions.Monthly &&
            ![
              scheduleMonthlyOnWeeksOptions.firstDayOfMonth,
              scheduleMonthlyOnWeeksOptions.lastDayOfMonth,
            ].includes(
              this.scheduleForm.taskDefinitionDto.cronDto.monthlyCronType
            ) &&
            ((this.cronDtoSelect.selectedSpecificDays.length === 0 &&
              this.scheduleForm.taskDefinitionDto.cronDto.monthlyCronType ===
                scheduleMonthlyOnWeeksOptions.specificDays) ||
              (this.cronDtoSelect.selectedDays.length === 0 &&
                ![scheduleMonthlyOnWeeksOptions.specificDays].includes(
                  this.scheduleForm.taskDefinitionDto.cronDto.monthlyCronType
                ))))
        ) {
          this.showScheduleNotify(
            "notifyMessages.You should select at least one day"
          );
        } else if (!this.isDistributionAvailable && !this.isContentAvailable) {
          this.isWhenDone = true;
          this.handleSave();
        } else {
          this.isWhenDone = true;
          this.activeTab = scheduleTabOptions.Distribution;
        }
      };

      const handleDistributionTab = () => {
        this.isDistributionFieldSolidity =
          this.checkDistributionTabFieldSolidity();
        
        const distributionReceiver = this.scheduleForm.taskNotificationDto.receiver?.[0];
        const isValidateEmail = validateEmails(distributionReceiver?.[this.receiverKey]);
        
        if (!this.isDistributionFieldSolidity) {
          this.showScheduleNotify("generalPages.Fill In All Fields");
        } else if (!isValidateEmail && !distributionReceiver?.isDynamic) {
          this.showScheduleNotify("notifyMessages.Please enter a valid email");
        } else {
          if (!this.isContentAvailable) {
            this.isDistributionDone = true;
            this.handleSave();
          } else {
            this.isDistributionDone = true;
            this.activeTab = scheduleTabOptions.Content;
          }
        }
      };

      const handleContentTab = () => {
        if (this.scheduleForm?.taskDefinitionDto?.message?.chartData?.length) {
          this.isContentDone = true;
          this.activeTab = scheduleTabOptions.Filters;
        } else {
          this.showScheduleNotify(
            "notifyMessages.You should select at least one chart"
          );
        }
      };

      const handleFiltersTab = () => {
        let isAnyMandatoryFilterNotField = false;
        let filterIdsPushedToChart = [];

        this.scheduleForm?.taskDefinitionDto?.message?.chartData?.forEach(
          (chart) => {
            const mandatoryFiltersAndParams = [
              ...chart?.filter,
              ...chart?.params,
            ].filter((f) => f.isMandatory);

            filterIdsPushedToChart = [
              ...filterIdsPushedToChart,
              ...mandatoryFiltersAndParams?.map((m) => {
                return {
                  filterId: m.filterId,
                  tabId: chart.tabId
                }
              }),
            ];

            mandatoryFiltersAndParams.forEach((filter) => {
              if (filter.isDynamic && (filter?.dynamicField === "" || filter?.dynamicField === undefined)) {
                isAnyMandatoryFilterNotField = true;
              } else if (!filter?.isDynamic && !filter?.value?.length) {
                isAnyMandatoryFilterNotField = true;
              }
            });
          }
        );

        //mandatoryChartsI scheduleFiltersTab componentinde mount esnasında dolduruluyor.
        const mandatoryChartsIAsArray = [
          ...this.scheduleForm?.taskDefinitionDto?.message?.mandatoryData
            ?.mandatoryChartsI,
        ];
        
        const matchingItems = mandatoryChartsIAsArray.filter(
          (mandatoryFilter) =>
            filterIdsPushedToChart.some(
              (addedFilter) =>
                addedFilter.filterId === mandatoryFilter.filterId &&
                addedFilter.tabId === mandatoryFilter.tabId
            )
        );

        if (
          this.isMandatoryControlActive &&
          (isAnyMandatoryFilterNotField ||
            matchingItems?.length !== mandatoryChartsIAsArray?.length)
        ) {
          this.showScheduleNotify(
            "Zorunlu Filtre. Lütfen filtre değeri girin."
          );
          return;
        }

        // son kontrol
        const usingFilters = [];
        this.scheduleForm?.taskDefinitionDto?.message?.chartData.forEach(
          (item) => {
            // Filtreleri kontrol et
            if (item.filter && item.filter.length > 0) {
              item.filter.forEach((filter) => {
                if (
                  !usingFilters.some(
                    (existingFilter) =>
                      existingFilter.fieldId === filter.fieldId
                  )
                ) {
                  usingFilters.push(filter);
                }
              });
            }

            if (item.params && item.params.length > 0) {
              item.params.forEach((param) => {
                if (
                  !usingFilters.some(
                    (existingParam) => existingParam.fieldId === param.fieldId
                  )
                ) {
                  usingFilters.push(param);
                }
              });
            }
          }
        );

        const scheduleFilterValue =
          this.scheduleForm?.taskDefinitionDto?.message?.scheduleFilterValue;

        Object.keys(scheduleFilterValue || {}).forEach((filterId) => {
          Object.keys(scheduleFilterValue[filterId] || {}).forEach((alias) => {
            const found = usingFilters.find((filter) => filter.alias === alias);
            if (!found) {
              // Eşleşmeyen değeri scheduleFilterValue array'inden sil
              delete scheduleFilterValue[filterId];
            }
          });
        });

        this.handleSave();
      };

      if (this.activeTab === scheduleTabOptions.General) {
        handleGeneralTab();
      } else if (this.activeTab === scheduleTabOptions.When) {
        handleWhenTab();
      } else if (this.activeTab === scheduleTabOptions.Distribution) {
        handleDistributionTab();
      } else if (this.activeTab === scheduleTabOptions.Content) {
        handleContentTab();
      } else {
        handleFiltersTab();
      }
    },
    handleSave() {
      if (
        this.scheduleForm.taskDefinitionDto.cronDto.cronType !=
        schedulePeriodOptions.IntraDay
      ) {
        // removed 3 hours time difference here
        this.scheduleForm.taskDefinitionDto.cronDto.hour = parseInt(
          this.dailyAtTime.dailyTime.slice(0, 2)
        );
        this.scheduleForm.taskDefinitionDto.cronDto.minute =
          this.dailyAtTime.dailyTime.slice(3, 5);
      } else {
        this.scheduleForm.taskDefinitionDto.cronDto.dailyCronType =
          scheduleDailyOnDaysOptions.SelectedDays;
        this.convertSelectedTimeRange(this.selectedTimeRange.timeRange);
        this.scheduleForm.taskDefinitionDto.cronDto.startHour =
          this.handleGoingTimeDifference(this.scheduleStartEndHours.startHour);
        this.scheduleForm.taskDefinitionDto.cronDto.endHour =
          this.handleGoingTimeDifference(this.scheduleStartEndHours.endHour);
      }

      this.handleCronDtoSelect(this.cronDtoSelect.selectedMonths);
      this.handleCronDtoSelect(this.cronDtoSelect.selectedDays);
      this.handleCronDtoSelect(this.cronDtoSelect.selectedSpecificDays);
      this.scheduleActiveFrom(this.epochTime.activeFrom);
      this.scheduleActiveUntil(this.epochTime.activeUntil);
      this.$emit("handleSchedule", this.scheduleForm);
      this.handleCancel();
    },
    convertSelectedTimeRange(range) {
      const selectedRange = range.split(" ");
      const selectedRangeNumber = selectedRange[0];
      const selectedRangeTimeType = selectedRange[1];
      if (selectedRangeTimeType === this.$t(timeRangeTypes.MINUTES)) {
        this.scheduleForm.taskDefinitionDto.cronDto.minute =
          selectedRangeNumber;
      } else {
        this.scheduleForm.taskDefinitionDto.cronDto.hour = selectedRangeNumber;
      }
    },
    handleCronDtoSelect(multiselect) {
      if (multiselect === this.cronDtoSelect?.selectedMonths) {
        multiselect.includes(scheduleAllSelect.All)
          ? (this.scheduleForm.taskDefinitionDto.cronDto.selectedMonths =
              multiselect.filter((x) => x === scheduleAllSelect.All))
          : (this.scheduleForm.taskDefinitionDto.cronDto.selectedMonths =
              multiselect);
      }
      if (multiselect === this.cronDtoSelect?.selectedDays) {
        multiselect.includes(scheduleAllSelect.All)
          ? (this.scheduleForm.taskDefinitionDto.cronDto.selectedDays =
              multiselect.filter((x) => x === scheduleAllSelect.All))
          : (this.scheduleForm.taskDefinitionDto.cronDto.selectedDays =
              multiselect);
      }
      if (multiselect === this.cronDtoSelect?.selectedSpecificDays) {
        this.scheduleForm.taskDefinitionDto.cronDto.selectedSpecificDays =
          multiselect.map((x) => x.toString());
      }
      if (
        multiselect ===
        this.scheduleEditForm?.taskDefinitionDto?.cronDto?.selectedMonths
      ) {
        if (multiselect.includes(scheduleAllSelect.All)) {
          return this.months.map((x) => x.key);
        } else {
          return multiselect;
        }
      }
      if (
        multiselect ===
        this.scheduleEditForm?.taskDefinitionDto?.cronDto?.selectedDays
      ) {
        if (multiselect.includes(scheduleAllSelect.All)) {
          return this.days.map((x) => x.key);
        } else {
          return multiselect;
        }
      }
      if (
        multiselect ===
        this.scheduleEditForm?.taskDefinitionDto?.cronDto?.selectedSpecificDays
      ) {
        return multiselect.map((x) => parseInt(x));
      }
    },
  },
};
</script>

<style scoped>
::v-deep .vis-schedule-popup-content label {
  font-weight: 500 !important;
}
.tabs {
  position: relative;
}

.el-tabs__header {
  border-bottom: none;
}

.el-tabs__header.is-top::after {
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #3d62f8;
  position: fixed !important;
}
::v-deep .el-tabs__nav-wrap::after {
  display: none !important;
}

::v-deep .el-tabs__active-bar {
  background-color: var(--primary);
}

::v-deep .el-tabs__item.is-active {
  color: var(--primary);
}
.vis-schedule-popup-title {
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #333333;
  padding: 24px 24px 0 24px;
}
</style>
