<template>
  <div>
    <div v-if="!hasMessage">
      <h2>
        <span>{{ $t("home.hello") }}</span
        >, {{ $t("home.welcome") }}
      </h2>
      <p>
        {{ $t("home.about") }}
      </p>
    </div>
    <div v-else v-html="message"></div>
  </div>
</template>
<script>

export default {
  props: {
    message: {
      type: String,
      default: ""
    }
  },
  computed: {
    hasMessage() {
      return this.message !== "";
    },
  },
};
</script>
<style scoped>
::v-deep .vis-dyis-welcome-box {
  text-align: center !important;
}
::v-deep .vis-dyis-welcome-box p:nth-child(1) {
  font-size: 1rem !important;
}
::v-deep .vis-dyis-welcome-box p:nth-child(2) {
  font-size: 1.375rem !important;
}
::v-deep .vis-dyis-welcome-box p:last-child {
  font-size: 1.5rem !important;
}
</style>