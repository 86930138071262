import { mapActions, mapGetters, mapMutations } from "vuex/dist/vuex.common.js";
import {
  ACTION as ACTION_SEARCH,
  GETTER as GETTER_SEARCH,
  MUTATION as MUTATION_HOME,
} from "../store/modules/Visualize/Home/types";
import { HOMEPAGE_SEARH_PARAMS } from "../commons/homeDataTypes";
import { LoadingComponent } from "../store/modules/Visualize/General/loadingComponentDefinitions";
import { getSearchQueryParams } from "../commons/helper";

export default {
  data() {
    return {
      searchQueryParam: "",
      scrollPosition: null,
      HOMEPAGE_SEARH_PARAMS: HOMEPAGE_SEARH_PARAMS,
      dataModels: [],
      mergeList: [],
    };
  },
  computed: {
    ...mapGetters({
      getSearchResults: GETTER_SEARCH.GET_SEARCH_HOMEPAGE_RESULTS,
      searchPagination: GETTER_SEARCH.GET_HOMEPAGE_PAGINATION,
      getMigrateTargetFolderList: GETTER_SEARCH.GET_SEARCH_MIGRATION_TARGET_FOLDERS_RESULTS,
      migrateTargetFolderListPagination: GETTER_SEARCH.GET_MIGRATION_TARGET_FOLDERS_PAGINATION,
    }),
  },
  methods: {
    ...mapActions({
      fetchHomePageSearch: ACTION_SEARCH.FETCH_HOMEPAGE_SEARCH,
      fetchMigrateTargetSearch: ACTION_SEARCH.FETCH_MIGRATE_TARGET_FOLDER_SEARCH
    }),
    ...mapMutations({
      resetHomepageSearch: MUTATION_HOME.RESET_SEARCH_HOMEPAGE_RESULTS,
      resetMigrateTargetSearch: MUTATION_HOME.RESET_SEARCH_MIGRATION_TARGET_FOLDERS_RESULTS,
    }),
    resetHomepageSearchParameters() {
      this.searchQueryData.forEach((x) => (x.value = ""));
      this.folders = [];
      this.dataModels = [];
      this.mergeList = [];
      this.resetHomepageSearch();
      this.resetMigrateTargetSearch();
    },
    checkBreadcrumb(id, name) {
      if (!id && !name) {
        this.folderNameList = [];
      }
      if (id && name) {
        const selectedBreadcrumb = this.folderNameList.find((x) => x.id == id);

        if (!selectedBreadcrumb) {
          this.folderNameList.push({ id, name });
        } else {
          const selectedFolderIndex = this.folderNameList.findIndex(
            (y) => y.id == selectedBreadcrumb.id
          );
          this.folderNameList = this.folderNameList.filter(
            (f, index) => index <= selectedFolderIndex
          );
        }
      }
    },
    handleScroll(e) {
      let currentScrollPosition = e.srcElement.scrollTop;
      this.scrollPosition =
        e.srcElement.scrollHeight - e.srcElement.offsetHeight;

      const getListAccordingToPagination = (pagination) => {
        const nextPageNumber = pagination.number + 1;
        if (
          currentScrollPosition === this.scrollPosition &&
          nextPageNumber < pagination.totalPages &&
          currentScrollPosition !== 0
        ) {
          this.handleSearchQueryData({
            val: nextPageNumber,
            type: HOMEPAGE_SEARH_PARAMS.PAGE,
          });
          setTimeout(() => {
            this.getTotalList();
          }, 1000); 
        }
      }

      if (!this.isMigrateTargetPopup) {
        getListAccordingToPagination(this.searchPagination);
      } else {
        getListAccordingToPagination(this.migrateTargetFolderListPagination);
      }

      this.scrollPosition = currentScrollPosition;
    },
    handleSearchQueryData(data) {
      this.searchQueryData.find((x) => x?.key === data.type).value = data.val;
    },
    
    async getHomepageSearchResults() {
      await this.fetchHomePageSearch({
        loadingComponent: LoadingComponent.HomepageList,
        queryParam: getSearchQueryParams(this.searchQueryData),
      });
    },
    async getMigrateTargetFolders(adress, queryData=this.searchQueryData) {
      await this.fetchMigrateTargetSearch({
        loadingComponent: LoadingComponent.HomepageList,
        queryParam: getSearchQueryParams(queryData),
        adress: adress
      });
    },
  },
};
