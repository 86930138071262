<template>
  <div class="vis-flex--spacebeetwen-Ycenter">
    <div :style="{ width: imageAreaWidth }">
      <img
        v-if="imageValue"
        class="vis-dashboard-property-image"
        :src="imagePath"
      />
      <div class="vis-flex--Ycenter" v-else>
        <el-button
          class="vis-mr--05"
          type="text"
          @click="$emit('handleSelectedStyleProperty')"
          >{{ $t("admin.images.Add image") }}</el-button
        >
        <i aria-hidden="true" :class="customIcon.Plus"></i>
      </div>
    </div>
    <div v-if="imageValue">
      <i
        aria-hidden="true"
        :class="[customIcon.Pencil, 'vis-cursor-pointer', 'vis-mr--05']"
        @click="$emit('handleSelectedStyleProperty')"
      ></i>
      <i
        aria-hidden="true"
        :class="[customIcon.Trash, 'vis-cursor-pointer']"
        @click="$emit('deletePropertyValue')"
      ></i>
    </div>
  </div>
</template>
<script>
import { CustomIcon } from "../../assets/js/custom-icons";

export default {
  props: {
    imageValue: {
      type: String,
      default: null,
    },
    imageAreaWidth: {
      type: String,
      default: "70%",
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
  computed: {
    imagePath() {
      const servicePath = process.env.VUE_APP_API_ENDPOINT;

      return `${servicePath}visualize/v1/images/${this.imageValue}/thumbnail`;
    },
  },
};
</script>
