import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";

import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";
import { NOTIFY_TITLE } from "../../../../commons/notifierTypes";

export const visSchedule = {
  state: {
    [STATE.SCHEDULES]: [],
    [STATE.TRANSACTIONS_HISTORY]: [],
  },
  getters: {
    [GETTER.GET_SCHEDULES]: (state) => {
      return state[STATE.SCHEDULES];
    },
    [GETTER.GET_TRANSACTIONS_HISTORY]: (state) => {
      return state[STATE.TRANSACTIONS_HISTORY];
    },
  },
  actions: {
    [ACTION.FETCH_TRANSACTIONS_HISTORY]: async ({ commit }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scheduler,
        serviceMethodSub: serviceMethodSub.readTransactionsHistory,
        successMutation: MUTATION.SET_TRANSACTIONS_HISTORY,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
      });
    },
    [ACTION.FETCH_SCHEDULES]: async ({ commit }, { dashboardId }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scheduler,
        serviceMethodSub: serviceMethodSub.readSchedulersByDashboardId,
        queryParam: dashboardId,
        successMutation: MUTATION.SET_SCHEDULES,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
      });
    },
    [ACTION.CREATE_SCHEDULE]: async ({ commit }, { bodyParam }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scheduler,
        serviceMethodSub: serviceMethodSub.createScheduler,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        bodyParam,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
        notifyTitle: NOTIFY_TITLE.SAVE_SUCCESS,
      });
    },
    [ACTION.DELETE_SCHEDULE]: async ({ commit, dispatch }, { dashboardId, taskId}) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scheduler,
        serviceMethodSub: serviceMethodSub.deleteScheduler,
        queryParam: taskId,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
      setTimeout(async () => {
        await dispatch(ACTION.FETCH_SCHEDULES, { dashboardId });
      }, 1000); 
    },
    [ACTION.UPDATE_SCHEDULE]: async ({ commit }, { bodyParam }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scheduler,
        serviceMethodSub: serviceMethodSub.updateScheduler,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        bodyParam,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
        notifyTitle: NOTIFY_TITLE.SAVE_SUCCESS,
      });
    },
    [ACTION.FETCH_CACHE_CLEANUP]: async ({ commit }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Scheduler,
        serviceMethodSub: serviceMethodSub.readCacheCleanup,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
      });
    },
  },
  mutations: {
    [MUTATION.SET_SCHEDULES](state, items) {
      Vue.set(state, [STATE.SCHEDULES], items);
    },
    [MUTATION.SET_TRANSACTIONS_HISTORY](state, items) {
      Vue.set(state, [STATE.TRANSACTIONS_HISTORY], items);
    },
  },
};
